.Tabs {

  &-Nav {
    display: flex;
    border-radius: 0 0 8px 8px;
    border-left: 1px solid var(--border-color);
    border-right: 1px solid var(--border-color);
    overflow-y: hidden;
    overflow-x: scroll;
    -ms-overflow-style: none;
    overflow: -moz-hidden-unscrollable;

    &:empty{
      padding-top: 24px;
      background-color: #fff;
    }

    &:before,
    &:after {
      content: '';
      display: block;
      background-color: var(--background-color);
      border-bottom: 1px solid var(--border-color);
      flex-grow: 1;
      min-width: 16px;
    }

    &::-webkit-scrollbar {
      width: 0 !important;
      height: 0 !important;
    }
  }

  &-Button {
    background-color: var(--background-color);
    border-bottom: 1px solid var(--border-color);
    padding: 5px 13px 10px;
    position: relative;
    color: var(--main-color);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;

    &-Icon {
      width: 48px;
      pointer-events: none;
    }

    &:visited {
      color: var(--main-color);
    }
    
    &.Tabs-Button_active {
      color: var(--text-color);
    }

    &_active {
      transform: scale(1, 1);
      clip-path: polygon(
        -1% -1%,
        101% -1%,
        101% 100%,
        calc(50% + 6px) 100%,
        50% calc(100% - 8px),
        calc(50% - 6px) 100%,
        -1% 100%
      );
    }
  }

  &-Tab {
    padding: 0;
    background: transparent;
    border: 0;
    margin-top: 24px;
  }
}
