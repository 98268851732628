.TrackFiles {
    display: flex;
    margin-top: 24px;
    align-items: flex-start;

    &-Functions {
        display: flex;
        justify-content: space-between;
    }

    &-Aside {
        background-color: #fff;
        flex: 1 4;
        border-radius: 8px;
        overflow: hidden;
        border: 1px solid #d3d9e0;

        max-height: calc(100vh - 56px);
        overflow: auto;
        position: sticky;
        top: 56px;

        margin-right: 24px;

        &::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
            border-radius: 8px;
            margin: 8px 0;
        }

        &::-webkit-scrollbar {
            width: 5px;
        }

        &::-webkit-scrollbar-thumb {
            background-color: rgba($color: #000000, $alpha: 0.2);
            opacity: 0.3;
            border-radius: 8px;

            &:hover {
                background-color: rgba($color: #000000, $alpha: 0.4);
            }
        }
    }

    &-SearchBox {
        background: #f7f7f7;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        padding: 24px;
        padding-bottom: 0px;
        // margin-bottom: 24px;
        border-bottom: 1px solid #d3d9e0;
        // display: flex;
        // align-items: center;
    }

    &-Categories {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        // min-height: 57px;
        padding: 0 24px;
        margin-top: 12px;
        border-top: 1px solid #d3d9e0;

        & > span {
            font-weight: bold;
            margin-right: 6px;
        }

        & > label {
            width: auto;
            padding: 8px;

            & > svg {
                margin-right: 6px;
            }
        }
    }

    &-List {
        padding-bottom: 8px;
    }

    &-Section {
        flex: 3 4;
        background-color: #f7f7f7;
        border: 1px solid #d3d9e0;
        border-radius: 8px;
    }
}
