.SliderLink {
  // max-width: 1123px;
  width: 100%;
  overflow: hidden;
  position: relative;

  &:hover &-Prev, 
  &:hover &-Next, 
  &:hover &-Nav {
    display: flex;
  }

  &-Prev, &-Next {
    position: absolute;
    justify-content: center;
    align-items: center;
    z-index: 31;
    top: calc(50% - 20px);
    background-color: rgba(0, 0, 0, 0.4);
    border-radius: 50%;
    width: 40px;
    height: 40px;
    cursor: pointer;
    display: none;
    &:hover {
      background-color: rgba(0, 0, 0, 0.7);
    }
    i {
      color: rgba(255, 255, 255, 0.4);
      font-size: 26px;
      &:hover {
        color: #fff;
      }
    }
  }
  &-Prev {
    left: 10px;
  }
  &-Next {
    right: 10px;
  }

  &-Item {
    display: block;
  }
  
  &-Nav {
    z-index: 30;
    position: absolute;
    bottom: 0;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    height: 20px;
    width: 50%;
    justify-content: space-between;
    align-items: center;
    display: none;
    
    &-Item {
      width: 100%;
      margin: 0 5px;
      border-radius: 10px;
      height: 7px;
      cursor: pointer;
      background-color: rgba(0, 0, 0, 0.4);
      &:hover {
         background-color: rgba(255, 255, 255, 0.6);
      }
      &-Active {
        background-color: rgba(255, 255, 255, 0.6);
      }
    }
  }

  &-Image {
    width: 100%;
    position: absolute;
    top: 0; 
    cursor: default;
     &_slide {
      transition: 0.5s left ease-in-out;
    }
    &_href {
      img {
        cursor: pointer;
      }
    }
  }
 
}

.ToCurr {
  left: 0;
  transition: 0.5s;
}

.ToBack {
  transition: all ease-out 0.5s;
  left: 100%;
}

.ToNext {
   transition: all ease-out 0.5s;
  left: 0;
}