@import '../../utils/global.scss';

.Login {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  &-Wrapper {
    max-width: 600px;
    min-height: 260px;
    // margin-top: -130px;
    width: 100%;
    // margin: auto;
    position: relative;
  }

  &-ErrorMessage {
    position: absolute;
    bottom: 10px;
    left: 50%;
    background: $ErrorColor;
    color: white;
    padding: 2%;
    transform: translateX(-50%);
    width: 100%;
    font-family: "Open Sans", Arial, Helvetica, sans-serif;

    &::after {
      content: '';
      display: block;
      position: absolute;
      bottom: -9px;
      margin-left: 2%;
      border-width: 10px 10px 0px 10px;
      border-style: solid;
      border-color: transparent;
      border-top-color: $ErrorColor;
    }
  }

  &-Form {
    position: relative;
    z-index: 2;
    background-color: $GrayBackground;
    box-sizing: border-box;
    border: 1px solid $BorderColor;

    .Input,
    .Checkbox {
      border-top: 1px solid $BorderColor;
    }

    &-Text {
      padding: 15px 15px;
      position: relative;
    }

    &-Actions {
      direction: rtl;
      padding: 0 15px 15px;

      .Button {
        padding: 10px 4% 11px;
      }
    }
  }

  &-Spinner {
  }
}

@media screen and (max-width: 600px) {
  .Login {
    .Banana {
      transform: scale(-1, 1);
      right: -120px;
    }

    &-Wrapper {
      left: 0;
      margin-left: 0;
    }
  }
}
