.CourseMyNew {
    // padding: 24px;

    &-Loading {

        min-height: 200px;

        &-Box {
            justify-content: center;
            display: flex;
            align-items: center;
            height: 200px;
        }
    }

    &-Header {
        display: flex;
        padding: 0 0 8px 0;
        align-items: center;

        &-Pagination {
            font-size: 12px;
            display: flex;
            align-items: center;
        }

        &-Title {
            margin: 6.5px 0;
            font-size: 16px;
            line-height: 116%;
        }

        &-Pagination {
            margin-left: auto;

            &Control {
                height: 24px;
                border: none;
                padding: 0;
                background-color: transparent;
                outline: none;
                cursor: pointer;
                color: var(--border-color);
            }

            &>span {
                color: #999;
                // font-size: 11px;
                font-weight: lighter;
            }
        }
    }
}

.CourseMyNewNoData {
    // border-radius: 8px;
    // border: 1px solid #d3d9e0;
    background-color: #fff;
    // padding: 24px;

    &-Header {
        margin: 0 0 8px 0;
        padding: 6.5px 0;
        color: #000;
        border: none;
        text-decoration: none !important;

        &-Title {
            margin-bottom: 8px;
            font-weight: 700;
            font-size: 16px;
            line-height: 116%;
            letter-spacing: 0.15px;
            padding: 6.5px 0;
            margin-bottom: 8px;
            color: rgba(0, 0, 0, 0.87) !important;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            width: 245px
        }

        &-Text {
            font-size: 14px;
            line-height: 20.02px;
            width: 100%;
            margin-bottom: 12px
        }

    }
}