@import "../../../../utils/global.scss";

.WikiItemNew:hover {
  .WikiItemNew-Actions_Hedaer {

    opacity: 1;
  }

  .WikiItemNew-Actions_Favorite {

    opacity: 1;
  }
}

.WikiItemNew {
  // margin-bottom: 24px;
  background: #FFFFFF;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12);
  // box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px rgba(0, 0, 0, 0.14), 0px 1px 14px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  position: relative;

  // min-width: 255px;
  // max-width: 255px;
  // margin-bottom: 24px ;
  // margin-right: 24px;
  &-WikiName {
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: 0.15px;
    color: rgba(0, 0, 0, 0.87);
    text-align: left;

  }

  &-Picture {
    &-BTN {
      width: 100%;
      // height: 245px;
      overflow: hidden;
      text-align: center;
      display: flex;
      align-items: center;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;

      // .Avatar_shape_rectangle {
      //   height: 188px;

      //   .Avatar-Img_shape_rectangle,
      //   .Avatar-Letter_shape_rectangle {
      //     border-radius: 4px;
      //     height: 188px;
      //     width: 100%;
      //   }
      // }
    }

    &-BTNNoImg {
      width: 100%;
      // height: 245px;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;

      // .Avatar_shape_rectangle {
      //   height: 188px;

      //   .Avatar-Img_shape_rectangle,
      //   .Avatar-Letter_shape_rectangle {
      //     border-radius: 4px;
      //     height: 188px;
      //     width: 100%;
      //   }
      // }


    }

    &-Img {
      width: 100%;

      .Avatar_shape_rectangle {
        height: 188px;

        // .Avatar-Img_shape_rectangle,
        // .Avatar-Letter_shape_rectangle {
        //   border-radius: 4px;
        //   height: 188px;
        //   width: 100%;
        // }
      }


    }

    &-Img1 {
      width: 60%;

      .Avatar_shape_rectangle {
        height: 188px;

        // .Avatar-Img_shape_rectangle,
        // .Avatar-Letter_shape_rectangle {
        //   border-radius: 4px;
        //   height: 188px;
        //   width: 100%;
        // }
      }
    }
  }



  // &-CreationDate {
  //   font-weight: 400;
  //   font-size: 12px;
  //   line-height: 166%;
  //   letter-spacing: 0.4px;
  //   color: rgba(0, 0, 0, 0.38);

  // }

  &-Settings {
    background-color: var(--background-color);
    color: var(--main-color);
    font-size: 24px;
    font-weight: 600;
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 5px;
    line-height: 1.4em;
    position: absolute;
    top: 15px;
    // right: 15px;
  }

  &-Actions_Hedaer {
    display: flex;
    justify-content: flex-end;
    position: absolute;
    right: 8px;
    top: 8px;
    z-index: 10;
    opacity: 0;
  }

  &-Actions_Favorite {
    display: flex;
    justify-content: center;
    position: absolute;
    left: 8px;
    top: 8px;
    z-index: 10;
    // opacity: 0;
  }
}