@import "../../../utils/global.scss";

.WorkflowsLayout {
  &-Content {
    padding: 0;
  }

  div &-Description {
    border-bottom: 1px solid $BorderColor;
    padding: 0 24px 12px 24px;
    &:empty {
      padding: 0px;
      // display: none;
    }
  }

  &-Fields {
    & > div {
      padding: 0 24px;
      border-bottom: 1px solid $BorderColor;
      & > div {
        margin: 12px 0;
        &:first-child {
          font-size: 16px;
          font-weight: 700;
        }
      }
    }
  }
}
