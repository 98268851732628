.CourseAdmin {
  display: flex;
  &-Info {
    margin-left: 12px;
    line-height: 2;
  }
  &-Name {
    color: var(--main-color);
  }
  &-Description {
    color: var(--text-muted-color);
    margin: 0;
  }
}
