.SwipeMenu {
  height: 6px;
  display: flex;
  width: 100%;
  margin-bottom: 8px;

  &-Item {
    margin: 0 2%;
    width: 100%;
    background: #fff;
    border-radius: 5px;
    cursor: pointer;
  }
}