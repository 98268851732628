.ActionsBox {
  border: 2px solid #f0c419;
  border-radius: 8px;
  padding: 12px 24px;
  width: fit-content;
  margin: 12px auto 0 auto;
  display: flex;
  @media (max-width: 576px) {
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 12px;
    padding: 12px;
  }

  &-Responsible {
    display: flex;
    flex-direction: column;
    align-items: center;
    & > span {
      font-weight: bold;
      margin-bottom: 4px;
      svg {
        font-size: 21px;
        margin-left: 6px;
      }
    }
  }

  &-Status {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &-Status {
    margin-right: 8px;
    &-Select {
      width: 250px;
    }

    &-Title {
      display: flex;
      align-items: center;
      margin-bottom: 4px;
      & > span {
        font-weight: bold;
      }
      & > svg {
        color: #000;
        margin-left: 6px;
        font-size: 21px;
        margin-left: 6px;
      }
    }

    &-Select {
      border-radius: 4px;
      & > div {
        color: inherit;
        & > div {
          padding: 12px;
        }
        & > svg {
          color: inherit;
        }
      }

      .Mui-disabled {
        background: #fff;
      }
    }

    @media (max-width: 576px) {
      margin: 0;
      margin-bottom: 12px;
    }
  }

  &-Responsible {
    margin-left: 8px;
    & > span {
      display: flex;
      align-items: center;
      cursor: pointer;
    }
    @media (max-width: 576px) {
      margin: 12px 0;
    }

    &-Empty {
      margin: auto;
      text-align: center;
      font-weight: 700;
    }

    .UserList {
      line-height: 1;
      & > li {
        padding: 0 3px;
      }
    }
  }
}
