.ReplyEditor {
    .ReplyEditor-CloseThank {
        display: block;
        font-size: 24px;
    }

    .ReplyEditor-Thanks {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 24px;
        border-bottom: 1px solid #d3d9e0;
    }

    .ReplyEditor-ThanksTitle {
        margin: 0;
    }

    .Reply-Editor {
        background-color: #fff;
        border-radius: 8px;
    }

    .DraftEditor-root .DraftEditor-editorContainer {
        min-height: auto;
        /* width: 100%; */
    }
}