.ChatList {
  list-style: none;
  padding: 0;

  &-Item {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 3px 24px;
    border-top: 1px solid #ccc;
    &:hover {
      background-color: #f7f7f7;
    }

  }
    .unread {
      background:  #f2f9ff;
      border-top: 1px solid #ccc;
      color: rgb(5, 5, 5);
      font-weight: bold;
      &:hover {
        background-color: #f7f7f7;
      }
  }
}
.ChatList-Count {
  margin-left: 6px;
  color: #000;
  font-weight: normal;
  &:empty {
    display: none;
  }
  .Bable{
    background: #979797;
    color: #fff;
    font-size: 11px;
    line-height: 8px;
    padding: 5px;
  }
}
.ChatList-AvatarBlock{
  position: relative;
}
.ChatList-Content {
  overflow: hidden;
  flex: 1;
  // border-bottom: 1px solid #ccc;
  padding: 0px 10px 14px;
}

.ChatList-Message {
  font-weight: normal;
  text-overflow: ellipsis;
  line-height: normal;
  white-space: nowrap;
  overflow: hidden;
  padding-left: 20px;
}

.ChatList-Mtime {
  font-weight: normal;
}

.ChatList-Type {
  width: 20px;
  height: 20px;
  display: flex; 
  align-items: center;
  .ChatList-TypeC,
  .ChatList-TypeP{
    color: #979797;
  }
  svg{
    width:20px;
  }
}

.ChatList-Title {
  display: flex;
  align-items: center;

}

.ChatList-Uname {
  font-weight: bold;
}
.ChatList-Sname,
.ChatList-Cname {
  font-weight: bold;
  font-size: 16px;  
  flex: 1;
  display: flex;
  color: #979797;

}
.ChatList-Sname{
  color: #000;
}
.ChatList-Status {
  position: absolute;
  width: 10px;
  height: 10px;
  border: 2px solid #fff;
  top: 37px;
  left: 42px;
  border-radius: 50%;
  &.ChatList-Status_offline {
    background-color: #000;
  }
  &.ChatList-Status_online {
    background-color: #0f0;
  }
  &.ChatList-Status_away {
    background-color: #ff0;
  }
  &.ChatList-Status_busy {
    background-color: #f00;
  }

}