.ThanksInput {
    display: flex;
    justify-content: center;

    .input {
        text-align: center;
    }
}

.SelectReasonsBox {
    overflow-y: auto;
    height: 50vh;
}