.Reply-Actions {
  padding-left: 56px;
  margin-top: 8px;
  line-height: 24px;
  padding-left: 0;
  .Button {
    color: #999;
    margin-left: 4.71693%;

    &:first-of-type {
      margin-left: 0;
    }
  }
  .Reply-Actions-BtnLikeNum {
    margin-left: 8px;
  }
  .Icon {
      margin-right: 8px;
  }
}