.TrackInfo {
    background-color: #fff;
    padding: 12px 24px 0px 24px;
    border: 1px solid #d3d9e0;
    border-radius: 8px;
    &-Section {
    }
    &-Avatar {
        margin-right: 24px;
    }
    &-AvatarEdit {
        margin-right: 24px;
        width: 64px;
    }
    &-Content {
        display: flex;
        align-items: center;
    }
    &-EditActions {
        margin-top: 12px;
    }
    &-RightActions {
        display: flex;
        justify-content: space-between;
    }
}
