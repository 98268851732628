.NotificationsSettingsGroup{
      &-Title{
        font-size: 24px;
      }
      &-GroupName {
          padding-left: 6px;
          font-weight: bold;
      }
      &-saveButton{
        color: #fff;
        background: #0066CC;
        margin-left: 91%;
        margin-top: 20px;
      }
}