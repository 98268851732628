.IdeaField{
  &-Textarea{
    width:97%;
      .Icon{
        color: #999999;
    }
  }
  &-HelpTooltip{
    position: absolute;
    top: 46px;
    right: 3px;
    z-index: 0;
    cursor: default;
  }
}