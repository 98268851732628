.ShopProduct {
  margin-top: 24px;
  padding: 24px;

  &-Info-DescMore {
    font-weight: bold;
    text-decoration: underline;
  }

  &-NotAvailable {
    color: var(--danger-color);
    margin-top: 24px;
  }

  &-Main {
    display: flex;
    margin-top: 6px;
    @media (max-width: 500px) {
      display: block;
    }
  }

  &-Comments {
    margin-left: -24px;
    margin-right: -24px;
    margin-top: 24px;

    &_allowFeedback {
      margin-bottom: -24px;
    }
  }

  &-CommentsDisabled {
    margin-top: 24px;
  }

  &-Photos {
    margin-right: 57px;
    width: 40%;
    @media (max-width: 500px) {
      margin-right: 0;
      width: unset;
    }
  }

  &-Info {
    width: 50%;
    @media (max-width: 500px) {
      margin-top: 24px;
      width: unset;
    }
    &-Header {
      span:nth-child(1) {
        display: block;
        font-size: 24px;
        line-height: 1;
        margin-bottom: 6px;
      }

      padding-bottom: 24px;
      border-bottom: 1px solid #d3d9e0;
    }

    &-Delivery {
      padding: 24px 0;
      border-bottom: 1px solid #d3d9e0;

      span {
        font-weight: bold;
      }
    }

    &-Rating {
      display: flex;
      padding-bottom: 12px;
      padding-top: 12px;
      border-bottom: 1px solid #d3d9e0;
      align-items: center;
    }

    &-CharacteristicsBox {
      display: flex;
      padding-bottom: 12px;
      padding-top: 12px;
      border-bottom: 1px solid #d3d9e0;
    }

    &-Prise {
      display: flex;
      align-items: center;
      padding: 24px 0;
      border-bottom: 1px solid #d3d9e0;

      &-Img {
        border-radius: 40px;
        background: #194776;
      }

      &>span {
        margin-right: 6px;
        font-size: 24px;
        margin-top: -6px;
        margin-left: 17px;
      }
    }

    &-Order {
      margin-top: 24px;
      padding: 12px 73px;

      &.Button_main:disabled {
        pointer-events: none;
      }
    }

    &-Missing {
      color: var(--danger-color);
      margin-top: 24px;
      font-size: 16px;
    }
  }

  &-CategoryProducts {
    margin-top: 24px;
  }
}

@media all and (-ms-high-contrast: none) {
  .ShopProduct {
    &-Info {
      &-Order.Button_main:disabled {
        pointer-events: auto;
      }
    }
  }
}