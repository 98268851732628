.EventEditor {
  position: relative;
  &-SelectCalendarBox {
    display: flex;
    align-items: center;
    padding-right: 24px;
    border-bottom: 1px solid #d3d9e0;
  }
  &-SelectCalendar {
    flex: 1;
  }
  &-Preloader {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: .4;
    background-color: #000000;
    left: 0;
    top: 0;
    z-index: 200;
  }
  .EventEdit-Expires {
    display: flex;
    // border-top: 1px solid #D3D9E0;
    border-bottom: 1px solid #d3d9e0;
    background: #fff;
  }

  .EventEdit-MaxParticipantsCount {
    display: flex;
    align-items: center;
    padding: 5px 24px;
    gap: 16px;

    input {
      width: 50px;
    } 
  }

  .EventEdit-Block {
    border-top: 1px solid #d3d9e0;
  }

  .EventEdit {
    &-Checkboxes {
      background-color: var(--background-highlight-color);
      padding-top: 12px;
      padding-bottom: 12px;

      &:empty {
        padding-top: 0;
        padding-bottom: 0;
      }

      .HelpTooltip {
        text-decoration: none;
        margin-left: 10px;
      }
    }
  }

  .EventEdit-Users {
    border-bottom: 1px solid #d3d9e0;
  }

  .EventEdit-TextareaMentions {
    border-top: 1px solid #d3d9e0;
  }

  &-Title {
    border-bottom: 1px solid #d3d9e0;
  }

  .EventEdit-ExpiresItem {
    flex: 1 1;
    align-items: center;
    display: flex;
  }

  .EventEdit-Datapicker {
    border-right: 1px solid #d3d9e0;

    .InputLayout-IconZone {
      .Icon {
        color: var(--text-muted-color);
      }
    }
  }

  .EventEdit-Timepicker {
    border-right: 1px solid #d3d9e0;
  }

  .EventEdit-Select {
    .Select-Inner {
      background-color: transparent;
    }
  }

  .PryanikyEditor {
    border-top: 1px solid #d3d9e0;
    border-bottom: 1px solid #d3d9e0;
  }
}
