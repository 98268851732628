.NewsTimelineHeader {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    margin-top: 24px;
    padding: 0 24px;

    &-Title {
        font-weight: bold;
        // font-size: 14px;
        // line-height: 24px;
        font-size: 16px;
        line-height: 116%;
        padding-left: 6.5px;
    }

    &-Navigation {
        display: flex;
        // height: 100%;
        align-items: center;
        // margin-right: 8px;
    }

    &-Indicator {
        min-width: 25px;
        text-align: center;
        // padding: 0 4px;
        // padding-bottom: 2px;
        font-weight: 400;
        font-size: 12px;
        // line-height: 159%;;
        line-height: 166%;
        // // height: 17px;

        span {
            color: #999;
        }
    }

    &-Button {
    }
}
