.ImageLoaderMui {
  position: relative;
  display: flex;
  align-items: center;
  padding: 12px 0;

  &-Block.ImageLoaderMui-Block_preview {
    // flex: 1;
    height: 72px;
    width: 72px;
    padding: 0;
    margin-right: 8px;

    &_preview {
      position: relative;
      flex: 0 0 72px;
    }

    &:nth-child(n + 2) {
      margin-left: 20px;
    }
  }

  &-Title {
    font-weight: bold;
    text-transform: uppercase;
    font-size: 15px;
    line-height: 26px;
    letter-spacing: 0.46px;
    text-transform: uppercase;
    color: #0066CC;
  }

  &-Description {
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    // display: flex;
    // align-items: center;
    letter-spacing: 0.4px;
    color: rgba(0, 0, 0, 0.6);
  }

  &-Block.ImageLoaderMui-Block_preview:hover {
    border-radius: 50%;
  }

  &-Progress {
    position: absolute;
    top: 0;
    left: 0;
    width: 72px;
    height: 72px;
    box-sizing: border-box;
    border: 1px solid #e7e7e7;
    transition: border-width .3s linear;
    display: flex;
    align-items: center;
    justify-content: center;


    &_circle {
      border-radius: 50%;
    }

    &-Icon {
      font-size: 45px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      &_success {
        color: #3dd13d;
      }

      &_error {
        color: red;
      }
    }
  }

  // &-ProgressBar {
  //   position: absolute;
  //   top: 50%;
  //   left: 50%;
  //   width: 0%;
  //   height: 0%;
  //   transform: translate(0%, 0%);
  //   border-radius: 50%;
  //   background-color: #e7e7e7;
  //   transition: width .3s linear, height .3s linear, transform .3s linear;
  // }
}