@import '../../../utils/global.scss';

.NewsBaseView {
    background-color: #fff;
    @include BorderRadius;
    border: 1px solid #d3d9e0;
    position: relative;
    padding-top: 24px;
    padding-bottom: 24px;
    margin-bottom: 24px;
}