.BirthdayWidget {
    padding: 24px;
    &-Navigate {
        font-size: 14px;
        display: flex;
        height: 23px;

        &-Item {
            cursor: pointer;
            color: #d3d9e0;
            height: 23px;
        }
    }
    // padding-top: 36px;
    // background-image: url('../../assets/svg/confetti.svg');
    // background-position: center -40px;
    // background-repeat: repeat-x;
    // line-height: 1.3;
    &-Header {
        &-Title {
            font-family: 'Open Sans', Arial, Helvetica, sans-serif;
            text-transform: none;
            font-weight: 700;
            font-size: 16px;
            line-height: 116%;
            letter-spacing: 0.15px;
            margin: 6.5px 0;
        }

        &-Text {
            font-size: 14px;
            line-height: 20.02px;
            width: 100%;
            margin-bottom: 12px;
        }

        &-Description {
            font-weight: 400;
            font-size: 12px;
            line-height: 166%;
            letter-spacing: 0.4px;
            color: rgba(0, 0, 0, 0.87);
            margin-bottom: 8px;
        }

        &-Image {
            img {
                width: 100%;
                max-width: 100%;
            }
        }

        &-Total {
            font-weight: 400;
            font-size: 14px;
            line-height: 143%;
            letter-spacing: 0.15px;
            color: rgba(0, 0, 0, 0.6);
        }
    }

    &-Input_count {
        position: relative;

        .Input-Input {
            text-align: center;
        }
    }

    &-Button_count_up,
    &-Button_count_down {
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
    }

    &-Button_count_up {
        left: 75%;
    }

    &-Button_count_down {
        left: 25%;
    }

    &-User {
        display: flex;
        align-items: center;
        margin-top: 16px;

        &-Info {
            margin-left: 16px;
        }

        &-Position {
            margin: 0;
        }

        &-Link {
            font-weight: bold;
            color: var(--main-color);
        }

        &-Gift {
            margin-top: 4px !important;
            font-weight: 500;
            font-size: 13px;
            line-height: 22px;
            letter-spacing: 0.46px;
        }
    }

    &-Buttons {
        display: flex;
        justify-content: space-between;
        margin-top: 12px;

        &-OtherDaays,
        &-ShowMore {
            margin-left: 52px;
            margin-top: 16px;
            display: block;
            text-align-last: left;
        }
    }
}
