.Unit {
  &-Header {
    background-color: var(--background-color);
    // padding: 24px;
  }

  &-Title {
    margin: 0;
  }

  &-Header {
    margin-bottom: 24px;
  }

  &-Section {
    display: flex;
  }

  &-Content {
    flex: 28;
  }

  &-Aside {
    flex: 10;
  }
}