.LMSLessonNavigation {
    font-weight: normal;
    font-size: 20px;
    line-height: 27px;
    padding: 24px;
    border-top: 1px solid #d3d9e0;
    display: flex;
    justify-content: space-between;

    &-Btn {
        color: #0066cc;
    }
}

.fullscreen {
    .LMSLessonNavigation {
        position: sticky;
        bottom: 0;
    }
}