.DReadItem {
  padding: 11px 24px;
  position: relative;

  &-Name {

    .Icon {
      margin-right: 5px;
    }
  }

  &-Remove {
    position: absolute;
    right: 24px;
    top: 6px;
    padding: 5px;
  }

  &-Progress {
    position: absolute;
    left: 0;
    bottom: 0;
    height: 4px;
    width: 0;
    transition: .1s width ease-in-out;
    background-color: darkgrey;

    &_success {
      background-color: green;
    }

    &_error {
      background-color: red;
    }
  }
}