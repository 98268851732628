.RatingsWidget {
  padding: 16px;
  border-radius: 8px;
  border: none;
  box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
  border-radius: 4px;

  &-Header-Title {
    margin-bottom: 8px;

    span {
      margin: 6.5px 0;
      font-size: 16px;
      font-weight: 700;
      color: #000;
    }
  }

  &-Title {
    margin-top: 0;
    margin-bottom: 8px;
  }

  &-Item {
    padding: 8px 0;
    line-height: normal;
    display: flex;
  }

  &-GroupName {
    margin: 6px 0;
    font-weight: 600;
  }

  &-Name {
    color: var(--text-color);
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    max-width: 100%;
    margin: 0;
    text-overflow: ellipsis;
    flex: 3;
    min-width: 120px;
    font-weight: normal;
    font-size: 14px;
  }

  &-Icon {
    // font-size: 50px;
    // line-height: 72px;
    // text-align: center;
    margin-right: 12px;

    .Avatar-Img {
      background-color: transparent;
    }
  }



  &-RatingBox {
    flex: 1;
    overflow: hidden;
  }

  &-ProgressBar {
    width: 100%;
  }

  &-Value {
    font-weight: bold;
    margin: 0;
    text-align: right;
    flex: 1;
    min-width: 120px;
  }

  &-Description {
    display: flex;
    flex-wrap: wrap;
  }

  .small {

    .RatingsWidget-Icon {
      margin-right: 12px;

      &:empty {
        display: none;
      }
    }

    .RatingsWidget-RatingBox {
      display: flex;
      margin-bottom: 10px;
    }

    .RatingsWidget-Name {
      font-size: 14px;
      font-weight: normal;
      line-height: 16px;
      line-height: 143%;
    }

    .RatingsWidget-Description {
      display: block;

    }

    .RatingsWidget-Value {
      text-align: left;
      font-size: 14px;
      line-height: 15px;
    }

    .RatingsWidget-Item {
      display: block;
    }

    .RatingsWidget-GroupName {
      font-weight: bold;
      font-size: 14px;
      line-height: 19px;
      margin: 0;
      margin-bottom: 8px;
    }

  }
}

.RatingsWidgetNoData {
  border-radius: 8px;
  border: 1px solid #d3d9e0;
  background-color: #fff;
  padding: 24px;

  &-Header {
    margin: 0 0 8px 0;
    padding: 6.5px 0;
    color: #000;
    border: none;
    text-decoration: none !important;

    &-Title {
      margin-bottom: 8px;
      font-weight: 700;
      font-size: 16px;
      line-height: 116%;
      letter-spacing: 0.15px;
      padding: 6.5px 0;
      margin-bottom: 8px;
      color: rgba(0, 0, 0, 0.87) !important;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      width: 245px
    }

    &-Text {
      font-size: 14px;
      line-height: 20.02px;
      width: 100%;
      margin-bottom: 12px
    }

  }
}