.NewsActionHeader {
    background-color: #FFF3BD;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    display: flex;
    align-items: center;
    padding: 8px 24px;
    border-bottom: 1px solid #d3d9e0;
    margin-bottom: 24px;

    &-Description {}

    &-Avatar {
        margin-right: 16px;
    }

    &-Mention {
        margin-right: 4px;
    }

    &-Text {
        display: inline-block;
    }
}