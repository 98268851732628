.ActionsLayout {
    display: flex;
    align-items: center;

    &-Master {
        display: flex;
        margin-left: 0;
        margin-right: auto;

        align-items: center;
        position: relative;
        padding: 10px 12px;

        @media (min-width: 576px) {
            padding: 14px 24px;
        }

        line-height: 24px;
        min-height: 24px;

        .Button {
            color: #999;
            margin-left: 6px; //4.71693%;
            display: flex;

            &:first-of-type {
                margin-left: 0;
            }
        }

        .Icon {
            margin-right: 8px;
        }

        .ActionButton {
            margin-right: 40px;

            .Button {
                text-transform: capitalize;
            }
        }
    }

    &-Slave {
        margin-right: 24px;
    }

    &-Info {
        position: relative;
        padding: 10px 12px;

        @media (min-width: 576px) {
            padding: 14px 24px;
        }
        
        .Button {
            color: #999;
        }
    }

}