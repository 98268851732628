.AdditionalFieldsRender {
    &-Category {}

    &-CatName {
        background: #f7f7f7;
        padding: 9px 24px;
        border-bottom: 1px solid #d3d9e0;
    }

    &-Item {}

    &-Name {
        // border-bottom: 1px solid #d3d9e0;
        padding-left: 24px;
        margin: 8px 0 0;
    }
    &-Field{
        display: flex;
        justify-content: space-between;
        margin-right: 24px;
        align-items: center;
        & > div {
            width: 100%;
        }
        .Icon{
            color: #999999;
        }
        div &-Help {
            width: 20px;
        }
    }
    &-CollapseBox {

    }
    .CollapseBox-Content {
        border-bottom: 1px solid #d3d9e0;
        .LineInput{
            margin: 0 9px;
            padding: 5px 0;
        }
    }
    // .CollapseBox-Content:last-child input{
    //     margin: 0;
    //     padding: 5px 24px;
    // }
    &-CollapseBox-Toggle {
    display: flex;
    cursor: pointer;
    background: #f7f7f7;
    padding: 9px 24px;
    border-bottom: 1px solid #d3d9e0;
    }

    &-CollapseBox-Toggle-Text {
    margin: 0;
    flex: 1 1;
    }

    &-CollapseBox-Toggle-Icon {

    }
    input{
        margin: 0;
        padding: 5px 3px 5px 24px;
        width: 93%;
        border: none;
    
    }
    .HelpTooltip{
        text-decoration: none;
    }
    .Input-Input{
        margin-left: 0;
        width:100%;
    }
    .EditorPolls-Datepicker{
        border-top: none;
    }
    .Select-Icon{
        margin: 0 0;
        padding: 0 8px 0 0;
    }
    .Select{
        padding: 9px 22px;
    }
    .Select-Inner{
        padding: 0px;
    }
    .MuiFormControl-root{
        margin: 0;
        width: 100%;

        .Icon_hash-tag:before {
        content: '\ee2e';
        margin-left: -1px;
        // color: #aab2c6;
        }  
        .makeStyles-formControl-2{
            padding-right: 15px;
        }
    }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .AdditionalFieldsRender {
        .HelpTooltip{
            text-decoration: none;
            cursor: default;
        }
    }

}