.Reply-Header {
    display: flex;

    .Reply-Header-Data {
        display: flex;
        align-items: center;
        padding-left: 16px;

        .Reply-Header-Data-User {
            margin-right: 16px;
        }

        .Reply-Header-Data-Date {
            color: #979797;
        }
    }

    .Button {
        vertical-align: baseline;
        color: #0066cc;
        font-weight: bold;
    }
}