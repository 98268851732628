.AboutWidget {
    padding: 24px;
    &-Header {
        border-bottom: 1px solid #ccc;
        display: flex;
        justify-content: space-between;
    }
    &-Title {
        margin: 0;
        
        flex: 1;
    }
    &-AttachSection {
        margin: 12px 0;
    }

    &-Attachments {
        padding: 12px 0;
    }
}