.JFLayout {
  .CheckBox {
    margin: 0 24px;
    .MuiButtonBase-root.MuiIconButton-root {
      color: rgba(0, 0, 0, 0.54);
    }
  }

  &_vertical {}

  &_horizontal {
    display: flex;
  }

  .InputCount-Placeholder {
    margin-top: 10px;
    padding: 0 34px;
  }

  .SliderSettings {

    &Sortable {

      &-Handle {
  
      }
    }

    .SliderItem {
      position: relative;
      display: flex;
      align-items: center;
  
      &-Button {
  
        &_remove {
          position: absolute;
          top: 10px;
          right: 10px;
        }
  
        &_add {
          width: 100%;
        }
      }
  
      &-Checkbox {
        margin: 0;
        padding: 0;
  
        .MuiButtonBase-root.MuiIconButton-root {
          color: rgba(0, 0, 0, 0.54);
        }
      }
    }
  }

  .VirtualUserItem {
    position: relative;

    .Input {
      width: 100%;
    }

    &-Button {

      &_remove {
        position: absolute;
        top: 6px;
        right: 10px;
      }

      &_add {
        width: 100%;
      }
    }
  }

  .PryanikyEditorV1 {
    padding: 24px;
  }

  .InputMUI {
    padding: 24px;

    &>.MuiInputLabel-root {
      margin-top: 24px;
      margin-left: 24px;
    }
  }

  .MuiAutocomplete-root {
    margin: 24px;

    .MuiFormControl-root {
      padding: 0;
    }
  }

  .JF-UsersListBlockItem-Header {
    display: flex;
    margin-right: 24px;

    .Input {
      flex: 1;
    }
  }

  .StatisticControl {
    display: flex;
    flex-direction: column;
    gap: 12px;

    &>div {
      margin: 0;
      padding: 0;
    }

    .InputMUI {
      &>.MuiInputLabel-root {
        margin-top: 0px;
        margin-left: 0px;
      }
    }
  }

  .TagsCloud-Tags {
    &>div {
      padding: 24px 0;
    }
  }
}

@media screen and (-ms-high-contrast: active),
(-ms-high-contrast: none) {
  .JFLayout {
    &_horizontal {
      flex-direction: column;

      .Radio {
        height: 30px !important;
      }
    }
  }
}