.Files {
    background-color: #ffffff;
    position: relative;
    border-radius: 8px;

    &-Header {

    }

    &-MoreBox {
        display: flex;
        justify-content: center;
        width: 100%;
        padding: 24px 0;
        position: relative;

        &:empty {
            display: none;
        }
    }
    
    &-Content {
        padding-bottom: 12px;
    }
}