.UserfieldsWidgetStyle {
  &-EditContent {
    display: flex;
    flex-direction: column;

  }

  &-Header {
    &-Title {
      margin: 6.5px 0;
      font-weight: 700;
      font-size: 16px;
      line-height: 116%;
      letter-spacing: 0.15px;

    }


  }

  .MuiLink-underlineAlways {
    text-decoration: none;

  }

  &-CatText {
    font-weight: 500;
    font-size: 14px;
    line-height: 157%;
    letter-spacing: 0.1px;
    color: rgba(0, 0, 0, 0.87);

    h4 {
      margin: 8px 0 4px 0;
    }
  }

  &-GrayText {
    font-weight: 400;
    font-size: 12px;
    line-height: 166%;
    letter-spacing: 0.4px;
    color: rgba(0, 0, 0, 0.6);
  }

  &-BlackText {
    font-weight: 400;
    font-size: 12px;
    line-height: 166%;
    letter-spacing: 0.4px;
    color: rgba(0, 0, 0, 0.87);
  }

  &-EditBtns {
    // position: absolute;
    // top: 0;
    // right: 0;
    display: flex;
    margin-top: 16px;

    .MuiButton-root {
      font-weight: 500;
      font-size: 13px;
      line-height: 22px;
      letter-spacing: 0.46px;
    }

  }

  &-Fields {
    display: flex;
    flex-direction: column;
  }

  .InfoBlock-User-Col1 {
    width: 100px;
  }
}