.TOStudentBasicItem {
    display: flex;
    padding: 12px;

    &:hover {}

    &-AvatarBox {
        // flex: 1 5;
        margin-right: 24px;
    }

    &-Avatar {}


    &-Title {
        font-family: "Open Sans";
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 24px;
        color: #0066cc;
    }
}