.AnswerItem {
  cursor: pointer;
  display: flex;
  align-items: center;
  margin: 6px 0;
  &-Right {
    span {
        color: #009D3E!important;
      }
    .MuiFormControlLabel-root {
      span {
        color: #009D3E!important;
      }
    }
  }
  &-Wrong {
    span {
        color: #FF6464!important;
      }
    .MuiFormControlLabel-root {
      span {
        color: #FF6464!important;
      }
    }
  }

  &-CommentText {  
    font-size: 12px;
    p {
      margin: 0;
    }
  }

}