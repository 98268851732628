.CourseStatusesWidget {
    background-color: #fff;
    border-radius: 8px;
    padding: 24px;
    border: 1px solid #d3d9e0;

    // justify-content: space-between;

    &-Box {
        margin: 0 24px;
        @media (max-width: 500px) {
            margin: 0;
            margin-top: 12px;
        }
    }

    &-SelectorBox {
        display: flex;
        justify-content: flex-end;
    }

    &-Content {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
    }

    &-Data {
        display: flex;
        // justify-content: space-between;
    }

    &-Chart {
        // flex: 1;
        width: 120px;
        // margin-left: 24px;
    }

    &-Summary {
        margin-right: 24px;
        // flex: 2;
    }

    &-Table {
        margin-right: 0;
    }

    &-SummaryAnswers {
        font-weight: bold;
    }
}
