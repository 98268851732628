@import '../../../../utils/global.scss';

.TrackItem {
    background-color: #fff;
    @include BorderRadius;
    // overflow: hidden;
    border: 1px solid #d3d9e0;
    box-shadow: 0px 0px 10px -5px rgba(0, 0, 0, 0.3);
    position: relative;

    margin-bottom: 24px;

    &-Appointed {
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        background-color: #FFF3BD;
        margin-bottom: 24px;
        padding: 8px 24px;
    }
    
    &-Actions {
        display: flex;
        margin-right: 24px;
        align-items: center;
    }

    &-ItemAction {
        margin-left: auto;
    }

    &-isShowOnTop {
    }

    &-isNeedApprove {}

    &-UserImg {
        display: block;
    }

    &-Attachments {
        &:empty {
            display: none;
        }
    }

    .NewsView-Preview {
        margin-top: 15px;

        &:empty {
            display: none;
        }
    }


    &:hover {
        .NewsView-ServiceActions {
            display: block;
        }
    }
}