.NewsShortView {
    background-color: #fff;
    border-radius: 8px;
    border: 1px solid #d3d9e0;
    box-shadow: 0px 0px 10px -5px rgba(0, 0, 0, 0.3);
    position: relative;
    padding-top: 24px;
    margin-bottom: 24px;

    &-isShowOnTop {
        // position: absolute;
        // left: 0;
        // top: 0;
        // text-align: right;
        // z-index: 20;
    }

    &-isNeedApprove {}

    &-UserImg {
        display: block;
    }




    &:hover {
        .NewsView-ServiceActions {
            display: block;
        }
    }
}