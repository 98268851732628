.NotFound {
  text-align: center;
  height: 100%;
  margin: 12em auto 0 auto;
  box-sizing: border-box;

  &-Image {
    display: inline-block;
    max-width: 100%;
    @media (min-width: 1440px) {
      // transform: translateX(-100px);
    }
  }
  &-ImageRed{
    display: none;
    max-width: 100%;
    @media (min-width: 1440px) {
      // transform: translateX(-100px);
    }
  }
  &-Title {
    font-size: 34px;
    line-height: 123%;
    font-weight: normal;
    margin: 32px 0 0 0;
}
  &-Text {
    font-size: 15px;
    line-height: 150%;
    margin-top: 8px;
 
  }
 
  &-Link {
    text-transform: uppercase;
  }
}