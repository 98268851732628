.Thread {
  display: flex;
  align-items: center;
  padding: 6px 24px;

  &-Deadlines {
    min-width: 200px;
  }
  &-Btn {
    min-width: 140px;
    padding: 2px 12px;
    border-radius: 8px;
    box-sizing: border-box;
    &_type_join {
      // color: #0066cc;
    }
  }
  &-Name {
    font-weight: bold;
    // margin-left: 24px;
    margin-right: 24px;
    text-overflow: ellipsis;
    width: 490px;
    overflow: hidden;
    white-space: pre;
  }
  &-Actions {
    .Button {
      margin: 0;
    }
  }
}
