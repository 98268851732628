.SessionSelectize {
  padding: 12px;
  padding-left: 24px;
  display: flex;
  justify-content: space-between;
  &-Header {
    margin: 0;
  }

  &-Tabs {
    padding: 0;
    display: flex;
    .LMSCourseTabs-TabBtn {
      font-size: 12px;
      margin-left: 12px;
      margin-right: 12px;
    }
  }
}
