.Referral {
  display: flex;
  flex-direction: column;
  padding: 24px;
  gap: 12px;

  &-Title {
    font-size: 16px;
    font-weight: bold;
  }

  .MuiIcon-root {
    width: 40px;
    height: 40px;
  }

  &-Content {
    display: flex;
    line-height: 19.92px
  }

  &-ReferralBtn {
    align-self: flex-start;
  }

  &-Icon {
    border-radius: 50%;
    // border: 3px solid $BorderColor;
    font-size: 50px;
    width: 40px;
    height: 40px;
    margin-right: 16px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 40px;
      height: 40px;
    }
  }
}