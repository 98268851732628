@import "../../../utils/global.scss";

.WikiItemList {
    @include BoxShadow;
    @include Border;
    // @include BorderRadius;
    background-color: var(--background-color);
    // margin-bottom: 24px;
    box-sizing: border-box;
    position: relative;
    // overflow: hidden;
    z-index:0;
    min-width: 40%;
    display:flex;
    align-items: center;
      h3{
        font-size: 20px;
        text-align: left;
        margin: 10px 0 0 15px;
        word-break: break-word;
    }
    &-SearchMarkdown{
      color: #000;
      margin: 0 0 6px 15px;
      text-align: left;
      span.CommonmarkRender-Mark{
        background-color: #ffeb3b8a!important;
        padding: 0 5px;
      }
    }
    &-ReadAndEdit{
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
    &-ActionsToggle{
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      background-color: var(--background-color);
      color: #fff;
      border-left: 1px solid #000!important;
      display: flex;
      align-items: center;
    }
    &-TypeUrl{
      width: 232px;;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    &-Description{
        width: 75%;
    }
    // &-DescriptionTypeUrl{
    //   width: 75%;
    //   margin-left: 124px;
    //   align-items: center;
    //   display: flex;
    // }
    &-DescriptionTypeUrlWithIMG{
      width: 75%;
      align-items: center;
      display: flex;
    }
    &-Picture{
     &-BTN{
        height: 95px;
        width: 100px;
        overflow: hidden;
        display: flex;
        border-radius: 50%;
        // align-items: center;
        justify-content: center;
        margin-left: 24px;
      } 
      &-BTNUrl{
        height: 95px;
        width: 100px;
        overflow: hidden;
        display: flex;
        border-radius: 50%;
        justify-content: center;
        margin: 5px 0 5px 24px;
      }

      &-BTNNoImg{
        height: 95px;
        width: 100px;
        overflow: hidden;
        display: flex;
        border-radius: 50%;
        // align-items: center;
        justify-content: center;
        margin-left: 24px;
      }
      &-Img{
        width: 100%;
        height: auto;
      }
      &-Img1{
        width: 100%;
        height: auto;
        background: #f2f1f1;
        padding-top:6px;
      }
      &-Img2{
         width: 115%;
        height: auto;
      }
    }
    &-CreationDateBox{
        display: flex;
        &-CreationDate{
            margin: 0 0 6px 15px;
            text-align: left;
            font-size: 11px;
            color: #999;
            .Icon{
                margin-right: 5px;
            }
        }
    }
  &-Settings{
     background-color: var(--background-color);
    color: var(--main-color);
    font-size: 24px;
    font-weight: 600; 
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 5px;
    line-height: 1.4em;
    position: absolute;
    top: 15px;
    // right: 15px;
  }
  &-Actions_Hedaer{
    display: flex;
    justify-content: flex-end;
    .Button.Button_background.Button_main.Button_type_rlink.WikiItemList-ActionsToggle {
      color: #fff!important;
    }
    .WikiItemList-ReadAndEdit{
      span{
        color: #fff;
      }
    }
    // position: absolute;
    // right: 10px;
    // top: 10px;
  }
    //  &-ReadAndEdit.Button_border.Button_background.Button_main.Button_type_rlink{
    //   color: #fff;
    // }
  &-Actions_Bottom{
    display: flex;
    justify-content: space-between;
    // border-top: 1px solid #ccc;
    padding: 10px 10px 10px 15px;
    .Actions{
      padding: 0;
    }
  }
  &-Authors{
    display:flex;
    padding-bottom: 10px;
    margin: 0 0 0 15px;
    .AuthorsModerators{
      width: 50%;
      display: flex;
      align-items: center;
      .UserList{
            line-height: 0!important;
              .UserList-Item {
                padding: 0 2px 2px;
                   .Avatar{
                    pointer-events: none;
                  }
              }
      }
    }
    .AuthorsTitle{
    font-size: 12px;
    margin: 0 10px 2px 0;
    }
    .Actions{
          padding: 0 24px 0 0;
    }
  }

  &-AuthorsList{
    .AuthorsList-Wrapper{
      .AuthorsList-Author{
        .AuthorItem-Avatar{
          width: 50px!important;
          .Avatar{
            width: 36px!important; 
            height: 36px!important; 
            min-width: 36px!important;
            pointer-events: none;
          }
          .Avatar-Letter{
              line-height: 33px!important;

          }
        }
      }
    }
  }

  &-ActionsList {
    // position: absolute;
    list-style: none;
    padding: 0;
    background-color: #fff;
    overflow: hidden;
    z-index: 20;
    line-height: normal;
    white-space: nowrap;
  }

  &-ActionsItem {
    padding: 5px 10px;

    .Button {
      display: block;
      width: 100%;
      text-align: left;
    }

    &:hover {
      background-color: $SystemBlue;

      .Button {
        color: #fff;
      }
    }
  }

}