.TOCoursesCardList {
    // background-color: #fff;
    margin-top: 24px;

    &-ToolBox {
        background: #f7f7f7;
        border-radius: 8px;
        flex: 3 4;
        padding: 24px;
        padding-bottom: 8px;
        margin-bottom: 24px;
        border: 1px solid #d3d9e0;
    }

    &-ArchiveLabel {
        font-size: 18px;
        margin-left: 8px;
    }

    &-SearchBox {
        display: flex;
        align-items: center;
        margin-bottom: 12px;
    }

    &-ParamsBox {
        display: flex;
    }

    &-SearchInput {
        max-width: none;
        flex: 1;
    }

    &-Trigger {
        display: block;
        margin-left: auto;
        margin-right: auto;
    }

    &-CreateBtn {
        margin-left: 24px;
        padding: 0px 12px;
        line-height: 24px;
    }
}
