.CourseView {
    // background-color: #ffffff;

    &-Comments {
        display: flex;
        margin-top: 24px;
    }

    &-Section {
        background-color: #fff;
        flex: 3 4;
        // margin-left: 24px;
        border-radius: 8px;
        border: 1px solid #d3d9e0;
    }

    &-Header {
        display: flex;
        background: #F7F7F7;
        align-items: center;
        padding: 24px;
        border-bottom: 1px solid #D3D9E0;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
    }
}