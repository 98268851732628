.VoteFullView {

  .Dialog-Content {
    padding: 24px;
  }

  &-User {
    display: flex;
    align-items: center;
  }

  &-UserName {
    font-size: 40px;
    line-height: 48px;
  }

  &-Avatar {
    margin-right: 24px;
  }

  .Dialog-Actions {
    padding: 0;
  }
}