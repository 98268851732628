.EditorIdeas {
    &-Title {
        border-top: 1px solid #D3D9E0;
    }

    .PryanikyEditor {
        border-top: 1px solid #D3D9E0;
        border-bottom: 1px solid #D3D9E0;
    }
    .EditorField{
        &-Input{
            border: none !important;
            margin: 0px 14px 0px 20px;
            padding: 0px;
            .Icon{
                // margin-right: 0px;
                 color: #999999;
            }
        }
        &-isAnonimus{
             color: hsl(0,0%,50%);
             .CheckboxInput-Text{
                padding-left: 8px;
             }
        }
    }
}