.Poll-ExpiresEnd,
.Poll-Content-Header {
    margin: 24px;
    margin-bottom: 6px;
}

.Poll-Content-Header {
    text-transform: capitalize;
}

.Poll-ExpiresEndDate {
    font-size: 11px;
    color: #999;
}

.Poll-CompleteBox {
    display: flex;
    justify-content: end;
    border-bottom: 1px solid #D3D9E0;
}

.Poll-Complete {
    display: block;
    margin-left: auto;
    margin-right: 24px;
    margin-top: 8px;
    margin-bottom: 8px;
}