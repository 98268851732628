.ThanksInput2 {
    display: flex;
    justify-content: center;
    align-items: center;

    .Input-Btn {
        padding: 4px 7px;
    }

    .Currency-Input {
        outline: 0;

        input {
            text-align: center;
            color: var(--highlight-color);
            font-weight: bold;
            outline: 0;
            font-size: 22px;
            padding: 0;
            -moz-appearance: textfield;
            &::-webkit-inner-spin-button,
            &::-webkit-outer-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }
        }
    }
}
