.SessionsFilter {
  &-Tabs {
    padding-top: 12px;
    padding-bottom: 12px;
    .LMSCourseTabs-TabBtn {
      font-size: 16px;
    }
  }
  // &-Title {
  //   margin: 0;
  //   padding: 12px;
  //   padding-left: 24px;
  // }

  &-Loader {
    position: relative;
    width: 100%;
    height: 50px;
  }

  &-Title {
    padding: 12px;
    padding-left: 24px;
    display: flex;
    justify-content: space-between;
  }
  &-Name {
    margin: 0;
  }

  &-Tabs {
    padding: 0;
    display: flex;
    .LMSCourseTabs-TabBtn {
      font-size: 12px;
      margin-left: 12px;
      margin-right: 12px;
    }
  }
}
