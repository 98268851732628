.WidgetsAdd {
  &-HelpTooltip{
    position: absolute;
    right: 45px;
    top: 41px;
  }
  &-Icon{
    font-size: 20px;
    color: #999999;
  }
  &-Settings{
    .InputMention{
      margin: 10px 24px 0 0;
    } 
    .CheckboxInput{
      margin-left: 9px;}
    .Radio{
      padding: 5px 0 5px 33px;
    }
    .JFLayout{
      .SliderItem-Button_add {
        width: 93%;
        margin: 0 24px 10px;
      }
      .SliderItem-Button_remove {
        position: absolute;
        top: 12px;
        right: 24px;
      }
    }

  }
  &-Selector {
    // white-space: nowrap;
    // overflow-x: auto;
    display: flex;
    flex-wrap: wrap;

    &-Item {
      // height: 100px;
      // width: 150px;
      flex-basis: 25%;
      max-width: 25%;
      height: 100px;
      box-sizing: border-box;
  
      &_selected {
        background: #06c;
        color: #fff;
      }
    }
  }

  &-Back {
    margin-right: 10px;
  }

  &-Body {
    position: relative;
  }
}