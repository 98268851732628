.SliderEventDetailItem {
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;

    &-Title {
        color: #999999;
    }

    &-Value {
    }
}
.SliderEventHeader {
    background-color: #f1543f;

    &-Date {
        font-weight: bold;
        font-size: 24px;
    }
    &-Day {
        font-weight: 300;
        font-size: 14px;
        line-height: 16px;
    }
}
.SliderEvent {
    &-Header {
    }

    &-OpenBtn {
    }

    &-Detail {
    }

    &-DetailItem {
        margin-right: 16px;
    }

    &-Location {
        margin-top: 4px;
    }

    &-Content {
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;
        padding: 24px;
        padding-top: 8px;
        padding-bottom: 16px;
    }

    &-Text {
    }

    &-Footer {
        margin-top: 8px;
    }

    &-Title {
        margin: 4px 0;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 18px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        cursor: pointer;
    }

    &-Preview {
        margin-top: 8px;
        display: block;
        max-width: 100%;
        height: auto;
        width: 100%;
        object-fit: cover;
        max-height: 120px;
    }

    &-Times {
        display: flex;
    }
}
