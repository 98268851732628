.Headers-List {
  list-style: none;
  margin: 0;
  padding: 0;
  margin-bottom: 9px;
  margin-top: 9px;
  border-top: 1px solid #d3d9e0;
}

.Headers-ListItem {
  border-bottom: 1px solid #d3d9e0;
  &:hover {
    background: #ccc;
  }

  &_active {
    background: #ccc;
  }
}

.Headers-Btn {
  background: none;
  border: none;
  width: 100%;
  text-align: left;
  outline: none;
  padding: 0 19px;
  &.header-one {
    font-size: 40px;
    line-height: 48px;
  }

  &.header-two {
    font-size: 32px;
    line-height: 40px;
  }

  &.header-three {
    font-size: 24px;
    line-height: 32px;
  }

  &.header-four {
    font-size: 16px;
    line-height: 24px;
  }

  &.header-five {
    font-size: 14px;
    line-height: 24px;
  }

  &.header-six {
    font-size: 10px;
    line-height: 20px;
  }
}
