.LinkLayout {
    background-color: #f3f3f3;
    display: flex;
    // min-height: 90px;
    align-items: center;
    margin-top: 10px;
    padding: 24px;
    &_clickable {
        cursor: pointer;
    }

    &-Img {
        display: block;
        max-width: 56px;
        width: 56px;
        height: auto;
        border: none;
    }

    &-Text {
        margin-left: 24px;
        flex: 1 1;
        min-width: 0;
    }
    .Markdown {
        p:first-of-type {
            margin-top: 0;
        }
    }
}