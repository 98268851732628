.Blocks {
    position: relative;
    border-radius: 8px;
 
    
    .MuiTabs-scrollButtons {
        position: absolute;
        top: 0;
        height: 100%;
        z-index: 1;
        background: #fff;

        &.Mui-disabled {
            display: none;
        }

        &:first-child {
            left: 0;
        }

        &:last-child {
            right: 0;
        }
    }

    .MuiCard-root,
    .MuiCardActionArea-root {
        height: 100%;
        box-sizing: border-box;
    }

    .MuiTab-root {

        .MuiCard-root {
            border-radius: 0px;
        }
    }
    
    .Button {
        height: 100%;
        text-align: center;
        font-weight: bold;
        padding: 8px;
        display: flex;
        flex-direction: column;

        .MuiCardMedia-root {
            margin-bottom: 8px;
            box-sizing: border-box;
            border-radius: 8px;
            flex: 1;
            background-size: contain;
        }

        .MuiCardContent-root {
            padding: 8px;

            span{
                min-height: 48px;
                max-height: 48px;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                display: -webkit-box;
                overflow: hidden;
            }
          
        }
    }
}