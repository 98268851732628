.InfoBlock-Group_edit_true {
    .InputMention {
        padding: 0;
        border-bottom: 1px solid rgba(0, 0, 0, 0.42);
        padding-bottom: 1px;

        &:focus-within {
            outline: none;
        }

        .css-1okebmr-indicatorSeparator {
            display: none;
        }

        .css-tlfecz-indicatorContainer {
            padding-right: 0;
        }

        &:hover {
            border-bottom: 2px solid rgba(0, 0, 0, 0.42);
            padding-bottom: 0;
        }
    }

    .AutoIncludeList {
        border-bottom: 1px solid rgba(0, 0, 0, 0.42);
    }

    .AutoIncludeUser {
        margin: 6px;
    }
}

.InfoBlock-Group {

    &-ModeratorSelect,
    &-GroupNewsTypes {
        margin-bottom: 16px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.42);
        padding-bottom: 1px;

        &:hover {
            border-bottom: 2px solid rgba(0, 0, 0, 0.42);
            padding-bottom: 0;
        }

        .InputLayout-WorkZone {
            padding: 0;
        }
        .InputLayout-WorkZone__focus {
            box-shadow: none;
        }
    }
   
    &-GroupNewsTypes {
        .ItemChip {
            cursor: grab;
        }
    }



    &-GroupNewsTypesItem {
        padding: 6px;

        &:hover {
            background-color: #edf5fd;
        }
    }
}