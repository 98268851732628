.NewsCreateDialog {

    &-Header {
        padding: 12px 24px 12px;
        border-top-right-radius: 8px;
        border-top-left-radius: 8px;
        border-bottom: 1px solid #d3d9e0;
    }

    &-Body {
        background-color: #fff;
        border-bottom-right-radius: 8px;
        border-bottom-left-radius: 8px;
    }

    &-Body.ModalDialogContent{
        overflow: inherit;
    }
}
