  .input {
      height: 34px;
      width: 220px;
      padding: 0 12px;
      font-size: 15px;
      font-family: inherit;
      background-color: transparent;
      border: none;
      color: #444;

      &:focus {
          outline: none;
      }

      &::placeholder {
          color: #888;
      }
  }

  .inputInvalid {
      color: #e65757;
  }

  .MentionSelect-Suggester {
      width: auto;
  }

  link {
      color: #2996da;
      text-decoration: underline;
  }