.CreateFolder {
    &-InputForm {
        display: flex;
    }

    &-Btn {
        margin: 0 6px;
    }

    &-Action {
        margin: 1px 0;
        padding-left: 8px;
        padding-right: 8px;

        .Icon {
            margin-left: 8px;
        }
    }
}

@media all and (-ms-high-contrast: none),
(-ms-high-contrast: active) {

    /* IE10+ CSS styles go here */
    .CreateFolder {
        min-width: 300px;
    }
}