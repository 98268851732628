@import '../../utils/global.scss';

.PageHeader {
  border: 1px solid $BorderColor;
  @include BorderRadius;
  background-color: white;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08);
  padding: 16px 32px;
  margin: 16px 2% 0;
  display: flex;
  align-items: center;

  &-Info {
    margin-left: 24px;
  }

  @media screen and (max-width: 768px) {
    padding: 16px;
    &-Avatar {
      width: 88px;
      height: 88px;
      margin-right: 32px;
    }
    .Avatar-Letter {
      line-height: 88px;
      font-size: 36px;
    }
  }
}
