.InfoBlock-Layout {
    display: flex;
    position: relative;

    padding: 22px 44px;
    @media (max-width: 500px) {
        padding: 22px 8px;
        flex-direction: column;
        align-items: center;
    }
    &-Left {
        width: 180px;
    }
    &-Right {
        margin-left: 24px;
        flex: 1;
        justify-content: center;
        display: flex;
        flex-direction: column;
        max-width: calc(100% - 204px);
        @media (max-width: 500px) {
            margin-left: 0;
            max-width: 100%;
        }
    }
    &-EditBtn {
        position: absolute;
        right: 0;
        top: 0;
    }
}
