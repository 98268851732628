.AnswersCheckbox {
    padding: 0;
    width: 100%;
    cursor: pointer;
    .CheckboxInput-Text {
        width: 100%;
    }
    @media (max-width: 576px) {
        line-height: 14px;
    }
}

.AnswersValue {
    line-height: 14px;
    font-family: "Open Sans",
        Arial,
        Helvetica,
        sans-serif;
}

.AnswerspaPticipants {
    flex: 110px;
    text-align: right;
    @media (max-width: 576px) {
        flex: 64px;
    }
}

@media all and (-ms-high-contrast: none),
(-ms-high-contrast: active) {

    /* IE10+ CSS styles go here */
    .AnswersCheckbox {
        height: 100%;
        top: 0px
    }
}