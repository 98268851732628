.OrgchartSkeleton {
    background-color: #f7f7f7;
    border: 1px solid var(--border-color);
    border-radius: 8px;
    overflow: hidden;
    margin-bottom: 24px;
  
    &-Image {
      text-align: center;
      background-color: #f7f7f7;
      height: 80vh;
    }
   
    &-Unit,
    &-Unit1,
    &-Unit2,
    &-MainUnit {
      padding: 12px 24px;
      width: 20%;
    //   border: 1px solid #b2afaf;
      border-radius: 8px;
      margin: 20px;
      position:relative;
      background: #fff;
    }
    &-MainUnit{
        margin: 20px auto;
    } 

    &-MainUnit::after {
        content: "";
        position: absolute;
        bottom: -10px;
        left: calc(50% - 1px);
        width: 2px;
        height: 9px;
        background-color: #b2afaf;
    }
    &-Unit1::before {
        content: "";
        position: absolute;
        top: -32px;
        right: -21px;
        width: calc(56% + 1px);
        border-top: 2px solid #b2afaf;
        box-sizing: border-box;
    }
    &-Unit1::after {
        content: "";
        position: absolute;
        top: -31px;
        height: 31px;
        left: calc(50% - 1px);
        width: 2px;
        background-color: #b2afaf;
    }
    &-Unit2::before {
        content: "";
        position: absolute;
        top: -32px;
        left: -21px;
        width: calc(56% + 1px);
        border-top: 2px solid #b2afaf;
        box-sizing: border-box;
    }
    &-Unit2::after {
        content: "";
        position: absolute;
        top: -31px;
        height: 31px;
        left: calc(50% - 1px);
        width: 2px;
        background-color: #b2afaf;
    }
    &-ChildrenUnits{
        display:flex;
        justify-content: center;
    }
    &-ChildrenUnits2{
        display:flex;
        justify-content: left;
        margin-left: 200px;
        position: relative;
        &-Unit1,
        &-Unit2{
          padding: 12px 24px;
          width: 20%;
        //   border: 1px solid #b2afaf;
          border-radius: 8px;
          margin: 20px;
          position:relative;
          background: #fff;
        }
        &-Unit1::before {
            content: "";
            position: absolute;
            top: -32px;
            right: -21px;
            width: calc(56% + 3px);
            border-top: 2px solid #b2afaf;
            box-sizing: border-box;
        }
        &-Unit1::after {
            content: "";
            position: absolute;
            top: -31px;
            height: 31px;
            left: calc(50% - 1px);
            width: 2px;
            background-color: #b2afaf;
        }
        &-Unit2::before {
            content: "";
            position: absolute;
            top: -32px;
            left: -21px;
            width: calc(56% + 3px);
            border-top: 2px solid #b2afaf;
            box-sizing: border-box;
        }
        &-Unit2::after {
            content: "";
            position: absolute;
            top: -31px;
            height: 31px;
            left: calc(50% - 1px);
            width: 2px;
            background-color: #b2afaf;
        }
    }
    &-ChildrenUnits2::after {
        content: "";
        position: absolute;
        top: -20px;
        left: calc(27% - 1px);
        width: 2px;
        height: 9px;
        background-color: #b2afaf;
    }

  }