@import '../../../../utils/global.scss';

.Modal-Body_type_thanks {
  padding: 0 !important;

  .Modal-Body {
    
    &-Part {
      padding: 0 15px;
      position: relative;
      background-color: #fff;

      &:nth-child(n + 2) {
        border-top: 1px solid $BorderColor;
      }
    }

    &-Input {
      margin: 0 -15px;

      input {
        text-align: center;
        padding: 6.5px 15px;
        font-size: 20px;
      }
    }
  
    &-Button {
  
      &_count_up,
      &_count_down {
        position: absolute;
        top: 6.5px;
        z-index: 1;
      }

      &_count_up {
        right: 25%;
      }

      &_count_down {
        left: 25%;
      }
    }

    &-CurrentCount {
      display: inline-block;
      width: 50%;
      text-align: center;
      padding: 11px 0;
    }

    &-Reasons-CategoryName {
      padding: 11px 0;
    }
  }
}