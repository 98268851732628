.Widget_type_layout {
    border: 0px;
    background: transparent;
    display: flex;
    box-sizing: border-box;
    justify-content: space-between;
    gap: 24px;
    // margin: 0 -1%;

    &.Widget_isMobile {
        display: block;
        margin: 0;
    }

    &.Widget_edit {
        padding-top: 37px;
    }

    .Widget {
        &-Sortable {
            min-height: 150px;
            height: calc(100% - 35px);
        }

        &-Column {
            width: 100%;
            box-sizing: border-box;

            &:empty {
                display: none;
            }

            @media (min-width: 1024px) {
                display: block;
            }

            &-AddWidget {
                width: 100%;
                display: block;
            }

            // &_mobileHidden {
            //   display: none;
            //   @media (min-width: 1024px) {
            //     display: block;
            //   }
            // }
        }

        &_edit &-Column {
            min-height: 150px;
            border: 1px solid var(--border-color);
        }
    }

    .Widget-Swipe {
        .Widget {
            &-Column {
                &-SwipeWrapper {
                    transition-duration: 300ms;
                    transition-timing-function: cubic-bezier(0.5, -1, 0.5, 0);
                }

                &.SwipeTranslate .Widget-Column-SwipeWrapper {
                    transform: translate(-25px, 0px) translateZ(0px);
                }

                &.SwipeTranslate:nth-child(n + 2) .Widget-Column-SwipeWrapper {
                    transform: translate(calc(-25px + 2%), 0px) translateZ(0px);
                }

                .Widget-Column-SwipeWrapper {
                    transform: none !important;
                    padding: 0 2px;
                }
                .Widget-Column-SwipeWrapper .Widget:not(.Widget_type_layout) {
                    width: calc(100% - 4px);
                    margin-left: 2px;
                }

                // &_preActive .Widget-Column-SwipeWrapper {
                //   transform: translate(calc(-2%), 0px) translateZ(0px);
                // }

                // &_postActive .Widget-Column-SwipeWrapper {
                //   transform: translate(calc(2%), 0px) translateZ(0px);
                // }
            }
        }
    }
}
