.MobileAppWidget {
  background: #FFFFFF;
  padding: 24px;
  &-Header {
    &-Title {
      font-family: "Open Sans", Arial, Helvetica, sans-serif;
      text-transform: none;
      font-weight: 700;
      font-size: 16px;
      line-height: 116%;
      letter-spacing: 0.15px;
      padding: 6.5px 0;
      margin:0 0 8px 0;
    }
    
  }
  // &-Title {
  //   font-weight: 700;
  //   font-size: 16px;
  //   line-height: 116%;
  //   letter-spacing: 0.15px;
  //   color: rgba(0, 0, 0, 0.87);
  //   margin: 0;
  //   padding: 0;
  // }

  &-Input {
    margin-right: -24px;
    margin-left: -24px;
    border-top: 1px solid var(--border-color);
    border-bottom: 1px solid var(--border-color);
    margin-bottom: 16px;
  }

  &-Advantages {
    padding-left: 24px;
    font-weight: 400;
    font-size: 14px;
    line-height: 143%;
    letter-spacing: 0.15px;
    margin: 4px 0 16px 0;
  }
  &-Advantage::marker{
    font-size: 10px;
  }

  &-Form {
    text-align: center;
  }

  &-Text {
    display: flex;
    align-items: center;
    font-size: 12px;
    line-height: 1.2;
  }

  .TickTimer {
    text-align: center;
  }

  &-OpenQRButton {
    display: block;
    text-align: left;
    &-Submit {
      // margin: 5px 0;
      // // width: 100%;
      // font-weight: 500;
      // font-size: 13px;
      // line-height: 22px;
      // letter-spacing: 0.46px;
    }
  }

 

  .HelpTooltip-Content {
    font-size: 12px;
    background-color: violet;
  }

  .Input-HelpTooltip {
    right: 0px;

    .Input-ErrorIcon {
      font-size: 14px;
    }
  }
}