.ReferralModal {
  .TabsElement {
    margin-bottom: 12px;
  }

  &-Body {
    display: flex;
    flex-direction: column;
    gap: 12px;
    align-items: baseline;
    margin: 0 24px;
    min-height: 290px;
  }

  &-Adresses {
    width: 100%;
  }

  &-Text {
    display: flex;
    flex-direction: column;
    &-Title {
      font-size: 16px;
      font-weight: bold;
    }

    &-Email {
      background: #eee;
      padding: 12px;
    }
  }

  &-Validation {
    width: 100%;
    box-sizing: border-box;
  }

  &-Link {
    display: flex;
    flex-direction: column;
    &-Title {
      font-size: 16px;
      font-weight: bold;
    }

    &-Copy {
      display: flex;
      align-items: center;
      svg {
        margin-left: 12px;
        cursor: pointer;
      }
    }
  }
}