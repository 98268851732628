.AdditionalContentRender-Value{
  padding-left: 0;
}

.AdditionalContentRender-CatName{
    padding: 9px 0px;
    font-size: 18px;
    margin-bottom: 10px;
}

h5{
  margin: 10px 0;
}