@import '../../../utils/global.scss';

.CountSlider-Slider {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  /* border: 1px solid #d3d9e0; */
  border-top: 0px;
  background: #fff;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  height: 100%;

  &-Progress {
    flex: 1 1;

    .Slider-Dot {
      opacity: 1;
      border-radius: 50%;
      height: 20px;
      width: 20px;
      background: #06c;
    }
  }

  &-Counts {
    /*position: absolute;
    top: 50%;
    margin-top: -12px;
    line-height: 24px;*/
    font-weight: bold;
    font-size: 24px;
    width: 70px;
    text-align: center;

    &_min {
      left: 0;
      color: #89ca5e;
    }

    &_max {
      right: 0;
      color: #b1b1b1;
    }
  }

  &-Bar {
    position: relative;
    margin: 15px 80px;
    height: 10px;
    border-radius: 5px;
    background: #b1b1b1;

    &-Dot {
      z-index: 1;
      position: absolute;
      top: -5px;
      left: -5px;
      border-radius: 50%;
      height: 20px;
      width: 20px;
      background: #06c;

      // transition-property: left;
      // transition-duration: $TansitionDuration;
      // transition-timing-function: $TansitionTimingFunction;
    }
  }
}