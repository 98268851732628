.UserMention-Item {
  display: flex;
  padding: 14px 17px;
  min-height: 66px;

  &:hover {
    background-color: #0066cc;
    color: #fff;
  }

  &_focused_item {

  }

  &_disabled {
    color: #ccc;
    &:hover {
      background-color: transparent;
      color: #ccc;
    }
  }
}



.MentionItem-Data {
  flex: 1 1;
  margin-left: 8px;
  font-size: 14px;
  line-height: 22px;
}

.UserMention-Img {
  &_disabled {
    filter: grayscale(100%);
  }
}

.MentionItem-displayName {
  font-weight: bold;
}

.UserMention-Chip {
  display: flex;
  padding: 2px 8px;
  font-size: 14px;
  line-height: normal;
  color: #0066cc;
  background-color: #dbdbdb;
  align-items: center;
  border-radius: 4px;
  // margin: 5px 6px 0px 0;
  margin: 2px 6px 2px 0;
}

.UserMention-avatar {
}

.MentionItem-DisabledItem {
  // color: red;
      color: #721c24;
  margin-top: 8px;
}

.UserMention-displayName {
  margin-left: 8px;
}

.PrnUserInput-Btn {
  line-height: 18px;

  .Icon {
    color: var(--text-muted-color);
  }
}

.UserMention-times {
  padding: 2px 5px;
}
