@import '../../../utils/global.scss';

.OrderBlock {
    &-Order {
        padding: 0 24px;
        height: 42px;
        line-height: 42px;
        position: relative;

        &.Button_main:disabled {
            pointer-events: none;
        }
    }

    &-NotAvailable {
        color: var(--danger-color);
    }
}