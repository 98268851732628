.LMSUserAnswer {
  border: 1px solid #d3d9e0;
  background: #fff;

  border-radius: 8px;

  &-PaddingBox {
    padding-top: 24px;
    padding-right: 24px;
    padding-left: 24px;
  }

  &-Content {
    padding: 15px 0;
    border-bottom: 1px solid #d3d9e0;
  }

  &-Footer {
    display: flex;
  }
}
