.WikiViewTypeSelector{
    position: relative;
    top: 6px;
    padding-bottom: 11px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    p{
        margin: 0;
    }
}

