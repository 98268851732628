@import '../../utils/global.scss';

.Filters {
  &:empty {
      display: none;
  }

  &-Subtitle {
    margin: 1.33em;
  }

  &-Block {

    &:nth-of-type(n + 2) {
      padding-top: 5px;
      border-top: 1px solid $BorderColor;
    }

    &:nth-last-of-type(n + 2) {
      padding-bottom: 5px;
    }
  }

  > .Selectize:last-of-type > *:last-child,
  &-Block:last-of-type .Selectize > *:last-child {
    @include BorderRadiusBottom;
  }
}