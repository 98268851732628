.Code {

  &-Header {
    margin: 5px 24px;
  }
  
  .monaco-editor,
  .monaco-editor > div {
    border-radius: 8px;
  }
}