.BottomLine {
  border-bottom: 1px solid #D3D9E0;
}

.Filter-Tags{
 display:block;
 .InputMention-Selected {
    display:flex;
    padding: 8px 12px;
    .InputMention-Selected-Name {
      display: block;
      vertical-align: middle;
      color: #000;
      overflow: hidden;
      text-overflow: ellipsis;
      margin: 0;
      pointer-events: visible;
    }
  }
}
.Filter-TitleIdeas {
  padding: 0;
  padding-top:24px;
  padding-left: 24px;
  margin: 0;
  padding-bottom: 24px;
}

.Filter-Subtitle {
  padding: 0;
  padding-left: 24px;
}

.Filter{
  &-Toggle{
    display: flex;
    align-items: center;
    justify-content: space-between!important;
    margin: 0 20px;
  }
}

.Filter-Bottom {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Filter-Button {
  margin-bottom: 24px;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    /* IE10+ CSS styles go here */
  .Filter-Tags{ 
     .css-1alnv5e{
       svg{
      min-width: 14px;
      min-height: 14px;
      }
    }
  }
 
}