.TOAllReports {
  &-Data {
    display: flex;
    border-radius: 8px;
    border: 1px solid #d3d9e0;
    background-color: #fff;
    padding: 24px;
  }

  &-Sessions {
    display: flex;
  }

  &-Error {
    color: red;
    margin-left: 24px;
  }

  &-Tabs {
    margin-left: -12px;
  }

  &-Control {
    background-color: #fff;
    padding: 24px;
    margin-bottom: 24px;
    border-radius: 8px;
    border: 1px solid #d3d9e0;
  }

  &-Table {}

}
