.Item {
    display: flex;
    border-bottom: 1px solid #d3d9e0;
    padding: 8px 16px;
    
    &:hover {
        background: var(--main-color);
        color: #fff!important;
    }

    &-Title {
        font-weight: bold;
        font-size: 14px;
        line-height: 24px;
    }

    &-UserList {
        margin-left: auto;
        line-height: 0.8;
    }

    &-Desc {
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;

        &:empty {
            display: none;
        }
    }

    &-ImgBox {
        .Avatar {
            &:empty {
                display: none;
            }
        }
    }

    &-DataBox {
        margin-left: 12px;
    }

    &-Icon {
        margin-right: 6px;
    }

    &-NetworkIcon {
        margin-right: 6px;
    }

    &-Network {
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;
        &:empty {
            display: none;
        }
    }
}
