.DistributeTimer{
    display: flex;
    justify-content: end;
    
    &-TimeCounter{
        display: flex;
        &-Days,
        &-Hours,
        &-Minutes{
            font-weight: 400;
            padding: 0 2px 0 4px;
        }
        &-Text{
            font-weight: 400;
        }
    }
    .Loading{ margin-left: 2px}
    .MuiCircularProgress-root.MuiCircularProgress-colorPrimary.MuiCircularProgress-indeterminate{
        margin-left: 5px;
    }
}