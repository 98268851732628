.DateInput .InputLayout-EditZone {
    height: auto; 
}

.DateInput .InputLayout-EditZone .HelpTooltip{
    position: absolute;
    z-index: 1;
    right: 43px;
    cursor: default;
  }
.DateInput .InputLayout-EditZone  .ErrorIcon:before {
    color: red;
    font-size: 16px;
}

.DateInput-Input::-webkit-inner-spin-button,
.DateInput-Input::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
}

.DateInput .InputLayout-IconZone .Icon_calendar {
    display: block;
    padding-bottom: 3px;
    padding-top: 3px;
}
.DateInput .InputLayout-IconZone .Icon_calendar::before {
    display: block;
}
