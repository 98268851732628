@import "../../../../utils/global.scss";

.TOCourseItem {
    @include BoxShadow;
    @include Border;
    @include BorderRadius;
    background-color: var(--background-color);
    margin-bottom: 24px;
    box-sizing: border-box;
    position: relative;
    overflow: hidden;

    &-Image {
        width: 100%;
        display: block;
    }

    &-Info {
        padding: 16px 24px;
        border-bottom: 1px solid var(--border-color);
    }

    &-Progress {
        margin-top: 20px;
    }

    &-Admin {
        margin-top: 20px;
    }

    &-Title {
        font-weight: normal;
        font-size: 24px;
        line-height: 32px;
        margin: 0;
        margin-top: 8px;
    }

    &-UnitCount {
        margin-right: 24px;
    }

    &-Section {
        display: flex;
    }

    &-Progress {
        margin: 12px 0;
        display: flex;
        align-items: center;

        &-Value {
            margin-left: 16px;
        }

        .ProgressBar-Bar {
            flex-grow: 1;
        }

        &-Value {
            font-size: 16px;
        }
    }

    &-Start {
        display: table;
        // margin: 16px auto 24px;
        vertical-align: middle;
        line-height: 2;
        padding: 0 12px;
    }

    &-ActionBox {
        margin: 16px 24px;
        display: flex;
        justify-content: center;
    }
}