@import "../../utils/global.scss";

.InputMention {
  // position: relative;
  box-sizing: border-box;
  background-color: #fff;

  display: flex;
  align-items: center;
  padding-left: 24px;
  // margin: 1px 0;

  &:focus-within {
    outline: 1px solid $SystemBlue;
    width: 100%;
  }

  &:hover {
    cursor: text;
  }

  [class*="css-"][class$="-container"] {
    flex: 1 1;
  }

  &-HelpTooltip {
    position: absolute;
    right: -2px;
  }

  &-ErrorIcon:before {
    right: 15px;
    color: $ErrorColor;
  }
  &-ErrorIcon {
    right: 15px;
    font-size: 16px;
  }

  &-Icon {
    // position: absolute;
    font-size: 16px;
    // width: 16px;
    text-align: center;
    margin-right: 8px;
    // top: 50%;
    // left: 15px;
    // transform: translateY(-50%);
  }

  &-Input {
    margin: 0;
    border: 0;
    padding: 11px 10px 10px 45px;
    @include FontSize;
    width: 100%;
    box-sizing: border-box;
    font-family: $FontFamilyRegular;
  }

  &_noIcon {
    .Input-Input {
      padding: 0px;
    }
  }

  [class*="css-"][class$="-control"] {
    border: 0px !important;
    box-shadow: none !important;
    // margin-left: 35px;
    background: transparent;
  }

  &-Selected {
    padding: 8px 12px;
    .SelectedIcon {
      margin: 0 8px 0 0 !important;
    }
    &-Icon,
    &-Name {
      display: inline-block;
      vertical-align: middle;
      color: #000;
      pointer-events: none;
    }

    &-Icon {
      margin-right: 8px;
      height: fit-content;
    }

    &:hover {
      background-color: #deebff;
    }
  }

  &_noIcon {
    [class*="css-"][class$="-control"] {
      margin-left: 0px;
    }
  }
}

[class*="css-"][class$="-option"] {
  background-color: #fff;
  padding: 0;
}
