.TOSessionsItem {
    display: flex;
    padding: 12px 24px 12px 40px;

    &_higthligth {
        transition: all 0.3s;
        animation: shadow 2s infinite ease-in-out;
    }
    @keyframes shadow {
        from {
            box-shadow: inset 0px 0px 0px #0066cc;
            animation-timing-function: ease-out;
        }
        50% {
            box-shadow: inset 0px 0px 15px #0066cc;
            animation-timing-function: ease-out;
        }
        to {
            box-shadow: inset 0px 0px 0px #0066cc;
        }
    }
    &-Data {
        flex: 2 5;
    }

    &-Duration {
        flex: 1 5;
    }

    &-Title {
        font-family: "Open Sans";
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 24px;
        color: #0066cc;
    }

    &-Users {
        flex: 1 5;
    }

    &-Action {
        flex: 1 5;
        text-align: right;
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }
    &-ActionMenu {
        // visibility: hidden;
        // background-color: #ebeff2;

        .Button {
            color: #000;
        }

        &.SplitButton .Button {
            padding: 3px 5px;
        }

        &.SplitButton .Dropdown .Button {
            padding: 0px 5px;
        }
    }
}
