@import '../../utils/global.scss';

.ItemTabsPage {
  padding-top: 24px;
  
  &-Header-Wrapper {
    margin: 0px 2%;
    overflow-x: hidden;
    padding-bottom: 11px;
    border: 1px solid var(--border-color);
    border-bottom: 0px;
  }

  &-Header,
  &-Content {
    margin: 0 auto;
  }
  
    
  &-Header-Data-Wrapper {
    background: #fff;

    .ItemTabsPage-Header {
      max-width: 800px;
      margin: 0 auto;
    }
  }
  
  &-Header {
    padding: 25px;
  }

  &-Content {
    margin-top: 11px;
  }

  &-Tabs {
    text-align: center;
    background: #fff;

    .PriorityNav {

      &-Button {
        padding: 5px 10px 0px;
        margin: 0 2.5px;
        position: relative;
  
        &:before,
        &:after {
          content: '';
          position: absolute;
          top: 100%;
          width: calc(100% + 5px);
          height: 10px;
          background: #fff;
          border-bottom: 1px solid $BorderColor;
        }
        &:before {
          left: -2.5px;
        }
        &:after {
          right: -2.5px;
        }
  
        &:hover,
        &_active {
          
          &:before {
            transform: skewX(-45deg);
            width: calc(50% + 10px);
            left: unset;
            right: calc(50% + 5px);
            border-right: 1px solid;
            border-color: #d3d9e0 $BorderColor;
          }
  
          &:after {
            transform: skewX(45deg);
            width: calc(50% + 10px);
            right: unset;
            left: calc(50% + 5px);
            border-left: 1px solid $BorderColor;
          }
        }
  
        .ItemTabsPage-Tabs-Ghost {
          position: absolute;
          top: 100%;
          height: 10px;
          background: #fff;
          border-bottom: 1px solid $BorderColor;
          width: 100000px;    
        }
  
        .ItemTabsPage-Tabs-Ghost_left {
          right: 100%;
        }
  
        .ItemTabsPage-Tabs-Ghost_right {
          left: 100%;
        }
      }
    }
  }
}