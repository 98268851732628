.TOCommentsCount {
  &-Data {
    display: flex;
  }
  
  &-Control {
    background-color: #fff;
    padding: 24px;
    margin-bottom: 24px;
    border-radius: 8px;
    border: 1px solid #d3d9e0;
  }
}
