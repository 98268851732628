.QuizStart {
  p {
    margin: 0;
  }
  button {
    padding-left: 24px;
    padding-right: 24px;
    margin: 24px 0;
  }
  &-Warning {
    margin-top: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 16px;
    // width: 838px;
    // height: 68px;
    // border: 1px solid #2196F3;
    background-color: #2196F3;
    border-radius: 4px;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;

    span {
      // font-family: 'Roboto';
      // font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 143%;
      /* or 20px */
      padding: 8px 0px;
      letter-spacing: 0.15px;
      color: #fff;
      height: 40px;
      display: flex;
      align-items: center;
      margin-left: 14px;
    }
  }
}

@media (max-width: 576px) {
  .QuizStart {
    &-Warning {
      border: 1px solid #2196F3;
      background-color: #fff;

      .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium {
        color: #305e83 !important;
      }

      span {
        font-size: 0.9em;
        color: #305e83;
      }
    }
  }
}