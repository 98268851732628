.QuestsWidget {
  padding: 24px 0;

  &-Header {
    display: flex;
    padding: 0 0 12px 0;
    align-items: center;
  }

  &-Pagination {
    font-size: 12px;
    display: flex;
    align-items: center;
  }

  &-Title {
    margin: 0;
    font-size: 16px;
  }

  &-Pagination {
    margin-left: auto;

    &Control {
      height: 24px;
      border: none;
      padding: 0;
      background-color: transparent;
      outline: none;
      cursor: pointer;
      color: var(--border-color);
    }

    &>span {
      color: #999;
      // font-size: 11px;
      font-weight: lighter;
    }
  }

  &-Details {
    padding: 0px 16px 12px 24px;

    &-Title {
      font-size: 16px;
      font-weight: bold;
      text-overflow: ellipsis;
      overflow: hidden;
      margin: 0;
    }
  }

  &-Item {
    padding: 24px;
  }

  &-HeaderEmpty {
    margin: 0 0 8px 0;
    padding: 6.5px 0;
    color: #000;
    border: none;
    text-decoration: none !important;

    &-Title {
      margin-bottom: 8px;
      font-weight: 700;
      font-size: 16px;
      line-height: 116%;
      letter-spacing: 0.15px;
      padding: 6.5px 0;
      margin-bottom: 8px;
      color: rgba(0, 0, 0, 0.87) !important;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      width: 245px
    }

    &-Text {
      font-size: 14px;
      line-height: 20.02px;
      width: 100%;
      margin-bottom: 12px
    }

  }

  &-Rewards {
    display: flex;
    align-items: center;
    margin: 12px 0;

    &>div {
      display: flex;
      align-items: center;
      margin-right: 12px;
      padding: 2px;
      border-radius: 50px;

      &.currency {
        background-color: #F0C419;
      }

      &.rating {
        background-color: #B0D3F0;
      }

      &>span {
        margin-left: 4px;
        margin-right: 8px;
        font-size: 12px;
      }
    }
  }

  &-Progress {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &-Line {
      width: 100%;
      margin-right: 12px;
    }

    &-Info {
      min-width: fit-content;

      span {
        font-size: 11px;
        color: #999;
      }
    }

  }

  &-Bonus {
    font-weight: 600;
  }

  &-List {
    padding-left: 18px;
  }

  &-BonusButton {
    color: #0066cc;
    font-weight: bold;
  }

  &-RewardHeader {
    margin-top: 8px;
    margin-bottom: 0;
  }

  &-RewardItem {
    display: block;

    >* {
      display: inline;
    }
  }

  &-Goals {
    padding: 0 24px 16px 24px;
  }

  &-GoalTextOnModeration {
    color: var(--border-color);
  }

  &-GoalTextHandStep {
    cursor: pointer;
  }

  &-GoalText {
    cursor: default;
  }

  &-GoalTextHandStep,
  &-GoalText {
    display: flex;
    flex-direction: column;

    &>span {
      color: #999;
      font-size: 11px;
    }
  }

  &-Goal {
    margin: 0;
    display: flex;

    &:not(:last-child) {
      margin-bottom: 8px;
    }

    .HelpTooltip {
      text-decoration: none;
    }
  }

  &-GoalIcon {
    color: var(--highlight-color);
    font-size: 16px;
    margin-right: 8px;

    &_inProgress {
      color: var(--border-color);
    }
  }

  &-Result {
    padding: 24px;
    border-top: 1px solid var(--border-color);

    h4 {
      font-size: 16px;
      font-weight: bold;
      margin: 0;
      margin-bottom: 8px;
    }

    &>div {
      span {
        font-weight: bold;
        margin-right: 6px;
      }
    }
  }
}