.TOCourseStatistics {
    display: flex;
    margin-top: 24px;
    align-items: flex-start;

    &-Aside {
        background-color: #fff;
        flex: 1 4;
        border-radius: 8px;
        overflow: hidden;
        margin-right: 24px;
        border: 1px solid #D3D9E0;
    }

    &-Section {
        // background-color: #fff;
        flex: 3 4;   
        // border-radius: 8px;
        // border: 1px solid #D3D9E0;
    }

    &-Header {
        display: flex;
        background: #F7F7F7;
        align-items: center;
        padding: 24px;
        border-bottom: 1px solid #D3D9E0;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
    }
}