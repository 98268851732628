@import "../../../utils/global.scss";

.LMSContextMenu {
    &-ActionsList {
        // position: absolute;
        list-style: none;
        padding: 0;
        background-color: #fff;
        overflow: hidden;
        z-index: 20;
        line-height: normal;
        white-space: nowrap;
    }

    &-ActionsToggle {
        position: absolute;
        right: 8px;
        top: 8px;
    }

    &-ActionsItem {
        padding: 5px 10px;

        .Button {
            display: block;
            width: 100%;
            text-align: left;
        }

        &:hover {
            background-color: $SystemBlue;

            .Button {
                color: #fff;
            }
        }
    }
}