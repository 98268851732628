.HierarchyWidgetNew-Item-Info:hover {
    .HierarchyWidgetNew-Delete {
        opacity: 1;
        width: auto;
        height: 34px;
    }
}

.HierarchyWidgetNew-DialogBodyShowAll {
    .HierarchyWidgetNew-Item-Info:hover {
        .HierarchyWidgetNew-Delete {
            opacity: 1;
            width: auto;
            height: 34px;
        }
    }
    .HierarchyWidgetNew-Delete {
        opacity: 0;
    }
}

.HierarchyWidgetNew {
    .HierarchyWidgetNew-Delete {
        opacity: 0;
    }

    &-Item {
        display: flex;
        align-items: flex-start;
        gap: 8px;
        grid-gap: 8px;
        width: 100%;

        &-Info {
            margin-left: 12px;
            min-width: 0;

            a {
                display: block;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                font-feature-settings: 'clig' off, 'liga' off;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 150%;
                letter-spacing: 0.15px;
                width: 100%;
            }

            p {
                margin: 0;
                line-height: 1;
            }
        }
    }
}
