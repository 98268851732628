.CoursesMy {
    background-color: white;
    border-radius: 8px;
    overflow: hidden;

    &-Title {
        margin: 12px;
    }
    &-MoreBtn {
        margin-top: 12px;
        display: block;
    }
    &-Item {
        border: none;
        border-radius: 0;
        box-shadow: none;
        margin-bottom: 0;
        border-bottom: 1px solid var(--border-color);
    }
}