@import '../../../utils/global.scss';

.NewsReplyView {
    &-UserName {
        font-weight: bold;
        margin-right: 4px;
    }

    &-ThankHeader {}

    &-DateTime {}
    
    &-MoreBtn {
        color: #000;
        cursor: pointer;
        font-weight: bold;
    }
}