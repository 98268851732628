.AboutUploader {
    position: relative;

    &-Header {
        display: flex;
        align-items: center;
        margin: 12px 0;
        justify-content: space-between;
        border-bottom: 1px solid #ccc;
    }

    &-Noop {
        padding: 12px 0;
        text-align: center;
        color: #ccc;
        font-style: italic;
        cursor: pointer;
    }

    &-Attachments {
        padding: 12px 0;
    }
}
