.TOUnitsAttandance {
  &-Data {
    display: flex;
    // margin: 24px;
  }

  &-Tabs {
    margin-left: -12px;
  }

  &-Sessions {
    display: flex;
  }

  &-Control {
    background-color: #fff;
    padding: 24px;
    margin-bottom: 24px;
    border-radius: 8px;
    border: 1px solid #d3d9e0;
  }

  &-Chart {
    border: 1px solid #d3d9e0;
    border-radius: 8px;
  }
}