.TOCreateCourse {
    background-color: #fff;
    z-index: 90;
    &-WrapEditor {
        margin: 0 24px;
    }
    .Dialog-HeaderContent{
        display: flex;
    }
    &-Video{
        margin-left: 5px;
    }
}
