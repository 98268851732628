.Error500 {
  text-align: center;
  height: 100%;
  margin: 12em auto 0 auto!important;
  box-sizing: border-box;

  &-Image {
    display: inline-block;
    max-width: 100%;
    @media (min-width: 1440px) {
      // transform: translateX(-100px);
    }
  }
  &-ImageRed{
    display: none;
    max-width: 100%;
    @media (min-width: 1440px) {
      // transform: translateX(-100px);
    }
  }
  &-Title {
    font-size: 34px;
    line-height: 123%;
    font-weight: normal;
    margin: 32px 0 0 0;
}
  &-Text {
    font-size: 15px;
    line-height: 150%;
    margin-top: 8px;
 
  }
 
  &-Link {
    text-transform: uppercase;
  }
}
//   color: var(--invariant-text-color);
//   position: relative;
//   z-index: 10;
//   width: 50%;
//   margin: 24px auto;
//   padding-bottom: 24px;

//   &-500 {
//     font-size: 150px;
//     margin: 0;
//   }

//   &-Image {
//     display: block;
//     max-width: 100%;
//     @media (min-width: 1440px) {
//       transform: translateX(-100px);
//     }
//   }
//   &-ImageRed{
//     display: none;
//     max-width: 100%;
//     @media (min-width: 1440px) {
//       transform: translateX(-100px);
//     }
//   }
//   &-Title {
//     font-size: 32px;
//     line-height: 44px;
//     font-weight: normal;
//     margin: 0;
//   }
//   &-Text {
//     font-size: 24px;
//     line-height: 33px;
//     margin-top: 8px;
//     max-width: 500px;
//   }
 
//   &-Link {
//     color: var(--main-lighten-color);
//     font-size: inherit;
//     &:visited,
//     &:focus {
//       color: var(--main-lighten-color);
//     }
//   }
// }