.ModalDialogTitle {
    display: flex;
    align-items: center;
    background-color: #fff;
    &-Header {
        flex: 1;
    }
    &-CloseBtn {
    }
}
