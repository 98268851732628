.LMSFilters {
    .Selectize-Button {
        width: auto;
    }

    &-Status {
        .Selectize-Button {
            &:last-of-type {
                border-bottom-right-radius: 0;
                border-bottom-left-radius: 0;
            }
        }

        padding-bottom: 6px;
        border-bottom: 1px solid var(--border-color);
        ;
    }

    &-Mandatory {
        padding-top: 6px;
    }

    border: 1px solid #d3d9e0;
    background-color: #ffffff;
    margin-bottom: 24px;
    border-radius: 8px;
}