.ResultSummaryTable {
    &-Value {
        line-height: normal;
        font-size: 20px;
        line-height: 24px;

        &_type_success {
            color: #89ca5e;
            // font-size: 64px;
        }

        &_type_wrong {
            color: #f7434c;
            // font-size: 64px;
        }
        
        &_type_wait {
            color: #194776;
        }

        &_type_moderated {
            color: #6fb19d;
        }
    }

    &-SummaryTitle {
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        padding-top: 9px;
        padding-bottom: 9px;
    }

    &-SummaryAnswers {
        font-weight: bold;
        font-size: 16px;
        line-height: 24px;
        padding-top: 9px;
        padding-bottom: 9px;
    }
}