.Modal-Body_type_addWidget {
  overflow: visible;
  
  .Widget-Selector {
    white-space: nowrap;
    overflow-x: auto;

    &-Item {
      height: 100px;
      width: 150px;
  
      &_checked {
        background: #06c;
        color: #fff;
      }
    }
  }
}