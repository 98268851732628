.Widget_type_users {
  padding: 24px;
  /* border-color: transparent;
  box-shadow: 0 1px 1px 0 (rgba(0, 0, 0, 0.12)); */
  .Widget_subtype_subscriptions {
    border: 2px solid blue;
    border-color: red;
    box-shadow: 0 1px 1px 0 (rgba(0, 0, 0, 0.12));
  }
}

