.TrackStatWidget {
    background-color: #fff;
    border-radius: 8px;
    padding: 24px;
    border: 1px solid #d3d9e0;
    display: flex;
    justify-content: space-evenly;
    
    &-Item {
        margin-left: 12px;
        margin-right: 12px;
    }

    &-Title {
        text-align: center;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 8px;
    }

    &-Chart {
        width: 146px;
    }
}