.SkillsCreator {
    display: flex;
    border-bottom: 1px solid #d3d9e0;
    padding-left: 24px;
    padding-right: 24px;
    align-items: center;
    padding-top: 17px;
    padding-bottom: 16px;

    @media (max-width: 500px) {
        padding-left: 8px;
        padding-right: 8px;
    }

    &-Index {
        width: 24px;
    }

    &-InputBox {
        // width: 180px;
        flex: 1;
        margin-top: -20px;
        @media (max-width: 500px) {
            margin-top: -10px;
        }
    }

    // &-Users {
    //     flex: 2;
    //     @media (max-width: 500px) {
    //         display: none;
    //     }
    // }

    // @media (max-width: 600px) {
    //     &-Users {
    //         display: none;
    //     }
    // }

    &-Action {
        width: 130px;
        display: flex;
        justify-content: flex-end;
        @media (max-width: 500px) {
            display: none;
        }
    }
}
