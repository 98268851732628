.ApproveDialog {
    &-User {
        padding: 12px 24px;
        line-height: normal;
        display: flex;
        align-items: center;

        &:not(:first-child) {
            border-top: 1px solid var(--border-color);
        }

        &:last-of-type {
            border-bottom-left-radius: 8px;
            border-bottom-right-radius: 8px;
        }

        &:hover {
            background-color: var(--main-color);
            color: var(--invariant-text-color);
            cursor: pointer;
        }
    }

    &-SelectBox {
        display: flex;
        margin-top: 6px;
        align-items: center;
    }

    &-ApproveActions {
        display: flex;
        align-items: center;
    }

    &-ApproveBtn {
        margin: 6px;
    }

    &-Actions {
        margin-right: 0;
        margin-left: auto;
        display: flex;
        align-items: center;
        // border-top: 1px solid var(--border-color);
        // background-color: var(--background-highlight-color);
    }

    &-Name {
        margin: 0;
    }

    &-SelectAll {
        padding: 12px 0;
    }

    &-Avatar,
    &-Info {
        margin-left: 12px;
    }

    &-Search {
        max-width: none;
        margin: 0 auto;
    }
}