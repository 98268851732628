.ResultHeader {
    padding: 24px 24px 8px 24px;
    display: flex;
    justify-content: space-between;
    &-HeaderColumn {
        // flex: 1 1;
    }
    &-Title {
        font-weight: bold;
        font-size: 24px;
        line-height: 33px;
    }

    &-Attempts {
        font-size: 20px;
        line-height: 27px;
    }

    &-Time {
        font-weight: bold;
        font-size: 24px;
        line-height: 33px;
    }

    &-TimeText {
        font-size: 20px;
        line-height: 27px;
    }
}