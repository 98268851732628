@import '../../../../utils/global.scss';

.EditorThanks {
    .Select {
        // border-bottom: 1px solid #D3D9E0;
        border-top: 1px solid #D3D9E0;

    }
    &-Error {
        margin: 12px;
    }

    &-MinLength {
        color:#A2A2A2;
        padding-left: 24px;
        padding-right: 24px;
    }

    .PryanikyEditor {
        border-top: 1px solid #D3D9E0;
    }
    .ReasonThanksSelector {
        border-top: 1px solid #D3D9E0;
        border-bottom: 1px solid #D3D9E0;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
    }

}
.ReasonsBox {
    display: flex;
    align-items: center;
    padding-left: 24px;
    margin: 1px 0;
    border-bottom: 1px solid #D3D9E0;
    border-top: 1px solid #D3D9E0;
    &:focus-within {
        outline: 1px solid $SystemBlue;
    }

    .Icon {
        font-size: 16px;
        margin-right: 8px;
    }
    

    .ReasonsInput {
        flex: 1 1;
        outline: none;
        border: none;

        .css-1pahdxg-control {
            outline: none;
            border: none;
            box-shadow: none;
        }

        .css-es53b3-multiValue {
            padding: 4px 0;
        }

        .css-yk16xz-control {
            outline: none;
            border: none;
        }

        .css-1hwfws3 {
            outline: none;
            border: none;
        }
    }
}

.Thanks-NoUser {
    padding: 12px 24px;
}