@import '../../utils/global.scss';

.ProjectLogo {
  min-height: 210px;
  padding-top: 70px;
  box-sizing: border-box;

  &_border {
    border-bottom: 1px solid $BorderColor;
  }

  &-Name {
    font-size: 40px;
    line-height: 40px;
    margin: 0;
    text-align: center;
  }
}
