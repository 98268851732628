.Reply-ServiceActions {
    position: absolute;
    right: -0;
    top: 0;
    text-align: right;
    display: none;
    z-index: 20;

    .Reply-ServiceActions-OpenBtn {
        font-size: 30px;
    }

    .Reply-ServiceActions-List {
        list-style: none;
        padding: 0;
        background-color: #fff;
        border: 1px solid #D3D9E0;
        box-shadow: 0px 0px 10px -5px rgba(0, 0, 0, .3);
        margin: 0 10px;
        padding: 10px;
    }
}

.Reply-ServiceActions_open {
    display: block;
}