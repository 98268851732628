@import "utils/src/global.scss";

.NotifiSettingTable {
  margin-bottom: calc(85px + 24px);
  &-Body {
    & > .Widget {
      padding: 24px;
    }
  }

  &-Container {
    overflow-x: auto;
  }

  &-Footer {
    position: fixed;
    bottom: 0;
    box-sizing: border-box;
    display: flex;
    justify-content: flex-end;
    padding: 24px;
    border: 1px solid $BorderColor;
    background-color: #fff;
    z-index: 10;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    button {
      margin-left: 24px;
    }
  }
}
