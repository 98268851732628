.NewsBannerTypeNews {
    padding: 12px;
    height: 100%;
    position: relative;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;

    &-Header {
        display: flex;
        align-items: center;
        margin-bottom: 8px;
    }

    &-Bottom {
        margin-top: auto;
        margin-bottom: 0;
        font-weight: 400;
        font-size: 14px;
        line-height: 143%;
        letter-spacing: 0.15px;
    }

    &-Content {
        flex: 1;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        margin-bottom: auto;
    }

    &-Title {
        box-sizing: border-box;
        overflow: hidden;
        font-weight: 500;
        font-size: 14px;
        line-height: 157%;
        letter-spacing: 0.1px;
        color: rgba(0, 0, 0, 0.87);
    }

    &-Side {}

    &-Text {
        flex: 1;
        overflow: hidden;
        max-width: 100%;
        text-align: left;
        font-weight: 400;
        font-size: 12px;
        line-height: 166%;
        letter-spacing: 0.4px;
    }

    &-Avatar {
        margin-right: 16px;
    }

    &-DisplayName {
        font-weight: 400;
        font-size: 14px;
        line-height: 143%;
        letter-spacing: 0.15px;
    }

    &-Type {
        font-weight: 400;
        font-size: 14px;
        line-height: 143%;
        letter-spacing: 0.15px;
        color: rgba(0, 0, 0, 0.38);
    }

    &-Action {
        padding-left: 0;
        padding-right: 0;
        font-size: 12px;
        line-height: 16px;
    }

    &_subtitle_badges &-Content {
        justify-content: center;
        align-items: flex-start;
        flex: none;
        &-Badge {
            padding: 8px 16px;
            background: #FFFFFF;
            border-radius: 4px;
            text-align: left;
            box-sizing: border-box;
        }
        .NewsBannerTypeNews-Title {
            box-sizing: border-box;
            overflow: hidden;
            font-weight: 400;
            font-size: 14px;
            line-height: 143%;
            letter-spacing: 0.15px;
        }
    }
}