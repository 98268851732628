.ResultActions {
    display: flex;
    justify-content: space-between;
    padding: 24px;

    &-Btn {
        min-width: 132px;
        border-radius: 8px;
        padding-left: 12px;
        padding-right: 12px;
        font-size: 14px;
        line-height: 19px;

        &_type_again {
            color: #0066cc;
        }
    }
}