.LMSTOStatFullSettings {
    &-Settings {
        // display: flex;
        border-bottom: 1px solid #D3D9E0;
        padding: 20px;
        // align-items: center;
        margin-bottom: 4px;

        &_isLoading {
            margin-bottom: 0px;
        }
    }

    &-Row {
        display: flex;
        align-items: center;
        margin-top: 4px;
    }

    &-StartDate {
        width: 140px;
        margin-right: 12px;
    }

    &-EndDate {
        width: 140px;
        margin-right: 12px;
    }

    &-Checkbox,
    &-RadioGroup {
        margin-top: 14px;
        margin-right: 12px;
    }
}