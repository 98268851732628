@import '../../utils/global.scss';

.Widget {
  box-sizing: border-box;
  border: 1px solid var(--border-color);
  background-color: var(--background-color);
  @include BorderRadius;
  position: relative;

  &_type_file {
    .Preview {
      .size_FULL {
        .Preview-Img {
          max-height: 100%
        }
      }
    }
  }

  &_type_grid {
    border: 0;
    background: none;
  }

  &_edit {
    min-height: 40px;
  }

  &:not(.Widget_edit):empty {
    display: none;
  }

  &_small {
    overflow: hidden !important;
    height: 40px !important;
    min-height: 40px !important;
  }

  &-Blind {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgba($color: (
        #fff),
      $alpha: .75
      );
    z-index: 14;
    border-radius: 8px;

    &_transparent {
      background: transparent !important;
    }

    &_draging {
      background: rgba($color: (
          #fff),
        $alpha: .9
        );
    }

    &_draggingElem {
      background: rgba($color: (
          #c8c8c8),
        $alpha: .9
        );
    }
  }

  &:not(:last-child) {
    margin-bottom: 24px;
  }

  &_noBorderRadius {
    border-radius: 0;
  }

  &-Name {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 15;
    margin-right: auto;
    padding: 6px 15px;
  }

  &-Menu {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 15;
    display: flex;
    align-items: center;

    .Button {
      padding: 5px;
    }
  }

  &-Overlay-H,
  &-Overlay-V {
    pointer-events: none;
    top: 0;
    height: 100%;
  }

  &-Overlay-H {
    position: fixed;
    left: 0;
    width: 100%;
    z-index: 91;
  }

  &-Overlay-V {
    position: absolute;
  }

  &-SliderSize {
    position: absolute;
    top: 25px;
    left: 0;
    z-index: 15;
    margin-right: auto;
    padding: 6px 15px;
    // max-width: 200px;
  }
}

.WidgetMui_box_shadow {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12);
}
.Widget.Filters:not(.Filters_type_timeline) {
  padding: 1px 0 0 0;
  // padding-top: 24px;
}

.WidgetMui {
  padding: 24px;
  border-radius: 4px;
  background: #FFFFFF;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12);
  border: none !important;
}

.Widget.Widget_type_calendarmini,
.Widget.Widget_type_wiki,
.Widget.Widget_type_users,
.Widget.Widget_type_tabs>.TabsMui,
.Widget.InfoBlock,
.Widget.SurveyMui,
.Widget.Quiz,
.Widget.SliderLinkMui,
.Widget.PageCommentsWidget,
.Widget.PresentsNew,
.Widget.Widget_type_timeline.Widget_timelineType_slider, 
.Widget.AboutWidget {
  border-radius: 4px;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12);
  border: none !important;
}
.Widget.VirtcurrencyNew.WidgetMui{
  padding:14px
}
.Widget.Filters-Calendar,
.Widget.Filters {
  border-radius: 4px;
  border: none !important;
  // padding-top: 24px;
}

.Widget.SecretsWidget {
  border: none !important;
}