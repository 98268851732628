.LeftMenuBarMui {
    box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.14);    
    width: 300px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    min-width: inherit;
    transition: min-width 0.25s ease-in-out;

    &_opened {
        min-width: 300px;
    }

    &-Sortable {
        overflow-x: auto;
        overflow-y: scroll;
        flex: 1;
        // margin-right: -17px;    
    }

    /* Скрываем scrollbar для Chrome, Safari и Opera */
    &-Sortable::-webkit-scrollbar {
        display: none;
    }
    
    /* Скрываем scrollbar для IE, Edge и Firefox */
    &-Sortable {
        -ms-overflow-style: none;  /* IE и Edge */
        scrollbar-width: none;  /* Firefox */
    }
}

.Layout.Mac .LeftMenuBarMui,
.Layout.Linux .LeftMenuBarMui {

    &-Sortable {
        // margin-right: 0; 
    }
}