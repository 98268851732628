.ChangePasssword {
    &-Msg {
        margin: 24px;
        // color: #721c24;
        // background-color: #f8d7da;
        // border-color: #f5c6cb;
        // position: relative;
        // padding: .75rem 1.25rem;
        margin-bottom: 1rem;
        // border: 1px solid transparent;
        // border-radius: .25rem;
    }
}