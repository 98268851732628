.NewsBanner {
    border: 1px solid #ccc;
    border-radius: 8px;
    background-color: #fff;
    margin: 24px 0;

    &-Header {
        margin: 16px 24px;
        font-weight: bold;
        font-size: 14px;
        line-height: 24px;
    }

    &-Content {
        display: flex;
        justify-content: space-between;
        overflow: hidden;
        margin-bottom: 24px;
        margin-top: 24px;
    
    }

    &-Banner {
        margin: 0 8px;
    }

    &-NavBox {
        width: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &-NavBtn {
        height: 100%;
        transition: all .3s ease-in-out;
    }

    &-NavIcon {}
}