.NotifiSettings {
  margin-top: 24px;
  &-Header {
    padding: 24px;
    &-Content {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
    }
    &-Avatar {
      width: 185px;
      height: 185px;
      border-radius: 50%;
      background: #194776;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 12px;
    }

    &-Info {
      max-width: 500px;
      &-Title {
        margin-top: 24px;
        font-weight: bold;
        font-size: 24px;
        line-height: 33px;
      }
      &-Comeback {
        position: absolute;
        top: 6px;
        left: 12px;
      }
      &-Aditional {
        span {
          font-weight: bold;
        }
      }
    }

     &-Tabs {
      display: flex;
      justify-content: center;
      margin-top: 24px;
    }
  }
  &-Content {
    margin-top: 24px;
  }
}