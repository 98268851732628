.LeftMenuItemMui {

    &:hover,
    &_selected {
        background: rgba(0, 0, 0, 0.04);
    }

    .MuiListItemSecondaryAction-root {
        display: flex;
        align-items: center;

        > .MuiSvgIcon-root {
            pointer-events: none;
        }
    }

    &[data-id="service"] {
        color: #06c;

        .MuiListItemIcon-root {
            color: inherit;
        }
    }
}