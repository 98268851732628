.EditorLongread {
    &-Short {
        border-bottom: 1px solid #D3D9E0;
        border-top: 1px solid #D3D9E0;

        .TabledEditor {
            .DraftEditor-editorContainer {
                min-height: 70px;
            }
        }
    }
}