.SurveyWidgetItem {
  // display: flex;
  // &-SurveyAvatar{
  //   width:40px;
  //   height:40px;
  //   margin-right: 16px;
  // }
    
    // flex-direction: column;
    padding: 0;
    &-Title {
      display: flex;
      flex-direction: column;
      // justify-content: center;
      // align-items: center;
       &-Image {
        margin-bottom: 4px;
        background-size: contain;
        width: 100%;
        height: 160px;
      }
      &-Text {
        font-size: 14px;
        line-height: 20.02px;
        width: 100%;
        margin-bottom: 12px
      }
      .MuiButtonBase-root.MuiButton-root.MuiButton-contained{
        text-transform: uppercase;
        width: 70%;
        font-size: 13px;
      }
    }
    &-Text {
      font-weight: 12;
      font-weight: normal;
    }
    a {
      margin-top: 6px;
    }
  }