.LMSTOStatCoursesList {
    background-color: #fff;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    &-Help {
        cursor: help;
        text-decoration: underline;
        text-decoration-color: #084ca1;
        text-decoration-style: dotted;
    }
    &-Header {
        background-color: #f7f7f7;
        padding: 24px;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        display: flex;
        justify-content: space-between;
        flex: 1;
        width: 100%;
        box-sizing: border-box;
    }

    &-Progress {
        // flex: 1;
        width: 100%;
        // box-sizing: border-box;
    }

    &-Trigger {
    }

    &-SearchBox {
        width: 528px;
    }

    &-Col1 {
        width: 230px;
    }

    &-ColAdditionl {
        max-width: 120px;
    }

    &-TableContainer {
        flex: 1;
        width: 100%;
        overflow: auto;
    }

    &-Table {
        min-width: 100%;
        position: relative;
        border-collapse: collapse;
        text-align: center;
        margin-top: 4px;

        &_isLoading {
            margin-top: 0px;
        }

        thead {
            th {
                // position: sticky;
                // top: 56px;
                background-color: #fff;
                white-space: nowrap;
                padding-left: 8px;
                padding-right: 8px;
                border-bottom: 1px solid #d3d9e0;
            }
        }

        th {
            padding: 12px 0;
        }

        td,
        th {
            border-bottom: 1px solid #d3d9e0;
        }
    }
}
