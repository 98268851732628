.Modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0);
  z-index: 9999;
  transition: background-color .25s ease-in-out;
  display: flex;
  align-items: center;
  padding: 10px;
  box-sizing: border-box;

  &_init {
    background-color: rgba(0, 0, 0, 0.75);
  }

  &-Window {
    box-sizing: border-box;
    background-color: #f7f7f7;
    border: 1px solid #d3d9e0;
    width: 100%;
    max-width: 700px;
    // position: absolute;
    // top: -100%;
    // left: 0;
    transition: transform .35s ease-in-out;
    // flex: 1 1 100%;
    transform: translate(0, -500%);
    margin: auto;
    border-radius: 8px;
    height: unset;

    &_init {
      // top: 50%;
      transform: translate(0, 0);
    }
  }
}

@media all and (-ms-high-contrast: none),
(-ms-high-contrast: active) {

    /* IE10+ CSS styles go here */

}