// @import '../../../utils/global.scss';

.SliderIdeasView {

    // padding-top: 18px;

    &-Render {
        font-size: 12px;
        line-height: 166%;
    }

    &-Header {
        height: 84px;
        margin: 0 24px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
    }

    &-Author {
        // margin-bottom: 8px;
        min-height: 62px;
        padding-bottom: 8px;
        padding-top: 8px;
        border-bottom: none !important;
    }

    &-HeaderBox {
        // width: 240px;
        // height: 84px;
        // margin: 0 auto;


        &-TextBox {
            width: 100%;
            height: 40px;
            background: #FFF3BD;
            border-radius: 60px;
            box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2);
            filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.14)) drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.12));
            font-weight: 500;
            font-size: 14px;
            line-height: 157%;
            display: flex;
            align-items: center;
            justify-content: center;

            &-Text {
                margin: 0 4px;
            }

            &-Currency {
                // background: #FFFFFF;
                border-radius: 20px;
                width: 80px;
                height: 24px;
                display: flex;
                align-items: center;
                text-align: center;
                position: relative;
                z-index: 100;

                span {
                    background: #FFFFFF;
                    border-radius: 20px;
                    width: 80px;
                    height: 24px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }

            &-Currency::before {
                content: "";
                position: absolute;
                width: 72px;
                height: 62px;
                top: -37px;
                left: 50%;
                transform: translateX(-50%);
                background-image: url("./IdeaImg.svg") !important;
                background-position: center;
                background-size: cover;
                background-repeat: no-repeat;
                z-index: -1;
            }
        }
    }



    &-Content {
        padding: 24px;
        padding-top: 8px;
        padding-bottom: 16px;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 166%;

        // height: 280px;
        // overflow-y: auto;
        // border-bottom: 1px solid #d3d9e0;
        &-Title {
            margin: 4px 0;
            font-style: normal;
            font-weight: bold;
            font-weight: 500;
            font-size: 14px;
            line-height: 157%;
            // white-space: nowrap;
            // overflow: hidden;
            // text-overflow: ellipsis;
            // cursor: pointer;
        }

        &-NewsText {
            margin-bottom: 8px;
        }

        &-Footer {
            margin-top: 8px;
        }

        &-Status {
            background: #F5F5F5;
            border-radius: 4px;
            padding: 8px;
            font-size: 12px;
            line-height: 166%;

            span {
                font-weight: 400;
                // font-size: 12px;
                // line-height: 166%;
                color: rgba(0, 0, 0, 0.6);
            }
        }
    }



    // &-Render {
    // }

    // &-Link {
    //     display: block;
    // }
}