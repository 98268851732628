@import '../../utils/global.scss';

.Invite {
  padding: 20px 24px;
  &-Form {
    background-color: var(--background-color);
    @include BorderRadius;
    overflow: hidden;
    padding: 24px 0;
  }
  &-ToggleButton {
    display: block;
    width: 100%;
    text-align: left;
    height: 40px;
    line-height: 40px;
    padding-top: 0;
    padding-bottom: 0;
  }
  &-ToggleIcon {
    margin-right: 8px;
  }
  &-SlideDown {
    margin-top: 10px;
  }
  &-Submit {
    margin-top: 24px;
    border-radius: 2px;
    padding-top: 10px;
    padding-bottom: 10px;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.3);
    display: block;
    margin: auto;
  }
}
