.EditPositions{
    text-align: left;
    margin: 10px 0 0 0;

    &-active{
        background: yellow;
    }
    &-Position{
        &-Type{
            // border: 2px solid #c3c3c3;
        }
        &-Description{
            text-align: left;
            line-height: 1;
            font-size: 12px;

        }
        .InputMention{
            border: 2px solid #c3c3c3;
        }
    }
    &-Input{        
        padding-left: 0px; 
        margin: 10px 0;
        border: 2px solid #c3c3c3;
    }
    &-Vacancy{
    margin: 0 0 10px;
        .MuiFormControl-root {
            margin: 0 0 10px 0;
        }
    }
    &-UploadVacancy{
        display: flex;
        align-items: center;
        
        &-UploadButton{
            margin: 0 20px 0 0;
        }
    }
    &-Attachments{
        padding: 0;
        margin: 0 10px 0 0;
    }
    &-AddPosition{
        &-Actions{
            display: flex;
            justify-content: flex-end;
            height: 50px;
            align-items: center;
            &-CancelBTN{
                color: #0066CC;
                margin-right: 15px;
            }
            &-SaveBTN{
                padding: 0 16px;
            }
        }
    }
    
}

