@import '../../../utils/global.scss';

.NewsContentView {

    // padding-top: 18px;
    

    &-Header {
        // margin-bottom: 8px;
        min-height: 62px;
        padding-bottom: 8px;
        padding-top: 8px;
        border-bottom: 1px solid #d3d9e0;
    }

    &-Title {
        margin: 4px 0;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 18px;
        // white-space: nowrap;
        // overflow: hidden;
        // text-overflow: ellipsis;
        // cursor: pointer;
    }

    &-Content {
        padding: 24px;
        padding-top: 8px;
        padding-bottom: 16px;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;
        // height: 280px;
        // overflow-y: auto;
        // border-bottom: 1px solid #d3d9e0;
        
    }

    &-Footer {
        margin-top: 8px;
    }

    &-Render {
    }

    &-Link {
        display: block;
    }
}
