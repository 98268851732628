.Group {
  
  .Widget_type_info {
    // border-bottom: 0;
    border-radius: 8px;
  }
  .Widget_type_tabs {
      // margin-top: 0px;
    .Tabs-Nav{
      border-radius: 8px;
      border-top: 1px solid #d3d9e0;
    }
  }
  .Widget_type_info + .Widget_type_tabs {
    // margin-top: 0px;

    .Widget_header {
      border-top: 0;
    }
  }
}