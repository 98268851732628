#StatusEditor {
  position: relative;
}

.User-Status {
  position: absolute;
  top: -12px;
  left: -19px;
  padding: 10px 15px;
  background: #fff;
  width: 100%;
  border: 1px solid #d3d9e0;
  z-index: 10;
  
  &-Ghost,
  &-Textarea {
    border: 1px solid #d3d9e0;
    font-family: 'Open Sans regular';
    padding: 2px;
    width: calc(100% + 4px);
    white-space: pre-wrap;
    overflow-wrap: break-word;
    box-sizing: border-box;
  }

  &-Ghost {
    z-index: 0;
    position: absolute;
    visibility: hidden;
  }

  &-Textarea {
    display: block;
    resize: none;
  }

  &-Buttons {
    text-align: right;
    margin-top: 5px;
  }
}