@import '../../utils/global.scss';

body.hideScroll .Header {
  padding-right: 41px;
}
.InfoIcon {
  transition: font-size $TransitionDuration $TransitionTimingFunction;
  z-index: 1000;
}
.Header {
  border-bottom: 1px solid var(--border-color);
  background-color: $HeaderBackground;
  position: fixed;
  height: 56px;
  line-height: 56px;
  top: 0;
  left: 0;
  z-index: 90;
  width: 100%;
  box-sizing: border-box;
  padding: 0 24px 0 65px;
  display: flex;
  @include Transition(padding-left);

  &-TitleLogo {
    height: auto;
    display: block;
    align-self: center;
    max-height: 100%;
    max-width: 100%;

    @media screen and (max-width: 768px) {
      // display: none;
    }
  }

  &-TitleButton {
    display: block;
    width: 120px;

    @media screen and (max-width: 768px) {
      // display: none;
    }
  }


  // transition: padding-left .25s ease-in-out;

  &-Inner {
    margin: 0 auto;
    width: 100%;
    height: 100%;
    max-width: 1052px;
    position: relative;
    display: flex;
    align-items: center;
  }

  &-SearchInput {
    margin: 0 auto;
    // flex-grow: 2;
    flex-basis: 100%;
    max-width: 620px;

    @media screen and (max-width: 500px) {
      position: absolute;
      width: calc(100% - 33px);
      top: 50%;
      transform: translateY(-150%);
      opacity: 0;
      background-color: var(--background-color);
      transition: all 0.3s ease-in-out;

      &_shown {
        transform: translateY(-50%);
        opacity: 1;
      }
    }
  }

  &-Button {
    height: 100%;
    position: relative;
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) { 
      overflow: visible;
    }

    &-Icon {
      font-size: 18px;
    }

    &_menuToggle {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 24px;
    }

    &_leftSide {
      margin-right: 2%;
    }

    &_rightSide {
      margin-left: 2%;
    }

    &_searchToggle {
      margin-left: auto;

      @media screen and (min-width: 501px) {
        display: none;
      }
    }

    &-EditType {
      display: block;
      width: 100%;
      text-align: left;
      &-VideoHelp{
        display:flex;
        align-items: center;
        padding-right: 8px;
        i{
          cursor: pointer;
        }
      }
      // &:hover {
      //   color: #fff;
      //   background-color: var(--main-color);
      // }
    }

    &_notificationCount {

      &.Space {
        margin-right: 12px;
        
      }
    }
  }
}

@media screen and (min-width: 768px) {
  .Header {
    padding-left: 80px;
  }

  .Header-Button_menuToggle {
    display: none;
  }
}

.ChatList_Buttons {
  display: flex;
  justify-content: space-between;
  padding: 10px 24px;
  border-top: 1px solid #d3d6e0;
}

.Header-PlayerItem {
  border-bottom: 1px solid #d3d9e0;
}


.Header-PupupLayout {
  width: 450px;
  // background-color: #fff;
  // border: 1px solid var(--border-color);
  // box-shadow: 0 10px 15px -10px #000;
}

.Header-PupupLayout-Notifications {
  // width: 450px;
  max-height: 500px;
  // overflow-y: auto;
  // background-color: #fff;
  // border: 1px solid var(--border-color);
  // box-shadow: 0 10px 15px -10px #000;
}

.Header-NotificationCouner {
  position: absolute;
  right: -15px;
  top: 5px;
}