@import '../../../utils/global.scss';

.Modal-Header {
  position: relative;
  padding: 12px 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;

  &_border {
    border-bottom: 1px solid $BorderColor;
  }

  &-Text {
    margin: 0;
  }
}