.Page {
  margin: 24px 2%;

  &[class*="Page_type_mobile"] {
    width: 500px;
    margin: 24px auto;
  }

  &-Menu {
    width: 100%;
    display: flex;

    &:not(&_init) .Button_create {
      width: 100%;
      display: block;
    }

    &_init {
      justify-content: flex-end;
    }
  }

  &-Layout {
    // display: flex;
    // margin: 24px 2%;
  }

}