.NewsBannerTypeGroup {
    background-color: #F7F7F7;
    padding: 12px;
    padding-top: 12px;
    padding-bottom: 12px;
    height: 100%;
    position: relative;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;

    &-UserList {
        // height: 40px;
        width: 100%;
    }

    &-Content {
        // flex: 1;
        // display: flex;
        // flex-direction: column;
        overflow: hidden;
        margin: 8px 0;

    }

    &-AvatarBox {
        // height: 64px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }

    &-UserListBox {
        // margin-right: 15px;
        // margin-left: 15px;
        // text-align: left;
        width: 100%;

        .UserList_underNext {
            padding-left: 12px;
        }
    }

    &-Text {
        overflow: hidden;
        max-width: 100%;
        text-align: left;
        // font-size: 12px;
        line-height: 16px;
        margin-bottom: auto;
        font-weight: 400;
        font-size: 12px;
        line-height: 166%;
        color: rgba(0, 0, 0, 0.87);
    }

    &-Bottom {
        background: #F5F5F5;
        margin-top: auto;
        display: flex;
        flex-direction: column;
        align-items: self-start;
    }

    &-Action {
        font-weight: 400;
        font-size: 14px;
        line-height: 143%;
        letter-spacing: 0.15px;
        padding: 8px 0 0 0;
    }

    &-Title {
        font-weight: 500;
        font-size: 14px;
        line-height: 157%;
        letter-spacing: 0.1px;
        color: rgba(0, 0, 0, 0.87);
    }
}