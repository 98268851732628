@import '../../../../utils/global.scss';

.CommentsLayout {
        
    &-More {
        background-color: #F7F7F7;
        border-top: 1px solid #D3D9E0;
        &:empty {
            display: none;
        }
    }

    &-Comments {
        // padding-bottom: 8px;
        background-color: #F7F7F7;
        border-top: 1px solid #D3D9E0;
        @include BorderRadiusBottom();
        &:empty {
            display:none;
        }
    }

    .MoreButton {
        display: block;
        width: 100%;
        padding: 8px;
        position: relative;
    }

    &-Form {
        border-bottom-right-radius: 8px;
        border-bottom-left-radius: 8px;
        background-color: #F7F7F7;
        border-top: 1px solid #D3D9E0;
        &:empty {
            display: none;
        }
    }
}