.ScrollBar {
  margin-left: auto;
  margin-top: 10px;
  display: flex;
  height: 32px;
  border-radius: 6px;
  background-color: #eaeaea;
  &-Bar {
    flex-grow: 2;
    height: 100%;
    position: relative;
    margin: 0 2px;
  }
  &-Slider {
    height: 100%;
    border-radius: 6px;
    position: absolute;
    background-color: #d2d1d1;
    touch-action: none;
  }
  &-Button {
    color: var(--invariant-text-color);
    background-color: var(--highlight-color);
    border: none;
    border-radius: inherit;
  }
}
