@import '../../utils/global.scss';

.Timepicker {
  position: relative;
  
  &-Icon {
    position: absolute;
    font-size: 16px;
    width: 16px;
    text-align: center;
    top: 50%;
    margin-top: -8px;
    left: 15px;
  }

  &-Input {
    padding: 11px 10px 10px 45px;
    border: 0px;
    width: 100%;
    box-sizing: border-box;
    font-family: $FontFamilyRegular;
    text-align: inherit;
  }

  &-ButtonWrapper {
    position: absolute;
    // width: calc(100% - 55px);
    // min-width: 73px;
    margin-left: 45px;
    // padding-right: 10px;
    transition: all .5s ease-in-out;
    overflow: visible;

    .Button {
      width: 25px;
      height: 25px;
    }

    &_onTop,
    &_onBottom {
      z-index: 2;
    }
    
    &_top {
      top: 0;
      opacity: 0;
    }

    &_onTop {
      top: -26px;
      opacity: 1;
    }

    &_bottom {
      bottom: 0;
      opacity: 0;
    }

    &_onBottom {
      bottom: -26px;
      opacity: 1;
    }
  }
}