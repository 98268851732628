.NewsEdit-Body_type_events {
    .EventEdit-Expires {
        display: flex;
        border-top: 1px solid #D3D9E0;
        border-bottom: 1px solid #D3D9E0;
    }

    .EventEdit-TextareaMentions {
        border-top: 1px solid #D3D9E0;
    }

    .EventEdit-ExpiresItem {
        flex: 1 1;

    }

    .EventEdit-Datapicker {
        border-right: 1px solid #D3D9E0;
    }

    .EventEdit-Timepicker {
        border-right: 1px solid #D3D9E0;
    }

    .EventEdit-Select {
        .Select-Inner {
            background-color: transparent;

        }
    }
}