.Widget_type_info {
    border: none;
    background: transparent;
    // передаем стили для сохранения вида инфоблоко бейджей, одного бейджа и групп
    .InfoBlock-Group {
        border: 1px solid #d3d9e0;
        background-color: #ffffff;
        border-radius: 8px;
    }
    .InfoBlock-Badges {
        border: 1px solid #d3d9e0;
        background-color: #ffffff;
        border-radius: 8px;
        padding: 24px;
    }
    .InfoBlock-Badge {
        border: 1px solid #d3d9e0;
        background-color: #ffffff;
        border-radius: 8px;
    }
}