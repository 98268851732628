.Creativetasks {
    &-Layout {
        background-color: #d4f0fa;
        margin: 60px 0 0;
        padding-left: 16px;
        padding-right: 16px;
        border-top: 1px solid #d3d9e0;
        padding-bottom: 42px;

        @media (min-width: 576px) {
            padding-left: 24px;
            padding-right: 24px;
        }
    }
}