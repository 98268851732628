.List-Header_type_common {

  background-color: var(--background-highlight-color);

  .List-Header {

    &-Search {
      flex-basis: 100%;
      // flex-grow: 1;
      // margin-right: auto;
      // max-width: 500px;
      // &_single {
      //   margin-left: auto;
      // }
    }

    &-Actions {
      flex-basis: 25%;
      margin-left: auto;

      .Dropdown-Button {
        width: 100%;

        *:first-child {
          flex: 1;
        }
      }
    }

    &-Checkbox {
      margin-right: 8px;
    }

    &-Count {

      &_text {
        padding-left: 0px;
        display: flex;
        align-items: center;
        .MuiFormControlLabel-root {
          margin-right: 0px;
        }
        .MuiButtonBase-root.MuiIconButton-root{
          color: rgba(0, 0, 0, 0.54); 
        }

        .Checkbox {
          margin-right: 15px;
        }
      }

      &_actions {

        .Button {
          padding: 0px 4px;
        }
      }
    }
  }
}