.FilesHeader {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    background-color: var(--background-highlight-color);
    border-bottom: 1px solid var(--border-color);
    // display: flex;
    // align-items: center;
    padding: 24px;

    &:empty {
        display: none;
    }

    &-UplBtn {
        padding-left: 8px;
        padding-right: 8px;
        .Icon {
            margin-left: 8px;
        }
    }

    &-SearchBox {
        margin-bottom: 12px;
    }

    &-Search {
        max-width: none;
    }

    &-Actions {
        display: flex;
        align-items: center;

        &:empty {
            display: none;
        }
    }

    &-SelectsBox {
        flex: 1;
        display: flex;
        align-items: center;
    }

    &-Checkbox {
        width: auto;
        padding: 0;
        margin-right: 6px;
    }

    &-SelectCount {
        padding: 0 24px;
    }

    &-CreateFolder {
        margin: 0 12px;
    }

    &-ActionMenu {
        margin: 0 6px;
        &.SplitButton>.Button {
            padding: 5px;
        }

        &.SplitButton>.Dropdown>.Button {
            padding: 0px 5px;
        }
    }

    &-SelectItem {
        padding: 12px;
    }
}