.Modal-Body_type_layoutGrid {
  display: flex;
  padding: 15px;
  
  .LayoutSelector {
    padding: 15px;
    display: flex;
    flex: 1 1;
    flex-wrap: wrap;
    text-align: center;
    margin-left: 5px;

    &:nth-child(n + 2) {
      margin-left: 15px;
    }

    &-Info {
      width: 100%;
      margin-bottom: 10px;
      white-space: nowrap;
    }

    .Selected {
      visibility: hidden;
    }

    &_selected .Selected {
      visibility: visible;
    }

    &-Column {
      flex: 1 1;
      background-color: #f1f1f1;
      height: 200px;
      margin: 0 3px;
    }

    &:hover,
    &_selected {
      background-color: #06c;
      color: #fff;
    }
  }
}