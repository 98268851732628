@import '../../List.scss';

.List_type_wikilist {
  .bulitsFull{
    background-color: #fff;
    display: flex;
    flex-direction: column;
    // align-items: center;
    padding: 5px 24px;
    border: 1px solid #e3e6e9;
    margin-bottom: 24px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;

 
    
    .bulitsForSearch{ 
      display: flex;
      width:100%;
      align-items: center;
      .wikiType{
      font-weight: 600;
      margin-right: 24px;
      font-size: 1rem;
      font-family: "Roboto", "Helvetica", "Arial", sans-serif;
      line-height: 1.5;
      letter-spacing: 0.00938em;
    }
  }
   
  }
  .bulitsShort{
    background-color: #fff;
    display: flex;
    align-items: center;
    padding: 5px 24px;
    border: 1px solid #e3e6e9;
    margin-bottom: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    .wikiType{
      font-weight: 600;
      margin-right: 24px;
    }
  }
  .List-Header.shortList{
        margin-bottom: 0;
        border-radius: 8px 8px 0 0;
  }
  .List-Header.withCrumbs{
        // border-radius: 0 0 8px 8px;
        border-top: none;
  }
  .List-Header.shortWithCrumbs{
        border-radius: 0;
        border-top: none;
        margin-bottom: 0;
  }
  .SearchInput{
    max-width: 100%;
  }
  .List {

    &-Header {

      // margin-bottom: 0;
      // margin-bottom: 24px;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
    

    &-Button {

      &_more {
        display: block;
        width: 100%;
        border-radius: 8px;
      }
    }
  }
  .Crumbs{
    background:#f7f7f7;
    display: flex;
    border-radius: 8px 8px 0 0;
    padding: 0 13px;
    border: 1px solid #e3e6e9;
    border-bottom: none;
  }
  .stub{
    background-color: var(--background-color);
    width: 100%;
    height: 352px;
    border-radius: 8px;
    text-align: center;
    padding-top: 40px;
  }
  .stubShort{
    background-color: var(--background-color);
    width: 100%;
    height: 352px;
    border-radius: 0 0 8px 8px;
    text-align: center;
    padding-top: 40px;
  }
}