.CTShopItem {
  margin-bottom: 24px;
  &-Header {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 24px;
    border-bottom: 1px solid #d3d9e0;
    padding: 8px 0;
    &-Info {
      display: flex;
      align-items: center;
      padding: 8px 24px 8px 6px;
      margin: 0 6px;
      border-radius: 50px;
      img {
        height: 32px;
        margin-right: 6px;
      }
      span {
        font-size: 24px;
        line-height: 1;
      }
      &.Order {
        background: #F9CDBA;
      }
      &.Deleted {
        background-color: #eee;
      }
    }
    
    & > span {
      font-weight: bold;
      font-size: 16px;
    }
  }

  &-Content {
    padding: 24px;
    display: flex;

    & > div {
      width: 50%;
      margin-bottom: 12px;
      div:first-child {
        span {
          font-weight: bold;
        }
        div {
          font-size: 12px;
          color: #999;
          line-height: 1;
        }
      }
      div:last-child {
        span {
          font-weight: bold;
          font-size: 16px;
        }
        div {
          font-size: 16px;
          line-height: 1;
        }
      }
    }

    & > a {
      width: 35%;
      margin-right: 24px;
      img {
        width: 100%;
        height: auto;
        display: block;
        border: none;
        padding: 0;
        border-radius: 8px;
      }
    }
  }
}