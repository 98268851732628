@import '../../../utils/global.scss';

.LeftMenuRemover {
  width: 100%;
  @include BorderRadius;
  border: 2px dashed $BorderColor;
  background-color: #fff;
  transition: color $TransitionDuration $TransitionTimingFunction, background-color $TransitionDuration $TransitionTimingFunction;
  padding: 8px 16px;
  text-align: center;
  box-sizing: border-box;

  &_isOver {
    background-color: #f00;
    color: #fff;
  }
}