.SecretSantaWidget {
    min-height: 225px;
    &-Header {
        text-align: left;

        &-Icon {
            width: 100%;

            .Button.Button_theme_unstyled.Button_type_rlink {
                width: 100%;
            }
        }

        &-Title {
            margin-bottom: 8px;

            span {
                margin: 6.5px 0;
                font-size: 16px;
                font-weight: 700;
                color: #000;
            }
            // margin-bottom: 8px;

            a {
                font-weight: 700;
                font-size: 16px;
                line-height: 116%;
                letter-spacing: 0.15px;
                padding: 6.5px 0;
                margin-bottom: 8px;
                color: rgba(0, 0, 0, 0.87) !important;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                width: 245px
            }

            // span {
            //     margin: 6.5px 0;
            //     font-size: 16px;
            //     font-weight: 700;
            //     color: #000;
            // }
        }

        &-TitlePresent {
            span {
                font-size: 15px;
                font-weight: 700;
            }
        }
    }

    &-AdditionalInfo {
        &-Date {
            background-color: #F5F5F5;
            text-align: left;
            padding: 8px;
            border-radius: 4px;
        }

        &-Description {
            padding: 12px 0 0 0;
            margin-bottom: 0px;

            p {
                margin: 0;
                padding: 0;
                font-weight: 400;
                font-size: 12px;
                line-height: 166%;
                letter-spacing: 0.4px;
                color: rgba(0, 0, 0, 0.87);
            }
        }

        &-DestributionOver {
            text-align: left;
            // font-style: italic;
            // font-size: 16px;
            font-size: 12px;
            line-height: 166%;

            &-Loading {
                white-space: nowrap;
            }
        }

        &-Info {
            display: flex;
        }

        &-Timer {
            display: flex;
            align-items: center;
            .MuiCircularProgress-root.MuiCircularProgress-colorPrimary.MuiCircularProgress-indeterminate{
                margin-left: 5px;
            }
            .DistributeTimer {
                font-weight: 400 !important;
                font-size: 12px;
                line-height: 166%;
                letter-spacing: 0.4px;
                color: rgba(0, 0, 0, 0.87);

                .DistributeTimer-TimeCounter-Days,
                .DistributeTimer-TimeCounter-Hours,
                .DistributeTimer-TimeCounter-Minutes {
                    font-weight: 400;
                    padding: 0 2px 0 4px;
                }

                .DistributeTimer-TimeCounter-Text {
                    font-weight: 400;
                }

            }

            &-Span {
                font-weight: 400;
                font-size: 12px;
                line-height: 166%;
                letter-spacing: 0.4px;
                color: rgba(0, 0, 0, 0.6);
            }
        }

        &-Span {
            
            font-weight: 400;
            font-size: 12px;
            line-height: 166%;
            letter-spacing: 0.4px;
            color: rgba(0, 0, 0, 0.6);
        }

        &-participantsCount {
            margin-left: 5px;
            font-weight: 400;
            font-size: 12px;
            line-height: 166%;
            letter-spacing: 0.4px;
            color: rgba(0, 0, 0, 0.87);
        }
    }

    &-UserAdditionalInfo {
        &-Avatar {
            align-items: center;
            display: flex;

            span {
                margin-left: 5px;
            }
        }

        &-Description {
            &-Box {
                background-color: #F5F5F5;
                text-align: left;
                padding: 8px;
                border-radius: 4px;
                font-size: 12px;
                line-height: 166%;
            }

            .CommonmarkRender-Paragraph {
                margin: 12px 0;
                line-height: 1.3em;
                font-size: 12px;
                line-height: 166%;
            }
        }

        &-Info {
            display: flex;

            span {
                color: #999;
                margin-right: 5px;
            }

            p {
                padding: 0;
                margin: 0;
            }
        }
    }

    &-SaveButton {
        margin-top: 20px;
        
        &-Submit,
        &-Unparticipate {
            align-self: flex-start;
        }

        .MuiButtonBase-root {
            text-transform: uppercase;
        }

        .MuiButtonBase-root.SecretSantaWidget-SaveButton-Unparticipate {
            align-self: flex-start;
        }
    }
}
