.UsersWidgetHierarchy-Item:hover {
  .UsersWidgetHierarchy-Delete {
    opacity: 1;
    width: auto;
    height: 34px;
  }
}


.UsersWidget_type_hierarchy {}

.UsersWidget_type_hierarchy_HBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
}

.UsersWidget_type_hierarchy_input {
  flex: 1;


  .MuiAutocomplete-tag {
    max-width: 130px;
  }
}

.UsersWidget_type_hierarchy_name {
  flex: 1;
}

.UsersWidget_type_hierarchy_add {
  // margin-top: 24px;
  margin-left: 12px;
}

.UsersWidgetHierarchy {

  &-Delete {
    opacity: 0;
  }

  &-Item {
    display: flex;
    align-items: flex-start;
    gap: 16px;
    width: 100%;


    &-Info {
      margin-left: 12px;
      min-width: 0;

      a {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: rgba(0, 0, 0, 0.87);
        font-feature-settings: 'clig' off, 'liga' off;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        letter-spacing: 0.15px;
        width: 100%;
      }

      p {
        margin: 0;
        line-height: 1;
      }
    }
  }
}