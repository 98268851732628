.StageBar {
    border: 2px solid #e5cd54;
    // width: max-content; //fit-content;
    padding: 5.5px 0;
    border-radius: 8px;
    background-color: #ffffff;
    max-width: 99%;
    // position: relative;
    // transition: all 0.3s linear;
    @media (max-width: 576px) {
        margin: 0 12px;
    }

    &-ButtonBox {
        border: none;
        .Button {
            min-width: 223px;
        }
    }

    &-Wrapper {
        display: flex;
        justify-content: center;
        // &_inRepost {
        //     .StageBar-Top,
        //     .StageBar-ButtonBox {
        //         display: block;
        //     }
        // }
    }

    &-Top {
        padding: 0 5.5px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }

    &-Controls {
        display: flex;
        justify-content: space-around;
    }

    &-Data {
        padding: 0 18.2px;
        line-height: normal;
        display: flex;
        flex-flow: column;
        justify-content: center;
    }

    &-Primary {
        display: flex;
        font-size: 14px;
        justify-content: center;
    }

    &-Second {
        text-align: center;
        color: #999999;
        font-size: 12px;
    }

    &-Content {
        display: none;
        margin-top: 5.5px;
        border-top: 2px solid #e5cd54;

        &:not(:empty) {
            display: block;
            animation: openbox 3.3s linear forwards;
        }
    }

    @keyframes openbox {
        from {
            // position: absolute;
            // margin-top: -100%;
            // transform: translate(0, -50%) scale(1, 0);
        }

        70% {
            // transform: translate(0, 0) scale(1, 1);
        }

        to {
            // position: static;
            // margin-top: 0;
            // transform: translate(0, 0) scale(1, 1);
        }
    }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    /* IE10+ CSS styles go here */
    .StageBar {
        display: flex;
        flex-wrap: wrap;
        max-width: 99%;

        &-ButtonBox {
            .Button {
                min-width: 223px;
            }
        }
    }
}
