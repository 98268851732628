.ThanksCounter {
    background-color: var(--background-highlight-color);
    display: flex;
    font-weight: bold;
    border-bottom: 1px solid var(--border-color);
    border-top: 1px solid var(--border-color);

    &-Item {
        flex: 1 1;
        text-align: center;
        padding: 5px 24px;

        &:first-of-type {
            border-right: 1px solid var(--border-color);
        }
    }

    &-Value {
        color: var(--highlight-color);
    }
}