.MoreCircle {
  border: 1px solid #d3d9e0;
  border-radius: 50%;
  width: 56px;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &-Dots {
    padding-bottom: 24px;
    color: #d3d9e0;
    font-size: 42px;
    line-height: 22px;
  }
}
