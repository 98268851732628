@import '../../../utils/global.scss';

/* autoprefixer grid: autoplace */

.Rating {
    &-Level {
        display: flex;
        align-items: center;
        padding: 15px;
        border-top: 1px solid var(--border-color);

        // border-bottom: 1px solid var(--border-color);
        // background-color: var(--background-color);
        // &:last-child {
        //   border-bottom: none;
        // }
        &:hover {
            // @include HoverShadow;
            // opacity: 0.99;
        }


    }

    &-LevelName {
        font-weight: bold;
    }

    &-LevelDesc {}

    &-LevelData {
        margin-left: 12px;
    }
}