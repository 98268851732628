.WidgetPosition {

    & > div > div > .Widget.SliderLinkMui  {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .Widget_timelineType_slider {
        display: flex;

        .NewsSlider,
        .NewsSliderNoData {
            flex: 1;
        }
    }

    // > div > div > 
    .Widget_type_blocks {
        display: flex;

        .Blocks {
            flex: 1;

            .MuiTabs-flexContainer {
                flex: 1;
            }
        }
    }

    .SurveyWidget-EmptyItem {

    }

    .Widget > .NewsSlider,
    .WidgetMui > .NewsSlider {
        display: flex;
        flex-direction: column;

        .MiniTimelineList-List {
            margin: auto 0;
        }
    }

    .Widget.Mood,
    .WidgetMui.Mood {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
}