.CourseTabs {
    display: flex;

    &-Button {
        padding: 12px;
        text-decoration: none;
        background-color: #ffffff;

        &_active {
            color: #000;
            clip-path: polygon(-1% -1%, 101% -1%, 101% 100%, calc(50% + 6px) 100%, 50% calc(100% - 8px), calc(50% - 6px) 100%, -1% 100%);
            // #triangle-up {
            //     width: 0;
            //     height: 0;
            //     border-left: 50px solid transparent;
            //     border-right: 50px solid transparent;
            //     border-bottom: 100px solid red;
            // }
        }
    }
}