.LeaderboardItem-Skeleton {
  display: flex;
  padding: 12px 24px;
  align-items: center;
  &:not(:first-child) {
    border-top: 1px solid var(--border-color);
  }
  &-Bar,
  &-Info {
    margin-left: 12px;
    @media (min-width: 576px) {
      margin-left: 24px;
    }
  }
}