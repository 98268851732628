.CurrencyTooltipItem {
  display: flex;
  &-Buble {
    font-size: 16px;
    line-height: 32px;
    border-radius: 50%;
    padding: 0 4px;
    padding-right: 6px;
    padding-top: 1px;
  }
  &-Left {
  }
  &-Right {
    padding-top: 2px;
    margin-left: 8px;
  }
  &-Icon {
  }
  &-Name {
    line-height: 12px;
    font-size: 12px;
  }
  &-Value {
    line-height: 14px;
    font-size: 14px;
    font-weight: bold;
  }
}
