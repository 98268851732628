.LMSTestInfoLayout {
    background-color: #ffffff;
    border-radius: 8px;
    // border: 1px solid #d3d9e0;

    &_fullscreen {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;

        .LMSTestInfoLayout-Actions {
            flex-direction: column;
            margin: 0 auto;
        }
    }

    &-Actions {
        flex-direction: column;
        margin: auto;
        width: 142px;
        display: flex;
        padding-top: 12px;
        padding-bottom: 24px;
    }

    &-ActionsBtn {
        display: block;
        // flex: 1;
        margin-top: 12px;
        margin-bottom: 12px;
        border-radius: 8px;

        &_type_skip {
            color: #0066cc;
        }
    }

    &-Title {
        margin: 0;
        padding: 24px;
        text-align: center;
        font-size: 24px;
        font-weight: bold;
    }

    &-Info {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 24px;
        // border-top: 1px solid #D3D9E0;
        border-bottom: 1px solid #d3d9e0;
    }

    &-PropertiesBox {
        display: flex;
        justify-content: center;
    }

    &-Description {
        padding: 0px 24px 24px 24px;
        margin-bottom: 24px;
        border-bottom: 1px solid #d3d9e0;

        &:empty {
            display: none;
        }
    }
}