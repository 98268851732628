.DistributeForm {
    &-Title {
        padding-left: 24px;
        border-bottom: 1px solid #ccc;
        background-color: #f7f7f7;
        padding-top: 12px;
        padding-bottom: 12px;
    }

    &-Stats {
        margin: 24px;
        margin-top: 0;
        flex: 1;
    }

    &-Actions {
        margin: 24px;
        margin-top: 0;
    }

    &-Section {
        display: flex;
        align-items: flex-start;
    }


    .ActionButtons-SaveBtn,
    .ActionButtons-CancelBtn {
        margin: 0;
        border-radius: 0;
    }

    .EditActions {
        .ButtonBox {
            margin: 15px 5px;
        }
    }
}