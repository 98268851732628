.ResultBody {
    &-Summary {
        display: flex;
        padding: 24px;
        justify-content: space-between;
    }

    &-SummaryContent {
        display: flex;
    }

    &-SummaryColumn {
        display: flex;
    }

    &-Chart {
        width: 140px;
        height: 140px;
        // margin-right: 24px;
        margin-left: 24px;
    }

    &-Item {
        // flex: 1;
    }

    &-Saved {
        margin: 24px;
    }

    &-Percent {
        font-size: 36px;
        color: #979797;
    }
}