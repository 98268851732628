@import 'blocks/../utils/global.scss';

.List-Header {
  background-color: var(--background-color);
  border: 1px solid var(--border-color);  
  padding: 12px 24px;

  &-Item {
    display: flex;
    flex-wrap: nowrap;

    > * {
      width: auto;
      white-space: nowrap;

      &:nth-last-child(n + 2) {
        margin-right: 20px;
      }
    }

    &:nth-child(n + 2) {
      margin-top: 15px;
    }
  }
}