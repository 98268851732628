.TabsControl {

  .Tabs {

    &-Nav {
      border-radius: 8px;
      border: 1px solid var(--border-color);
      background: #fff;
      

      &:before,
      &:after {
        display: none;
      }
    }

    &-Button {
      border-bottom: 0px;
      clip-path: none;
    }
  }

  &-Controls {
    display: flex;
    justify-content: space-between;
    background: transparent;
    border: 0;

    &_before,
    &_after {

    }
  }
}