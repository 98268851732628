@import '../../../../../utils/global.scss';

.IntercompanyGroupRequests {
  &-Item {
    background: transparent !important;
    border: 0 !important;
    border-top: 1px solid $BorderColor !important;
  }

  &-Item,
  &-Table {

    &-Dt {
      display: flex;
      flex-basis: 25%;
      align-items: center;
    }
  }

  &-Item {

    &-Header {
      display: flex;
      align-items: center;
      width: 100%;

      :last-child {
        flex-direction: column;
        align-items: flex-end;
      }
    }

    &-Dt {

      .Avatar {
        margin-right: 10px;
      }
    }

    &-Details {

      p {
        margin: 0;
      }

      p:nth-last-child(n + 2) {
        margin-bottom: 1em;
      }
    }
  }


  &-Table {

    &-Header {
      display: flex;
      align-items: center;
      width: 100%;
      padding: 0 24px;
      box-sizing: border-box;
      :last-child {
        flex-direction: column;
        align-items: flex-end;
      }
    }
  }

  &-Loading {
    text-align: center;  
  }
}