.CurrentCategory {
    &-List {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }
    &-Item {
        border: 1px solid #eee;
        border-radius: 8px;
        position: relative;
        margin-bottom: 24px;
    }
    &-ShowMore {
        width: 100%;
        border: 1px solid #eee;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08);
        border-radius: 8px;
        padding: 6px;
        margin-bottom: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        background-color: #fff;
    }
}
