@import '../../utils/global.scss';

.NewsPostBlock {
  border: 1px solid $BorderColor;
  background: $GrayBackground;
  @include BorderRadius;

  .PriorityNav {
    padding: 5px;

    .Dropdown {
      right: 5px;
    }
  }

  .PriorityNav-Button {
    // padding: 5px 10px;
    padding-bottom: 3px;
    margin: 5px 10px;
    border-bottom: 1px solid #afafaf;

    &:nth-child(n + 2) {
      margin-left: 5px;
    }

    &_active {
      // text-decoration: underline;
      border-bottom: 1px solid #06c;
      // padding-bottom: 4px;
      // border-bottom: 1px solid;
    }
  }

  &_hide {
    display: none;
  }
}