.Filter {
  &-Calendar{
    padding: 0;
    border:none;
    background-color: transparent;

  }
  &-Action {
    display: flex;
    justify-content: center;
    button {
      padding: 4px;
    }
  }
  &-Title {
  padding: 0;
  padding-left: 24px;
  margin-bottom: 0;
  padding-bottom: 24px;
  }

  &-Subtitle {
    padding: 0;
    padding-left: 24px;
  }

  &-Toggle {
    display: flex;
    align-items: center;
    justify-content: space-around;
  }

  &-Bottom {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  // &-Button {
  //   margin-bottom: 24px;
  // }

  &-Dropdown {
    margin-bottom: 24px;
    margin-left: 24px;
  }

  &-Select {
    max-width: 150px;
    margin-left: 24px;
    margin-bottom: 24px;
  }

  &-Radius {
    .Selectize-Button:last-of-type {
      border-bottom-right-radius: 8px; 
      border-bottom-left-radius: 8px;
    }
  }

  &-SortBy {
    width: 0;
  	height: 0;
  	border-left: 10px solid transparent;
  	border-right: 10px solid transparent;
  	border-bottom: 15px solid #ccc;
  }
}

.BottomLine {
  border-bottom: 1px solid #D3D9E0;
}

.TopLine {
  border-top: 1px solid #D3D9E0;
}

.Dropdown-Options_align_left {
  margin-left: 24px;
}

.Selectize-Button {
  align-items: center;
}

