@import '../../../../utils/global.scss';

.Modal-Body_type_achievement {
  padding: 0 !important;

  .SearchInput {
    width: 90%;
    margin: 15px auto;

    & + .Modal-Body-Part {
      border-top: 1px solid $BorderColor;
    }
  }
  
  .Modal-Body {

    &-Part {
      padding: 0 15px;
    }

    &-Achievement {
      padding: 10.5px 0;
    }

    &-Goal {
    }
  }
}