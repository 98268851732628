.TestResult {
  background-color: #ffffff;
  border-radius: 8px;

  &_fullscreen {
    display: flex;
    flex-direction: column;
    // justify-content: center;
    align-items: center;
    height: 100%;
    overflow: auto;
  }

  &-NotStarted {
    padding: 24px 24px 8px 24px;
  }
}