.HeaderLayout_type_post {
    .HeaderLayout-Left {
        padding-right: 24px;
        padding-left: 24px;
        @media (max-width: 576px) {
            padding-left: 12px;
            padding-right: 12px;
        }
    }
    .HeaderLayout-Right {
        align-self: flex-start;
        display: flex;
    }
}