.KPIItemEdit {
    &-ButtonBox {
    }
    &-Actions {
        padding-top: 4px;
        padding-bottom: 4px;
        padding-left: 8px;
        padding-right: 8px;
        display: flex;
        justify-content: flex-end;
    }
}
