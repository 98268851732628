.CourseProgress {
  display: flex;

  // align-items: center;
  // margin: 16px 0;
  &-Bar {
    display: flex;
    flex: 1 1;
  }

  &-Item {
    height: 16px;
    background: #D7D7D7;
    display: block;
    flex: 1 1;
    margin-right: 1px;

    &:last-of-type {
      margin-right: 0;
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
    }

    &:first-of-type {
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
    }
  }

  &-Item_complite {
    background: #89CA5E;
  }

  .ProgressBar-Bar {
    flex-grow: 1;
  }

  &-Value {
    font-size: 16px;
    margin-left: 12px;
    line-height: 16px;
  }
}