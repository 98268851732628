.WikiCreateDialog{
    &-PageType{
        display: flex;
        justify-content: space-evenly;
    }
        &-Page1{
        text-align: center;
        h3{
            margin: 0;
            font-size: 16px;
            font-family: "Roboto", "Helvetica", "Arial", sans-serif!important;
            color: #848888;;
        }
    }
    &-Page{
        width: 200px;
        height: 230px;
        border-radius: 15px;
        text-align: center;
        opacity: 0.3;
    }
}


@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    /* IE10+ CSS styles go here */
    .WikiCreateDialog{
        &-PageType{
            display: flex;
            justify-content: center;
        }
    }
}