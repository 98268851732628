.PhotoViewer {
    &-ImageZoom {
        max-height: 450px;
        width: auto;
        height: auto;
        max-width: 100%;

        >div {
            >img {
                max-height: 450px!important;
                width: auto !important;
                height: auto !important;
                max-width: 100% !important;
                margin: auto !important;
            }
            >div {
                z-index: 120 !important;
            }
        }
    }

    &-Slider {
        position: relative;
        z-index: 60;
        height: 450px;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;
        border-radius: 8px;
        border: 1px solid #d3d9e0;
        display: flex;
        align-items: center;
        justify-content: center;
        @media (max-width: 500px) {
            height: unset;
            overflow: hidden;
          }

        &-Back,
        &-Forward {
            position: absolute;
            cursor: pointer;
            width: 50px;
            height: 50px;
            background-color: rgba(0, 0, 0, 0.4);
            display: flex;
            align-items: center;
            justify-content: center;
            top: 0;
            bottom: 0;
            margin: auto;
            border-radius: 100px;

            i {
                color: rgba(255, 255, 255, 0.4);
                font-size: 27px;
            }

            &:hover {
                background-color: rgba(0, 0, 0, 0.7);

                i {
                    color: rgba(255, 255, 255, 0.7);
                }
            }
        }

        &-Back {
            left: 5px;
        }

        &-Forward {
            right: 5px;
        }
    }

    &-Items {
        display: flex;
        flex-wrap: wrap;

        div {
            border-radius: 8px;
            border: 1px solid #d3d9e0;
            width: 92px;
            height: 92px;
            margin-right: 24px;
            margin-top: 24px;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center center;

            &:nth-child(4n + 4) {
                margin-right: 0;
            }
        }

        &-ItemNotActive {
            filter: grayscale(100%);
            filter: gray;
            -webkit-filter: grayscale(1);
        }
    }
}