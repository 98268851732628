.LayoutLoading {
  height: 100vh;
  width: 100vw;
  background: #efefef;

  &-Loader {
    width: 150px;
    height: 150px;
    position: relative;
    margin: 0 auto;

    &-Item {
      position: absolute;

      &_type {

        &_first {
          animation: rotate 1s linear infinite;
        }

        &_second {
          animation: rotate 1s linear infinite reverse;
        }
      }
    }
  }

  &-Data {
    position: fixed;
    top: 50%;
    left: 0;
    transform: translate(0,-50%);
    width: 100%;
    text-align: center;
  }
}