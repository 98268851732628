.Widget_type_calendarmini {
  position: relative;

  .MuiPickersStaticWrapper-staticWrapperRoot {
    background-color: transparent;
    align-items: center;
    min-width: 100%;

    .MuiPickersBasePicker-container {
      width: 100%;

      .MuiPickersBasePicker-pickerView {
        min-width: 100%;

        .MuiPickersCalendarHeader-switchHeader {
          width: 100%;

        }
      }
    }
  }
}