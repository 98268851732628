.LMSBadge {
    &-Title {

    }

    &-Row {
        display: flex;
    }

    &-ColLeft {
        flex: 1 6;
    }

    &-Img {

    }

    &-ColRight {
        flex: 5 6;
    }

    &-Name {
        margin: 0;
    }

    &-Description {
        
    }
}