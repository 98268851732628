.AnswerTypeSort {
    &-Item {
        background: #F5F5F5;
        box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12);
        border-radius: 4px;
        margin-bottom: 12px;
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        letter-spacing: 0.15px;
        color: rgba(0, 0, 0, 0.87);
    }
    &-Item:first-child{
        margin-top:8px;
    }
}