
.ThanksSelector-Actions {
  border-top: 1px solid var(--border-color);
  background-color: var(--background-highlight-color);
}

.ReasonThanksSelector-Title {
  font-weight: bold;
}

.ReasonThanksSelector-Icon {
  color: #0066cc;
}

.ReasonThanksSelector-Opn {
  cursor: pointer;
  // padding-top: 5px;
  color: #0066cc;
}