.Album {
  width: 250px;
  height: 250px;
  margin-bottom: 24px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  display: flex;
  &-Actions {
    background-color: rgba($color: #000000, $alpha: 0.5);
    transition: all 0.2s linear;
    transform: translateY(-100%);
    padding: 12px;
    color: #fff;
    .Button {
      color: #fff;
    }
  }

  &-Info {
    background-color: rgba($color: #000000, $alpha: 0.5);
    transition: all 0.2s linear;
    transform: translateY(100%);
    flex: 1;
    padding: 12px;
    &.Button_type_rlink {
      color: #fff;
    }
  }

  &:hover {
    .Album-Actions {
      transform: translateY(0);
    }

    .Album-Info {
      transform: translateY(0);
    }
  }

  // &-Preview {
  //     display: block;
  //     width: 100%;
  //     height: auto;
  // }
}
