.EditorWorkflows {
    padding: 24px;
    &-Short {
        // border-bottom: 1px solid #D3D9E0;
        border: 1px solid #D3D9E0;
        border-radius: 4px;

        .TabledEditor {
            .DraftEditor-editorContainer {
                min-height: 70px;
            }
        }
    }

    .MuiFormControl-root {
        margin-bottom: 6px;
    }

    &-Deleted {
        font-weight: bold;
        font-size: 16px;
        color: #e44343;
    }

    .FieldRender {
        &:first-child {
            .FieldCategory-Title {
                margin-top: 0;
            }
        }
    }
}