.InputMention {
  box-sizing: border-box;
  background-color: #fff;
  display: flex;
  align-items: center;
  padding-left: 24px; }
  .InputMention:focus-within {
    outline: 1px solid #0066cc;
    width: 100%; }
  .InputMention:hover {
    cursor: text; }
  .InputMention [class*="css-"][class$="-container"] {
    flex: 1 1; }
  .InputMention-HelpTooltip {
    position: absolute;
    right: -2px; }
  .InputMention-ErrorIcon:before {
    right: 15px;
    color: #ff0033; }
  .InputMention-ErrorIcon {
    right: 15px;
    font-size: 16px; }
  .InputMention-Icon {
    font-size: 16px;
    text-align: center;
    margin-right: 8px; }
  .InputMention-Input {
    margin: 0;
    border: 0;
    padding: 11px 10px 10px 45px;
    font-size: 12px;
    width: 100%;
    box-sizing: border-box;
    font-family: "Open Sans", Arial, Helvetica, sans-serif; }
    @media (min-width: 576px) {
      .InputMention-Input {
        font-size: 14px; } }
  .InputMention_noIcon .Input-Input {
    padding: 0px; }
  .InputMention [class*="css-"][class$="-control"] {
    border: 0px !important;
    box-shadow: none !important;
    background: transparent; }
  .InputMention-Selected {
    padding: 8px 12px; }
    .InputMention-Selected .SelectedIcon {
      margin: 0 8px 0 0 !important; }
    .InputMention-Selected-Icon, .InputMention-Selected-Name {
      display: inline-block;
      vertical-align: middle;
      color: #000;
      pointer-events: none; }
    .InputMention-Selected-Icon {
      margin-right: 8px;
      height: fit-content; }
    .InputMention-Selected:hover {
      background-color: #deebff; }
  .InputMention_noIcon [class*="css-"][class$="-control"] {
    margin-left: 0px; }

[class*="css-"][class$="-option"] {
  background-color: #fff;
  padding: 0; }
