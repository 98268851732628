.EventsList {
  &-ListRound {
    min-height: 52px;
  }
  &-Item-Event-Info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    &-Title {
      
      &-Place, &-Time {
        font-weight: 400;
        margin-right: 10px;
        color: #999;
      width: 25%;
      line-height: 1;
      text-align: right;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 12px;
      }
      a {
        color: #000;
        &:visited {
          color: #000;
        }
      }
      &-Name {
        color: #000;
        font-size: 24px;
      }
    }
    span {
      
    }
  }
}