.Photoalbums {
  background-color: #fff;
  border-radius: 8px;
  &-MoreBox {
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 24px 0;
    position: relative;
    &:empty {
      display: none;
    }
  }
  &-List {
    display: flex;
    flex-wrap: wrap;
    padding: 24px;
    justify-content: center;
  }

  &-Actions {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    background-color: var(--background-highlight-color);
    border-bottom: 1px solid var(--border-color);
    &:empty {
      display: none;
    }
  }
}
