.PostContentRender {
}

.ReadMore {
    cursor: pointer;
    font-weight: bold;
}

.Markdown {
    p {
        text-overflow: ellipsis;
        overflow: hidden;
    }
    .Button {
        color: rgb(0, 102, 204);
        font-weight: bold;
        display: inline;
    }

    .img-in-post {
        max-width: 100%;
        height: auto;
        cursor: pointer;

        &.alg-left {
            margin-right: 12px;
        }

        &.alg-right {
            margin-left: 12px;
        }
    }

    .underline {
        text-decoration: underline;
    }

    code {
        background-color: #23241f;
        color: #f8f8f2;
        overflow: visible;
        display: block;
        white-space: pre-wrap;
        margin-bottom: 5px;
        margin-top: 5px;
        padding: 5px 10px;
    }

    blockquote {
        border-left: 4px solid #ccc;
        margin-bottom: 5px;
        margin-top: 5px;
        padding-left: 16px;
    }

    .table-in-post {
        position: relative;
        border-collapse: collapse;
        min-width: 100%;
        line-height: 24px;

        td,
        th {
            padding: 0.25em;
            text-align: center;
            padding-top: 8px;
            padding-bottom: 8px;
        }

        tr {
            box-shadow: 0px 1px 0px 0px #d3d9e0;
        }

        th {
            // position: -webkit-sticky;
            // position: sticky;
            background: #fff;
            color: #94a4b5;
            font-weight: normal;
            // box-shadow: 1px 1px 0px 0px #D3D9E0;
        }

        thead th {
            top: 0;
            z-index: 1;
        }

        thead th:first-child {
            left: 0;
            z-index: 2;
        }

        tbody th {
            z-index: 1;
            left: 0;
        }
    }
}
