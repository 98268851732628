.EventInfo {
    line-height: 22px;
    padding: 8px 24px;

    &-InfoTitle {
        font-family: "Open Sans", Arial, Helvetica, sans-serif;
        font-weight: bold;
    }
    @media (max-width: 576px) {
        line-height: 20px;
        padding: 8px;
    }

    &-InfoTitle {
        display: inline-block;
    }

    &-InfoItem {
        overflow: hidden;
        text-overflow: ellipsis;
    }
}
