.Reply-Editor {
    padding: 8px 24px;
    display: flex;
    align-items: center;
    @media (max-width: 576px) {
        padding: 8px 8px;
    }
    &-EditorBox {
        flex: 1;
    }
    &-RatingBox {
        padding-left: 12px;
    }
    .Reply-Editor-EditorBox {
        overflow: hidden;
    }

    .TextareaMentions-Wrapper__highlighter,
    .TextareaMentions-Wrapper__input {
        min-height: auto;
        padding: 5px;
        line-height: 24px;
    }

    .TextareaMentions {
        flex: 1 1;
        margin: 0 5px;
    }

    .prn-editor-container {
        padding-left: 16px;
        padding-right: 16px;
    }
}