@font-face {
  font-family: 'Open Sans';
  src: url('./assets/fonts/OpenSans/OpenSans-Light.eot');
  src: url('./assets/fonts/OpenSans/OpenSans-Light.eot?#iefix') format('embedded-opentype'),
    // url('./assets/fonts/OpenSans/OpenSans-Light.woff') format('woff'),
    url('./assets/fonts/OpenSans/OpenSans-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Open Sans';
  src: url('./assets/fonts/OpenSans/OpenSans-LightItalic.eot');
  src: url('./assets/fonts/OpenSans/OpenSans-LightItalic.eot?#iefix') format('embedded-opentype'),
    // url('./assets/fonts/OpenSans/OpenSans-LightItalic.woff') format('woff'),
    url('./assets/fonts/OpenSans/OpenSans-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: 'Open Sans';
  src: url('./assets/fonts/OpenSans/OpenSans-Regular.eot');
  src: url('./assets/fonts/OpenSans/OpenSans-Regular.eot?#iefix') format('embedded-opentype'),
    // url('./assets/fonts/OpenSans/OpenSans-Regular.woff') format('woff'),
    url('./assets/fonts/OpenSans/OpenSans-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Open Sans';
  src: url('./assets/fonts/OpenSans/OpenSans-Italic.eot');
  src: url('./assets/fonts/OpenSans/OpenSans-Italic.eot?#iefix') format('embedded-opentype'),
    // url('./assets/fonts/OpenSans/OpenSans-Italic.woff') format('woff'),
    url('./assets/fonts/OpenSans/OpenSans-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: 'Open Sans';
  src: url('./assets/fonts/OpenSans/OpenSans-SemiBold.eot');
  src: url('./assets/fonts/OpenSans/OpenSans-SemiBold.eot?#iefix') format('embedded-opentype'),
    // url('./assets/fonts/OpenSans/OpenSans-SemiBold.woff') format('woff'),
    url('./assets/fonts/OpenSans/OpenSans-SemiBold.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Open Sans';
  src: url('./assets/fonts/OpenSans/OpenSans-SemiBoldItalic.eot');
  src: url('./assets/fonts/OpenSans/OpenSans-SemiBoldItalic.eot?#iefix') format('embedded-opentype'),
    // url('./assets/fonts/OpenSans/OpenSans-SemiBoldItalic.woff') format('woff'),
    url('./assets/fonts/OpenSans/OpenSans-SemiBoldItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: 'Open Sans';
  src: url('./assets/fonts/OpenSans/OpenSans-Bold.eot');
  src: url('./assets/fonts/OpenSans/OpenSans-Bold.eot?#iefix') format('embedded-opentype'),
    // url('./assets/fonts/OpenSans/OpenSans-Bold.woff') format('woff'),
    url('./assets/fonts/OpenSans/OpenSans-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'Open Sans';
  src: url('./assets/fonts/OpenSans/OpenSans-BoldItalic.eot');
  src: url('./assets/fonts/OpenSans/OpenSans-BoldItalic.eot?#iefix') format('embedded-opentype'),
    // url('./assets/fonts/OpenSans/OpenSans-BoldItalic.woff') format('woff'),
    url('./assets/fonts/OpenSans/OpenSans-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}
@font-face {
  font-family: 'Open Sans';
  src: url('./assets/fonts/OpenSans/OpenSans-ExtraBold.eot');
  src: url('./assets/fonts/OpenSans/OpenSans-ExtraBold.eot?#iefix') format('embedded-opentype'),
    // url('./assets/fonts/OpenSans/OpenSans-ExtraBold.woff') format('woff'),
    url('./assets/fonts/OpenSans/OpenSans-ExtraBold.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: 'Open Sans';
  src: url('./assets/fonts/OpenSans/OpenSans-ExtraBoldItalic.eot');
  src: url('./assets/fonts/OpenSans/OpenSans-ExtraBoldItalic.eot?#iefix') format('embedded-opentype'),
    // url('./assets/fonts/OpenSans/OpenSans-ExtraBoldItalic.woff') format('woff'),
    url('./assets/fonts/OpenSans/OpenSans-ExtraBoldItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}
// @font-face {
//   font-family: 'BukhariScript';
//   src: url('./assets/fonts/BukhariScript/BukhariScript.woff2') format('woff2'),
//   url('./assets/fonts/BukhariScript/BukhariScript.woff') format('woff');
//   font-weight: 400;
//   font-style: normal;
// }
