.InfoBlock-Group {
    &-Img {
        max-width: 180px;
        margin: auto;
        &_edit {
            margin: 0;
        }
    }
    &-Actions {
        display: flex;
        gap: 8px;

        &-Splitter {
            border-left: 1px solid;
        }
    }
    &-ActionButton {
        width: 100%;
    }
    &-DataName {
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 33px;
    }
    &-Title {
        font-weight: bold;
        margin: 8px 0;
    }
    &-NetworkIcon {
        margin-left: 12px;
    }
    &-Inner {
        display: flex;
        align-items: center;
        padding: 24px 48px;
        &_align_top {
            align-items: flex-start;
        }
    }
    &-GrayText {
        color: #4b4b4b;
    }
    &-Skeleton {
        padding: 12px 44px;
        display: flex;
        align-items: center;
    }
    &-Info {
        padding: 0 24px;
        flex-grow: 2;
    }
    .InfoBlock-Layout-Right {
        padding-bottom: 36px;
    }
}
