@import '../../List.scss';

.List_type_groups {

  .ListLite {
    background-color: var(--background-color);
    border: 1px solid var(--border-color);   
  } 

  .List {

    &-Item {

      &-Avatar {
        position: relative;

        .Item_isHidden,
        .Item_visibilityType_1 {
          font-size: 8px;
          color: #fff;
        }

        .Item_isHidden {
          background-color: #91c1d7;
        }

        .Item_visibilityType_1 {
          background-color: var(--danger-color);
        }

        .Subsystem-Badge {
          position: absolute;
          width: 20px;
          height: 20px;
          transform: translate(-50%, -50%);
          border: 2px solid #fff; 
          box-sizing: border-box;
          border-radius: 50%;
          color: #fff;
          background: #06c;
          font-size: 9px;
          top: 15%;
          left: 85%;
        }
      }

      &-DataPart {
        flex: 1;

        // &:nth-of-type(n + 2) {
        //   width: calc(100% - 170px);
        //   min-width: calc(100% - 170px);
        //   // max-width: 30%;
        // }
      }

      &_nochecked {
        
        .List-Item-DataPart {

          &:nth-of-type(n + 2) {
            width: calc(100% - 140px);
            min-width: calc(100% - 140px);
            // max-width: 30%;
          }
        }
      }
    }

    &-Button {

      &_more {
        margin-top: 10px;
        display: block;
        width: 100%;
        border-radius: 8px;
      }
    }
  }
  .stub{
    background-color: #ffffff;
    height: 352px;
    border-radius: 0 0 8px 8px;
    text-align: center;
    padding-top: 40px;
    border: 1px solid #e9ecef;
  }
}

.groupeList{
  font-weight: bold;
  font-size: 16px;
}