@import '../../utils/global.scss';

.CountSlider {
  position: relative;
  z-index: 10;

  &-Inner {
    display: flex;
    align-items: center;
    // padding: 0 24px;
  }

  &-Icon {
    font-size: 16px;
    text-align: center;
    margin-right: 18px;
  }

  &-NotEnought {
    text-align: center;
  }

  &-Input {
    text-align: center;
    font-weight: bold;
    display: inline-block !important;
    padding: 0 !important;
    border: 0px !important;
    outline: none !important;
    font-size: 24px !important;
    display: inline-block;
    width: 3rem;
    color: #89ca5e;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    -moz-appearance: textfield;
  }

  &-Before {
    margin-right: 16px;
  }

  &-After {
    margin-left: 16px;
  }

  &-Min,
  &-Max {
    font-size: 24px;
    display: block;
    text-align: center;
    width: 10rem;
    color: #999;

    &_active {
      color: #99cc66;
    }
  }

  &-Range {
    box-shadow: 0px 1px 9px 0px rgba(0, 0, 0, 0.2);
    display: flex;
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 12;
    width: 100%;
    background-color: var(--background-color);

    &_type_modal {
      position: static;
      box-shadow: none;
      border-top: 1px solid var(--border-color);
      border-bottom: 1px solid var(--border-color);
    }

    &_entering {
      animation: fadeInDown, 0.3s, ease-out;
    }

    &_exiting {
      animation: fadeOutUp, 0.3s, ease-out;
    }

    &Input {
      flex: 1 1 auto;
      -webkit-appearance: none;
      margin: 4px 0;
      //for ie
      padding: 0;

      &:focus {
        outline: none;
      }

      &::-webkit-slider-runnable-track {
        width: 100%;
        height: 6px;
        cursor: pointer;
        box-shadow: none;
        background: #999;
        border-radius: 0px;
        border: none;
      }

      &::-webkit-slider-thumb {
        box-shadow: none;
        border: none;
        height: 14px;
        width: 14px;
        border-radius: 7px;
        background: #0066cc;
        cursor: pointer;
        -webkit-appearance: none;
        margin-top: -4px;
      }

      &:focus::-webkit-slider-runnable-track {
        background: #999;
      }

      &::-moz-range-track {
        width: 100%;
        height: 6px;
        cursor: pointer;
        box-shadow: none;
        background: #999;
        border-radius: 0px;
        border: none;
      }

      &::-moz-range-thumb {
        box-shadow: none;
        border: none;
        height: 14px;
        width: 14px;
        border-radius: 7px;
        background: #0066cc;
        cursor: pointer;
      }

      &::-ms-track {
        width: 100%;
        height: 14px;
        background: transparent;
        border-color: transparent;
        border-top-width: 4px;
        border-bottom-width: 4px;
        box-sizing: border-box;
        color: transparent;
      }

      &::-ms-fill-lower {
        background: #99cc66;
        border: none;
        border-radius: 0px;
        box-shadow: none;
      }

      &::-ms-fill-upper {
        background: #999;
        border: none;
        border-radius: 0px;
        box-shadow: none;
      }

      &::-ms-thumb {
        cursor: pointer;
        box-shadow: none;
        border: none;
        height: 14px;
        width: 14px;
        border-radius: 7px;
        background: #0066cc;
      }

      &:focus::-ms-fill-lower {
        background: #99cc66;
      }

      &:focus::-ms-fill-upper {
        background: #999;
      }
    }
  }
}