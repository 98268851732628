.InfoBlock-Info {
  .InfoBlock-DateTimeInput {
    .InputLayout-WorkZone {
      padding: 0;
      padding-bottom: 1px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.42);

      &:hover {
        padding-bottom: 0;
        border-bottom: 2px solid rgba(0, 0, 0, 0.87);
      }

      &.InputLayout-WorkZone__focus {
        box-shadow: none;
        border-bottom: 2px solid #3f51b5;
        padding-bottom: 0;
      }
    }
  }
}

.InfoBlock-UserEdit {
  .InfoBlock-Img {
    .ImgLoader-Button {
      display: none;
    }
  }

  .ImgLoader {
    min-height: auto;
  }

  .InfoBlockImg-Button {
    width: 100%;
  }

  .MuiFormHelperText-root {
    color: red;
  }
}