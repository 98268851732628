.ImgDataLayout {
    background-color: #fff;
    padding: 12px;
    border-radius: 8px;
    box-shadow: 0px 0px 15px 0px #000;
}

.ImgData {
    z-index: 10;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    // background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.ImgData-Item {
    margin-top: 6px;
    margin-bottom: 6px;
}