.UserWidgetGroup {
  &-HeaderNoData {
    &-Title {
      font-family: "Open Sans", Arial, Helvetica, sans-serif;
      text-transform: none;
      font-weight: 700;
      font-size: 16px;
      line-height: 116%;
      letter-spacing: 0.15px;
      margin: 6.5px 0;
    }

    &-noData {
      width: 100%;
      max-width: 300px;
    }

    &-Text {
      font-size: 14px;
      line-height: 20.02px;
      width: 100%;
      margin-bottom: 12px
    }
  }

  &-Header {
    display: flex;
    align-items: center;

    .Avatar {
      margin-right: 10px;
    }
  }

  &-Name {
    min-width: 0px;
    overflow: hidden;
    margin: 6.5px 0;
  }

  &-Item {
    display: flex;
    align-items: flex-start;
    margin-top: 24px;

    &-Info {
      margin-left: 12px;
      min-width: 0;

      a {
        font-weight: 700;
        line-height: 1;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
      }

      p {
        margin: 0;
        line-height: 1;
      }
    }
  }
}

.UserWidgetGroupNew {
  background-color: #fff;
  padding: 16px;
  border-radius: 4px;

  &-BackgroundImg {
    background: #f5f5f5;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 8px;
    padding: 8px;
    display:flex;
    flex-direction: column;
    gap: 8px;
  }

  &-Content {
    width: 100%;
    padding: 8px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    box-sizing: border-box;
    width: 100%;
  }

  &-Item {
    display: flex;
    align-items: flex-start;
    gap: 16px;
    width: 100%;


    &-Info {
      margin-left: 12px;
      min-width: 0;

      a {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: rgba(0, 0, 0, 0.87);
        font-feature-settings: 'clig' off, 'liga' off;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        letter-spacing: 0.15px;
        width: 100%;
      }

      p {
        margin: 0;
        line-height: 1;
      }
    }
  }
}