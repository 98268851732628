.CalendarModal {
    padding: 24px;
    &-Item {
      display: flex;
      align-items: center;
      p {
        margin-left: 20px;
        font-size: 20px;
      }
    }
  }