.Modal-Body_type_layoutWidth {
  padding: 15px;

  .WidthChanger {
    display: flex;

    &-Column {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &-Dragger {
      width: 10px;
      position: relative;
      cursor: col-resize;

      > .Icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    &-Column {
      background-color: #f1f1f1;
      height: 200px;
      flex-direction: column;
    }
  }
}