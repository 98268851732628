.SkillsHeader {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #d3d9e0;
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 20px;
    padding-bottom: 20px;

    &-Title {
    }

    &-Header {
        margin: 4px 0;
    }

    @media (max-width: 400px) {
        &-Title {
            display: none;
        }
    }

    &-ButtonBox {
        height: 26px;
        width: 250px;
    }

    &-CreateBox {
    }

    &-CreateBtn {
        padding-left: 16px;
        padding-right: 16px;
        padding-top: 0;
        padding-bottom: 0;
    }

    &-Btn {
        &.Button {
            padding-left: 16px;
            padding-right: 16px;
        }
    }
}
