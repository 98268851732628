.NewsView-Header {
  display: flex;
  padding-top: 15px;
  padding-bottom: 15px;
  align-items: center;

  &-UserImg {
    flex-basis: 56px;
    display: block;
    text-align: right;
    text-decoration: none;
  }

  &-UserImg:hover+.NewsView-Header-UserName {
    text-decoration: underline;
  }

  &-UserName {
    color: #0066cc;
    font-weight: bold;
    display: inline;
    text-decoration: none;
  }

  &-Data {
    flex: 1;
    margin-left: 24px;
    color: #999999;
    // padding-top: 10px;
    line-height: 18px;
  }

  .Button {
    color: #0066cc;
    font-weight: bold;
    display: inline;
  }

  .Avatar {
    display: block;
  }
}