.LMSTOComments {
    display: flex;
    margin-top: 24px;
    align-items: flex-start;

    &-Aside {
        background-color: #fff;
        flex: 1 4;
        border-radius: 8px;
        overflow: hidden;
        border: 1px solid #d3d9e0;

        max-height: calc(100vh - 56px);
        overflow: auto;
        position: sticky;
        top: 56px;

        &::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
            border-radius: 8px;
            margin: 8px 0;
        }

        &::-webkit-scrollbar {
            width: 5px;
        }

        &::-webkit-scrollbar-thumb {
            background-color: rgba($color: #000000, $alpha: .2);
            opacity: 0.3;
            border-radius: 8px;

            &:hover {
                background-color: rgba($color: #000000, $alpha: .4);
            }
        }
    }

    &-Section {
        flex: 3 4;
        margin-left: 24px;
    }

    &-Item {
        border-radius: 8px;
        background-color: #fff;
        border: 1px solid #d3d9e0;
        margin-bottom: 24px;
    }

    &-Search {
        border-radius: 8px;
        background-color: #fff;
        border: 1px solid #d3d9e0;
        margin-bottom: 24px;
        padding: 12px 24px;
    }

    &-Unit {
        padding: 12px 24px;
    }

    &-Title {
        margin: 0;
    }

    &-Trigger {
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
}
