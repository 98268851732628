$BorderColor: #d3d9e0;
$HeaderBackground: #fff;
$HeaderHeight: 72px;
$HeightIncludeHeader: calc(100vh - 56px);
$GrayBackground: #f7f7f7;
$WhiteBackground: #fff;
$ErrorColor: #ff0033;
$SuccessColor: #89ca5e;
$LeftMenuWidth: 300px;
$LeftMenuFoldedWidth: 93px;

$TransitionDuration: 0.25s;
$TransitionTimingFunction: ease-in-out;

$FontFamilyRegular: 'Open Sans',
Arial,
Helvetica,
sans-serif;
$FontFamilyBold: 'Open Sans',
Arial,
Helvetica,
sans-serif;

$SystemBlue: #0066cc;

$defaultPadding: 24px;
$defaultMargin: 24px;

@mixin HeaderHeight {
  margin-top: 56px;
}

@mixin HeaderHeight {
  margin-top: 56px;
}

@mixin Left-menu-padding {
  padding-left: 218px;
}

@mixin SystemBlue {
  background-color: #0066cc;
}

@mixin Transition($prop) {
  transition-property: $prop;
  transition-duration: 0.25s;
  transition-timing-function: ease-in-out;
}

@mixin BoxShadow {
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08);
}

@mixin HoverShadow {
  box-shadow: 0px 0px 20px rgba(65, 100, 132, 0.25);
}

@mixin Border {
  border: 1px solid var(--border-color);
}

@mixin BorderRadius {
  border-radius: 8px;
  // overflow: hidden;
}

@mixin BorderRadiusTop {
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
}

@mixin BorderRadiusBottom {
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
}

@mixin FontSize {
  font-size: 12px;

  @media (min-width: 576px) {
    font-size: 14px;
  }
}

@mixin BlockPadding {
  padding: 8px 12px;

  @media (min-width: 576px) {
    padding: 12px 24px;
  }
}

@mixin HiddenScrollbars {
  &::-webkit-scrollbar {
    width: 0 !important;
    height: 0 !important;
  }

  -ms-overflow-style: none;
  overflow: -moz-hidden-unscrollable;
}