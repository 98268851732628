.BadgeWidget {
    position: relative;
    padding: 24px;
    display: flex;
    align-items: stretch;
    &-noData{
        width:100%;
        max-width:300px;
    }
    &-Header {
        &-Title {
          font-family: "Open Sans", Arial, Helvetica, sans-serif;
          text-transform: none;
          font-weight: 700;
          font-size: 16px;
          line-height: 116%;
          letter-spacing: 0.15px;
          margin: 6.5px 0;
        }
    
        &-Text {
          font-size: 14px;
          line-height: 20.02px;
          width: 100%;
          margin-bottom: 12px
        }
    }
    &-Bages {
        flex: 1;
    }

    &-BadgeIcon {
        width: 175px;
        padding: 12px 0;
        @media (max-width: 500px) {
            width: 120px;
        }
    }

    &-Latest {
    }

    &-LatestTitle {
        margin: 6px 0;
        font-weight: 600;
    }

    &-Placeholder {
        min-height: auto;
        margin-top: 24px;
        border: 1px solid var(--border-color);
    }

    &-LatestList {
        display: flex;
        flex-wrap: wrap;
        @media (max-width: 500px) {
            justify-content: center;
        }
    }

    &-All {
    }

    &-AllTitle {
        margin: 6px 0;
        font-weight: 600;
    }

    &-List {
    }

    &-CatTitle {
        margin: 6px 0;
        font-weight: 600;

        &::first-letter {
            text-transform: capitalize;
        }

        &:empty {
            display: none;
        }
    }

    &-CatBadges {
        display: flex;
        flex-wrap: wrap;
        @media (max-width: 500px) {
            justify-content: center;
        }
    }

    &-ShowBox {
    }

    &-ShowBtn {
        position: sticky;
        top: 64px;
        margin-top: 64px;
        width: 54px;
        border-radius: 50%;
        transform: rotateZ(90deg);
        display: flex;
        justify-content: center;
        align-items: center;

        &:before {
            content: "";
            display: block;
            float: left;
            padding-top: 100%;
        }
    }
}
