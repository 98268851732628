.CreateGroup {
background-color: #fff;
padding: 24px;
overflow-y: visible!important;
  &-BorderSplitter {
    border-bottom: 1px solid var(--border-color);
  
  }
  .Input-Input,
  .Textarea-Textarea,
  .CheckboxInput  {
    font-size: 16px!important;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif!important;
    // margin: 0 0 0 47px;
  }
  &-VisibilityChange {
    display: flex;
    padding: 0 24px;

    .Radio {
      flex: 1;
    }
  }
}