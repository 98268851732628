@font-face {
  font-family: 'pryaniky-icons';
  src: url('../font/pryaniky-icons.eot?47902053');
  src: url('../font/pryaniky-icons.eot?47902053#iefix') format('embedded-opentype'),
    /* url('../font/pryaniky-icons.woff2?47902053') format('woff2'), */
    /* url('../font/pryaniky-icons.woff?47902053') format('woff'), */
    url('../font/pryaniky-icons.ttf?47902053') format('truetype'),
    url('../font/pryaniky-icons.svg?47902053#pryaniky-icons') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'pryaniky-icons';
    src: url('../font/pryaniky-icons.svg?47902053#pryaniky-icons') format('svg');
  }
}
*/

[class^='Icon_']:before,
[class*=' Icon_']:before {
  font-family: 'pryaniky-icons';
  font-style: normal;
  font-weight: normal;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: 0.2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: 0.2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.Icon_alicorn:before {
  content: '\e800';
} /* '' */
.Icon_align-center:before {
  content: '\e801';
} /* '' */
.Icon_align-justify:before {
  content: '\e802';
} /* '' */
.Icon_align-left:before {
  content: '\e803';
} /* '' */
.Icon_align-right:before {
  content: '\e804';
} /* '' */
.Icon_allergies:before {
  content: '\e805';
} /* '' */
.Icon_ambulance:before {
  content: '\e806';
} /* '' */
.Icon_american-sign-language-interpreting:before {
  content: '\e807';
} /* '' */
.Icon_analytics:before {
  content: '\e808';
} /* '' */
.Icon_anchor:before {
  content: '\e809';
} /* '' */
.Icon_angel:before {
  content: '\e80a';
} /* '' */
.Icon_angle-double-down:before {
  content: '\e80b';
} /* '' */
.Icon_angle-double-left:before {
  content: '\e80c';
} /* '' */
.Icon_angle-double-right:before {
  content: '\e80d';
} /* '' */
.Icon_angle-double-up:before {
  content: '\e80e';
} /* '' */
.Icon_angle-down:before {
  content: '\e80f';
} /* '' */
.Icon_angle-left:before {
  content: '\e810';
} /* '' */
.Icon_angle-right:before {
  content: '\e811';
} /* '' */
.Icon_angle-up:before {
  content: '\e812';
} /* '' */
.Icon_angry:before {
  content: '\e813';
} /* '' */
.Icon_ankh:before {
  content: '\e814';
} /* '' */
.Icon_apple-alt:before {
  content: '\e815';
} /* '' */
.Icon_apple-crate:before {
  content: '\e816';
} /* '' */
.Icon_archive:before {
  content: '\e817';
} /* '' */
.Icon_archway:before {
  content: '\e818';
} /* '' */
.Icon_arrow-alt-circle-down:before {
  content: '\e819';
} /* '' */
.Icon_arrow-alt-circle-left:before {
  content: '\e81a';
} /* '' */
.Icon_arrow-alt-circle-right:before {
  content: '\e81b';
} /* '' */
.Icon_arrow-alt-down:before {
  content: '\e81c';
} /* '' */
.Icon_arrow-alt-from-bottom:before {
  content: '\e81d';
} /* '' */
.Icon_arrow-alt-circle-up:before {
  content: '\e81e';
} /* '' */
.Icon_arrow-alt-from-left:before {
  content: '\e81f';
} /* '' */
.Icon_arrow-alt-from-right:before {
  content: '\e820';
} /* '' */
.Icon_arrow-alt-from-top:before {
  content: '\e821';
} /* '' */
.Icon_arrow-alt-left:before {
  content: '\e822';
} /* '' */
.Icon_arrow-alt-right:before {
  content: '\e823';
} /* '' */
.Icon_arrow-alt-square-down:before {
  content: '\e824';
} /* '' */
.Icon_arrow-alt-square-left:before {
  content: '\e825';
} /* '' */
.Icon_arrow-alt-square-right:before {
  content: '\e826';
} /* '' */
.Icon_arrow-alt-square-up:before {
  content: '\e827';
} /* '' */
.Icon_arrow-alt-to-bottom:before {
  content: '\e828';
} /* '' */
.Icon_arrow-alt-to-left:before {
  content: '\e829';
} /* '' */
.Icon_arrow-alt-to-right:before {
  content: '\e82a';
} /* '' */
.Icon_arrow-alt-to-top:before {
  content: '\e82b';
} /* '' */
.Icon_arrow-alt-up:before {
  content: '\e82c';
} /* '' */
.Icon_arrow-circle-down:before {
  content: '\e82d';
} /* '' */
.Icon_arrow-circle-left:before {
  content: '\e82e';
} /* '' */
.Icon_arrow-circle-right:before {
  content: '\e82f';
} /* '' */
.Icon_arrow-circle-up:before {
  content: '\e830';
} /* '' */
.Icon_arrow-down:before {
  content: '\e831';
} /* '' */
.Icon_arrow-from-bottom:before {
  content: '\e832';
} /* '' */
.Icon_arrow-from-left:before {
  content: '\e833';
} /* '' */
.Icon_arrow-from-right:before {
  content: '\e834';
} /* '' */
.Icon_arrow-from-top:before {
  content: '\e835';
} /* '' */
.Icon_fa-arrow-left:before {
  content: '\e836';
} /* '' */
.Icon_arrow-fa-right:before {
  content: '\e837';
} /* '' */
.Icon_arrows:before {
  content: '\e838';
} /* '' */
.Icon_arrows-alt:before {
  content: '\e839';
} /* '' */
.Icon_arrows-alt-h:before {
  content: '\e83a';
} /* '' */
.Icon_arrows-alt-v:before {
  content: '\e83b';
} /* '' */
.Icon_arrows-h:before {
  content: '\e83c';
} /* '' */
.Icon_arrow-square-down:before {
  content: '\e83d';
} /* '' */
.Icon_arrow-square-right:before {
  content: '\e83e';
} /* '' */
.Icon_arrow-square-left:before {
  content: '\e83f';
} /* '' */
.Icon_arrow-square-up:before {
  content: '\e840';
} /* '' */
.Icon_arrows-v:before {
  content: '\e841';
} /* '' */
.Icon_arrow-to-bottom:before {
  content: '\e842';
} /* '' */
.Icon_arrow-to-left:before {
  content: '\e843';
} /* '' */
.Icon_arrow-to-right:before {
  content: '\e844';
} /* '' */
.Icon_arrow-to-top:before {
  content: '\e845';
} /* '' */
.Icon_arrow-up:before {
  content: '\e846';
} /* '' */
.Icon_assistive-listening-systems:before {
  content: '\e847';
} /* '' */
.Icon_asterisk:before {
  content: '\e848';
} /* '' */
.Icon_at:before {
  content: '\e849';
} /* '' */
.Icon_atlas:before {
  content: '\e84a';
} /* '' */
.Icon_atom:before {
  content: '\e84b';
} /* '' */
.Icon_atom-alt:before {
  content: '\e84c';
} /* '' */
.Icon_audio-description:before {
  content: '\e84d';
} /* '' */
.Icon_fa-award:before {
  content: '\e84e';
} /* '' */
.Icon_axe:before {
  content: '\e84f';
} /* '' */
.Icon_axe-battle:before {
  content: '\e850';
} /* '' */
.Icon_baby:before {
  content: '\e851';
} /* '' */
.Icon_baby-carriage:before {
  content: '\e852';
} /* '' */
.Icon_backpack:before {
  content: '\e853';
} /* '' */
.Icon_backspace:before {
  content: '\e854';
} /* '' */
.Icon_backward:before {
  content: '\e855';
} /* '' */
.Icon_bacon:before {
  content: '\e856';
} /* '' */
.Icon_badge:before {
  content: '\e857';
} /* '' */
.Icon_badge-check:before {
  content: '\e858';
} /* '' */
.Icon_badge-dollar:before {
  content: '\e859';
} /* '' */
.Icon_badge-percent:before {
  content: '\e85a';
} /* '' */
.Icon_badger-honey:before {
  content: '\e85b';
} /* '' */
.Icon_balance-scale:before {
  content: '\e85c';
} /* '' */
.Icon_balance-scale-left:before {
  content: '\e85d';
} /* '' */
.Icon_balance-scale-right:before {
  content: '\e85e';
} /* '' */
.Icon_ballot:before {
  content: '\e85f';
} /* '' */
.Icon_ballot-check:before {
  content: '\e860';
} /* '' */
.Icon_ball-pile:before {
  content: '\e861';
} /* '' */
.Icon_ban:before {
  content: '\e862';
} /* '' */
.Icon_band-aid:before {
  content: '\e863';
} /* '' */
.Icon_barcode:before {
  content: '\e864';
} /* '' */
.Icon_barcode-alt:before {
  content: '\e865';
} /* '' */
.Icon_barcode-read:before {
  content: '\e866';
} /* '' */
.Icon_barcode-scan:before {
  content: '\e867';
} /* '' */
.Icon_bars:before {
  content: '\e868';
} /* '' */
.Icon_baseball:before {
  content: '\e869';
} /* '' */
.Icon_baseball-ball:before {
  content: '\e86a';
} /* '' */
.Icon_basketball-ball:before {
  content: '\e86b';
} /* '' */
.Icon_basketball-hoop:before {
  content: '\e86c';
} /* '' */
.Icon_bat:before {
  content: '\e86d';
} /* '' */
.Icon_bath:before {
  content: '\e86e';
} /* '' */
.Icon_battery-bolt:before {
  content: '\e86f';
} /* '' */
.Icon_battery-empty:before {
  content: '\e870';
} /* '' */
.Icon_battery-full:before {
  content: '\e871';
} /* '' */
.Icon_battery-half:before {
  content: '\e872';
} /* '' */
.Icon_battery-quarter:before {
  content: '\e873';
} /* '' */
.Icon_battery-slash:before {
  content: '\e874';
} /* '' */
.Icon_battery-three-quarters:before {
  content: '\e875';
} /* '' */
.Icon_bed:before {
  content: '\e876';
} /* '' */
.Icon_beer:before {
  content: '\e877';
} /* '' */
.Icon_fa-bell:before {
  content: '\e878';
} /* '' */
.Icon_bells:before {
  content: '\e879';
} /* '' */
.Icon_bell-school:before {
  content: '\e87a';
} /* '' */
.Icon_bell-school-slash:before {
  content: '\e87b';
} /* '' */
.Icon_bell-slash:before {
  content: '\e87c';
} /* '' */
.Icon_bezier-curve:before {
  content: '\e87d';
} /* '' */
.Icon_bible:before {
  content: '\e87e';
} /* '' */
.Icon_bicycle:before {
  content: '\e87f';
} /* '' */
.Icon_binoculars:before {
  content: '\e880';
} /* '' */
.Icon_biohazard:before {
  content: '\e881';
} /* '' */
.Icon_birthday-cake:before {
  content: '\e882';
} /* '' */
.Icon_blanket:before {
  content: '\e883';
} /* '' */
.Icon_blender:before {
  content: '\e884';
} /* '' */
.Icon_blender-phone:before {
  content: '\e885';
} /* '' */
.Icon_blind:before {
  content: '\e886';
} /* '' */
.Icon_blog:before {
  content: '\e887';
} /* '' */
.Icon_bold:before {
  content: '\e888';
} /* '' */
.Icon_bolt:before {
  content: '\e889';
} /* '' */
.Icon_bomb:before {
  content: '\e88a';
} /* '' */
.Icon_bone:before {
  content: '\e88b';
} /* '' */
.Icon_bone-break:before {
  content: '\e88c';
} /* '' */
.Icon_bong:before {
  content: '\e88d';
} /* '' */
.Icon_book:before {
  content: '\e88e';
} /* '' */
.Icon_book-alt:before {
  content: '\e88f';
} /* '' */
.Icon_book-dead:before {
  content: '\e890';
} /* '' */
.Icon_book-heart:before {
  content: '\e891';
} /* '' */
.Icon_bookmark:before {
  content: '\e892';
} /* '' */
.Icon_book-medical:before {
  content: '\e893';
} /* '' */
.Icon_book-open:before {
  content: '\e894';
} /* '' */
.Icon_book-reader:before {
  content: '\e895';
} /* '' */
.Icon_fa-books:before {
  content: '\e896';
} /* '' */
.Icon_books-medical:before {
  content: '\e897';
} /* '' */
.Icon_book-spells:before {
  content: '\e898';
} /* '' */
.Icon_book-user:before {
  content: '\e899';
} /* '' */
.Icon_boot:before {
  content: '\e89a';
} /* '' */
.Icon_booth-curtain:before {
  content: '\e89b';
} /* '' */
.Icon_bow-arrow:before {
  content: '\e89c';
} /* '' */
.Icon_bowling-ball:before {
  content: '\e89d';
} /* '' */
.Icon_bowling-pins:before {
  content: '\e89e';
} /* '' */
.Icon_box:before {
  content: '\e89f';
} /* '' */
.Icon_box-alt:before {
  content: '\e8a0';
} /* '' */
.Icon_box-ballot:before {
  content: '\e8a1';
} /* '' */
.Icon_box-check:before {
  content: '\e8a2';
} /* '' */
.Icon_boxes:before {
  content: '\e8a3';
} /* '' */
.Icon_boxes-alt:before {
  content: '\e8a4';
} /* '' */
.Icon_box-fragile:before {
  content: '\e8a5';
} /* '' */
.Icon_box-full:before {
  content: '\e8a6';
} /* '' */
.Icon_box-heart:before {
  content: '\e8a7';
} /* '' */
.Icon_boxing-glove:before {
  content: '\e8a8';
} /* '' */
.Icon_box-open:before {
  content: '\e8a9';
} /* '' */
.Icon_box-up:before {
  content: '\e8aa';
} /* '' */
.Icon_box-usd:before {
  content: '\e8ab';
} /* '' */
.Icon_brackets:before {
  content: '\e8ac';
} /* '' */
.Icon_brackets-curly:before {
  content: '\e8ad';
} /* '' */
.Icon_braille:before {
  content: '\e8ae';
} /* '' */
.Icon_brain:before {
  content: '\e8af';
} /* '' */
.Icon_bread-loaf:before {
  content: '\e8b0';
} /* '' */
.Icon_bread-slice:before {
  content: '\e8b1';
} /* '' */
.Icon_briefcase:before {
  content: '\e8b2';
} /* '' */
.Icon_briefcase-medical:before {
  content: '\e8b3';
} /* '' */
.Icon_broadcast-tower:before {
  content: '\e8b4';
} /* '' */
.Icon_broom:before {
  content: '\e8b5';
} /* '' */
.Icon_browser:before {
  content: '\e8b6';
} /* '' */
.Icon_brush:before {
  content: '\e8b7';
} /* '' */
.Icon_bug:before {
  content: '\e8b8';
} /* '' */
.Icon_building:before {
  content: '\e8b9';
} /* '' */
.Icon_bullhorn:before {
  content: '\e8ba';
} /* '' */
.Icon_bullseye:before {
  content: '\e8bb';
} /* '' */
.Icon_bullseye-arrow:before {
  content: '\e8bc';
} /* '' */
.Icon_bullseye-pointer:before {
  content: '\e8bd';
} /* '' */
.Icon_burn:before {
  content: '\e8be';
} /* '' */
.Icon_burrito:before {
  content: '\e8bf';
} /* '' */
.Icon_bus:before {
  content: '\e8c0';
} /* '' */
.Icon_bus-alt:before {
  content: '\e8c1';
} /* '' */
.Icon_business-time:before {
  content: '\e8c2';
} /* '' */
.Icon_bus-school:before {
  content: '\e8c3';
} /* '' */
.Icon_cabinet-filing:before {
  content: '\e8c4';
} /* '' */
.Icon_calculator:before {
  content: '\e8c5';
} /* '' */
.Icon_calculator-alt:before {
  content: '\e8c6';
} /* '' */
.Icon_fa-calendar:before {
  content: '\e8c7';
} /* '' */
.Icon_calendar-alt:before {
  content: '\e8c8';
} /* '' */
.Icon_calendar-check:before {
  content: '\e8c9';
} /* '' */
.Icon_calendar-day:before {
  content: '\e8ca';
} /* '' */
.Icon_calendar-edit:before {
  content: '\e8cb';
} /* '' */
.Icon_calendar-plus:before {
  content: '\e8cc';
} /* '' */
.Icon_calendar-minus:before {
  content: '\e8cd';
} /* '' */
.Icon_calendar-exclamation:before {
  content: '\e8ce';
} /* '' */
.Icon_calendar-star:before {
  content: '\e8cf';
} /* '' */
.Icon_calendar-times:before {
  content: '\e8d0';
} /* '' */
.Icon_calendar-week:before {
  content: '\e8d1';
} /* '' */
.Icon_camera:before {
  content: '\e8d2';
} /* '' */
.Icon_camera-alt:before {
  content: '\e8d3';
} /* '' */
.Icon_camera-retro:before {
  content: '\e8d4';
} /* '' */
.Icon_campfire:before {
  content: '\e8d5';
} /* '' */
.Icon_campground:before {
  content: '\e8d6';
} /* '' */
.Icon_candle-holder:before {
  content: '\e8d7';
} /* '' */
.Icon_candy-cane:before {
  content: '\e8d8';
} /* '' */
.Icon_candy-corn:before {
  content: '\e8d9';
} /* '' */
.Icon_cannabis:before {
  content: '\e8da';
} /* '' */
.Icon_capsules:before {
  content: '\e8db';
} /* '' */
.Icon_car:before {
  content: '\e8dc';
} /* '' */
.Icon_car-alt:before {
  content: '\e8dd';
} /* '' */
.Icon_car-battery:before {
  content: '\e8de';
} /* '' */
.Icon_car-bump:before {
  content: '\e8df';
} /* '' */
.Icon_car-crash:before {
  content: '\e8e0';
} /* '' */
.Icon_caret-circle-down:before {
  content: '\e8e1';
} /* '' */
.Icon_caret-circle-left:before {
  content: '\e8e2';
} /* '' */
.Icon_caret-circle-right:before {
  content: '\e8e3';
} /* '' */
.Icon_caret-circle-up:before {
  content: '\e8e4';
} /* '' */
.Icon_caret-down:before {
  content: '\e8e5';
} /* '' */
.Icon_caret-left:before {
  content: '\e8e6';
} /* '' */
.Icon_caret-right:before {
  content: '\e8e7';
} /* '' */
.Icon_caret-square-down:before {
  content: '\e8e8';
} /* '' */
.Icon_caret-square-left:before {
  content: '\e8e9';
} /* '' */
.Icon_caret-square-right:before {
  content: '\e8ea';
} /* '' */
.Icon_caret-square-up:before {
  content: '\e8eb';
} /* '' */
.Icon_caret-up:before {
  content: '\e8ec';
} /* '' */
.Icon_car-garage:before {
  content: '\e8ed';
} /* '' */
.Icon_car-mechanic:before {
  content: '\e8ee';
} /* '' */
.Icon_carrot:before {
  content: '\e8ef';
} /* '' */
.Icon_car-side:before {
  content: '\e8f0';
} /* '' */
.Icon_cart-arrow-down:before {
  content: '\e8f1';
} /* '' */
.Icon_car-tilt:before {
  content: '\e8f2';
} /* '' */
.Icon_cart-plus:before {
  content: '\e8f3';
} /* '' */
.Icon_car-wash:before {
  content: '\e8f4';
} /* '' */
.Icon_cash-register:before {
  content: '\e8f5';
} /* '' */
.Icon_cat:before {
  content: '\e8f6';
} /* '' */
.Icon_cauldron:before {
  content: '\e8f7';
} /* '' */
.Icon_certificate:before {
  content: '\e8f8';
} /* '' */
.Icon_chair:before {
  content: '\e8f9';
} /* '' */
.Icon_chair-office:before {
  content: '\e8fa';
} /* '' */
.Icon_chalkboard:before {
  content: '\e8fb';
} /* '' */
.Icon_chalkboard-teacher:before {
  content: '\e8fc';
} /* '' */
.Icon_charging-station:before {
  content: '\e8fd';
} /* '' */
.Icon_chart-area:before {
  content: '\e8fe';
} /* '' */
.Icon_chart-bar:before {
  content: '\e8ff';
} /* '' */
.Icon_chart-line:before {
  content: '\e900';
} /* '' */
.Icon_chart-line-down:before {
  content: '\e901';
} /* '' */
.Icon_chart-network:before {
  content: '\e902';
} /* '' */
.Icon_chart-pie:before {
  content: '\e903';
} /* '' */
.Icon_chart-pie-alt:before {
  content: '\e904';
} /* '' */
.Icon_chart-scatter:before {
  content: '\e905';
} /* '' */
.Icon_check:before {
  content: '\e906';
} /* '' */
.Icon_fa-check-circle:before {
  content: '\e907';
} /* '' */
.Icon_check-double:before {
  content: '\e908';
} /* '' */
.Icon_check-square:before {
  content: '\e909';
} /* '' */
.Icon_cheese:before {
  content: '\e90a';
} /* '' */
.Icon_cheeseburger:before {
  content: '\e90b';
} /* '' */
.Icon_cheese-swiss:before {
  content: '\e90c';
} /* '' */
.Icon_chess:before {
  content: '\e90d';
} /* '' */
.Icon_chess-bishop:before {
  content: '\e90e';
} /* '' */
.Icon_chess-bishop-alt:before {
  content: '\e90f';
} /* '' */
.Icon_chess-board:before {
  content: '\e910';
} /* '' */
.Icon_chess-clock:before {
  content: '\e911';
} /* '' */
.Icon_chess-clock-alt:before {
  content: '\e912';
} /* '' */
.Icon_chess-king:before {
  content: '\e913';
} /* '' */
.Icon_chess-king-alt:before {
  content: '\e914';
} /* '' */
.Icon_chess-knight:before {
  content: '\e915';
} /* '' */
.Icon_chess-knight-alt:before {
  content: '\e916';
} /* '' */
.Icon_chess-pawn:before {
  content: '\e917';
} /* '' */
.Icon_chess-pawn-alt:before {
  content: '\e918';
} /* '' */
.Icon_chess-queen:before {
  content: '\e919';
} /* '' */
.Icon_chess-queen-alt:before {
  content: '\e91a';
} /* '' */
.Icon_chess-rook:before {
  content: '\e91b';
} /* '' */
.Icon_chess-rook-alt:before {
  content: '\e91c';
} /* '' */
.Icon_chevron-circle-down:before {
  content: '\e91d';
} /* '' */
.Icon_chevron-circle-left:before {
  content: '\e91e';
} /* '' */
.Icon_chevron-circle-right:before {
  content: '\e91f';
} /* '' */
.Icon_chevron-circle-up:before {
  content: '\e920';
} /* '' */
.Icon_chevron-double-down:before {
  content: '\e921';
} /* '' */
.Icon_chevron-double-left:before {
  content: '\e922';
} /* '' */
.Icon_chevron-double-right:before {
  content: '\e923';
} /* '' */
.Icon_chevron-double-up:before {
  content: '\e924';
} /* '' */
.Icon_chevron-down:before {
  content: '\e925';
} /* '' */
.Icon_chevron-left:before {
  content: '\e926';
} /* '' */
.Icon_chevron-right:before {
  content: '\e927';
} /* '' */
.Icon_chevron-square-down:before {
  content: '\e928';
} /* '' */
.Icon_chevron-square-left:before {
  content: '\e929';
} /* '' */
.Icon_chevron-square-right:before {
  content: '\e92a';
} /* '' */
.Icon_chevron-square-up:before {
  content: '\e92b';
} /* '' */
.Icon_chevron-up:before {
  content: '\e92c';
} /* '' */
.Icon_child:before {
  content: '\e92d';
} /* '' */
.Icon_chimney:before {
  content: '\e92e';
} /* '' */
.Icon_church:before {
  content: '\e92f';
} /* '' */
.Icon_circle:before {
  content: '\e930';
} /* '' */
.Icon_circle-notch:before {
  content: '\e931';
} /* '' */
.Icon_city:before {
  content: '\e932';
} /* '' */
.Icon_claw-marks:before {
  content: '\e933';
} /* '' */
.Icon_clinic-medical:before {
  content: '\e934';
} /* '' */
.Icon_clipboard:before {
  content: '\e935';
} /* '' */
.Icon_clipboard-check:before {
  content: '\e936';
} /* '' */
.Icon_clipboard-list:before {
  content: '\e937';
} /* '' */
.Icon_clipboard-list-check:before {
  content: '\e938';
} /* '' */
.Icon_clipboard-prescription:before {
  content: '\e939';
} /* '' */
.Icon_clipboard-user:before {
  content: '\e93a';
} /* '' */
.Icon_fa-clock:before {
  content: '\e93b';
} /* '' */
.Icon_clone:before {
  content: '\e93c';
} /* '' */
.Icon_closed-captioning:before {
  content: '\e93d';
} /* '' */
.Icon_cloud:before {
  content: '\e93e';
} /* '' */
.Icon_cloud-download:before {
  content: '\e93f';
} /* '' */
.Icon_cloud-download-alt:before {
  content: '\e940';
} /* '' */
.Icon_cloud-drizzle:before {
  content: '\e941';
} /* '' */
.Icon_cloud-hail:before {
  content: '\e942';
} /* '' */
.Icon_cloud-hail-mixed:before {
  content: '\e943';
} /* '' */
.Icon_cloud-meatball:before {
  content: '\e944';
} /* '' */
.Icon_cloud-moon:before {
  content: '\e945';
} /* '' */
.Icon_cloud-moon-rain:before {
  content: '\e946';
} /* '' */
.Icon_cloud-rain:before {
  content: '\e947';
} /* '' */
.Icon_cloud-rainbow:before {
  content: '\e948';
} /* '' */
.Icon_clouds:before {
  content: '\e949';
} /* '' */
.Icon_cloud-showers:before {
  content: '\e94a';
} /* '' */
.Icon_cloud-showers-heavy:before {
  content: '\e94b';
} /* '' */
.Icon_cloud-sleet:before {
  content: '\e94c';
} /* '' */
.Icon_clouds-moon:before {
  content: '\e94d';
} /* '' */
.Icon_cloud-snow:before {
  content: '\e94e';
} /* '' */
.Icon_clouds-sun:before {
  content: '\e94f';
} /* '' */
.Icon_cloud-sun:before {
  content: '\e950';
} /* '' */
.Icon_cloud-sun-rain:before {
  content: '\e951';
} /* '' */
.Icon_cloud-upload:before {
  content: '\e952';
} /* '' */
.Icon_cloud-upload-alt:before {
  content: '\e953';
} /* '' */
.Icon_club:before {
  content: '\e954';
} /* '' */
.Icon_cocktail:before {
  content: '\e955';
} /* '' */
.Icon_code:before {
  content: '\e956';
} /* '' */
.Icon_code-branch:before {
  content: '\e957';
} /* '' */
.Icon_code-commit:before {
  content: '\e958';
} /* '' */
.Icon_code-merge:before {
  content: '\e959';
} /* '' */
.Icon_coffee:before {
  content: '\e95a';
} /* '' */
.Icon_coffee-togo:before {
  content: '\e95b';
} /* '' */
.Icon_coffin:before {
  content: '\e95c';
} /* '' */
.Icon_cog:before {
  content: '\e95d';
} /* '' */
.Icon_cogs:before {
  content: '\e95e';
} /* '' */
.Icon_coins:before {
  content: '\e95f';
} /* '' */
.Icon_columns:before {
  content: '\e960';
} /* '' */
.Icon_comment:before {
  content: '\e961';
} /* '' */
.Icon_comment-alt:before {
  content: '\e962';
} /* '' */
.Icon_comment-alt-check:before {
  content: '\e963';
} /* '' */
.Icon_comment-alt-dollar:before {
  content: '\e964';
} /* '' */
.Icon_comment-alt-dots:before {
  content: '\e965';
} /* '' */
.Icon_comment-alt-edit:before {
  content: '\e966';
} /* '' */
.Icon_comment-alt-exclamation:before {
  content: '\e967';
} /* '' */
.Icon_comment-alt-lines:before {
  content: '\e968';
} /* '' */
.Icon_comment-alt-medical:before {
  content: '\e969';
} /* '' */
.Icon_comment-alt-minus:before {
  content: '\e96a';
} /* '' */
.Icon_comment-alt-plus:before {
  content: '\e96b';
} /* '' */
.Icon_comment-alt-slash:before {
  content: '\e96c';
} /* '' */
.Icon_comment-alt-smile:before {
  content: '\e96d';
} /* '' */
.Icon_comment-alt-times:before {
  content: '\e96e';
} /* '' */
.Icon_comment-check:before {
  content: '\e96f';
} /* '' */
.Icon_comment-dollar:before {
  content: '\e970';
} /* '' */
.Icon_comment-dots:before {
  content: '\e971';
} /* '' */
.Icon_comment-edit:before {
  content: '\e972';
} /* '' */
.Icon_comment-exclamation:before {
  content: '\e973';
} /* '' */
.Icon_comment-lines:before {
  content: '\e974';
} /* '' */
.Icon_comment-medical:before {
  content: '\e975';
} /* '' */
.Icon_comment-minus:before {
  content: '\e976';
} /* '' */
.Icon_comment-plus:before {
  content: '\e977';
} /* '' */
.Icon_comments:before {
  content: '\e978';
} /* '' */
.Icon_comments-alt:before {
  content: '\e979';
} /* '' */
.Icon_comments-alt-dollar:before {
  content: '\e97a';
} /* '' */
.Icon_comments-dollar:before {
  content: '\e97b';
} /* '' */
.Icon_comment-slash:before {
  content: '\e97c';
} /* '' */
.Icon_comment-smile:before {
  content: '\e97d';
} /* '' */
.Icon_comment-times:before {
  content: '\e97e';
} /* '' */
.Icon_compact-disc:before {
  content: '\e97f';
} /* '' */
.Icon_compass:before {
  content: '\e980';
} /* '' */
.Icon_compass-slash:before {
  content: '\e981';
} /* '' */
.Icon_compress:before {
  content: '\e982';
} /* '' */
.Icon_compress-alt:before {
  content: '\e983';
} /* '' */
.Icon_compress-arrows-alt:before {
  content: '\e984';
} /* '' */
.Icon_compress-wide:before {
  content: '\e985';
} /* '' */
.Icon_concierge-bell:before {
  content: '\e986';
} /* '' */
.Icon_container-storage:before {
  content: '\e987';
} /* '' */
.Icon_conveyor-belt:before {
  content: '\e988';
} /* '' */
.Icon_conveyor-belt-alt:before {
  content: '\e989';
} /* '' */
.Icon_cookie:before {
  content: '\e98a';
} /* '' */
.Icon_cookie-bite:before {
  content: '\e98b';
} /* '' */
.Icon_copy:before {
  content: '\e98c';
} /* '' */
.Icon_copyright:before {
  content: '\e98d';
} /* '' */
.Icon_corn:before {
  content: '\e98e';
} /* '' */
.Icon_couch:before {
  content: '\e98f';
} /* '' */
.Icon_cow:before {
  content: '\e990';
} /* '' */
.Icon_credit-card:before {
  content: '\e991';
} /* '' */
.Icon_credit-card-blank:before {
  content: '\e992';
} /* '' */
.Icon_credit-card-front:before {
  content: '\e993';
} /* '' */
.Icon_cricket:before {
  content: '\e994';
} /* '' */
.Icon_croissant:before {
  content: '\e995';
} /* '' */
.Icon_crop:before {
  content: '\e996';
} /* '' */
.Icon_crop-alt:before {
  content: '\e997';
} /* '' */
.Icon_cross:before {
  content: '\e998';
} /* '' */
.Icon_crosshairs:before {
  content: '\e999';
} /* '' */
.Icon_crow:before {
  content: '\e99a';
} /* '' */
.Icon_crown:before {
  content: '\e99b';
} /* '' */
.Icon_crutch:before {
  content: '\e99c';
} /* '' */
.Icon_crutches:before {
  content: '\e99d';
} /* '' */
.Icon_cube:before {
  content: '\e99e';
} /* '' */
.Icon_cubes:before {
  content: '\e99f';
} /* '' */
.Icon_curling:before {
  content: '\e9a0';
} /* '' */
.Icon_cut:before {
  content: '\e9a1';
} /* '' */
.Icon_dagger:before {
  content: '\e9a2';
} /* '' */
.Icon_database:before {
  content: '\e9a3';
} /* '' */
.Icon_deaf:before {
  content: '\e9a4';
} /* '' */
.Icon_debug:before {
  content: '\e9a5';
} /* '' */
.Icon_deer:before {
  content: '\e9a6';
} /* '' */
.Icon_deer-rudolph:before {
  content: '\e9a7';
} /* '' */
.Icon_democrat:before {
  content: '\e9a8';
} /* '' */
.Icon_desktop:before {
  content: '\e9a9';
} /* '' */
.Icon_desktop-alt:before {
  content: '\e9aa';
} /* '' */
.Icon_dewpoint:before {
  content: '\e9ab';
} /* '' */
.Icon_dharmachakra:before {
  content: '\e9ac';
} /* '' */
.Icon_diagnoses:before {
  content: '\e9ad';
} /* '' */
.Icon_diamond:before {
  content: '\e9ae';
} /* '' */
.Icon_dice:before {
  content: '\e9af';
} /* '' */
.Icon_dice-d4:before {
  content: '\e9b0';
} /* '' */
.Icon_dice-d6:before {
  content: '\e9b1';
} /* '' */
.Icon_dice-d8:before {
  content: '\e9b2';
} /* '' */
.Icon_dice-d10:before {
  content: '\e9b3';
} /* '' */
.Icon_dice-d12:before {
  content: '\e9b4';
} /* '' */
.Icon_dice-d20:before {
  content: '\e9b5';
} /* '' */
.Icon_dice-five:before {
  content: '\e9b6';
} /* '' */
.Icon_dice-four:before {
  content: '\e9b7';
} /* '' */
.Icon_dice-one:before {
  content: '\e9b8';
} /* '' */
.Icon_dice-six:before {
  content: '\e9b9';
} /* '' */
.Icon_dice-three:before {
  content: '\e9ba';
} /* '' */
.Icon_dice-two:before {
  content: '\e9bb';
} /* '' */
.Icon_digital-tachograph:before {
  content: '\e9bc';
} /* '' */
.Icon_diploma:before {
  content: '\e9bd';
} /* '' */
.Icon_directions:before {
  content: '\e9be';
} /* '' */
.Icon_disease:before {
  content: '\e9bf';
} /* '' */
.Icon_divide:before {
  content: '\e9c0';
} /* '' */
.Icon_dizzy:before {
  content: '\e9c1';
} /* '' */
.Icon_dna:before {
  content: '\e9c2';
} /* '' */
.Icon_dog:before {
  content: '\e9c3';
} /* '' */
.Icon_dog-leashed:before {
  content: '\e9c4';
} /* '' */
.Icon_dollar-sign:before {
  content: '\e9c5';
} /* '' */
.Icon_dolly:before {
  content: '\e9c6';
} /* '' */
.Icon_dolly-empty:before {
  content: '\e9c7';
} /* '' */
.Icon_dolly-flatbed:before {
  content: '\e9c8';
} /* '' */
.Icon_dolly-flatbed-alt:before {
  content: '\e9c9';
} /* '' */
.Icon_dolly-flatbed-empty:before {
  content: '\e9ca';
} /* '' */
.Icon_donate:before {
  content: '\e9cb';
} /* '' */
.Icon_do-not-enter:before {
  content: '\e9cc';
} /* '' */
.Icon_door-closed:before {
  content: '\e9cd';
} /* '' */
.Icon_door-open:before {
  content: '\e9ce';
} /* '' */
.Icon_dot-circle:before {
  content: '\e9cf';
} /* '' */
.Icon_dove:before {
  content: '\e9d0';
} /* '' */
.Icon_fa-download:before {
  content: '\e9d1';
} /* '' */
.Icon_drafting-compass:before {
  content: '\e9d2';
} /* '' */
.Icon_dragon:before {
  content: '\e9d3';
} /* '' */
.Icon_draw-circle:before {
  content: '\e9d4';
} /* '' */
.Icon_draw-polygon:before {
  content: '\e9d5';
} /* '' */
.Icon_draw-square:before {
  content: '\e9d6';
} /* '' */
.Icon_dreidel:before {
  content: '\e9d7';
} /* '' */
.Icon_drum:before {
  content: '\e9d8';
} /* '' */
.Icon_drum-steelpan:before {
  content: '\e9d9';
} /* '' */
.Icon_drumstick:before {
  content: '\e9da';
} /* '' */
.Icon_drumstick-bite:before {
  content: '\e9db';
} /* '' */
.Icon_duck:before {
  content: '\e9dc';
} /* '' */
.Icon_dumbbell:before {
  content: '\e9dd';
} /* '' */
.Icon_dumpster:before {
  content: '\e9de';
} /* '' */
.Icon_dumpster-fire:before {
  content: '\e9df';
} /* '' */
.Icon_dungeon:before {
  content: '\e9e0';
} /* '' */
.Icon_ear:before {
  content: '\e9e1';
} /* '' */
.Icon_ear-muffs:before {
  content: '\e9e2';
} /* '' */
.Icon_eclipse:before {
  content: '\e9e3';
} /* '' */
.Icon_eclipse-alt:before {
  content: '\e9e4';
} /* '' */
.Icon_fa-edit:before {
  content: '\e9e5';
} /* '' */
.Icon_egg:before {
  content: '\e9e6';
} /* '' */
.Icon_egg-fried:before {
  content: '\e9e7';
} /* '' */
.Icon_eject:before {
  content: '\e9e8';
} /* '' */
.Icon_elephant:before {
  content: '\e9e9';
} /* '' */
.Icon_ellipsis-h:before {
  content: '\e9ea';
} /* '' */
.Icon_ellipsis-h-alt:before {
  content: '\e9eb';
} /* '' */
.Icon_ellipsis-v:before {
  content: '\e9ec';
} /* '' */
.Icon_ellipsis-v-alt:before {
  content: '\e9ed';
} /* '' */
.Icon_empty-set:before {
  content: '\e9ee';
} /* '' */
.Icon_engine-warning:before {
  content: '\e9ef';
} /* '' */
.Icon_fa-envelope:before {
  content: '\e9f0';
} /* '' */
.Icon_envelope-open:before {
  content: '\e9f1';
} /* '' */
.Icon_envelope-open-dollar:before {
  content: '\e9f2';
} /* '' */
.Icon_envelope-open-text:before {
  content: '\e9f3';
} /* '' */
.Icon_envelope-square:before {
  content: '\e9f4';
} /* '' */
.Icon_equals:before {
  content: '\e9f5';
} /* '' */
.Icon_eraser:before {
  content: '\e9f6';
} /* '' */
.Icon_ethernet:before {
  content: '\e9f7';
} /* '' */
.Icon_euro-sign:before {
  content: '\e9f8';
} /* '' */
.Icon_exchange:before {
  content: '\e9f9';
} /* '' */
.Icon_exchange-alt:before {
  content: '\e9fa';
} /* '' */
.Icon_exclamation:before {
  content: '\e9fb';
} /* '' */
.Icon_exclamation-circle:before {
  content: '\e9fc';
} /* '' */
.Icon_exclamation-square:before {
  content: '\e9fd';
} /* '' */
.Icon_exclamation-triangle:before {
  content: '\e9fe';
} /* '' */
.Icon_expand:before {
  content: '\e9ff';
} /* '' */
.Icon_expand-alt:before {
  content: '\ea00';
} /* '' */
.Icon_expand-arrows:before {
  content: '\ea01';
} /* '' */
.Icon_expand-arrows-alt:before {
  content: '\ea02';
} /* '' */
.Icon_expand-wide:before {
  content: '\ea03';
} /* '' */
.Icon_external-link:before {
  content: '\ea04';
} /* '' */
.Icon_external-link-alt:before {
  content: '\ea05';
} /* '' */
.Icon_external-link-square:before {
  content: '\ea06';
} /* '' */
.Icon_external-link-square-alt:before {
  content: '\ea07';
} /* '' */
.Icon_fa-eye:before {
  content: '\ea08';
} /* '' */
.Icon_eye-dropper:before {
  content: '\ea09';
} /* '' */
.Icon_eye-evil:before {
  content: '\ea0a';
} /* '' */
.Icon_eye-slash:before {
  content: '\ea0b';
} /* '' */
.Icon_fast-backward:before {
  content: '\ea0c';
} /* '' */
.Icon_fast-forward:before {
  content: '\ea0d';
} /* '' */
.Icon_fax:before {
  content: '\ea0e';
} /* '' */
.Icon_feather:before {
  content: '\ea0f';
} /* '' */
.Icon_feather-alt:before {
  content: '\ea10';
} /* '' */
.Icon_female:before {
  content: '\ea11';
} /* '' */
.Icon_field-hockey:before {
  content: '\ea12';
} /* '' */
.Icon_fighter-jet:before {
  content: '\ea13';
} /* '' */
.Icon_file:before {
  content: '\ea14';
} /* '' */
.Icon_file-alt:before {
  content: '\ea15';
} /* '' */
.Icon_file-archive:before {
  content: '\ea16';
} /* '' */
.Icon_file-audio:before {
  content: '\ea17';
} /* '' */
.Icon_file-certificate:before {
  content: '\ea18';
} /* '' */
.Icon_file-chart-line:before {
  content: '\ea19';
} /* '' */
.Icon_file-chart-pie:before {
  content: '\ea1a';
} /* '' */
.Icon_file-check:before {
  content: '\ea1b';
} /* '' */
.Icon_file-code:before {
  content: '\ea1c';
} /* '' */
.Icon_file-contract:before {
  content: '\ea1d';
} /* '' */
.Icon_file-csv:before {
  content: '\ea1e';
} /* '' */
.Icon_file-download:before {
  content: '\ea1f';
} /* '' */
.Icon_file-edit:before {
  content: '\ea20';
} /* '' */
.Icon_file-excel:before {
  content: '\ea21';
} /* '' */
.Icon_file-exclamation:before {
  content: '\ea22';
} /* '' */
.Icon_file-export:before {
  content: '\ea23';
} /* '' */
.Icon_file-image:before {
  content: '\ea24';
} /* '' */
.Icon_file-import:before {
  content: '\ea25';
} /* '' */
.Icon_file-invoice:before {
  content: '\ea26';
} /* '' */
.Icon_file-invoice-dollar:before {
  content: '\ea27';
} /* '' */
.Icon_file-medical:before {
  content: '\ea28';
} /* '' */
.Icon_file-medical-alt:before {
  content: '\ea29';
} /* '' */
.Icon_file-minus:before {
  content: '\ea2a';
} /* '' */
.Icon_file-pdf:before {
  content: '\ea2b';
} /* '' */
.Icon_file-plus:before {
  content: '\ea2c';
} /* '' */
.Icon_file-powerpoint:before {
  content: '\ea2d';
} /* '' */
.Icon_file-prescription:before {
  content: '\ea2e';
} /* '' */
.Icon_file-signature:before {
  content: '\ea2f';
} /* '' */
.Icon_files-medical:before {
  content: '\ea30';
} /* '' */
.Icon_file-spreadsheet:before {
  content: '\ea31';
} /* '' */
.Icon_file-times:before {
  content: '\ea32';
} /* '' */
.Icon_file-upload:before {
  content: '\ea33';
} /* '' */
.Icon_file-user:before {
  content: '\ea34';
} /* '' */
.Icon_file-video:before {
  content: '\ea35';
} /* '' */
.Icon_file-word:before {
  content: '\ea36';
} /* '' */
.Icon_fill:before {
  content: '\ea37';
} /* '' */
.Icon_fill-drip:before {
  content: '\ea38';
} /* '' */
.Icon_film:before {
  content: '\ea39';
} /* '' */
.Icon_film-alt:before {
  content: '\ea3a';
} /* '' */
.Icon_filter:before {
  content: '\ea3b';
} /* '' */
.Icon_fingerprint:before {
  content: '\ea3c';
} /* '' */
.Icon_fire:before {
  content: '\ea3d';
} /* '' */
.Icon_fire-alt:before {
  content: '\ea3e';
} /* '' */
.Icon_fire-extinguisher:before {
  content: '\ea3f';
} /* '' */
.Icon_fireplace:before {
  content: '\ea40';
} /* '' */
.Icon_fire-smoke:before {
  content: '\ea41';
} /* '' */
.Icon_first-aid:before {
  content: '\ea42';
} /* '' */
.Icon_fish:before {
  content: '\ea43';
} /* '' */
.Icon_fish-cooked:before {
  content: '\ea44';
} /* '' */
.Icon_fist-raised:before {
  content: '\ea45';
} /* '' */
.Icon_fa-flag:before {
  content: '\ea46';
} /* '' */
.Icon_flag-alt:before {
  content: '\ea47';
} /* '' */
.Icon_flag-checkered:before {
  content: '\ea48';
} /* '' */
.Icon_flag-usa:before {
  content: '\ea49';
} /* '' */
.Icon_flame:before {
  content: '\ea4a';
} /* '' */
.Icon_flask:before {
  content: '\ea4b';
} /* '' */
.Icon_flask-poison:before {
  content: '\ea4c';
} /* '' */
.Icon_flask-potion:before {
  content: '\ea4d';
} /* '' */
.Icon_flower:before {
  content: '\ea4e';
} /* '' */
.Icon_flower-daffodil:before {
  content: '\ea4f';
} /* '' */
.Icon_flower-tulip:before {
  content: '\ea50';
} /* '' */
.Icon_flushed:before {
  content: '\ea51';
} /* '' */
.Icon_fog:before {
  content: '\ea52';
} /* '' */
.Icon_folder:before {
  content: '\ea53';
} /* '' */
.Icon_folder-minus:before {
  content: '\ea54';
} /* '' */
.Icon_folders:before {
  content: '\ea55';
} /* '' */
.Icon_folder-plus:before {
  content: '\ea56';
} /* '' */
.Icon_folder-open:before {
  content: '\ea57';
} /* '' */
.Icon_folder-times:before {
  content: '\ea58';
} /* '' */
.Icon_folder-tree:before {
  content: '\ea59';
} /* '' */
.Icon_font:before {
  content: '\ea5a';
} /* '' */
.Icon_font-awesome-logo-full:before {
  content: '\ea5b';
} /* '' */
.Icon_football-ball:before {
  content: '\ea5c';
} /* '' */
.Icon_forklift:before {
  content: '\ea5d';
} /* '' */
.Icon_football-helmet:before {
  content: '\ea5e';
} /* '' */
.Icon_forward:before {
  content: '\ea5f';
} /* '' */
.Icon_french-fries:before {
  content: '\ea60';
} /* '' */
.Icon_fragile:before {
  content: '\ea61';
} /* '' */
.Icon_frog:before {
  content: '\ea62';
} /* '' */
.Icon_frosty-head:before {
  content: '\ea63';
} /* '' */
.Icon_frown:before {
  content: '\ea64';
} /* '' */
.Icon_frown-open:before {
  content: '\ea65';
} /* '' */
.Icon_function:before {
  content: '\ea66';
} /* '' */
.Icon_funnel-dollar:before {
  content: '\ea67';
} /* '' */
.Icon_futbol:before {
  content: '\ea68';
} /* '' */
.Icon_gamepad:before {
  content: '\ea69';
} /* '' */
.Icon_gas-pump:before {
  content: '\ea6a';
} /* '' */
.Icon_gas-pump-slash:before {
  content: '\ea6b';
} /* '' */
.Icon_gavel:before {
  content: '\ea6c';
} /* '' */
.Icon_gem:before {
  content: '\ea6d';
} /* '' */
.Icon_genderless:before {
  content: '\ea6e';
} /* '' */
.Icon_ghost:before {
  content: '\ea6f';
} /* '' */
.Icon_gift:before {
  content: '\ea70';
} /* '' */
.Icon_gift-card:before {
  content: '\ea71';
} /* '' */
.Icon_gifts:before {
  content: '\ea72';
} /* '' */
.Icon_gingerbread-man:before {
  content: '\ea73';
} /* '' */
.Icon_glass:before {
  content: '\ea74';
} /* '' */
.Icon_glass-champagne:before {
  content: '\ea75';
} /* '' */
.Icon_glass-cheers:before {
  content: '\ea76';
} /* '' */
.Icon_glasses:before {
  content: '\ea77';
} /* '' */
.Icon_glasses-alt:before {
  content: '\ea78';
} /* '' */
.Icon_glass-martini:before {
  content: '\ea79';
} /* '' */
.Icon_glass-martini-alt:before {
  content: '\ea7a';
} /* '' */
.Icon_glass-whiskey:before {
  content: '\ea7b';
} /* '' */
.Icon_glass-whiskey-rocks:before {
  content: '\ea7c';
} /* '' */
.Icon_globe:before {
  content: '\ea7d';
} /* '' */
.Icon_globe-africa:before {
  content: '\ea7e';
} /* '' */
.Icon_globe-americas:before {
  content: '\ea7f';
} /* '' */
.Icon_globe-asia:before {
  content: '\ea80';
} /* '' */
.Icon_globe-europe:before {
  content: '\ea81';
} /* '' */
.Icon_globe-snow:before {
  content: '\ea82';
} /* '' */
.Icon_globe-stand:before {
  content: '\ea83';
} /* '' */
.Icon_golf-ball:before {
  content: '\ea84';
} /* '' */
.Icon_golf-club:before {
  content: '\ea85';
} /* '' */
.Icon_gopuram:before {
  content: '\ea86';
} /* '' */
.Icon_graduation-cap:before {
  content: '\ea87';
} /* '' */
.Icon_greater-than:before {
  content: '\ea88';
} /* '' */
.Icon_greater-than-equal:before {
  content: '\ea89';
} /* '' */
.Icon_grimace:before {
  content: '\ea8a';
} /* '' */
.Icon_grin:before {
  content: '\ea8b';
} /* '' */
.Icon_grin-alt:before {
  content: '\ea8c';
} /* '' */
.Icon_grin-beam:before {
  content: '\ea8d';
} /* '' */
.Icon_grin-beam-sweat:before {
  content: '\ea8e';
} /* '' */
.Icon_grin-hearts:before {
  content: '\ea8f';
} /* '' */
.Icon_grin-squint:before {
  content: '\ea90';
} /* '' */
.Icon_grin-squint-tears:before {
  content: '\ea91';
} /* '' */
.Icon_grin-stars:before {
  content: '\ea92';
} /* '' */
.Icon_grin-tears:before {
  content: '\ea93';
} /* '' */
.Icon_grin-tongue:before {
  content: '\ea94';
} /* '' */
.Icon_grin-tongue-squint:before {
  content: '\ea95';
} /* '' */
.Icon_grin-tongue-wink:before {
  content: '\ea96';
} /* '' */
.Icon_grin-wink:before {
  content: '\ea97';
} /* '' */
.Icon_grip-horizontal:before {
  content: '\ea98';
} /* '' */
.Icon_grip-lines:before {
  content: '\ea99';
} /* '' */
.Icon_grip-lines-vertical:before {
  content: '\ea9a';
} /* '' */
.Icon_grip-vertical:before {
  content: '\ea9b';
} /* '' */
.Icon_guitar:before {
  content: '\ea9c';
} /* '' */
.Icon_h1:before {
  content: '\ea9d';
} /* '' */
.Icon_h2:before {
  content: '\ea9e';
} /* '' */
.Icon_h3:before {
  content: '\ea9f';
} /* '' */
.Icon_hamburger:before {
  content: '\eaa0';
} /* '' */
.Icon_hammer:before {
  content: '\eaa1';
} /* '' */
.Icon_hammer-war:before {
  content: '\eaa2';
} /* '' */
.Icon_hamsa:before {
  content: '\eaa3';
} /* '' */
.Icon_hand-heart:before {
  content: '\eaa4';
} /* '' */
.Icon_hand-holding:before {
  content: '\eaa5';
} /* '' */
.Icon_hand-holding-box:before {
  content: '\eaa6';
} /* '' */
.Icon_hand-holding-heart:before {
  content: '\eaa7';
} /* '' */
.Icon_hand-holding-magic:before {
  content: '\eaa8';
} /* '' */
.Icon_hand-holding-seedling:before {
  content: '\eaa9';
} /* '' */
.Icon_hand-holding-usd:before {
  content: '\eaaa';
} /* '' */
.Icon_hand-holding-water:before {
  content: '\eaab';
} /* '' */
.Icon_hand-lizard:before {
  content: '\eaac';
} /* '' */
.Icon_hand-middle-finger:before {
  content: '\eaad';
} /* '' */
.Icon_hand-paper:before {
  content: '\eaae';
} /* '' */
.Icon_hand-peace:before {
  content: '\eaaf';
} /* '' */
.Icon_hand-point-down:before {
  content: '\eab0';
} /* '' */
.Icon_hand-pointer:before {
  content: '\eab1';
} /* '' */
.Icon_hand-point-left:before {
  content: '\eab2';
} /* '' */
.Icon_hand-point-right:before {
  content: '\eab3';
} /* '' */
.Icon_hand-point-up:before {
  content: '\eab4';
} /* '' */
.Icon_hand-receiving:before {
  content: '\eab5';
} /* '' */
.Icon_hand-rock:before {
  content: '\eab6';
} /* '' */
.Icon_hands:before {
  content: '\eab7';
} /* '' */
.Icon_hand-scissors:before {
  content: '\eab8';
} /* '' */
.Icon_handshake:before {
  content: '\eab9';
} /* '' */
.Icon_handshake-alt:before {
  content: '\eaba';
} /* '' */
.Icon_hands-heart:before {
  content: '\eabb';
} /* '' */
.Icon_hands-helping:before {
  content: '\eabc';
} /* '' */
.Icon_hand-spock:before {
  content: '\eabd';
} /* '' */
.Icon_hands-usd:before {
  content: '\eabe';
} /* '' */
.Icon_hanukiah:before {
  content: '\eabf';
} /* '' */
.Icon_hard-hat:before {
  content: '\eac0';
} /* '' */
.Icon_hashtag:before {
  content: '\eac1';
} /* '' */
.Icon_hat-santa:before {
  content: '\eac2';
} /* '' */
.Icon_hat-winter:before {
  content: '\eac3';
} /* '' */
.Icon_hat-witch:before {
  content: '\eac4';
} /* '' */
.Icon_hat-wizard:before {
  content: '\eac5';
} /* '' */
.Icon_haykal:before {
  content: '\eac6';
} /* '' */
.Icon_hdd:before {
  content: '\eac7';
} /* '' */
.Icon_heading:before {
  content: '\eac8';
} /* '' */
.Icon_headphones:before {
  content: '\eac9';
} /* '' */
.Icon_headphones-alt:before {
  content: '\eaca';
} /* '' */
.Icon_headset:before {
  content: '\eacb';
} /* '' */
.Icon_head-side:before {
  content: '\eacc';
} /* '' */
.Icon_head-side-brain:before {
  content: '\eacd';
} /* '' */
.Icon_head-side-medical:before {
  content: '\eace';
} /* '' */
.Icon_head-vr:before {
  content: '\eacf';
} /* '' */
.Icon_fa-heart:before {
  content: '\ead0';
} /* '' */
.Icon_heartbeat:before {
  content: '\ead1';
} /* '' */
.Icon_heart-broken:before {
  content: '\ead2';
} /* '' */
.Icon_heart-circle:before {
  content: '\ead3';
} /* '' */
.Icon_heart-rate:before {
  content: '\ead4';
} /* '' */
.Icon_heart-square:before {
  content: '\ead5';
} /* '' */
.Icon_helicopter:before {
  content: '\ead6';
} /* '' */
.Icon_helmet-battle:before {
  content: '\ead7';
} /* '' */
.Icon_hexagon:before {
  content: '\ead8';
} /* '' */
.Icon_highlighter:before {
  content: '\ead9';
} /* '' */
.Icon_hiking:before {
  content: '\eada';
} /* '' */
.Icon_hippo:before {
  content: '\eadb';
} /* '' */
.Icon_history:before {
  content: '\eadc';
} /* '' */
.Icon_hockey-mask:before {
  content: '\eadd';
} /* '' */
.Icon_hockey-puck:before {
  content: '\eade';
} /* '' */
.Icon_hockey-sticks:before {
  content: '\eadf';
} /* '' */
.Icon_holly-berry:before {
  content: '\eae0';
} /* '' */
.Icon_fa-home:before {
  content: '\eae1';
} /* '' */
.Icon_home-alt:before {
  content: '\eae2';
} /* '' */
.Icon_home-heart:before {
  content: '\eae3';
} /* '' */
.Icon_home-lg:before {
  content: '\eae4';
} /* '' */
.Icon_home-lg-alt:before {
  content: '\eae5';
} /* '' */
.Icon_hood-cloak:before {
  content: '\eae6';
} /* '' */
.Icon_horse:before {
  content: '\eae7';
} /* '' */
.Icon_horse-head:before {
  content: '\eae8';
} /* '' */
.Icon_hospital:before {
  content: '\eae9';
} /* '' */
.Icon_hospital-alt:before {
  content: '\eaea';
} /* '' */
.Icon_hospitals:before {
  content: '\eaeb';
} /* '' */
.Icon_hospital-symbol:before {
  content: '\eaec';
} /* '' */
.Icon_hospital-user:before {
  content: '\eaed';
} /* '' */
.Icon_hotdog:before {
  content: '\eaee';
} /* '' */
.Icon_hotel:before {
  content: '\eaef';
} /* '' */
.Icon_hot-tub:before {
  content: '\eaf0';
} /* '' */
.Icon_hourglass:before {
  content: '\eaf1';
} /* '' */
.Icon_hourglass-end:before {
  content: '\eaf2';
} /* '' */
.Icon_hourglass-half:before {
  content: '\eaf3';
} /* '' */
.Icon_hourglass-start:before {
  content: '\eaf4';
} /* '' */
.Icon_house-damage:before {
  content: '\eaf5';
} /* '' */
.Icon_house-flood:before {
  content: '\eaf6';
} /* '' */
.Icon_hryvnia:before {
  content: '\eaf7';
} /* '' */
.Icon_h-square:before {
  content: '\eaf8';
} /* '' */
.Icon_humidity:before {
  content: '\eaf9';
} /* '' */
.Icon_hurricane:before {
  content: '\eafa';
} /* '' */
.Icon_ice-cream:before {
  content: '\eafb';
} /* '' */
.Icon_ice-skate:before {
  content: '\eafc';
} /* '' */
.Icon_icicles:before {
  content: '\eafd';
} /* '' */
.Icon_i-cursor:before {
  content: '\eafe';
} /* '' */
.Icon_id-badge:before {
  content: '\eaff';
} /* '' */
.Icon_id-card:before {
  content: '\eb00';
} /* '' */
.Icon_id-card-alt:before {
  content: '\eb01';
} /* '' */
.Icon_igloo:before {
  content: '\eb02';
} /* '' */
.Icon_image:before {
  content: '\eb03';
} /* '' */
.Icon_images:before {
  content: '\eb04';
} /* '' */
.Icon_inbox:before {
  content: '\eb05';
} /* '' */
.Icon_inbox-in:before {
  content: '\eb06';
} /* '' */
.Icon_inbox-out:before {
  content: '\eb07';
} /* '' */
.Icon_indent:before {
  content: '\eb08';
} /* '' */
.Icon_industry:before {
  content: '\eb09';
} /* '' */
.Icon_industry-alt:before {
  content: '\eb0a';
} /* '' */
.Icon_infinity:before {
  content: '\eb0b';
} /* '' */
.Icon_info:before {
  content: '\eb0c';
} /* '' */
.Icon_info-circle:before {
  content: '\eb0d';
} /* '' */
.Icon_info-square:before {
  content: '\eb0e';
} /* '' */
.Icon_inhaler:before {
  content: '\eb0f';
} /* '' */
.Icon_integral:before {
  content: '\eb10';
} /* '' */
.Icon_intersection:before {
  content: '\eb11';
} /* '' */
.Icon_inventory:before {
  content: '\eb12';
} /* '' */
.Icon_island-tropical:before {
  content: '\eb13';
} /* '' */
.Icon_italic:before {
  content: '\eb14';
} /* '' */
.Icon_jack-o-lantern:before {
  content: '\eb15';
} /* '' */
.Icon_jedi:before {
  content: '\eb16';
} /* '' */
.Icon_joint:before {
  content: '\eb17';
} /* '' */
.Icon_journal-whills:before {
  content: '\eb18';
} /* '' */
.Icon_kaaba:before {
  content: '\eb19';
} /* '' */
.Icon_fa-key:before {
  content: '\eb1a';
} /* '' */
.Icon_keyboard:before {
  content: '\eb1b';
} /* '' */
.Icon_keynote:before {
  content: '\eb1c';
} /* '' */
.Icon_key-skeleton:before {
  content: '\eb1d';
} /* '' */
.Icon_khanda:before {
  content: '\eb1e';
} /* '' */
.Icon_kidneys:before {
  content: '\eb1f';
} /* '' */
.Icon_kiss:before {
  content: '\eb20';
} /* '' */
.Icon_kiss-beam:before {
  content: '\eb21';
} /* '' */
.Icon_kiss-wink-heart:before {
  content: '\eb22';
} /* '' */
.Icon_kite:before {
  content: '\eb23';
} /* '' */
.Icon_kiwi-bird:before {
  content: '\eb24';
} /* '' */
.Icon_knife-kitchen:before {
  content: '\eb25';
} /* '' */
.Icon_lambda:before {
  content: '\eb26';
} /* '' */
.Icon_lamp:before {
  content: '\eb27';
} /* '' */
.Icon_landmark:before {
  content: '\eb28';
} /* '' */
.Icon_landmark-alt:before {
  content: '\eb29';
} /* '' */
.Icon_language:before {
  content: '\eb2a';
} /* '' */
.Icon_laptop:before {
  content: '\eb2b';
} /* '' */
.Icon_laptop-code:before {
  content: '\eb2c';
} /* '' */
.Icon_laptop-medical:before {
  content: '\eb2d';
} /* '' */
.Icon_laugh:before {
  content: '\eb2e';
} /* '' */
.Icon_laugh-beam:before {
  content: '\eb2f';
} /* '' */
.Icon_laugh-squint:before {
  content: '\eb30';
} /* '' */
.Icon_laugh-wink:before {
  content: '\eb31';
} /* '' */
.Icon_layer-group:before {
  content: '\eb32';
} /* '' */
.Icon_layer-minus:before {
  content: '\eb33';
} /* '' */
.Icon_layer-plus:before {
  content: '\eb34';
} /* '' */
.Icon_leaf:before {
  content: '\eb35';
} /* '' */
.Icon_leaf-heart:before {
  content: '\eb36';
} /* '' */
.Icon_leaf-maple:before {
  content: '\eb37';
} /* '' */
.Icon_leaf-oak:before {
  content: '\eb38';
} /* '' */
.Icon_lemon:before {
  content: '\eb39';
} /* '' */
.Icon_less-than:before {
  content: '\eb3a';
} /* '' */
.Icon_less-than-equal:before {
  content: '\eb3b';
} /* '' */
.Icon_level-down:before {
  content: '\eb3c';
} /* '' */
.Icon_level-down-alt:before {
  content: '\eb3d';
} /* '' */
.Icon_level-up:before {
  content: '\eb3e';
} /* '' */
.Icon_level-up-alt:before {
  content: '\eb3f';
} /* '' */
.Icon_life-ring:before {
  content: '\eb40';
} /* '' */
.Icon_lightbulb:before {
  content: '\eb41';
} /* '' */
.Icon_lightbulb-dollar:before {
  content: '\eb42';
} /* '' */
.Icon_lightbulb-exclamation:before {
  content: '\eb43';
} /* '' */
.Icon_lightbulb-on:before {
  content: '\eb44';
} /* '' */
.Icon_lightbulb-slash:before {
  content: '\eb45';
} /* '' */
.Icon_lights-holiday:before {
  content: '\eb46';
} /* '' */
.Icon_link:before {
  content: '\eb47';
} /* '' */
.Icon_lips:before {
  content: '\eb48';
} /* '' */
.Icon_lira-sign:before {
  content: '\eb49';
} /* '' */
.Icon_list:before {
  content: '\eb4a';
} /* '' */
.Icon_list-alt:before {
  content: '\eb4b';
} /* '' */
.Icon_list-ol:before {
  content: '\eb4c';
} /* '' */
.Icon_list-ul:before {
  content: '\eb4d';
} /* '' */
.Icon_fa-location:before {
  content: '\eb4e';
} /* '' */
.Icon_location-arrow:before {
  content: '\eb4f';
} /* '' */
.Icon_location-circle:before {
  content: '\eb50';
} /* '' */
.Icon_location-slash:before {
  content: '\eb51';
} /* '' */
.Icon_fa-lock:before {
  content: '\eb52';
} /* '' */
.Icon_lock-alt:before {
  content: '\eb53';
} /* '' */
.Icon_lock-open:before {
  content: '\eb54';
} /* '' */
.Icon_lock-open-alt:before {
  content: '\eb55';
} /* '' */
.Icon_long-arrow-alt-down:before {
  content: '\eb56';
} /* '' */
.Icon_long-arrow-alt-left:before {
  content: '\eb57';
} /* '' */
.Icon_long-arrow-alt-right:before {
  content: '\eb58';
} /* '' */
.Icon_long-arrow-alt-up:before {
  content: '\eb59';
} /* '' */
.Icon_long-arrow-down:before {
  content: '\eb5a';
} /* '' */
.Icon_long-arrow-left:before {
  content: '\eb5b';
} /* '' */
.Icon_long-arrow-right:before {
  content: '\eb5c';
} /* '' */
.Icon_long-arrow-up:before {
  content: '\eb5d';
} /* '' */
.Icon_loveseat:before {
  content: '\eb5e';
} /* '' */
.Icon_low-vision:before {
  content: '\eb5f';
} /* '' */
.Icon_luchador:before {
  content: '\eb60';
} /* '' */
.Icon_luggage-cart:before {
  content: '\eb61';
} /* '' */
.Icon_lungs:before {
  content: '\eb62';
} /* '' */
.Icon_mace:before {
  content: '\eb63';
} /* '' */
.Icon_magic:before {
  content: '\eb64';
} /* '' */
.Icon_magnet:before {
  content: '\eb65';
} /* '' */
.Icon_mailbox:before {
  content: '\eb66';
} /* '' */
.Icon_mail-bulk:before {
  content: '\eb67';
} /* '' */
.Icon_male:before {
  content: '\eb68';
} /* '' */
.Icon_mandolin:before {
  content: '\eb69';
} /* '' */
.Icon_map:before {
  content: '\eb6a';
} /* '' */
.Icon_map-marked:before {
  content: '\eb6b';
} /* '' */
.Icon_map-marked-alt:before {
  content: '\eb6c';
} /* '' */
.Icon_map-marker:before {
  content: '\eb6d';
} /* '' */
.Icon_map-marker-alt:before {
  content: '\eb6e';
} /* '' */
.Icon_map-marker-alt-slash:before {
  content: '\eb6f';
} /* '' */
.Icon_map-marker-check:before {
  content: '\eb70';
} /* '' */
.Icon_map-marker-edit:before {
  content: '\eb71';
} /* '' */
.Icon_map-marker-exclamation:before {
  content: '\eb72';
} /* '' */
.Icon_map-marker-minus:before {
  content: '\eb73';
} /* '' */
.Icon_map-marker-plus:before {
  content: '\eb74';
} /* '' */
.Icon_map-marker-question:before {
  content: '\eb75';
} /* '' */
.Icon_map-marker-slash:before {
  content: '\eb76';
} /* '' */
.Icon_map-marker-smile:before {
  content: '\eb77';
} /* '' */
.Icon_map-marker-times:before {
  content: '\eb78';
} /* '' */
.Icon_map-pin:before {
  content: '\eb79';
} /* '' */
.Icon_map-signs:before {
  content: '\eb7a';
} /* '' */
.Icon_marker:before {
  content: '\eb7b';
} /* '' */
.Icon_mars:before {
  content: '\eb7c';
} /* '' */
.Icon_mars-double:before {
  content: '\eb7d';
} /* '' */
.Icon_mars-stroke:before {
  content: '\eb7e';
} /* '' */
.Icon_mars-stroke-h:before {
  content: '\eb7f';
} /* '' */
.Icon_mars-stroke-v:before {
  content: '\eb80';
} /* '' */
.Icon_mask:before {
  content: '\eb81';
} /* '' */
.Icon_meat:before {
  content: '\eb82';
} /* '' */
.Icon_fa-medal:before {
  content: '\eb83';
} /* '' */
.Icon_medkit:before {
  content: '\eb84';
} /* '' */
.Icon_megaphone:before {
  content: '\eb85';
} /* '' */
.Icon_meh:before {
  content: '\eb86';
} /* '' */
.Icon_meh-blank:before {
  content: '\eb87';
} /* '' */
.Icon_meh-rolling-eyes:before {
  content: '\eb88';
} /* '' */
.Icon_memory:before {
  content: '\eb89';
} /* '' */
.Icon_menorah:before {
  content: '\eb8a';
} /* '' */
.Icon_mercury:before {
  content: '\eb8b';
} /* '' */
.Icon_meteor:before {
  content: '\eb8c';
} /* '' */
.Icon_microchip:before {
  content: '\eb8d';
} /* '' */
.Icon_microphone:before {
  content: '\eb8e';
} /* '' */
.Icon_microphone-alt:before {
  content: '\eb8f';
} /* '' */
.Icon_microphone-alt-slash:before {
  content: '\eb90';
} /* '' */
.Icon_microphone-slash:before {
  content: '\eb91';
} /* '' */
.Icon_microscope:before {
  content: '\eb92';
} /* '' */
.Icon_mind-share:before {
  content: '\eb93';
} /* '' */
.Icon_minus:before {
  content: '\eb94';
} /* '' */
.Icon_minus-circle:before {
  content: '\eb95';
} /* '' */
.Icon_minus-hexagon:before {
  content: '\eb96';
} /* '' */
.Icon_minus-octagon:before {
  content: '\eb97';
} /* '' */
.Icon_minus-square:before {
  content: '\eb98';
} /* '' */
.Icon_mistletoe:before {
  content: '\eb99';
} /* '' */
.Icon_mitten:before {
  content: '\eb9a';
} /* '' */
.Icon_mobile:before {
  content: '\eb9b';
} /* '' */
.Icon_mobile-alt:before {
  content: '\eb9c';
} /* '' */
.Icon_mobile-android:before {
  content: '\eb9d';
} /* '' */
.Icon_mobile-android-alt:before {
  content: '\eb9e';
} /* '' */
.Icon_money-bill:before {
  content: '\eb9f';
} /* '' */
.Icon_money-bill-alt:before {
  content: '\eba0';
} /* '' */
.Icon_money-bill-wave:before {
  content: '\eba1';
} /* '' */
.Icon_money-bill-wave-alt:before {
  content: '\eba2';
} /* '' */
.Icon_money-check:before {
  content: '\eba3';
} /* '' */
.Icon_money-check-alt:before {
  content: '\eba4';
} /* '' */
.Icon_monitor-heart-rate:before {
  content: '\eba5';
} /* '' */
.Icon_monkey:before {
  content: '\eba6';
} /* '' */
.Icon_monument:before {
  content: '\eba7';
} /* '' */
.Icon_moon:before {
  content: '\eba8';
} /* '' */
.Icon_moon-cloud:before {
  content: '\eba9';
} /* '' */
.Icon_moon-stars:before {
  content: '\ebaa';
} /* '' */
.Icon_mortar-pestle:before {
  content: '\ebab';
} /* '' */
.Icon_mosque:before {
  content: '\ebac';
} /* '' */
.Icon_motorcycle:before {
  content: '\ebad';
} /* '' */
.Icon_mountain:before {
  content: '\ebae';
} /* '' */
.Icon_mountains:before {
  content: '\ebaf';
} /* '' */
.Icon_mouse-pointer:before {
  content: '\ebb0';
} /* '' */
.Icon_mug-hot:before {
  content: '\ebb1';
} /* '' */
.Icon_mug-marshmallows:before {
  content: '\ebb2';
} /* '' */
.Icon_music:before {
  content: '\ebb3';
} /* '' */
.Icon_narwhal:before {
  content: '\ebb4';
} /* '' */
.Icon_network-wired:before {
  content: '\ebb5';
} /* '' */
.Icon_neuter:before {
  content: '\ebb6';
} /* '' */
.Icon_newspaper:before {
  content: '\ebb7';
} /* '' */
.Icon_not-equal:before {
  content: '\ebb8';
} /* '' */
.Icon_notes-medical:before {
  content: '\ebb9';
} /* '' */
.Icon_object-group:before {
  content: '\ebba';
} /* '' */
.Icon_object-ungroup:before {
  content: '\ebbb';
} /* '' */
.Icon_octagon:before {
  content: '\ebbc';
} /* '' */
.Icon_oil-can:before {
  content: '\ebbd';
} /* '' */
.Icon_oil-temp:before {
  content: '\ebbe';
} /* '' */
.Icon_om:before {
  content: '\ebbf';
} /* '' */
.Icon_omega:before {
  content: '\ebc0';
} /* '' */
.Icon_ornament:before {
  content: '\ebc1';
} /* '' */
.Icon_otter:before {
  content: '\ebc2';
} /* '' */
.Icon_outdent:before {
  content: '\ebc3';
} /* '' */
.Icon_pager:before {
  content: '\ebc4';
} /* '' */
.Icon_paint-brush:before {
  content: '\ebc5';
} /* '' */
.Icon_paint-brush-alt:before {
  content: '\ebc6';
} /* '' */
.Icon_paint-roller:before {
  content: '\ebc7';
} /* '' */
.Icon_palette:before {
  content: '\ebc8';
} /* '' */
.Icon_pallet:before {
  content: '\ebc9';
} /* '' */
.Icon_pallet-alt:before {
  content: '\ebca';
} /* '' */
.Icon_paperclip:before {
  content: '\ebcb';
} /* '' */
.Icon_paper-plane:before {
  content: '\ebcc';
} /* '' */
.Icon_parachute-box:before {
  content: '\ebcd';
} /* '' */
.Icon_paragraph:before {
  content: '\ebce';
} /* '' */
.Icon_parking:before {
  content: '\ebcf';
} /* '' */
.Icon_parking-circle:before {
  content: '\ebd0';
} /* '' */
.Icon_parking-circle-slash:before {
  content: '\ebd1';
} /* '' */
.Icon_parking-slash:before {
  content: '\ebd2';
} /* '' */
.Icon_passport:before {
  content: '\ebd3';
} /* '' */
.Icon_pastafarianism:before {
  content: '\ebd4';
} /* '' */
.Icon_paste:before {
  content: '\ebd5';
} /* '' */
.Icon_pause:before {
  content: '\ebd6';
} /* '' */
.Icon_pause-circle:before {
  content: '\ebd7';
} /* '' */
.Icon_paw:before {
  content: '\ebd8';
} /* '' */
.Icon_paw-alt:before {
  content: '\ebd9';
} /* '' */
.Icon_paw-claws:before {
  content: '\ebda';
} /* '' */
.Icon_peace:before {
  content: '\ebdb';
} /* '' */
.Icon_pegasus:before {
  content: '\ebdc';
} /* '' */
.Icon_pen:before {
  content: '\ebdd';
} /* '' */
.Icon_pen-alt:before {
  content: '\ebde';
} /* '' */
.Icon_pencil:before {
  content: '\ebdf';
} /* '' */
.Icon_pencil-alt:before {
  content: '\ebe0';
} /* '' */
.Icon_pencil-paintbrush:before {
  content: '\ebe1';
} /* '' */
.Icon_pencil-ruler:before {
  content: '\ebe2';
} /* '' */
.Icon_pen-fancy:before {
  content: '\ebe3';
} /* '' */
.Icon_pennant:before {
  content: '\ebe4';
} /* '' */
.Icon_pen-nib:before {
  content: '\ebe5';
} /* '' */
.Icon_pen-square:before {
  content: '\ebe6';
} /* '' */
.Icon_people-carry:before {
  content: '\ebe7';
} /* '' */
.Icon_pepper-hot:before {
  content: '\ebe8';
} /* '' */
.Icon_percent:before {
  content: '\ebe9';
} /* '' */
.Icon_percentage:before {
  content: '\ebea';
} /* '' */
.Icon_person-booth:before {
  content: '\ebeb';
} /* '' */
.Icon_person-carry:before {
  content: '\ebec';
} /* '' */
.Icon_person-dolly:before {
  content: '\ebed';
} /* '' */
.Icon_person-dolly-empty:before {
  content: '\ebee';
} /* '' */
.Icon_person-sign:before {
  content: '\ebef';
} /* '' */
.Icon_phone:before {
  content: '\ebf0';
} /* '' */
.Icon_phone-office:before {
  content: '\ebf1';
} /* '' */
.Icon_phone-plus:before {
  content: '\ebf2';
} /* '' */
.Icon_phone-slash:before {
  content: '\ebf3';
} /* '' */
.Icon_phone-square:before {
  content: '\ebf4';
} /* '' */
.Icon_phone-volume:before {
  content: '\ebf5';
} /* '' */
.Icon_pi:before {
  content: '\ebf6';
} /* '' */
.Icon_pie:before {
  content: '\ebf7';
} /* '' */
.Icon_pig:before {
  content: '\ebf8';
} /* '' */
.Icon_piggy-bank:before {
  content: '\ebf9';
} /* '' */
.Icon_pills:before {
  content: '\ebfa';
} /* '' */
.Icon_pizza:before {
  content: '\ebfb';
} /* '' */
.Icon_pizza-slice:before {
  content: '\ebfc';
} /* '' */
.Icon_place-of-worship:before {
  content: '\ebfd';
} /* '' */
.Icon_plane:before {
  content: '\ebfe';
} /* '' */
.Icon_plane-alt:before {
  content: '\ebff';
} /* '' */
.Icon_plane-arrival:before {
  content: '\ec00';
} /* '' */
.Icon_plane-departure:before {
  content: '\ec01';
} /* '' */
.Icon_play:before {
  content: '\ec02';
} /* '' */
.Icon_play-circle:before {
  content: '\ec03';
} /* '' */
.Icon_plug:before {
  content: '\ec04';
} /* '' */
.Icon_plus:before {
  content: '\ec05';
} /* '' */
.Icon_plus-circle:before {
  content: '\ec06';
} /* '' */
.Icon_plus-hexagon:before {
  content: '\ec07';
} /* '' */
.Icon_plus-octagon:before {
  content: '\ec08';
} /* '' */
.Icon_plus-square:before {
  content: '\ec09';
} /* '' */
.Icon_podcast:before {
  content: '\ec0a';
} /* '' */
.Icon_podium:before {
  content: '\ec0b';
} /* '' */
.Icon_podium-star:before {
  content: '\ec0c';
} /* '' */
.Icon_poll:before {
  content: '\ec0d';
} /* '' */
.Icon_poll-h:before {
  content: '\ec0e';
} /* '' */
.Icon_poll-people:before {
  content: '\ec0f';
} /* '' */
.Icon_poo:before {
  content: '\ec10';
} /* '' */
.Icon_poop:before {
  content: '\ec11';
} /* '' */
.Icon_poo-storm:before {
  content: '\ec12';
} /* '' */
.Icon_popcorn:before {
  content: '\ec13';
} /* '' */
.Icon_portrait:before {
  content: '\ec14';
} /* '' */
.Icon_pound-sign:before {
  content: '\ec15';
} /* '' */
.Icon_power-off:before {
  content: '\ec16';
} /* '' */
.Icon_pray:before {
  content: '\ec17';
} /* '' */
.Icon_praying-hands:before {
  content: '\ec18';
} /* '' */
.Icon_prescription:before {
  content: '\ec19';
} /* '' */
.Icon_prescription-bottle:before {
  content: '\ec1a';
} /* '' */
.Icon_prescription-bottle-alt:before {
  content: '\ec1b';
} /* '' */
.Icon_presentation:before {
  content: '\ec1c';
} /* '' */
.Icon_print:before {
  content: '\ec1d';
} /* '' */
.Icon_print-search:before {
  content: '\ec1e';
} /* '' */
.Icon_print-slash:before {
  content: '\ec1f';
} /* '' */
.Icon_procedures:before {
  content: '\ec20';
} /* '' */
.Icon_project-diagram:before {
  content: '\ec21';
} /* '' */
.Icon_pumpkin:before {
  content: '\ec22';
} /* '' */
.Icon_puzzle-piece:before {
  content: '\ec23';
} /* '' */
.Icon_qrcode:before {
  content: '\ec24';
} /* '' */
.Icon_question:before {
  content: '\ec25';
} /* '' */
.Icon_question-circle:before {
  content: '\ec26';
} /* '' */
.Icon_question-square:before {
  content: '\ec27';
} /* '' */
.Icon_quidditch:before {
  content: '\ec28';
} /* '' */
.Icon_quote-left:before {
  content: '\ec29';
} /* '' */
.Icon_quote-right:before {
  content: '\ec2a';
} /* '' */
.Icon_quran:before {
  content: '\ec2b';
} /* '' */
.Icon_rabbit:before {
  content: '\ec2c';
} /* '' */
.Icon_rabbit-fast:before {
  content: '\ec2d';
} /* '' */
.Icon_racquet:before {
  content: '\ec2e';
} /* '' */
.Icon_radiation:before {
  content: '\ec2f';
} /* '' */
.Icon_radiation-alt:before {
  content: '\ec30';
} /* '' */
.Icon_rainbow:before {
  content: '\ec31';
} /* '' */
.Icon_raindrops:before {
  content: '\ec32';
} /* '' */
.Icon_ram:before {
  content: '\ec33';
} /* '' */
.Icon_ramp-loading:before {
  content: '\ec34';
} /* '' */
.Icon_random:before {
  content: '\ec35';
} /* '' */
.Icon_receipt:before {
  content: '\ec36';
} /* '' */
.Icon_rectangle-landscape:before {
  content: '\ec37';
} /* '' */
.Icon_rectangle-portrait:before {
  content: '\ec38';
} /* '' */
.Icon_rectangle-wide:before {
  content: '\ec39';
} /* '' */
.Icon_recycle:before {
  content: '\ec3a';
} /* '' */
.Icon_redo:before {
  content: '\ec3b';
} /* '' */
.Icon_redo-alt:before {
  content: '\ec3c';
} /* '' */
.Icon_registered:before {
  content: '\ec3d';
} /* '' */
.Icon_repeat:before {
  content: '\ec3e';
} /* '' */
.Icon_repeat-1:before {
  content: '\ec3f';
} /* '' */
.Icon_repeat-1-alt:before {
  content: '\ec40';
} /* '' */
.Icon_repeat-alt:before {
  content: '\ec41';
} /* '' */
.Icon_reply:before {
  content: '\ec42';
} /* '' */
.Icon_reply-all:before {
  content: '\ec43';
} /* '' */
.Icon_republican:before {
  content: '\ec44';
} /* '' */
.Icon_restroom:before {
  content: '\ec45';
} /* '' */
.Icon_retweet:before {
  content: '\ec46';
} /* '' */
.Icon_retweet-alt:before {
  content: '\ec47';
} /* '' */
.Icon_ribbon:before {
  content: '\ec48';
} /* '' */
.Icon_ring:before {
  content: '\ec49';
} /* '' */
.Icon_rings-wedding:before {
  content: '\ec4a';
} /* '' */
.Icon_road:before {
  content: '\ec4b';
} /* '' */
.Icon_robot:before {
  content: '\ec4c';
} /* '' */
.Icon_fa-rocket:before {
  content: '\ec4d';
} /* '' */
.Icon_route:before {
  content: '\ec4e';
} /* '' */
.Icon_route-highway:before {
  content: '\ec4f';
} /* '' */
.Icon_route-interstate:before {
  content: '\ec50';
} /* '' */
.Icon_rss:before {
  content: '\ec51';
} /* '' */
.Icon_rss-square:before {
  content: '\ec52';
} /* '' */
.Icon_ruble-sign:before {
  content: '\ec53';
} /* '' */
.Icon_ruler:before {
  content: '\ec54';
} /* '' */
.Icon_ruler-combined:before {
  content: '\ec55';
} /* '' */
.Icon_ruler-horizontal:before {
  content: '\ec56';
} /* '' */
.Icon_ruler-triangle:before {
  content: '\ec57';
} /* '' */
.Icon_ruler-vertical:before {
  content: '\ec58';
} /* '' */
.Icon_running:before {
  content: '\ec59';
} /* '' */
.Icon_rupee-sign:before {
  content: '\ec5a';
} /* '' */
.Icon_rv:before {
  content: '\ec5b';
} /* '' */
.Icon_sack:before {
  content: '\ec5c';
} /* '' */
.Icon_sack-dollar:before {
  content: '\ec5d';
} /* '' */
.Icon_sad-cry:before {
  content: '\ec5e';
} /* '' */
.Icon_sad-tear:before {
  content: '\ec5f';
} /* '' */
.Icon_salad:before {
  content: '\ec60';
} /* '' */
.Icon_sandwich:before {
  content: '\ec61';
} /* '' */
.Icon_satellite:before {
  content: '\ec62';
} /* '' */
.Icon_satellite-dish:before {
  content: '\ec63';
} /* '' */
.Icon_sausage:before {
  content: '\ec64';
} /* '' */
.Icon_save:before {
  content: '\ec65';
} /* '' */
.Icon_scalpel:before {
  content: '\ec66';
} /* '' */
.Icon_scalpel-path:before {
  content: '\ec67';
} /* '' */
.Icon_scanner:before {
  content: '\ec68';
} /* '' */
.Icon_scanner-keyboard:before {
  content: '\ec69';
} /* '' */
.Icon_scanner-touchscreen:before {
  content: '\ec6a';
} /* '' */
.Icon_scarecrow:before {
  content: '\ec6b';
} /* '' */
.Icon_scarf:before {
  content: '\ec6c';
} /* '' */
.Icon_school:before {
  content: '\ec6d';
} /* '' */
.Icon_screwdriver:before {
  content: '\ec6e';
} /* '' */
.Icon_scroll:before {
  content: '\ec6f';
} /* '' */
.Icon_scroll-old:before {
  content: '\ec70';
} /* '' */
.Icon_scrubber:before {
  content: '\ec71';
} /* '' */
.Icon_scythe:before {
  content: '\ec72';
} /* '' */
.Icon_sd-card:before {
  content: '\ec73';
} /* '' */
.Icon_fa-search:before {
  content: '\ec74';
} /* '' */
.Icon_search-dollar:before {
  content: '\ec75';
} /* '' */
.Icon_search-location:before {
  content: '\ec76';
} /* '' */
.Icon_search-minus:before {
  content: '\ec77';
} /* '' */
.Icon_search-plus:before {
  content: '\ec78';
} /* '' */
.Icon_seedling:before {
  content: '\ec79';
} /* '' */
.Icon_server:before {
  content: '\ec7a';
} /* '' */
.Icon_shapes:before {
  content: '\ec7b';
} /* '' */
.Icon_share:before {
  content: '\ec7c';
} /* '' */
.Icon_share-all:before {
  content: '\ec7d';
} /* '' */
.Icon_share-alt:before {
  content: '\ec7e';
} /* '' */
.Icon_share-alt-square:before {
  content: '\ec7f';
} /* '' */
.Icon_share-square:before {
  content: '\ec80';
} /* '' */
.Icon_sheep:before {
  content: '\ec81';
} /* '' */
.Icon_shekel-sign:before {
  content: '\ec82';
} /* '' */
.Icon_shield:before {
  content: '\ec83';
} /* '' */
.Icon_shield-alt:before {
  content: '\ec84';
} /* '' */
.Icon_shield-check:before {
  content: '\ec85';
} /* '' */
.Icon_shield-cross:before {
  content: '\ec86';
} /* '' */
.Icon_ship:before {
  content: '\ec87';
} /* '' */
.Icon_shipping-fast:before {
  content: '\ec88';
} /* '' */
.Icon_shipping-timed:before {
  content: '\ec89';
} /* '' */
.Icon_shish-kebab:before {
  content: '\ec8a';
} /* '' */
.Icon_shoe-prints:before {
  content: '\ec8b';
} /* '' */
.Icon_shopping-bag:before {
  content: '\ec8c';
} /* '' */
.Icon_shopping-basket:before {
  content: '\ec8d';
} /* '' */
.Icon_shopping-cart:before {
  content: '\ec8e';
} /* '' */
.Icon_shovel:before {
  content: '\ec8f';
} /* '' */
.Icon_shovel-snow:before {
  content: '\ec90';
} /* '' */
.Icon_shower:before {
  content: '\ec91';
} /* '' */
.Icon_shredder:before {
  content: '\ec92';
} /* '' */
.Icon_shuttlecock:before {
  content: '\ec93';
} /* '' */
.Icon_shuttle-van:before {
  content: '\ec94';
} /* '' */
.Icon_sickle:before {
  content: '\ec95';
} /* '' */
.Icon_sigma:before {
  content: '\ec96';
} /* '' */
.Icon_sign:before {
  content: '\ec97';
} /* '' */
.Icon_signal:before {
  content: '\ec98';
} /* '' */
.Icon_signal-1:before {
  content: '\ec99';
} /* '' */
.Icon_signal-2:before {
  content: '\ec9a';
} /* '' */
.Icon_signal-3:before {
  content: '\ec9b';
} /* '' */
.Icon_signal-4:before {
  content: '\ec9c';
} /* '' */
.Icon_signal-alt:before {
  content: '\ec9d';
} /* '' */
.Icon_signal-alt-1:before {
  content: '\ec9e';
} /* '' */
.Icon_signal-alt-2:before {
  content: '\ec9f';
} /* '' */
.Icon_signal-alt-3:before {
  content: '\eca0';
} /* '' */
.Icon_signal-alt-slash:before {
  content: '\eca1';
} /* '' */
.Icon_signal-slash:before {
  content: '\eca2';
} /* '' */
.Icon_signature:before {
  content: '\eca3';
} /* '' */
.Icon_sign-in:before {
  content: '\eca4';
} /* '' */
.Icon_sign-in-alt:before {
  content: '\eca5';
} /* '' */
.Icon_sign-language:before {
  content: '\eca6';
} /* '' */
.Icon_sign-out:before {
  content: '\eca7';
} /* '' */
.Icon_sign-out-alt:before {
  content: '\eca8';
} /* '' */
.Icon_sim-card:before {
  content: '\eca9';
} /* '' */
.Icon_sitemap:before {
  content: '\ecaa';
} /* '' */
.Icon_skating:before {
  content: '\ecab';
} /* '' */
.Icon_skeleton:before {
  content: '\ecac';
} /* '' */
.Icon_skiing:before {
  content: '\ecad';
} /* '' */
.Icon_skiing-nordic:before {
  content: '\ecae';
} /* '' */
.Icon_ski-jump:before {
  content: '\ecaf';
} /* '' */
.Icon_ski-lift:before {
  content: '\ecb0';
} /* '' */
.Icon_skull:before {
  content: '\ecb1';
} /* '' */
.Icon_skull-crossbones:before {
  content: '\ecb2';
} /* '' */
.Icon_slash:before {
  content: '\ecb3';
} /* '' */
.Icon_sledding:before {
  content: '\ecb4';
} /* '' */
.Icon_sleigh:before {
  content: '\ecb5';
} /* '' */
.Icon_sliders-h:before {
  content: '\ecb6';
} /* '' */
.Icon_sliders-h-square:before {
  content: '\ecb7';
} /* '' */
.Icon_sliders-v:before {
  content: '\ecb8';
} /* '' */
.Icon_sliders-v-square:before {
  content: '\ecb9';
} /* '' */
.Icon_fa-smile:before {
  content: '\ecba';
} /* '' */
.Icon_smile-beam:before {
  content: '\ecbb';
} /* '' */
.Icon_smile-plus:before {
  content: '\ecbc';
} /* '' */
.Icon_smile-wink:before {
  content: '\ecbd';
} /* '' */
.Icon_smog:before {
  content: '\ecbe';
} /* '' */
.Icon_smoke:before {
  content: '\ecbf';
} /* '' */
.Icon_smoking:before {
  content: '\ecc0';
} /* '' */
.Icon_smoking-ban:before {
  content: '\ecc1';
} /* '' */
.Icon_sms:before {
  content: '\ecc2';
} /* '' */
.Icon_snake:before {
  content: '\ecc3';
} /* '' */
.Icon_snow-blowing:before {
  content: '\ecc4';
} /* '' */
.Icon_snowboarding:before {
  content: '\ecc5';
} /* '' */
.Icon_snowflake:before {
  content: '\ecc6';
} /* '' */
.Icon_snowflakes:before {
  content: '\ecc7';
} /* '' */
.Icon_snowman:before {
  content: '\ecc8';
} /* '' */
.Icon_snowmobile:before {
  content: '\ecc9';
} /* '' */
.Icon_snowplow:before {
  content: '\ecca';
} /* '' */
.Icon_socks:before {
  content: '\eccb';
} /* '' */
.Icon_solar-panel:before {
  content: '\eccc';
} /* '' */
.Icon_sort:before {
  content: '\eccd';
} /* '' */
.Icon_sort-alpha-down:before {
  content: '\ecce';
} /* '' */
.Icon_sort-alpha-up:before {
  content: '\eccf';
} /* '' */
.Icon_sort-amount-down:before {
  content: '\ecd0';
} /* '' */
.Icon_sort-amount-up:before {
  content: '\ecd1';
} /* '' */
.Icon_sort-down:before {
  content: '\ecd2';
} /* '' */
.Icon_sort-numeric-down:before {
  content: '\ecd3';
} /* '' */
.Icon_sort-numeric-up:before {
  content: '\ecd4';
} /* '' */
.Icon_sort-up:before {
  content: '\ecd5';
} /* '' */
.Icon_soup:before {
  content: '\ecd6';
} /* '' */
.Icon_spa:before {
  content: '\ecd7';
} /* '' */
.Icon_space-shuttle:before {
  content: '\ecd8';
} /* '' */
.Icon_spade:before {
  content: '\ecd9';
} /* '' */
.Icon_spider:before {
  content: '\ecda';
} /* '' */
.Icon_spider-black-widow:before {
  content: '\ecdb';
} /* '' */
.Icon_spider-web:before {
  content: '\ecdc';
} /* '' */
.Icon_spinner:before {
  content: '\ecdd';
} /* '' */
.Icon_spinner-third:before {
  content: '\ecde';
} /* '' */
.Icon_splotch:before {
  content: '\ecdf';
} /* '' */
.Icon_spray-can:before {
  content: '\ece0';
} /* '' */
.Icon_square:before {
  content: '\ece1';
} /* '' */
.Icon_square-full:before {
  content: '\ece2';
} /* '' */
.Icon_square-root:before {
  content: '\ece3';
} /* '' */
.Icon_square-root-alt:before {
  content: '\ece4';
} /* '' */
.Icon_squirrel:before {
  content: '\ece5';
} /* '' */
.Icon_staff:before {
  content: '\ece6';
} /* '' */
.Icon_stamp:before {
  content: '\ece7';
} /* '' */
.Icon_star:before {
  content: '\ece8';
} /* '' */
.Icon_star-and-crescent:before {
  content: '\ece9';
} /* '' */
.Icon_star-christmas:before {
  content: '\ecea';
} /* '' */
.Icon_star-exclamation:before {
  content: '\eceb';
} /* '' */
.Icon_star-half:before {
  content: '\ecec';
} /* '' */
.Icon_star-half-alt:before {
  content: '\eced';
} /* '' */
.Icon_star-of-david:before {
  content: '\ecee';
} /* '' */
.Icon_star-of-life:before {
  content: '\ecef';
} /* '' */
.Icon_stars:before {
  content: '\ecf0';
} /* '' */
.Icon_steak:before {
  content: '\ecf1';
} /* '' */
.Icon_steering-wheel:before {
  content: '\ecf2';
} /* '' */
.Icon_step-backward:before {
  content: '\ecf3';
} /* '' */
.Icon_step-forward:before {
  content: '\ecf4';
} /* '' */
.Icon_stethoscope:before {
  content: '\ecf5';
} /* '' */
.Icon_sticky-note:before {
  content: '\ecf6';
} /* '' */
.Icon_stocking:before {
  content: '\ecf7';
} /* '' */
.Icon_stomach:before {
  content: '\ecf8';
} /* '' */
.Icon_stop:before {
  content: '\ecf9';
} /* '' */
.Icon_stop-circle:before {
  content: '\ecfa';
} /* '' */
.Icon_stopwatch:before {
  content: '\ecfb';
} /* '' */
.Icon_store:before {
  content: '\ecfc';
} /* '' */
.Icon_store-alt:before {
  content: '\ecfd';
} /* '' */
.Icon_stream:before {
  content: '\ecfe';
} /* '' */
.Icon_street-view:before {
  content: '\ecff';
} /* '' */
.Icon_stretcher:before {
  content: '\ed00';
} /* '' */
.Icon_strikethrough:before {
  content: '\ed01';
} /* '' */
.Icon_stroopwafel:before {
  content: '\ed02';
} /* '' */
.Icon_subscript:before {
  content: '\ed03';
} /* '' */
.Icon_subway:before {
  content: '\ed04';
} /* '' */
.Icon_suitcase:before {
  content: '\ed05';
} /* '' */
.Icon_suitcase-rolling:before {
  content: '\ed06';
} /* '' */
.Icon_sun:before {
  content: '\ed07';
} /* '' */
.Icon_sun-cloud:before {
  content: '\ed08';
} /* '' */
.Icon_sun-dust:before {
  content: '\ed09';
} /* '' */
.Icon_sun-haze:before {
  content: '\ed0a';
} /* '' */
.Icon_sunrise:before {
  content: '\ed0b';
} /* '' */
.Icon_sunset:before {
  content: '\ed0c';
} /* '' */
.Icon_superscript:before {
  content: '\ed0d';
} /* '' */
.Icon_surprise:before {
  content: '\ed0e';
} /* '' */
.Icon_swatchbook:before {
  content: '\ed0f';
} /* '' */
.Icon_swimmer:before {
  content: '\ed10';
} /* '' */
.Icon_swimming-pool:before {
  content: '\ed11';
} /* '' */
.Icon_sword:before {
  content: '\ed12';
} /* '' */
.Icon_swords:before {
  content: '\ed13';
} /* '' */
.Icon_synagogue:before {
  content: '\ed14';
} /* '' */
.Icon_sync:before {
  content: '\ed15';
} /* '' */
.Icon_sync-alt:before {
  content: '\ed16';
} /* '' */
.Icon_syringe:before {
  content: '\ed17';
} /* '' */
.Icon_table:before {
  content: '\ed18';
} /* '' */
.Icon_tablet:before {
  content: '\ed19';
} /* '' */
.Icon_tablet-alt:before {
  content: '\ed1a';
} /* '' */
.Icon_tablet-android:before {
  content: '\ed1b';
} /* '' */
.Icon_tablet-android-alt:before {
  content: '\ed1c';
} /* '' */
.Icon_table-tennis:before {
  content: '\ed1d';
} /* '' */
.Icon_tablet-rugged:before {
  content: '\ed1e';
} /* '' */
.Icon_tablets:before {
  content: '\ed1f';
} /* '' */
.Icon_tachometer:before {
  content: '\ed20';
} /* '' */
.Icon_tachometer-alt:before {
  content: '\ed21';
} /* '' */
.Icon_tachometer-alt-average:before {
  content: '\ed22';
} /* '' */
.Icon_tachometer-alt-fast:before {
  content: '\ed23';
} /* '' */
.Icon_tachometer-alt-fastest:before {
  content: '\ed24';
} /* '' */
.Icon_tachometer-alt-slow:before {
  content: '\ed25';
} /* '' */
.Icon_tachometer-alt-slowest:before {
  content: '\ed26';
} /* '' */
.Icon_tachometer-average:before {
  content: '\ed27';
} /* '' */
.Icon_tachometer-fast:before {
  content: '\ed28';
} /* '' */
.Icon_tachometer-fastest:before {
  content: '\ed29';
} /* '' */
.Icon_tachometer-slow:before {
  content: '\ed2a';
} /* '' */
.Icon_tachometer-slowest:before {
  content: '\ed2b';
} /* '' */
.Icon_taco:before {
  content: '\ed2c';
} /* '' */
.Icon_fa-tag:before {
  content: '\ed2d';
} /* '' */
.Icon_tags:before {
  content: '\ed2e';
} /* '' */
.Icon_tally:before {
  content: '\ed2f';
} /* '' */
.Icon_tanakh:before {
  content: '\ed30';
} /* '' */
.Icon_tape:before {
  content: '\ed31';
} /* '' */
.Icon_tasks:before {
  content: '\ed32';
} /* '' */
.Icon_tasks-alt:before {
  content: '\ed33';
} /* '' */
.Icon_taxi:before {
  content: '\ed34';
} /* '' */
.Icon_teeth:before {
  content: '\ed35';
} /* '' */
.Icon_teeth-open:before {
  content: '\ed36';
} /* '' */
.Icon_temperature-frigid:before {
  content: '\ed37';
} /* '' */
.Icon_temperature-high:before {
  content: '\ed38';
} /* '' */
.Icon_temperature-hot:before {
  content: '\ed39';
} /* '' */
.Icon_temperature-low:before {
  content: '\ed3a';
} /* '' */
.Icon_tenge:before {
  content: '\ed3b';
} /* '' */
.Icon_tennis-ball:before {
  content: '\ed3c';
} /* '' */
.Icon_terminal:before {
  content: '\ed3d';
} /* '' */
.Icon_text-height:before {
  content: '\ed3e';
} /* '' */
.Icon_text-width:before {
  content: '\ed3f';
} /* '' */
.Icon_th:before {
  content: '\ed40';
} /* '' */
.Icon_theater-masks:before {
  content: '\ed41';
} /* '' */
.Icon_thermometer:before {
  content: '\ed42';
} /* '' */
.Icon_thermometer-empty:before {
  content: '\ed43';
} /* '' */
.Icon_thermometer-full:before {
  content: '\ed44';
} /* '' */
.Icon_thermometer-half:before {
  content: '\ed45';
} /* '' */
.Icon_thermometer-quarter:before {
  content: '\ed46';
} /* '' */
.Icon_thermometer-three-quarters:before {
  content: '\ed47';
} /* '' */
.Icon_theta:before {
  content: '\ed48';
} /* '' */
.Icon_th-large:before {
  content: '\ed49';
} /* '' */
.Icon_th-list:before {
  content: '\ed4a';
} /* '' */
.Icon_thumbs-down:before {
  content: '\ed4b';
} /* '' */
.Icon_thumbs-up:before {
  content: '\ed4c';
} /* '' */
.Icon_thumbtack:before {
  content: '\ed4d';
} /* '' */
.Icon_thunderstorm:before {
  content: '\ed4e';
} /* '' */
.Icon_thunderstorm-moon:before {
  content: '\ed4f';
} /* '' */
.Icon_thunderstorm-sun:before {
  content: '\ed50';
} /* '' */
.Icon_ticket:before {
  content: '\ed51';
} /* '' */
.Icon_ticket-alt:before {
  content: '\ed52';
} /* '' */
.Icon_tilde:before {
  content: '\ed53';
} /* '' */
.Icon_times:before {
  content: '\ed54';
} /* '' */
.Icon_times-circle:before {
  content: '\ed55';
} /* '' */
.Icon_times-hexagon:before {
  content: '\ed56';
} /* '' */
.Icon_times-octagon:before {
  content: '\ed57';
} /* '' */
.Icon_times-square:before {
  content: '\ed58';
} /* '' */
.Icon_tint:before {
  content: '\ed59';
} /* '' */
.Icon_tint-slash:before {
  content: '\ed5a';
} /* '' */
.Icon_tire:before {
  content: '\ed5b';
} /* '' */
.Icon_tired:before {
  content: '\ed5c';
} /* '' */
.Icon_tire-flat:before {
  content: '\ed5d';
} /* '' */
.Icon_tire-pressure-warning:before {
  content: '\ed5e';
} /* '' */
.Icon_tire-rugged:before {
  content: '\ed5f';
} /* '' */
.Icon_toggle-off:before {
  content: '\ed60';
} /* '' */
.Icon_toggle-on:before {
  content: '\ed61';
} /* '' */
.Icon_toilet:before {
  content: '\ed62';
} /* '' */
.Icon_toilet-paper:before {
  content: '\ed63';
} /* '' */
.Icon_toilet-paper-alt:before {
  content: '\ed64';
} /* '' */
.Icon_tombstone:before {
  content: '\ed65';
} /* '' */
.Icon_tombstone-alt:before {
  content: '\ed66';
} /* '' */
.Icon_toolbox:before {
  content: '\ed67';
} /* '' */
.Icon_tools:before {
  content: '\ed68';
} /* '' */
.Icon_tooth:before {
  content: '\ed69';
} /* '' */
.Icon_toothbrush:before {
  content: '\ed6a';
} /* '' */
.Icon_torah:before {
  content: '\ed6b';
} /* '' */
.Icon_torii-gate:before {
  content: '\ed6c';
} /* '' */
.Icon_tornado:before {
  content: '\ed6d';
} /* '' */
.Icon_tractor:before {
  content: '\ed6e';
} /* '' */
.Icon_trademark:before {
  content: '\ed6f';
} /* '' */
.Icon_traffic-cone:before {
  content: '\ed70';
} /* '' */
.Icon_traffic-light:before {
  content: '\ed71';
} /* '' */
.Icon_traffic-light-go:before {
  content: '\ed72';
} /* '' */
.Icon_traffic-light-slow:before {
  content: '\ed73';
} /* '' */
.Icon_traffic-light-stop:before {
  content: '\ed74';
} /* '' */
.Icon_train:before {
  content: '\ed75';
} /* '' */
.Icon_tram:before {
  content: '\ed76';
} /* '' */
.Icon_transgender:before {
  content: '\ed77';
} /* '' */
.Icon_transgender-alt:before {
  content: '\ed78';
} /* '' */
.Icon_trash:before {
  content: '\ed79';
} /* '' */
.Icon_trash-alt:before {
  content: '\ed7a';
} /* '' */
.Icon_trash-restore:before {
  content: '\ed7b';
} /* '' */
.Icon_trash-restore-alt:before {
  content: '\ed7c';
} /* '' */
.Icon_treasure-chest:before {
  content: '\ed7d';
} /* '' */
.Icon_tree:before {
  content: '\ed7e';
} /* '' */
.Icon_tree-alt:before {
  content: '\ed7f';
} /* '' */
.Icon_tree-christmas:before {
  content: '\ed80';
} /* '' */
.Icon_tree-decorated:before {
  content: '\ed81';
} /* '' */
.Icon_tree-large:before {
  content: '\ed82';
} /* '' */
.Icon_tree-palm:before {
  content: '\ed83';
} /* '' */
.Icon_trees:before {
  content: '\ed84';
} /* '' */
.Icon_triangle:before {
  content: '\ed85';
} /* '' */
.Icon_trophy:before {
  content: '\ed86';
} /* '' */
.Icon_trophy-alt:before {
  content: '\ed87';
} /* '' */
.Icon_truck:before {
  content: '\ed88';
} /* '' */
.Icon_truck-container:before {
  content: '\ed89';
} /* '' */
.Icon_truck-couch:before {
  content: '\ed8a';
} /* '' */
.Icon_truck-loading:before {
  content: '\ed8b';
} /* '' */
.Icon_truck-monster:before {
  content: '\ed8c';
} /* '' */
.Icon_truck-moving:before {
  content: '\ed8d';
} /* '' */
.Icon_truck-pickup:before {
  content: '\ed8e';
} /* '' */
.Icon_truck-plow:before {
  content: '\ed8f';
} /* '' */
.Icon_truck-ramp:before {
  content: '\ed90';
} /* '' */
.Icon_tshirt:before {
  content: '\ed91';
} /* '' */
.Icon_tty:before {
  content: '\ed92';
} /* '' */
.Icon_turkey:before {
  content: '\ed93';
} /* '' */
.Icon_turtle:before {
  content: '\ed94';
} /* '' */
.Icon_tv:before {
  content: '\ed95';
} /* '' */
.Icon_tv-retro:before {
  content: '\ed96';
} /* '' */
.Icon_umbrella:before {
  content: '\ed97';
} /* '' */
.Icon_umbrella-beach:before {
  content: '\ed98';
} /* '' */
.Icon_underline:before {
  content: '\ed99';
} /* '' */
.Icon_undo:before {
  content: '\ed9a';
} /* '' */
.Icon_undo-alt:before {
  content: '\ed9b';
} /* '' */
.Icon_unicorn:before {
  content: '\ed9c';
} /* '' */
.Icon_union:before {
  content: '\ed9d';
} /* '' */
.Icon_universal-access:before {
  content: '\ed9e';
} /* '' */
.Icon_university:before {
  content: '\ed9f';
} /* '' */
.Icon_unlink:before {
  content: '\eda0';
} /* '' */
.Icon_unlock:before {
  content: '\eda1';
} /* '' */
.Icon_unlock-alt:before {
  content: '\eda2';
} /* '' */
.Icon_upload:before {
  content: '\eda3';
} /* '' */
.Icon_usd-circle:before {
  content: '\eda4';
} /* '' */
.Icon_usd-square:before {
  content: '\eda5';
} /* '' */
.Icon_fa-user:before {
  content: '\eda6';
} /* '' */
.Icon_user-alt:before {
  content: '\eda7';
} /* '' */
.Icon_user-alt-slash:before {
  content: '\eda8';
} /* '' */
.Icon_user-astronaut:before {
  content: '\eda9';
} /* '' */
.Icon_user-chart:before {
  content: '\edaa';
} /* '' */
.Icon_user-check:before {
  content: '\edab';
} /* '' */
.Icon_user-circle:before {
  content: '\edac';
} /* '' */
.Icon_user-clock:before {
  content: '\edad';
} /* '' */
.Icon_user-cog:before {
  content: '\edae';
} /* '' */
.Icon_user-crown:before {
  content: '\edaf';
} /* '' */
.Icon_user-edit:before {
  content: '\edb0';
} /* '' */
.Icon_user-friends:before {
  content: '\edb1';
} /* '' */
.Icon_user-graduate:before {
  content: '\edb2';
} /* '' */
.Icon_user-hard-hat:before {
  content: '\edb3';
} /* '' */
.Icon_user-headset:before {
  content: '\edb4';
} /* '' */
.Icon_user-injured:before {
  content: '\edb5';
} /* '' */
.Icon_user-lock:before {
  content: '\edb6';
} /* '' */
.Icon_user-md:before {
  content: '\edb7';
} /* '' */
.Icon_user-md-chat:before {
  content: '\edb8';
} /* '' */
.Icon_user-minus:before {
  content: '\edb9';
} /* '' */
.Icon_user-ninja:before {
  content: '\edba';
} /* '' */
.Icon_user-nurse:before {
  content: '\edbb';
} /* '' */
.Icon_user-plus:before {
  content: '\edbc';
} /* '' */
.Icon_users:before {
  content: '\edbd';
} /* '' */
.Icon_users-class:before {
  content: '\edbe';
} /* '' */
.Icon_users-cog:before {
  content: '\edbf';
} /* '' */
.Icon_users-crown:before {
  content: '\edc0';
} /* '' */
.Icon_user-secret:before {
  content: '\edc1';
} /* '' */
.Icon_user-shield:before {
  content: '\edc2';
} /* '' */
.Icon_user-slash:before {
  content: '\edc3';
} /* '' */
.Icon_users-medical:before {
  content: '\edc4';
} /* '' */
.Icon_user-tag:before {
  content: '\edc5';
} /* '' */
.Icon_user-tie:before {
  content: '\edc6';
} /* '' */
.Icon_user-times:before {
  content: '\edc7';
} /* '' */
.Icon_utensil-fork:before {
  content: '\edc8';
} /* '' */
.Icon_utensil-knife:before {
  content: '\edc9';
} /* '' */
.Icon_utensils:before {
  content: '\edca';
} /* '' */
.Icon_utensils-alt:before {
  content: '\edcb';
} /* '' */
.Icon_utensil-spoon:before {
  content: '\edcc';
} /* '' */
.Icon_value-absolute:before {
  content: '\edcd';
} /* '' */
.Icon_vector-square:before {
  content: '\edce';
} /* '' */
.Icon_venus:before {
  content: '\edcf';
} /* '' */
.Icon_venus-double:before {
  content: '\edd0';
} /* '' */
.Icon_venus-mars:before {
  content: '\edd1';
} /* '' */
.Icon_vial:before {
  content: '\edd2';
} /* '' */
.Icon_vials:before {
  content: '\edd3';
} /* '' */
.Icon_video:before {
  content: '\edd4';
} /* '' */
.Icon_video-plus:before {
  content: '\edd5';
} /* '' */
.Icon_video-slash:before {
  content: '\edd6';
} /* '' */
.Icon_vihara:before {
  content: '\edd7';
} /* '' */
.Icon_volcano:before {
  content: '\edd8';
} /* '' */
.Icon_volume:before {
  content: '\edd9';
} /* '' */
.Icon_volleyball-ball:before {
  content: '\edda';
} /* '' */
.Icon_volume-down:before {
  content: '\eddb';
} /* '' */
.Icon_volume-mute:before {
  content: '\eddc';
} /* '' */
.Icon_volume-off:before {
  content: '\eddd';
} /* '' */
.Icon_volume-slash:before {
  content: '\edde';
} /* '' */
.Icon_volume-up:before {
  content: '\eddf';
} /* '' */
.Icon_vote-nay:before {
  content: '\ede0';
} /* '' */
.Icon_vote-yea:before {
  content: '\ede1';
} /* '' */
.Icon_vr-cardboard:before {
  content: '\ede2';
} /* '' */
.Icon_walker:before {
  content: '\ede3';
} /* '' */
.Icon_walking:before {
  content: '\ede4';
} /* '' */
.Icon_fa-wallet:before {
  content: '\ede5';
} /* '' */
.Icon_wand:before {
  content: '\ede6';
} /* '' */
.Icon_wand-magic:before {
  content: '\ede7';
} /* '' */
.Icon_warehouse:before {
  content: '\ede8';
} /* '' */
.Icon_warehouse-alt:before {
  content: '\ede9';
} /* '' */
.Icon_watch:before {
  content: '\edea';
} /* '' */
.Icon_watch-fitness:before {
  content: '\edeb';
} /* '' */
.Icon_water:before {
  content: '\edec';
} /* '' */
.Icon_water-lower:before {
  content: '\eded';
} /* '' */
.Icon_water-rise:before {
  content: '\edee';
} /* '' */
.Icon_wave-square:before {
  content: '\edef';
} /* '' */
.Icon_webcam:before {
  content: '\edf0';
} /* '' */
.Icon_webcam-slash:before {
  content: '\edf1';
} /* '' */
.Icon_weight:before {
  content: '\edf2';
} /* '' */
.Icon_weight-hanging:before {
  content: '\edf3';
} /* '' */
.Icon_whale:before {
  content: '\edf4';
} /* '' */
.Icon_wheat:before {
  content: '\edf5';
} /* '' */
.Icon_wheelchair:before {
  content: '\edf6';
} /* '' */
.Icon_whistle:before {
  content: '\edf7';
} /* '' */
.Icon_wifi:before {
  content: '\edf8';
} /* '' */
.Icon_wifi-1:before {
  content: '\edf9';
} /* '' */
.Icon_wifi-2:before {
  content: '\edfa';
} /* '' */
.Icon_wifi-slash:before {
  content: '\edfb';
} /* '' */
.Icon_wind:before {
  content: '\edfc';
} /* '' */
.Icon_window:before {
  content: '\edfd';
} /* '' */
.Icon_window-alt:before {
  content: '\edfe';
} /* '' */
.Icon_window-close:before {
  content: '\edff';
} /* '' */
.Icon_window-maximize:before {
  content: '\ee00';
} /* '' */
.Icon_window-minimize:before {
  content: '\ee01';
} /* '' */
.Icon_window-restore:before {
  content: '\ee02';
} /* '' */
.Icon_windsock:before {
  content: '\ee03';
} /* '' */
.Icon_wind-warning:before {
  content: '\ee04';
} /* '' */
.Icon_wine-bottle:before {
  content: '\ee05';
} /* '' */
.Icon_wine-glass:before {
  content: '\ee06';
} /* '' */
.Icon_wine-glass-alt:before {
  content: '\ee07';
} /* '' */
.Icon_won-sign:before {
  content: '\ee08';
} /* '' */
.Icon_wreath:before {
  content: '\ee09';
} /* '' */
.Icon_wrench:before {
  content: '\ee0a';
} /* '' */
.Icon_x-ray:before {
  content: '\ee0b';
} /* '' */
.Icon_yen-sign:before {
  content: '\ee0c';
} /* '' */
.Icon_yin-yang:before {
  content: '\ee0d';
} /* '' */
.Icon_abacus:before {
  content: '\ee0e';
} /* '' */
.Icon_acorn:before {
  content: '\ee0f';
} /* '' */
.Icon_ad:before {
  content: '\ee10';
} /* '' */
.Icon_address-book:before {
  content: '\ee11';
} /* '' */
.Icon_address-card:before {
  content: '\ee12';
} /* '' */
.Icon_adjust:before {
  content: '\ee13';
} /* '' */
.Icon_air-freshener:before {
  content: '\ee14';
} /* '' */
.Icon_alarm-clock:before {
  content: '\ee15';
} /* '' */
.Icon_award:before {
  content: '\ee16';
} /* '' */
.Icon_bell:before {
  content: '\ee17';
} /* '' */
.Icon_books:before {
  content: '\ee18';
} /* '' */
.Icon_book-stack:before {
  content: '\ee19';
} /* '' */
.Icon_cake:before {
  content: '\ee1b';
} /* '' */
.Icon_calendar:before {
  content: '\ee1c';
} /* '' */
.Icon_clip:before {
  content: '\ee1e';
} /* '' */
.Icon_check-circle:before {
  content: '\ee1f';
} /* '' */
.Icon_clock:before {
  content: '\ee20';
} /* '' */
.Icon_coin-laying:before {
  content: '\ee21';
} /* '' */
.Icon_coins-stack:before {
  content: '\ee22';
} /* '' */
.Icon_coin-standing:before {
  content: '\ee23';
} /* '' */
.Icon_cross-mark:before {
  content: '\ee24';
  /* margin: 18px;
  font-size: 20px;
  font-weight: 600;
  color: black; */
} /* '' */
.Icon_cup:before {
  content: '\ee25';
} /* '' */
.Icon_download:before {
  content: '\ee26';
} /* '' */
.Icon_edit:before {
  content: '\ee27';
} /* '' */
.Icon_envelope:before {
  content: '\ee28';
} /* '' */
.Icon_eye:before {
  content: '\ee29';
} /* '' */
.Icon_eye-crossed:before {
  content: '\ee2a';
} /* '' */
.Icon_finish:before {
  content: '\ee2b';
} /* '' */
.Icon_flag:before {
  content: '\ee2c';
} /* '' */
.Icon_give:before {
  content: '\ee2d';
} /* '' */
.Icon_hash-tag:before {
  content: '\ee2e';
} /* '' */
.Icon_heart:before {
  content: '\ee2f';
} /* '' */
.Icon_heart-fill:before {
  content: '\ee30';
} /* '' */
.Icon_home:before {
  content: '\ee31';
} /* '' */
.Icon_idea:before {
  content: '\ee32';
} /* '' */
.Icon_ignots:before {
  content: '\ee33';
} /* '' */
.Icon_key:before {
  content: '\ee34';
} /* '' */
.Icon_likers:before {
  content: '\ee35';
} /* '' */
.Icon_location:before {
  content: '\ee36';
} /* '' */
.Icon_lock:before {
  content: '\ee37';
} /* '' */
.Icon_medal:before {
  content: '\ee38';
} /* '' */
.Icon_message:before {
  content: '\ee39';
} /* '' */
.Icon_money:before {
  content: '\ee3a';
} /* '' */
.Icon_money-bag:before {
  content: '\ee3b';
} /* '' */
.Icon_qusetion:before {
  content: '\ee3c';
} /* '' */
.Icon_rocket:before {
  content: '\ee3f';
} /* '' */
.Icon_search:before {
  content: '\ee40';
} /* '' */
.Icon_shop:before {
  content: '\ee41';
} /* '' */
.Icon_smile:before {
  content: '\ee42';
} /* '' */
.Icon_tag:before {
  content: '\ee43';
} /* '' */
.Icon_triangle-down:before {
  content: '\ee44';
} /* '' */
.Icon_triangle-up:before {
  content: '\ee45';
} /* '' */
.Icon_user:before {
  content: '\ee46';
} /* '' */
.Icon_user-add:before {
  content: '\ee47';
} /* '' */
.Icon_user-group:before {
  content: '\ee48';
} /* '' */
.Icon_wallet:before {
  content: '\ee49';
} /* '' */
.Icon_wiki:before {
  content: '\ee4a';
} /* '' */
.Icon_achievement:before {
  content: '\ee4b';
} /* '' */
.Icon_arrow-left:before {
  content: '\ee4c';
} /* '' */
.Icon_arrow-right:before {
  content: '\ee4d';
} /* '' */
.Icon_at-sign:before {
  content: '\ee4e';
} /* '' */
