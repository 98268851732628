.HeaderLayout {
    display: flex;
    align-items: center;

    &-ActionButton {
        width: 24px;
        height: 24px;
        padding: 0;
        font-size: 14px;
        margin-left: 8px;
        line-height: 16px;
    }

    &-Left {
    }

    &-Content {
        flex: 1;
        color: #979797;
        font-size: 14px;
        overflow: hidden;
        text-overflow: ellipsis;
        @media (max-width: 576px) {
            font-size: 12px;
        }
    }

    &-Top {
        .Markdown {
            display: inline;

            p {
                display: inline;
            }

            a.Button {
                display: inline;
            }
        }
    }

    &-Right {
        align-self: flex-start;
        padding-right: 24px;
        @media (max-width: 576px) {
            padding-right: 12px;
        }
    }

    &-Bottom {
        font-size: 12px;
        line-height: 22px;
    }

    &-Top {
        line-height: 25px;
        @media (max-width: 576px) {
            line-height: 18px;
        }
    }

    .UserName,
    .GrupLink {
        color: #0066cc;
        font-weight: bold;
    }

    .clear {
        display: none;
    }

    &_compact {
        .HeaderLayout-Right {
            padding-right: 0px;
        }
        .HeaderLayout-Top {
            line-height: 20px;
        }
    }
    &.HeaderLayout_compact {
        // padding-right: 8px;
        // padding-left: 8px;
        .HeaderLayout-Left {
            padding-right: 12px;
            padding-left: 0px;
            align-self: center;
            line-height: 0;
        }
        .HeaderLayout-Content {
            align-self: center;
        }
        .HeaderLayout-Bottom {
            line-height: 16px;
        }
    }
}
