.InfoBlock-Group_edit_false {
  
}

.InfoBlock-Group {
    &-DataDescription {
        font-size: 16px;
        line-height: 22px;
    }

    &-DataName {
        font-weight: 500;
    }

    &-Img {
        margin-bottom: 12px;
    }

    &-Label {
        font-weight: normal;
        text-transform: uppercase;
    }
    &-Actions {
        display: flex; 
    }
}

// Стили для ie
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .InfoBlock-Group {
        &-Actions .ButtonBox{
            overflow: visible;
            .Button{
                flex: none;
                -ms-flex: none;
                // width: 150px;
                // background-color: #eee;
            }
            .Dropdown {
                flex: none;
                -ms-flex: none;
                &-Options {
                    bottom: auto;
                }
            }
            
        }
        
        // &-Actions div {
        //     a {
        //         width: 135px;
        //     }
        //     div {
        //         width: 37px;
        //     }
        // }
        
    }
    
}