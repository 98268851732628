.Journal{
    &-ActionBlock{
        display: flex;
        padding: 10px 24px;
        border-bottom: 1px solid #d6dbe2;
    }
    &-InfoBlock{
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 80%;
        margin: 0 24px;
        .Markdown{
            p{
                margin: 0;
                u{
                    background-color: #f1543f8f;
                    text-decoration: line-through;
                }
                strong{
                    background-color:#68dd0080;
                    font-weight: 400;
                }
            }
        }
    }
      .stub{
    background-color: var(--background-color);
    width: 100%;
    height: 352px;
    border-radius: 8px;
    text-align: center;
    padding-top: 40px;
  }
}