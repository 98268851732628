.RatingBadgeWidget {
    &-ContentBox {
        &-InfoBox {
            &-ToTheNextRevardLeft {
                // display: "flex",
                display: none,
            }
        }
    }

    // &-Location:hover{ 
    //     color:#000;
    //     .Box.RatingBadgeWidget-BadgeAvatarBig.MuiBox-root {
    //         opacity: 1!important

    // }}

    // &-Label &-Edit {
    //     display: none;
    //   }

    //   &-Label:hover &-Edit {
    //     display: block;
    //   }
}