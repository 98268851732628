.TOCEScormUnit {
    padding-bottom: 24px;

    &-Frame {
        margin-top: 24px;
        border: 1px solid #ccc;
    }

    &-Upload {
        margin-bottom: 24px;
    }

    &-Preview {
        margin-top: 24px;
    }

    &-changeSizes {
        display: flex;
        align-items: center;
    }
}
