   .AnswerAdd {
       flex: 1 1;
       text-align: left;
       color: #858585;
       padding: 10px 0;

       .Icon::before {
           margin: 0;
           margin-right: 8px;
       }
   }