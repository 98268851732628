@import '../../utils/global.scss';
.Layout {
    font-family: $FontFamilyRegular;
    transition: all $TransitionDuration $TransitionTimingFunction;
    min-height: calc(100vh - 72px);
    display: flex;
    flex-direction: column;

    &_auth {
        padding-top: $HeaderHeight;
    }

    @media screen and (min-width: 720px) {
        padding-left: $LeftMenuFoldedWidth;

        &:not(.Layout_wv).Layout_openedMenu {
            padding-left: $LeftMenuWidth;
        }
    }

    &_noMenu {
        padding-left: 0px !important;
    }

    &-Inner {
        box-sizing: border-box;
        max-width: 1170px;
        margin: 0 auto;
        transition: all 0.3s ease-in-out;
        width: 100%;
    }

    &-Inner.FullWidth,
    &-Inner.FullWidthForce {
        max-width: unset;
    }

    &_wv {
        padding: 0;
    }

    // .TopButton {
    //   position: fixed;
    //   bottom: 25px;
    //   right: 25px;
    //   border-radius: 50%;
    //   width: 50px;
    //   height: 50px;
    //   z-index: 20;
    // }
    #AppFooter {
        margin-top: auto;
    }
    #LayoutAlert {
        position: sticky;
        top: 72px;
        z-index: 110;
    }
}
