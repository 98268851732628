.ExpertEvalutionResults {

    width: 100%;

    &-SaveCancel {
        margin: 24px 0;

        button {
            margin: 0 24px;
        }
    }

    .StickyTable-Wrapper {
        border-bottom: 1px solid #D3D9E0;
    }

    .AgreedToRate {
        margin: 10px 0;
    }


    .SingleTypeFieldAnswer {
        max-width: 200px;
    }


}

.ExpertEvalutionResults-Content {
    max-width: 100%;
    border-radius: 4px;

    &-Loading {
        width: 100%;
        display: flex;
        justify-content: center;
        height: 100px;
    }

    .StickyTable-th.StickyTable-th_left {
        padding-left: 24px;
        text-align: left;

        .CategoryName {
            text-transform: uppercase;
        }
    }

    .StickyTable-td:last-child {
        padding-right: 24px;

    }

    .StickyTable-th:last-child .ExpertAvatar {
        margin-right: 24px;

    }

    .StickyTable-td,
    .StickyTable-th {
        text-align: left;
    }

    .StickyTable-td>div {
        max-width: 150px;
        // text-overflow: ellipsis;
        // overflow: hidden;
        white-space: pre-wrap;
    }


    .StickyTable-td {
        .Markdown {
            max-width: 150px;

            p {
                text-align: left;
            }

        }

        .MatrixAnswer {
            text-align: left;
            max-width: 100px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
        }

        .UserList {
            max-width: 158px;
        }

        .Tag>.MuiButton-text>span.TagLable {
            max-width: 150px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: pre-wrap;
        }



    }


    .ExpertAvatar {
        // position: relative;
        width: fit-content;
        display: flex;
        height: 40px;
        border: 1px solid #ccc;
        border-radius: 28px;

        span,
        .Expert {
            height: 40px;
        }

        .ExpertDelete {
            .Icon_cross-mark:before {
                content: '\ee24';
                color: #94a4b5;
                font-weight: 600;
            }

            display: flex;
            justify-content: center;
            width: 32px;
            align-items: center;
            // position: absolute;
            cursor: pointer;
            // top: -6px;
            // right: -10px;
            // background: #fff;
            // padding: 2px;
            // z-index: 2;
            // border-radius: 50%;
        }
    }
}