
@import '../../../utils/global.scss';

.Widget_subtype_wikilist{
    .BottomLine{
        border-radius: 8px;
        position: relative;
        top: 1px;
    }
    .ViewType{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 24px;
    }

}
.InfoIcon {
    transition: font-size $TransitionDuration $TransitionTimingFunction;
  }