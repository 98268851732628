.PagesNew {
  margin: 24px 2%;
  display: flex;
  flex-direction: column;
  gap: 24px;

  .Widget_type_wiki {
    // padding: 24px;
    // border-radius: 4px;
    // background: #FFFFFF;
    // box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12);
  }

  .Page {
    margin: 0%;
  }
}