.NotifiSettingsGroups {
  width: 100%;
  &-Item {
    padding: 24px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    border-bottom: 1px solid #d3d9e0;
    @media (max-width: 500px) {
      padding: 12px;
    }
    &-Title {
      display: flex;
      align-items: center;
      font-size: 16px;
      div {
        margin-right: 24px;
      }
    }
    &-Actions {
      margin-right: 40px;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      &>* {
        margin-top: 12px;
      }
      @media (max-width: 500px) {
        margin-top: 12px;
      }
    }
  }
}
