.CourseHeader {
  background-color: #ffffff;
  border-radius: 8px;

  border: 1px solid #d3d9e0;

  &-TopActions {
    display: flex;
    margin: 8px 24px;
    justify-content: space-between;
  }

  &-BackLink {
  }

  &-Section {
    display: flex;
    padding-right: 24px;
    padding-bottom: 24px;
    padding-left: 43px;
    padding-top: 18px;
  }

  &-ImageBox {
    padding-right: 24px;
  }

  &-DescBox {
    flex: 1;
  }

  &-Progress {
    margin-top: 36px;
  }

  &-Name {
    font-size: 24px;
    font-weight: bold;
  }

  &-Authors {
    margin-top: 12px;
    display: flex;
    flex-wrap: wrap;
  }

  &-Author {
    margin: 12px;
  }

  &-AuthorsList {
    width: 100%;
  }

  &-Title {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  &-Like {
    justify-content: center;
    margin-top: 24px;
  }

  &-Nav {
    // background-color: #ffffff;
    display: flex;
    justify-content: center;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
    overflow: hidden;
  }
}
