.QuizWidgetItem {
    display: flex;
    flex-direction: column;
    padding: 0;
    &-Title {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        &-Image {
            margin-bottom: 8px;
            background-size: contain;
        }
        &-Text {
            font-size: 14px;
            font-weight: 700;
            line-height: 157%;
            width: 100%;
        }
    }
    &-Text {
        font-weight: normal;
        line-height: 166%;
    }
    a {
        margin-top: 16px;
        align-self: self-start;
    }
}
