:root {
    --blist-mt: 40px;
    --blist-mt-d: -40px;
}

.BadgesListItem {
    flex-basis: calc(25% - 16px);
    max-width: calc(25% - 16px);

    &-Data {
        text-align: center;
        margin-top: var(--blist-mt);
        border: 1px solid #D3D9E0;
        border-radius: 8px;
        background-color: #ffffff;
        height: calc(100% - var(--blist-mt));
        padding: 0 24px 24px 24px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        align-items: center;

        .Avatar {
            margin-top: var(--blist-mt-d);

            &-Badge {
                background: #D3D9E0;
                border: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 12px;
                color: #000;
            }
        }

        .BadgesListItem-More {
            align-self: start;
            display: flex;
            align-items: center;
            margin-top: 12px;

            .MuiSvgIcon-root {
                font-size: 18px;
            }
        }
    }

    &-Name,
    &-Currency {
        font-size: 16px;
        line-height: 24px;
    }
    
    &-Name {
        margin: 0;
        margin-top: 16px;
        font-weight: 700;
    }
    
    &-Currency {
        margin-bottom: 8px;
    }

    &-Description {
        align-self: start;
        text-align: left;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        font-size: 14px;
        line-height: 18px;
        margin-top: auto;
        width: 100%;

        .CommonmarkRender-MoreBtn {
            display: none;
        }
    }
}