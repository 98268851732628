.QuizFinish {

  &-Buttons {
    display: flex;
    margin: 24px 0;
    flex-direction: column;
    align-items: flex-start;

    button {
      width: fit-content;
      margin-bottom: 6px;
    }

    a {
      width: fit-content;
    }
  }

  &-Badge {
    &-Description {
      margin-top: 12px;
      display: flex;
      align-items: center;

      span {
        max-width: 500px;
        font-size: 16px;
        margin-left: 12px;
      }
    }

    &-RewardTypeCurrency {
      font-weight: 600;
      padding: 12px 0;
    }

    &-RewardTypeBadge {
      font-weight: 600;
      padding: 12px 0;
      display: none;
    }
  }

  &-Text {
    .Markdown {
      p {
        font-size: 16px;
        line-height: 1;
      }
    }
  }

  &-LastAttempt {
    padding: 12px 0;

    span {
      font-weight: 600;
      margin-right: 6px;
    }

  }

  &-Warning {
    margin-top: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 16px;
    // width: 838px;
    // height: 68px;
    // border: 1px solid #2196F3;
    background-color: #2196F3;
    border-radius: 4px;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;

    span {
      // font-family: 'Roboto';
      // font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 143%;
      /* or 20px */
      padding: 8px 0px;
      letter-spacing: 0.15px;
      color: #fff;
      height: 40px;
      display: flex;
      align-items: center;
      margin-left: 14px;
    }
  }
}

@media (max-width: 576px) {
  .QuizFinish {
    &-Warning {
      border: 1px solid #2196F3;
      background-color: #fff;
      .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium{
        color:#305e83!important;
      }
      span {
        font-size: 0.9em;
        color: #305e83;
      }
    }
  }

}