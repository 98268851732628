@import '../../utils/global.scss';


.ListRound {
  list-style-type: none;
  margin: 0px;
  padding: 0px;
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  // justify-content: space-between;



  &-Item {
    margin: 6px;
    position: relative;
  }

  &-RemoveBable {
    padding: 0px 0px;
    border: 2px solid #fff;
    line-height: 20px;
    display: block;
  }

  &-Remove {
    position: absolute;
    right: -6px;
    top: -6px;
  }

}

.Test-List {
  width: 30%;
  max-width: 320px;
  border: 1px solid var(--border-color);
  border-radius: 8px;
  padding: 12px 24px;
}