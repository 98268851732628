.ThanksUserEditor {
    // .ReasonSelector {
    height: 40vh;
    overflow: auto;
    // border-top: 1px solid var(--border-color);
    border-bottom: 1px solid var(--border-color);

    // }
    .ReasonSelector {
        border-top: 1px solid var(--border-color);
    }

    .PryanikyEditor {
        position: relative;
    }
}