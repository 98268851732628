.NewsBannerItem {
    // width: 198px;
    // height: 254px;
    width: 252.33px;
    // height: 100%;
    min-height: 274px;
    max-height: 334px;
    // position: relative;
    // border: 1px solid #D3D9E0;
    border-radius: 8px;
    overflow: hidden;
    position: relative;
    background: #F5F5F5;

    &-Close {
        border-radius: 50%;
        width: 28px;
        height: 28px;
        position: absolute;
        top: 0;
        z-index: 1;
        right: 0px;
        display: none;
    }

    &:hover &-Close {
        display: block;
    }
}