.LeaderboardMui {
    &-Popover {
        &-Content {
            &-Filters{
                // .MuiFormControlLabel-root.MuiFormControlLabel-labelPlacementEnd{
                //     width:100%
                // }
            }
        }
    }

    &-MyItem {
        position: sticky!important;
        bottom: 0;
        background-color: #ffffff;
        z-index: 20;
        border-bottom: 1px solid var(--border-color);
        animation-duration: 0.2s;
        animation-name: slidein;

    }

    @keyframes slidein {
        from {
            transform: translateY(100%);
        }

        to {
            transform: translateY(0);
        }
    }

    &-MyItem.myInTop {
        top: 56px;
        position: sticky!important;
        bottom: auto;
        animation-name: slideout;
        
    }

    @keyframes slideout {
        from {
            transform: translateY(-100%);
        }

        to {
            transform: translateY(0);
        }
    }

    @media (min-width: 600px) {
        // grid-template-areas: 'pos avatar name progress value';
        // grid-template-columns: 1.5em 56px 150px 1fr auto;
        // grid-gap: 16px;
        font-size: 16px;

        &Value {
            justify-self: end;
        }
    }
}