.Exam {
    display: flex;

    &-Aside {
        flex: 10;
    }

    &-Section {
        flex: 28;

    }
}