.EditorForm { 
    position: relative;

    &-Actions {
        display: flex;
        padding: 0 12px;
        align-items: center;
        .Icon {
            line-height: 26px;
            font-size: 26px;
            color: #768aa1;
        }

        &_entering {
            animation: slideDown .3s linear;
        }

        &_exiting {
            animation: slideUp .3s linear;
        }
    }

    &-EditActions {
        flex: 1;
    }

    &-SendActions {
        margin-right: 12px;
    }

    &-EditButton {
        margin-left: 12px;
    }
    &-ErrorBox {
        margin: 12px 24px;
    }
    &-ErrorsLog {
        margin: 12px 24px;
    }
    
}

.EditorForm_close {
    .Editor,
    .EditorPolls-Checkboxes,
    .BadgeSelector,
    .AdditionalFieldsRender-Category:last-of-type .AdditionalFieldsRender-CollapseBox-Toggle {
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;

    }
    .prn-editor-container {
        background-color: transparent;
    }
    .EditorForm-Actions {
        display: none;
    }
}


@keyframes slideDown {
    from {
        // transform: translateY(-100%);
        margin-top: -100px;
        
    }

    to {
        // transform: translateY(0)
        margin-top: 0;
    }
}

@keyframes slideUp {
    from {
        // transform: translateY(0)
        margin-top: 0;
    }

    to {
        // transform: translateY(-100%)
        
        margin-top: -100px;
    }
}