.PrnEditor-StyleButton:hover {
    background: #E2E2E2;
    outline: 0;
}

.PrnEditor-StyleButton {
    background: transparent;
    color: #888;
    /* font-size: 18px; */
    border: 0;
    /* padding-top: 5px; */
    padding: 12px;
    line-height: normal;
    /* vertical-align: bottom; */
    /* height: 34px; */
    /* width: 36px; */
}

.PrnEditor-StyleButton.PrnEditor-activeButton {
    background: #E2E2E2;
    color: #444;
}