.CourseLayout {
  &-Header {
    background-color: var(--background-color);
  }

  &-Header {
    margin-bottom: 24px;
  }

  &-Section {
    display: flex;
    align-items: flex-start;
  }

  &-FullscreenBtn {
    &_fullscreen {
      position: absolute;
      left: 0;
      top: 0;

      &:hover {
        text-shadow: 0px 0px 5px #fff;
      }
    }
  }

  &-Content {
    flex: 28;
    border: 1px solid #d3d9e0;
    background-color: #ffffff;
    border-radius: 8px;
  }

  &-Aside {
    width: 288px;
    flex: 10;
    background-color: #ffffff;
    border-radius: 8px;
    margin-right: 24px;
    border: 1px solid #d3d9e0;

    max-height: calc(100vh - 56px);
    overflow: auto;
    position: sticky;
    top: 56px;

    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
      border-radius: 8px;
      margin: 8px 0;
    }

    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgba($color: #000000, $alpha: .2);
      opacity: 0.3;
      border-radius: 8px;

      &:hover {
        background-color: rgba($color: #000000, $alpha: .4);
      }
    }
  }

  &-Middle {
    border: 1px solid #d3d9e0;
    background-color: #ffffff;
    border-radius: 8px;
    margin-bottom: 24px;
    padding: 24px;

    &:empty {
      display: none;
    }
  }
}