.Grid {
  margin: 0 -24px;

  &_edit &-Item {
    overflow: hidden;
  }

  &-Wrapper {

    &_edit {
      padding-top: 24px;

      .Grid-Adder {
        width: 100%;
      }
    }
  }

  &-Item {
    overflow: auto;

    &.Widget {
      margin: 0;
    }

    .Widget {
      min-height: 100%;
      margin: 0;
    }

    .Widget,
    .List-Header,
    .ListLite {
      border-radius: 0;
    }

    .react-resizable-handle {
      z-index: 15
    }

    .Widget_type_wiki {
      overflow: auto;
    }

    .PryanikyEditorV1-Toolbar {
      top: 0;
    }

    .SliderLink {
      max-width: auto;
    }
  }
}