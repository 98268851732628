.IdeasEvalution{
    &-Content{
        margin:0 24px;
        &-Loading{
            width:100%;
            display: flex;
            justify-content: center;
            height:100px;
        }
    }
    &-SaveCancel{
        margin: 24px 0;
        button{
            margin:0 24px;
        }
    }
}