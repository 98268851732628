.TOCEAnswerInput {
    &-Video{
        width:100%;
        text-align: right;
        font-size: 22px;
    }
    .PryanikyEditorV1 {
        .DraftEditor-editorContainer {
            min-height: 250px;
            padding-bottom: 40px;
        }
    }
}
