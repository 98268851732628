.EventAction {
    display: flex;
    margin-top: 20px;
    justify-content: center;
    align-items: flex-start;
    // flex-direction: column-reverse;

    &-GetCalendarData {
        // margin-right: 30px;
    }

    &-GetCalendarVariants {
        margin-top: 12px;
    }

    &-Base {

        .Button {
            // text-transform: capitalize;
        }
    }

    &-Meeting {
    }

    &-MeetingTimer {
        display: flex;
    }

    &_isMeeting {
        justify-content: space-between;
    }

    &-MeetingTimerDo {
        font-size: 12px;
        line-height: 16px;
        color: #999999;
    }

    &-MeetingTimerTime {
        font-size: 14px;
        line-height: 24px;
        color: #000000;
    }

    &-MeetingTimerLeft {
        margin-right: 6px;
    }

    &-Btn {
        &_active {
            background-color: #d4e5e0;
        }
    }

    &-WaitBtn {
        cursor: default;
        color: #d3d9e0;
    }

    div {
        // min-width: 300px;
        // flex-basis: 50%;
        // min-height: 45px;
    }

    @media screen and (max-width: 700px) {
        div {
            flex-basis: 100%;
        }
    }
}
