.MeetingView {
    // &-Jitsi {
    transition: all ease-in-out 0.3s;
    background-color: #000;
    box-shadow: 0px 0px 10px 5px #000;
    // }
    position: relative;
    // bottom: 0;
    // right: 0;
    display: flex;
    flex-direction: column;

    .react-jitsi-container {
    }

    &-Controls {
        width: 200px;
        height: 100px;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        position: absolute;
    }

    &-Jitsi {
        flex: 1;
    }
}
