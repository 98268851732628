.ListViewIdeasFieldsRender{
    &-Field{

    }
    &-Name{
        color: #999;
         white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    &-Value{
       color: #000;
    }
}