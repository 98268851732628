@import "../../../../../utils/global.scss";
.TOCoursesListItem {
    border-bottom: 1px solid #d3d9e0;
    box-sizing: border-box;
    position: relative;
    overflow: hidden;
    display: flex;
    padding: 8px 24px 12px 24px;

    &-Image {
    }

    &-Info {
        flex: 1;
    }

    &-StatOne {
        display: flex;
    }

    &-Title {
        font-weight: bold;
        font-size: 14px;
    }

    &-ActionBox {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &-ActionMenu {
        .Button {
            padding: 0 4px;
        }
    }
}