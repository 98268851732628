.TOCreateSession {
    &-Name {
        border-bottom: 1px solid #d3d9e0;
        .InputLayout {
            .InputLayout-EditZone {
                font-size: 16px;
                font-family: "Roboto", "Helvetica", "Arial", sans-serif;
            }
        }
    }

    &-Description {
        border-bottom: 1px solid #d3d9e0;
        .Textarea-Textarea {
            font-size: 16px;
            font-family: "Roboto", "Helvetica", "Arial", sans-serif;
        }
    }

    &-Users {
        border-bottom: 1px solid #d3d9e0;
        .Icon_user-add {
            color: #00f;
        }
        .PrnSelect-Placeholder{
            font-size: 14px;
            font-family: "Roboto", "Helvetica", "Arial", sans-serif;
        }
    }

    &-Obligatory {
        .Icon_user-add {
            color: #f00;
        }
        .PrnSelect-Placeholder{
            font-size: 14px;
            font-family: "Roboto", "Helvetica", "Arial", sans-serif;
        }
    }

    &-DurationDays {
        flex: 1 1;
    }

    &-Periode {
        display: flex;
    }

    &-Type {
        border-right: 1px solid #d3d9e0;
        margin-left: 20px;
    }

    &-Durations {
        border-bottom: 1px solid #d3d9e0;
        display: flex;
    }

    &-Periode {
    }
    &-CheckboxInput {
        font-size: 16px;
        font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    }
}
