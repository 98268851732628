.GoalItem {
  margin: 0;
  display: flex;
  line-height: 143%;
  // margin-bottom: 8px;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: 16px;
  }

  .HelpTooltip {
    text-decoration: none;
  }

  &_clicked {
    cursor: pointer;
    font-size: 14px;
    margin-right: 14px;
  }

  // &-IconDone {
  //   color: #89ca5e;
  //   font-size: 16px;
  //   margin-right: 8px;
  // }

  &-Icon {
    // color: var(--highlight-color);
    // font-size: 14px;
    margin-right: 8px;

    &_inProgress {
      color: var(--border-color);
    }
  }
}