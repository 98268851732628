.AdditionalContentRender {
    &-CatName {
        padding: 9px 24px;
        border-bottom: 1px solid #d3d9e0;
    }

    &-Description {
        margin-top: 4px;
        font-style: italic;
    }

    &-Value {
        padding-left: 24px;
        width: 100%;
        word-break: break-word;

    }
    
    &-HelpTooltip{
        position: absolute;
        right: 24px;
    }
    &-Field{
        display: flex;
        justify-content: space-between;
        margin-right: 24px;
        align-items: center;
        // width: 100%;

    }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .AdditionalContentRender {
        &-HelpTooltip{
            text-decoration: none;
            cursor: default;
        }
    }

}