.fade {
    animation: zoomin .1s ease-in-out;
}

.CalendarInput {
    padding-top: 14px;
}

.Calendar-Title {
    text-decoration: underline;
    font-weight: bold;
}

.disabled-date {
    color: #ccc;
}

@keyframes zoomin {
    from {
        transform: scale(0);
        opacity: 0;

    }

    to {
        transform: scale(1);
        opacity: 1;
    }
}