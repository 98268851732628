.StatisticFilters {
  padding: 24px;
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  & > div {
    max-width: 30%;
    width: 100%;
  }

  &-Last {
    order: 2;
    max-width: unset;
  }
}