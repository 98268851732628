.NewsBannerTypeBanner {
    background-color: #F7F7F7;
    padding: 16px;
    padding-top: 16px;
    padding-bottom: 12px;
    height: 100%;
    position: relative;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;

    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    .CommonmarkRender_type_full {
        text-align: left;
        font-weight: 400;
        font-size: 12px;
        line-height: 166%;
        letter-spacing: 0.4px;
    }
    .CommonmarkRender-Image {
        margin: 8px 0;
        border-radius: 4px;

    }

}