@import "../../../utils/global.scss";

.LeftMenuItem {
  color: #94a5b5;

  .Mui {
    &TreeItem {
      &-label {
        padding: 0;
        background-color: transparent !important;
      }

      &-group {
        margin-left: 0;

        .LeftMenuItem-Layout .LeftMenuItem-Body {
          padding-left: 17px;

          .LeftMenuItem-Dragster {
            padding-left: 21px;
          }
        }
      }

      &-iconContainer {
        display: none;
      }
    }
  }

  &-Layout {
    position: relative;

    &_isOver {
      &:before,
      &:after {
        // content: '';
        height: 50px;
        width: 100%;
        z-index: 1;
        opacity: 0.5;
        // background-color: yellow;
      }

      &:before {
        display: none;
      }

      &:after {
        display: block;
      }

      &.LeftMenuItem-Layout_dragBefore {
        &:before {
          display: block;
        }

        &:after {
          display: none;
        }
      }

      &.LeftMenuItem-Layout_expanded {
        &:after {
          margin-left: 17px;
        }
      }
    }

    &_canDrop.LeftMenuItem-Layout_isOver {
      &:before,
      &:after {
        content: "";
        background-color: yellow;
      }
    }

    &-Adder {
      opacity: 0;
      position: absolute;
      left: 0;
      width: 100%;
      height: 7px;
      z-index: 1;
      transform: translate(0, -3px);
      cursor: crosshair;

      i.Icon {
        color: #000;
      }

      &:before {
        content: "";
        position: absolute;
        border-top: 1px solid;
        left: 0;
        width: 100%;
        bottom: 0;
        transform: translate(0, -3px);
      }

      &:hover {
        opacity: 1;
      }

      .Icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 50%;
        border: 1px solid;
        background-color: #fff;
      }

      &_before {
        top: 0;
      }

      &_after {
        bottom: 0;
      }
    }
  }

  &-Dragster {
    position: absolute;
    cursor: grab;
    top: 50%;
    transform: translateY(-50%);
    left: -100%;
    transition: left $TransitionDuration $TransitionTimingFunction;
    left: 4px;
  }

  &-Body {
    opacity: 1;
    position: relative;

    &_active {
      .LeftMenuItem-Name,
      .LeftMenuItem-Label {
        font-weight: bold;
      }
    }

    &:hover {
      color: #fff;
      background-color: #182736;
    }

    &_isDragging {
      opacity: 0.5;
      height: 0;
    }
  }

  &-Actions {
    display: none;
    position: absolute;
    z-index: 1;
    right: 45px;
    top: 50%;
    transform: translateY(-50%);
    align-items: center;

    // transition: right $TransitionDuration $TransitionTimingFunction;
    // padding-right: 8px;
    // right: -75px;

    // &:hover {
    //   right: 0;
    // }

    button {
      &:nth-child(n + 2) {
        margin-left: 4px;
      }
    }
  }

  &-Layout:hover &-Actions {
    display: flex;
  }
}
