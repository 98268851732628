.NewsEdit-Body_type_ideas {}

.NewsEdit-Body-Idea-Category-Toggle {
    display: flex;
    cursor: pointer;
    background: #f7f7f7;
    padding: 9px 24px;
    border-bottom: 1px solid #d3d9e0;
}

.NewsEdit-Body-Idea-Category-Toggle-Text {
    margin: 0;
    flex: 1 1;
}

.NewsEdit-Body-Idea-Category-Toggle-Icon {}

.IdeaEdit-Matrix {
    padding: 0 24px;
    border-bottom: 1px solid #d3d9e0;
}

.IdeaEdit-CollapseBox {
    .CollapseBox-Content {
        border-bottom: 1px solid #d3d9e0;
    }
    .IdeaEdit-Input {
        border-bottom: none;
    }
}

.IdeaEdit-Input-Title {
    border-bottom: 1px solid #d3d9e0;
}

.IdeaEdit-TextareaMentions {
    border-bottom: 1px solid #d3d9e0;
}

.IdeaEdit-Input {
    border-bottom: 1px solid #d3d9e0;
}