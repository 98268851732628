.Modal-Body.Modal-Body_type_groupList {
  // max-height: unset;
  padding: 0;
}

.Modal-Body_type_groupList-List {
  padding: 0;
  margin: 0;
}

.Modal-Body_type_groupList-List-Item {
  list-style-type: none;
  padding: 10px 4%;
  display: flex;
  align-items: center;
  background-color: #ffffff;
  border-bottom: 1px solid var(--border-color);
}

.Modal-Body_type_groupList-List-Item:hover {
  cursor: pointer;
  background-color: #0066cc;
  color: #fff;
}

.Modal-Body_type_groupList-List-Item_selected {
  background-color: #0066cc;
  color: #fff;
}

.Modal-Body_type_groupList-List-Item-Img {
  width: 54px;
  height: 54px;
  text-align: center;
  overflow: hidden;
  border-radius: 50%;
}

.Modal-Body_type_groupList-List-Item-Img>img {
  width: 100%;
}

.Modal-Body_type_groupList-List-Item-Img>div {
  line-height: 54px;
  color: #fff;
  font-size: 20px;
  width: 100%;
  // height: 100%;
  text-align: center;
}

.Modal-Body_type_groupList-List-Item-Data {
  margin-left: 15px;
}

.Modal-Body_type_groupList-List-Item-Clear {
  clear: both;
}

.Modal-Body_type_groupList-List-Item_button {
  padding: 0;
  position: relative;
  min-height: 30px;
}

.Modal-Body_type_groupList-List-Item .Button {
  display: block;
  width: 100%;
  height: 100%;
}

.Modal-Body_type_groupList-List-Item-Check {
  width: 50px;
}

.Modal-Body_type_groupList-Data-Wrap {
  max-height: 400px;
  overflow-y: auto;
  min-height: 150px;
}

.Modal-Body_type_groupList-Search-Box {
  padding: 15px 3%;
  border-bottom: 1px solid #d3d9e0;
}

.Modal-Body_type_groupList-Selector {
  padding: 10px 0 0 0;
}

.Modal-Body_type_groupList-Button_more {
  display: block;
  margin: auto;
}


@media all and (-ms-high-contrast: none),
(-ms-high-contrast: active) {

    /* IE10+ CSS styles go here */
.Modal-Body_type_groupList-List-Item {
  height: 50px;
}

.Modal-Body_type_groupList-List-Item-Img {
  height: 48px;
  width: 48px;
}
.Modal-Body_type_groupList-Data-Wrap {
  max-height: 450px;
}
}