.LMSTOStatCourseListItem {
    &-Col1 {
        padding: 24px;
        // line-height: 0;
        text-align: left;
    }
    &-Col0 {
        padding-left: 12px;
    }

    &-ColField {
        overflow: hidden;
        max-width: 120px;
    }

    &-Mention {
        font-weight: bold;
    }

    &-AuthorsList {
        .AuthorsList-Wrapper {
            display: inline-block;
        }
        .AuthorItem-Avatar {
            width: auto;
        }
    }

    &_isSingle {

    }

    &_course {
    }
    &_session {
        background-color: #F7F7F7;
        .LMSTOStatCourseListItem-Col1 {
            padding: 12px;
            padding-left: 48px;
        }
    }

}