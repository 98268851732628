.Fields-DateTimeInput {
    width: 100%;
    // background-color: #fff;
    // // width: 300px;
    // // margin-left: 24px;
    // // border: 1px solid #d3d9e0;

    // // .InputLayout-WorkZone{
    // //     padding-left: 3px;
    // // }

    // input {
    //     margin-left: 0px;
    // }
    // .InputLayout-WorkZone {
    //     width: 54%;
    // }
}
