.ColumnChanger{
  position: relative;

  > div {
    position: absolute;
    top: -20px;
    left: 50%;
    width: 50%;
    min-width: 2px;
    height: calc(100% + 40px);
    cursor: col-resize;
    transform: translate(-50%);

    > div {
      position: absolute;
      top: 0;
      left: 50%;
      width: 2px;
      height: 100%;
      border-left: 1px solid #000;
      border-right: 1px solid #fff;
      box-sizing: border-box;
    }
  }
}