@import '../../../utils/global.scss';

.PageComments{
    &-Title{
        display: flex;
        justify-content: space-between;
        padding: 10px 24px;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        background-color: #f7f7f7;
        position: absolute;
        z-index: 1;
        border-bottom: 1px solid #ccc;
        width: 95.5%;
        // top: 54%;
        // left: 13.99%;
        // right: 8.4%;
            h3{
            margin: 5px 0;
        }
    }
    &-Cross{
        font-size: 20px;
        line-height: 40px;
    }
    &-CommentsLayout {
        // background-color: #ffffff;
        height: 50%;
        position: fixed;
        z-index: 1;
        // border: 1px solid var(--border-color);
        // background-color: var(--background-color);
        // border-radius: 8px;
        bottom: 35px;
        // width: 58%;

        &-Data {
            margin: 0 12%;
            height: 100%;
            border: 1px solid var(--border-color);
            background-color: var(--background-color);
            border-radius: 8px;
            position: relative;
            overflow: hidden;
        }
    }    
    &-More {
        background-color: #F7F7F7;
        border-top: 1px solid #D3D9E0;
        &:empty {
            display: none;
        }
    }

    &-Comments {
        position: relative;
        top: 60px;
        // padding-bottom: 8px;
        background-color: #F7F7F7;
        border-top: 1px solid #D3D9E0;
        @include BorderRadiusBottom();
        &:empty {
            display:none;
        }
    }

    &-Form {
        border-bottom-right-radius: 8px;
        border-bottom-left-radius: 8px;
        background-color: #F7F7F7;
        border-top: 1px solid #D3D9E0;
        &:empty {
            display: none;
        }
    }
}