@import '../../List.scss';

.List_type_shop {

  .List {

    &-Header {
      flex-wrap: wrap;
      margin-bottom: 24px;
    }
    

    &-Button {

      &_more {
        display: block;
        width: 100%;
        border-radius: 8px;
      }
    }
  }
}


.List-Wrap {
  flex-wrap: wrap;
}