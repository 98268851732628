.TOTestResults {
    &-Data {
        display: flex;
        border-radius: 8px;
        border: 1px solid #d3d9e0;
        background-color: #fff;
        padding: 24px;
    }

    &-Sessions {
        display: flex;
    }

    &-Tabs {
        margin-left: -12px;
    }

    &-NoSession {
        text-align: center;
    }

    &-Control {
        background-color: #fff;
        padding: 24px;
        margin-bottom: 24px;
        border-radius: 8px;
        border: 1px solid #d3d9e0;
    }

    &-Chart {
        margin-right: 24px;
        flex: 2;
    }

    &-Summary {
        flex: 3;
    }

    &-Table {}

    &-Value {
        line-height: normal;
        font-size: 20px;
        line-height: 24px;
    }

    &-SummaryTitle {
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        padding-top: 9px;
        padding-bottom: 9px;
    }

    &-SummaryAnswers {
        font-weight: bold;
        font-size: 16px;
        line-height: 24px;
        padding-top: 9px;
        padding-bottom: 9px;
    }
}