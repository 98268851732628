.TOStudentResults {
  display: flex;
  margin-top: 24px;

  &-Aside {
    background-color: #fff;
    flex: 1 4;
    border-radius: 8px;
    border: 1px solid #D3D9E0;
    width: 288px;
  }

  &-Breadcrumbs {
    margin-bottom: 32px;
  }

  &-Section {
    // background-color: #fff;
    flex: 3 4;
    margin-left: 24px;
    
  }

  &-Block {
    background-color: #fff;
    margin-top: 24px;
    border-radius: 8px;
    padding: 24px 0;
    border: 1px solid #d3d9e0;

    &:first-of-type {
      margin-top: 0;
    }
  }

  &-Session {
    padding: 24px;
  }

  &-Row {
    display: flex;
  }

  &-UserMention {
    font-size: 24px;
  }

  &-Col {
    flex: 1;

    &_noFlex {
      flex: none;
    }
  }

  &-Chart {
    width: 100px;
    height: 100px;
    // margin-right: 24px;
    // margin-left: 24px;
    margin-right: 8px;
  }

  &-Avatar {
    margin-right: 8px;
  }
}
