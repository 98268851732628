.WikiEditMui {
    background-color: #fff;
    width: 296px;
    margin: 0 16px;

    // &-Loading {
    //     .Loading {
    //         width: 328px;
    //         max-width: 328px;
    //         margin: 0 16px;
    //         white-space: nowrap;
    //     }

    // }

    .InputMention:focus-within {
        width: 100%;
    }

    &-Content {
        // padding: 0 24px;

        &-Item {
            margin: 8px 0 0 0;
        }
    }

    &-CheckboxInput {
        font-size: 16px;
        font-family: "Roboto", "Helvetica", "Arial", sans-serif;
        margin: 5px 24px 0 24px;
    }

    &-ViewType {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 24px;
    }

    // &-PositionInCollection {
    //   margin: 0 24px;

    //   .InputMUI {
    //     width: 100%;
    //   }
    // }

    .Dialog-Actions {
        display: flex;
        justify-content: space-evenly;
    }

    &-InputFolder {
        display: flex;
        align-items: center;
        margin: 8px 0 0 0;
      
        .TooltipMargin {
            margin-left: 10px;
        }

        // .MuiAutocomplete-root.MuiAutocomplete-hasClearIcon.MuiAutocomplete-hasPopupIcon {
        //     width: 100%;
        // }

        &-Folder {
            width: 100%;
            padding: 0px;

            .Icon {
                margin-left: 8px;
                color: #757575;
            }
        }
    }

    &-Loading {
        margin: 10px 16px;
        width: 296px;
        white-space: nowrap;
    }

    &-FileInput {
        margin: 10px 24px;
        padding: 0px;
    }

    &-Input {
        border: 1px solid #ccc;
        margin: 10px 24px;
        padding: 0px;

        .Input-Input {
            background: #fff;
            font-size: 16px !important;
            font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
            // margin: 0 0 0 47px;
        }

        .Icon {
            margin-left: 8px;
            color: #757575;
        }
    }

    .ImageLoaderMui-Block {
        max-width: 210px;
    }

    &-notImportant {
        color: #0066cc;
        margin: 24px;

    }


    &-AvatarLoader {
        margin: 10px 24px;
    }

    &-Radio {
        display: block !important;
        // flex: 1 1 100%!important;
        margin: 24px !important;
    }

    &-Sex {
        width: 100%;
    }

    &-IMG {
        margin-left: 24px;

        .ImgLoader-Preview {
            border-radius: 50%;
            margin-bottom: 15px;
        }

        .ImgLoader-Info {
            .Button_borderRadius {
                position: relative;
                top: 100px;
            }
        }

    }

    &-Save {
        .Loading {
            width: 10rem;
            white-space: nowrap;
        }
    }

    &-Save:disabled {
        margin-right: 10px;
        margin-left: 10px;
    }

    // &-child{
    //   margin: 5px;
    // }
    &-delete {
        color: #fff;
        margin-right: 12px;
    }

}


@media all and (-ms-high-contrast: none),
(-ms-high-contrast: active) {

    /* IE10+ CSS styles go here */
    .WikiEditDialog {
        &-urlChild {
            top: 12px;
        }
    }
}