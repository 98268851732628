.ReactionsItem {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 44px;
    height: 44px;
    &-Tooltip {
        display: none;
    }
    &:hover &-Tooltip {
        display: block;
        position: absolute;
        top: -35px;
        padding: 3px;
        border-radius: 3px;
        background-color: rgba(0, 0, 0, 0.7);
        color: #fff;
        z-index: 30;
    }
    &-Image {
        width: 38px;
        height: 38px;
        transition: all 0.3s ease-in-out;
        &Selected {
            width: 44px;
            height: 44px;
        }
        &:hover {
            width: 44px;
            height: 44px;
            transition: all 0.3s ease-in-out;
        }
    }
    &-Count {
        position: absolute;
        right: -2px;
        bottom: 0;
        padding: 1px;
        line-height: 1;
        text-align: center;
        color: #06c;
        font-weight: 600;
        background-color: #fff;
        border-radius: 20px;
        font-size: 14px;
        height: 14px;
        width: 14px;
        &Selected {
            position: absolute;
            right: -2px;
            bottom: 0;
            padding: 1px;
            line-height: 1;
            font-weight: 600;
            border-radius: 20px;
            font-size: 14px;
            height: 14px;
            width: 14px;
            text-align: center;
            color: #fff;
            background-color: #06c;
        }
    }
}