@import "../../../utils/global.scss";

.NewsHeadView {
    // background-color: #fff;
    // @include BorderRadius;
    // border-bottom: 1px solid #d3d9e0;
    // display: flex;
    // align-items: center;
    // min-height: 78px;
    // padding: 8px 0;

    &-Header {
        padding: 8px 0;
        box-shadow: inset 0px 0px 0px 0px #d3d9e0;
        transition: all 0.15s ease-in-out;

        &:hover {
            box-shadow: inset 0px -6px 0px 0px #d3d9e0;
        }
        &_active {
            box-shadow: inset 6px 0px 0px 0px #d3d9e0;
            &:hover {
                box-shadow: inset 6px -6px 0px 0px #d3d9e0;
            }
        }
    }
}
