.ItemChip {
    border-radius: 15px;
    background: #ccc;
    padding: 3px 8px;
    display: flex;
    margin: 3px;
}

.ItemChip-Content {}

.ItemChip-CloseBox {
    margin-left: 5px;
}

.ItemChip-Close {
    cursor: pointer;
}