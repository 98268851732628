@import '../../utils/global.scss';

.Virtcurrency {
  padding: 24px;

  &-Block {

    &:not(:last-child) {
      padding-bottom: 18px;
    }

    &:last-child {
      padding-top: 18px;
      border-top: 1px solid var(--border-color);
    }
  }

  &-Icon,
  &-Count {
    display: inline-block;
    color: #000;
    vertical-align: middle;
  }



  &-Text {
    display: flex;
    flex-direction: column;

    &-Count,
    &-Count2 {
      display: inline-block;
      color: #000;
      vertical-align: middle;
      line-height: 20.02px;
    }

    &-Count2 {
      font-size: 14px;
      font-weight: 500;

      &_loading {
        width: 50%;
      }
    }
  }

  &-Icon,
  &-IconColl {
    border-radius: 50%;
    font-size: 50px;
    width: 40px;
    height: 40px;
    margin-right: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #FFB400;

    &:before {
      width: auto !important;
    }

    .Icon_gem:before {
      width: 26px;
    }
  }

  &-Header {
    margin-top: 0;
    margin-bottom: 14.5px;
  }

  &-Content {
    .MuiIcon-root {
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;

      img {
        width: 24px;
        height: 24px;
      }
    }

    display: flex;
    // justify-content: center;
    align-items: center;
  }

  .Wallet {
    position: relative;
    justify-content: center;
    padding: 16px 0 0 0;
    padding-top: 40px;

    .Wallet-Icon {
      font-size: 62px;
      border-radius: 50%;
      border: 3px solid #d3d9e0;
      padding: 8px;
    }

    .Wallet-Name {
      font-size: 16px;
      line-height: 16px;
      position: absolute;
      top: 16px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}

.VirtcurrencyNew {
  padding: 24px;

  &-CardArticleItem {

    &-Avatar {
      width: 48px;
      height: 48px;
      margin-right: 5px;
    }
  }

  &-Icon,
  &-Count {
    display: inline-block;
    color: #000;
    vertical-align: middle;
  }

  &-Text {
    display: flex;
  }

  &-Header {
    margin-top: 0;
    margin-bottom: 14.5px;
  }

  &-Content {
    display: flex;
    align-items: center;
  }
}