.CourseEditor {
    display: flex;
    margin-top: 24px;

    &-Aside {
        background-color: #fff;
        flex: 1 4;
        border-radius: 8px;
        overflow: hidden;
        margin-right: 24px;
        border: 1px solid #D3D9E0;
        padding-top: 12px;
    }

    &-Section {
        flex: 3 4;
      
    }

    &-Content {
        background-color: #fff;
        border-radius: 8px;
        border: 1px solid #D3D9E0;
        position:relative;
        &-Video{
            position:absolute;
            top:0;
            right: 11px;
            font-size: 22px;
        }
    }

    &-Message {
        margin-bottom: 24px;
        border-radius: 8px;
        border: 1px solid #D3D9E0;
    }
}