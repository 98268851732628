.Answer {
    &-User {
        font-weight: bold;
    }
    &-Actions {
        margin: 12px 0;
        margin-right: auto;
    }
    &-Status {
        cursor: default;
        &_status_Approved {
            background-color: #89ca5e;
            color: #fff;
        }
        &_status_Disapproved {
            background-color: #f1543f;
            color: #fff;
        }
        &_status_WaitModeration {
            background-color: #ccc;
            color: #000;
        }
    }
}
