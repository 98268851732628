.ActionButtons {
    // background-color: #fff;
    display: flex;
    justify-content: center;
}

.ActionButtons-SaveBtn {
    // background-color: #0066cc;
    border-radius: 5px;
    color: #fff;
}

.ActionButtons-SaveBtn,
.ActionButtons-CancelBtn {
    padding: 7px 24px;
    margin: 15px 5px;
    &:last-of-type {
        margin-right: 0;
    }
}