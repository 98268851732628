.ImgLoader_type_profileEdit {
    .ImgLoader-Button {}

    .ImgLoader-Info {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .ImgLoader-Preview {
        width: auto;
        height: auto;
        float: none;
        margin: 0;
    }
    .ImgLoader-Preview-Img {
        width: 100%;
        height: auto;
        display: block;
    }
}