.FilterWorkflowsBar {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 6px 24px;
  background: rgba(255,255,255,.5);

  & > button {
    margin-left: 12px;
  }

  .SearchInput {
    flex: 1;
    margin-right: 24px;
  }

  .NewsViewSelector {
    // width: 100%;
    display: flex;
    justify-content: space-between;
    h4 {
      margin: 0;
      margin-right: 16px;
    }
  }

  &-Preview {
    width: 100%;
    text-align: start;
    font-weight: bold;
    font-size: 16px;
    height: 46px;
    vertical-align: middle;
    display: flex;
    align-items: center;
  }
}