.InputLayout {
  /* display: flex; */
  width: 100%;
  height: 100%;
}

.InputLayout-WorkZone {
  display: flex;
  padding: 0px 24px 0 24px;
  align-items: center;
  height: 100%;
}

.InputLayout-EditZone {
  display: flex;
  flex: 1 1;
  flex-wrap: wrap;
  align-items: center;
  height: 100%;
  /* padding-bottom: 5px; */
  padding-top: 2px;
  padding-bottom: 2px;
}

.InputLayout-IconZone {
  width: 38px;
  display: flex;
  align-items: center;
  height: 40px;
}

.InputLayout-IconZone:empty {
  display: none;
}

.InputLayout-Suggester {
  position: relative;
}

.InputLayout-WorkZone__focus {
  box-shadow: inset 0px 0px 1px 1px #0066cc;
}
