.LMSQuestion {
  background-color: #fff3bd;
  border-radius: 8px;
  padding: 20px 32px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &-Header {
    display: flex;
  }

  &-Title {
    font-size: 18px;
    font-weight: bold;
    line-height: 20px;
    letter-spacing: -0.3px;
    cursor: pointer;
    user-select: none;
    margin-right: auto;
  }

  &-Control {
    cursor: pointer;
    user-select: none;
  }

  &-Version {
    font-size: 12px;
    color: #999999;
    margin-right: 12px;
  }

  &-Section {}
}