@import "../../List.scss";

.List_type_orders {
  .ListLite {
    background-color: var(--background-color);
    border: 1px solid var(--border-color);
  }

  .List {
    &-Button {
      &_more {
        margin-top: 10px;
        display: block;
        width: 100%;
        border-radius: 8px;
      }
    }
  }
}
