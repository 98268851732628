.TOStudentItem {
    // display: flex;
    // padding: 12px 24px 12px 40px;

    &:hover {
    }

    &-AvatarBox {
        // flex: 1 5;
        margin-right: 24px;
    }
    &-Avatar {
    }

    &-Data {
        flex: 3 5;
    }

    &-Duration {
        // flex: 1 5;
    }

    &-Title {
        font-family: "Open Sans";
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 24px;
        color: #0066cc;
    }

    &-Action {
        flex: 1 5;
        text-align: right;
    }

    &-Spacer {
        flex: 1;
    }

    &-Info {
        //flex: 4 5;
        margin: 0 24px;
    }

    &-Bottom {
        display: flex;
    }

    &-Top {
        display: flex;
        margin-bottom: 12px;
    }

    //////////////////////////
    &-SessionList {
        width: 100%;
    }
}
