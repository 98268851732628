.StatisticWidget {
  padding: 24px;

  &_toChange {
    box-shadow: 0px 0px 6px 6px rgba(60, 221, 33, 1);
  }
  &-Title {
    font-size: 16px;
    font-weight: bold;
    text-align: center;
  }

  &-Chart {
    display: flex;
    justify-content: center;
  }

  .TabsElement {
    flex-wrap: wrap;
    justify-content: space-evenly;

    &-TabBtn {
      margin: 0 12px;
    }
  }
}