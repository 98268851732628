@import '../../utils/global.scss';

.MountainRating {
  position: relative;
  outline: none;
  font-family: 'Roboto', 'Open Sans', Arial, Helvetica, sans-serif;
  line-height: 1.3;
  &-Field {
    height: 600px;
    overflow: hidden;
    position: relative;
    display: flex;
    background-color: #6ca3cb;
  }
  &-Background {
    position: absolute;
    top: -100px;
    left: 0;
    min-width: 100%;
    min-height: calc(100% + 100px);
    transition: filter 2s ease-in;
    &_loading {
      filter: blur(20px);
    }
  }
  &-Bars {
    display: flex;
    align-items: flex-end;
    height: 100%;
    touch-action: none;
    margin-left: auto;
    position: relative;
    z-index: 10;
  }
  &-Grid {
    position: relative;
    flex-grow: 2;
    width: 100%;
    height: 100%;
    &Row {
      text-align: center;
      font-size: 18px;
      box-sizing: border-box;
      font-weight: bold;
      font-style: italic;
    }
    &Point {
      display: inline-block;
      background-color: rgba(255, 255, 255, 0.8);
      border-radius: 6px;
      width: 3rem;
      height: 2rem;
      line-height: 2rem;
      text-align: center;
    }
  }
  &-Select {
    border-radius: 6px;
    width: 173px;
    border: none;
    &-Icon_chevron {
      color: rgb(127, 0, 255);
    }
  }
  &-Header {
    padding: 24px;
    position: absolute;
    top: 0;
    display: flex;
    justify-content: space-between;
    width: 100%;
    box-sizing: border-box;
    z-index: 11;
  }
  &-Title {
    font-size: 24px;
    font-weight: bold;
    color: var(--invariant-text-color);
    margin: 0;
  }
  &-Popover {
    z-index: 9999;
    /*background-color:rgba(255, 255, 255, 0.8);*/
    padding: 16px;
    border-radius: 8px;
    // @include BoxShadow;
    // @include Border;
    display: flex;
    &-Info {
      margin-left: 16px;
    }
    &-Name {
      font-size: 16px;
      line-height: 22px;
      margin-top: 0;
      margin-bottom: 8px;
    }
    &-Contact {
      margin-top: 0;
      margin-bottom: 8px;
      &-Label {
        display: inline-block;
        min-width: 10rem;
        white-space: nowrap;
        color: var(--text-muted-color);
      }
    }
    &-Description {
      margin-top: 0;
      margin-bottom: 8px;
      white-space: pre-line;
      color: var(--text-muted-color);
    }
  }
}
