.Widget[class*="Widget_type_external"] {
    iframe {
        display: block;
    }
}

.Widget[class*="WidgetMui_type_external"] {
    iframe {
        display: block;
    }
}

.Widget[class*="WidgetMui_type_iframe"] {
    iframe {
        display: block;
    }
}