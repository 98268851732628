.NewAnswerForm {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    >*{
        align-self: center;
    }

    &-AnswerTimes {
        color: #858585;
        padding: 10px 0;

        .Icon::before {
            margin: 0;
            margin-right: 8px;
        }
    }

    &-AnswerSubmit {
        padding: 10px 0 10px 20px;

        .Icon::before {
            margin: 0;
        }
    }

    &-AnswerAddInput {
        outline: none;
        flex: 1 1;
        // height: 100%;
        border: none;
    }
}