.SkillsList {
    margin-bottom: 8px;
    border-collapse: collapse;
    width: 100%;

    &-ColIndex {
        width: 30px;
        @media (max-width: 500px) {
            display: none;
        }
    }

    &-ColName {
        width: 35%;
    }

    &-ColUsers {
        width: 40%;
        @media (max-width: 500px) {
            display: none;
        }
    }

    &-ColActions {
        width: 20%;
    }
    &-Empty {
        padding: 6px 24px;
        min-width: 200px;
    }
    &-EmptyList {
        padding: 0px;
        min-width: 200px;
    }
}