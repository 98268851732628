.DateTable {
    width: 100%;
    text-align: center;
}

.CellItem {
    padding: 0;
    cursor: pointer;
    border-radius: 4px;
    line-height: normal;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    width: 32px;
}

.current-date {
    background-color: #ccc;
}

.selected-date {
    background-color: #0066cc;
    color: #fff;
}

.BtnBox {
    display: flex;
    justify-content: space-around;
}