.NewsView-Actions {
    padding-top: 14px;
    padding-bottom: 20px;
    line-height: 24px;

    .Button {
        color: #999;
        margin-left: 4.71693%;
        &:first-of-type {
            margin-left: 0;
        }
    }

    .NewsView-Actions-BtnLikeNum {
        margin-left: 8px;
    }

    .Icon {
        margin-right: 8px;
    }
}