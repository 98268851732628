.TOStudentList {
    display: flex;
    margin-top: 24px;

    align-items: flex-start;

    &-List {
        background-color: #fff;
    }

    &-Aside {
        background-color: #fff;
        flex: 1 4;
        border-radius: 8px;
        margin-right: 24px;
        overflow: hidden;
        border: 1px solid #D3D9E0;

        max-height: calc(100vh - 56px);
        overflow: auto;
        position: sticky;
        top: 56px;

        &:empty {
            display: none;
        }

        &::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
            border-radius: 8px;
            margin: 8px 0;
        }

        &::-webkit-scrollbar {
            width: 5px;
        }

        &::-webkit-scrollbar-thumb {
            background-color: rgba($color: #000000, $alpha: .2);
            opacity: 0.3;
            border-radius: 8px;

            &:hover {
                background-color: rgba($color: #000000, $alpha: .4);
            }
        }
    }

    &-Section {
        background-color: #fff;
        flex: 3 4;
        border-radius: 8px;
        border: 1px solid #D3D9E0;
        padding-bottom: 8px;
    }

    &-Search {
        margin-left: 44px;
        flex: 1 1;
    }

    &-Tabs {
        border-bottom: 1px solid #D3D9E0;
    }

    &-Item {
        border-bottom: 1px solid #D3D9E0;
    }

    &-Trigger {
        display: block;
        margin-left: auto;
        margin-right: auto;
    }

    &-Header {
        display: flex;
        background: #F7F7F7;
        align-items: center;
        padding: 24px;
        border-bottom: 1px solid #D3D9E0;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
    }
}