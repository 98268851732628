.InfoBlock-Badge {
    &-Wrap {
        display: flex;
    }
    &-Currency {
        font-weight: bold;
        font-size: 24px;
    }
    &-Data {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
        margin-left: 30px;
    }

    &-DataName {}

    &-DataDescription {
        margin-top: 10px;
    }

    &-Img {
        .Avatar-Img {
            background-color: transparent;
        }
    }
}