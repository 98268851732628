.Selector {
    display: flex;
    border-bottom: 1px solid #D3D9E0;

    &-Button {
        flex: 1;
        line-height: normal;
        padding: 6px;
        border-right: 1px #D3D9E0 solid;

        &::first-letter {
            text-transform: capitalize;
        }

        &:last-of-type {
            border-right: none;
        }
    }

    &-Button_active {
        background: #6fb19d;
        color: #fff;
    }
}