.QuestItem {

  &-Details {
    padding: 0px 0 12px 0;

    &.standart {
      display: flex;
      justify-content: center;
      flex-direction: column;
    }

    &-Header {
      display: flex;
      align-items: center;
      margin-bottom: 16px;

      &.standart {
        align-items: center;
        margin-bottom: 16px;
      }
    }

    &-Title {
      font-size: 14px;
      // font-weight: bold;
      text-overflow: ellipsis;
      overflow: hidden;
      margin: 0;
      // margin-top: 6px;
      margin-left: 12px;

      // margin-bottom: 12px;
      &.standart {
        margin-left: 24px;
        margin-top: 0;
      }
    }
  }

  &-Rewards {
    padding: 12px 0 0 0;
    // border-top: 1px solid var(--border-color);

    h4 {
      margin-top: 0px;
      margin-bottom: 8px;
      font-size: 14px;
      font-weight: bold;
    }

    &-Images {
      display: flex;
      align-items: center;



      &>div {
        display: flex;
        align-items: center;
        // margin-right: 12px;
        // padding: 2px;
        border-radius: 50px;
        background: rgba(245, 0, 87, 0.08);
        margin-bottom: 8px;

        &.currency {
          // background-color: #F0C419;

          .Avatar {
            border-radius: 50%;
            border: 3px solid #f7f7f7;
            background: #f7f7f7;
          }
        }

        &.rating {
          background-color: #B0D3F0;
        }

        &>span {
          // margin-left: 4px;
          margin-right: 8px;
          font-size: 14px;
        }
      }
    }

    &-Currency {
      &.standart {
        padding: 0 6px;
        font-size: 14px;
      }
    }


    &.standart {
      order: -1;
      margin-top: 0;

      &>div {
        &>span {
          font-size: 16px;
          font-weight: bold;
          margin-left: 8px;
          margin-right: 12px;
        }
      }
    }

    &-Info {


      &>div {
        display: inline;

        &>span {
          display: inline-block;
          min-width: 30px;
          font-weight: bold;
        }
      }
    }

    &-Item {
      &-Icon {
        width: 40px;
        height: 40px;
        background-color: #F0C419;
        display: flex;
        align-items: center;
        margin-right: 8px;
        // padding: 2px;
        border-radius: 50px;

        // .MuiIcon-root {
        //   width: 40px;
        //   height: 40px;
        // }
        .MuiSvgIcon-root {
          width: 40px;
        }

      }
    }
  }

  &-Progress {
    &-Line {
      width: 100%;
      margin-right: 12px;

      &_standart {
        width: 100%;
        margin-right: 12px;
      }
    }

    &-Info {
      min-width: fit-content;
      margin-bottom: 4px;
      font-size: 14px;

      span {
        color: #999;
      }

      &-Text {
        font-weight: bold;
        color: #000;
      }

    }

  }

  &-Bonus {
    font-weight: 600;
  }

  &-List {
    padding-left: 18px;
  }

  &-BonusButton {
    color: #0066cc;
    font-weight: bold;
  }

  &-RewardHeader {
    margin-top: 8px;
    margin-bottom: 0;
  }

  &-RewardItem {
    display: block;

    >* {
      display: inline;
    }
  }

  &-Goals {
    padding: 6px 0;
  }

  &-Done {
    position: absolute;
    color: #89ca5e;
    top: 6px;
    left: 6px;
    z-index: 1;
  }
}

.QuestItem.standart {
  padding-bottom: 24px;
}