@import '../../utils/global.scss';

.MultiSelect {
    position: relative;
    padding: 11px 24px 10px 0;

    &-HelpTooltip{
        top: 10px;
        right: 0;
        position: absolute;
    }
    &-ErrorIcon:before {
        // right: 15px;
        // position: absolute;
        color: $ErrorColor;
    }
    &-Options {
        border: 1px solid var(--border-color);
        box-sizing: border-box;
        display: block;
        position: absolute;
        top: 100%;
        left: 0;
        margin: 0;
        padding: 0;
        list-style-type: none;
        width: 100%;
        background-color: var(--background-color);
        z-index: 1;
        max-height: 250px;
        overflow-y: auto;
    }

    &-Item {
        &.checked {
            background-color: #ccc;
        }
        padding: 10px;
    }

    &-Placeholder {}

    &-Selected {
        margin: 2px;
    }

    &-OptionsItem {
        
        &:hover {
            color: var(--invariant-text-color);
            background-color: var(--main-color);
            cursor: pointer;
        }
    }

    &-Caret {
        float: right;
    }

    &-Inner {
        padding: 0 0 0 24px;
    }

    &-Icon {
        padding-right: 8px;
    }
}