.EditorPolls {
    &-Checkboxes {
        background-color: var(--background-highlight-color);
        padding-top: 12px;
        padding-bottom: 12px;
        .HelpTooltip{
            text-decoration: none;
            margin-left: 10px;
        }
    }
    &-AnswerInput {
        border-top: 1px solid #D3D9E0;
        // border-bottom: 1px solid #D3D9E0;
    }
    .PryanikyEditor {
        border-top: 1px solid #D3D9E0;
        
    }
    &-Datepicker {
        border-top: 1px solid #D3D9E0;
        .InputLayout-WorkZone {
            padding-left: 24px;
        }
        .InputLayout-IconZone {
            .Icon {
                color: var(--text-muted-color);
            }
        }
    }
    &-AddNewAnswer {
        border-top: 1px solid #D3D9E0;
        border-bottom: 1px solid #D3D9E0;
        padding: 11px 24px 10px 24px;
        width: 100%;
        text-align: left;
        .Icon {
            margin-right: 12px;
        }
        .Icon_plus:before {
            margin-left: 0;
            margin-right: 7px;
        }
    }
}