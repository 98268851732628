.TracksHeader {
    border: 1px solid #d3d9e0;
    border-radius: 8px;
    background-color: #fff;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08);
    padding: 16px 32px;

    &-Section {
        display: flex;
        align-items: center;
    }

    &-Noop {
        background-color: #ffffff;
        flex: 1;
    }

    &-Info {
        margin-left: 24px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex: 1;
    }

    @media screen and (max-width: 768px) {
        padding: 16px;

        &-Avatar {
            width: 88px;
            height: 88px;
            margin-right: 32px;
        }

        .Avatar-Letter {
            line-height: 88px;
            font-size: 36px;
        }
    }

    &-Title {
        font-weight: bold;
        margin: 8px 0;
    }

}