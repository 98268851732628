.NewsReplyLayout {
    position: relative;
    border-bottom: 1px solid #D3D9E0;
    padding-top: 24px;
    display: flex;

    &-Aside {
        margin-left: 24px;
        @media (max-width: 576px) {
            margin-left: 12px;
        }
    }

    &-Section {
        margin-left: 12px;
        margin-right: 24px;
        flex: 1;
        overflow: hidden;
        @media (max-width: 576px) {
            margin-right: 12px;
        }
    }

    &-Header {
        display: flex;
        justify-content: space-between;

        .UserName,
        .GrupLink {
            color: #0066cc;
            font-weight: bold;
        }
    }

    &-Title {}

    &-Service {}

    &-Content {}

    &-Footer {}

    &-Additional {
        
    }

    &:last-of-type {
        border-bottom: none;
    }
    &:hover {
        .ServiceActions-Toggle {
            // display: block;
            opacity: 1;
        }
    }
}