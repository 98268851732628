.LTLerningTrackListItem {
    display: flex;
    background-color: #fff;
    padding: 12px 24px;
    border-bottom: 1px solid #d3d9e0;

    &-TypeBox {
    }

    &-Type {
        margin-right: 8px;
        color: #999999;
    }

    &-TypeValue {
        font-weight: bold;
        color: #000000;
    }

    &-Title {
        color: #0066cc;
        font-weight: bold;
    }

    &-Content {
        margin-left: 16px;
        flex: 1;
    }
    &-ActionMenu {
        .Button {
            padding: 0 4px;
        }
    }
}
