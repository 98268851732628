.NewsBannerTypeOther {
    padding: 12px;
    height: 100%;
    position: relative;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;

    &-Header {
        display: flex;
        align-items: center;
    }

    &-Bottom {
        margin-top: auto;
        margin-bottom: 0;
    }

    &-Content {
        flex: 1;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        margin-bottom: auto;
    }

    &-Title {
        box-sizing: border-box;
        overflow: hidden;
        margin-top: 8px;
        font-weight: 500;
        font-size: 14px;
        line-height: 157%;
        letter-spacing: 0.1px;
        color: rgba(0, 0, 0, 0.87);
    }

    &-Side {}

    &-Text {
        flex: 1;
        overflow: hidden;
        max-width: 100%;
        text-align: left;
        font-weight: 400;
        font-size: 12px;
        letter-spacing: 0.4px;
    }

    &-Avatar {
        margin-right: 5px;
    }

    &-DisplayName {
        font-size: 14px;
        line-height: 14px;
    }

    &-Type {
        font-size: 12px;
        line-height: 16px;
        color: #999999;
    }

    &-Action {
        padding-left: 0;
        padding-right: 0;
        font-size: 12px;
        line-height: 16px;
    }
}