.AchievementsSelector {
  // padding: 12px 24px;
  
  .Icon {
    margin-right: 20px;
  }

  &-Search {
    margin: 0 auto;
    margin-top: 8px;
  }

  &-Items {
    padding: 12px 0;
  }

  &-Item-Title {
    padding-left: 24px;
    line-height: 1.8;
    margin: 0;
  }

  &-SelectedDesc {
    border-top: 1px solid #D3D9E0;
    padding: 0 24px;
  }

  &-SelectButton {
    width: 100%;
    background-color: #ffffff;
    padding: 12px 24px;
    display: flex;
  }

  &-Goal {
    &s {
      padding: 0;
      margin: 0;
    }
    padding: 0 48px;
    cursor: pointer;
    &:focus,
    &:hover {
      background-color: var(--main-color);
      color: var(--invariant-text-color);
    }

    &_zero {
      // padding: 0;
      padding-left: 24px;
    }
    &_disabled {
      color: #D3D9E0;
    }
  }

  &-Placeholder {
    text-align: center;
  }
}
