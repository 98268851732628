.List-SortSelection {
  display: flex;
  background-color: var(--background-color);
  padding: 12px 24px;
  align-items: center;
  border-left: 1px solid var(--border-color);
  border-left: 1px solid var(--border-color);
}

.List-LeaderboardLevel {
  
}

.LeaderboardTable {
  width: 100%;
}