.NewsEdit {}

.NewsEdit-Action {
    display: flex;
    align-items: center;
    padding: 20px 0;
}

.NewsEdit-LeftSection {
    flex: 1 1;
    text-align: left;
}

.NewsEdit-RightSection {
    flex: 1 1;
    text-align: right;
}

.NewsEdit-SaveBtn,
.NewsEdit-CancleBtn {
    padding: 10px 20px;
}

.NewsEdit-CancleBtn {
    margin-left: 24px;
}