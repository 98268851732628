@import '../../utils/global.scss';

.CreateNews {
    border: 1px solid $BorderColor;
    background: $GrayBackground;
    @include BorderRadiusTop;
    @include BorderRadiusBottom;

    &-Error {
        padding: 12px;
    }

    &_modalRender {
        position: relative;

        .MuiTabScrollButton-root {
            position: absolute;
            top: 0;
            height: 100%;
            z-index: 2;
            background-color: #fff;

            &:first-child {
                left: 0;
                border-top-left-radius: 8px;
                border-bottom-left-radius: 8px;
            }

            &:last-child {
                right: 0;
                border-top-right-radius: 8px;
                border-bottom-right-radius: 8px;
            }
        }

        .CreateNews {

            &-PostName {
                display: flex;
                align-items: center;
                flex: 1;
            }
        }
    }

    &:empty {
        display: none;
    }

    .Editor {
        background-color: #fff;
    }
    .PriorityNav {
        padding: 10px 12px;

        .Dropdown {
            right: 5px;
        }
    }

    .DraftEditor-editorContainer {
        min-height: 145px;
        padding: 0 24px;
    }

    .PriorityNav-Button {
        // padding: 5px 10px;
        padding-bottom: 3px;
        margin: 5px 12px;
        border-bottom: 1px solid #afafaf;

        &:nth-child(n + 2) {
            margin-left: 12px;
        }

        &_active {
            // text-decoration: underline;
            border-bottom: 2px solid #06c;
            // padding-bottom: 4px;
            // border-bottom: 1px solid;
        }
    }
    .PriorityNav-Button::first-letter {
        text-transform: capitalize;
    }

    &_hide {
        display: none;
    }
}