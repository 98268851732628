.DistributeRender-Form {}

.DistributeRender-Distribute {
    padding: 0 12px;
}

.DistributeRender-FundInput {
    border: none;
}

.DistributeRender-Fund,
.DistributeRender-FundSize,
.DistributeRender-Remain {
    margin: 12px 0;
}

.DistributeRender-Fund {}

.DistributeRender-FundSize {}

.DistributeRender-Remain {}