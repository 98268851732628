@import 'blocks/List/List.scss';

.WikiList {
  // padding: 24px;
  // border-radius: 4px;
  // background: #FFFFFF;
  // box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12);
  // &-isGroup{
  //   margin: 0 1%
  // }
  &-CardView {
    // .MUIInfinityList-Content {
    //   display: flex;
    //   flex-wrap: wrap;
    //   justify-content: space-between;
    //   // margin: 0 12px;

    // }

    //   .Masonry-Column:not(:last-child) {
    //     margin-right: 3%;
    // }
    .MUIListPlaceholder {
      border-radius: 4px;
      background: #FFFFFF;
    }

  }

  &-FiltersColumnNoExist {
    .Masonry-Column:not(:last-child) {
      margin-right: 3%;
    }
  }

  &-FiltersColumnExist {
    .Masonry-Column:not(:last-child) {
      margin-right: 2%;
    }
  }


  &-ListView {
    border-radius: 4px;
    background: #FFFFFF;

    .MUIInfinityList-Content {
      padding: 12px 24px;
    }

    .MUIListPlaceholder {
      border-radius: 4px;
      background: #FFFFFF;
    }

    .ImagesCollage.previewer {
  
      .ImagesCollage-Item {
        cursor: pointer;
      }
    }
  }

  &-BulitsFull {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5px 0;


    .CheckboxInput {
      padding: 8px 0;
    }


  }


  &-BulitsForSearch {
    // display: flex;
    width: 100%;
    // align-items: center;

    &-WikiTypes {
      display: flex;
      align-items: center;
      font-weight: 400;
      font-size: 16px;
      line-height: 150%;
      letter-spacing: 0.15px;

      &-Lable {
        // font-weight: 400;
        // font-size: 16px;
        // line-height: 150%;
        // letter-spacing: 0.15px;
        margin-right: 10px;
      }
    }

    &-SortFilters {
      display: flex;


      &-Item {
        margin-right: 14px;
        font-weight: 500;
        font-size: 16px;
        line-height: 150%;
        letter-spacing: 0.15px;
        display: flex;
        white-space: nowrap;
        align-items: center;
        cursor: pointer;
      }
    }
  }

}