.CurrencyTimeline {
  &-Filters {
    display: flex;
    align-items: center;
    padding: 6px 24px;
    margin-bottom: 24px;
    & > span {
      font-weight: bold;
      margin-right: 16px;
    }
    & > div {
      margin-right: 24px;
      label span {
        font-size: 14px;
      }
    }
  }
}