.LMSTOStatFullSummaryInfo {
   display: flex;
   margin-top: 12px;
   // padding: 24px;

   &-Percent {
      color: #979797;
      font-size: 24px;
      vertical-align: text-top;
      line-height: 48px;
   }

   &-Title {
      padding-left: 8px;
      padding-right: 8px;
      white-space: nowrap;
   }

   &-ValuesBox {
      display: flex;
      justify-content: space-around;
      flex: 1;
      align-items: center;
      text-align: center;
   }

   &-HelpIcon {
          vertical-align: bottom;
   }

   &-Higthlight {
      color: #89CA5E;
      vertical-align: text-top;
   }

   &-Chart {
      width: 146px;
      height: 146px;
   }

   &-ChartIcon {
      color: #89CA5E;
      font-size: 38px;
   }

   &-Value {
      font-size: 48px;
      line-height: 60px;
      padding-left: 8px;
      padding-right: 8px;
   }
}