.LMSScormUnit {
    margin-top: 8px;

    &-Frame {
        // width: 100%;
        // height: 100%;
    }
}

.LMSScormUnitLoader_fullscreen {
    height: 100%;
}

.LMSScormUnit_fullscreen {
    /*77px кнопки снизу - 25px кнока поного экрана - 8px отступ сверху*/
    height: calc(100% - 85px);
    margin-bottom: 77px;
}