.CourseTest {
  background-color: var(--background-color);
  border-radius: 8px;
  // border: 1px solid var(--border-color);
  // padding: 24px;

  &_fullscreen {
    height: 100%;
    overflow: auto;
  }

  &-UploadBtn {
    margin-top: 24px;
  }

  &-Attachment {
    padding: 0;
  }

  &-FileSection {
    margin-top: 24px;
  }

  &-Choose {
    margin: 0;
    padding: 0 24px;
  }
  &-TooltipContent {
    min-width: 250px;
  }
  &-QuestionTitle {
    display: flex;
  }
  &-QuestionHeader {
    margin-left: 24px;
    font-weight: bold;
  }
  &-Header {
    // background-color: #fff3bd;
    // border-radius: 8px;
    // padding: 20px 32px;
    margin: 0 24px;
    // min-height: 236px;
    // display: flex;
    // flex-direction: column;
    // justify-content: space-between;
  }
  &-QuestionCount {
    font-size: 18px;
    line-height: 20px;
    letter-spacing: -0.3px;
  }
  &-Question {
    margin: 0;
  }
  &-Spin {
    padding-bottom: 20px;
  }
  &-Question {
    font-weight: bold;
  }
  &-Timer {
    padding: 24px;
  }
  &-TimerTitle {
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
  }
  &-Time {
  }
  &-Input {
    position: absolute;
    opacity: 0;
    z-index: -1;
  }
  &-Answers {
    padding: 32px 0 14px;
  }
  &-Label {
    display: flex;
    align-items: flex-start;
    padding: 0 24px;
    font-size: 15px;
    margin: 18px 0;
    cursor: pointer;
    &_highlight_right {
      background-color: #00ff00;
    }
    &_highlight_wrong {
      background-color: #ff0000;
    }
    &_highlight_achtung {
      background-color:  #ffee00;
    }
  }
  &-AnswerText {
    margin-top: 10px;
  }
  &-Checkbox {
    vertical-align: middle;
    font-size: 26px;
    color: var(--border-color);
    &_selected {
      color: var(--main-color);
    }
    &::after {
      content: "\00a0";
    }
  }
  &-Controls {
    border-top: 1px solid var(--border-color);
    padding: 24px;
    display: flex;
    justify-content: space-between;
  }
  &-Control {
    min-width: 132px;
    border-radius: 8px;
    padding-left: 12px;
    padding-right: 12px;
    font-size: 14px;
    line-height: 19px;
    /*margin-left: 32px;

    &:first-child {
      margin-left: 0;
    }*/
  }

  &-TextareaBox {
    margin: 0 24px;
    max-width: 100%;
  }

  &-Textarea {
    max-width: 100%;
    box-sizing: border-box;
    min-width: 100%;
    min-height: 150px;
    resize: vertical;
    padding: 10px 16px;
    border: 1px solid #94a4b5;
  }
}
