.CalendarSkeleton {
  position: absolute;
  bottom: -8px;
  z-index: 20;
  width: 100%;
  height: calc(100% - 55px);
  background-color: var(--background-color);
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}

@media (max-width: 1106px) {
  .CalendarSkeleton {
    height: calc(100% - 60px);
  }
}

@media (max-width: 681px) {
  .CalendarSkeleton {
    height: calc(100% - 57px);
  }
}