.LMSTOStatGame {
     &-Top {}

     &-Content {
         display: flex;
         margin-top: 24px;
         align-items: flex-start;
     }

     &-Aside {
         background-color: #fff;
         flex: 1 4;
         border-radius: 8px;
         overflow: hidden;
         border: 1px solid #d3d9e0;
         margin-right: 24px;
     }

     &-Section {
         flex: 3 4;
         max-width: 100%;
     }

     &-Summary {
         margin-bottom: 24px;
     }
}
