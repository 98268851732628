.SurveyWidget {
  &-Survey {
    &-Loading {
      height: 225px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 24px 24px 24px;
    }
  }

  &-EmptyItem {
    padding: 24px
  }

  &-Navigation {
    display: flex;
    // height: 100%;
    align-items: center;
    // margin-right: 8px;
    min-width: 24px;
  }

  // &-Button {
  //   width: 24px;
  //   padding: 0px!important;
  // }

  &-Indicator {
    min-width: 25px;
    text-align: center;
    // padding: 0 4px;
    // padding-bottom: 2px;
    font-weight: 400;
    font-size: 12px;
    // line-height: 159%;;
    line-height: 166%;
    // // height: 17px;

    span {
      color: #999;
    }
  }

  &-Header {
    // padding: 24px;
    margin: 0 0 8px 0;
    display: flex;
    justify-content: space-between;

    &-Title {
      font-size: 16px;
      font-weight: 700;
    }

    // &-Total {
    //   font-size: 12px;
    //   display: flex;
    //   align-items: center;


    //   &-Length {
    //     color: rgba(0, 0, 0, 0.6);

    //   }
    // }
  }



  &-Quiz {

    // border-bottom: 1px solid #D3D9E0;
    &-NoData {
      margin: 0px 24px 24px 24px;
      display: block;
    }
  }

  &-Navigate {
    font-size: 14px;
    display: flex;
    height: 23px;

    &-Item {
      cursor: pointer;
      color: #d3d9e0;
      height: 23px;
    }
  }

}