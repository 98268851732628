.LMSCourseTabs {
    padding-top: 24px;
    padding-bottom: 24px;

    // border-bottom: 1px solid #d3d9e0;
    &-TabBtn {
        font-size: 20px;
        margin-left: 24px;
        margin-right: 24px;
        border-bottom: 1px solid #979797;
        padding-bottom: 1px;
        line-height: normal;

        &_selected {
            padding-bottom: 0;
            border-bottom: 2px solid #0066CC;
        }
    }
}