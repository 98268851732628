.NewsEdit-Body_type_competitions {}

.NewsEdit-Body_type_competitions-Input-Title {}

.NewsEdit-Body_type_competitions-Input-Text {
    border-top: 1px solid #D3D9E0;
}

.NewsEdit-Body_type_competitions-ExpiresBox {
    border-top: 1px solid #D3D9E0;
}