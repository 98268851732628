.LMSTOStatUsersSummary {
    background-color: #fff;
    border: 1px solid #D3D9E0;
    border-radius: 8px;

    &-Information {
        padding: 24px;
    }

    &-Settings {

    }
}