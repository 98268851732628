.LeftMenuBarMui {

    &:not(.LeftMenuBarMui_stayOpened):not(.LeftMenuBarMui_opened) {

        .LeftMenuMuiSearch {

            .MuiFormLabel-root {
                display: none;
            }

            .MuiInputAdornment-root {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translateX(-50%);
            }
        }
    }
    // &:not(.LeftMenuBarMui_opened) {}
}