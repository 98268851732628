.LMSTaskSelectorItem {
    display: flex;
    padding: 6px 12px;
    overflow: hidden;
    align-items: center;
    cursor: pointer;
    width: auto;
    transition: all 0.2s ease-in-out;

    &:hover {
        color: #fff;
        background-color: #0066cc;

        .LMSTaskSelectorItem-StatusIcon {
            color: #fff;
        }
    }

    &_active {
        color: #fff;
        background-color: #6fb19d;

        .LMSTaskSelectorItem-StatusIcon {
            color: #fff;
        }
    }

    &-TypeIcon {
        margin-right: 12px;

        .Avatar-Img {
            background-size: contain;
            background-color: transparent;
        }
    }

    &-Status {
        margin-left: auto;
    }

    &-StatusIcon {
        font-size: 18px;
        background-size: contain;

        &_color_check {
            color: #89ca5e;
        }

        &_color_times {
            color: #f7434c;
        }

        &_color_clock {
            color: gray;
        }
    }

    &-Title {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        text-decoration: none;
    }

    &-Content {
        margin-right: 12px;
        overflow: hidden;
    }

    &-UnitType {
        color: #ccc;
        font-size: 12px;
    }
}