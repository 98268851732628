@import '../../utils/global.scss';

.PwdReset {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow-x: hidden;

  &-Wrapper {
    position: absolute;
    display: block;
    max-width: 600px;
    top: 50%;
    left: 50%;
    margin-left: -300px;
    min-height: 260px;
    // margin-top: -130px;
    width: 100%;
    transform: translateY(-50%);
  }

  &-ErrorMessage {
    position: absolute;
    bottom: 10px;
    left: 50%;
    background: $ErrorColor;
    color: white;
    padding: 2%;
    transform: translateX(-50%);
    width: 100%;
    font-family: 'Open Sans regular';

    &::after {
      content: '';
      display: block;
      position: absolute;
      bottom: -9px;
      margin-left: 2%;
      border-width: 10px 10px 0px 10px;
      border-style: solid;
      border-color: transparent;
      border-top-color: $ErrorColor;
    }
  }

  &-Form {
    position: relative;
    z-index: 2;
    background-color: $GrayBackground;
    box-sizing: border-box;
    border: 1px solid $BorderColor;

    .Input,
    .Checkbox,
    &-Actions {
      border-top: 1px solid $BorderColor;
    }

    &-Text {
      padding: 15px 15px;
      position: relative;
    }

    &-Actions {
      direction: rtl;
      padding: 15px;

      .Button {
        padding: 10px 4% 11px;
      }
    }
  }

  &-Spinner {
  }
}

@media screen and (max-width: 600px) {
  .Forgot {
    .Banana {
      transform: scale(-1, 1);
      right: -120px;
    }

    &-Wrapper {
      left: 0;
      margin-left: 0;
    }
  }
}