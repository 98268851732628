.WidgetAddDialog {
  &-Title {
    align-items: flex-start !important;
  }

  &-HelpTooltip {
    position: absolute;
    right: 50px;
    top: 11px;
  }

  &-Search {
    max-width: 500px;
    margin: 15px auto 0 auto;
  }

  &-Icon {
    font-size: 20px;
    color: #999999;
  }

  // &-Settings {
  //   .InputMention {
  //     margin: 10px 24px 0 0;
  //   }
  //   .CheckboxInput {
  //     margin-left: 9px;
  //   }
  //   .Radio {
  //     padding: 5px 0 5px 33px;
  //   }
  //   .JFLayout {
  //     .SliderItem-Button_add {
  //       width: 93%;
  //       margin: 0 24px 10px;
  //     }
  //     .SliderItem-Button_remove {
  //       position: absolute;
  //       top: 12px;
  //       right: 24px;
  //     }
  //   }
  // }
  &-Content {
    margin: 0px 24px 24px 24px;
  }

  &-Selector {

    margin: 16px 0;

    // white-space: nowrap;
    // overflow-x: auto;
    // display: flex;
    // flex-wrap: wrap;
    .MuiButton-textPrimary:hover {
      background: rgba(0, 0, 0, 0.04)!important;
      border-radius: 0px!important;
    }

    .MuiButtonBase-root.MuiButton-root {
      padding: 8px 16px!important;
    }

    &-Item {
      // height: 100px;
      // width: 150px;
      // flex-basis: 25%;
      // max-width: 25%;
      // height: 100px;
      // box-sizing: border-box;
      flex-basis: 100%;
      max-width: 100%;
      height: auto;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      padding: 9px 8px 9px 16px;

      &-Content {
        display: flex;
        width: 100%;

        .Avatar {
          height: 40px;
          margin-right: 12px;
        }

        .Avatar-Img {
          height: 40px;
          border: 1px solid rgba(0, 0, 0, 0.23);
          border-radius: 4px;
        }
      }

      &-Title {
        font-weight: 500;
        font-size: 14px;
        line-height: 157%;
        letter-spacing: 0.1px;
        color: rgba(0, 0, 0, 0.87);
        text-transform: none;
        text-align: left;

      }

      &-Description {
        font-weight: 400;
        font-size: 12px;
        line-height: 166%;
        letter-spacing: 0.4px;
        color: rgba(0, 0, 0, 0.87);
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: 420px;
        text-transform: none;
        text-align: left;

      }
    }

    &-Item:hover {
      background-color: #06c;
      color: #fff;
    }

    .MuiAccordion-root.AccordionDetail {
      padding: 0;
    }
  }

  &-Back {
    margin-right: 10px;
  }

  &-Body {
    position: relative;
  }
}