@import '../../List.scss';

.List_type_groups_tree {

  .List-Item-DataPart:nth-of-type(n + 2) {
    flex: 1;
    width: calc(60% - 36px);
    min-width: auto;
  }

  .List-Item_group-Button {

    &_subgroups {
      margin-right: 30px;
      opacity: 1 !important;
    }
  }

  .List-Item:not(:hover) {

    .List-Item_group-Button {

      &_subgroups {
        border-color: transparent;
        color: var(--main-color);
      }
    }
  }
}