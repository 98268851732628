.TOCEUnitsItem {
    // position: relative;
    &-Toolbar {
        position: sticky;
        top: 56px;
        background-color: #fff;
        display: flex;
        justify-content: space-between;
        padding: 8px;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        border-bottom: 1px solid #d3d9e0;
        z-index: 80;
        align-items: flex-start;
    }

    &-Btn {
        &_type_delete {
            background-color: red;
        }

        &_type_save {

        }
    }
}
