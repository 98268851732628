@import '../../../../utils/global.scss';

.LeftMenuItem {

  &-Name {
    // flex: 1;
    margin-left: 16px;
    color: inherit;
  }

  &-Label {
    padding: 15px 15px 15px 28px;
    display: flex;
    align-items: center;
    text-decoration: none;

    &.Button {
      color: inherit;
    }

    &_bt_user {
      padding-left: 15px;
    }

    &_rt_dropdown {

      .Icon {
        transition: transform .1s $TransitionTimingFunction;

        &.Chevron {
          // position: absolute;
          right: 15px;
          order: 100;
        }

        &.expanded {
          transform: rotate(-90deg);
        }
      }
    }

    &_rt_link {
      // padding-right: 15px;

      .LeftMenuItem-Name {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }

  &-System {
    display: flex;
    margin-left: 8px;
    flex: 1;
  }

  &-NotificationDot {
    border-radius: 50%;
    height: 7px;
    width: 7px;
    background: #c11a1a;
    margin-bottom: 10px;
  }
}