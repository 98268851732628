.EditActions {
    display: flex;
    padding: 0 12px;
    align-items: center;
    @media (max-width: 500px) {
        display: block;
        text-align: center;
    }

    .Icon {
        line-height: 26px;
        font-size: 26px;
        color: #768aa1;
    }

    &-EditActions {
        flex: 1;
        .Button {
            width: 60px;
            height: 50px;
            text-align: center;
        }
    }

    &-SendActions {
        margin-right: 12px;
    }

    &-EditButton {
        // margin-left: 12px;
        &_isOpenGroups {
            background-color: #ccc;
            border-radius: 8px;
        }

    }
    &-Helptooltip{
        text-decoration: none;
    }
    &-SelectGroup {
        width: 350px;
        margin: 12px;
    }
    &-Active {
        &.Icon {
            font-weight: bold;
            color: #000;
        }
    }
}