.Invite-OptionalFields {

  overflow: hidden;
  // height: 650px;
  
  &_enter {
    height: 0;
  }
  
  &_entering {
    // height: 650px;
    transition: height .5s ease-out;
  }

  &_exit {
    // height: 650px;
  }

  &_exiting {
    height: 0;
    transition: height .5s ease-out;
  }
}