@import '../../../../utils/global.scss';

.LMSLTItemView {
    background-color: #fff;
    @include BorderRadius;
    border: 1px solid #d3d9e0;
    // box-shadow: 0px 0px 10px -5px rgba(0, 0, 0, 0.3);
    position: relative;
    padding-top: 24px;

    &-isShowOnTop {
        // position: absolute;
        // left: 0;
        // top: 0;
        // text-align: right;
        // z-index: 20;
    }

    &_actionHeader {
        padding-top: 0px;
    }

    &-isNeedApprove {}

    &-UserImg {
        display: block;
    }

    &-Attachments {
        &:empty {
            display: none;
        }
    }

    .NewsView-Preview {
        margin-top: 15px;

        &:empty {
            display: none;
        }
    }

    .horizontalPadding {
        padding-left: 12px;
        padding-right: 12px;

        @media (min-width: 576px) {
            padding-left: 24px;
            padding-right: 24px;
        }
    }

    .horizontalMargin {
        margin-left: 12px;
        margin-right: 12px;

        @media (min-width: 576px) {
            margin-left: 24px;
            margin-right: 24px;
        }
    }


    &:hover {
        .NewsView-ServiceActions {
            display: block;
        }
    }
}
