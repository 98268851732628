.Orgchart{
    &-Stub{
        background-color: #ffffff;
        width: 99.3%;
        height: 352px;
        border-radius: 8px;
        text-align: center;
        padding-top: 40px;
        margin: 24px 12px;
    }
    &-Header{
        background: #fff;
        // display: flex;
        margin: 10px 10px 0 10px;
        border-radius: 8px;
        padding: 10px 20px;
        // justify-content: space-between;
        &-BTnAddUnit{
            display: flex;
            align-items: center;
        }
        &-Search{
                // width: 80%;
        // .MuiFormControl-root{
        //         width:100%;
        //     } 
        }
    }

    &-Orgchart{
        display: flex;
        flex-direction: column;
        justify-content: left;
        
        // .List-Header_type_common{
        //     background-color: #f7f7f7;
        //     margin: 10px 5px 0 10px;
        //     padding: 10px 24px;
        //     .SearchInput {
        //         max-width: 70%;
        //     }
        // }
        .orgchart-container{ 
            // min-width: 99%;
            background-color: #f7f7f7;
            .orgchart {
                box-sizing: border-box;
                display: inline-block;
                background-image: none;
                background-color: #F7F7F7;
                background-size: 10px 10px;
                border: 1px dashed rgba(0,0,0,0);
                padding: 20px 20px 0 20px;
            }
        }
    
        .orgchart ul li .oc-node {        
            margin: 25px;
        }
        .orgchart ul li .oc-node::before {    
            background-color: #b2afaf;
            top: -37px;    
            height: 37px;
        }
        .orgchart ul li::before {
            border-top: 2px solid #b2afaf;
        }
        .orgchart ul li .oc-node:not(:only-child)::after {
            background-color:#b2afaf;
            bottom: -17px;
            height: 17px;
        }

        &_view_horizontal {

            .orgchart ul {
                display: block;

                li {
                    display: flex;
                    align-items: center;
                    
                    .oc-node {

                        &::before {
                            height: 2px;
                            width: 37px;
                            top: calc(50% - 1px);
                            left: -37px;
                        }

                        &:not(:only-child)::after {
                            height: 2px;
                            width: 17px;
                            bottom: auto;
                            top: calc(50% - 1px);
                            left: auto;
                            right: -16px;
                        }
                    }

                    &:before {
                        top: 0;
                        left: -11px;
                        width: auto;
                        height: 100%;
                        border-left: 2px solid #b2afaf;
                    }

                    &:first-child::before{
                        left: -11px;
                        width: auto;
                        top: calc(50% - 1px);
                        height: calc(50% + 1px);
                    }

                    &:last-child::before {
                        width: auto;
                        height: calc(50% + 1px);
                    }

                    &:only-child::before {
                        height: 2px;
                    }
                }
            }
        }

        &_view_tree {

            .orgchart-container {
                text-align: center;
            }

            .orgchart ul {
                display: block;
                text-align: left;

                & > li > ul {
                    position: relative;
                    // left: 50%;
                    margin-left: calc(162.5px + 25px);
                }
                
                li {
                    display: block;
                    
                    .oc-node,
                    &.isSiblingsCollapsed .oc-node {

                        &::before {
                            top: 19px;
                            height: 2px;
                            left: -25px;
                            width: 25px;
                        }

                        &:not(:only-child)::after {
                            left: 50%;
                            height: 26px;
                            bottom: -26px;
                        }
                    }

                    &.isSiblingsCollapsed::before {
                        content: '';
                    }

                    &::before {
                        border-top: 0;
                        border-left: 2px solid #b2afaf;
                        top: 0;
                        left: 0;
                        width: auto;
                        height: 100%;
                    }

                    &:first-child::before {
                        left: 0;
                        width: auto;
                    }

                    &:last-child::before {
                        width: auto;
                        height: 46px;
                    }
                }
            }
        }
    }  

    &-ChartWrapper {
        position: relative;
        margin: 10px;

        .OrcContainer {
            cursor: grab;
            padding-top: 56px;

            &.grabbing {
                cursor: grabbing;
            }

            .OrcChart {
                cursor: inherit !important;
                padding: 0px;
            }
        }

        .orgchart-container {
            margin: 0px;
        }
    }

    &-ChartMenu {
        position: absolute;
        margin: 30px;
        z-index: 10;
        top: 0;
        right: 0;
        // left: 0;
        display: flex;

        > *:nth-child(n + 2) {
            margin-left: 16px;
        }

        .MuiButton-root {
            background: #fff;

            .Icon_sitemap:before {
                margin: 0px 3px 0 0;
            }
        }

        .MuiButton-root:hover {
            background-color: #f7f7f7;
        }
    }

    &-FileProgress {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        z-index: 50;
        background: rgba(0, 0, 0, .75);
        display: flex;
        justify-content: center;
        align-items: center;

        .Icon {
            color: #fff;
            animation: spin 1s infinite;
            font-size: 34px;
        }
    }

    &-ViewSelector {

        .MuiSelect-root {
            padding: 8px 32px 8px 8px;
            background: #fff;
        }
    }

    &-ViewIcon {

        &_horizontal {
            transform: rotate(-90deg);
            display: block;

            &:before {
                display: inline !important;
            }
        }
    }
}
