@import '../../List.scss';

.List_type_groups {

  .List-Item { 

    .List-Item_group-Button_action {
      padding: 0 4px;
    }
  }
  
}

.Group-Label {
  color: #999;
}