.SliderLink{
  &-Buttons {
    button {
      width: 50px;
      padding: 4px;
      background-color: #000;
      color: #fff;
      opacity: .8;
    }
  }
}
  


