@import "../../../utils/global.scss";

.ChangePassword {
  // background-color: #fff;
  min-height: 145px;
  padding:8px 24px!important;

  &-Body {
    position: relative;
    overflow-y: hidden; /* Скрываем вертикальный scrollbar */
    & > *:nth-child(n + 2) {
      margin-top: 16px;
    }
  }
  
  &-Body   &-Strength {
    transition: margin-top $TransitionDuration $TransitionTimingFunction,
      font-size $TransitionDuration $TransitionTimingFunction;
    margin-top: 0px;
    font-size: 0px;

    .InfoIcon {
      transition: font-size $TransitionDuration $TransitionTimingFunction;
      font-size: 0px;
    }

    &-Info {
      display: flex;
      align-items: center;
    }

    &Progress {
      &_strength {
        &_null .CourseProgress-Item {
          background-color: #f00;
        }

        &_min .CourseProgress-Item_complite {
          background-color: #f00;
        }

        &_medium .CourseProgress-Item_complite {
          background-color: #ff0;
        }
      }

      .CourseProgress-Item {
        transition: height $TransitionDuration $TransitionTimingFunction;
        height: 0px;
      }

      .CourseProgress-Value {
        display: none;
      }
    }

    &_view {
      margin-top: 16px;
      font-size: 14px;

      .ChangePassword-StrengthProgress .CourseProgress-Item {
        height: 8px;
      }

      .InfoIcon {
        font-size: 1.5rem;
      }
    }
  }
}
