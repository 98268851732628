.TVHeader {
    background-color: #fff;
    padding: 12px 24px 0px 24px;
    border: 1px solid #d3d9e0;
    border-radius: 8px;

    &-Section {
    }

    &-Avatar {
        margin-right: 24px;
    }

    &-Content {
        display: flex;
        align-items: center;
    }

    &-Categories {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        min-height: 57px;
        padding: 0 24px;
        border-top: 1px solid #d3d9e0;

        & > span {
            font-weight: bold;
            margin-right: 6px;
        }

        & > label {
            width: auto;
            padding: 8px;

            & > svg {
                margin-right: 6px;
            }
        }
    }
}
