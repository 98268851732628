@import "../../utils/global.scss";

.TimePicker2 {
    position: relative;
    padding: 0 24px;
    margin: 1px 0;

    &-BottomGroup,
    &-TopGroup {
        position: absolute;
        z-index: 1;
        transition: all 0.1s linear;
        left: 62px;
    }

    &-TopGroup {
        top: 0;
    }

    &-BottomGroup {
        bottom: 0;
    }

    &-InputBox {
        background: #fff;
        z-index: 2;
        position: relative;
        display: flex;
        align-items: center;
    }

    &-Input {
        appearance: none;
        padding: 10px 18px;
        border: none;
        font-family: "Open Sans", Arial, Helvetica, sans-serif;
        -moz-appearance: textfield;

        &::-webkit-inner-spin-button,
        &::-webkit-outer-spin-button {
            -webkit-appearance: none;
        }
    }

    &-IconClock {
        font-size: 16px;
    }

    &_isFocus {
        outline: 1px solid $SystemBlue;

        .TimePicker2-TopGroup {
            top: -21px;
        }

        .TimePicker2-BottomGroup {
            bottom: -21px;
        }
    }
}
