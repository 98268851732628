.WikiCreateDialogNew {
  background-color: #fff;

  &-Content {
    // padding: 0 24px;

    &-Item {
      margin: 8px 0 0 0;
    }
  }

  .TooltipMargin {
    .HelpTooltip-Content {
      overflow: hidden;
      text-overflow: ellipsis;
      background: aliceblue;
      padding: 10px 5px;
      border-bottom: 2px solid #99c7ef;
      border-top: 2px solid #99c7ef;
    }
  }

  .InputMention:focus-within {
    width: 100%;
  }

  &-CheckboxInput {
    font-size: 16px;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    margin: 5px 24px 0 24px;
  }

  &-ViewType {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 24px;
  }

  &-PositionInCollection {
    margin: 8px 0 0 0;

    .InputMUI {
      width: 100%;
    }
  }

  &-Type.DropdownMUI {
    margin: 10px 24px;
    width: 92%;
  }

  &-InputFolder {
    display: flex;
    align-items: center;
    margin: 8px 0 0 0;

    .TooltipMargin {
      margin-left: 10px;
    }

    .MuiAutocomplete-root.MuiAutocomplete-hasClearIcon.MuiAutocomplete-hasPopupIcon {
      width: 100%;
    }

    &-Folder {
      width: 100%;
      padding: 0px;

      .Icon {
        margin-left: 8px;
        color: #757575;
      }
    }
  }

  &-FileInput {
    margin: 10px 24px;
    padding: 0px;
  }

  &-Input {
    border: 1px solid #ccc;
    margin: 10px 24px;
    padding: 0px;

    .Input-Input {
      background: #fff;
      font-size: 16px !important;
      font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
      // margin: 0 0 0 47px;
    }

    .Icon {
      margin-left: 8px;
    }
  }

  &-notImportant {
    color: #0066cc;
    margin: 24px;
  }

  // &-child {
  //   position: relative;
  //   left: -456px;
  //   margin: 0;
  //   color: #757575;
  // }
  &-Create {
    margin: 0 0 0 10px !important;

    .Loading {
      width: 10rem;
      white-space: nowrap;
    }
  }

  &-Create:disabled {
    margin: 0 0 0 10px;
  }

  &-InputLink {
    border: 1px solid #ccc;
    margin: 10px 24px;
    padding: 0px;
    // .Input-Input {
    //   background: #fff;
    //   font-size: 16px!important;
    //   font-family: "Roboto", "Helvetica", "Arial", sans-serif!important;
    //   // margin: 0 0 0 47px;
    // }
    // .Icon {
    //   margin-left: 8px;
    // }
  }

  &-ErrorLink {
    border: 1px solid red;
    margin: 10px 24px;
    padding: 0px;
    // .Input-Input {
    //   background: #fff;
    //   font-size: 16px!important;
    //   font-family: "Roboto", "Helvetica", "Arial", sans-serif!important;
    //   // margin: 0 0 0 47px;
    // }
    // .Icon {
    //   margin-left: 8px;
    // }
  }

  &-AvatarLoader {
    margin: 10px 24px;
  }

  &-Radio {
    display: block !important;
    // flex: 1 1 100%!important;
    margin: 24px !important;
  }

  &-Sex {
    width: 100%;
  }
}

@media all and (-ms-high-contrast: none),
(-ms-high-contrast: active) {

  /* IE10+ CSS styles go here */
  .WikiCreateDialogNew {
    &-child {
      top: 4px;
    }
  }
}