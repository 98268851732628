.Quiz {
  margin-top: 24px;

  &-Header {
    &-Title {
      padding: 24px;
      display: flex;
      flex-direction: column;

      &-Name {
        font-size: 14px;
        font-weight: bold;
      }

      &-Date {
        font-size: 12px;
        font-weight: normal;
      }
    }

    &-Background {
      height: 155px;
      position: relative;
    }

    &-Question {
      font-size: 24px;
      font-weight: normal;
    }
  }

  &-Body {
    padding: 0 24px;
  }

}

