.Search {
  &-Wrapper {
    display: flex;
    justify-content: space-between;
  }

  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  &-WithFilter {
    width: 70%;
  }

  &-ListUsers {
    background-color: #fff;
    padding-top: 8px;
    border: 1px solid var(--border-color);
    border-radius: 8px;
  }

  &-ListGroups {
    background-color: #fff;
    padding-top: 8px;
    border: 1px solid var(--border-color);
    border-radius: 8px;
  }

  &-ListNews {
    .Timeline-Placeholder {
      margin-top: 0;
    }
  }

  &-Header {
    width: 100%;
    background-color: #fff;
    box-sizing: border-box;
    padding: 24px;
    margin-top: 24px;
  }

  &-RequestTitle {
    display: flex;
    flex-direction: column;
    align-items: center;

    &-Text {
      font-size: 16px;
      font-weight: 800;
      margin-bottom: 10px;

    }

    &-Query {
      font-size: 22px;
      background-color: #fef002;
      font-weight: 500;
    }
  }

  &-Tabs {
    display: flex;
    justify-content: center;
    margin-top: 20px;

    .Button {
      text-transform: capitalize;
    }
  }

  &-Results {

    margin-top: 24px;
    width: 100%;

    // max-width: 806px;
    .ListLite {
      border: 1px solid var(--border-color);
    }

  }

  &-Filter {
    width: 28%;
    height: 100%;
  }
}