.NewsEditor {
    position: relative;
    background-color: #fff;

    &-Actions {
        background-color: #f7f7f7;
        display: flex;
        align-items: center;
        padding-left: 24px;
        padding-right: 24px;
    }

    &-FormActions {
        flex: 1;
    }
}
