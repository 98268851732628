.TasksEditor {
  position: relative;
  
  > * {
    border-top: 1px solid #D3D9E0;
  }

  > *:last-child {
    border-bottom: 1px solid #D3D9E0;
  }

  .Button_requireAttach,
  .Button_requireComment,
  .Button_removeTask {
    padding: 6px;
    border-radius: 4px;
  }

  &-AddNewTask {
    padding: 10px 20px;
    width: 100%;

    .Icon {
      margin-right: 15px;
    }
  }

  &-FormSelector {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 0px;
    overflow: hidden;
    transition: .15s height ease-in-out;
    border: 0;
    display: flex;
    width: 100%;
    
    &_inited {
      height: 47px;
    }

    &-Button {
      flex-basis: 50%;
    }
  }
}