.CalendarModalEvents {
  padding: 0 24px;
  &-Item {
    display: flex;
    margin: 12px 0;
    height: 36px;
    border: 2px solid;
    border-radius: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 3px 20px;
    color: #fff;
    font-size: 16px;
    text-align: left;
    &:visited {
      color: #fff;
    }
    &:focus {
      color: #fff;
    }

    &-Time {
      margin-right: 15px;
      width: 45px; 
    
    }
    
  }
  
}