.NewsView-TopBadge {
    display: flex;
    justify-content: center;
    font-size: 24px;
    color: #666666;
    position: relative;
}

.PostTitleIcon-ValueText {
    font-weight: bold;
    margin: 0 8.5px;
    width: 110px;
    height: 110px;
}

.PostTitleIcon-ValueContent {
    text-align: center;
    color: #000;
}

.PostTitleIcon-ImgBox {
    background-repeat: no-repeat;
    background-position: top center;
    height: 100%;
    width: 100%;
    background-size: contain;
}

.PostTitleIcon-RightText {
    display: block;
    flex: 1 1;
    text-align: left;
    width: 100%;
}
.PostTitleIcon-LeftText {
    display: block;
    flex: 1 1;
    text-align: right;
    width: 100%;
}
