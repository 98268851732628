.Mood {
  padding: 24px;
  // & > div {
  //   padding: 12px 24px;
  //   // border-bottom: 1px solid #d7d7d7;
  // }

  &-Title {
    font-size: 16px;
    margin-bottom: 13px;
    line-height: 116%;

    & span {
      &:first-child {
        font-weight: bold;
      }
    }
  }

  &-Mark {
    text-align: left;
    background-color: #F5F5F5;
    border-radius: 4px;
    padding: 12px 8px;

    &-Slider {
      // display: flex;
      // align-items: center;
      // height: 30px;

      // &>span {
      //   color: #89CA5E;
      padding-left: 6px;
      padding-right: 6px;

      .MuiSlider-thumb.Mui-disabled {
        margin-top: -5px;

      }

      .MuiSlider-root {
        color: #0066CC;
      }

      .MuiSlider-mark {
        top: 14px;
      }

      .makeStyles-root-8.Mui-disabled>.MuiSlider-rail {
        background-color: #D7D7D7;
      
    }

    // backgroundColor: '#D7D7D7',
    // }
  }

  &-StubBox {
    display: flex;
    align-items: center;
  }

  &-Stub {
    height: 36px;
    margin-right: 10px;
  }

  &-Number {
    display: inline-block;
    font-weight: bold;
    font-size: 24px;
    line-height: 1;
    min-width: 25px;
    margin-left: 24px;
  }

  &-View {
    display: flex;
    align-items: center;

    img {
      width: 36px;
      height: 36px;
    }

    span {
      color: #000;
      margin-left: 12px;
    }

  }
}

&-Send {
  .MuiButtonBase-root {
    margin-top: 20px;
    text-transform: uppercase;
  }

  // &>button {
  //   width: 100%;
  // }
}


}