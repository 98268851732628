.LMSTOStatFullUser {
    &-Profile {
        margin-top: 24px;
        background-color: #ffffff;
        border-radius: 8px;
        border: 1px solid #D3D9E0;
    }

    &-UserData {
        margin-right: 12px;
        max-width: 200px;
        overflow: hidden;
    }

    &-Info {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        padding: 24px;
    }

    &-SummaryInfo {
        flex: 1;
    }

    &-Courses {
        margin-top: 24px;
    }

    &-UserData {
        margin-left: 16px;
    }

    &-DisplayName {
        font-weight: bold;
        font-size: 14px;
        line-height: 24px;
        margin-bottom: 6px;
    }
}