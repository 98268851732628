.TOSessionsList {
    background-color: #fff;
    margin-top: 24px;
    border: 1px solid #D3D9E0;
    border-radius: 8px;

    &-Header {
        display: flex;
        background: #F7F7F7;
        align-items: center;
        padding: 24px;
        border-bottom: 1px solid #D3D9E0;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
    }

    &-Search {
        margin-left: 44px;
        flex: 1 1;
    }

    &-Trigger {
        display: block;
        margin-left: auto;
        margin-right: auto;
    }

    &-Tabs {
        border-bottom: 1px solid #D3D9E0;
    }

    &-List {
        padding-bottom: 12px;
    }

    &-CreateSession {}

    &-Item {
        border-bottom: 1px solid #D3D9E0;
    }
}