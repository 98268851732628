@import 'utils/src/global.scss';

.NetworkItem {
    display: flex;
    justify-content: space-between;
    padding: $defaultPadding;
    border-bottom: 1px solid $BorderColor;
    &-Title {
      display: flex;
      align-items: center;
      span {
        font-size: 26px;
      }
    }
    &-Settings {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

.NetworkItem-Settings-Dropdown {
  button {
    min-width: 180px;
    height: 35px;
    display: flex;  
    justify-content: center;
    i {
      margin-right: unset;
    }
  }
}