.PrnSelect {
  &-Suggester {
    top: 0;
    left: 0;
  }

  &-Placeholder {
    padding: 5px 2px;
    color: hsl(0, 0%, 50%);
  }

  &-InputBox {
    flex: 1 1;
  }

  &-WorkElements {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  &-Input {
    width: 100%;
  }

  &-Input {
    &:focus {
      outline: none;
    }
  }

  .focused_item {
    background-color: #0066cc;
  }
}
