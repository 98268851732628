.HeaderLayout_type_comment {
    align-items: flex-start;
    .HeaderLayout-Left {
        padding-right: 16px;
        padding-left: 24px;
        
        @media (max-width: 576px) {
            padding-left: 12px;
            padding-right: 12px;
        }
    }
}