.LMSTOStatUsersListItem {
    &-Col1 {
        padding: 24px;
        line-height: 0;
    }

    &-Col2 {
        text-align: left;
    }

    &-ColField {
        overflow: hidden;
        max-width: 120px;
    }

    &-Mention {
        font-weight: bold;
    }

    &-Avatar {}
}