.InfoBlock {
    &-Img {
        max-width: 180px;
        margin: auto;
        &_edit {
            margin: 0;
        }
    }
    &-DataName {
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 33px;
    }
    &-Title {
        font-weight: bold;
        margin: 8px 0;
    }
    &-Inner {
        display: flex;
        align-items: center;
        padding: 24px 48px;
        &_align_top {
            align-items: flex-start;
        }
        @media (max-width: 500px) {
            margin-top: 12px;
            flex-direction: column;
            // text-align: center;
            // justify-content: center;
            align-items: center;
            padding: 24px 12px;
        }
    }
    &-GrayText {
        color: #4B4B4B;
    }
    &-Skeleton {
        padding: 12px 44px;
        display: flex;
        align-items: center;
    }
    &-Info {
        padding: 0 24px;
        flex-grow: 2;
        @media (max-width: 500px) {
            padding: 24px 8px;
            max-width: 100%;
        }
    }
}