@import "../../../utils/global.scss";

.WikiItem {
    @include BoxShadow;
    @include Border;
    @include BorderRadius;
    background-color: var(--background-color);
    margin-bottom: 24px;
    box-sizing: border-box;
    position: relative;
    overflow: hidden;
    min-width: 40%;

      h3{
        font-size: 20px;
        text-align: left;
        margin: 5px 0 0 15px;
        word-break: break-word;
    }
    &-TypeUrl{
      width: 232px;;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    &-SearchMarkdown{
      color: #000;
      margin: 0 0 6px 15px;
      text-align: left;
      max-width: 350px;
      span.CommonmarkRender-Mark{
        background-color: #ffeb3b8a!important;
        padding: 0 5px;
      }
    }
    &-Picture{
     &-BTN{
        width: 100%;
        height: 245px;
        overflow: hidden;
        text-align: center;
        display: flex;
        align-items: center;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
      } 
      &-BTNNoImg{
        width: 100%;
        height: 245px;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      &-Img{
        width: 100%;
        height: auto;
      }
      &-Img1{
        width: 60%;
        height: auto;
      }
    }
   
    &-CreationDate{
      margin: 0 0 6px 15px;
      text-align: left;
      font-size: 11px;
      color: #999;
      .Icon{
        margin-right: 5px;
      }
    }
  &-Settings{
     background-color: var(--background-color);
    color: var(--main-color);
    font-size: 24px;
    font-weight: 600; 
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 5px;
    line-height: 1.4em;
    position: absolute;
    top: 15px;
    // right: 15px;
  }
  &-Actions_Hedaer{
    display: flex;
    justify-content: flex-end;
    position: absolute;
    right: 10px;
    top: 10px;
  }

  &-Actions_Bottom{
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #ccc;
    padding: 10px 10px 10px 15px;
    .Actions{
      padding: 0;
    }
    .WikiItem-Start{
      span{
        color:#fff;
      }
    }
  }
  &-Authors{
    display:flex;
    padding-bottom: 10px;
    margin: 0 0 0 15px;
    .AuthorsModerators{
      width: 50%;
      .UserList{
            line-height: 0!important;
              .UserList-Item {
                padding: 0 2px 2px;
                   .Avatar{
                    pointer-events: none;
                  }
              }
      }
    }
    .AuthorsTitle{
    font-size: 16px;
    margin: 0 0 10px 0;
    }
  }

  &-AuthorsList{
    .AuthorsList-Wrapper{
      .AuthorsList-Author{
        .AuthorItem-Avatar{
          width: 50px!important;
          .Avatar{
            // width: 40px!important; 
            // height: 40px!important; 
            // min-width: 40px!important;
            pointer-events: none;
          }
          .Avatar-Letter{
              line-height: 33px!important;

          }
        }
      }
    }
  }

  &-ActionsList {
    // position: absolute;
    list-style: none;
    padding: 0;
    background-color: #fff;
    overflow: hidden;
    z-index: 20;
    line-height: normal;
    white-space: nowrap;
  }

  &-ActionsItem {
    padding: 5px 10px;

    .Button {
      display: block;
      width: 100%;
      text-align: left;
    }

    &:hover {
      background-color: $SystemBlue;

      .Button {
        color: #fff;
      }
    }
  }

}