@import '../../List.scss';

.Widget_subtype_questsmoderate{
 .ListLite {
    background-color: #ffffff;
    background-color: var(--background-color);
    border: 1px solid #d3d9e0;
    border: 1px solid var(--border-color);
  }
    .QuestModerate{

      &-Attachments.Attachments_init {
        padding: 0;
    
        .Attachments-Item.Attachments-Item_state_view:hover .Attachments-Item-BtnGroup {
          display: block;
          opacity: 1;
        }
    
        .Attachments-Item-BtnGroup {
          display: block;
          opacity: 0;
    
          .Attachments-Item-ViewBtn {
            padding: 0 10px;
            display: none;
          }
        }
    
        .Attachments-Item {
          border: none;
          padding: 5px 0 0 0;
          background-color: inherit;
    
          .Attachments-Item-TypeIcon {
            padding: 0;
            padding-right: 10px;
          }
    
          .Attachments-Item-Name {
            max-width: 100px;
    
            .Attachments-Item-DownloadBtn {
              padding: 0;
              overflow: hidden;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              width: 100%;
              height: 19px;
            }
          }
        }
      }
    
      &-Tabs {
        display: flex;
        justify-content: center;
        margin-top: 6px;
      }

      &-Header {

        .Checkbox {
          visibility: hidden;
        }

        .List-Item-Avatar {
          text-align: left;
        }
      }

      &-actionButton{
        display: block;
        color: #fff;
        border: 1px solid #fff;
        margin: 5px;
        border-radius: 8px;
        
      }
    // &-Table-Top{
    //   display: flex;
    //   justify-content: space-around;
    //   padding: 0px 0 0 24px;
    //     p{
    //       width: 150px;
    //     }
    //   }
    }
   .List-Placeholder {
    border-top: 2px solid #e9ecef;
    border-radius: 0 0 8px 8px;
    }
    .List-Item_type_common {
      align-items: flex-start;
        .List-Item-DataPart{
            // min-width: 143px;           
            p{
              margin:0;
            }
            p.Bonus{
              color: #ccc;
              font-size: 16px;
            }
            p.Status span{
              color: #ccc;
              font-size: 12px;
            }
            p.Status {
              font-size: 12px;
              overflow: hidden;
              text-overflow: ellipsis;
              margin: 0;
            }
        } 
    }
    .List-Item_type_common svg.Checkbox{
      position: absolute;
      top: 56px;
    }
    .List-Item_type_common .Button:not(a) {
    opacity: 100;
}
    .List-Item_type_common .List-Item-DataPart > div > a{
      display: block;
    }
    .List-Item_type_common .List-Item-DataPart > div> div > p > a{
      display: block;
    }
    .List-Item_type_common .List-Item-DataPart>div{
        white-space: normal; 
        overflow: hidden; 
        text-overflow: unset;
    }
    .List-Item_type_common div.List-Item-Avatar{
      min-width: 135px;
      text-align: center;
      p{
        margin: 0;
      }
    }
    .List-Item_type_common:hover   {
      background-color: #ffffff;
      color: #000;
    }

    .List-Item_type_common:hover .List-Item-DataPart a.Button {
      background-color: #ffffff;
      color: #0066cc;
    }
    // .List-Item-DataPart{
    //   min-width: 150px;
    //   p{
    //     margin:0;
    //   }
    //   p.Bonus{
    //     color: #ccc;
    //     font-size: 16px;
    //   }
    // }
  
}