@import '../../List.scss';

.List_type_users {


  .ListLite {
    background-color: var(--background-color);
    border: 1px solid var(--border-color);   
  } 
.noMargin{
 margin: 0;
 font-style: italic;
}
  .List {

    &-Item {
      &-QRCode {
        visibility: hidden;
      }

      &:hover {
        .List-Item-QRCode {
          visibility: visible;
        }
      }
      
      // display: flex;
      &-DataPart {
        .UserName{
          font-weight: bold;
          font-size: 16px;
        }
        flex: 1;
        // min-width: 250px;
        /*&:nth-of-type(n + 2) {
          width: calc((100% - 170px) / 2);
          min-width: calc((100% - 170px) / 2);
          // max-width: 30%;
        }*/
      }

      &_nochecked {
        
        .List-Item-DataPart {

          /*&:nth-of-type(n + 2) {
            width: calc((100% - 140px) / 2);
            min-width: calc((100% - 140px) / 2);
            // max-width: 30%;
          }*/
        }
      }
    }

    &-Button {

      &_more {
        margin-top: 10px;
        display: block;
        width: 100%;
        border-radius: 8px;
      }
    }
  }
}