.KPIItemView {
    &-Check {
    }
    &-Name {
        font-weight: bold;
        padding-top: 8px;
        padding-bottom: 8px;
        overflow: hidden;
        text-overflow: clip;
    }
    &-Goal {
        color: #4b4b4b;
        font-weight: bold;
        padding-top: 8px;
        padding-bottom: 8px;
    }
    &-DueDate {
        padding-top: 8px;
        padding-bottom: 8px;
    }
    &-Actions {
        padding-top: 4px;
        padding-bottom: 4px;
        padding-left: 8px;
        padding-right: 8px;
        display: flex;
        justify-content: flex-end;
    }
}
