@import '../../../utils/global.scss';

/* autoprefixer grid: autoplace */

.RatingTableItem {
  &-Item {
    

    // border-bottom: 1px solid var(--border-color);
    // background-color: var(--background-color);
    // &:last-child {
    //   border-bottom: none;
    // }
    &:hover {
      @include HoverShadow;
      opacity: 0.99;
    }

    &_leveled {
      padding-left: 50px;
    }

    td {
      padding: 12px;
    }

    &.MyItem {
      position: sticky;
      bottom: 0;
      background-color: #ffffff;
      z-index: 20;
      border-bottom: 1px solid var(--border-color);
      animation-duration: 0.2s;
      animation-name: slidein;
    }

    @keyframes slidein {
      from {
        transform: translateY(100%);
      }

      to {
        transform: translateY(0);
      }
    }

    &.myInTop {
      top: 56px;
      position: sticky;
      bottom: auto;
      animation-name: slideout;
    }
    @keyframes slideout {
      from {
        transform: translateY(-100%);
      }

      to {
        transform: translateY(0);
      }
    }

  }

  &-ItemPosition {
    color: #808080;
    grid-area: pos;
    justify-self: end;
  }

  &-ProgressSlider {
    display: none;
    position: absolute;
    top: 0;
    width: 64px;
    transition: all 1s ease-in-out;

    svg {
      display: block;
    }

    img {
      display: block;
    }

    @media (min-width: 500px) {
      display: block;
    }
  }

  &-Progress {
    flex-grow: 2;
    position: relative;
    grid-area: progress;
  }

  &-ProgressBox {
    text-align: right;
    margin-bottom: 20px;
  }

  &-UserName {
    overflow: hidden;
    word-spacing: 9999px;
    text-overflow: ellipsis;
    line-height: 1.5;
    text-decoration: none;
    color: $SystemBlue;
    grid-area: name;

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }

  &-UserAvatar {
    grid-area: avatar;
  }

  &-ItemValue {
    position: relative;
    z-index: 10;
    text-shadow: 0px 0px 3px #fff;
    color: var(--highlight-color);
    font-weight: 700;
    grid-area: value;
  }


}