.Messages {

  #chatFrame {
    display: none;
  }

  &_isAuth {

    #chatFrame {
      display: block;
    }
  }
}