.UserInputDialog {
  .Dialog-Body {
    // overflow: hidden;
  }

  &-Body {
    margin: 0 24px;
    overflow-x: hidden;
  }

  &-User {
    padding: 12px;
    line-height: normal;
    display: flex;
    align-items: center;

    .MuiFormControlLabel-root {
      margin-right: 0px;
    }

    .MuiButtonBase-root.MuiIconButton-root {
      color: rgba(0, 0, 0, 0.54);
    }

    &:not(:first-child) {
      border-top: 1px solid var(--border-color);
    }

    &:hover {
      background-color: var(--main-color);
      color: var(--invariant-text-color);
      cursor: pointer;
    }

    &_disabled {
      padding-left: 24px;
      color: #ccc;

      &:hover {
        background-color: transparent;
        color: #ccc;
        cursor: default;
      }
    }
  }

  &-Tabs {
    margin: 0 -24px;
  }

  &-DisabledUser {
    // color: red;
    color: #721c24;
    margin-top: 8px;
  }

  &-Actions {
    position: sticky;
    bottom: 0px;
    border-top: 1px solid var(--border-color);
    background-color: var(--background-highlight-color);
  }

  &-Name {
    margin: 0;
  }

  &-Trigger {
    position: relative;
    width: 100%;
    height: 50px;

    &:empty {
      height: 0;
    }
  }

  &-Content {
    // overflow: auto;
    // max-height: 75vh;
  }



  &-Avatar {
    &_disabled {
      filter: grayscale(100%);
    }
  }

  &-Avatar,
  &-Info {
    margin-left: 12px;
  }

  &-Search {
    max-width: 500px;
    // margin: 0 auto;
  }
}