@import 'utils/src/global.scss';

.GroupItem {
    display: flex;
    justify-content: space-between;
    padding: $defaultPadding;
    border-bottom: 1px solid $BorderColor;
    &-Title {
      display: flex;
      align-items: center;
      span {
        font-size: 20px;
        margin-left: 12px;
      }
    }
    &-Settings {
      display: flex;
      flex-direction: column;
      align-items: center;
      &-Dropdown {
        button {
          min-width: 180px;
          display: flex;  
          justify-content: center;
          i {
            margin-right: unset;
          }
        }
        
      }
      &-Spinner {
        position: static;
        padding: 12px 24px;
        i {
          top: unset;
        }
      }
      &-Empty {
        padding: 12px 24px;
      }
    }
  }


  .GroupItem-Settings-Dropdown {
    button {
          min-width: 180px;
          height: 35px;
          display: flex;  
          justify-content: center;
          i {
            margin-right: unset;
          }
        }
  }