.Purse-Color {
  fill: #94a5b5;
  stroke: #94a5b5;
}

.Purse {
  &.Shop-Header-Search-Wallet {
    .Purse-Icon {
      line-height: 28px;
    }
  }

  &.ShopProduct-Info-Prise-Img {
    .Purse-Icon {
      line-height: 34px;
      font-size: 26px;
    }
  }

  &-Icon {
    font-size: 22px;
    color: #94a5b5;
    line-height: 34px;
  }
}



.Shop-Header {
  display: flex;
  justify-content: center;
  flex-direction: column;
  line-height: 1;
  padding: 0;

  &>div {
    display: flex;
  }

  &-Search {
    padding: 12px 24px;
    background-color: #F7F7F7;
    flex-wrap: wrap;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;

    .SearchInput {
      flex-grow: 1;
      max-width: initial;
      margin: 12px 6px;

    }

    &-Currency {
      display: flex;
      align-items: center;
      margin: 0 6px;
    }

    &-CurrencyText {
      font-weight: 700;
      white-space: nowrap;
    }

    &-CurrencyValue {
      font-size: 24px;
      display: block;
    }

    &-Wallet {
      margin-right: 12px;
      border-radius: 50px;
      background: #194776;
    }
  }


  &-Categories {
    align-items: center;
    flex-wrap: wrap;
    min-height: 57px;
    padding: 8px 24px;
    border-top: 1px solid #D3D9E0;
    .MuiTypography-body1 {
      font-size: 14px;
      font-family: Open Sans,Arial,Helvetica,sans-serif;

    }
    .PrivateSwitchBase-root-4 {
      padding: 0 4px 0 0;
    }
    .MuiSvgIcon-fontSizeSmall{
      font-size: 14px;
    }
    .MuiFormControlLabel-root {
      margin-right: 7px;  
      margin-left: -6px;
      padding: 6px;
      .jss4{
        padding: 0 4px 0 0!important;
      }
      .MuiIconButton-root {
        padding: 0 4px 0 0!important;
      }
    }
    &-Title {
      font-weight: 700;
      margin-right: 6px;
      display: inline-flex;
      align-items: center;
      margin-right: 10px;
      vertical-align: middle;
      -webkit-tap-highlight-color: transparent;
      font-family: Open Sans,Arial,Helvetica,sans-serif;
    }

    // &>label {
    //   width: auto;
    //   padding: 8px;

    //   &>svg {
    //     margin-right: 6px;
    //   }
    // }
  }
}