@import '../../utils/global.scss';

.LeftMenu {
  position: fixed;
  top: $HeaderHeight;
  left: 0;
  height: $HeightIncludeHeader;
  overflow: hidden;
  width: $LeftMenuWidth;
  background-color: #194776;
  z-index: 90;
  transition: width $TransitionDuration $TransitionTimingFunction;

  &-Inner {
    overflow-x: hidden;
    overflow-y: scroll;
    height: 100%;
    margin-right: -17px !important;
    z-index: 1;
  }

  &_hide {
    width: $LeftMenuFoldedWidth;
    
    .LeftMenuItem {
      
      &-Label {
        justify-content: center;
        padding-right: 0px;
        padding-left: 0px;

        & > *:nth-child(n + 2) {
          display: none;
        }

        & > .LeftMenuItem-System {
          display: block;
          position: absolute;
          right: 7px;
        }
      }

      &-Actions {
        top: 50%;
        left: 50%;
        right: unset;
        transform: translate(-50%, -50%);
      }
    }
  }

  &-Layout {
    position: relative;
  }

  &-Editor {
    position: fixed;
    box-sizing: border-box;
    top: $HeaderHeight;
    right: 0;
    height: $HeightIncludeHeader;
    width: 0;
    padding-left: 0;
    overflow: hidden;
    background-color: rgba(255,255,255,.85);
    z-index: 89;
    transition: width $TransitionDuration $TransitionTimingFunction, padding-left $TransitionDuration $TransitionTimingFunction;
    display: flex;
    justify-content: center;

    &-Inner {
      margin: 48px;
      width: 100%;
      // max-width: 500px;
    }
  }

  &-Layout_edit &-Editor {
    width: 100%;
    padding-left: $LeftMenuWidth;
  }
}

@media screen and (max-width: 767px) {
  .LeftMenu_hidden {
    width: 0;
  }
}