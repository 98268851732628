.SecretSantaWidgetList{
    &-EmptyItem{
        padding:24px;
    }
    &-Santa {
        &-Loading {
          min-height: 327px;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0 24px 24px 24px;
        }
      }
}