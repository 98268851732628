.Photo {
  width: 250px;
  height: 250px;
  margin-bottom: 24px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  display: flex;
  &-Uploading {
    background-color: rgba($color: #000000, $alpha: 0.5);
    flex: 1;
    color: #fff;
    position: relative;
  }
  &-Actions {
    background-color: rgba($color: #000000, $alpha: 0.5);
    transition: all 0.2s linear;
    transform: translateY(-100%);
    padding: 12px;
    color: #fff;
    display: flex;
    .CheckboxInput {
      padding: 0;
    }
    .Button {
      color: #fff;
    }
  }

  &-Info {
    background-color: rgba($color: #000000, $alpha: 0.5);
    transition: all 0.2s linear;
    transform: translateY(100%);
    flex: 1;
    padding: 12px;
    color: #fff;
    cursor: pointer;
    &.Button_type_rlink {
      color: #fff;
    }
  }

  &:hover, &_selected {
    .Photo-Actions {
      transform: translateY(0);
    }

    .Photo-Info {
      transform: translateY(0);
    }
  }
}
