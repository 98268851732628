.AuthorsList {
  // justify-content: space-between;
  // max-width: 350px;

  &-Wrapper {
    display: flex;
    flex-wrap: wrap;
  }
  &-Author {
    // width: 21%;
    // text-align: center;
    /*&:last-of-type {
      text-align: right;
      width: 18.5%;
    }
    &:first-of-type {
      text-align: left;
      width: 18.5%;
    }*/

    // margin-left: 12px;
  }
}
