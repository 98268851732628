@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    /* IE10+ CSS styles go here */

    .EditorCreativetasks {
        &-Title {
            border-top: 1px solid #d3d9e0;
        }

        &-Datepicker {
            flex: none;
            border-top: 1px solid #d3d9e0;
            border-left: none;
        }

        .ThanksSelector {
            // border-top: 1px solid #D3D9E0;
            flex: none;
        }

        .PryanikyEditor {
            border-top: 1px solid #d3d9e0;
            border-bottom: 1px solid #d3d9e0;
        }

        &-HContainer {
            border-top: 1px solid #d3d9e0;
            display: block;
        }
    }
}

@supports (-ms-ime-align: auto) {
    /* EDGE with Trident */
    
    .EditorCreativetasks {
        &-Title {
            border-top: 1px solid #d3d9e0;
        }

        &-Datepicker {
            flex: none;
            border-top: 1px solid #d3d9e0;
            border-left: none;
        }

        .ThanksSelector {
            // border-top: 1px solid #D3D9E0;
            flex: none;
        }

        .PryanikyEditor {
            border-top: 1px solid #d3d9e0;
            border-bottom: 1px solid #d3d9e0;
        }

        &-HContainer {
            border-top: 1px solid #d3d9e0;
            display: block;
        }
    }
}
