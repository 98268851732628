.Wiki {
    .EmbeddedHtmlPlugin {
        white-space: normal;

        html,
        body,
        div,
        span,
        applet,
        object,
        iframe,
        h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        p,
        blockquote,
        pre,
        a,
        abbr,
        acronym,
        address,
        big,
        cite,
        code,
        del,
        dfn,
        em,
        img,
        ins,
        kbd,
        q,
        s,
        samp,
        small,
        strike,
        strong,
        tt,
        var,
        b,
        u,
        i,
        center,
        dl,
        dt,
        dd,
        ol,
        ul,
        li,
        fieldset,
        form,
        label,
        legend,
        table,
        caption,
        tbody,
        tfoot,
        thead,
        tr,
        th,
        td,
        article,
        aside,
        canvas,
        details,
        embed,
        figure,
        figcaption,
        footer,
        header,
        hgroup,
        menu,
        nav,
        output,
        ruby,
        section,
        summary,
        time,
        mark,
        audio,
        video {
            margin: 0;
            padding: 0;
            border: 0;
        }

        article,
        aside,
        details,
        figcaption,
        figure,
        footer,
        header,
        hgroup,
        menu,
        nav,
        section {
            display: block;
        }

        body {
            line-height: 1;
        }

        blockquote,
        q {
            quotes: none;
        }

        blockquote:before,
        blockquote:after,
        q:before,
        q:after {
            content: "";
            content: none;
        }

        table {
            border-collapse: collapse;
            border-spacing: 0;
        }

        .ui-slider-horizontal.ui-slider-pips {
            margin-bottom: 1.4em;
        }

        .ui-slider-pips .ui-slider-label,
        .ui-slider-pips .ui-slider-pip-hide {
            display: none;
        }

        .ui-slider-pips .ui-slider-pip-label .ui-slider-label {
            display: block;
        }

        .ui-slider-pips .ui-slider-pip {
            width: 2em;
            height: 1em;
            line-height: 1em;
            position: absolute;
            font-size: 0.8em;
            color: #999;
            overflow: visible;
            text-align: center;
            top: 20px;
            left: 20px;
            margin-left: -1em;
            cursor: pointer;
            -webkit-touch-callout: none;
            -webkit-user-select: none;
            -khtml-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
        }

        .ui-slider-pips .ui-slider-line {
            background: #999;
            width: 1px;
            height: 3px;
            position: absolute;
            left: 50%;
        }

        .ui-slider-pips .ui-slider-label {
            position: absolute;
            top: 5px;
            left: 50%;
            margin-left: -1em;
            width: 2em;
        }

        .ui-slider-pip:hover .ui-slider-label {
            color: #000;
            font-weight: bold;
        }

        .ui-slider-vertical.ui-slider-pips {
            margin-bottom: 1em;
            margin-right: 2em;
        }

        .ui-slider-vertical.ui-slider-pips .ui-slider-pip {
            text-align: left;
            top: auto;
            left: 20px;
            margin-left: 0;
            margin-bottom: -0.5em;
        }

        .ui-slider-vertical.ui-slider-pips .ui-slider-line {
            width: 3px;
            height: 1px;
            position: absolute;
            top: 50%;
            left: 0;
        }

        .ui-slider-vertical.ui-slider-pips .ui-slider-label {
            top: 50%;
            left: 0.5em;
            margin-left: 0;
            margin-top: -0.5em;
            width: 2em;
        }

        .ui-slider-float .ui-slider-handle:focus,
        .ui-slider-float .ui-slider-handle:focus .ui-slider-tip,
        .ui-slider-float .ui-slider-handle:focus .ui-slider-tip-label {
            outline: none;
        }

        .ui-slider-float .ui-slider-tip,
        .ui-slider-float .ui-slider-tip-label {
            position: absolute;
            visibility: hidden;
            top: -40px;
            display: block;
            width: 34px;
            margin-left: -18px;
            left: 50%;
            height: 20px;
            line-height: 20px;
            background: #fff;
            border-radius: 3px;
            border: 1px solid #888;
            text-align: center;
            font-size: 12px;
            opacity: 0;
            color: #333;
            -webkit-transition-duration: 200ms, 200ms, 0;
            -moz-transition-duration: 200ms, 200ms, 0;
            -ms-transition-duration: 200ms, 200ms, 0;
            transition-duration: 200ms, 200ms, 0;
            -webkit-transition-property: opacity, top, visibility;
            -moz-transition-property: opacity, top, visibility;
            -ms-transition-property: opacity, top, visibility;
            transition-property: opacity, top, visibility;
            -webkit-transition-delay: 0, 0, 200ms;
            -moz-transition-delay: 0, 0, 200ms;
            -ms-transition-delay: 0, 0, 200ms;
            transition-delay: 0, 0, 200ms;
        }

        .ui-slider-float .ui-slider-handle:hover .ui-slider-tip,
        .ui-slider-float .ui-slider-handle:focus .ui-slider-tip,
        .ui-slider-float .ui-slider-pip:hover .ui-slider-tip-label {
            opacity: 1;
            top: -30px;
            visibility: visible;
            -webkit-transition-delay: 200ms, 200ms, 0;
            -moz-transition-delay: 200ms, 200ms, 0;
            -ms-transition-delay: 200ms, 200ms, 0;
            transition-delay: 200ms, 200ms, 0;
        }

        .ui-slider-float .ui-slider-pip .ui-slider-tip-label {
            top: 42px;
        }

        .ui-slider-float .ui-slider-pip:hover .ui-slider-tip-label {
            top: 32px;
            font-weight: normal;
        }

        .ui-slider-float .ui-slider-tip:after,
        .ui-slider-float .ui-slider-pip .ui-slider-tip-label:after {
            content: " ";
            width: 0;
            height: 0;
            border: 5px solid rgba(255, 255, 255, 0);
            border-top-color: rgba(255, 255, 255, 1);
            position: absolute;
            bottom: -10px;
            left: 50%;
            margin-left: -5px;
        }

        .ui-slider-float .ui-slider-tip:before,
        .ui-slider-float .ui-slider-pip .ui-slider-tip-label:before {
            content: " ";
            width: 0;
            height: 0;
            border: 5px solid rgba(255, 255, 255, 0);
            border-top-color: #888;
            position: absolute;
            bottom: -11px;
            left: 50%;
            margin-left: -5px;
        }

        .ui-slider-float .ui-slider-pip .ui-slider-tip-label:after {
            border: 5px solid rgba(255, 255, 255, 0);
            border-bottom-color: rgba(255, 255, 255, 1);
            top: -10px;
        }

        .ui-slider-float .ui-slider-pip .ui-slider-tip-label:before {
            border: 5px solid rgba(255, 255, 255, 0);
            border-bottom-color: #888;
            top: -11px;
        }

        .ui-slider-vertical.ui-slider-float .ui-slider-tip,
        .ui-slider-vertical.ui-slider-float .ui-slider-tip-label {
            top: 50%;
            margin-top: -11px;
            width: 34px;
            margin-left: 0;
            left: -60px;
            color: #333;
            -webkit-transition-duration: 200ms, 200ms, 0;
            -moz-transition-duration: 200ms, 200ms, 0;
            -ms-transition-duration: 200ms, 200ms, 0;
            transition-duration: 200ms, 200ms, 0;
            -webkit-transition-property: opacity, left, visibility;
            -moz-transition-property: opacity, left, visibility;
            -ms-transition-property: opacity, left, visibility;
            transition-property: opacity, left, visibility;
            -webkit-transition-delay: 0, 0, 200ms;
            -moz-transition-delay: 0, 0, 200ms;
            -ms-transition-delay: 0, 0, 200ms;
            transition-delay: 0, 0, 200ms;
        }

        .ui-slider-vertical.ui-slider-float .ui-slider-handle:hover .ui-slider-tip,
        .ui-slider-vertical.ui-slider-float .ui-slider-handle:focus .ui-slider-tip,
        .ui-slider-vertical.ui-slider-float .ui-slider-pip:hover .ui-slider-tip-label {
            top: 50%;
            margin-top: -11px;
            left: -50px;
        }

        .ui-slider-vertical.ui-slider-float .ui-slider-pip .ui-slider-tip-label {
            left: 47px;
        }

        .ui-slider-vertical.ui-slider-float .ui-slider-pip:hover .ui-slider-tip-label {
            left: 37px;
        }

        .ui-slider-vertical.ui-slider-float .ui-slider-tip:after,
        .ui-slider-vertical.ui-slider-float .ui-slider-pip .ui-slider-tip-label:after {
            border: 5px solid rgba(255, 255, 255, 0);
            border-left-color: rgba(255, 255, 255, 1);
            border-top-color: transparent;
            position: absolute;
            bottom: 50%;
            margin-bottom: -5px;
            right: -10px;
            margin-left: 0;
            top: auto;
            left: auto;
        }

        .ui-slider-vertical.ui-slider-float .ui-slider-tip:before,
        .ui-slider-vertical.ui-slider-float .ui-slider-pip .ui-slider-tip-label:before {
            border: 5px solid rgba(255, 255, 255, 0);
            border-left-color: #888;
            border-top-color: transparent;
            position: absolute;
            bottom: 50%;
            margin-bottom: -5px;
            right: -11px;
            margin-left: 0;
            top: auto;
            left: auto;
        }

        .ui-slider-vertical.ui-slider-float .ui-slider-pip .ui-slider-tip-label:after {
            border: 5px solid rgba(255, 255, 255, 0);
            border-right-color: rgba(255, 255, 255, 1);
            right: auto;
            left: -10px;
        }

        .ui-slider-vertical.ui-slider-float .ui-slider-pip .ui-slider-tip-label:before {
            border: 5px solid rgba(255, 255, 255, 0);
            border-right-color: #888;
            right: auto;
            left: -11px;
        }

        .ui-slider-pips .ui-slider-pip-selected-initial,
        .ui-slider-pips .ui-slider-pip-selected-initial-first,
        .ui-slider-pips .ui-slider-pip-selected-initial-second {
            font-weight: bold;
            color: #14ca82;
        }

        .ui-slider-pips .ui-slider-pip-selected-initial {
        }

        .ui-slider-pips .ui-slider-pip-selected-initial-first {
        }

        .ui-slider-pips .ui-slider-pip-selected-initial-second {
            color: #1897c9;
        }

        .ui-slider-pips .ui-slider-pip-selected,
        .ui-slider-pips .ui-slider-pip-selected-first,
        .ui-slider-pips .ui-slider-pip-selected-second {
            font-weight: bold;
            color: #ff7a00;
        }

        .ui-slider-pips .ui-slider-pip-selected {
        }

        .ui-slider-pips .ui-slider-pip-selected-first {
        }

        .ui-slider-pips .ui-slider-pip-selected-second {
            color: #e70081;
        }

        input.es-input {
            padding-right: 20px !important;
            background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAICAYAAADJEc7MAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAIGNIUk0AAG2YAABzjgAA4DIAAIM2AAB5CAAAxgwAADT6AAAgbL5TJ5gAAABGSURBVHjaYvz//z8DOYCJgUzA0tnZidPK8vJyRpw24pLEpwnuVHRFhDQxMDAwMPz//x+OOzo6/iPz8WFGuocqAAAA//8DAD/sORHYg7kaAAAAAElFTkSuQmCC)
                right center no-repeat;
        }

        input.es-input.open {
            -webkit-border-bottom-left-radius: 0;
            -moz-border-radius-bottomleft: 0;
            border-bottom-left-radius: 0;
            -webkit-border-bottom-right-radius: 0;
            -moz-border-radius-bottomright: 0;
            border-bottom-right-radius: 0;
        }

        .es-list {
            position: absolute;
            padding: 0;
            margin: 0;
            border: 1px solid #d1d1d1;
            display: none;
            z-index: 1000;
            background: #fff;
            max-height: 160px;
            overflow-y: auto;
            -moz-box-shadow: 0 2px 3px #ccc;
            -webkit-box-shadow: 0 2px 3px #ccc;
            box-shadow: 0 2px 3px #ccc;
        }

        .es-list li {
            display: block;
            padding: 5px 10px;
            margin: 0;
        }

        .es-list li.selected {
            background: #f3f3f3;
        }

        .es-list li[disabled] {
            opacity: 0.5;
        }

        .ui-slider {
            position: relative;
            text-align: left;
        }

        .ui-slider .ui-slider-handle {
            position: absolute;
            z-index: 2;
            width: 1.2em;
            height: 1.2em;
            cursor: default;
        }

        .ui-slider .ui-slider-range {
            position: absolute;
            z-index: 1;
            font-size: 0.7em;
            display: block;
            border: 0;
            background-position: 0 0;
        }

        .ui-slider-horizontal {
            height: 0.8em;
            background-color: #ffd24a;
        }

        .ui-slider-horizontal .ui-slider-handle {
            top: -0.5em;
            margin-left: -0.4em;
            border-width: 0;
            height: 20px;
            width: 20px;
        }

        .ui-slider-horizontal .ui-slider-range {
            top: 0;
            height: 100%;
        }

        .ui-slider-horizontal .ui-slider-range-min {
            left: 0;
        }

        .ui-slider-horizontal .ui-slider-range-max {
            right: 0;
        }

        .ui-slider-vertical {
            width: 0.8em;
            height: 100px;
        }

        .ui-slider-vertical .ui-slider-handle {
            left: -0.3em;
            margin-left: 0;
            margin-bottom: -0.6em;
            border-width: 0;
            height: 20px;
            width: 20px;
        }

        .ui-slider-vertical .ui-slider-range {
            left: 0;
            width: 100%;
        }

        .ui-slider-vertical .ui-slider-range-min {
            bottom: 0;
        }

        .ui-slider-vertical .ui-slider-range-max {
            top: 0;
        }

        @-webkit-keyframes passing-through {
            0% {
                opacity: 0;
                -webkit-transform: translateY(40px);
                -moz-transform: translateY(40px);
                -ms-transform: translateY(40px);
                -o-transform: translateY(40px);
                transform: translateY(40px);
            }

            30%,
            70% {
                opacity: 1;
                -webkit-transform: translateY(0);
                -moz-transform: translateY(0);
                -ms-transform: translateY(0);
                -o-transform: translateY(0);
                transform: translateY(0);
            }

            100% {
                opacity: 0;
                -webkit-transform: translateY(-40px);
                -moz-transform: translateY(-40px);
                -ms-transform: translateY(-40px);
                -o-transform: translateY(-40px);
                transform: translateY(-40px);
            }
        }

        @-moz-keyframes passing-through {
            0% {
                opacity: 0;
                -webkit-transform: translateY(40px);
                -moz-transform: translateY(40px);
                -ms-transform: translateY(40px);
                -o-transform: translateY(40px);
                transform: translateY(40px);
            }

            30%,
            70% {
                opacity: 1;
                -webkit-transform: translateY(0);
                -moz-transform: translateY(0);
                -ms-transform: translateY(0);
                -o-transform: translateY(0);
                transform: translateY(0);
            }

            100% {
                opacity: 0;
                -webkit-transform: translateY(-40px);
                -moz-transform: translateY(-40px);
                -ms-transform: translateY(-40px);
                -o-transform: translateY(-40px);
                transform: translateY(-40px);
            }
        }

        @keyframes passing-through {
            0% {
                opacity: 0;
                -webkit-transform: translateY(40px);
                -moz-transform: translateY(40px);
                -ms-transform: translateY(40px);
                -o-transform: translateY(40px);
                transform: translateY(40px);
            }

            30%,
            70% {
                opacity: 1;
                -webkit-transform: translateY(0);
                -moz-transform: translateY(0);
                -ms-transform: translateY(0);
                -o-transform: translateY(0);
                transform: translateY(0);
            }

            100% {
                opacity: 0;
                -webkit-transform: translateY(-40px);
                -moz-transform: translateY(-40px);
                -ms-transform: translateY(-40px);
                -o-transform: translateY(-40px);
                transform: translateY(-40px);
            }
        }

        @-webkit-keyframes slide-in {
            0% {
                opacity: 0;
                -webkit-transform: translateY(40px);
                -moz-transform: translateY(40px);
                -ms-transform: translateY(40px);
                -o-transform: translateY(40px);
                transform: translateY(40px);
            }

            30% {
                opacity: 1;
                -webkit-transform: translateY(0);
                -moz-transform: translateY(0);
                -ms-transform: translateY(0);
                -o-transform: translateY(0);
                transform: translateY(0);
            }
        }

        @-moz-keyframes slide-in {
            0% {
                opacity: 0;
                -webkit-transform: translateY(40px);
                -moz-transform: translateY(40px);
                -ms-transform: translateY(40px);
                -o-transform: translateY(40px);
                transform: translateY(40px);
            }

            30% {
                opacity: 1;
                -webkit-transform: translateY(0);
                -moz-transform: translateY(0);
                -ms-transform: translateY(0);
                -o-transform: translateY(0);
                transform: translateY(0);
            }
        }

        @keyframes slide-in {
            0% {
                opacity: 0;
                -webkit-transform: translateY(40px);
                -moz-transform: translateY(40px);
                -ms-transform: translateY(40px);
                -o-transform: translateY(40px);
                transform: translateY(40px);
            }

            30% {
                opacity: 1;
                -webkit-transform: translateY(0);
                -moz-transform: translateY(0);
                -ms-transform: translateY(0);
                -o-transform: translateY(0);
                transform: translateY(0);
            }
        }

        @-webkit-keyframes pulse {
            0% {
                -webkit-transform: scale(1);
                -moz-transform: scale(1);
                -ms-transform: scale(1);
                -o-transform: scale(1);
                transform: scale(1);
            }

            10% {
                -webkit-transform: scale(1.1);
                -moz-transform: scale(1.1);
                -ms-transform: scale(1.1);
                -o-transform: scale(1.1);
                transform: scale(1.1);
            }

            20% {
                -webkit-transform: scale(1);
                -moz-transform: scale(1);
                -ms-transform: scale(1);
                -o-transform: scale(1);
                transform: scale(1);
            }
        }

        @-moz-keyframes pulse {
            0% {
                -webkit-transform: scale(1);
                -moz-transform: scale(1);
                -ms-transform: scale(1);
                -o-transform: scale(1);
                transform: scale(1);
            }

            10% {
                -webkit-transform: scale(1.1);
                -moz-transform: scale(1.1);
                -ms-transform: scale(1.1);
                -o-transform: scale(1.1);
                transform: scale(1.1);
            }

            20% {
                -webkit-transform: scale(1);
                -moz-transform: scale(1);
                -ms-transform: scale(1);
                -o-transform: scale(1);
                transform: scale(1);
            }
        }

        @keyframes pulse {
            0% {
                -webkit-transform: scale(1);
                -moz-transform: scale(1);
                -ms-transform: scale(1);
                -o-transform: scale(1);
                transform: scale(1);
            }

            10% {
                -webkit-transform: scale(1.1);
                -moz-transform: scale(1.1);
                -ms-transform: scale(1.1);
                -o-transform: scale(1.1);
                transform: scale(1.1);
            }

            20% {
                -webkit-transform: scale(1);
                -moz-transform: scale(1);
                -ms-transform: scale(1);
                -o-transform: scale(1);
                transform: scale(1);
            }
        }

        .dropzone,
        .dropzone * {
            box-sizing: border-box;
        }

        .dropzone {
            height: 100%;
            border: 0;
            padding: 0;
            transition: opacity 0.5s ease-in-out;
            overflow: hidden;
            opacity: 0;
            z-index: -1;
        }

        .dropzone.dropzoneView,
        .dropzone.dz-started {
            opacity: 0.7;
            z-index: 1;
        }

        .dropzone.dzopacity {
            opacity: 1;
        }

        .dropzone.dz-clickable {
            cursor: pointer;
        }

        .dropzone.dz-clickable * {
            cursor: default;
        }

        .dropzone.dz-clickable .dz-message,
        .dropzone.dz-clickable .dz-message * {
            cursor: pointer;
        }

        .dropzone.dz-started .dz-message {
            display: none;
        }

        .dropzone.dz-drag-hover {
            border-style: solid;
        }

        .dropzone.dz-drag-hover .dz-message {
            opacity: 0.5;
        }

        .dropzone .dz-message {
            text-align: center;
            margin: 2em 0;
            margin: 0;
        }

        .dropzone .dz-preview {
            position: relative;
            display: inline-block;
            vertical-align: top;
            margin: 6px;
            min-height: 100px;
        }

        .dropzone .dz-preview:hover {
            z-index: 1000;
        }

        .dropzone .dz-preview:hover .dz-details {
            opacity: 1;
        }

        .dropzone .dz-preview.dz-file-preview .dz-image {
            border-radius: 20px;
            background: #999;
            background: linear-gradient(to bottom, #eee, #ddd);
        }

        .dropzone .dz-preview.dz-file-preview .dz-details {
            opacity: 1;
        }

        .dropzone .dz-preview.dz-image-preview {
        }

        .dropzone .dz-preview.dz-image-preview .dz-details {
            -webkit-transition: opacity 0.2s linear;
            -moz-transition: opacity 0.2s linear;
            -ms-transition: opacity 0.2s linear;
            -o-transition: opacity 0.2s linear;
            transition: opacity 0.2s linear;
        }

        .dropzone .dz-preview .dz-remove {
            font-size: 14px;
            text-align: center;
            display: block;
            cursor: pointer;
            border: none;
            position: absolute;
            top: 0;
            right: 0;
            z-index: 21;
        }

        .dropzone .dz-preview .dz-remove:hover {
            text-decoration: underline;
        }

        .dropzone .dz-preview:hover .dz-details {
            opacity: 1;
        }

        .dropzone .dz-preview .dz-details {
            z-index: 20;
            position: absolute;
            top: 0;
            left: 0;
            opacity: 0;
            font-size: 13px;
            min-width: 100%;
            max-width: 100%;
            padding: 1em 1em;
            text-align: center;
            color: rgba(0, 0, 0, 0.9);
            line-height: 150%;
        }

        .dropzone .dz-preview .dz-details .dz-size {
            margin-bottom: 0.5em;
            font-size: 16px;
        }

        .dropzone .dz-preview .dz-details .dz-filename {
            white-space: nowrap;
        }

        .dropzone .dz-preview .dz-details .dz-filename:hover span {
            border: 1px solid rgba(200, 200, 200, 0.8);
            background-color: rgba(255, 255, 255, 0.8);
        }

        .dropzone .dz-preview .dz-details .dz-filename:not(:hover) {
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .dropzone .dz-preview .dz-details .dz-filename:not(:hover) span {
            border: 1px solid transparent;
        }

        .dropzone .dz-preview .dz-details .dz-filename span,
        .dropzone .dz-preview .dz-details .dz-size span {
            background-color: rgba(255, 255, 255, 0.4);
            padding: 0 0.4em;
            border-radius: 3px;
            white-space: normal;
            word-break: break-all;
        }

        .dropzone .dz-preview:hover .dz-image img {
            -webkit-transform: scale(1.05, 1.05);
            -moz-transform: scale(1.05, 1.05);
            -ms-transform: scale(1.05, 1.05);
            -o-transform: scale(1.05, 1.05);
            transform: scale(1.05, 1.05);
            -webkit-filter: blur(8px);
            filter: blur(8px);
        }

        .dropzone .dz-preview .dz-image {
            border-radius: 20px;
            overflow: hidden;
            width: 120px;
            height: 120px;
            position: relative;
            display: block;
            z-index: 10;
        }

        .dropzone .dz-preview .dz-image img {
            display: block;
        }

        .dropzone .dz-preview.dz-success .dz-success-mark {
            -webkit-animation: passing-through 3s cubic-bezier(0.77, 0, 0.175, 1);
            -moz-animation: passing-through 3s cubic-bezier(0.77, 0, 0.175, 1);
            -ms-animation: passing-through 3s cubic-bezier(0.77, 0, 0.175, 1);
            -o-animation: passing-through 3s cubic-bezier(0.77, 0, 0.175, 1);
            animation: passing-through 3s cubic-bezier(0.77, 0, 0.175, 1);
        }

        .dropzone .dz-preview.dz-error .dz-error-mark {
            opacity: 1;
            -webkit-animation: slide-in 3s cubic-bezier(0.77, 0, 0.175, 1);
            -moz-animation: slide-in 3s cubic-bezier(0.77, 0, 0.175, 1);
            -ms-animation: slide-in 3s cubic-bezier(0.77, 0, 0.175, 1);
            -o-animation: slide-in 3s cubic-bezier(0.77, 0, 0.175, 1);
            animation: slide-in 3s cubic-bezier(0.77, 0, 0.175, 1);
        }

        .dropzone .dz-preview .dz-success-mark,
        .dropzone .dz-preview .dz-error-mark {
            pointer-events: none;
            opacity: 0;
            z-index: 500;
            position: absolute;
            display: block;
            top: 50%;
            left: 50%;
            margin-left: -27px;
            margin-top: -27px;
        }

        .dropzone .dz-preview .dz-success-mark svg,
        .dropzone .dz-preview .dz-error-mark svg {
            display: block;
            width: 54px;
            height: 54px;
        }

        .dropzone .dz-preview.dz-processing .dz-progress {
            opacity: 1;
            -webkit-transition: all 0.2s linear;
            -moz-transition: all 0.2s linear;
            -ms-transition: all 0.2s linear;
            -o-transition: all 0.2s linear;
            transition: all 0.2s linear;
        }

        .dropzone .dz-preview.dz-complete .dz-progress {
            opacity: 0;
            -webkit-transition: opacity 0.4s ease-in;
            -moz-transition: opacity 0.4s ease-in;
            -ms-transition: opacity 0.4s ease-in;
            -o-transition: opacity 0.4s ease-in;
            transition: opacity 0.4s ease-in;
        }

        .dropzone .dz-preview:not(.dz-processing) .dz-progress {
            -webkit-animation: pulse 6s ease infinite;
            -moz-animation: pulse 6s ease infinite;
            -ms-animation: pulse 6s ease infinite;
            -o-animation: pulse 6s ease infinite;
            animation: pulse 6s ease infinite;
        }

        .dropzone .dz-preview .dz-progress {
            opacity: 1;
            z-index: 1000;
            pointer-events: none;
            position: absolute;
            height: 16px;
            left: 50%;
            top: 50%;
            margin-top: -8px;
            width: 80px;
            margin-left: -40px;
            background: rgba(255, 255, 255, 0.9);
            -webkit-transform: scale(1);
            border-radius: 8px;
            overflow: hidden;
        }

        .dropzone .dz-preview .dz-progress .dz-upload {
            background: #333;
            background: linear-gradient(to bottom, #666, #444);
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            width: 0;
            -webkit-transition: width 300ms ease-in-out;
            -moz-transition: width 300ms ease-in-out;
            -ms-transition: width 300ms ease-in-out;
            -o-transition: width 300ms ease-in-out;
            transition: width 300ms ease-in-out;
        }

        .dropzone .dz-preview.dz-error .dz-error-message {
            display: block;
        }

        .dropzone .dz-preview.dz-error:hover .dz-error-message {
            opacity: 1;
            pointer-events: auto;
        }

        .dropzone .dz-preview .dz-error-message {
            pointer-events: none;
            z-index: 1000;
            position: absolute;
            display: block;
            display: none;
            opacity: 0;
            -webkit-transition: opacity 0.3s ease;
            -moz-transition: opacity 0.3s ease;
            -ms-transition: opacity 0.3s ease;
            -o-transition: opacity 0.3s ease;
            transition: opacity 0.3s ease;
            border-radius: 8px;
            font-size: 13px;
            top: 130px;
            left: -10px;
            top: 0;
            left: 130px;
            width: 140px;
            background: #be2626;
            background: linear-gradient(to bottom, #be2626, #a92222);
            padding: 0.5em 1.2em;
            color: #fff;
        }

        #newPostDropzone.dropzone .dz-preview:nth-child(4n) .dz-error-message,
        #newPostDropzone.dropzone .dz-preview:nth-child(4n + 1) .dz-error-message,
        [id^="replys"].dropzone .dz-preview:nth-child(3n + 1) .dz-error-message,
        [id^="newsEdit"].dropzone .dz-preview:nth-child(3n + 1) .dz-error-message {
            left: unset;
            right: 130px;
        }

        .dropzone .dz-preview .dz-error-message:after {
            content: "";
            position: absolute;
            top: 10px;
            left: -6px;
            width: 0;
            height: 0;
            border-right: 6px solid #be2626;
            border-left: none;
            border-top: 6px solid transparent;
            border-bottom: 6px solid transparent;
        }

        #newPostDropzone.dropzone .dz-preview:nth-child(4n) .dz-error-message:after,
        #newPostDropzone.dropzone .dz-preview:nth-child(4n + 1) .dz-error-message:after,
        [id^="replys"].dropzone .dz-preview:nth-child(3n + 1) .dz-error-message:after,
        [id^="newsEdit"].dropzone .dz-preview:nth-child(3n + 1) .dz-error-message:after {
            content: "";
            position: absolute;
            top: 10px;
            left: unset;
            right: -6px;
            width: 0;
            height: 0;
            border-right: none;
            border-left: 6px solid #be2626;
            border-top: 6px solid transparent;
            border-bottom: 6px solid transparent;
        }

        .pace {
            -webkit-pointer-events: none;
            pointer-events: none;
            -webkit-user-select: none;
            -moz-user-select: none;
            user-select: none;
        }

        .pace-inactive {
            display: none;
        }

        .pace .pace-progress {
            background: #29d;
            position: fixed;
            z-index: 2000;
            top: 0;
            right: 100%;
            width: 100%;
            height: 2px;
        }

        .pace .pace-progress-inner {
            display: block;
            position: absolute;
            right: 0;
            width: 100px;
            height: 100%;
            box-shadow: 0 0 10px #29d, 0 0 5px #29d;
            opacity: 1;
            -webkit-transform: rotate(3deg) translate(0, -4px);
            -moz-transform: rotate(3deg) translate(0, -4px);
            -ms-transform: rotate(3deg) translate(0, -4px);
            -o-transform: rotate(3deg) translate(0, -4px);
            transform: rotate(3deg) translate(0, -4px);
        }

        .pace .pace-activity {
            display: block;
            position: fixed;
            z-index: 2000;
            top: 15px;
            right: 15px;
            width: 14px;
            height: 14px;
            border: solid 2px transparent;
            border-top-color: #29d;
            border-left-color: #29d;
            border-radius: 10px;
            -webkit-animation: pace-spinner 400ms linear infinite;
            -moz-animation: pace-spinner 400ms linear infinite;
            -ms-animation: pace-spinner 400ms linear infinite;
            -o-animation: pace-spinner 400ms linear infinite;
            animation: pace-spinner 400ms linear infinite;
        }

        @-webkit-keyframes pace-spinner {
            0% {
                -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
            }

            100% {
                -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
            }
        }

        @-moz-keyframes pace-spinner {
            0% {
                -moz-transform: rotate(0deg);
                transform: rotate(0deg);
            }

            100% {
                -moz-transform: rotate(360deg);
                transform: rotate(360deg);
            }
        }

        @-o-keyframes pace-spinner {
            0% {
                -o-transform: rotate(0deg);
                transform: rotate(0deg);
            }

            100% {
                -o-transform: rotate(360deg);
                transform: rotate(360deg);
            }
        }

        @-ms-keyframes pace-spinner {
            0% {
                -ms-transform: rotate(0deg);
                transform: rotate(0deg);
            }

            100% {
                -ms-transform: rotate(360deg);
                transform: rotate(360deg);
            }
        }

        @keyframes pace-spinner {
            0% {
                transform: rotate(0deg);
                transform: rotate(0deg);
            }

            100% {
                transform: rotate(360deg);
                transform: rotate(360deg);
            }
        }

        .video-js .vjs-big-play-button .vjs-icon-placeholder:before,
        .video-js .vjs-modal-dialog,
        .vjs-button > .vjs-icon-placeholder:before,
        .vjs-modal-dialog .vjs-modal-dialog-content {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }

        .video-js .vjs-big-play-button .vjs-icon-placeholder:before,
        .vjs-button > .vjs-icon-placeholder:before {
            text-align: center;
        }

        

        .video-js .vjs-big-play-button .vjs-icon-placeholder:before,
        .video-js .vjs-play-control .vjs-icon-placeholder,
        .vjs-icon-play {
            font-family: VideoJS;
            font-weight: 400;
            font-style: normal;
        }

        .video-js .vjs-big-play-button .vjs-icon-placeholder:before,
        .video-js .vjs-play-control .vjs-icon-placeholder:before,
        .vjs-icon-play:before {
            content: "";
        }

        .vjs-icon-play-circle {
            font-family: VideoJS;
            font-weight: 400;
            font-style: normal;
        }

        .vjs-icon-play-circle:before {
            content: "";
        }

        .video-js .vjs-play-control.vjs-playing .vjs-icon-placeholder,
        .vjs-icon-pause {
            font-family: VideoJS;
            font-weight: 400;
            font-style: normal;
        }

        .video-js .vjs-play-control.vjs-playing .vjs-icon-placeholder:before,
        .vjs-icon-pause:before {
            content: "";
        }

        .video-js .vjs-mute-control.vjs-vol-0 .vjs-icon-placeholder,
        .vjs-icon-volume-mute {
            font-family: VideoJS;
            font-weight: 400;
            font-style: normal;
        }

        .video-js .vjs-mute-control.vjs-vol-0 .vjs-icon-placeholder:before,
        .vjs-icon-volume-mute:before {
            content: "";
        }

        .video-js .vjs-mute-control.vjs-vol-1 .vjs-icon-placeholder,
        .vjs-icon-volume-low {
            font-family: VideoJS;
            font-weight: 400;
            font-style: normal;
        }

        .video-js .vjs-mute-control.vjs-vol-1 .vjs-icon-placeholder:before,
        .vjs-icon-volume-low:before {
            content: "";
        }

        .video-js .vjs-mute-control.vjs-vol-2 .vjs-icon-placeholder,
        .vjs-icon-volume-mid {
            font-family: VideoJS;
            font-weight: 400;
            font-style: normal;
        }

        .video-js .vjs-mute-control.vjs-vol-2 .vjs-icon-placeholder:before,
        .vjs-icon-volume-mid:before {
            content: "";
        }

        .video-js .vjs-mute-control .vjs-icon-placeholder,
        .vjs-icon-volume-high {
            font-family: VideoJS;
            font-weight: 400;
            font-style: normal;
        }

        .video-js .vjs-mute-control .vjs-icon-placeholder:before,
        .vjs-icon-volume-high:before {
            content: "";
        }

        .video-js .vjs-fullscreen-control .vjs-icon-placeholder,
        .vjs-icon-fullscreen-enter {
            font-family: VideoJS;
            font-weight: 400;
            font-style: normal;
        }

        .video-js .vjs-fullscreen-control .vjs-icon-placeholder:before,
        .vjs-icon-fullscreen-enter:before {
            content: "";
        }

        .video-js.vjs-fullscreen .vjs-fullscreen-control .vjs-icon-placeholder,
        .vjs-icon-fullscreen-exit {
            font-family: VideoJS;
            font-weight: 400;
            font-style: normal;
        }

        .video-js.vjs-fullscreen .vjs-fullscreen-control .vjs-icon-placeholder:before,
        .vjs-icon-fullscreen-exit:before {
            content: "";
        }

        .vjs-icon-square {
            font-family: VideoJS;
            font-weight: 400;
            font-style: normal;
        }

        .vjs-icon-square:before {
            content: "";
        }

        .vjs-icon-spinner {
            font-family: VideoJS;
            font-weight: 400;
            font-style: normal;
        }

        .vjs-icon-spinner:before {
            content: "";
        }

        .video-js .vjs-subs-caps-button .vjs-icon-placeholder,
        .video-js .vjs-subtitles-button .vjs-icon-placeholder,
        .video-js.video-js:lang(en-AU) .vjs-subs-caps-button .vjs-icon-placeholder,
        .video-js.video-js:lang(en-GB) .vjs-subs-caps-button .vjs-icon-placeholder,
        .video-js.video-js:lang(en-IE) .vjs-subs-caps-button .vjs-icon-placeholder,
        .video-js.video-js:lang(en-NZ) .vjs-subs-caps-button .vjs-icon-placeholder,
        .vjs-icon-subtitles {
            font-family: VideoJS;
            font-weight: 400;
            font-style: normal;
        }

        .video-js .vjs-subs-caps-button .vjs-icon-placeholder:before,
        .video-js .vjs-subtitles-button .vjs-icon-placeholder:before,
        .video-js.video-js:lang(en-AU) .vjs-subs-caps-button .vjs-icon-placeholder:before,
        .video-js.video-js:lang(en-GB) .vjs-subs-caps-button .vjs-icon-placeholder:before,
        .video-js.video-js:lang(en-IE) .vjs-subs-caps-button .vjs-icon-placeholder:before,
        .video-js.video-js:lang(en-NZ) .vjs-subs-caps-button .vjs-icon-placeholder:before,
        .vjs-icon-subtitles:before {
            content: "";
        }

        .video-js .vjs-captions-button .vjs-icon-placeholder,
        .video-js:lang(en) .vjs-subs-caps-button .vjs-icon-placeholder,
        .video-js:lang(fr-CA) .vjs-subs-caps-button .vjs-icon-placeholder,
        .vjs-icon-captions {
            font-family: VideoJS;
            font-weight: 400;
            font-style: normal;
        }

        .video-js .vjs-captions-button .vjs-icon-placeholder:before,
        .video-js:lang(en) .vjs-subs-caps-button .vjs-icon-placeholder:before,
        .video-js:lang(fr-CA) .vjs-subs-caps-button .vjs-icon-placeholder:before,
        .vjs-icon-captions:before {
            content: "";
        }

        .video-js .vjs-chapters-button .vjs-icon-placeholder,
        .vjs-icon-chapters {
            font-family: VideoJS;
            font-weight: 400;
            font-style: normal;
        }

        .video-js .vjs-chapters-button .vjs-icon-placeholder:before,
        .vjs-icon-chapters:before {
            content: "";
        }

        .vjs-icon-share {
            font-family: VideoJS;
            font-weight: 400;
            font-style: normal;
        }

        .vjs-icon-share:before {
            content: "";
        }

        .vjs-icon-cog {
            font-family: VideoJS;
            font-weight: 400;
            font-style: normal;
        }

        .vjs-icon-cog:before {
            content: "";
        }

        .video-js .vjs-play-progress,
        .video-js .vjs-volume-level,
        .vjs-icon-circle {
            font-family: VideoJS;
            font-weight: 400;
            font-style: normal;
        }

        .video-js .vjs-play-progress:before,
        .video-js .vjs-volume-level:before,
        .vjs-icon-circle:before {
            content: "";
        }

        .vjs-icon-circle-outline {
            font-family: VideoJS;
            font-weight: 400;
            font-style: normal;
        }

        .vjs-icon-circle-outline:before {
            content: "";
        }

        .vjs-icon-circle-inner-circle {
            font-family: VideoJS;
            font-weight: 400;
            font-style: normal;
        }

        .vjs-icon-circle-inner-circle:before {
            content: "";
        }

        .vjs-icon-hd {
            font-family: VideoJS;
            font-weight: 400;
            font-style: normal;
        }

        .vjs-icon-hd:before {
            content: "";
        }

        .video-js .vjs-control.vjs-close-button .vjs-icon-placeholder,
        .vjs-icon-cancel {
            font-family: VideoJS;
            font-weight: 400;
            font-style: normal;
        }

        .video-js .vjs-control.vjs-close-button .vjs-icon-placeholder:before,
        .vjs-icon-cancel:before {
            content: "";
        }

        .video-js .vjs-play-control.vjs-ended .vjs-icon-placeholder,
        .vjs-icon-replay {
            font-family: VideoJS;
            font-weight: 400;
            font-style: normal;
        }

        .video-js .vjs-play-control.vjs-ended .vjs-icon-placeholder:before,
        .vjs-icon-replay:before {
            content: "";
        }

        .vjs-icon-facebook {
            font-family: VideoJS;
            font-weight: 400;
            font-style: normal;
        }

        .vjs-icon-facebook:before {
            content: "";
        }

        .vjs-icon-gplus {
            font-family: VideoJS;
            font-weight: 400;
            font-style: normal;
        }

        .vjs-icon-gplus:before {
            content: "";
        }

        .vjs-icon-linkedin {
            font-family: VideoJS;
            font-weight: 400;
            font-style: normal;
        }

        .vjs-icon-linkedin:before {
            content: "";
        }

        .vjs-icon-twitter {
            font-family: VideoJS;
            font-weight: 400;
            font-style: normal;
        }

        .vjs-icon-twitter:before {
            content: "";
        }

        .vjs-icon-tumblr {
            font-family: VideoJS;
            font-weight: 400;
            font-style: normal;
        }

        .vjs-icon-tumblr:before {
            content: "";
        }

        .vjs-icon-pinterest {
            font-family: VideoJS;
            font-weight: 400;
            font-style: normal;
        }

        .vjs-icon-pinterest:before {
            content: "";
        }

        .video-js .vjs-descriptions-button .vjs-icon-placeholder,
        .vjs-icon-audio-description {
            font-family: VideoJS;
            font-weight: 400;
            font-style: normal;
        }

        .video-js .vjs-descriptions-button .vjs-icon-placeholder:before,
        .vjs-icon-audio-description:before {
            content: "";
        }

        .video-js .vjs-audio-button .vjs-icon-placeholder,
        .vjs-icon-audio {
            font-family: VideoJS;
            font-weight: 400;
            font-style: normal;
        }

        .video-js .vjs-audio-button .vjs-icon-placeholder:before,
        .vjs-icon-audio:before {
            content: "";
        }

        .vjs-icon-next-item {
            font-family: VideoJS;
            font-weight: 400;
            font-style: normal;
        }

        .vjs-icon-next-item:before {
            content: "";
        }

        .vjs-icon-previous-item {
            font-family: VideoJS;
            font-weight: 400;
            font-style: normal;
        }

        .vjs-icon-previous-item:before {
            content: "";
        }

        .video-js {
            display: block;
            vertical-align: top;
            box-sizing: border-box;
            color: #fff;
            background-color: #000;
            position: relative;
            padding: 0;
            font-size: 10px;
            line-height: 1;
            font-weight: 400;
            font-style: normal;
            font-family: Arial, Helvetica, sans-serif;
            word-break: initial;
        }

        .video-js:-moz-full-screen {
            position: absolute;
        }

        .video-js:-webkit-full-screen {
            width: 100% !important;
            height: 100% !important;
        }

        .video-js[tabindex="-1"] {
            outline: 0;
        }

        .video-js *,
        .video-js :after,
        .video-js :before {
            box-sizing: inherit;
        }

        .video-js ul {
            font-family: inherit;
            font-size: inherit;
            line-height: inherit;
            list-style-position: outside;
            margin-left: 0;
            margin-right: 0;
            margin-top: 0;
            margin-bottom: 0;
        }

        .video-js.vjs-16-9,
        .video-js.vjs-4-3,
        .video-js.vjs-fluid {
            width: 100%;
            max-width: 100%;
            height: 0;
        }

        .video-js.vjs-16-9 {
            padding-top: 56.25%;
        }

        .video-js.vjs-4-3 {
            padding-top: 75%;
        }

        .video-js.vjs-fill {
            width: 100%;
            height: 100%;
        }

        .video-js .vjs-tech {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }

        body.vjs-full-window {
            padding: 0;
            margin: 0;
            height: 100%;
            overflow-y: auto;
        }

        .vjs-full-window .video-js.vjs-fullscreen {
            position: fixed;
            overflow: hidden;
            z-index: 1000;
            left: 0;
            top: 0;
            bottom: 0;
            right: 0;
        }

        .video-js.vjs-fullscreen {
            width: 100% !important;
            height: 100% !important;
            padding-top: 0 !important;
        }

        .video-js.vjs-fullscreen.vjs-user-inactive {
            cursor: none;
        }

        .vjs-hidden {
            display: none !important;
        }

        .vjs-disabled {
            opacity: 0.5;
            cursor: default;
        }

        .video-js .vjs-offscreen {
            height: 1px;
            left: -9999px;
            position: absolute;
            top: 0;
            width: 1px;
        }

        .vjs-lock-showing {
            display: block !important;
            opacity: 1;
            visibility: visible;
        }

        .vjs-no-js {
            padding: 20px;
            color: #fff;
            background-color: #000;
            font-size: 18px;
            font-family: Arial, Helvetica, sans-serif;
            text-align: center;
            width: 300px;
            height: 150px;
            margin: 0 auto;
        }

        .vjs-no-js a,
        .vjs-no-js a:visited {
            color: #66a8cc;
        }

        .video-js .vjs-big-play-button {
            font-size: 3em;
            line-height: 1.5em;
            height: 1.5em;
            width: 3em;
            display: block;
            position: absolute;
            top: 10px;
            left: 10px;
            padding: 0;
            cursor: pointer;
            opacity: 1;
            border: 0.06666em solid #fff;
            background-color: #2b333f;
            background-color: rgba(43, 51, 63, 0.7);
            -webkit-border-radius: 0.3em;
            -moz-border-radius: 0.3em;
            border-radius: 0.3em;
            -webkit-transition: all 0.4s;
            -moz-transition: all 0.4s;
            -ms-transition: all 0.4s;
            -o-transition: all 0.4s;
            transition: all 0.4s;
        }

        .vjs-big-play-centered .vjs-big-play-button {
            top: 50%;
            left: 50%;
            margin-top: -0.75em;
            margin-left: -1.5em;
        }

        .video-js .vjs-big-play-button:focus,
        .video-js:hover .vjs-big-play-button {
            border-color: #fff;
            background-color: #73859f;
            background-color: rgba(115, 133, 159, 0.5);
            -webkit-transition: all 0s;
            -moz-transition: all 0s;
            -ms-transition: all 0s;
            -o-transition: all 0s;
            transition: all 0s;
        }

        .vjs-controls-disabled .vjs-big-play-button,
        .vjs-error .vjs-big-play-button,
        .vjs-has-started .vjs-big-play-button,
        .vjs-using-native-controls .vjs-big-play-button {
            display: none;
        }

        .vjs-has-started.vjs-paused.vjs-show-big-play-button-on-pause .vjs-big-play-button {
            display: block;
        }

        .video-js button {
            background: 0 0;
            border: none;
            color: inherit;
            display: inline-block;
            overflow: visible;
            font-size: inherit;
            line-height: inherit;
            text-transform: none;
            text-decoration: none;
            transition: none;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
        }

        .vjs-control .vjs-button {
            width: 100%;
            height: 100%;
        }

        .video-js .vjs-control.vjs-close-button {
            cursor: pointer;
            height: 3em;
            position: absolute;
            right: 0;
            top: 0.5em;
            z-index: 2;
        }

        .video-js .vjs-modal-dialog {
            background: rgba(0, 0, 0, 0.8);
            background: -webkit-linear-gradient(-90deg, rgba(0, 0, 0, 0.8), rgba(255, 255, 255, 0));
            background: linear-gradient(180deg, rgba(0, 0, 0, 0.8), rgba(255, 255, 255, 0));
            overflow: auto;
            box-sizing: content-box;
        }

        .video-js .vjs-modal-dialog > * {
            box-sizing: border-box;
        }

        .vjs-modal-dialog .vjs-modal-dialog-content {
            font-size: 1.2em;
            line-height: 1.5;
            padding: 20px 24px;
            z-index: 1;
        }

        .vjs-menu-button {
            cursor: pointer;
        }

        .vjs-menu-button.vjs-disabled {
            cursor: default;
        }

        .vjs-workinghover .vjs-menu-button.vjs-disabled:hover .vjs-menu {
            display: none;
        }

        .vjs-menu .vjs-menu-content {
            display: block;
            padding: 0;
            margin: 0;
            font-family: Arial, Helvetica, sans-serif;
            overflow: auto;
            box-sizing: content-box;
        }

        .vjs-menu .vjs-menu-content > * {
            box-sizing: border-box;
        }

        .vjs-scrubbing .vjs-menu-button:hover .vjs-menu {
            display: none;
        }

        .vjs-menu li {
            list-style: none;
            margin: 0;
            padding: 0.2em 0;
            line-height: 1.4em;
            font-size: 1.2em;
            text-align: center;
            text-transform: lowercase;
        }

        .vjs-menu li.vjs-menu-item:focus,
        .vjs-menu li.vjs-menu-item:hover {
            background-color: #73859f;
            background-color: rgba(115, 133, 159, 0.5);
        }

        .vjs-menu li.vjs-selected,
        .vjs-menu li.vjs-selected:focus,
        .vjs-menu li.vjs-selected:hover {
            background-color: #fff;
            color: #2b333f;
        }

        .vjs-menu li.vjs-menu-title {
            text-align: center;
            text-transform: uppercase;
            font-size: 1em;
            line-height: 2em;
            padding: 0;
            margin: 0 0 0.3em 0;
            font-weight: 700;
            cursor: default;
        }

        .vjs-menu-button-popup .vjs-menu {
            display: none;
            position: absolute;
            bottom: 0;
            width: 10em;
            left: -3em;
            height: 0;
            margin-bottom: 1.5em;
            border-top-color: rgba(43, 51, 63, 0.7);
        }

        .vjs-menu-button-popup .vjs-menu .vjs-menu-content {
            background-color: #2b333f;
            background-color: rgba(43, 51, 63, 0.7);
            position: absolute;
            width: 100%;
            bottom: 1.5em;
            max-height: 15em;
        }

        .vjs-menu-button-popup .vjs-menu.vjs-lock-showing,
        .vjs-workinghover .vjs-menu-button-popup:hover .vjs-menu {
            display: block;
        }

        .video-js .vjs-menu-button-inline {
            -webkit-transition: all 0.4s;
            -moz-transition: all 0.4s;
            -ms-transition: all 0.4s;
            -o-transition: all 0.4s;
            transition: all 0.4s;
            overflow: hidden;
        }

        .video-js .vjs-menu-button-inline:before {
            width: 2.222222222em;
        }

        .video-js .vjs-menu-button-inline.vjs-slider-active,
        .video-js .vjs-menu-button-inline:focus,
        .video-js .vjs-menu-button-inline:hover,
        .video-js.vjs-no-flex .vjs-menu-button-inline {
            width: 12em;
        }

        .vjs-menu-button-inline .vjs-menu {
            opacity: 0;
            height: 100%;
            width: auto;
            position: absolute;
            left: 4em;
            top: 0;
            padding: 0;
            margin: 0;
            -webkit-transition: all 0.4s;
            -moz-transition: all 0.4s;
            -ms-transition: all 0.4s;
            -o-transition: all 0.4s;
            transition: all 0.4s;
        }

        .vjs-menu-button-inline.vjs-slider-active .vjs-menu,
        .vjs-menu-button-inline:focus .vjs-menu,
        .vjs-menu-button-inline:hover .vjs-menu {
            display: block;
            opacity: 1;
        }

        .vjs-no-flex .vjs-menu-button-inline .vjs-menu {
            display: block;
            opacity: 1;
            position: relative;
            width: auto;
        }

        .vjs-no-flex .vjs-menu-button-inline.vjs-slider-active .vjs-menu,
        .vjs-no-flex .vjs-menu-button-inline:focus .vjs-menu,
        .vjs-no-flex .vjs-menu-button-inline:hover .vjs-menu {
            width: auto;
        }

        .vjs-menu-button-inline .vjs-menu-content {
            width: auto;
            height: 100%;
            margin: 0;
            overflow: hidden;
        }

        .video-js .vjs-control-bar {
            display: none;
            width: 100%;
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            height: 3em;
            background-color: #2b333f;
            background-color: rgba(43, 51, 63, 0.7);
        }

        .vjs-has-started .vjs-control-bar {
            display: -webkit-box;
            display: -webkit-flex;
            display: -ms-flexbox;
            display: flex;
            visibility: visible;
            opacity: 1;
            -webkit-transition: visibility 0.1s, opacity 0.1s;
            -moz-transition: visibility 0.1s, opacity 0.1s;
            -ms-transition: visibility 0.1s, opacity 0.1s;
            -o-transition: visibility 0.1s, opacity 0.1s;
            transition: visibility 0.1s, opacity 0.1s;
        }

        .vjs-has-started.vjs-user-inactive.vjs-playing .vjs-control-bar {
            visibility: visible;
            opacity: 0;
            -webkit-transition: visibility 1s, opacity 1s;
            -moz-transition: visibility 1s, opacity 1s;
            -ms-transition: visibility 1s, opacity 1s;
            -o-transition: visibility 1s, opacity 1s;
            transition: visibility 1s, opacity 1s;
        }

        .vjs-controls-disabled .vjs-control-bar,
        .vjs-error .vjs-control-bar,
        .vjs-using-native-controls .vjs-control-bar {
            display: none !important;
        }

        .vjs-audio.vjs-has-started.vjs-user-inactive.vjs-playing .vjs-control-bar {
            opacity: 1;
            visibility: visible;
        }

        .vjs-has-started.vjs-no-flex .vjs-control-bar {
            display: table;
        }

        .video-js .vjs-control {
            position: relative;
            text-align: center;
            margin: 0;
            padding: 0;
            height: 100%;
            width: 4em;
            -webkit-box-flex: none;
            -moz-box-flex: none;
            -webkit-flex: none;
            -ms-flex: none;
            flex: none;
        }

        .vjs-button > .vjs-icon-placeholder:before {
            font-size: 1.8em;
            line-height: 1.67;
        }

        .video-js .vjs-control:focus,
        .video-js .vjs-control:focus:before,
        .video-js .vjs-control:hover:before {
            text-shadow: 0 0 1em #fff;
        }

        .video-js .vjs-control-text {
            border: 0;
            clip: rect(0 0 0 0);
            height: 1px;
            overflow: hidden;
            padding: 0;
            position: absolute;
            width: 1px;
        }

        .vjs-no-flex .vjs-control {
            display: table-cell;
            vertical-align: middle;
        }

        .video-js .vjs-custom-control-spacer {
            display: none;
        }

        .video-js .vjs-progress-control {
            cursor: pointer;
            -webkit-box-flex: auto;
            -moz-box-flex: auto;
            -webkit-flex: auto;
            -ms-flex: auto;
            flex: auto;
            display: -webkit-box;
            display: -webkit-flex;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -webkit-align-items: center;
            -ms-flex-align: center;
            align-items: center;
            min-width: 4em;
        }

        .video-js .vjs-progress-control.disabled {
            cursor: default;
        }

        .vjs-live .vjs-progress-control {
            display: none;
        }

        .vjs-no-flex .vjs-progress-control {
            width: auto;
        }

        .video-js .vjs-progress-holder {
            -webkit-box-flex: auto;
            -moz-box-flex: auto;
            -webkit-flex: auto;
            -ms-flex: auto;
            flex: auto;
            -webkit-transition: all 0.2s;
            -moz-transition: all 0.2s;
            -ms-transition: all 0.2s;
            -o-transition: all 0.2s;
            transition: all 0.2s;
            height: 0.3em;
        }

        .video-js .vjs-progress-control .vjs-progress-holder {
            margin: 0 10px;
        }

        .video-js .vjs-progress-control:hover .vjs-progress-holder {
            font-size: 1.666666666666666666em;
        }

        .video-js .vjs-progress-control:hover .vjs-progress-holder.disabled {
            font-size: 1em;
        }

        .video-js .vjs-progress-holder .vjs-load-progress,
        .video-js .vjs-progress-holder .vjs-load-progress div,
        .video-js .vjs-progress-holder .vjs-play-progress {
            position: absolute;
            display: block;
            height: 100%;
            margin: 0;
            padding: 0;
            width: 0;
            left: 0;
            top: 0;
        }

        .video-js .vjs-play-progress {
            background-color: #fff;
        }

        .video-js .vjs-play-progress:before {
            font-size: 0.9em;
            position: absolute;
            right: -0.5em;
            top: -0.333333333333333em;
            z-index: 1;
        }

        .video-js .vjs-load-progress {
            background: #bfc7d3;
            background: rgba(115, 133, 159, 0.5);
        }

        .video-js .vjs-load-progress div {
            background: #fff;
            background: rgba(115, 133, 159, 0.75);
        }

        .video-js .vjs-time-tooltip {
            background-color: #fff;
            background-color: rgba(255, 255, 255, 0.8);
            -webkit-border-radius: 0.3em;
            -moz-border-radius: 0.3em;
            border-radius: 0.3em;
            color: #000;
            float: right;
            font-family: Arial, Helvetica, sans-serif;
            font-size: 1em;
            padding: 6px 8px 8px 8px;
            pointer-events: none;
            position: relative;
            top: -3.4em;
            visibility: hidden;
            z-index: 1;
        }

        .video-js .vjs-progress-holder:focus .vjs-time-tooltip {
            display: none;
        }

        .video-js .vjs-progress-control:hover .vjs-progress-holder:focus .vjs-time-tooltip,
        .video-js .vjs-progress-control:hover .vjs-time-tooltip {
            display: block;
            font-size: 0.6em;
            visibility: visible;
        }

        .video-js .vjs-progress-control.disabled:hover .vjs-time-tooltip {
            font-size: 1em;
        }

        .video-js .vjs-progress-control .vjs-mouse-display {
            display: none;
            position: absolute;
            width: 1px;
            height: 100%;
            background-color: #000;
            z-index: 1;
        }

        .vjs-no-flex .vjs-progress-control .vjs-mouse-display {
            z-index: 0;
        }

        .video-js .vjs-progress-control:hover .vjs-mouse-display {
            display: block;
        }

        .video-js.vjs-user-inactive .vjs-progress-control .vjs-mouse-display {
            visibility: hidden;
            opacity: 0;
            -webkit-transition: visibility 1s, opacity 1s;
            -moz-transition: visibility 1s, opacity 1s;
            -ms-transition: visibility 1s, opacity 1s;
            -o-transition: visibility 1s, opacity 1s;
            transition: visibility 1s, opacity 1s;
        }

        .video-js.vjs-user-inactive.vjs-no-flex .vjs-progress-control .vjs-mouse-display {
            display: none;
        }

        .vjs-mouse-display .vjs-time-tooltip {
            color: #fff;
            background-color: #000;
            background-color: rgba(0, 0, 0, 0.8);
        }

        .video-js .vjs-slider {
            position: relative;
            cursor: pointer;
            padding: 0;
            margin: 0 0.45em 0 0.45em;
            -webkit-touch-callout: none;
            -webkit-user-select: none;
            -khtml-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            background-color: #73859f;
            background-color: rgba(115, 133, 159, 0.5);
        }

        .video-js .vjs-slider.disabled {
            cursor: default;
        }

        .video-js .vjs-slider:focus {
            text-shadow: 0 0 1em #fff;
            -webkit-box-shadow: 0 0 1em #fff;
            -moz-box-shadow: 0 0 1em #fff;
            box-shadow: 0 0 1em #fff;
        }

        .video-js .vjs-mute-control {
            cursor: pointer;
            -webkit-box-flex: none;
            -moz-box-flex: none;
            -webkit-flex: none;
            -ms-flex: none;
            flex: none;
            padding-left: 2em;
            padding-right: 2em;
            padding-bottom: 3em;
        }

        .video-js .vjs-volume-control {
            cursor: pointer;
            margin-right: 1em;
            display: -webkit-box;
            display: -webkit-flex;
            display: -ms-flexbox;
            display: flex;
        }

        .video-js .vjs-volume-control.vjs-volume-horizontal {
            width: 5em;
        }

        .video-js .vjs-volume-panel .vjs-volume-control {
            visibility: visible;
            opacity: 0;
            width: 1px;
            height: 1px;
            margin-left: -1px;
        }

        .video-js .vjs-volume-panel {
            -webkit-transition: width 1s;
            -moz-transition: width 1s;
            -ms-transition: width 1s;
            -o-transition: width 1s;
            transition: width 1s;
        }

        .video-js .vjs-volume-panel .vjs-mute-control:hover ~ .vjs-volume-control,
        .video-js .vjs-volume-panel .vjs-volume-control.vjs-slider-active,
        .video-js .vjs-volume-panel .vjs-volume-control:active,
        .video-js .vjs-volume-panel .vjs-volume-control:hover,
        .video-js .vjs-volume-panel:active .vjs-volume-control,
        .video-js .vjs-volume-panel:focus .vjs-volume-control,
        .video-js .vjs-volume-panel:hover .vjs-volume-control {
            visibility: visible;
            opacity: 1;
            position: relative;
            -webkit-transition: visibility 0.1s, opacity 0.1s, height 0.1s, width 0.1s, left 0s, top 0s;
            -moz-transition: visibility 0.1s, opacity 0.1s, height 0.1s, width 0.1s, left 0s, top 0s;
            -ms-transition: visibility 0.1s, opacity 0.1s, height 0.1s, width 0.1s, left 0s, top 0s;
            -o-transition: visibility 0.1s, opacity 0.1s, height 0.1s, width 0.1s, left 0s, top 0s;
            transition: visibility 0.1s, opacity 0.1s, height 0.1s, width 0.1s, left 0s, top 0s;
        }

        .video-js .vjs-volume-panel .vjs-mute-control:hover ~ .vjs-volume-control.vjs-volume-horizontal,
        .video-js .vjs-volume-panel .vjs-volume-control.vjs-slider-active.vjs-volume-horizontal,
        .video-js .vjs-volume-panel .vjs-volume-control:active.vjs-volume-horizontal,
        .video-js .vjs-volume-panel .vjs-volume-control:hover.vjs-volume-horizontal,
        .video-js .vjs-volume-panel:active .vjs-volume-control.vjs-volume-horizontal,
        .video-js .vjs-volume-panel:focus .vjs-volume-control.vjs-volume-horizontal,
        .video-js .vjs-volume-panel:hover .vjs-volume-control.vjs-volume-horizontal {
            width: 5em;
            height: 3em;
        }

        .video-js .vjs-volume-panel.vjs-volume-panel-horizontal.vjs-slider-active,
        .video-js .vjs-volume-panel.vjs-volume-panel-horizontal:active,
        .video-js .vjs-volume-panel.vjs-volume-panel-horizontal:hover {
            width: 9em;
            -webkit-transition: width 0.1s;
            -moz-transition: width 0.1s;
            -ms-transition: width 0.1s;
            -o-transition: width 0.1s;
            transition: width 0.1s;
        }

        .video-js .vjs-volume-panel .vjs-volume-control.vjs-volume-vertical {
            height: 8em;
            width: 3em;
            left: -3.5em;
            -webkit-transition: visibility 1s, opacity 1s, height 1s 1s, width 1s 1s, left 1s 1s, top 1s 1s;
            -moz-transition: visibility 1s, opacity 1s, height 1s 1s, width 1s 1s, left 1s 1s, top 1s 1s;
            -ms-transition: visibility 1s, opacity 1s, height 1s 1s, width 1s 1s, left 1s 1s, top 1s 1s;
            -o-transition: visibility 1s, opacity 1s, height 1s 1s, width 1s 1s, left 1s 1s, top 1s 1s;
            transition: visibility 1s, opacity 1s, height 1s 1s, width 1s 1s, left 1s 1s, top 1s 1s;
        }

        .video-js .vjs-volume-panel .vjs-volume-control.vjs-volume-horizontal {
            -webkit-transition: visibility 1s, opacity 1s, height 1s 1s, width 1s, left 1s 1s, top 1s 1s;
            -moz-transition: visibility 1s, opacity 1s, height 1s 1s, width 1s, left 1s 1s, top 1s 1s;
            -ms-transition: visibility 1s, opacity 1s, height 1s 1s, width 1s, left 1s 1s, top 1s 1s;
            -o-transition: visibility 1s, opacity 1s, height 1s 1s, width 1s, left 1s 1s, top 1s 1s;
            transition: visibility 1s, opacity 1s, height 1s 1s, width 1s, left 1s 1s, top 1s 1s;
        }

        .video-js.vjs-no-flex .vjs-volume-panel .vjs-volume-control.vjs-volume-horizontal {
            width: 5em;
            height: 3em;
            visibility: visible;
            opacity: 1;
            position: relative;
            -webkit-transition: none;
            -moz-transition: none;
            -ms-transition: none;
            -o-transition: none;
            transition: none;
        }

        .video-js.vjs-no-flex .vjs-volume-control.vjs-volume-vertical,
        .video-js.vjs-no-flex .vjs-volume-panel .vjs-volume-control.vjs-volume-vertical {
            position: absolute;
            bottom: 3em;
            left: 0.5em;
        }

        .video-js .vjs-volume-panel {
            display: -webkit-box;
            display: -webkit-flex;
            display: -ms-flexbox;
            display: flex;
        }

        .video-js .vjs-volume-bar {
            margin: 1.35em 0.45em;
        }

        .vjs-volume-bar.vjs-slider-horizontal {
            width: 5em;
            height: 0.3em;
        }

        .vjs-volume-bar.vjs-slider-vertical {
            width: 0.3em;
            height: 5em;
            margin: 1.35em auto;
        }

        .video-js .vjs-volume-level {
            position: absolute;
            bottom: 0;
            left: 0;
            background-color: #fff;
        }

        .video-js .vjs-volume-level:before {
            position: absolute;
            font-size: 0.9em;
        }

        .vjs-slider-vertical .vjs-volume-level {
            width: 0.3em;
        }

        .vjs-slider-vertical .vjs-volume-level:before {
            top: -0.5em;
            left: -0.3em;
        }

        .vjs-slider-horizontal .vjs-volume-level {
            height: 0.3em;
        }

        .vjs-slider-horizontal .vjs-volume-level:before {
            top: -0.3em;
            right: -0.5em;
        }

        .video-js .vjs-volume-panel.vjs-volume-panel-vertical {
            width: 4em;
        }

        .vjs-volume-bar.vjs-slider-vertical .vjs-volume-level {
            height: 100%;
        }

        .vjs-volume-bar.vjs-slider-horizontal .vjs-volume-level {
            width: 100%;
        }

        .video-js .vjs-volume-vertical {
            width: 3em;
            height: 8em;
            bottom: 8em;
            background-color: #2b333f;
            background-color: rgba(43, 51, 63, 0.7);
        }

        .video-js .vjs-volume-horizontal .vjs-menu {
            left: -2em;
        }

        .vjs-poster {
            display: inline-block;
            vertical-align: middle;
            background-repeat: no-repeat;
            background-position: 50% 50%;
            background-size: contain;
            background-color: #000;
            cursor: pointer;
            margin: 0;
            padding: 0;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            height: 100%;
        }

        .vjs-poster img {
            display: block;
            vertical-align: middle;
            margin: 0 auto;
            max-height: 100%;
            padding: 0;
            width: 100%;
        }

        .vjs-has-started .vjs-poster {
            display: none;
        }

        .vjs-audio.vjs-has-started .vjs-poster {
            display: block;
        }

        .vjs-using-native-controls .vjs-poster {
            display: none;
        }

        .video-js .vjs-live-control {
            display: -webkit-box;
            display: -webkit-flex;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: flex-start;
            -webkit-align-items: flex-start;
            -ms-flex-align: flex-start;
            align-items: flex-start;
            -webkit-box-flex: auto;
            -moz-box-flex: auto;
            -webkit-flex: auto;
            -ms-flex: auto;
            flex: auto;
            font-size: 1em;
            line-height: 3em;
        }

        .vjs-no-flex .vjs-live-control {
            display: table-cell;
            width: auto;
            text-align: left;
        }

        .video-js .vjs-time-control {
            -webkit-box-flex: none;
            -moz-box-flex: none;
            -webkit-flex: none;
            -ms-flex: none;
            flex: none;
            font-size: 1em;
            line-height: 3em;
            min-width: 2em;
            width: auto;
            padding-left: 1em;
            padding-right: 1em;
        }

        .vjs-live .vjs-time-control {
            display: none;
        }

        .video-js .vjs-current-time,
        .vjs-no-flex .vjs-current-time {
            display: none;
        }

        .vjs-no-flex .vjs-remaining-time.vjs-time-control.vjs-control {
            width: 0 !important;
            white-space: nowrap;
        }

        .video-js .vjs-duration,
        .vjs-no-flex .vjs-duration {
            display: none;
        }

        .vjs-time-divider {
            display: none;
            line-height: 3em;
        }

        .vjs-live .vjs-time-divider {
            display: none;
        }

        .video-js .vjs-play-control .vjs-icon-placeholder {
            cursor: pointer;
            -webkit-box-flex: none;
            -moz-box-flex: none;
            -webkit-flex: none;
            -ms-flex: none;
            flex: none;
        }

        .vjs-text-track-display {
            position: absolute;
            bottom: 3em;
            left: 0;
            right: 0;
            top: 0;
            pointer-events: none;
        }

        .video-js.vjs-user-inactive.vjs-playing .vjs-text-track-display {
            bottom: 1em;
        }

        .video-js .vjs-text-track {
            font-size: 1.4em;
            text-align: center;
            margin-bottom: 0.1em;
            background-color: #000;
            background-color: rgba(0, 0, 0, 0.5);
        }

        .vjs-subtitles {
            color: #fff;
        }

        .vjs-captions {
            color: #fc6;
        }

        .vjs-tt-cue {
            display: block;
        }

        video::-webkit-media-text-track-display {
            -moz-transform: translateY(-3em);
            -ms-transform: translateY(-3em);
            -o-transform: translateY(-3em);
            -webkit-transform: translateY(-3em);
            transform: translateY(-3em);
        }

        .video-js.vjs-user-inactive.vjs-playing video::-webkit-media-text-track-display {
            -moz-transform: translateY(-1.5em);
            -ms-transform: translateY(-1.5em);
            -o-transform: translateY(-1.5em);
            -webkit-transform: translateY(-1.5em);
            transform: translateY(-1.5em);
        }

        .video-js .vjs-fullscreen-control {
            cursor: pointer;
            -webkit-box-flex: none;
            -moz-box-flex: none;
            -webkit-flex: none;
            -ms-flex: none;
            flex: none;
        }

        .vjs-playback-rate .vjs-playback-rate-value,
        .vjs-playback-rate > .vjs-menu-button {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }

        .vjs-playback-rate .vjs-playback-rate-value {
            pointer-events: none;
            font-size: 1.5em;
            line-height: 2;
            text-align: center;
        }

        .vjs-playback-rate .vjs-menu {
            width: 4em;
            left: 0;
        }

        .vjs-error .vjs-error-display .vjs-modal-dialog-content {
            font-size: 1.4em;
            text-align: center;
        }

        .vjs-error .vjs-error-display:before {
            color: #fff;
            content: "X";
            font-family: Arial, Helvetica, sans-serif;
            font-size: 4em;
            left: 0;
            line-height: 1;
            margin-top: -0.5em;
            position: absolute;
            text-shadow: 0.05em 0.05em 0.1em #000;
            text-align: center;
            top: 50%;
            vertical-align: middle;
            width: 100%;
        }

        .vjs-loading-spinner {
            display: none;
            position: absolute;
            top: 50%;
            left: 50%;
            margin: -25px 0 0 -25px;
            opacity: 0.85;
            text-align: left;
            border: 6px solid rgba(43, 51, 63, 0.7);
            box-sizing: border-box;
            background-clip: padding-box;
            width: 50px;
            height: 50px;
            border-radius: 25px;
            visibility: hidden;
        }

        .vjs-seeking .vjs-loading-spinner,
        .vjs-waiting .vjs-loading-spinner {
            display: block;
            animation: 0s linear 0.3s forwards vjs-spinner-show;
        }

        .vjs-loading-spinner:after,
        .vjs-loading-spinner:before {
            content: "";
            position: absolute;
            margin: -6px;
            box-sizing: inherit;
            width: inherit;
            height: inherit;
            border-radius: inherit;
            opacity: 1;
            border: inherit;
            border-color: transparent;
            border-top-color: #fff;
        }

        .vjs-seeking .vjs-loading-spinner:after,
        .vjs-seeking .vjs-loading-spinner:before,
        .vjs-waiting .vjs-loading-spinner:after,
        .vjs-waiting .vjs-loading-spinner:before {
            -webkit-animation: vjs-spinner-spin 1.1s cubic-bezier(0.6, 0.2, 0, 0.8) infinite,
                vjs-spinner-fade 1.1s linear infinite;
            animation: vjs-spinner-spin 1.1s cubic-bezier(0.6, 0.2, 0, 0.8) infinite,
                vjs-spinner-fade 1.1s linear infinite;
        }

        .vjs-seeking .vjs-loading-spinner:before,
        .vjs-waiting .vjs-loading-spinner:before {
            border-top-color: #fff;
        }

        .vjs-seeking .vjs-loading-spinner:after,
        .vjs-waiting .vjs-loading-spinner:after {
            border-top-color: #fff;
            -webkit-animation-delay: 0.44s;
            animation-delay: 0.44s;
        }

        @keyframes vjs-spinner-show {
            to {
                visibility: visible;
            }
        }

        @-webkit-keyframes vjs-spinner-show {
            to {
                visibility: visible;
            }
        }

        @keyframes vjs-spinner-spin {
            100% {
                transform: rotate(360deg);
            }
        }

        @-webkit-keyframes vjs-spinner-spin {
            100% {
                -webkit-transform: rotate(360deg);
            }
        }

        @keyframes vjs-spinner-fade {
            0% {
                border-top-color: #73859f;
            }

            20% {
                border-top-color: #73859f;
            }

            35% {
                border-top-color: #fff;
            }

            60% {
                border-top-color: #73859f;
            }

            100% {
                border-top-color: #73859f;
            }
        }

        @-webkit-keyframes vjs-spinner-fade {
            0% {
                border-top-color: #73859f;
            }

            20% {
                border-top-color: #73859f;
            }

            35% {
                border-top-color: #fff;
            }

            60% {
                border-top-color: #73859f;
            }

            100% {
                border-top-color: #73859f;
            }
        }

        .vjs-chapters-button .vjs-menu ul {
            width: 24em;
        }

        .video-js .vjs-subs-caps-button + .vjs-menu .vjs-captions-menu-item .vjs-menu-item-text .vjs-icon-placeholder {
            position: absolute;
        }

        .video-js
            .vjs-subs-caps-button
            + .vjs-menu
            .vjs-captions-menu-item
            .vjs-menu-item-text
            .vjs-icon-placeholder:before {
            font-family: VideoJS;
            content: "";
            font-size: 1.5em;
            line-height: inherit;
        }

        .video-js.vjs-layout-tiny:not(.vjs-fullscreen) .vjs-custom-control-spacer {
            -webkit-box-flex: auto;
            -moz-box-flex: auto;
            -webkit-flex: auto;
            -ms-flex: auto;
            flex: auto;
        }

        .video-js.vjs-layout-tiny:not(.vjs-fullscreen).vjs-no-flex .vjs-custom-control-spacer {
            width: auto;
        }

        .video-js.vjs-layout-tiny:not(.vjs-fullscreen) .vjs-audio-button,
        .video-js.vjs-layout-tiny:not(.vjs-fullscreen) .vjs-captions-button,
        .video-js.vjs-layout-tiny:not(.vjs-fullscreen) .vjs-chapters-button,
        .video-js.vjs-layout-tiny:not(.vjs-fullscreen) .vjs-current-time,
        .video-js.vjs-layout-tiny:not(.vjs-fullscreen) .vjs-descriptions-button,
        .video-js.vjs-layout-tiny:not(.vjs-fullscreen) .vjs-duration,
        .video-js.vjs-layout-tiny:not(.vjs-fullscreen) .vjs-mute-control,
        .video-js.vjs-layout-tiny:not(.vjs-fullscreen) .vjs-playback-rate,
        .video-js.vjs-layout-tiny:not(.vjs-fullscreen) .vjs-progress-control,
        .video-js.vjs-layout-tiny:not(.vjs-fullscreen) .vjs-remaining-time,
        .video-js.vjs-layout-tiny:not(.vjs-fullscreen) .vjs-subtitles-button,
        .video-js.vjs-layout-tiny:not(.vjs-fullscreen) .vjs-time-divider,
        .video-js.vjs-layout-tiny:not(.vjs-fullscreen) .vjs-volume-control {
            display: none;
        }

        .video-js.vjs-layout-x-small:not(.vjs-fullscreen) .vjs-audio-button,
        .video-js.vjs-layout-x-small:not(.vjs-fullscreen) .vjs-captions-button,
        .video-js.vjs-layout-x-small:not(.vjs-fullscreen) .vjs-chapters-button,
        .video-js.vjs-layout-x-small:not(.vjs-fullscreen) .vjs-current-time,
        .video-js.vjs-layout-x-small:not(.vjs-fullscreen) .vjs-descriptions-button,
        .video-js.vjs-layout-x-small:not(.vjs-fullscreen) .vjs-duration,
        .video-js.vjs-layout-x-small:not(.vjs-fullscreen) .vjs-mute-control,
        .video-js.vjs-layout-x-small:not(.vjs-fullscreen) .vjs-playback-rate,
        .video-js.vjs-layout-x-small:not(.vjs-fullscreen) .vjs-remaining-time,
        .video-js.vjs-layout-x-small:not(.vjs-fullscreen) .vjs-subtitles-button,
        .video-js.vjs-layout-x-small:not(.vjs-fullscreen) .vjs-time-divider,
        .video-js.vjs-layout-x-small:not(.vjs-fullscreen) .vjs-volume-control {
            display: none;
        }

        .video-js.vjs-layout-small:not(.vjs-fullscreen) .vjs-captions-button,
        .video-js.vjs-layout-small:not(.vjs-fullscreen) .vjs-chapters-button,
        .video-js.vjs-layout-small:not(.vjs-fullscreen) .vjs-current-time,
        .video-js.vjs-layout-small:not(.vjs-fullscreen) .vjs-descriptions-button,
        .video-js.vjs-layout-small:not(.vjs-fullscreen) .vjs-duration,
        .video-js.vjs-layout-small:not(.vjs-fullscreen) .vjs-mute-control,
        .video-js.vjs-layout-small:not(.vjs-fullscreen) .vjs-playback-rate,
        .video-js.vjs-layout-small:not(.vjs-fullscreen) .vjs-remaining-time,
        .video-js.vjs-layout-small:not(.vjs-fullscreen) .vjs-subtitles-button .vjs-audio-button,
        .video-js.vjs-layout-small:not(.vjs-fullscreen) .vjs-time-divider,
        .video-js.vjs-layout-small:not(.vjs-fullscreen) .vjs-volume-control {
            display: none;
        }

        .vjs-modal-dialog.vjs-text-track-settings {
            background-color: #2b333f;
            background-color: rgba(43, 51, 63, 0.75);
            color: #fff;
            height: 70%;
        }

        .vjs-text-track-settings .vjs-modal-dialog-content {
            display: table;
        }

        .vjs-text-track-settings .vjs-track-settings-colors,
        .vjs-text-track-settings .vjs-track-settings-controls,
        .vjs-text-track-settings .vjs-track-settings-font {
            display: table-cell;
        }

        .vjs-text-track-settings .vjs-track-settings-controls {
            text-align: right;
            vertical-align: bottom;
        }

        .vjs-text-track-settings fieldset {
            margin: 5px;
            padding: 3px;
            border: none;
        }

        .vjs-text-track-settings fieldset span {
            display: inline-block;
            margin-left: 5px;
        }

        .vjs-text-track-settings legend {
            color: #fff;
            margin: 0 0 5px 0;
        }

        .vjs-text-track-settings .vjs-label {
            position: absolute;
            clip: rect(1px 1px 1px 1px);
            clip: rect(1px, 1px, 1px, 1px);
            display: block;
            margin: 0 0 5px 0;
            padding: 0;
            border: 0;
            height: 1px;
            width: 1px;
            overflow: hidden;
        }

        .vjs-track-settings-controls button:active,
        .vjs-track-settings-controls button:focus {
            outline-style: solid;
            outline-width: medium;
            background-image: linear-gradient(0deg, #fff 88%, #73859f 100%);
        }

        .vjs-track-settings-controls button:hover {
            color: rgba(43, 51, 63, 0.75);
        }

        .vjs-track-settings-controls button {
            background-color: #fff;
            background-image: linear-gradient(-180deg, #fff 88%, #73859f 100%);
            color: #2b333f;
            cursor: pointer;
            border-radius: 2px;
        }

        .vjs-track-settings-controls .vjs-default-button {
            margin-right: 1em;
        }

        @media print {
            .video-js > :not(.vjs-tech):not(.vjs-poster) {
                visibility: hidden;
            }
        }

        .vjs-resize-manager {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border: none;
            visibility: hidden;
        }

        @media \0screen {
            .vjs-user-inactive.vjs-playing .vjs-control-bar :before {
                content: "";
            }
        }

        @media \0screen {
            .vjs-has-started.vjs-user-inactive.vjs-playing .vjs-control-bar {
                visibility: hidden;
            }
        }

        #tiptip_holder {
            display: none;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 99999;
        }

        #tiptip_holder.tip_top {
            padding-bottom: 5px;
        }

        #tiptip_holder.tip_bottom {
            padding-top: 5px;
        }

        #tiptip_holder.tip_right {
            padding-left: 5px;
        }

        #tiptip_holder.tip_left {
            padding-right: 5px;
        }

        #tiptip_content {
            font-size: 11px;
            color: #fff;
            text-shadow: 0 0 2px #000;
            padding: 4px 8px;
            border: 1px solid rgba(255, 255, 255, 0.25);
            background-color: #191919;
            background-color: rgba(25, 25, 25, 0.92);
            background-image: -webkit-gradient(linear, 0% 0%, 0% 100%, from(transparent), to(#000));
            border-radius: 3px;
            -webkit-border-radius: 3px;
            -moz-border-radius: 3px;
            box-shadow: 0 0 3px #555;
            -webkit-box-shadow: 0 0 3px #555;
            -moz-box-shadow: 0 0 3px #555;
        }

        #tiptip_arrow,
        #tiptip_arrow_inner {
            position: absolute;
            border-color: transparent;
            border-style: solid;
            border-width: 6px;
            height: 0;
            width: 0;
        }

        #tiptip_holder.tip_top #tiptip_arrow {
            border-top-color: #fff;
            border-top-color: rgba(255, 255, 255, 0.35);
        }

        #tiptip_holder.tip_bottom #tiptip_arrow {
            border-bottom-color: #fff;
            border-bottom-color: rgba(255, 255, 255, 0.35);
        }

        #tiptip_holder.tip_right #tiptip_arrow {
            border-right-color: #fff;
            border-right-color: rgba(255, 255, 255, 0.35);
        }

        #tiptip_holder.tip_left #tiptip_arrow {
            border-left-color: #fff;
            border-left-color: rgba(255, 255, 255, 0.35);
        }

        #tiptip_holder.tip_top #tiptip_arrow_inner {
            margin-top: -7px;
            margin-left: -6px;
            border-top-color: #191919;
            border-top-color: rgba(25, 25, 25, 0.92);
        }

        #tiptip_holder.tip_bottom #tiptip_arrow_inner {
            margin-top: -5px;
            margin-left: -6px;
            border-bottom-color: #191919;
            border-bottom-color: rgba(25, 25, 25, 0.92);
        }

        #tiptip_holder.tip_right #tiptip_arrow_inner {
            margin-top: -6px;
            margin-left: -5px;
            border-right-color: #191919;
            border-right-color: rgba(25, 25, 25, 0.92);
        }

        #tiptip_holder.tip_left #tiptip_arrow_inner {
            margin-top: -6px;
            margin-left: -7px;
            border-left-color: #191919;
            border-left-color: rgba(25, 25, 25, 0.92);
        }

        @media screen and (-webkit-min-device-pixel-ratio: 0) {
            #tiptip_content {
                padding: 4px 8px 5px 8px;
                background-color: rgba(45, 45, 45, 0.88);
            }

            #tiptip_holder.tip_bottom #tiptip_arrow_inner {
                border-bottom-color: rgba(45, 45, 45, 0.88);
            }

            #tiptip_holder.tip_top #tiptip_arrow_inner {
                border-top-color: rgba(20, 20, 20, 0.92);
            }
        }

        .bootstrap-timepicker {
            position: relative;
        }

        .bootstrap-timepicker.pull-right .bootstrap-timepicker-widget.dropdown-menu {
            left: auto;
            right: 0;
        }

        .bootstrap-timepicker.pull-right .bootstrap-timepicker-widget.dropdown-menu:before {
            left: auto;
            right: 12px;
        }

        .bootstrap-timepicker.pull-right .bootstrap-timepicker-widget.dropdown-menu:after {
            left: auto;
            right: 13px;
        }

        .bootstrap-timepicker .input-group-addon {
            cursor: pointer;
        }

        .bootstrap-timepicker .input-group-addon i {
            display: inline-block;
            width: 16px;
            height: 16px;
        }

        .bootstrap-timepicker-widget.dropdown-menu {
            padding: 4px;
        }

        .bootstrap-timepicker-widget.dropdown-menu.open {
            display: inline-block;
        }

        .bootstrap-timepicker-widget.dropdown-menu:before {
            border-bottom: 7px solid rgba(0, 0, 0, 0.2);
            border-left: 7px solid transparent;
            border-right: 7px solid transparent;
            content: "";
            display: inline-block;
            position: absolute;
        }

        .bootstrap-timepicker-widget.dropdown-menu:after {
            border-bottom: 6px solid #fff;
            border-left: 6px solid transparent;
            border-right: 6px solid transparent;
            content: "";
            display: inline-block;
            position: absolute;
        }

        .bootstrap-timepicker-widget.timepicker-orient-left:before {
            left: 6px;
        }

        .bootstrap-timepicker-widget.timepicker-orient-left:after {
            left: 7px;
        }

        .bootstrap-timepicker-widget.timepicker-orient-right:before {
            right: 6px;
        }

        .bootstrap-timepicker-widget.timepicker-orient-right:after {
            right: 7px;
        }

        .bootstrap-timepicker-widget.timepicker-orient-top:before {
            top: -7px;
        }

        .bootstrap-timepicker-widget.timepicker-orient-top:after {
            top: -6px;
        }

        .bootstrap-timepicker-widget.timepicker-orient-bottom:before {
            bottom: -7px;
            border-bottom: 0;
            border-top: 7px solid #999;
        }

        .bootstrap-timepicker-widget.timepicker-orient-bottom:after {
            bottom: -6px;
            border-bottom: 0;
            border-top: 6px solid #fff;
        }

        .bootstrap-timepicker-widget a.btn,
        .bootstrap-timepicker-widget input {
            border-radius: 4px;
        }

        .bootstrap-timepicker-widget table {
            width: 100%;
            margin: 0;
        }

        .bootstrap-timepicker-widget table td {
            text-align: center;
            height: 30px;
            margin: 0;
            padding: 2px;
        }

        .bootstrap-timepicker-widget table td:not(.separator) {
            min-width: 30px;
        }

        .bootstrap-timepicker-widget table td span {
            width: 100%;
        }

        .bootstrap-timepicker-widget table td a {
            border: 1px transparent solid;
            width: 100%;
            display: inline-block;
            margin: 0;
            padding: 8px 0;
            outline: 0;
            color: #333;
        }

        .bootstrap-timepicker-widget table td a:hover {
            text-decoration: none;
            background-color: #eee;
            -webkit-border-radius: 4px;
            -moz-border-radius: 4px;
            border-radius: 4px;
            border-color: #ddd;
        }

        .bootstrap-timepicker-widget table td a i {
            margin-top: 2px;
            font-size: 18px;
        }

        .bootstrap-timepicker-widget table td input {
            width: 25px;
            margin: 0;
            text-align: center;
        }

        .bootstrap-timepicker-widget .modal-content {
            padding: 4px;
        }

        @media (min-width: 767px) {
            .bootstrap-timepicker-widget.modal {
                width: 200px;
                margin-left: -100px;
            }
        }

        @media (max-width: 767px) {
            .bootstrap-timepicker {
                width: 100%;
            }

            .bootstrap-timepicker .dropdown-menu {
                width: 100%;
            }
        }

        .fa,
        .fas,
        .far,
        .fal,
        .fab {
            -moz-osx-font-smoothing: grayscale;
            -webkit-font-smoothing: antialiased;
            display: inline-block;
            font-style: normal;
            font-variant: normal;
            text-rendering: auto;
            line-height: 1;
        }

        .fa-lg {
            font-size: 1.33333em;
            line-height: 0.75em;
            vertical-align: -0.0667em;
        }

        .fa-xs {
            font-size: 0.75em;
        }

        .fa-sm {
            font-size: 0.875em;
        }

        .fa-1x {
            font-size: 1em;
        }

        .fa-2x {
            font-size: 2em;
        }

        .fa-3x {
            font-size: 3em;
        }

        .fa-4x {
            font-size: 4em;
        }

        .fa-5x {
            font-size: 5em;
        }

        .fa-6x {
            font-size: 6em;
        }

        .fa-7x {
            font-size: 7em;
        }

        .fa-8x {
            font-size: 8em;
        }

        .fa-9x {
            font-size: 9em;
        }

        .fa-10x {
            font-size: 10em;
        }

        .fa-fw {
            text-align: center;
            width: 1.25em;
        }

        .fa-ul {
            list-style-type: none;
            margin-left: 2.5em;
            padding-left: 0;
        }

        .fa-ul > li {
            position: relative;
        }

        .fa-li {
            left: -2em;
            position: absolute;
            text-align: center;
            width: 2em;
            line-height: inherit;
        }

        .fa-border {
            border: solid 0.08em #eee;
            border-radius: 0.1em;
            padding: 0.2em 0.25em 0.15em;
        }

        .fa-pull-left {
            float: left;
        }

        .fa-pull-right {
            float: right;
        }

        .fa.fa-pull-left,
        .fas.fa-pull-left,
        .far.fa-pull-left,
        .fal.fa-pull-left,
        .fab.fa-pull-left {
            margin-right: 0.3em;
        }

        .fa.fa-pull-right,
        .fas.fa-pull-right,
        .far.fa-pull-right,
        .fal.fa-pull-right,
        .fab.fa-pull-right {
            margin-left: 0.3em;
        }

        .fa-spin {
            -webkit-animation: fa-spin 2s infinite linear;
            animation: fa-spin 2s infinite linear;
        }

        .fa-pulse {
            -webkit-animation: fa-spin 1s infinite steps(8);
            animation: fa-spin 1s infinite steps(8);
        }

        @-webkit-keyframes fa-spin {
            0% {
                -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
            }

            100% {
                -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
            }
        }

        @keyframes fa-spin {
            0% {
                -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
            }

            100% {
                -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
            }
        }

        .fa-rotate-90 {
            -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=1)";
            -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
        }

        .fa-rotate-180 {
            -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2)";
            -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
        }

        .fa-rotate-270 {
            -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=3)";
            -webkit-transform: rotate(270deg);
            transform: rotate(270deg);
        }

        .fa-flip-horizontal {
            -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1)";
            -webkit-transform: scale(-1, 1);
            transform: scale(-1, 1);
        }

        .fa-flip-vertical {
            -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1)";
            -webkit-transform: scale(1, -1);
            transform: scale(1, -1);
        }

        .fa-flip-horizontal.fa-flip-vertical {
            -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1)";
            -webkit-transform: scale(-1, -1);
            transform: scale(-1, -1);
        }

        :root .fa-rotate-90,
        :root .fa-rotate-180,
        :root .fa-rotate-270,
        :root .fa-flip-horizontal,
        :root .fa-flip-vertical {
            -webkit-filter: none;
            filter: none;
        }

        .fa-stack {
            display: inline-block;
            height: 2em;
            line-height: 2em;
            position: relative;
            vertical-align: middle;
            width: 2em;
        }

        .fa-stack-1x,
        .fa-stack-2x {
            left: 0;
            position: absolute;
            text-align: center;
            width: 100%;
        }

        .fa-stack-1x {
            line-height: inherit;
        }

        .fa-stack-2x {
            font-size: 2em;
        }

        .fa-inverse {
            color: #fff;
        }


        .sr-only {
            border: 0;
            clip: rect(0, 0, 0, 0);
            height: 1px;
            margin: -1px;
            overflow: hidden;
            padding: 0;
            position: absolute;
            width: 1px;
        }

        .sr-only-focusable:active,
        .sr-only-focusable:focus {
            clip: auto;
            height: auto;
            margin: 0;
            overflow: visible;
            position: static;
            width: auto;
        }



        .far {
            font-family: "Font Awesome 5 Free";
            font-weight: 400;
        }



        .fa,
        .fas {
            font-family: "Font Awesome 5 Free";
            font-weight: 900;
        }

        .select2-container {
            margin: 0;
            position: relative;
            display: inline-block;
            zoom: 1;
            *display: inline;
            vertical-align: middle;
        }

        .select2-container,
        .select2-drop,
        .select2-search,
        .select2-search input {
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            box-sizing: border-box;
        }

        .select2-container .select2-choice {
            display: block;
            height: 26px;
            padding: 0 0 0 8px;
            overflow: hidden;
            position: relative;
            border: 1px solid #aaa;
            white-space: nowrap;
            line-height: 26px;
            color: #444;
            text-decoration: none;
            border-radius: 4px;
            background-clip: padding-box;
            -webkit-touch-callout: none;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            background-color: #fff;
            background-image: -webkit-gradient(
                linear,
                left bottom,
                left top,
                color-stop(0, #eee),
                color-stop(0.5, #fff)
            );
            background-image: -webkit-linear-gradient(center bottom, #eee 0%, #fff 50%);
            background-image: -moz-linear-gradient(center bottom, #eee 0%, #fff 50%);
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff',endColorstr='#eeeeee',GradientType=0);
            background-image: linear-gradient(to top, #eee 0%, #fff 50%);
        }

        html[dir="rtl"] .select2-container .select2-choice {
            padding: 0 8px 0 0;
        }

        .select2-container.select2-drop-above .select2-choice {
            border-bottom-color: #aaa;
            border-radius: 0 0 4px 4px;
            background-image: -webkit-gradient(
                linear,
                left bottom,
                left top,
                color-stop(0, #eee),
                color-stop(0.9, #fff)
            );
            background-image: -webkit-linear-gradient(center bottom, #eee 0%, #fff 90%);
            background-image: -moz-linear-gradient(center bottom, #eee 0%, #fff 90%);
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff',endColorstr='#eeeeee',GradientType=0);
            background-image: linear-gradient(to bottom, #eee 0%, #fff 90%);
        }

        .select2-container.select2-allowclear .select2-choice .select2-chosen {
            margin-right: 42px;
        }

        .select2-container .select2-choice > .select2-chosen {
            margin-right: 26px;
            display: block;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            float: none;
            width: auto;
        }

        html[dir="rtl"] .select2-container .select2-choice > .select2-chosen {
            margin-left: 26px;
            margin-right: 0;
        }

        .select2-container .select2-choice abbr {
            display: none;
            width: 12px;
            height: 12px;
            position: absolute;
            right: 24px;
            top: 8px;
            font-size: 1px;
            text-decoration: none;
            border: 0;
            cursor: pointer;
            outline: 0;
        }

        .select2-container.select2-allowclear .select2-choice abbr {
            display: inline-block;
        }

        .select2-container .select2-choice abbr:hover {
            background-position: right -11px;
            cursor: pointer;
        }

        .select2-drop-mask {
            border: 0;
            margin: 0;
            padding: 0;
            position: fixed;
            left: 0;
            top: 0;
            min-height: 100%;
            min-width: 100%;
            height: auto;
            width: auto;
            opacity: 0;
            z-index: 9998;
            background-color: #fff;
            filter: alpha(opacity=0);
        }

        .select2-drop {
            width: 100%;
            margin-top: -1px;
            position: absolute;
            z-index: 9999;
            top: 100%;
            background: #fff;
            color: #000;
            border: 1px solid #aaa;
            border-top: 0;
            border-radius: 0 0 4px 4px;
            -webkit-box-shadow: 0 4px 5px rgba(0, 0, 0, 0.15);
            box-shadow: 0 4px 5px rgba(0, 0, 0, 0.15);
        }

        .select2-drop.select2-drop-above {
            margin-top: 1px;
            border-top: 1px solid #aaa;
            border-bottom: 0;
            border-radius: 4px 4px 0 0;
            -webkit-box-shadow: 0 -4px 5px rgba(0, 0, 0, 0.15);
            box-shadow: 0 -4px 5px rgba(0, 0, 0, 0.15);
        }

        .select2-drop-active {
            border: 1px solid #5897fb;
            border-top: none;
        }

        .select2-drop.select2-drop-above.select2-drop-active {
            border-top: 1px solid #5897fb;
        }

        .select2-drop-auto-width {
            border-top: 1px solid #aaa;
            width: auto;
        }

        .select2-drop-auto-width .select2-search {
            padding-top: 4px;
        }

        .select2-container .select2-choice .select2-arrow {
            display: inline-block;
            width: 18px;
            height: 100%;
            position: absolute;
            right: 0;
            top: 0;
            border-left: 1px solid #aaa;
            border-radius: 0 4px 4px 0;
            background-clip: padding-box;
            background: #ccc;
            background-image: -webkit-gradient(
                linear,
                left bottom,
                left top,
                color-stop(0, #ccc),
                color-stop(0.6, #eee)
            );
            background-image: -webkit-linear-gradient(center bottom, #ccc 0%, #eee 60%);
            background-image: -moz-linear-gradient(center bottom, #ccc 0%, #eee 60%);
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#eeeeee',endColorstr='#cccccc',GradientType=0);
            background-image: linear-gradient(to top, #ccc 0%, #eee 60%);
        }

        html[dir="rtl"] .select2-container .select2-choice .select2-arrow {
            left: 0;
            right: auto;
            border-left: none;
            border-right: 1px solid #aaa;
            border-radius: 4px 0 0 4px;
        }

        .select2-container .select2-choice .select2-arrow b {
            display: block;
            width: 100%;
            height: 100%;
        }

        html[dir="rtl"] .select2-container .select2-choice .select2-arrow b {
            background-position: 2px 1px;
        }

        .select2-search {
            display: inline-block;
            width: 100%;
            min-height: 26px;
            margin: 0;
            padding-left: 4px;
            padding-right: 4px;
            position: relative;
            z-index: 10000;
            white-space: nowrap;
        }

        .select2-search input {
            width: 100%;
            height: auto !important;
            min-height: 26px;
            padding: 4px 20px 4px 5px;
            margin: 0;
            outline: 0;
            font-family: sans-serif;
            font-size: 1em;
            border: 1px solid #aaa;
            border-radius: 0;
            -webkit-box-shadow: none;
            box-shadow: none;

        }

        html[dir="rtl"] .select2-search input {
            padding: 4px 5px 4px 20px;
        }

        .select2-drop.select2-drop-above .select2-search input {
            margin-top: 4px;
        }

        .select2-search input.select2-active {

        }

        .select2-container-active .select2-choice,
        .select2-container-active .select2-choices {
            border: 1px solid #5897fb;
            outline: none;
            -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
            box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
        }

        .select2-dropdown-open .select2-choice {
            border-bottom-color: transparent;
            -webkit-box-shadow: 0 1px 0 #fff inset;
            box-shadow: 0 1px 0 #fff inset;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
            background-color: #eee;
            background-image: -webkit-gradient(
                linear,
                left bottom,
                left top,
                color-stop(0, #fff),
                color-stop(0.5, #eee)
            );
            background-image: -webkit-linear-gradient(center bottom, #fff 0%, #eee 50%);
            background-image: -moz-linear-gradient(center bottom, #fff 0%, #eee 50%);
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#eeeeee',endColorstr='#ffffff',GradientType=0);
            background-image: linear-gradient(to top, #fff 0%, #eee 50%);
        }

        .select2-dropdown-open.select2-drop-above .select2-choice,
        .select2-dropdown-open.select2-drop-above .select2-choices {
            border: 1px solid #5897fb;
            border-top-color: transparent;
            background-image: -webkit-gradient(
                linear,
                left top,
                left bottom,
                color-stop(0, #fff),
                color-stop(0.5, #eee)
            );
            background-image: -webkit-linear-gradient(center top, #fff 0%, #eee 50%);
            background-image: -moz-linear-gradient(center top, #fff 0%, #eee 50%);
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#eeeeee',endColorstr='#ffffff',GradientType=0);
            background-image: linear-gradient(to bottom, #fff 0%, #eee 50%);
        }

        .select2-dropdown-open .select2-choice .select2-arrow {
            background: transparent;
            border-left: none;
            filter: none;
        }

        html[dir="rtl"] .select2-dropdown-open .select2-choice .select2-arrow {
            border-right: none;
        }

        .select2-dropdown-open .select2-choice .select2-arrow b {
            background-position: -18px 1px;
        }

        html[dir="rtl"] .select2-dropdown-open .select2-choice .select2-arrow b {
            background-position: -16px 1px;
        }

        .select2-hidden-accessible {
            border: 0;
            clip: rect(0 0 0 0);
            height: 1px;
            margin: -1px;
            overflow: hidden;
            padding: 0;
            position: absolute;
            width: 1px;
        }

        .select2-results {
            max-height: 200px;
            padding: 0 0 0 4px;
            margin: 4px 4px 4px 0;
            position: relative;
            overflow-x: hidden;
            overflow-y: auto;
            -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        }

        html[dir="rtl"] .select2-results {
            padding: 0 4px 0 0;
            margin: 4px 0 4px 4px;
        }

        .select2-results ul.select2-result-sub {
            margin: 0;
            padding-left: 0;
        }

        .select2-results li {
            list-style: none;
            display: list-item;
            background-image: none;
        }

        .select2-results li.select2-result-with-children > .select2-result-label {
            font-weight: bold;
        }

        .select2-results .select2-result-label {
            padding: 3px 7px 4px;
            margin: 0;
            cursor: pointer;
            min-height: 1em;
            -webkit-touch-callout: none;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
        }

        .select2-results-dept-1 .select2-result-label {
            padding-left: 20px;
        }

        .select2-results-dept-2 .select2-result-label {
            padding-left: 40px;
        }

        .select2-results-dept-3 .select2-result-label {
            padding-left: 60px;
        }

        .select2-results-dept-4 .select2-result-label {
            padding-left: 80px;
        }

        .select2-results-dept-5 .select2-result-label {
            padding-left: 100px;
        }

        .select2-results-dept-6 .select2-result-label {
            padding-left: 110px;
        }

        .select2-results-dept-7 .select2-result-label {
            padding-left: 120px;
        }

        .select2-results .select2-highlighted {
            background: #3875d7;
            color: #fff;
        }

        .select2-results li em {
            background: #feffde;
            font-style: normal;
        }

        .select2-results .select2-highlighted em {
            background: transparent;
        }

        .select2-results .select2-highlighted ul {
            background: #fff;
            color: #000;
        }

        .select2-results .select2-no-results,
        .select2-results .select2-searching,
        .select2-results .select2-selection-limit {
            background: #f4f4f4;
            display: list-item;
            padding-left: 5px;
        }

        .select2-results .select2-disabled.select2-highlighted {
            color: #666;
            background: #f4f4f4;
            display: list-item;
            cursor: default;
        }

        .select2-results .select2-disabled {
            background: #f4f4f4;
            display: list-item;
            cursor: default;
        }

        .select2-results .select2-selected {
            display: none;
        }

        .select2-more-results {
            background: #f4f4f4;
            display: list-item;
        }

        .select2-container.select2-container-disabled .select2-choice {
            background-color: #f4f4f4;
            background-image: none;
            border: 1px solid #ddd;
            cursor: default;
        }

        .select2-container.select2-container-disabled .select2-choice .select2-arrow {
            background-color: #f4f4f4;
            background-image: none;
            border-left: 0;
        }

        .select2-container.select2-container-disabled .select2-choice abbr {
            display: none;
        }

        .select2-container-multi .select2-choices {
            height: auto !important;
            height: 1%;
            margin: 0;
            padding: 0 5px 0 0;
            position: relative;
            border: 1px solid #aaa;
            cursor: text;
            overflow: hidden;
            background-color: #fff;
            background-image: -webkit-gradient(linear, 0% 0%, 0% 100%, color-stop(1%, #eee), color-stop(15%, #fff));
            background-image: -webkit-linear-gradient(top, #eee 1%, #fff 15%);
            background-image: -moz-linear-gradient(top, #eee 1%, #fff 15%);
            background-image: linear-gradient(to bottom, #eee 1%, #fff 15%);
        }

        html[dir="rtl"] .select2-container-multi .select2-choices {
            padding: 0 0 0 5px;
        }

        .select2-locked {
            padding: 3px 5px 3px 5px !important;
        }

        .select2-container-multi .select2-choices {
            min-height: 26px;
        }

        .select2-container-multi.select2-container-active .select2-choices {
            border: 1px solid #5897fb;
            outline: none;
            -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
            box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
        }

        .select2-container-multi .select2-choices li {
            float: left;
            list-style: none;
        }

        html[dir="rtl"] .select2-container-multi .select2-choices li {
            float: right;
        }

        .select2-container-multi .select2-choices .select2-search-field {
            margin: 0;
            padding: 0;
            white-space: nowrap;
        }

        .select2-container-multi .select2-choices .select2-search-field input {
            padding: 5px;
            margin: 1px 0;
            font-family: sans-serif;
            color: #666;
            outline: 0;
            border: 0;
            -webkit-box-shadow: none;
            box-shadow: none;
            background: transparent !important;
        }


        .select2-default {
            color: #999 !important;
        }

        .select2-container-multi .select2-choices .select2-search-choice {
            padding: 3px 5px 3px 18px;
            margin: 3px 0 3px 5px;
            position: relative;
            line-height: 13px;
            color: #333;
            cursor: default;
            border: 1px solid #aaa;
            border-radius: 3px;
            -webkit-box-shadow: 0 0 2px #fff inset, 0 1px 0 rgba(0, 0, 0, 0.05);
            box-shadow: 0 0 2px #fff inset, 0 1px 0 rgba(0, 0, 0, 0.05);
            background-clip: padding-box;
            -webkit-touch-callout: none;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            background-color: #e4e4e4;
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#eeeeee',endColorstr='#f4f4f4',GradientType=0);
            background-image: -webkit-gradient(
                linear,
                0% 0%,
                0% 100%,
                color-stop(20%, #f4f4f4),
                color-stop(50%, #f0f0f0),
                color-stop(52%, #e8e8e8),
                color-stop(100%, #eee)
            );
            background-image: -webkit-linear-gradient(top, #f4f4f4 20%, #f0f0f0 50%, #e8e8e8 52%, #eee 100%);
            background-image: -moz-linear-gradient(top, #f4f4f4 20%, #f0f0f0 50%, #e8e8e8 52%, #eee 100%);
            background-image: linear-gradient(to top, #f4f4f4 20%, #f0f0f0 50%, #e8e8e8 52%, #eee 100%);
        }

        html[dir="rtl"] .select2-container-multi .select2-choices .select2-search-choice {
            margin: 3px 5px 3px 0;
            padding: 3px 18px 3px 5px;
        }

        .select2-container-multi .select2-choices .select2-search-choice .select2-chosen {
            cursor: default;
        }

        .select2-container-multi .select2-choices .select2-search-choice-focus {
            background: #d4d4d4;
        }

        .select2-search-choice-close {
            display: block;
            width: 12px;
            height: 13px;
            position: absolute;
            right: 3px;
            top: 4px;
            font-size: 1px;
            outline: none;
        }

        html[dir="rtl"] .select2-search-choice-close {
            right: auto;
            left: 3px;
        }

        .select2-container-multi .select2-search-choice-close {
            left: 3px;
        }

        html[dir="rtl"] .select2-container-multi .select2-search-choice-close {
            left: auto;
            right: 2px;
        }

        .select2-container-multi .select2-choices .select2-search-choice .select2-search-choice-close:hover {
            background-position: right -11px;
        }

        .select2-container-multi .select2-choices .select2-search-choice-focus .select2-search-choice-close {
            background-position: right -11px;
        }

        .select2-container-multi.select2-container-disabled .select2-choices {
            background-color: #f4f4f4;
            background-image: none;
            border: 1px solid #ddd;
            cursor: default;
        }

        .select2-container-multi.select2-container-disabled .select2-choices .select2-search-choice {
            padding: 3px 5px 3px 5px;
            border: 1px solid #ddd;
            background-image: none;
            background-color: #f4f4f4;
        }

        .select2-container-multi.select2-container-disabled
            .select2-choices
            .select2-search-choice
            .select2-search-choice-close {
            display: none;
            background: none;
        }

        .select2-result-selectable .select2-match,
        .select2-result-unselectable .select2-match {
            text-decoration: underline;
        }

        .select2-offscreen,
        .select2-offscreen:focus {
            clip: rect(0 0 0 0) !important;
            width: 1px !important;
            height: 1px !important;
            border: 0 !important;
            margin: 0 !important;
            padding: 0 !important;
            overflow: hidden !important;
            position: absolute !important;
            outline: 0 !important;
            left: 0 !important;
            top: 0 !important;
        }

        .select2-display-none {
            display: none;
        }

        .select2-measure-scrollbar {
            position: absolute;
            top: -10000px;
            left: -10000px;
            width: 100px;
            height: 100px;
            overflow: scroll;
        }

        @media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-resolution: 2dppx) {
            .select2-search input,
            .select2-search-choice-close,
            .select2-container .select2-choice abbr,
            .select2-container .select2-choice .select2-arrow b {
                background-repeat: no-repeat !important;
                background-size: 60px 40px !important;
            }

            .select2-search input {
                background-position: 100% -21px !important;
            }
        }

        .uploadify {
            position: relative;
            margin-bottom: 1em;
            width: 16px;
            clear: none;
        }

        .uploadify-button {
            clear: none;
            float: none;
            width: 16px;
            cursor: hand;
            padding: 0;
        }

        .uploadify-button.disabled {
            background-color: #d0d0d0;
            color: gray;
        }

        .uploadify-queue {
            margin-bottom: 1em;
            margin-top: 20px;
            clear: both;
        }

        .uploadify-queue-item {
            outline: 0 !important;
            -webkit-appearance: none;
            box-shadow: none;
            -moz-box-shadow: none;
            -webkit-box-shadow: none;
            outline: none;
            background-color: #f5f5f5;
            -webkit-border-radius: 3px;
            -moz-border-radius: 3px;
            border-radius: 3px;
            font: 11px Verdana, Geneva, sans-serif;
            margin-top: 5px;
            width: 480px;
            padding: 10px;
            clear: both;
        }

        .uploadify-error {
            background-color: #fde5dd !important;
        }

        .uploadify-queue-item .cancel a {
            float: right;
            height: 16px;
            text-indent: -9999px;
            width: 16px;
        }

        .uploadify-queue-item.completed {
            background-color: #e5e5e5;
        }

        .uploadify-progress {
            background-color: #e5e5e5;
            margin-top: 10px;
            width: 100%;
        }

        .uploadify-progress-bar {
            background-color: #09f;
            height: 3px;
            width: 1px;
        }

        .uploadifive-button {
            clear: none;
            cursor: pointer;
            float: left;
            background-position: -337px -5px;
            overflow: hidden;
            width: 16px;
            height: 14px;
        }

        .uploadifive-queue-item {
            outline: 0 !important;
            -webkit-appearance: none;
            box-shadow: none;
            -moz-box-shadow: none;
            -webkit-box-shadow: none;
            outline: none;
            background-color: #f5f5f5;
            border-bottom: 1px dotted #d5d5d5;
            -webkit-border-radius: 5px;
            -moz-border-radius: 5px;
            border-radius: 5px;
            font: 12px Arial, Helvetica, Sans-serif;
            margin-top: 3px;
            padding: 15px 0 15px 0;
            margin-bottom: 1em;
            width: 100%;
            clear: both;
        }

        .uploadifive-queue-item > div {
            margin: 0 15px 0 15px;
        }

        .uploadifive-queue-item .close {
            display: block;
            float: right;
            height: 16px;
            text-indent: -9999px;
            width: 16px;
        }

        .uploadifive-queue-item .progress {
            border: 1px solid #d0d0d0;
            height: 3px;
            margin-top: 5px;
            width: 100%;
            outline: 0 !important;
            -webkit-appearance: none;
            box-shadow: none;
            -moz-box-shadow: none;
            -webkit-box-shadow: none;
            outline: none;
        }

        .uploadifive-queue-item .progress-bar {
            background-color: #0072bc;
            height: 3px;
            width: 0;
        }

        .datepicker {
            top: 0;
            left: 0;
            padding: 4px;
            margin-top: 1px;
            -webkit-border-radius: 4px;
            -moz-border-radius: 4px;
            border-radius: 4px;
        }

        .datepicker:before {
            content: "";
            display: inline-block;
            border-left: 7px solid transparent;
            border-right: 7px solid transparent;
            border-bottom: 7px solid #ccc;
            border-bottom-color: rgba(0, 0, 0, 0.2);
            position: absolute;
            top: -7px;
            left: 6px;
        }

        .datepicker:after {
            content: "";
            display: inline-block;
            border-left: 6px solid transparent;
            border-right: 6px solid transparent;
            border-bottom: 6px solid #fff;
            position: absolute;
            top: -6px;
            left: 7px;
        }

        .datepicker > div {
            display: none;
        }

        .datepicker table {
            width: 100%;
            margin: 0;
        }

        .datepicker td,
        .datepicker th {
            text-align: center;
            width: 20px;
            height: 20px;
            -webkit-border-radius: 4px;
            -moz-border-radius: 4px;
            border-radius: 4px;
        }

        .datepicker td.day:hover {
            background: #eee;
            cursor: pointer;
        }

        .datepicker td.day.disabled {
            color: #eee;
        }

        .datepicker td.old,
        .datepicker td.new {
            color: #999;
        }

        .datepicker td.active,
        .datepicker td.active:hover {
            color: #fff;
            background-color: #006dcc;
            background-image: -moz-linear-gradient(top, #08c, #04c);
            background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#08c), to(#04c));
            background-image: -webkit-linear-gradient(top, #08c, #04c);
            background-image: -o-linear-gradient(top, #08c, #04c);
            background-image: linear-gradient(to bottom, #08c, #04c);
            background-repeat: repeat-x;
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff0088cc',endColorstr='#ff0044cc',GradientType=0);
            border-color: #04c #04c #002a80;
            border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
            *background-color: #04c;
            filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
            color: #fff;
            text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
        }

        .datepicker td.active:hover,
        .datepicker td.active:hover:hover,
        .datepicker td.active:focus,
        .datepicker td.active:hover:focus,
        .datepicker td.active:active,
        .datepicker td.active:hover:active,
        .datepicker td.active.active,
        .datepicker td.active:hover.active,
        .datepicker td.active.disabled,
        .datepicker td.active:hover.disabled,
        .datepicker td.active[disabled],
        .datepicker td.active:hover[disabled] {
            color: #fff;
            background-color: #04c;
            *background-color: #003bb3;
        }

        .datepicker td.active:active,
        .datepicker td.active:hover:active,
        .datepicker td.active.active,
        .datepicker td.active:hover.active {
            background-color: #039 \9;
        }

        .datepicker td span {
            display: block;
            width: 47px;
            height: 54px;
            line-height: 54px;
            float: left;
            margin: 2px;
            cursor: pointer;
            -webkit-border-radius: 4px;
            -moz-border-radius: 4px;
            border-radius: 4px;
        }

        .datepicker td span:hover {
            background: #eee;
        }

        .datepicker td span.active {
            color: #fff;
            background-color: #006dcc;
            background-image: -moz-linear-gradient(top, #08c, #04c);
            background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#08c), to(#04c));
            background-image: -webkit-linear-gradient(top, #08c, #04c);
            background-image: -o-linear-gradient(top, #08c, #04c);
            background-image: linear-gradient(to bottom, #08c, #04c);
            background-repeat: repeat-x;
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff0088cc',endColorstr='#ff0044cc',GradientType=0);
            border-color: #04c #04c #002a80;
            border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
            *background-color: #04c;
            filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
            color: #fff;
            text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
        }

        .datepicker td span.active:hover,
        .datepicker td span.active:focus,
        .datepicker td span.active:active,
        .datepicker td span.active.active,
        .datepicker td span.active.disabled,
        .datepicker td span.active[disabled] {
            color: #fff;
            background-color: #04c;
            *background-color: #003bb3;
        }

        .datepicker td span.active:active,
        .datepicker td span.active.active {
            background-color: #039 \9;
        }

        .datepicker td span.old {
            color: #999;
        }

        .datepicker th.switch {
            width: 145px;
        }

        .datepicker th.next,
        .datepicker th.prev {
            font-size: 21px;
        }

        .datepicker thead tr:first-child th {
            cursor: pointer;
        }

        .datepicker thead tr:first-child th:hover {
            background: #eee;
        }

        .input-append.date .add-on i,
        .input-prepend.date .add-on i {
            display: block;
            cursor: pointer;
            width: 16px;
            height: 16px;
        }

        .popover.topLeft {
            margin-top: -10px;
        }

        .popover.topLeft .arrow {
            bottom: -10px;
            left: 25%;
            margin-left: -10px;
            border-width: 10px 10px 0;
            border-top-color: #fff;
        }

        .popover.topLeft .arrow:after {
            border-width: 11px 11px 0;
            border-top-color: rgba(0, 0, 0, 0.25);
            bottom: -1px;
            left: -11px;
        }

        .popover.topRight {
            margin-top: -10px;
        }

        .popover.topRight .arrow {
            bottom: -10px;
            left: 75%;
            margin-left: -10px;
            border-width: 10px 10px 0;
            border-top-color: #fff;
        }

        .popover.topRight .arrow:after {
            border-width: 11px 11px 0;
            border-top-color: rgba(0, 0, 0, 0.25);
            bottom: -1px;
            left: -11px;
        }

        .popover.topRightFull {
            margin-top: -88px;
        }

        .popover.topRightFull .arrow {
            bottom: -10px;
            left: 90%;
            margin-left: -10px;
            border-width: 10px 10px 0;
            border-top-color: #fff;
        }

        .popover.topRightFull .arrow:after {
            border-width: 11px 11px 0;
            border-top-color: rgba(0, 0, 0, 0.25);
            bottom: -1px;
            left: -11px;
        }

        .popover.rightTop {
            margin-left: 10px;
        }

        .popover.rightTop .arrow {
            top: 25%;
            left: -10px;
            margin-top: -10px;
            border-width: 10px 10px 10px 0;
            border-right-color: #fff;
        }

        .popover.rightTop .arrow:after {
            border-width: 11px 11px 11px 0;
            border-right-color: rgba(0, 0, 0, 0.25);
            bottom: -11px;
            left: -1px;
        }

        .popover.rightBottom {
            margin-left: 10px;
        }

        .popover.rightBottom .arrow {
            top: 75%;
            left: -10px;
            margin-top: -10px;
            border-width: 10px 10px 10px 0;
            border-right-color: #fff;
        }

        .popover.rightBottom .arrow:after {
            border-width: 11px 11px 11px 0;
            border-right-color: rgba(0, 0, 0, 0.25);
            bottom: -11px;
            left: -1px;
        }

        .popover.bottomLeft {
            margin-top: 10px;
        }

        .popover.bottomLeft .arrow {
            top: -10px;
            left: 25%;
            margin-left: -10px;
            border-width: 0 10px 10px;
            border-bottom-color: #fff;
        }

        .popover.bottomLeft .arrow:after {
            border-width: 0 11px 11px;
            border-bottom-color: rgba(0, 0, 0, 0.25);
            top: -1px;
            left: -11px;
        }

        .popover.bottomRight {
            margin-top: 10px;
        }

        .popover.bottomRight .arrow {
            top: -10px;
            left: 75%;
            margin-left: -10px;
            border-width: 0 10px 10px;
            border-bottom-color: #fff;
        }

        .popover.bottomRight .arrow:after {
            border-width: 0 11px 11px;
            border-bottom-color: rgba(0, 0, 0, 0.25);
            top: -1px;
            left: -11px;
        }

        .popover.leftTop {
            margin-left: -10px;
        }

        .popover.leftTop .arrow {
            top: 25%;
            right: -10px;
            margin-top: -10px;
            border-width: 10px 0 10px 10px;
            border-left-color: #fff;
        }

        .popover.leftTop .arrow:after {
            border-width: 11px 0 11px 11px;
            border-left-color: rgba(0, 0, 0, 0.25);
            bottom: -11px;
            right: -1px;
        }

        .popover.leftBottom {
            margin-left: -10px;
        }

        .popover.leftBottom .arrow {
            top: 75%;
            right: -10px;
            margin-top: -10px;
            border-width: 10px 0 10px 10px;
            border-left-color: #fff;
        }

        .popover.leftBottom .arrow:after {
            border-width: 11px 0 11px 11px;
            border-left-color: rgba(0, 0, 0, 0.25);
            bottom: -11px;
            right: -1px;
        }

        .popover {
            z-index: 1060;
        }

        .animated {
            -webkit-animation-duration: 1s;
            animation-duration: 1s;
            -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
        }

        .animated.infinite {
            -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
        }

        .animated.hinge {
            -webkit-animation-duration: 2s;
            animation-duration: 2s;
        }

        @-webkit-keyframes bounce {
            0%,
            20%,
            53%,
            80%,
            100% {
                -webkit-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
                transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
                -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0);
            }

            40%,
            43% {
                -webkit-transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
                transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
                -webkit-transform: translate3d(0, -30px, 0);
                transform: translate3d(0, -30px, 0);
            }

            70% {
                -webkit-transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
                transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
                -webkit-transform: translate3d(0, -15px, 0);
                transform: translate3d(0, -15px, 0);
            }

            90% {
                -webkit-transform: translate3d(0, -4px, 0);
                transform: translate3d(0, -4px, 0);
            }
        }

        @keyframes bounce {
            0%,
            20%,
            53%,
            80%,
            100% {
                -webkit-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
                transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
                -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0);
            }

            40%,
            43% {
                -webkit-transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
                transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
                -webkit-transform: translate3d(0, -30px, 0);
                transform: translate3d(0, -30px, 0);
            }

            70% {
                -webkit-transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
                transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
                -webkit-transform: translate3d(0, -15px, 0);
                transform: translate3d(0, -15px, 0);
            }

            90% {
                -webkit-transform: translate3d(0, -4px, 0);
                transform: translate3d(0, -4px, 0);
            }
        }

        .bounce {
            -webkit-animation-name: bounce;
            animation-name: bounce;
            -webkit-transform-origin: center bottom;
            -ms-transform-origin: center bottom;
            transform-origin: center bottom;
        }

        @-webkit-keyframes flash {
            0%,
            50%,
            100% {
                opacity: 1;
            }

            25%,
            75% {
                opacity: 0;
            }
        }

        @keyframes flash {
            0%,
            50%,
            100% {
                opacity: 1;
            }

            25%,
            75% {
                opacity: 0;
            }
        }

        .flash {
            -webkit-animation-name: flash;
            animation-name: flash;
        }

        @-webkit-keyframes pulse {
            0% {
                -webkit-transform: scale3d(1, 1, 1);
                transform: scale3d(1, 1, 1);
            }

            50% {
                -webkit-transform: scale3d(1.05, 1.05, 1.05);
                transform: scale3d(1.05, 1.05, 1.05);
            }

            100% {
                -webkit-transform: scale3d(1, 1, 1);
                transform: scale3d(1, 1, 1);
            }
        }

        @keyframes pulse {
            0% {
                -webkit-transform: scale3d(1, 1, 1);
                transform: scale3d(1, 1, 1);
            }

            50% {
                -webkit-transform: scale3d(1.05, 1.05, 1.05);
                transform: scale3d(1.05, 1.05, 1.05);
            }

            100% {
                -webkit-transform: scale3d(1, 1, 1);
                transform: scale3d(1, 1, 1);
            }
        }

        .pulse {
            -webkit-animation-name: pulse;
            animation-name: pulse;
        }

        @-webkit-keyframes rubberBand {
            0% {
                -webkit-transform: scale3d(1, 1, 1);
                transform: scale3d(1, 1, 1);
            }

            30% {
                -webkit-transform: scale3d(1.25, 0.75, 1);
                transform: scale3d(1.25, 0.75, 1);
            }

            40% {
                -webkit-transform: scale3d(0.75, 1.25, 1);
                transform: scale3d(0.75, 1.25, 1);
            }

            50% {
                -webkit-transform: scale3d(1.15, 0.85, 1);
                transform: scale3d(1.15, 0.85, 1);
            }

            65% {
                -webkit-transform: scale3d(0.95, 1.05, 1);
                transform: scale3d(0.95, 1.05, 1);
            }

            75% {
                -webkit-transform: scale3d(1.05, 0.95, 1);
                transform: scale3d(1.05, 0.95, 1);
            }

            100% {
                -webkit-transform: scale3d(1, 1, 1);
                transform: scale3d(1, 1, 1);
            }
        }

        @keyframes rubberBand {
            0% {
                -webkit-transform: scale3d(1, 1, 1);
                transform: scale3d(1, 1, 1);
            }

            30% {
                -webkit-transform: scale3d(1.25, 0.75, 1);
                transform: scale3d(1.25, 0.75, 1);
            }

            40% {
                -webkit-transform: scale3d(0.75, 1.25, 1);
                transform: scale3d(0.75, 1.25, 1);
            }

            50% {
                -webkit-transform: scale3d(1.15, 0.85, 1);
                transform: scale3d(1.15, 0.85, 1);
            }

            65% {
                -webkit-transform: scale3d(0.95, 1.05, 1);
                transform: scale3d(0.95, 1.05, 1);
            }

            75% {
                -webkit-transform: scale3d(1.05, 0.95, 1);
                transform: scale3d(1.05, 0.95, 1);
            }

            100% {
                -webkit-transform: scale3d(1, 1, 1);
                transform: scale3d(1, 1, 1);
            }
        }

        .rubberBand {
            -webkit-animation-name: rubberBand;
            animation-name: rubberBand;
        }

        @-webkit-keyframes shake {
            0%,
            100% {
                -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0);
            }

            10%,
            30%,
            50%,
            70%,
            90% {
                -webkit-transform: translate3d(-10px, 0, 0);
                transform: translate3d(-10px, 0, 0);
            }

            20%,
            40%,
            60%,
            80% {
                -webkit-transform: translate3d(10px, 0, 0);
                transform: translate3d(10px, 0, 0);
            }
        }

        @keyframes shake {
            0%,
            100% {
                -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0);
            }

            10%,
            30%,
            50%,
            70%,
            90% {
                -webkit-transform: translate3d(-10px, 0, 0);
                transform: translate3d(-10px, 0, 0);
            }

            20%,
            40%,
            60%,
            80% {
                -webkit-transform: translate3d(10px, 0, 0);
                transform: translate3d(10px, 0, 0);
            }
        }

        .shake {
            -webkit-animation-name: shake;
            animation-name: shake;
        }

        @-webkit-keyframes swing {
            20% {
                -webkit-transform: rotate3d(0, 0, 1, 15deg);
                transform: rotate3d(0, 0, 1, 15deg);
            }

            40% {
                -webkit-transform: rotate3d(0, 0, 1, -10deg);
                transform: rotate3d(0, 0, 1, -10deg);
            }

            60% {
                -webkit-transform: rotate3d(0, 0, 1, 5deg);
                transform: rotate3d(0, 0, 1, 5deg);
            }

            80% {
                -webkit-transform: rotate3d(0, 0, 1, -5deg);
                transform: rotate3d(0, 0, 1, -5deg);
            }

            100% {
                -webkit-transform: rotate3d(0, 0, 1, 0deg);
                transform: rotate3d(0, 0, 1, 0deg);
            }
        }

        @keyframes swing {
            20% {
                -webkit-transform: rotate3d(0, 0, 1, 15deg);
                transform: rotate3d(0, 0, 1, 15deg);
            }

            40% {
                -webkit-transform: rotate3d(0, 0, 1, -10deg);
                transform: rotate3d(0, 0, 1, -10deg);
            }

            60% {
                -webkit-transform: rotate3d(0, 0, 1, 5deg);
                transform: rotate3d(0, 0, 1, 5deg);
            }

            80% {
                -webkit-transform: rotate3d(0, 0, 1, -5deg);
                transform: rotate3d(0, 0, 1, -5deg);
            }

            100% {
                -webkit-transform: rotate3d(0, 0, 1, 0deg);
                transform: rotate3d(0, 0, 1, 0deg);
            }
        }

        .swing {
            -webkit-transform-origin: top center;
            -ms-transform-origin: top center;
            transform-origin: top center;
            -webkit-animation-name: swing;
            animation-name: swing;
        }

        @-webkit-keyframes tada {
            0% {
                -webkit-transform: scale3d(1, 1, 1);
                transform: scale3d(1, 1, 1);
            }

            10%,
            20% {
                -webkit-transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
                transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
            }

            30%,
            50%,
            70%,
            90% {
                -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
                transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
            }

            40%,
            60%,
            80% {
                -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
                transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
            }

            100% {
                -webkit-transform: scale3d(1, 1, 1);
                transform: scale3d(1, 1, 1);
            }
        }

        @keyframes tada {
            0% {
                -webkit-transform: scale3d(1, 1, 1);
                transform: scale3d(1, 1, 1);
            }

            10%,
            20% {
                -webkit-transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
                transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
            }

            30%,
            50%,
            70%,
            90% {
                -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
                transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
            }

            40%,
            60%,
            80% {
                -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
                transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
            }

            100% {
                -webkit-transform: scale3d(1, 1, 1);
                transform: scale3d(1, 1, 1);
            }
        }

        .tada {
            -webkit-animation-name: tada;
            animation-name: tada;
        }

        @-webkit-keyframes wobble {
            0% {
                -webkit-transform: none;
                transform: none;
            }

            15% {
                -webkit-transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg);
                transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg);
            }

            30% {
                -webkit-transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg);
                transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg);
            }

            45% {
                -webkit-transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg);
                transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg);
            }

            60% {
                -webkit-transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg);
                transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg);
            }

            75% {
                -webkit-transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
                transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
            }

            100% {
                -webkit-transform: none;
                transform: none;
            }
        }

        @keyframes wobble {
            0% {
                -webkit-transform: none;
                transform: none;
            }

            15% {
                -webkit-transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg);
                transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg);
            }

            30% {
                -webkit-transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg);
                transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg);
            }

            45% {
                -webkit-transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg);
                transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg);
            }

            60% {
                -webkit-transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg);
                transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg);
            }

            75% {
                -webkit-transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
                transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
            }

            100% {
                -webkit-transform: none;
                transform: none;
            }
        }

        .wobble {
            -webkit-animation-name: wobble;
            animation-name: wobble;
        }

        @-webkit-keyframes bounceIn {
            0%,
            20%,
            40%,
            60%,
            80%,
            100% {
                -webkit-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
                transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            }

            0% {
                opacity: 0;
                -webkit-transform: scale3d(0.3, 0.3, 0.3);
                transform: scale3d(0.3, 0.3, 0.3);
            }

            20% {
                -webkit-transform: scale3d(1.1, 1.1, 1.1);
                transform: scale3d(1.1, 1.1, 1.1);
            }

            40% {
                -webkit-transform: scale3d(0.9, 0.9, 0.9);
                transform: scale3d(0.9, 0.9, 0.9);
            }

            60% {
                opacity: 1;
                -webkit-transform: scale3d(1.03, 1.03, 1.03);
                transform: scale3d(1.03, 1.03, 1.03);
            }

            80% {
                -webkit-transform: scale3d(0.97, 0.97, 0.97);
                transform: scale3d(0.97, 0.97, 0.97);
            }

            100% {
                opacity: 1;
                -webkit-transform: scale3d(1, 1, 1);
                transform: scale3d(1, 1, 1);
            }
        }

        @keyframes bounceIn {
            0%,
            20%,
            40%,
            60%,
            80%,
            100% {
                -webkit-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
                transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            }

            0% {
                opacity: 0;
                -webkit-transform: scale3d(0.3, 0.3, 0.3);
                transform: scale3d(0.3, 0.3, 0.3);
            }

            20% {
                -webkit-transform: scale3d(1.1, 1.1, 1.1);
                transform: scale3d(1.1, 1.1, 1.1);
            }

            40% {
                -webkit-transform: scale3d(0.9, 0.9, 0.9);
                transform: scale3d(0.9, 0.9, 0.9);
            }

            60% {
                opacity: 1;
                -webkit-transform: scale3d(1.03, 1.03, 1.03);
                transform: scale3d(1.03, 1.03, 1.03);
            }

            80% {
                -webkit-transform: scale3d(0.97, 0.97, 0.97);
                transform: scale3d(0.97, 0.97, 0.97);
            }

            100% {
                opacity: 1;
                -webkit-transform: scale3d(1, 1, 1);
                transform: scale3d(1, 1, 1);
            }
        }

        .bounceIn {
            -webkit-animation-name: bounceIn;
            animation-name: bounceIn;
            -webkit-animation-duration: 0.75s;
            animation-duration: 0.75s;
        }

        @-webkit-keyframes bounceInDown {
            0%,
            60%,
            75%,
            90%,
            100% {
                -webkit-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
                transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            }

            0% {
                opacity: 0;
                -webkit-transform: translate3d(0, -3000px, 0);
                transform: translate3d(0, -3000px, 0);
            }

            60% {
                opacity: 1;
                -webkit-transform: translate3d(0, 25px, 0);
                transform: translate3d(0, 25px, 0);
            }

            75% {
                -webkit-transform: translate3d(0, -10px, 0);
                transform: translate3d(0, -10px, 0);
            }

            90% {
                -webkit-transform: translate3d(0, 5px, 0);
                transform: translate3d(0, 5px, 0);
            }

            100% {
                -webkit-transform: none;
                transform: none;
            }
        }

        @keyframes bounceInDown {
            0%,
            60%,
            75%,
            90%,
            100% {
                -webkit-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
                transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            }

            0% {
                opacity: 0;
                -webkit-transform: translate3d(0, -3000px, 0);
                transform: translate3d(0, -3000px, 0);
            }

            60% {
                opacity: 1;
                -webkit-transform: translate3d(0, 25px, 0);
                transform: translate3d(0, 25px, 0);
            }

            75% {
                -webkit-transform: translate3d(0, -10px, 0);
                transform: translate3d(0, -10px, 0);
            }

            90% {
                -webkit-transform: translate3d(0, 5px, 0);
                transform: translate3d(0, 5px, 0);
            }

            100% {
                -webkit-transform: none;
                transform: none;
            }
        }

        .bounceInDown {
            -webkit-animation-name: bounceInDown;
            animation-name: bounceInDown;
        }

        @-webkit-keyframes bounceInLeft {
            0%,
            60%,
            75%,
            90%,
            100% {
                -webkit-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
                transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            }

            0% {
                opacity: 0;
                -webkit-transform: translate3d(-3000px, 0, 0);
                transform: translate3d(-3000px, 0, 0);
            }

            60% {
                opacity: 1;
                -webkit-transform: translate3d(25px, 0, 0);
                transform: translate3d(25px, 0, 0);
            }

            75% {
                -webkit-transform: translate3d(-10px, 0, 0);
                transform: translate3d(-10px, 0, 0);
            }

            90% {
                -webkit-transform: translate3d(5px, 0, 0);
                transform: translate3d(5px, 0, 0);
            }

            100% {
                -webkit-transform: none;
                transform: none;
            }
        }

        @keyframes bounceInLeft {
            0%,
            60%,
            75%,
            90%,
            100% {
                -webkit-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
                transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            }

            0% {
                opacity: 0;
                -webkit-transform: translate3d(-3000px, 0, 0);
                transform: translate3d(-3000px, 0, 0);
            }

            60% {
                opacity: 1;
                -webkit-transform: translate3d(25px, 0, 0);
                transform: translate3d(25px, 0, 0);
            }

            75% {
                -webkit-transform: translate3d(-10px, 0, 0);
                transform: translate3d(-10px, 0, 0);
            }

            90% {
                -webkit-transform: translate3d(5px, 0, 0);
                transform: translate3d(5px, 0, 0);
            }

            100% {
                -webkit-transform: none;
                transform: none;
            }
        }

        .bounceInLeft {
            -webkit-animation-name: bounceInLeft;
            animation-name: bounceInLeft;
        }

        @-webkit-keyframes bounceInRight {
            0%,
            60%,
            75%,
            90%,
            100% {
                -webkit-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
                transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            }

            0% {
                opacity: 0;
                -webkit-transform: translate3d(3000px, 0, 0);
                transform: translate3d(3000px, 0, 0);
            }

            60% {
                opacity: 1;
                -webkit-transform: translate3d(-25px, 0, 0);
                transform: translate3d(-25px, 0, 0);
            }

            75% {
                -webkit-transform: translate3d(10px, 0, 0);
                transform: translate3d(10px, 0, 0);
            }

            90% {
                -webkit-transform: translate3d(-5px, 0, 0);
                transform: translate3d(-5px, 0, 0);
            }

            100% {
                -webkit-transform: none;
                transform: none;
            }
        }

        @keyframes bounceInRight {
            0%,
            60%,
            75%,
            90%,
            100% {
                -webkit-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
                transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            }

            0% {
                opacity: 0;
                -webkit-transform: translate3d(3000px, 0, 0);
                transform: translate3d(3000px, 0, 0);
            }

            60% {
                opacity: 1;
                -webkit-transform: translate3d(-25px, 0, 0);
                transform: translate3d(-25px, 0, 0);
            }

            75% {
                -webkit-transform: translate3d(10px, 0, 0);
                transform: translate3d(10px, 0, 0);
            }

            90% {
                -webkit-transform: translate3d(-5px, 0, 0);
                transform: translate3d(-5px, 0, 0);
            }

            100% {
                -webkit-transform: none;
                transform: none;
            }
        }

        .bounceInRight {
            -webkit-animation-name: bounceInRight;
            animation-name: bounceInRight;
        }

        @-webkit-keyframes bounceInUp {
            0%,
            60%,
            75%,
            90%,
            100% {
                -webkit-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
                transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            }

            0% {
                opacity: 0;
                -webkit-transform: translate3d(0, 3000px, 0);
                transform: translate3d(0, 3000px, 0);
            }

            60% {
                opacity: 1;
                -webkit-transform: translate3d(0, -20px, 0);
                transform: translate3d(0, -20px, 0);
            }

            75% {
                -webkit-transform: translate3d(0, 10px, 0);
                transform: translate3d(0, 10px, 0);
            }

            90% {
                -webkit-transform: translate3d(0, -5px, 0);
                transform: translate3d(0, -5px, 0);
            }

            100% {
                -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0);
            }
        }

        @keyframes bounceInUp {
            0%,
            60%,
            75%,
            90%,
            100% {
                -webkit-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
                transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            }

            0% {
                opacity: 0;
                -webkit-transform: translate3d(0, 3000px, 0);
                transform: translate3d(0, 3000px, 0);
            }

            60% {
                opacity: 1;
                -webkit-transform: translate3d(0, -20px, 0);
                transform: translate3d(0, -20px, 0);
            }

            75% {
                -webkit-transform: translate3d(0, 10px, 0);
                transform: translate3d(0, 10px, 0);
            }

            90% {
                -webkit-transform: translate3d(0, -5px, 0);
                transform: translate3d(0, -5px, 0);
            }

            100% {
                -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0);
            }
        }

        .bounceInUp {
            -webkit-animation-name: bounceInUp;
            animation-name: bounceInUp;
        }

        @-webkit-keyframes bounceOut {
            20% {
                -webkit-transform: scale3d(0.9, 0.9, 0.9);
                transform: scale3d(0.9, 0.9, 0.9);
            }

            50%,
            55% {
                opacity: 1;
                -webkit-transform: scale3d(1.1, 1.1, 1.1);
                transform: scale3d(1.1, 1.1, 1.1);
            }

            100% {
                opacity: 0;
                -webkit-transform: scale3d(0.3, 0.3, 0.3);
                transform: scale3d(0.3, 0.3, 0.3);
            }
        }

        @keyframes bounceOut {
            20% {
                -webkit-transform: scale3d(0.9, 0.9, 0.9);
                transform: scale3d(0.9, 0.9, 0.9);
            }

            50%,
            55% {
                opacity: 1;
                -webkit-transform: scale3d(1.1, 1.1, 1.1);
                transform: scale3d(1.1, 1.1, 1.1);
            }

            100% {
                opacity: 0;
                -webkit-transform: scale3d(0.3, 0.3, 0.3);
                transform: scale3d(0.3, 0.3, 0.3);
            }
        }

        .bounceOut {
            -webkit-animation-name: bounceOut;
            animation-name: bounceOut;
            -webkit-animation-duration: 0.75s;
            animation-duration: 0.75s;
        }

        @-webkit-keyframes bounceOutDown {
            20% {
                -webkit-transform: translate3d(0, 10px, 0);
                transform: translate3d(0, 10px, 0);
            }

            40%,
            45% {
                opacity: 1;
                -webkit-transform: translate3d(0, -20px, 0);
                transform: translate3d(0, -20px, 0);
            }

            100% {
                opacity: 0;
                -webkit-transform: translate3d(0, 2000px, 0);
                transform: translate3d(0, 2000px, 0);
            }
        }

        @keyframes bounceOutDown {
            20% {
                -webkit-transform: translate3d(0, 10px, 0);
                transform: translate3d(0, 10px, 0);
            }

            40%,
            45% {
                opacity: 1;
                -webkit-transform: translate3d(0, -20px, 0);
                transform: translate3d(0, -20px, 0);
            }

            100% {
                opacity: 0;
                -webkit-transform: translate3d(0, 2000px, 0);
                transform: translate3d(0, 2000px, 0);
            }
        }

        .bounceOutDown {
            -webkit-animation-name: bounceOutDown;
            animation-name: bounceOutDown;
        }

        @-webkit-keyframes bounceOutLeft {
            20% {
                opacity: 1;
                -webkit-transform: translate3d(20px, 0, 0);
                transform: translate3d(20px, 0, 0);
            }

            100% {
                opacity: 0;
                -webkit-transform: translate3d(-2000px, 0, 0);
                transform: translate3d(-2000px, 0, 0);
            }
        }

        @keyframes bounceOutLeft {
            20% {
                opacity: 1;
                -webkit-transform: translate3d(20px, 0, 0);
                transform: translate3d(20px, 0, 0);
            }

            100% {
                opacity: 0;
                -webkit-transform: translate3d(-2000px, 0, 0);
                transform: translate3d(-2000px, 0, 0);
            }
        }

        .bounceOutLeft {
            -webkit-animation-name: bounceOutLeft;
            animation-name: bounceOutLeft;
        }

        @-webkit-keyframes bounceOutRight {
            20% {
                opacity: 1;
                -webkit-transform: translate3d(-20px, 0, 0);
                transform: translate3d(-20px, 0, 0);
            }

            100% {
                opacity: 0;
                -webkit-transform: translate3d(2000px, 0, 0);
                transform: translate3d(2000px, 0, 0);
            }
        }

        @keyframes bounceOutRight {
            20% {
                opacity: 1;
                -webkit-transform: translate3d(-20px, 0, 0);
                transform: translate3d(-20px, 0, 0);
            }

            100% {
                opacity: 0;
                -webkit-transform: translate3d(2000px, 0, 0);
                transform: translate3d(2000px, 0, 0);
            }
        }

        .bounceOutRight {
            -webkit-animation-name: bounceOutRight;
            animation-name: bounceOutRight;
        }

        @-webkit-keyframes bounceOutUp {
            20% {
                -webkit-transform: translate3d(0, -10px, 0);
                transform: translate3d(0, -10px, 0);
            }

            40%,
            45% {
                opacity: 1;
                -webkit-transform: translate3d(0, 20px, 0);
                transform: translate3d(0, 20px, 0);
            }

            100% {
                opacity: 0;
                -webkit-transform: translate3d(0, -2000px, 0);
                transform: translate3d(0, -2000px, 0);
            }
        }

        @keyframes bounceOutUp {
            20% {
                -webkit-transform: translate3d(0, -10px, 0);
                transform: translate3d(0, -10px, 0);
            }

            40%,
            45% {
                opacity: 1;
                -webkit-transform: translate3d(0, 20px, 0);
                transform: translate3d(0, 20px, 0);
            }

            100% {
                opacity: 0;
                -webkit-transform: translate3d(0, -2000px, 0);
                transform: translate3d(0, -2000px, 0);
            }
        }

        .bounceOutUp {
            -webkit-animation-name: bounceOutUp;
            animation-name: bounceOutUp;
        }

        @-webkit-keyframes fadeIn {
            0% {
                opacity: 0;
            }

            100% {
                opacity: 1;
            }
        }

        @keyframes fadeIn {
            0% {
                opacity: 0;
            }

            100% {
                opacity: 1;
            }
        }

        .fadeIn {
            -webkit-animation-name: fadeIn;
            animation-name: fadeIn;
        }

        @-webkit-keyframes fadeInDown {
            0% {
                opacity: 0;
                -webkit-transform: translate3d(0, -100%, 0);
                transform: translate3d(0, -100%, 0);
            }

            100% {
                opacity: 1;
                -webkit-transform: none;
                transform: none;
            }
        }

        @keyframes fadeInDown {
            0% {
                opacity: 0;
                -webkit-transform: translate3d(0, -100%, 0);
                transform: translate3d(0, -100%, 0);
            }

            100% {
                opacity: 1;
                -webkit-transform: none;
                transform: none;
            }
        }

        .fadeInDown {
            -webkit-animation-name: fadeInDown;
            animation-name: fadeInDown;
        }

        @-webkit-keyframes fadeInDownBig {
            0% {
                opacity: 0;
                -webkit-transform: translate3d(0, -2000px, 0);
                transform: translate3d(0, -2000px, 0);
            }

            100% {
                opacity: 1;
                -webkit-transform: none;
                transform: none;
            }
        }

        @keyframes fadeInDownBig {
            0% {
                opacity: 0;
                -webkit-transform: translate3d(0, -2000px, 0);
                transform: translate3d(0, -2000px, 0);
            }

            100% {
                opacity: 1;
                -webkit-transform: none;
                transform: none;
            }
        }

        .fadeInDownBig {
            -webkit-animation-name: fadeInDownBig;
            animation-name: fadeInDownBig;
        }

        @-webkit-keyframes fadeInLeft {
            0% {
                opacity: 0;
                -webkit-transform: translate3d(-100%, 0, 0);
                transform: translate3d(-100%, 0, 0);
            }

            100% {
                opacity: 1;
                -webkit-transform: none;
                transform: none;
            }
        }

        @keyframes fadeInLeft {
            0% {
                opacity: 0;
                -webkit-transform: translate3d(-100%, 0, 0);
                transform: translate3d(-100%, 0, 0);
            }

            100% {
                opacity: 1;
                -webkit-transform: none;
                transform: none;
            }
        }

        .fadeInLeft {
            -webkit-animation-name: fadeInLeft;
            animation-name: fadeInLeft;
        }

        @-webkit-keyframes fadeInLeftBig {
            0% {
                opacity: 0;
                -webkit-transform: translate3d(-2000px, 0, 0);
                transform: translate3d(-2000px, 0, 0);
            }

            100% {
                opacity: 1;
                -webkit-transform: none;
                transform: none;
            }
        }

        @keyframes fadeInLeftBig {
            0% {
                opacity: 0;
                -webkit-transform: translate3d(-2000px, 0, 0);
                transform: translate3d(-2000px, 0, 0);
            }

            100% {
                opacity: 1;
                -webkit-transform: none;
                transform: none;
            }
        }

        .fadeInLeftBig {
            -webkit-animation-name: fadeInLeftBig;
            animation-name: fadeInLeftBig;
        }

        @-webkit-keyframes fadeInRight {
            0% {
                opacity: 0;
                -webkit-transform: translate3d(100%, 0, 0);
                transform: translate3d(100%, 0, 0);
            }

            100% {
                opacity: 1;
                -webkit-transform: none;
                transform: none;
            }
        }

        @keyframes fadeInRight {
            0% {
                opacity: 0;
                -webkit-transform: translate3d(100%, 0, 0);
                transform: translate3d(100%, 0, 0);
            }

            100% {
                opacity: 1;
                -webkit-transform: none;
                transform: none;
            }
        }

        .fadeInRight {
            -webkit-animation-name: fadeInRight;
            animation-name: fadeInRight;
        }

        @-webkit-keyframes fadeInRightBig {
            0% {
                opacity: 0;
                -webkit-transform: translate3d(2000px, 0, 0);
                transform: translate3d(2000px, 0, 0);
            }

            100% {
                opacity: 1;
                -webkit-transform: none;
                transform: none;
            }
        }

        @keyframes fadeInRightBig {
            0% {
                opacity: 0;
                -webkit-transform: translate3d(2000px, 0, 0);
                transform: translate3d(2000px, 0, 0);
            }

            100% {
                opacity: 1;
                -webkit-transform: none;
                transform: none;
            }
        }

        .fadeInRightBig {
            -webkit-animation-name: fadeInRightBig;
            animation-name: fadeInRightBig;
        }

        @-webkit-keyframes fadeInUp {
            0% {
                opacity: 0;
                -webkit-transform: translate3d(0, 100%, 0);
                transform: translate3d(0, 100%, 0);
            }

            100% {
                opacity: 1;
                -webkit-transform: none;
                transform: none;
            }
        }

        @keyframes fadeInUp {
            0% {
                opacity: 0;
                -webkit-transform: translate3d(0, 100%, 0);
                transform: translate3d(0, 100%, 0);
            }

            100% {
                opacity: 1;
                -webkit-transform: none;
                transform: none;
            }
        }

        .fadeInUp {
            -webkit-animation-name: fadeInUp;
            animation-name: fadeInUp;
        }

        @-webkit-keyframes fadeInUpBig {
            0% {
                opacity: 0;
                -webkit-transform: translate3d(0, 2000px, 0);
                transform: translate3d(0, 2000px, 0);
            }

            100% {
                opacity: 1;
                -webkit-transform: none;
                transform: none;
            }
        }

        @keyframes fadeInUpBig {
            0% {
                opacity: 0;
                -webkit-transform: translate3d(0, 2000px, 0);
                transform: translate3d(0, 2000px, 0);
            }

            100% {
                opacity: 1;
                -webkit-transform: none;
                transform: none;
            }
        }

        .fadeInUpBig {
            -webkit-animation-name: fadeInUpBig;
            animation-name: fadeInUpBig;
        }

        @-webkit-keyframes fadeOut {
            0% {
                opacity: 1;
            }

            100% {
                opacity: 0;
            }
        }

        @keyframes fadeOut {
            0% {
                opacity: 1;
            }

            100% {
                opacity: 0;
            }
        }

        .fadeOut {
            -webkit-animation-name: fadeOut;
            animation-name: fadeOut;
        }

        @-webkit-keyframes fadeOutDown {
            0% {
                opacity: 1;
            }

            100% {
                opacity: 0;
                -webkit-transform: translate3d(0, 100%, 0);
                transform: translate3d(0, 100%, 0);
            }
        }

        @keyframes fadeOutDown {
            0% {
                opacity: 1;
            }

            100% {
                opacity: 0;
                -webkit-transform: translate3d(0, 100%, 0);
                transform: translate3d(0, 100%, 0);
            }
        }

        .fadeOutDown {
            -webkit-animation-name: fadeOutDown;
            animation-name: fadeOutDown;
        }

        @-webkit-keyframes fadeOutDownBig {
            0% {
                opacity: 1;
            }

            100% {
                opacity: 0;
                -webkit-transform: translate3d(0, 2000px, 0);
                transform: translate3d(0, 2000px, 0);
            }
        }

        @keyframes fadeOutDownBig {
            0% {
                opacity: 1;
            }

            100% {
                opacity: 0;
                -webkit-transform: translate3d(0, 2000px, 0);
                transform: translate3d(0, 2000px, 0);
            }
        }

        .fadeOutDownBig {
            -webkit-animation-name: fadeOutDownBig;
            animation-name: fadeOutDownBig;
        }

        @-webkit-keyframes fadeOutLeft {
            0% {
                opacity: 1;
            }

            100% {
                opacity: 0;
                -webkit-transform: translate3d(-100%, 0, 0);
                transform: translate3d(-100%, 0, 0);
            }
        }

        @keyframes fadeOutLeft {
            0% {
                opacity: 1;
            }

            100% {
                opacity: 0;
                -webkit-transform: translate3d(-100%, 0, 0);
                transform: translate3d(-100%, 0, 0);
            }
        }

        .fadeOutLeft {
            -webkit-animation-name: fadeOutLeft;
            animation-name: fadeOutLeft;
        }

        @-webkit-keyframes fadeOutLeftBig {
            0% {
                opacity: 1;
            }

            100% {
                opacity: 0;
                -webkit-transform: translate3d(-2000px, 0, 0);
                transform: translate3d(-2000px, 0, 0);
            }
        }

        @keyframes fadeOutLeftBig {
            0% {
                opacity: 1;
            }

            100% {
                opacity: 0;
                -webkit-transform: translate3d(-2000px, 0, 0);
                transform: translate3d(-2000px, 0, 0);
            }
        }

        .fadeOutLeftBig {
            -webkit-animation-name: fadeOutLeftBig;
            animation-name: fadeOutLeftBig;
        }

        @-webkit-keyframes fadeOutRight {
            0% {
                opacity: 1;
            }

            100% {
                opacity: 0;
                -webkit-transform: translate3d(100%, 0, 0);
                transform: translate3d(100%, 0, 0);
            }
        }

        @keyframes fadeOutRight {
            0% {
                opacity: 1;
            }

            100% {
                opacity: 0;
                -webkit-transform: translate3d(100%, 0, 0);
                transform: translate3d(100%, 0, 0);
            }
        }

        .fadeOutRight {
            -webkit-animation-name: fadeOutRight;
            animation-name: fadeOutRight;
        }

        @-webkit-keyframes fadeOutRightBig {
            0% {
                opacity: 1;
            }

            100% {
                opacity: 0;
                -webkit-transform: translate3d(2000px, 0, 0);
                transform: translate3d(2000px, 0, 0);
            }
        }

        @keyframes fadeOutRightBig {
            0% {
                opacity: 1;
            }

            100% {
                opacity: 0;
                -webkit-transform: translate3d(2000px, 0, 0);
                transform: translate3d(2000px, 0, 0);
            }
        }

        .fadeOutRightBig {
            -webkit-animation-name: fadeOutRightBig;
            animation-name: fadeOutRightBig;
        }

        @-webkit-keyframes fadeOutUp {
            0% {
                opacity: 1;
            }

            100% {
                opacity: 0;
                -webkit-transform: translate3d(0, -100%, 0);
                transform: translate3d(0, -100%, 0);
            }
        }

        @keyframes fadeOutUp {
            0% {
                opacity: 1;
            }

            100% {
                opacity: 0;
                -webkit-transform: translate3d(0, -100%, 0);
                transform: translate3d(0, -100%, 0);
            }
        }

        .fadeOutUp {
            -webkit-animation-name: fadeOutUp;
            animation-name: fadeOutUp;
        }

        @-webkit-keyframes fadeOutUpBig {
            0% {
                opacity: 1;
            }

            100% {
                opacity: 0;
                -webkit-transform: translate3d(0, -2000px, 0);
                transform: translate3d(0, -2000px, 0);
            }
        }

        @keyframes fadeOutUpBig {
            0% {
                opacity: 1;
            }

            100% {
                opacity: 0;
                -webkit-transform: translate3d(0, -2000px, 0);
                transform: translate3d(0, -2000px, 0);
            }
        }

        .fadeOutUpBig {
            -webkit-animation-name: fadeOutUpBig;
            animation-name: fadeOutUpBig;
        }

        @-webkit-keyframes flip {
            0% {
                -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -360deg);
                transform: perspective(400px) rotate3d(0, 1, 0, -360deg);
                -webkit-animation-timing-function: ease-out;
                animation-timing-function: ease-out;
            }

            40% {
                -webkit-transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -190deg);
                transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -190deg);
                -webkit-animation-timing-function: ease-out;
                animation-timing-function: ease-out;
            }

            50% {
                -webkit-transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -170deg);
                transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -170deg);
                -webkit-animation-timing-function: ease-in;
                animation-timing-function: ease-in;
            }

            80% {
                -webkit-transform: perspective(400px) scale3d(0.95, 0.95, 0.95);
                transform: perspective(400px) scale3d(0.95, 0.95, 0.95);
                -webkit-animation-timing-function: ease-in;
                animation-timing-function: ease-in;
            }

            100% {
                -webkit-transform: perspective(400px);
                transform: perspective(400px);
                -webkit-animation-timing-function: ease-in;
                animation-timing-function: ease-in;
            }
        }

        @keyframes flip {
            0% {
                -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -360deg);
                transform: perspective(400px) rotate3d(0, 1, 0, -360deg);
                -webkit-animation-timing-function: ease-out;
                animation-timing-function: ease-out;
            }

            40% {
                -webkit-transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -190deg);
                transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -190deg);
                -webkit-animation-timing-function: ease-out;
                animation-timing-function: ease-out;
            }

            50% {
                -webkit-transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -170deg);
                transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -170deg);
                -webkit-animation-timing-function: ease-in;
                animation-timing-function: ease-in;
            }

            80% {
                -webkit-transform: perspective(400px) scale3d(0.95, 0.95, 0.95);
                transform: perspective(400px) scale3d(0.95, 0.95, 0.95);
                -webkit-animation-timing-function: ease-in;
                animation-timing-function: ease-in;
            }

            100% {
                -webkit-transform: perspective(400px);
                transform: perspective(400px);
                -webkit-animation-timing-function: ease-in;
                animation-timing-function: ease-in;
            }
        }

        .animated.flip {
            -webkit-backface-visibility: visible;
            backface-visibility: visible;
            -webkit-animation-name: flip;
            animation-name: flip;
        }

        @-webkit-keyframes flipInX {
            0% {
                -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
                transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
                -webkit-transition-timing-function: ease-in;
                transition-timing-function: ease-in;
                opacity: 0;
            }

            40% {
                -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
                transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
                -webkit-transition-timing-function: ease-in;
                transition-timing-function: ease-in;
            }

            60% {
                -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
                transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
                opacity: 1;
            }

            80% {
                -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
                transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
            }

            100% {
                -webkit-transform: perspective(400px);
                transform: perspective(400px);
            }
        }

        @keyframes flipInX {
            0% {
                -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
                transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
                -webkit-transition-timing-function: ease-in;
                transition-timing-function: ease-in;
                opacity: 0;
            }

            40% {
                -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
                transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
                -webkit-transition-timing-function: ease-in;
                transition-timing-function: ease-in;
            }

            60% {
                -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
                transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
                opacity: 1;
            }

            80% {
                -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
                transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
            }

            100% {
                -webkit-transform: perspective(400px);
                transform: perspective(400px);
            }
        }

        .flipInX {
            -webkit-backface-visibility: visible !important;
            backface-visibility: visible !important;
            -webkit-animation-name: flipInX;
            animation-name: flipInX;
        }

        @-webkit-keyframes flipInY {
            0% {
                -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
                transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
                -webkit-transition-timing-function: ease-in;
                transition-timing-function: ease-in;
                opacity: 0;
            }

            40% {
                -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
                transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
                -webkit-transition-timing-function: ease-in;
                transition-timing-function: ease-in;
            }

            60% {
                -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
                transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
                opacity: 1;
            }

            80% {
                -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -5deg);
                transform: perspective(400px) rotate3d(0, 1, 0, -5deg);
            }

            100% {
                -webkit-transform: perspective(400px);
                transform: perspective(400px);
            }
        }

        @keyframes flipInY {
            0% {
                -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
                transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
                -webkit-transition-timing-function: ease-in;
                transition-timing-function: ease-in;
                opacity: 0;
            }

            40% {
                -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
                transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
                -webkit-transition-timing-function: ease-in;
                transition-timing-function: ease-in;
            }

            60% {
                -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
                transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
                opacity: 1;
            }

            80% {
                -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -5deg);
                transform: perspective(400px) rotate3d(0, 1, 0, -5deg);
            }

            100% {
                -webkit-transform: perspective(400px);
                transform: perspective(400px);
            }
        }

        .flipInY {
            -webkit-backface-visibility: visible !important;
            backface-visibility: visible !important;
            -webkit-animation-name: flipInY;
            animation-name: flipInY;
        }

        @-webkit-keyframes flipOutX {
            0% {
                -webkit-transform: perspective(400px);
                transform: perspective(400px);
            }

            30% {
                -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
                transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
                opacity: 1;
            }

            100% {
                -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
                transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
                opacity: 0;
            }
        }

        @keyframes flipOutX {
            0% {
                -webkit-transform: perspective(400px);
                transform: perspective(400px);
            }

            30% {
                -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
                transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
                opacity: 1;
            }

            100% {
                -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
                transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
                opacity: 0;
            }
        }

        .flipOutX {
            -webkit-animation-name: flipOutX;
            animation-name: flipOutX;
            -webkit-animation-duration: 0.75s;
            animation-duration: 0.75s;
            -webkit-backface-visibility: visible !important;
            backface-visibility: visible !important;
        }

        @-webkit-keyframes flipOutY {
            0% {
                -webkit-transform: perspective(400px);
                transform: perspective(400px);
            }

            30% {
                -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -15deg);
                transform: perspective(400px) rotate3d(0, 1, 0, -15deg);
                opacity: 1;
            }

            100% {
                -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
                transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
                opacity: 0;
            }
        }

        @keyframes flipOutY {
            0% {
                -webkit-transform: perspective(400px);
                transform: perspective(400px);
            }

            30% {
                -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -15deg);
                transform: perspective(400px) rotate3d(0, 1, 0, -15deg);
                opacity: 1;
            }

            100% {
                -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
                transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
                opacity: 0;
            }
        }

        .flipOutY {
            -webkit-backface-visibility: visible !important;
            backface-visibility: visible !important;
            -webkit-animation-name: flipOutY;
            animation-name: flipOutY;
            -webkit-animation-duration: 0.75s;
            animation-duration: 0.75s;
        }

        @-webkit-keyframes lightSpeedIn {
            0% {
                -webkit-transform: translate3d(100%, 0, 0) skewX(-30deg);
                transform: translate3d(100%, 0, 0) skewX(-30deg);
                opacity: 0;
            }

            60% {
                -webkit-transform: skewX(20deg);
                transform: skewX(20deg);
                opacity: 1;
            }

            80% {
                -webkit-transform: skewX(-5deg);
                transform: skewX(-5deg);
                opacity: 1;
            }

            100% {
                -webkit-transform: none;
                transform: none;
                opacity: 1;
            }
        }

        @keyframes lightSpeedIn {
            0% {
                -webkit-transform: translate3d(100%, 0, 0) skewX(-30deg);
                transform: translate3d(100%, 0, 0) skewX(-30deg);
                opacity: 0;
            }

            60% {
                -webkit-transform: skewX(20deg);
                transform: skewX(20deg);
                opacity: 1;
            }

            80% {
                -webkit-transform: skewX(-5deg);
                transform: skewX(-5deg);
                opacity: 1;
            }

            100% {
                -webkit-transform: none;
                transform: none;
                opacity: 1;
            }
        }

        .lightSpeedIn {
            -webkit-animation-name: lightSpeedIn;
            animation-name: lightSpeedIn;
            -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
        }

        @-webkit-keyframes lightSpeedOut {
            0% {
                opacity: 1;
            }

            100% {
                -webkit-transform: translate3d(100%, 0, 0) skewX(30deg);
                transform: translate3d(100%, 0, 0) skewX(30deg);
                opacity: 0;
            }
        }

        @keyframes lightSpeedOut {
            0% {
                opacity: 1;
            }

            100% {
                -webkit-transform: translate3d(100%, 0, 0) skewX(30deg);
                transform: translate3d(100%, 0, 0) skewX(30deg);
                opacity: 0;
            }
        }

        .lightSpeedOut {
            -webkit-animation-name: lightSpeedOut;
            animation-name: lightSpeedOut;
            -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
        }

        @-webkit-keyframes rotateIn {
            0% {
                -webkit-transform-origin: center;
                transform-origin: center;
                -webkit-transform: rotate3d(0, 0, 1, -200deg);
                transform: rotate3d(0, 0, 1, -200deg);
                opacity: 0;
            }

            100% {
                -webkit-transform-origin: center;
                transform-origin: center;
                -webkit-transform: none;
                transform: none;
                opacity: 1;
            }
        }

        @keyframes rotateIn {
            0% {
                -webkit-transform-origin: center;
                transform-origin: center;
                -webkit-transform: rotate3d(0, 0, 1, -200deg);
                transform: rotate3d(0, 0, 1, -200deg);
                opacity: 0;
            }

            100% {
                -webkit-transform-origin: center;
                transform-origin: center;
                -webkit-transform: none;
                transform: none;
                opacity: 1;
            }
        }

        .rotateIn {
            -webkit-animation-name: rotateIn;
            animation-name: rotateIn;
        }

        @-webkit-keyframes rotateInDownLeft {
            0% {
                -webkit-transform-origin: left bottom;
                transform-origin: left bottom;
                -webkit-transform: rotate3d(0, 0, 1, -45deg);
                transform: rotate3d(0, 0, 1, -45deg);
                opacity: 0;
            }

            100% {
                -webkit-transform-origin: left bottom;
                transform-origin: left bottom;
                -webkit-transform: none;
                transform: none;
                opacity: 1;
            }
        }

        @keyframes rotateInDownLeft {
            0% {
                -webkit-transform-origin: left bottom;
                transform-origin: left bottom;
                -webkit-transform: rotate3d(0, 0, 1, -45deg);
                transform: rotate3d(0, 0, 1, -45deg);
                opacity: 0;
            }

            100% {
                -webkit-transform-origin: left bottom;
                transform-origin: left bottom;
                -webkit-transform: none;
                transform: none;
                opacity: 1;
            }
        }

        .rotateInDownLeft {
            -webkit-animation-name: rotateInDownLeft;
            animation-name: rotateInDownLeft;
        }

        @-webkit-keyframes rotateInDownRight {
            0% {
                -webkit-transform-origin: right bottom;
                transform-origin: right bottom;
                -webkit-transform: rotate3d(0, 0, 1, 45deg);
                transform: rotate3d(0, 0, 1, 45deg);
                opacity: 0;
            }

            100% {
                -webkit-transform-origin: right bottom;
                transform-origin: right bottom;
                -webkit-transform: none;
                transform: none;
                opacity: 1;
            }
        }

        @keyframes rotateInDownRight {
            0% {
                -webkit-transform-origin: right bottom;
                transform-origin: right bottom;
                -webkit-transform: rotate3d(0, 0, 1, 45deg);
                transform: rotate3d(0, 0, 1, 45deg);
                opacity: 0;
            }

            100% {
                -webkit-transform-origin: right bottom;
                transform-origin: right bottom;
                -webkit-transform: none;
                transform: none;
                opacity: 1;
            }
        }

        .rotateInDownRight {
            -webkit-animation-name: rotateInDownRight;
            animation-name: rotateInDownRight;
        }

        @-webkit-keyframes rotateInUpLeft {
            0% {
                -webkit-transform-origin: left bottom;
                transform-origin: left bottom;
                -webkit-transform: rotate3d(0, 0, 1, 45deg);
                transform: rotate3d(0, 0, 1, 45deg);
                opacity: 0;
            }

            100% {
                -webkit-transform-origin: left bottom;
                transform-origin: left bottom;
                -webkit-transform: none;
                transform: none;
                opacity: 1;
            }
        }

        @keyframes rotateInUpLeft {
            0% {
                -webkit-transform-origin: left bottom;
                transform-origin: left bottom;
                -webkit-transform: rotate3d(0, 0, 1, 45deg);
                transform: rotate3d(0, 0, 1, 45deg);
                opacity: 0;
            }

            100% {
                -webkit-transform-origin: left bottom;
                transform-origin: left bottom;
                -webkit-transform: none;
                transform: none;
                opacity: 1;
            }
        }

        .rotateInUpLeft {
            -webkit-animation-name: rotateInUpLeft;
            animation-name: rotateInUpLeft;
        }

        @-webkit-keyframes rotateInUpRight {
            0% {
                -webkit-transform-origin: right bottom;
                transform-origin: right bottom;
                -webkit-transform: rotate3d(0, 0, 1, -90deg);
                transform: rotate3d(0, 0, 1, -90deg);
                opacity: 0;
            }

            100% {
                -webkit-transform-origin: right bottom;
                transform-origin: right bottom;
                -webkit-transform: none;
                transform: none;
                opacity: 1;
            }
        }

        @keyframes rotateInUpRight {
            0% {
                -webkit-transform-origin: right bottom;
                transform-origin: right bottom;
                -webkit-transform: rotate3d(0, 0, 1, -90deg);
                transform: rotate3d(0, 0, 1, -90deg);
                opacity: 0;
            }

            100% {
                -webkit-transform-origin: right bottom;
                transform-origin: right bottom;
                -webkit-transform: none;
                transform: none;
                opacity: 1;
            }
        }

        .rotateInUpRight {
            -webkit-animation-name: rotateInUpRight;
            animation-name: rotateInUpRight;
        }

        @-webkit-keyframes rotateOut {
            0% {
                -webkit-transform-origin: center;
                transform-origin: center;
                opacity: 1;
            }

            100% {
                -webkit-transform-origin: center;
                transform-origin: center;
                -webkit-transform: rotate3d(0, 0, 1, 200deg);
                transform: rotate3d(0, 0, 1, 200deg);
                opacity: 0;
            }
        }

        @keyframes rotateOut {
            0% {
                -webkit-transform-origin: center;
                transform-origin: center;
                opacity: 1;
            }

            100% {
                -webkit-transform-origin: center;
                transform-origin: center;
                -webkit-transform: rotate3d(0, 0, 1, 200deg);
                transform: rotate3d(0, 0, 1, 200deg);
                opacity: 0;
            }
        }

        .rotateOut {
            -webkit-animation-name: rotateOut;
            animation-name: rotateOut;
        }

        @-webkit-keyframes rotateOutDownLeft {
            0% {
                -webkit-transform-origin: left bottom;
                transform-origin: left bottom;
                opacity: 1;
            }

            100% {
                -webkit-transform-origin: left bottom;
                transform-origin: left bottom;
                -webkit-transform: rotate3d(0, 0, 1, 45deg);
                transform: rotate3d(0, 0, 1, 45deg);
                opacity: 0;
            }
        }

        @keyframes rotateOutDownLeft {
            0% {
                -webkit-transform-origin: left bottom;
                transform-origin: left bottom;
                opacity: 1;
            }

            100% {
                -webkit-transform-origin: left bottom;
                transform-origin: left bottom;
                -webkit-transform: rotate3d(0, 0, 1, 45deg);
                transform: rotate3d(0, 0, 1, 45deg);
                opacity: 0;
            }
        }

        .rotateOutDownLeft {
            -webkit-animation-name: rotateOutDownLeft;
            animation-name: rotateOutDownLeft;
        }

        @-webkit-keyframes rotateOutDownRight {
            0% {
                -webkit-transform-origin: right bottom;
                transform-origin: right bottom;
                opacity: 1;
            }

            100% {
                -webkit-transform-origin: right bottom;
                transform-origin: right bottom;
                -webkit-transform: rotate3d(0, 0, 1, -45deg);
                transform: rotate3d(0, 0, 1, -45deg);
                opacity: 0;
            }
        }

        @keyframes rotateOutDownRight {
            0% {
                -webkit-transform-origin: right bottom;
                transform-origin: right bottom;
                opacity: 1;
            }

            100% {
                -webkit-transform-origin: right bottom;
                transform-origin: right bottom;
                -webkit-transform: rotate3d(0, 0, 1, -45deg);
                transform: rotate3d(0, 0, 1, -45deg);
                opacity: 0;
            }
        }

        .rotateOutDownRight {
            -webkit-animation-name: rotateOutDownRight;
            animation-name: rotateOutDownRight;
        }

        @-webkit-keyframes rotateOutUpLeft {
            0% {
                -webkit-transform-origin: left bottom;
                transform-origin: left bottom;
                opacity: 1;
            }

            100% {
                -webkit-transform-origin: left bottom;
                transform-origin: left bottom;
                -webkit-transform: rotate3d(0, 0, 1, -45deg);
                transform: rotate3d(0, 0, 1, -45deg);
                opacity: 0;
            }
        }

        @keyframes rotateOutUpLeft {
            0% {
                -webkit-transform-origin: left bottom;
                transform-origin: left bottom;
                opacity: 1;
            }

            100% {
                -webkit-transform-origin: left bottom;
                transform-origin: left bottom;
                -webkit-transform: rotate3d(0, 0, 1, -45deg);
                transform: rotate3d(0, 0, 1, -45deg);
                opacity: 0;
            }
        }

        .rotateOutUpLeft {
            -webkit-animation-name: rotateOutUpLeft;
            animation-name: rotateOutUpLeft;
        }

        @-webkit-keyframes rotateOutUpRight {
            0% {
                -webkit-transform-origin: right bottom;
                transform-origin: right bottom;
                opacity: 1;
            }

            100% {
                -webkit-transform-origin: right bottom;
                transform-origin: right bottom;
                -webkit-transform: rotate3d(0, 0, 1, 90deg);
                transform: rotate3d(0, 0, 1, 90deg);
                opacity: 0;
            }
        }

        @keyframes rotateOutUpRight {
            0% {
                -webkit-transform-origin: right bottom;
                transform-origin: right bottom;
                opacity: 1;
            }

            100% {
                -webkit-transform-origin: right bottom;
                transform-origin: right bottom;
                -webkit-transform: rotate3d(0, 0, 1, 90deg);
                transform: rotate3d(0, 0, 1, 90deg);
                opacity: 0;
            }
        }

        .rotateOutUpRight {
            -webkit-animation-name: rotateOutUpRight;
            animation-name: rotateOutUpRight;
        }

        @-webkit-keyframes hinge {
            0% {
                -webkit-transform-origin: top left;
                transform-origin: top left;
                -webkit-animation-timing-function: ease-in-out;
                animation-timing-function: ease-in-out;
            }

            20%,
            60% {
                -webkit-transform: rotate3d(0, 0, 1, 80deg);
                transform: rotate3d(0, 0, 1, 80deg);
                -webkit-transform-origin: top left;
                transform-origin: top left;
                -webkit-animation-timing-function: ease-in-out;
                animation-timing-function: ease-in-out;
            }

            40%,
            80% {
                -webkit-transform: rotate3d(0, 0, 1, 60deg);
                transform: rotate3d(0, 0, 1, 60deg);
                -webkit-transform-origin: top left;
                transform-origin: top left;
                -webkit-animation-timing-function: ease-in-out;
                animation-timing-function: ease-in-out;
                opacity: 1;
            }

            100% {
                -webkit-transform: translate3d(0, 700px, 0);
                transform: translate3d(0, 700px, 0);
                opacity: 0;
            }
        }

        @keyframes hinge {
            0% {
                -webkit-transform-origin: top left;
                transform-origin: top left;
                -webkit-animation-timing-function: ease-in-out;
                animation-timing-function: ease-in-out;
            }

            20%,
            60% {
                -webkit-transform: rotate3d(0, 0, 1, 80deg);
                transform: rotate3d(0, 0, 1, 80deg);
                -webkit-transform-origin: top left;
                transform-origin: top left;
                -webkit-animation-timing-function: ease-in-out;
                animation-timing-function: ease-in-out;
            }

            40%,
            80% {
                -webkit-transform: rotate3d(0, 0, 1, 60deg);
                transform: rotate3d(0, 0, 1, 60deg);
                -webkit-transform-origin: top left;
                transform-origin: top left;
                -webkit-animation-timing-function: ease-in-out;
                animation-timing-function: ease-in-out;
                opacity: 1;
            }

            100% {
                -webkit-transform: translate3d(0, 700px, 0);
                transform: translate3d(0, 700px, 0);
                opacity: 0;
            }
        }

        .hinge {
            -webkit-animation-name: hinge;
            animation-name: hinge;
        }

        @-webkit-keyframes rollIn {
            0% {
                opacity: 0;
                -webkit-transform: translate3d(-100%, 0, 0) rotate3d(0, 0, 1, -120deg);
                transform: translate3d(-100%, 0, 0) rotate3d(0, 0, 1, -120deg);
            }

            100% {
                opacity: 1;
                -webkit-transform: none;
                transform: none;
            }
        }

        @keyframes rollIn {
            0% {
                opacity: 0;
                -webkit-transform: translate3d(-100%, 0, 0) rotate3d(0, 0, 1, -120deg);
                transform: translate3d(-100%, 0, 0) rotate3d(0, 0, 1, -120deg);
            }

            100% {
                opacity: 1;
                -webkit-transform: none;
                transform: none;
            }
        }

        .rollIn {
            -webkit-animation-name: rollIn;
            animation-name: rollIn;
        }

        @-webkit-keyframes rollOut {
            0% {
                opacity: 1;
            }

            100% {
                opacity: 0;
                -webkit-transform: translate3d(100%, 0, 0) rotate3d(0, 0, 1, 120deg);
                transform: translate3d(100%, 0, 0) rotate3d(0, 0, 1, 120deg);
            }
        }

        @keyframes rollOut {
            0% {
                opacity: 1;
            }

            100% {
                opacity: 0;
                -webkit-transform: translate3d(100%, 0, 0) rotate3d(0, 0, 1, 120deg);
                transform: translate3d(100%, 0, 0) rotate3d(0, 0, 1, 120deg);
            }
        }

        .rollOut {
            -webkit-animation-name: rollOut;
            animation-name: rollOut;
        }

        @-webkit-keyframes zoomIn {
            0% {
                opacity: 0;
                -webkit-transform: scale3d(0.3, 0.3, 0.3);
                transform: scale3d(0.3, 0.3, 0.3);
            }

            50% {
                opacity: 1;
            }
        }

        @keyframes zoomIn {
            0% {
                opacity: 0;
                -webkit-transform: scale3d(0.3, 0.3, 0.3);
                transform: scale3d(0.3, 0.3, 0.3);
            }

            50% {
                opacity: 1;
            }
        }

        .zoomIn {
            -webkit-animation-name: zoomIn;
            animation-name: zoomIn;
        }

        @-webkit-keyframes zoomInDown {
            0% {
                opacity: 0;
                -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
                transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
                -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
                animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
            }

            60% {
                opacity: 1;
                -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
                transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
                -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
                animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
            }
        }

        @keyframes zoomInDown {
            0% {
                opacity: 0;
                -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
                transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
                -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
                animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
            }

            60% {
                opacity: 1;
                -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
                transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
                -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
                animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
            }
        }

        .zoomInDown {
            -webkit-animation-name: zoomInDown;
            animation-name: zoomInDown;
        }

        @-webkit-keyframes zoomInLeft {
            0% {
                opacity: 0;
                -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
                transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
                -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
                animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
            }

            60% {
                opacity: 1;
                -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
                transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
                -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
                animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
            }
        }

        @keyframes zoomInLeft {
            0% {
                opacity: 0;
                -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
                transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
                -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
                animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
            }

            60% {
                opacity: 1;
                -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
                transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
                -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
                animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
            }
        }

        .zoomInLeft {
            -webkit-animation-name: zoomInLeft;
            animation-name: zoomInLeft;
        }

        @-webkit-keyframes zoomInRight {
            0% {
                opacity: 0;
                -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
                transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
                -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
                animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
            }

            60% {
                opacity: 1;
                -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
                transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
                -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
                animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
            }
        }

        @keyframes zoomInRight {
            0% {
                opacity: 0;
                -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
                transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
                -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
                animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
            }

            60% {
                opacity: 1;
                -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
                transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
                -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
                animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
            }
        }

        .zoomInRight {
            -webkit-animation-name: zoomInRight;
            animation-name: zoomInRight;
        }

        @-webkit-keyframes zoomInUp {
            0% {
                opacity: 0;
                -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
                transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
                -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
                animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
            }

            60% {
                opacity: 1;
                -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
                transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
                -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
                animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
            }
        }

        @keyframes zoomInUp {
            0% {
                opacity: 0;
                -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
                transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
                -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
                animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
            }

            60% {
                opacity: 1;
                -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
                transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
                -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
                animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
            }
        }

        .zoomInUp {
            -webkit-animation-name: zoomInUp;
            animation-name: zoomInUp;
        }

        @-webkit-keyframes zoomOut {
            0% {
                opacity: 1;
            }

            50% {
                opacity: 0;
                -webkit-transform: scale3d(0.3, 0.3, 0.3);
                transform: scale3d(0.3, 0.3, 0.3);
            }

            100% {
                opacity: 0;
            }
        }

        @keyframes zoomOut {
            0% {
                opacity: 1;
            }

            50% {
                opacity: 0;
                -webkit-transform: scale3d(0.3, 0.3, 0.3);
                transform: scale3d(0.3, 0.3, 0.3);
            }

            100% {
                opacity: 0;
            }
        }

        .zoomOut {
            -webkit-animation-name: zoomOut;
            animation-name: zoomOut;
        }

        @-webkit-keyframes zoomOutDown {
            40% {
                opacity: 1;
                -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
                transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
                -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
                animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
            }

            100% {
                opacity: 0;
                -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
                transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
                -webkit-transform-origin: center bottom;
                transform-origin: center bottom;
                -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
                animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
            }
        }

        @keyframes zoomOutDown {
            40% {
                opacity: 1;
                -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
                transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
                -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
                animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
            }

            100% {
                opacity: 0;
                -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
                transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
                -webkit-transform-origin: center bottom;
                transform-origin: center bottom;
                -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
                animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
            }
        }

        .zoomOutDown {
            -webkit-animation-name: zoomOutDown;
            animation-name: zoomOutDown;
        }

        @-webkit-keyframes zoomOutLeft {
            40% {
                opacity: 1;
                -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0);
                transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0);
            }

            100% {
                opacity: 0;
                -webkit-transform: scale(0.1) translate3d(-2000px, 0, 0);
                transform: scale(0.1) translate3d(-2000px, 0, 0);
                -webkit-transform-origin: left center;
                transform-origin: left center;
            }
        }

        @keyframes zoomOutLeft {
            40% {
                opacity: 1;
                -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0);
                transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0);
            }

            100% {
                opacity: 0;
                -webkit-transform: scale(0.1) translate3d(-2000px, 0, 0);
                transform: scale(0.1) translate3d(-2000px, 0, 0);
                -webkit-transform-origin: left center;
                transform-origin: left center;
            }
        }

        .zoomOutLeft {
            -webkit-animation-name: zoomOutLeft;
            animation-name: zoomOutLeft;
        }

        @-webkit-keyframes zoomOutRight {
            40% {
                opacity: 1;
                -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0);
                transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0);
            }

            100% {
                opacity: 0;
                -webkit-transform: scale(0.1) translate3d(2000px, 0, 0);
                transform: scale(0.1) translate3d(2000px, 0, 0);
                -webkit-transform-origin: right center;
                transform-origin: right center;
            }
        }

        @keyframes zoomOutRight {
            40% {
                opacity: 1;
                -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0);
                transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0);
            }

            100% {
                opacity: 0;
                -webkit-transform: scale(0.1) translate3d(2000px, 0, 0);
                transform: scale(0.1) translate3d(2000px, 0, 0);
                -webkit-transform-origin: right center;
                transform-origin: right center;
            }
        }

        .zoomOutRight {
            -webkit-animation-name: zoomOutRight;
            animation-name: zoomOutRight;
        }

        @-webkit-keyframes zoomOutUp {
            40% {
                opacity: 1;
                -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
                transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
                -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
                animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
            }

            100% {
                opacity: 0;
                -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
                transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
                -webkit-transform-origin: center bottom;
                transform-origin: center bottom;
                -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
                animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
            }
        }

        @keyframes zoomOutUp {
            40% {
                opacity: 1;
                -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
                transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
                -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
                animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
            }

            100% {
                opacity: 0;
                -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
                transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
                -webkit-transform-origin: center bottom;
                transform-origin: center bottom;
                -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
                animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
            }
        }

        .zoomOutUp {
            -webkit-animation-name: zoomOutUp;
            animation-name: zoomOutUp;
        }

        @-webkit-keyframes slideInDown {
            0% {
                -webkit-transform: translateY(-100%);
                transform: translateY(-100%);
                visibility: visible;
            }

            100% {
                -webkit-transform: translateY(0);
                transform: translateY(0);
            }
        }

        @keyframes slideInDown {
            0% {
                -webkit-transform: translateY(-100%);
                transform: translateY(-100%);
                visibility: visible;
            }

            100% {
                -webkit-transform: translateY(0);
                transform: translateY(0);
            }
        }

        .slideInDown {
            -webkit-animation-name: slideInDown;
            animation-name: slideInDown;
        }

        @-webkit-keyframes slideInLeft {
            0% {
                -webkit-transform: translateX(-100%);
                transform: translateX(-100%);
                visibility: visible;
            }

            100% {
                -webkit-transform: translateX(0);
                transform: translateX(0);
            }
        }

        @keyframes slideInLeft {
            0% {
                -webkit-transform: translateX(-100%);
                transform: translateX(-100%);
                visibility: visible;
            }

            100% {
                -webkit-transform: translateX(0);
                transform: translateX(0);
            }
        }

        .slideInLeft {
            -webkit-animation-name: slideInLeft;
            animation-name: slideInLeft;
        }

        @-webkit-keyframes slideInRight {
            0% {
                -webkit-transform: translateX(100%);
                transform: translateX(100%);
                visibility: visible;
            }

            100% {
                -webkit-transform: translateX(0);
                transform: translateX(0);
            }
        }

        @keyframes slideInRight {
            0% {
                -webkit-transform: translateX(100%);
                transform: translateX(100%);
                visibility: visible;
            }

            100% {
                -webkit-transform: translateX(0);
                transform: translateX(0);
            }
        }

        .slideInRight {
            -webkit-animation-name: slideInRight;
            animation-name: slideInRight;
        }

        @-webkit-keyframes slideInUp {
            0% {
                -webkit-transform: translateY(100%);
                transform: translateY(100%);
                visibility: visible;
            }

            100% {
                -webkit-transform: translateY(0);
                transform: translateY(0);
            }
        }

        @keyframes slideInUp {
            0% {
                -webkit-transform: translateY(100%);
                transform: translateY(100%);
                visibility: visible;
            }

            100% {
                -webkit-transform: translateY(0);
                transform: translateY(0);
            }
        }

        .slideInUp {
            -webkit-animation-name: slideInUp;
            animation-name: slideInUp;
        }

        @-webkit-keyframes slideOutDown {
            0% {
                -webkit-transform: translateY(0);
                transform: translateY(0);
            }

            100% {
                visibility: hidden;
                -webkit-transform: translateY(100%);
                transform: translateY(100%);
            }
        }

        @keyframes slideOutDown {
            0% {
                -webkit-transform: translateY(0);
                transform: translateY(0);
            }

            100% {
                visibility: hidden;
                -webkit-transform: translateY(100%);
                transform: translateY(100%);
            }
        }

        .slideOutDown {
            -webkit-animation-name: slideOutDown;
            animation-name: slideOutDown;
        }

        @-webkit-keyframes slideOutLeft {
            0% {
                -webkit-transform: translateX(0);
                transform: translateX(0);
            }

            100% {
                visibility: hidden;
                -webkit-transform: translateX(-100%);
                transform: translateX(-100%);
            }
        }

        @keyframes slideOutLeft {
            0% {
                -webkit-transform: translateX(0);
                transform: translateX(0);
            }

            100% {
                visibility: hidden;
                -webkit-transform: translateX(-100%);
                transform: translateX(-100%);
            }
        }

        .slideOutLeft {
            -webkit-animation-name: slideOutLeft;
            animation-name: slideOutLeft;
        }

        @-webkit-keyframes slideOutRight {
            0% {
                -webkit-transform: translateX(0);
                transform: translateX(0);
            }

            100% {
                visibility: hidden;
                -webkit-transform: translateX(100%);
                transform: translateX(100%);
            }
        }

        @keyframes slideOutRight {
            0% {
                -webkit-transform: translateX(0);
                transform: translateX(0);
            }

            100% {
                visibility: hidden;
                -webkit-transform: translateX(100%);
                transform: translateX(100%);
            }
        }

        .slideOutRight {
            -webkit-animation-name: slideOutRight;
            animation-name: slideOutRight;
        }

        @-webkit-keyframes slideOutUp {
            0% {
                -webkit-transform: translateY(0);
                transform: translateY(0);
            }

            100% {
                visibility: hidden;
                -webkit-transform: translateY(-100%);
                transform: translateY(-100%);
            }
        }

        @keyframes slideOutUp {
            0% {
                -webkit-transform: translateY(0);
                transform: translateY(0);
            }

            100% {
                visibility: hidden;
                -webkit-transform: translateY(-100%);
                transform: translateY(-100%);
            }
        }

        .slideOutUp {
            -webkit-animation-name: slideOutUp;
            animation-name: slideOutUp;
        }

        .mentions-input-box {
            position: relative;
            background: #fff;
        }

        .mentions-input-parsetext-btn {
            position: absolute;
            right: 5px;
            top: 5px;
            opacity: 0.5;
        }

        .mentions-input-box div {
            clear: both;
        }

        .mentions-input-box textarea {
            width: 100%;
            clear: both;
            display: block;
            height: 18px;
            padding: 4px 6px;
            border: 1px solid #ccc;
            border-radius: 0;
            overflow: hidden;
            background: transparent;
            position: relative;
            outline: 0;
            resize: none;
            font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            box-sizing: border-box;
        }

        .mentions-input-box .mentions-autocomplete-list {
            display: none;
            background: #fff;
            border: 1px solid #b2b2b2;
            position: absolute;
            left: 0;
            right: 0;
            z-index: 10000;
            margin-top: -2px;
            border-radius: 5px;
            border-top-right-radius: 0;
            border-top-left-radius: 0;
            -webkit-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.148438);
            -moz-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.148438);
            box-shadow: 0 2px 5px rgba(0, 0, 0, 0.148438);
        }

        .mentions-input-box .mentions-autocomplete-list .mentions-autocomplete-loading {
            display: none;
        }

        .mentions-input-box .mentions-autocomplete-list ul {
            margin: 0;
            padding: 0;
        }

        .mentions-input-box .mentions-autocomplete-list li {
            background-color: #fff;
            padding: 0 5px;
            margin: 0;
            width: auto;
            border-bottom: 1px solid #eee;
            height: 30px;
            line-height: 26px;
            overflow: hidden;
            cursor: pointer;
            list-style: none;
            white-space: nowrap;
            clear: both;
            font-size: 13px;
        }

        .mentions-input-box .mentions-autocomplete-list li:last-child {
            border-radius: 5px;
        }

        .mentions-input-box .mentions-autocomplete-list li > img,
        .mentions-input-box .mentions-autocomplete-list li > div.icon {
            width: 27px;
            height: 27px;
            margin-right: 5px;
            -moz-background-origin: 3px;
            border-radius: 3px;
        }

        .mentions-input-box .mentions-autocomplete-list li em {
            font-weight: bold;
            font-style: none;
        }

        .mentions-input-box .mentions-autocomplete-list li:hover,
        .mentions-input-box .mentions-autocomplete-list li.active {
            background-color: #f2f2f2;
        }

        .mentions-input-box .mentions-autocomplete-list li.selected {
            font-weight: bold;
        }

        .mentions-input-box .mentions-autocomplete-list li b {
            background: #ff9;
            font-weight: normal;
        }

        .mentions-input-box .mentions {
            position: absolute;
            left: 1px;
            right: 0;
            top: 2px;
            bottom: 0;
            padding: 4px 6px;
            color: #fff;
            overflow: hidden;
            line-height: 20px;
            white-space: pre-wrap;
            word-wrap: break-word;
            font-size: 13px;
            font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
        }

        .mentions-input-box .mentions > div {
            color: #fff;
            white-space: pre-wrap;
            width: 100%;
        }

        .mentions-input-box .mentions > div > strong {
            font-weight: normal;
            background: #daeffa;
        }

        .mentions-input-box .mentions > div > strong.foundmany {
            font-weight: normal;
            background: #ffd0d0;
        }

        .mentions-input-box .mentions > div > strong > span {
            filter: progid:DXImageTransform.Microsoft.Alpha(opacity=0);
        }

        body {
            font-family: Geneva, Arial, Helvetica, sans-serif;
            font-size: 12px;
            color: #000;
        }

        a[href]:after {
            content: "";
        }

        .navbar-fixed-top {
            position: relative;
        }

        .brand > img {
            max-height: 60px;
        }

        .post-items-type {
            line-height: 30px;
            margin: 0;
            display: inline-block;
            vertical-align: middle;
        }

        .event-post-outlookBlock {
            padding-top: 10px;
            padding-left: 60px;
        }

        .pryanikyPostType .post-goal-desc p {
            font-size: 12px;
            line-height: 18px;
        }

        .post-items-type.dropdown {
            height: auto;
        }

        .bold {
            font-weight: bold;
        }

        .post-items-type > li > a {
            color: #000;
        }

        #postElem {
            padding-bottom: 0;
            padding-top: 0;
        }

        #postElem .accordion {
            background: none;
            box-shadow: none;
        }

        .post-items-type > li {
            margin-right: 8px;
            float: left;
            height: 25px;
            line-height: 25px;
        }

        .rait-name {
            width: 160px;
            padding: 0;
            margin: 0;
            white-space: nowrap;
            height: 10px;
            clear: none;
            font-size: 14px;
            margin-bottom: 8px;
            text-decoration: underline;
        }

        .entry_header {
            font-size: 12px;
            color: #797979;
            width: 105px;
            margin-right: 10px;
            padding-left: 10px;
            height: 20px;
            float: left;
            clear: left;
        }

        .user_search_entry > a,
        .group_search_entry > a,
        .tag_search_entry > a {
            color: #000;
            text-decoration: none;
            font-size: 14px;
        }

        .marg-like {
            margin-left: 65px;
        }

        .like-date {
            width: 460px;
        }

        .postst {
            width: 460px;
            margin: 15px 0 0 0 !important;
        }

        .icon-icaldownload {
            width: 16px;
            height: 16px;
        }

        .icon-down {
            background-position: -285px -192px;
        }

        .icon-postat {
            width: 20px;
            height: 20px;
            margin-right: 17px;
            background-position: -312px -190px !important;
        }

        .icon-postsharp {
            width: 16px;
            height: 16px;
            margin-right: 17px;
            background-position: -332px -190px !important;
        }

        .icon-postattach {
            width: 18px;
            height: 25px;
            background-position: -350px -190px;
        }

        .filecont {
            width: 18px;
            height: 18px;
            margin-bottom: -5px;
            float: left;
        }

        .icon-questcheck {
            background-position: -380px -195px;
        }

        .icon-search2 {
            background-position: -49px -33px;
        }

        .icon-givecake {
            background-position: -265px -192px;
        }

        .icon-like {
            background-position: -227px -193px;
        }

        .icon-badge {
            background-position: -206px -193px;
        }

        .icon-newsss {
            background-position: -247px -193px;
        }

        .icon-homeum {
            background-position: -2px -193px;
        }

        .icon-poll {
            background-position: -192px -168px;
        }

        .icon-prnmessage {
            background-position: -26px -193px;
        }

        .icon-partner {
            background-position: -49px -192px;
        }

        .icon-page {
            background-position: -186px -192px;
        }

        .icon-lavkad {
            background-position: -140px -192px;
        }

        .icon-competition {
            background-position: -162px -194px;
        }

        .icon-idea {
            background-position: -162px -194px;
        }

        .icon-notice {
            background-position: -48px -144px;
        }

        .icon-interview {
            background-position: -116px -192px;
        }

        .icon-invite {
            background-position: -94px -192px;
        }

        .icon-goupcomment {
            background-position: -72px -192px;
        }

        .icon-allgroups {
            background-position: -72px -192px;
        }

        .icon-compet {
            background-position: -116px -192px;
        }

        .icon-myorders {
            background-position: -116px -192px;
        }

        .icon-compsett {
        }

        .icon-groupadd {
            background-position: -409px -192px;
        }

        .icon-reward {
            background-position: -216px -168px;
        }

        input {
            -webkit-user-select: auto;
        }

        input[type="text"],
        input[type="numeric"]:focus {
            outline: none;
            box-shadow: none !important;
        }

        .selected {
            margin: 0 !important;
            border-radius: 6px;
            border: 2px solid #fff;
            color: #008ece;
        }

        .post-items-content {
            margin-top: 5px;
            margin-bottom: 9px;
        }

        .tabs {
            margin: 2px;
            padding: 0 5px;
        }

        .tabs:hover {
            border-radius: 6px;
            border: 2px solid #fff;
            margin: 0;
        }

        .spanleft {
            margin: 0;
            width: 164px;
        }

        .leftmenu {
            width: 164px;
            padding: 8px 0;
        }

        .podrgruzhajemij_poisk {
            top: 0 !important;
        }

        .leftnav {
            padding-left: 0 !important;
        }

        .leftnav > .active > a {
            text-shadow: none;
            background: none;
            font-weight: bold;
            color: #086bb8;
        }

        .leftnav > .active > a:hover {
            text-shadow: none;
            background: #dee7ef;
            font-weight: bold;
            color: #086bb8;
        }

        .leftnav > li {
            overflow: hidden;
            border-radius: 5px;
            width: 164px !important;
            line-height: 25px !important;
            height: 25px !important;
            padding-left: 6px !important;
        }

        .leftnav > li > a {
            color: #313431;
            margin-left: -11px;
            line-height: 25px !important;
            height: 25px !important;
            padding-top: 0;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .leftnav > li > a > i {
            opacity: 0.5;
        }

        .usersInDialog tr {
            line-height: 60px;
            overflow: hidden;
            border: none;
            width: 100%;
            cursor: pointer;
            -webkit-touch-callout: none;
            -webkit-user-select: none;
            -khtml-user-select: none;
            -moz-user-select: moz-none;
            -ms-user-select: none;
            user-select: none;
        }

        .usersInDialog td {
            padding: 5px 0 5px 0;
        }

        .usersInDialog td:first-child {
            vertical-align: middle;
        }

        .usersInDialog td:last-child {
            overflow: hidden;
        }

        .usersInDialog td > input {
            margin: 0;
        }

        .usersInDialog span {
            padding: 5px;
        }

        input.dlgfullsize,
        select.dlgfullsize,
        textarea.dlgfullsize {
            width: 91%;
            width: -moz-calc(100% - 14px);
            width: -webkit-calc(100% - 14px);
            width: calc(100% - 14px);
        }

        input.pagefullsize,
        select.pagefullsize,
        textarea.pagefullsize {
            width: 97%;
            width: -moz-calc(100% - 10px);
            width: -webkit-calc(100% - 10px);
            width: calc(100% - 10px);
        }

        .searchinput input[type="text"] {
            padding: 2px !important;
            height: 18px !important;
            border-right: none !important;
            -moz-border-radius: 4px 0 0 4px !important;
            -webkit-border-radius: 4px 0 0 4px !important;
            border-radius: 4px 0 0 4px !important;
        }

        .searchinput.inmenu {
            padding: 15px 20px 5px 10px;
        }

        .btn-toolbar {
            margin-bottom: 0 !important;
        }

        .append-cust.searchtop {
            margin-top: 3px !important;
            background-color: #fff;
            -webkit-padding-end: 0;
        }

        .append-cust.searchtop button {
            -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
            -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
            box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
        }

        #searchtxt {
            padding: 2px;
            height: 18px;
            border-right: none;
            width: 410px;
            -webkit-border-radius: 4px 0 0 4px !important;
            -moz-border-radius: 4px 0 0 4px !important;
            border-radius: 4px 0 0 4px !important;
        }

        .tabsdiv {
            display: none;
        }

        .tabsdiv p {
            padding: 5px;
        }

        .position-butt {
            position: relative;
            bottom: 0;
            width: 320px;
            margin: 0;
        }

        .tabsdiv .kolichestvo_pryan {
            padding: 0 0 0 5px;
            width: 185px;
        }

        .first.tabsdiv {
            display: block;
        }

        .userlist2 {
            width: 480px;
        }

        .visibilitymenu {
            padding: 3px;
            clear: none;
        }

        #preload {
            display: none;
        }

        #TellThanks {
            display: none;
        }

        .right_lk {
            width: 254px;
            height: auto;
            margin: 0;
        }

        .right_lk h3,
        .widgetpane h3 {
            font-weight: bold;
            font-size: 14px;
            height: auto;
        }

        .right-well {
            position: relative;
            background-color: #fef9e4;
            -webkit-border-radius: 0;
            -moz-border-radius: 0;
            border-radius: 0;
            float: none;
        }

        .white {
            background-color: #fff;
            border: none;
            line-height: 16px;
        }

        .well-loadmore {
            padding: 5px 0;
        }

        .enable_free_answer {
            overflow: hidden;
            width: 300px;
            height: 20px;
            margin-top: 10px;
        }

        .add_answer_interview {
            overflow: hidden;
            width: 300px;
            height: 20px;
            float: left;
        }

        .add_answer_interview a {
            cursor: pointer;
            text-decoration: underline;
        }

        .prnsugestoritem {
            height: 42px;
            overflow: hidden;
            cursor: pointer;
        }

        .prnsugestoritem img {
            width: 32px;
            height: 32px;
            float: left;
            padding: 5px;
        }

        .prnsugestoritem span {
            display: inline;
            font-size: 12px;
            vertical-align: middle;
            padding: 0 0 0 0;
        }

        .prnsugestoritem span:last-child {
            font-weight: bold;
        }

        .pryaniky-reason-selector {
            overflow: hidden;
        }

        .pryaniky-reason-selector .selectThanksCat {
            margin: 0;
            padding: 0 5px 0 0;
            font-size: 14px;
            line-height: 20px;
            display: inline-block;
        }

        .pryaniky-reason-selector .selectThanksCat.active {
            color: #000;
            font-weight: bold;
        }

        .cont-badg {
            width: 80px;
            height: 80px;
        }

        .cont-badge-text {
            width: 395px;
        }

        #selectBadge {
            float: left;
        }

        .groupsel {
            padding-left: 14px;
            background-position: 0 4px;
            margin-left: 8px;
        }

        .content {
            padding: 0;
            // margin: 0 16px 0 16px;
        }

        .span530 {
            width: 530px;
            overflow: visible !important;
        }

        .mainnews {
        }

        .commentProgress,
        .newsProgress {
            display: none;
            position: absolute;
            margin: 2px 0 0 338px;
        }

        .form_kolichestv_pryan {
            height: auto;
            width: 350px;
            border: 3px solid #dee2e4;
            background: #eee;
            position: absolute;
            display: none;
            margin: -163px 0 0 33px !important;
        }

        .form_kolichestv_pryan p {
            font-size: 14px;
            color: #000;
            width: 120px;
            float: right;
            margin: 15px 5px 15px 0;
        }

        .form_kolichestv_pryan .close {
            position: relative;
            float: right;
            margin: 5px 10px;
            background: #fff;
            width: 12px;
            height: 12px;
        }

        .form_kolichestv_pryan .close img {
            margin-left: 2px;
        }

        .pryanikPanel {
            display: none;
            position: absolute;
            z-index: 1001;
            margin-top: 5px;
        }

        .newslist {
            float: none;
            clear: left;
            margin-top: 20px !important;
            width: 530px;
        }

        .n-i-wikipage {
            border: none;
        }

        .n-i-wikipage .postfooter {
            padding-left: 10px;
        }

        .newsitem {
            float: none;
            clear: left;
            padding-top: 20px;
            background: #fff;
            margin-bottom: 20px;
            border-top: 1px solid #dee7ef;
        }

        .newsitem .accordion {
            background: none;
            box-shadow: none;
        }

        .newslist .newsitem {
            border-top: 1px solid #dee7ef;
            border: 1px solid #d3d6db;
            background: #fff;
            margin-bottom: 20px;
            -moz-box-shadow: 1px 5px 8px #c2c2c2;
            -webkit-box-shadow: 1px 5px 8px #c2c2c2;
            box-shadow: 1px 5px 8px #c2c2c2;
        }

        .more_news {
            padding: 5px 20px;
            width: 114px;
            height: 29px;
            margin: 35px auto;
            clear: both;
        }

        .realtext,
        .realtext p {
            line-height: 18px;
            margin-top: 10px;
            font-size: 14px;
        }

        .realtext ol,
        .realtext ul {
            list-style-position: inside;
        }

        .formhint {
            min-width: 250px;
            min-height: 1px;
            margin-bottom: 5px;
        }

        .no-space {
            margin-left: 0;
            margin-top: 0;
            overflow: hidden;
        }

        .no-space-left {
            margin-left: 0;
        }

        .creativetaskwinnertext {
            font-size: 14px;
            min-width: 250px;
            font-family: Segoe UI, serif;
            margin-left: 80px;
        }

        .replytext {
            line-height: 18px;
            margin-bottom: 5px;
            font-size: 14px;
        }

        .namel {
            font-weight: bold;
            font-size: 14px;
        }

        .middelnamel {
            margin-left: 5px;
        }

        .reply-list .commentBox {
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            box-sizing: border-box;
            text-align: left;
            clear: right;
        }

        .reply-list {
            margin: 0 0 5px 60px;
        }

        .reply-list > ul {
            list-style: none;
        }

        .reply-list > ul > li {
            border-top: 1px solid #dee7ef;
            clear: both;
            margin: 10px 0 0 0;
            padding-top: 10px;
        }

        .reply-list > ul > li .reply {
            padding: 0 0 0 10px;
        }

        .reply-list > ul > li .reply > span {
            font-weight: bold;
            padding-bottom: 2px;
        }

        .reply-list.replyto {
            padding: 10px 15px 5px 0;
        }

        .reply-list.replyto div.replypostimage {
            margin: 0 10px 0 0;
            width: 32px;
            height: 32px;
            padding: 0;
            float: left;
        }

        .reply-list.replyto div.replypostcomment {
            width: 85%;
            width: -moz-calc(100% - 44px);
            width: -webkit-calc(100% - 44px);
            width: calc(100% - 44px);
            margin-bottom: 5px;
            float: left;
        }

        .reply-list.replyto div.replypostcomment textarea {
            width: 100%;
            min-height: 30px;
        }

        .replyto p.sendMessageControls {
            display: none;
            margin-top: -3px;
            font-size: 12px;
        }

        .replyto p.sendMessageProgress {
            display: none;
            margin-top: -13px;
            font-size: 12px;
            color: #ccc;
        }

        .postfooter {
            color: #6d6d6d;
            font-size: 12px;
            margin-top: 0;
            clear: both;
        }

        .votetext {
            width: -webkit-calc(100% - 100px);
            width: calc(100% - 100px);
        }

        .voteprogress {
            width: 80%;
            width: -moz-calc(100% - 100px);
            width: -webkit-calc(100% - 100px);
            width: calc(100% - 100px);
            height: 5px;
            position: relative;
            border: none;
            margin-top: 5px;
        }

        .progressupload {
            width: 91%;
            width: -moz-calc(100% - 20px);
            width: -webkit-calc(100% - 20px);
            width: calc(100% - 20px);
            display: inline-block;
            margin-right: 5px;
            float: left;
            height: 5px;
        }

        .main-width {
            width: 980px;
            margin-top: 4px;
            overflow: hidden;
        }

        .left-filt {
            margin-left: 400px;
            position: absolute;
        }

        .right-group-select {
            right: 0;
            position: absolute;
            padding: 11px 0 0 11px;
            background-color: #fff;
            z-index: 1;
        }

        .news_nav {
            position: relative;
            overflow: visible;
        }

        .postNewsButton {
            font-weight: bold;
            margin: 0 0 10px 20px;
        }

        .pull-right > .postNewsButton {
            float: right;
        }

        .select2-choices {
            border: 1px solid #ccc !important;
        }

        .rait-well {
            background: none;
            border: none;
            -webkit-box-shadow: none;
            box-shadow: none;
            -moz-box-shadow: none;
        }

        .my-bdg {
            text-align: center;
            background: #efebd6;
            border: none;
            border-radius: 6px;
            line-height: 20px;
        }

        .right-well .badge-prn {
            color: #3686c1;
            background: none;
            font-size: 14px;
            font-weight: bold;
        }

        .right-well .badge-prn:hover {
            background: #fff;
        }

        .zakazat {
            width: 200px;
            overflow: hidden;
        }

        .cont-hed-top {
            height: 74px;
        }

        .cont-hed-top a {
            font-size: 30px !important;
            color: #000 !important;
            font-weight: bold !important;
            display: inline-block;
            width: 550px;
        }

        .cont-hed-top a span,
        .cont-hed-top a img {
            display: inline-block;
        }

        .cont-hed-bot {
            border-radius: 5px 5px 0 0;
            height: 30px;
            margin-top: 6px;
        }

        .bnt-cust {
            background: none !important;
            padding: 1px !important;
            margin: 0 !important;
            border-left: none !important;
        }

        .append-cust {
            margin: -2px 0 0 0 !important;
        }

        .head-li-menu {
            color: #b5b2b5;
            margin-top: 10px;
        }

        .head-li-menu a {
            color: #b5b2b5 !important;
        }

        .Pryaniky_friendsRait {
            padding: 20px;
            color: #696969;
        }

        .Pryaniky_friendsRait h3 {
            font-size: 20px;
        }

        .content_prof .avatar_large {
            width: 198px;
            overflow: hidden;
            margin: 10px 10px 5px 10px;
        }

        .form_prof_dannie {
            width: auto;
            margin: 0 0 20px 15px;
            height: auto;
            min-height: 125px;
            position: relative;
        }

        .form_prof_dannie h1 {
            font-family: Arial;
            font-size: 18px;
            font-weight: bold;
            color: #000;
            margin-bottom: 5px;
        }

        .form_prof_dannie p,
        .form_prof_dannie .p {
            font-family: Arial;
            font-size: 14px;
            line-height: 22px;
            color: #000;
            max-width: 540px;
        }

        .form_prof_dannie > span {
            color: #ccc;
        }

        .form_prof_dannie2 {
            width: auto;
            margin: 0 0 10px 15px;
            height: auto;
            position: relative;
        }

        .form_prof_danni2e h1 {
            font-family: Arial;
            font-size: 18px;
            font-weight: bold;
            color: #000;
            margin-bottom: 5px;
        }

        .form_prof_dannie2 * {
            font-family: Arial;
            font-size: 14px;
            line-height: 22px;
            max-width: 540px;
        }

        .well li {
            list-style-position: inside;
        }

        .form_prof_dannie2 > span {
            color: #ccc;
        }

        .top_part_prof {
            width: 759px;
            height: auto;
            position: relative;
        }

        .userpanel {
            height: 60px;
            padding: 7px;
        }

        .userpanel span {
            line-height: 18px;
        }

        .userpanel:hover {
            background-color: #fef9e4;
        }

        .top_part_prof .profile-actions {
            position: absolute;
            bottom: 11px;
            width: 100%;
            float: left;
            clear: both;
            margin-left: 216px;
        }

        .form_prof_dannie .profile-actions a {
            margin-right: 25px;
        }

        .feedback_prof {
            font-weight: bold;
            width: 250px;
            height: 96px;
            background: #eaebd7;
            float: left;
            clear: left;
            margin-top: 15px;
            margin-left: 0;
        }

        .feedback_prof .row {
            margin: 0;
        }

        .likeprof {
            color: #60a400;
            font-size: 20px;
        }

        .dislikeprof {
            color: #a90000;
            font-size: 14px;
        }

        .dostupno {
            width: 340px;
            margin-bottom: 0;
        }

        .pryaniky-reason-selector {
            clear: both;
            border-top: 1px solid #e5e5e5;
            padding-top: 10px;
            margin-bottom: 20px;
        }

        .count-inp {
            width: 30px;
        }

        .check-cont {
            margin-top: 10px;
        }

        .dostupno_kol {
            width: 190px;
            margin-top: 0;
            font-size: 14px;
            text-align: right;
            margin-right: 20px;
        }

        .answerBox > input {
            width: 91%;
            width: -moz-calc(100% - 35px);
            width: -webkit-calc(100% - 35px);
            width: calc(100% - 35px);
        }

        .answerBox > button {
            margin-top: 4px;
        }

        .answerBox:last-of-type > input {
            background-color: #eee;
            margin-bottom: 0;
            cursor: pointer;
        }

        .bar {
            padding: 3px 0 0 5px;
            text-align: left !important;
        }

        xscript {
            display: none;
        }

        .badgeminput {
            margin-top: 10px;
            width: 85%;
            width: -moz-calc(100% - 72px);
            width: -webkit-calc(100% - 72px);
            width: calc(100% - 72px);
            clear: right;
        }

        .noTransition {
            -moz-transition: none !important;
            -webkit-transition: none !important;
            -o-transition: none !important;
            transition: none !important;
        }

        .uploadblock {
            margin: 20px;
        }

        .qq-uploader {
            position: relative;
            width: 100%;
        }

        .qq-upload-button {
            display: table-cell;
        }

        .qq-upload-button-hover {
        }

        .qq-upload-button-focus {
            outline: 1px dotted #000;
        }

        .qq-upload-drop-area {
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            min-height: 70px;
            z-index: 2;
            text-align: center;
        }

        .qq-upload-drop-area span {
            display: block;
            top: 50%;
            width: 100%;
            margin-top: -8px;
            font-size: 16px;
        }

        .qq-upload-drop-area-active {
        }

        .qq-upload-list {
            margin: 15px 35px;
            padding: 0;
            list-style: disc;
            display: none;
        }

        .qq-upload-list li {
            margin: 0;
            padding: 0;
            line-height: 15px;
            font-size: 12px;
        }

        .qq-upload-file,
        .qq-upload-spinner,
        .qq-upload-size,
        .qq-upload-cancel,
        .qq-upload-failed-text {
            margin-right: 7px;
        }

        .qq-upload-file {
        }

        .qq-upload-spinner {
            display: block;
            width: 15px;
            height: 15px;
        }

        .qq-upload-size,
        .qq-upload-cancel {
            font-size: 11px;
        }

        .qq-upload-failed-text {
            display: none;
        }

        .qq-upload-fail .qq-upload-failed-text {
            display: inline;
        }

        .accordion-caret .accordion-toggle:hover {
            text-decoration: none;
        }

        .accordion-caret .accordion-toggle:hover span {
            text-decoration: underline;
        }

        .accordion-caret .accordion-toggle:before {
            font-size: 16px;
            margin-right: 5px;
            vertical-align: -1px;
        }

        .accordion-caret .accordion-toggle:not(.collapsed):before {
            display: inline-block;
            width: 0;
            height: 0;
            vertical-align: middle;
            border-top: 4px solid #000;
            border-right: 4px solid transparent;
            border-left: 4px solid transparent;
            content: "";
            margin-left: 0;
        }

        .accordion-caret .accordion-toggle.collapsed:before {
            display: inline-block;
            width: 0;
            height: 0;
            vertical-align: middle;
            border-top: 4px solid transparent;
            border-left: 4px solid #000;
            border-bottom: 4px solid transparent;
            content: "";
            margin-left: 5px;
        }

        .table {
            font-size: 13px;
        }

        .left-submenu {
            margin-left: 15px;
        }

        .datepicker {
            z-index: 1051;
        }

        .editable-displayName,
        .editable-socialConnect,
        .editable-phoneInput {
            display: block;
            margin-bottom: 5px;
        }

        .editable-socialConnect a {
            display: inline-block;
            margin: 3px;
        }

        .editable-displayName span {
            width: 70px;
            display: inline-block;
        }

        .editable-phoneInput span {
            width: 100px;
            display: inline-block;
        }

        .editable-buttons button {
            margin-top: 0;
        }

        .edituserprofile div {
            font-size: 14px;
            line-height: 20px;
        }

        .edituserprofile label span,
        .edituserprofile div span {
            width: 150px;
            display: inline-block;
        }

        .firstlogon {
            margin-top: 30px;
        }

        .firstlogon label {
            width: 150px;
            display: inline-block;
        }

        .menubadge {
            display: none;
        }

        .report_error {
            color: red;
            font-size: 16px;
            font-weight: bold;
            float: left;
            clear: left;
            width: 100%;
            text-align: center;
            margin-top: 50px;
        }

        .report_choice {
            padding: 10px;
            margin-top: 10px;
            width: 100%;
            height: 70px;
            background-color: #eee;
            clear: left;
            float: left;
            overflow-x: hidden;
            overflow-y: hidden;
            border-radius: 6px;
        }

        .report_choice:hover {
            cursor: pointer;
        }

        .reports_choice_container {
            margin-bottom: 10px;
            float: left;
        }

        .report_additional_variables {
            float: left;
            margin-left: 10px;
            width: 50%;
            margin-top: -5px;
        }

        .report_date_header {
            float: left;
            width: 100%;
            margin-bottom: 5px;
        }

        #reportbegindate {
            float: left;
            width: 85px;
            text-align: center;
        }

        #reportenddate {
            float: left;
            margin-left: 10px;
            width: 85px;
            text-align: center;
        }

        #createreport {
            margin-right: 10px;
            width: 180px;
        }

        .createreport_b {
        }

        .createreport_g {
        }

        .report_iframe_container {
            float: left;
            clear: left;
        }

        .selected_report {
            color: green;
        }

        .report_page_left {
            float: left;
            margin-top: 20px;
            width: 60%;
        }

        .report_name {
            font-size: 14px;
            float: left;
        }

        .report_description {
            width: 36%;
            margin-left: 10px;
            height: auto;
            font-size: 12px;
            float: right;
            clear: right;
            overflow-x: hidden;
            overflow-y: hidden;
            margin-top: 20px;
            margin-bottom: 10px;
            padding: 10px;
            background: #fff;
            line-height: 18px;
        }

        .report_description ul {
            list-style-type: disc;
            list-style-position: inside;
            margin: 10px;
        }

        .report_page_right {
            float: left;
            margin-left: 30px;
            margin-top: 20px;
            font-size: 14px;
            width: 68%;
        }

        .report_page_header {
            width: 100%;
            float: left;
            font-size: 16px;
            clear: left;
            font-weight: bold;
        }

        .report_choice:hover {
            background-color: #ffd24a;
        }

        .report_choice_header {
            width: 100%;
            padding-left: 10px;
            float: left;
            font-size: 14px;
            font-weight: bold;
        }

        .report_time {
            margin-left: 10px;
            float: left;
        }

        .report_time_checked {
            text-decoration: none !important;
            color: #000;
            border-style: dotted;
            font-weight: bold;
            cursor: default;
        }

        .report_time_header {
            float: left;
            clear: left;
            font-weight: bold;
        }

        .report_additional_variables {
            float: left;
            margin-left: 10px;
        }

        #report_iframe_container {
            width: 100%;
            height: 980px;
            overflow-x: hidden;
            overflow-y: hidden;
        }

        #reportFrame {
            width: 100%;
            height: 980px;
            overflow-x: hidden;
            overflow-y: hidden;
        }

        .report_extra_variables_news {
            display: none;
        }

        .report_extra_variables_tablecreativeusers {
            display: none;
        }

        .report_extra_variables_tablegeneroususers {
            display: none;
        }

        .report_extra_variables_tablepopularusers {
            display: none;
        }

        .report_extra_variables_actions {
            min-width: 300px;
            min-height: 30px;
            overflow: hidden;
            display: none;
        }

        .report_extra_variables_show {
            display: initial;
        }

        .report_extra_variables_header {
            float: left;
            font-weight: bold;
            margin: 10px;
            clear: left;
        }

        #report_newstype {
            float: right;
            height: 20px;
            margin-top: 8px;
        }

        #report_tablecreativeusers {
            float: right;
            height: 20px;
            margin-top: 8px;
        }

        #report_tablegeneroususers {
            float: right;
            height: 20px;
            margin-top: 8px;
        }

        #report_tablepopularusers {
            float: right;
            height: 20px;
            margin-top: 8px;
        }

        .meanValueContainer {
            float: left;
            margin-top: 8px;
        }

        .current_page_href {
            font-weight: bold;
        }

        [class*="report_extra"] [title*="Если"] {
            font-size: 12px;
        }

        [class*="report_extra"] {
            clear: both;
        }

        [class*="report_extra"] select {
            float: right;
        }

        .aloha-icon-withimage {
            width: 54px;
            height: 48px;
            margin: 0;
            padding: 0;
        }

        .left-menu {
            padding-bottom: 10px;
        }

        .sidebar-nav .left-menu:first-child {
            padding-top: 10px;
            margin-bottom: 29px;
        }

        .wellgray {
            position: relative;
        }

        .well.wellgray ul.redactor_toolbar {
            background-color: #f5f5f5 !important;
        }

        .img-wrap {
            text-align: center;
            height: 258px;
            overflow: hidden;
            position: relative;
            display: inline-block;
            width: 250px;
            margin: 3px;
            border: 1px solid #eee;
            float: left;
        }

        .img-overlay {
            text-align: left;
            background-color: #000;
            bottom: 0;
            color: #fff;
            opacity: 0;
            filter: alpha(opacity=0);
            position: absolute;
            width: 100%;
            z-index: 1000;
        }

        .img-overlay h5,
        .img-overlay div {
            padding: 0 5px;
        }

        .img-wrap:hover .img-overlay {
            opacity: 0.75;
            filter: alpha(opacity=75);
            transition: opacity 0.25s;
            -moz-transition: opacity 0.25s;
            -webkit-transition: opacity 0.25s;
        }

        #pryaniky-top-link {
            display: none;
            position: fixed;
            left: 5px;
            padding-bottom: 0 !important;
            bottom: 5px;
            font-weight: bold;
            text-decoration: none;
        }

        #pryaniky-tiles {
            list-style-type: none;
            position: relative;
            margin: 0;
            padding: 0;
        }

        #pryaniky-tiles li {
            width: 200px;
            background-color: #fff;
            border: 1px solid #dedede;
            border-radius: 2px;
            -moz-border-radius: 2px;
            -webkit-border-radius: 2px;
            display: inline-block;
            cursor: pointer;
            padding: 4px;
        }

        #pryaniky-tiles li.inactive {
            visibility: hidden;
            opacity: 0;
        }

        #pryaniky-tiles li img {
            display: block;
        }

        #pryaniky-tiles li p {
            color: #666;
            font-size: 12px;
            margin: 7px 0 0 7px;
        }

        .wookmark-placeholder {
            border-radius: 2px;
            -moz-border-radius: 2px;
            -webkit-border-radius: 2px;
            background-color: #eee;
            border: 1px solid #dedede;
            z-index: -1;
        }



        .highlight {
            background-color: #9ae096;
            border-radius: 50px;
        }

        .peditableblock {
            padding-top: 0;
            padding-bottom: 0;
            margin-top: 0;
            margin-bottom: 0;
            min-height: 0;
        }

        .peditableblock p {
            font-size: 1em;
            line-height: 20px;
            min-height: 20px;
            padding-bottom: 7px;
        }

        table.noborder,
        table.noborder tr,
        table.noborder tr td,
        table.noborder tbody {
            border: 0;
        }

        .prnuinput {
            position: absolute;
            top: 5px;
            right: 5px;
        }

        .sider-right {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            width: 70px;
            background-color: #fefefe;
            background-color: rgba(255, 255, 255, 0.8);
            box-shadow: 0 0 3px rgba(0, 0, 0, 0.4);
        }

        .sider-top {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            background-color: #fefefe;
            background-color: rgba(255, 255, 255, 0.7);
            box-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
            padding: 5px;
            text-align: center;
            font-size: 14px;
            color: #333;
            text-shadow: 1px 1px 0 #fff;
        }

        .sider-top a {
            display: inline-block !important;
            height: 48px;
            width: 50px;
            margin-right: 13px;
            background-position: center center;
            background-repeat: no-repeat;
        }

        .sider-right a {
            display: block;
            height: 50px;
            margin-top: 13px;
            background-position: center center;
            background-repeat: no-repeat;
        }



        .prnpreviewprocessing {
            position: relative;
        }

        .editable table {
            width: 100%;
            border-top: 1px solid #e5eff8;
            border-right: 1px solid #e5eff8;
            margin: 1em auto;
            border-collapse: collapse;
        }

        .editable caption {
            font-style: italic;
            color: #9ba9b4;
            font-size: 0.9em;
            caption-side: bottom;
            text-align: right;
            padding-top: 5px;
            padding-bottom: 5px;
        }

        .editable tr {
            background: #f7fbff;
        }

        .editable tr:nth-child(2n) {
            background: #eff7ff;
        }

        .editable td {
            color: #678197;
            border-bottom: 1px solid #e5eff8;
            border-left: 1px solid #e5eff8;
            padding: 0.3em 1em;
            margin: 0;
        }

        .editable {
            font-size: 14px;
            line-height: 18px;
            line-height: 20px;
            min-height: 20px;
            padding-bottom: 7px;
        }

        .editable p {
            padding-bottom: 7px;
        }

        .editable li {
            margin-left: 15px;
            font-size: 14px;
            line-height: 20px;
        }

        .editable > ul {
            padding-bottom: 10px;
        }

        .editable ul {
            padding-left: 2em;
        }

        .editable h4 {
            padding-top: 5px;
            padding-bottom: 10px;
        }

        .moveaction {
            display: none;
        }

        .ui-state-highligh {
            border: 2px dashed grey;
            background-color: #fff;
        }

        #fancybox-loading {
            position: fixed;
            top: 50%;
            left: 50%;
            width: 40px;
            height: 40px;
            margin-top: -20px;
            margin-left: -20px;
            cursor: pointer;
            overflow: hidden;
            z-index: 1104;
            display: none;
        }

        #fancybox-loading div {
            position: absolute;
            top: 0;
            left: 0;
            width: 40px;
            height: 480px;
        }

        .hiddengroupitem,
        .hiddentagitem {
            display: none;
        }

        .datepicker .switch {
            text-decoration: underline !important;
        }

        .goal_questbonus {
            width: 50px;
            height: 50px;
            background-size: 100%;
            text-align: center;
            float: none;
            color: #fff;
        }

        .widget-quest .goal_questbonus {
            width: 40px;
            height: 40px;
        }

        .widget-quest .goal_questbonus > p {
            padding-top: 10px !important;
            font-size: 14px !important;
        }

        .goallist li:first-child {
            min-height: 55px;
            margin-top: -39px;
        }

        .widget-quest .goallist li:first-child {
            margin-left: 50px;
            margin-top: -40px;
            padding: 0;
        }

        .shopitem_box {
            display: inline-block;
        }

        .modal-tabselector li {
            display: inline-block;
            padding: 10px;
        }

        .modal-tabselector li.active {
            background-color: #007acc;
        }

        .modal-tabselector li.active a {
            color: #fff;
        }

        .b_ratings .b_rating img.rating_icon {
            width: 32px;
            height: 32px;
            margin-right: 3px;
        }

        .b_ratings .b_rating .rating_marker {
            overflow: visible;
            margin-left: 35px;
        }

        .right-ratingspane {
            margin: 0;
        }

        .cont-hed-bot .nav > li:first-child > div > a > .menubadge {
            display: inline-block;
        }

        .cont-hed-bot .nav > li:first-child {
            width: 90px;
            height: 10px;
        }

        .replytext {
            padding-right: 15px;
        }

        .news_tagsholder a {
            color: #fff;
        }

        .dropdown-menu {
            text-align: left;
        }

        .realtext h1,
        .realtext h2,
        .realtext h3,
        .realtext h4,
        .realtext h5,
        .realtext h6 {
            margin-top: 0 !important;
            padding: 0 !important;
            background: none;
            color: #000;
            font-weight: bold;
        }

        .realtext h1 {
            font-size: 36px !important;
            line-height: 1.111em !important;
            margin-bottom: 0.15em !important;
        }

        .realtext h2 {
            font-size: 30px !important;
            line-height: 1.111em !important;
            margin-bottom: 0.25em !important;
        }

        .realtext h3 {
            font-size: 24px !important;
            line-height: 1.333em !important;
            margin-bottom: 0.2em !important;
        }

        .realtext h4 {
            font-size: 18px !important;
            line-height: 1.5em !important;
            margin-bottom: 0.2em !important;
        }

        .realtext h5 {
            font-size: 1em !important;
            line-height: 1.6em !important;
            margin-bottom: 0.25em !important;
        }

        .realtext h6 {
            font-size: 0.8em !important;
            line-height: 1.6em !important;
            text-transform: uppercase;
            margin-bottom: 0.3em !important;
        }

        .w-b-birthday {
        }

        .w-b-birthday li {
            clear: both;
            list-style-type: none;
            padding: 5px;
        }

        .w-b-birthday li img {
            margin-right: 5px;
        }

        .event-post {
            margin: 15px 5px;
        }

        .event-post > .event-left-block {
            float: left;
            margin: 0 10px 10px 0;
        }

        .event-date {
            border-radius: 6px;
            -webkit-border-radius: 6px;
            -moz-border-radius: 6px;
            padding: 0;
            margin: 0;
            display: inline-block;
            width: 48px;
            border: 1px solid #e3e3e3;
            min-height: 45px;
        }

        .event-date .event-date-month {
            padding: 0;
            margin: 0;
            text-align: center;
            font-size: 10px;
            background-color: red;
        }

        .event-date .event-date-day {
            padding: 5px;
            margin: 0;
            text-align: center;
            font-size: 16px;
            font-weight: bold;
            border-radius: 0 0 5px 5px;
            -webkit-border-radius: 0 0 5px 5px;
            -moz-border-radius: 0 0 5px 5px;
            background-color: #fff;
        }

        .n-b-poll {
            width: 100%;
            padding-left: 11px;
        }

        .goalpushed {
            text-decoration: line-through;
        }

        .usermarker,
        .headusermarker {
            width: 30px;
            height: 30px;
        }


        .table-ideas {
            table-layout: fixed;
        }

        .fixed {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
        }

        .navbar .brand {
            color: #d2f2ff !important;
            text-shadow: none !important;
            font-weight: normal !important;
            margin-left: -40px;
            margin-bottom: 5px;
        }

        .navbar .brand > img {
            vertical-align: middle !important;
        }

        .p-b-anmidea {
            font-size: 14px;
            margin-bottom: 10px;
        }

        .navbar-inner {
            border-bottom: 3px solid #fff !important;
            height: 110px;
        }

        .navbar-fixed-top {
            margin-bottom: 20px;
        }

        .main-width {
            overflow: visible;
        }

        .gradient {
            padding-top: 5px !important;
        }

        .span530 .w-b-textblock {
            padding: 0;
            margin-bottom: 15px;
        }

        .g-p-groupheadblock .avatar_large img {
            width: 100px !important;
            height: 100px !important;
        }

        .g-p-groupheadblock .avatar_large {
            min-width: 100px !important;
        }

        .g-p-groupheadblock .avatar_large div {
            border: none !important;
        }

        .g-p-groupheadblock .form_prof_dannie {
            min-height: 100px;
        }

        .g-p-groupheadblock .profile-actions {
            margin-left: 116px !important;
        }

        .w-b-textblock {
            background: #fff;
        }

        .right_lk .prn-widget {
            background: #fff;
        }

        .right_lk .prn-widget span .well {
            box-shadow: none;
        }

        .right_lk .prn-widget span h3 {
            padding: 0 19px;
        }

        .right_lk .well {
            -moz-box-shadow: 1px 5px 8px #c2c2c2;
            -webkit-box-shadow: 1px 5px 8px #c2c2c2;
            box-shadow: 1px 5px 8px #c2c2c2;
            margin-bottom: 30px;
            padding: 19px;
            background: #fff;
        }

        .postblock .span2 .caret {
            margin-top: 8px !important;
        }

        .postblock .span2 a {
            float: left;
            color: #000;
        }

        .dropdown-menu > li > label {
            display: block;
            padding: 3px 20px;
            clear: both;
        }

        .dropdown-menu > li > label.checkbox {
            display: block;
            padding: 3px 10px 3px 30px;
            clear: both;
        }

        .postblock .span2 {
            margin-right: 20px;
        }

        .right_lk table td {
            padding: 5px !important;
            border: 1px solid #333;
            vertical-align: top;
        }

        .right_lk table {
            border-collapse: collapse;
            font-size: 1em !important;
        }

        .replytext table {
            border-collapse: collapse;
            font-size: 1em !important;
        }

        .replytext table td {
            padding: 5px !important;
            border: 1px solid #333;
            vertical-align: top;
        }

        .content {
            // margin: 0 16px 0 10px;
        }

        .spanleft {
            width: 170px;
        }

        .leftnav > li {
            width: 162px !important;
        }

        #shopitems {
            background: #fff;
            border-radius: 5px;
        }

        .shopItemUser {
            border-bottom: 1px solid rgba(0, 0, 0, 0.075);
            margin-left: 10px;
            width: 230px;
            width: 230px;
            padding: 5px 2px;
            min-height: 300px;
            margin-top: 3px;
            display: none;
            float: none !important;
            display: -moz-inline-stack;
            display: inline-block;
            vertical-align: top;
            *display: inline;
        }

        .shopItemUser:hover {
            -webkit-box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.075);
            -moz-box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.075);
            box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.075);
        }

        .reply-list {
            background: #fafbfb;
            padding: 0 15px 0 0;
            margin: 10px 0 0 0;
            border-top: 1px solid #dee7ef;
        }

        .reply-list.replyto div.replypostcomment {
            width: 89.7%;
            width: -moz-calc(100% - 60px);
            width: -webkit-calc(100% - 60px);
            width: calc(100% - 60px);
            margin-bottom: 5px;
            float: left;
        }

        .reply-list .namel {
            font-size: 12px;
            margin-top: -10px;
        }

        .well {
            border-radius: 0 !important;
            -moz-box-shadow: 1px 5px 8px #c2c2c2;
            -webkit-box-shadow: 1px 5px 8px #c2c2c2;
            box-shadow: 1px 5px 8px #c2c2c2;
            border: none !important;
        }

        .span-two-thirds img {
            margin-top: 5px;
        }

        .left-menu {
            background: #fff;
            border: 1px solid #d3d6db;
            -moz-box-shadow: 1px 5px 8px #c2c2c2;
            -webkit-box-shadow: 1px 5px 8px #c2c2c2;
            box-shadow: 1px 5px 8px #c2c2c2;
        }

        .reply-list.replyto {
            border-top: 0;
            margin-top: 0;
            border-radius: 0 0 5px 5px;
            position: relative;
        }

        .mainnews .replytext_new {
            padding-right: 15px;
            margin-left: 67px !important;
        }

        .news_avatar {
            margin-left: 7px;
        }

        .postfooter {
            padding-right: 15px;
        }

        .reply .postfooter {
            padding-right: 0;
        }

        .mainnews .media > .pull-left {
            margin-right: 0;
        }

        .reply-list .replytext {
            font-size: 12px;
        }

        .right-group-select {
            background: none;
            top: -15px;
        }

        .reply-list > ul > li {
            padding-left: 10px;
            border-top: 0;
        }

        .reply-list > ul > li.rait-well:first-child {
            padding-bottom: 10px !important;
            border-left: 2px solid #fafbfb;
            padding-left: 5px;
            margin-top: 0;
            border-bottom: 1px solid #dee7ef;
        }

        .reply-list.replyto div.replypostimage {
            margin: 0 10px 0 10px;
        }

        .table tr:nth-child(odd) {
            background: #f5f5f5;
        }

        .table tr:nth-child(even) {
            background: #fff;
        }

        .table thead tr th {
            background: #fff;
        }

        .table-hover tbody tr:hover > td,
        .table-hover tbody tr:hover > th {
            background-color: #c3cfe0;
        }

        .cont-hed-bot {
            background: none;
            margin-bottom: 20px;
        }

        .cont-hed-bot .dropdown {
            margin-top: -35px;
        }

        .cont-hed-bot .nav {
            margin-top: -15px;
        }

        .cont-hed-bot .searchtop {
            margin-left: 10px !important;
        }

        .n-b-preview {
            display: inline-block;
        }

        .prnsyspreview,
        .n-b-badgepreview {
            margin-right: -12px !important;
        }

        .right_lk .progress .bar-counter {
            margin-top: 0;
            line-height: 18px;
        }

        .progress .bar-counter {
            margin-top: 5px;
        }

        #report_tablecreativeusers,
        #report_tablepopularusers,
        #report_tablegeneroususers,
        #report_newstype {
            height: 30px;
            margin-top: 0;
        }

        .n-b-poll {
            margin-top: 10px;
            margin-right: 5px;
            width: 100%;
        }

        .pryan_count_new {
            right: -70px;
            margin-left: 0;
        }

        .right_lk .shopitem_box .btn {
            margin-left: 0;
        }

        .g-b-table thead tr th:last-child {
            display: none;
        }

        .p-b-breadcrumb li:last-child .divider {
            display: none;
        }

        .p-b-about {
            padding: 10px;
            line-height: 20px;
            background: #fff;
            border: 1px solid #d3d6db;
            border-radius: 5px;
        }

        .padding4 span {
            color: inherit !important;
            border-bottom: 1px solid #7ad8ff;
            margin-left: 5px;
        }

        .padding4 {
            color: #7ad8ff !important;
            text-decoration: none !important;
        }

        .top_part_prof {
            padding-bottom: 35px;
        }

        .top_part_prof .well {
            box-shadow: none !important;
        }

        .top_part_prof .profile-actions {
            margin-bottom: 5px;
        }

        .profile_thcount {
            bottom: 15px !important;
        }

        .group_right .well {
            padding-top: 19px !important;
        }

        input[type="text"] {
            border-radius: 0 !important;
        }

        input,
        textarea,
        .selected,
        select .mentions-input-box,
        .select2-choices,
        .popover,
        button.btn,
        input[type="submit"].btn,
        #searchtxt,
        .dropdown-menu {
            border-radius: 0 !important;
        }

        .btn {
            border-radius: 0 !important;
        }

        .leftnav > li {
            border-radius: 0 !important;
        }

        select {
            border-radius: 0 !important;
        }

        .searchinput input[type="text"] {
            border-radius: 0 !important;
        }

        .tabs:hover {
            border-radius: 0 !important;
        }

        textarea,
        input[type="text"],
        input[type="password"],
        input[type="datetime"],
        input[type="datetime-local"],
        input[type="date"],
        input[type="month"],
        input[type="time"],
        input[type="week"],
        input[type="number"],
        input[type="email"],
        input[type="url"],
        input[type="search"],
        input[type="tel"],
        input[type="color"],
        .uneditable-input {
            border-radius: 0 !important;
        }

        .newsnemu {
            right: 5px !important;
            z-index: 1000;
        }

        .span10 h4 {
            margin-bottom: 8px;
        }

        .span10 .post-items-type {
            margin-bottom: 20px;
        }

        .accordion {
            background: #fff;
            -moz-box-shadow: 1px 5px 8px #c2c2c2;
            -webkit-box-shadow: 1px 5px 8px #c2c2c2;
            box-shadow: 1px 5px 8px #c2c2c2;
        }

        .accordion-group {
            border: 0 !important;
        }

        .page-read-mode .prn-widget-no-data {
            display: none;
        }

        .profile_right h2 {
            line-height: 40px !important;
        }

        .modal,
        .progress {
            border-radius: 0;
        }

        #form_prof_dannie_head {
            padding-bottom: 10px;
        }

        #form_prof_dannie_head .btn-group > .btn.active {
            margin-top: -10px !important;
        }

        .reasontext,
        .reasonvin {
            width: 150px !important;
        }

        .input input[type="number"] {
            margin-top: 5px;
        }

        .n-b-newstype22 blockquote,
        [class*="wikicolumn1"] blockquote {
            margin-left: 1.5em !important;
            padding-left: 0 !important;
            color: #777;
            font-style: italic !important;
        }

        .n-b-newstype22 div,
        [class*="wikicolumn1"] div,
        .n-b-newstype22 p,
        [class*="wikicolumn1"] p,
        .n-b-newstype22 ul,
        [class*="wikicolumn1"] ul,
        .n-b-newstype22 ol,
        [class*="wikicolumn1"] ol,
        .n-b-newstype22 table,
        [class*="wikicolumn1"] table,
        .n-b-newstype22 dl,
        [class*="wikicolumn1"] dl,
        .n-b-newstype22 blockquote,
        [class*="wikicolumn1"] blockquote,
        .n-b-newstype22 pre,
        [class*="wikicolumn1"] pre {
            margin: 0;
            margin-bottom: 10px !important;
            border: none;
            background: none;
            box-shadow: none !important;
            font-size: 14px;
            line-height: 1.5em;
        }

        .n-b-newstype22 pre,
        [class*="wikicolumn1"] pre {
            overflow: auto;
            padding: 1em !important;
            border: 1px solid #ddd !important;
            border-radius: 3px !important;
            background: #f8f8f8 !important;
            white-space: pre;
            font-size: 90% !important;
        }

        .n-b-newstype22 h1,
        .n-b-newstype22 h2,
        .n-b-newstype22 h3,
        .n-b-newstype22 h4,
        .n-b-newstype22 h5,
        .n-b-newstype22 h6,
        [class*="wikicolumn1"] h1,
        [class*="wikicolumn1"] h2,
        [class*="wikicolumn1"] h3,
        [class*="wikicolumn1"] h4,
        [class*="wikicolumn1"] h5,
        [class*="wikicolumn1"] h6 {
            margin-top: 0 !important;
            padding: 0 !important;
            background: none;
            color: #000;
            font-weight: bold;
        }

        .n-b-newstype22 h1,
        [class*="wikicolumn1"] h1 {
            font-size: 36px !important;
            line-height: 1.111em !important;
            margin-bottom: 0.15em !important;
        }

        .n-b-newstype22 h2,
        [class*="wikicolumn1"] h2 {
            font-size: 30px !important;
            line-height: 1.111em !important;
            margin-bottom: 0.25em !important;
        }

        .n-b-newstype22 h3,
        [class*="wikicolumn1"] h3 {
            font-size: 24px !important;
            line-height: 1.333em !important;
            margin-bottom: 0.2em !important;
        }

        .n-b-newstype22 h4,
        [class*="wikicolumn1"] h4 {
            font-size: 18px !important;
            line-height: 1.5em !important;
            margin-bottom: 0.2em !important;
        }

        .n-b-newstype22 h5,
        [class*="wikicolumn1"] h5 {
            font-size: 1em !important;
            line-height: 1.6em !important;
            margin-bottom: 0.25em !important;
        }

        .n-b-newstype22 ol,
        .n-b-newstype22 ul {
            padding-left: 12px !important;
        }

        [class*="wikicolumn1"] {
            background: #fff;
            // padding: 20px 15px;
            // width: 750px;
        }

        .redactor_editor {
            padding: 10px 0;
        }

        .redactor_toolbar li {
            background: #f5f5f5;
        }

        [class*="wikicolumn1"] table,
        .n-b-newstype22 table {
            border-collapse: collapse;
            font-size: 1em !important;
        }

        [class*="wikicolumn1"] table td,
        .n-b-newstype22 table td {
            padding: 5px !important;
            border: 1px solid #ddd;
            vertical-align: top;
        }

        [class*="wikicolumn1"] table tr,
        .n-b-newstype22 table tr {
            background: none !important;
        }

        .right_lk .rating-marker > div {
            max-width: 80%;
        }

        .validationMessage {
            margin-top: -7px;
            margin-bottom: 5px;
        }

        .redactor_toolbar li a {
            color: #333 !important;
        }

        .post-items-type > .active > a {
            color: #fff;
        }

        .post-items-type > li:hover > a {
            color: #fff;
        }

        .post-items-content .dropdown-menu a,
        .post-items-content .dropdown-menu span {
            color: #333 !important;
        }

        .post-items-content .dropdown-menu > li > a:hover {
            color: #fff !important;
            background: #7a7a7a !important;
            padding: 3px 20px !important;
            border: 0 !important;
        }

        .post-items-content .dropdown-menu > li > a:hover span {
            color: #fff !important;
        }

        .gradient .dropdown-menu .radio,
        .gradient .dropdown-menu .checkbox {
            color: #333 !important;
        }

        .pryanikyPostType div:first-child a,
        .pryanikyPostType div:first-child span {
            color: #333 !important;
        }

        .pryanikyPostType > div {
            margin-bottom: 10px;
        }

        .v-b-item {
            overflow: hidden;
            float: left;
            width: 357px;
            min-height: 150px;
            margin-top: 10px;
            margin-left: 10px;
            border: 1px solid #fff;
            padding: 10px;
        }

        .ms_bg {
            background-attachment: fixed;
            font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
        }

        .ms_login_w {
            background: #fff;
            padding: 40px 50px;
            font-size: 15px;
            line-height: 18px;
            position: relative;
        }

        .orange_btn {
            color: #fff !important;
            text-decoration: none !important;
            font-size: 16px;
            background: #ffc20d;
            background: -webkit-linear-gradient(#ffc20d, #f14e23);
            background: -o-linear-gradient(#ffc20d, #f14e23);
            background: -moz-linear-gradient(#ffc20d, #f14e23);
            background: linear-gradient(#ffc20d, #f14e23);
            padding: 13px 5px;
            display: block;
            width: 200px;
            text-align: center;
            margin: 20px 0;
            border: 0;
            outline: 0;
        }

        .orange_btn:hover {
            background: #fa8405;
            background: -webkit-linear-gradient(#fa8405, #ed370c);
            background: -o-linear-gradient(#fa8405, #ed370c);
            background: -moz-linear-gradient(#fa8405, #ed370c);
            background: linear-gradient(#fa8405, #ed370c);
        }

        .ms_bg .ms_body_log {
            padding: 20px 0 90px;
            margin: 0 auto;
            float: none;
        }

        .ms_logo {
            margin-bottom: 35px;
        }

        .ms_logo_text {
            position: absolute;
            right: 40px;
            top: 30px;
            width: 130px;
        }

        .ms_logo_text img {
            width: 100%;
        }

        .ms_bg .ms_login_w h3 {
            margin-bottom: 10px;
        }

        .ms_bg h3 {
            font-size: 25px;
            font-weight: normal;
        }

        .logon_form {
            color: #1b3466;
            font-size: 16px;
            line-height: 22px;
        }

        .logon_form form {
            width: 310px;
            margin: 20px auto 0;
        }

        .logon_form form input,
        .logon_form .orange_btn {
            width: 100%;
        }

        .logon_form form input {
            margin-bottom: 10px;
            border: 1px solid #234486;
            padding: 7px 10px;
            font-size: 16px;
            width: 93%;
        }

        .logon_form .orange_btn {
            margin: 10px 0;
        }

        .diot_bg {
            background-size: cover;
            background-attachment: fixed;
            font-family: Arial, Helvetica, sans-serif;
        }

        .b_no_style {
            background: none;
            border: none;
            outline: none;
        }

        .diot_body_log {
            padding-top: 40px;
            float: none;
            margin: 0 auto;
        }

        .diot_body_log img {
            margin: 0 auto;
            display: block;
        }

        .diot_logo {
            margin-bottom: 40px !important;
        }

        .diot_btn {
            margin: 20px auto 0 auto;
            display: block;
        }

        .diot_body_log .logon_form h3 {
            font-size: 25px;
            font-weight: bold;
            color: #002d58;
            text-shadow: 0 0 10px #fff;
        }

        .diot_body_log .logon_form form input {
            text-align: center;
            color: #002d58;
            font-size: 20px;
        }

        .b-trc-fulltext {
            display: none;
        }

        .b-trc-fulltext.b-trc-truncated {
            display: block;
        }

        .re-meetupplaceholder:before {
            content: "";
        }

        .content-wikipage {
            position: relative;
        }

        .w-p-editbutton {
            right: -20px;
            opacity: 0.5;
            position: absolute;
        }

        .w-p-editbutton:hover {
            opacity: 1;
        }

        .marginVer5 {
            margin: 5px 0 5px 20px;
        }

        .material-switch > input[type="checkbox"] {
            display: none;
        }

        .material-switch > label {
            cursor: pointer;
            height: 0;
            position: relative;
            width: 40px;
        }

        .material-switch > label::before {
            background: #000;
            box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.5);
            border-radius: 8px;
            content: "";
            height: 13px;
            margin-top: -8px;
            position: absolute;
            opacity: 0.3;
            transition: all 0.4s ease-in-out;
            width: 40px;
        }

        .material-switch > label::after {
            background: #fff;
            border-radius: 16px;
            box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
            content: "";
            height: 20px;
            left: -4px;
            margin-top: -8px;
            position: absolute;
            top: -4px;
            transition: all 0.3s ease-in-out;
            width: 20px;
        }

        .material-switch > input[type="checkbox"]:checked + label::before {
            background: inherit;
            opacity: 0.5;
        }

        .material-switch > input[type="checkbox"]:checked + label::after {
            background: inherit;
            left: 20px;
        }

        .constTest .border {
            border-top: 1px solid #b8b8b8;
        }

        .constTest .menuSection {
            height: 30px;
            margin-top: 10px;
        }

        .constTest .menublockIcon {
            font-size: 16px;
            height: 30px;
            text-align: center;
        }

        .constTest .copyIcon {
            border-right: 1px solid #b8b8b8;
            padding-right: 7px;
        }

        .constTest .topMargin {
            margin-top: 8px;
        }

        .questionitem ol.botMargin {
            margin-bottom: 10px;
        }

        .constTest .questionSec {
            margin-top: 9px;
            float: left;
            font-size: 14px;
        }

        .constTest .toggle {
            margin: 18px -30px 0 0;
        }

        .constTest .menuIcon {
            font-size: 20px;
            text-align: center;
        }

        ul.q-s-height li {
            height: 30px;
            padding: 5px 0 5px 0;
            clear: both;
        }

        .q-s-ulStyle {
            font-size: 14px;
        }

        .q-s-ulStyle {
            margin-bottom: 5px;
        }

        li.q-s-liStyle {
            margin-bottom: 5px;
        }

        .q-s-toggleStyle {
            margin-top: 12px;
        }

        .q-s-ulStyle .tagsinput {
            width: auto !important;
            min-height: 68px !important;
            max-height: 68px !important;
        }

        @-webkit-keyframes click-wave {
            0% {
                width: 40px;
                height: 40px;
                opacity: 0.35;
                position: relative;
            }

            100% {
                width: 200px;
                height: 200px;
                margin-left: -80px;
                margin-top: -80px;
                opacity: 0;
            }
        }

        @-moz-keyframes click-wave {
            0% {
                width: 40px;
                height: 40px;
                opacity: 0.35;
                position: relative;
            }

            100% {
                width: 200px;
                height: 200px;
                margin-left: -80px;
                margin-top: -80px;
                opacity: 0;
            }
        }

        @-o-keyframes click-wave {
            0% {
                width: 40px;
                height: 40px;
                opacity: 0.35;
                position: relative;
            }

            100% {
                width: 200px;
                height: 200px;
                margin-left: -80px;
                margin-top: -80px;
                opacity: 0;
            }
        }

        @keyframes click-wave {
            0% {
                width: 40px;
                height: 40px;
                opacity: 0.35;
                position: relative;
            }

            100% {
                width: 200px;
                height: 200px;
                margin-left: -80px;
                margin-top: -80px;
                opacity: 0;
            }
        }

        .option-input {
            -webkit-appearance: none;
            -moz-appearance: none;
            -ms-appearance: none;
            -o-appearance: none;
            appearance: none;
            position: relative;
            top: 13.33333px;
            width: 40px !important;
            height: 40px;
            -webkit-transition: all 0.15s ease-out 0;
            -moz-transition: all 0.15s ease-out 0;
            transition: all 0.15s ease-out 0;
            background: #cbd1d8;
            border: none;
            color: #fff;
            cursor: pointer;
            display: inline-block;
            outline: none;
            position: relative;
            margin-right: 0.5rem;
            z-index: 1000;
        }

        .option-input:hover {
            background: #9faab7;
        }

        .option-input:checked {
            background: #40e0d0;
        }

        .option-input:checked::before {
            width: 40px;
            height: 40px;
            position: absolute;
            content: "✓";
            display: inline-block;
            font-size: 26.66667px;
            text-align: center;
            line-height: 40px;
        }

        .option-input:checked::after {
            -webkit-animation: click-wave 0.65s;
            -moz-animation: click-wave 0.65s;
            animation: click-wave 0.65s;
            background: #40e0d0;
            content: "";
            display: block;
            position: relative;
            z-index: 100;
        }

        .option-input.radioinput {
            border-radius: 50% !important;
        }

        .option-input.radioinput::after {
            border-radius: 50% !important;
        }

        #question_code pre.prettyprint {
            margin-bottom: 0;
        }

        #question_code code,
        pre {
            font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
            font-size: 14px;
        }

        #welcomewizard .l-questions {
            border: 1px solid #999;
            padding: 10px;
            background-color: #efefef;
        }

        #welcomewizard .l-variants {
            border-bottom: 1px dotted #999;
            padding: 10px;
            margin-bottom: 10px;
        }

        #welcomewizard ul {
            list-style-type: none;
        }

        #welcomewizard .option-input {
            top: 0;
        }

        #welcomewizard input {
            outline: none;
            border: 0;
            box-shadow: none;
        }

        #welcomewizard .padding {
            padding-top: 10px;
        }

        #welcomewizard .labelMargin {
            margin-top: 10px;
        }

        #welcomewizard .paddingBoth {
            padding: 10px 0 10px 0;
        }

        .l-b-coursecontent {
            width: auto;
        }

        .realtext iframe {
            max-width: 450px !important;
        }

        .ui-slider-handle[class*="ui-state-"] .ui-slider-tip {
            visibility: visible;
            opacity: 1;
            top: -30px;
        }

        #postElem .accordion {
            margin: 0;
            padding: 0;
        }

        #postElem > form > div > .checkbox {
            margin-top: 15px;
        }

        #postElem > form > div > div > .accordion:nth-child(1) {
            margin-bottom: 15px;
        }

        #postElem > form > div > div > .postblock > input {
            float: none;
            margin-right: 15px;
            display: inline-block;
            vertical-align: baseline;
        }

        #postElem > form > div > div > .postblock > a {
            display: inline-block;
            vertical-align: baseline;
        }

        #postElem > form > div > div > .postblock > .btn-group > .dropdown-toggle {
            margin-left: -1px;
            padding: 4px 8px;
        }

        #postElem > form > div > div > .postblock > .btn-group > .dropdown-menu {
            top: 28px;
            right: 0;
            left: inherit;
        }

        #postElem > form > div > div > .accordion .accordion-heading .accordion-toggle {
            font-weight: bold;
            border-bottom: 1px solid #fff;
            padding-bottom: 2px;
            color: #fff;
            padding-left: 0;
        }

        .editNewsBlock .accordion .accordion-heading .accordion-toggle {
            font-weight: bold;
            border-bottom: 1px solid #fff;
            padding-bottom: 2px;
            font-size: 14px;
            color: #000;
            padding-left: 0;
        }

        #postElem > form > div > div > .accordion .accordion-heading .accordion-toggle:hover span {
            text-decoration: none;
        }

        #postElem .accordion-toggle.collapsed {
            border: none;
        }

        #postElem .accordion-toggle:not(.collapsed):before {
            border-top: 5px solid #fff !important;
            border-right: 6px solid transparent !important;
            border-left: 6px solid transparent !important;
        }

        #postElem .accordion-toggle.collapsed:before {
            border-top: 5px solid transparent !important;
            border-left: 6px solid #fff !important;
            border-bottom: 6px solid transparent !important;
        }

        #postElem .accordion-group table > tbody > tr > td:nth-child(0) {
            color: #fff;
        }

        #postElem .accordion-group table > tbody > tr {
            color: #fff;
        }

        #postElem .accordion-group table.idTable > tbody > tr > td:nth-child(0),
        .editNewsBlock table tr > td:nth-child(0) {
            text-align: left;
        }

        #postElem .accordion-group table.idTable > tbody > tr > td:nth-child(n + 2),
        .editNewsBlock table tr > td:nth-child(n + 2) {
            width: 30px;
            text-align: center;
            padding: 2px 6px;
        }

        #postElem .accordion-group div > .textbox > input,
        .editNewsBlock .accordion-group div > .textbox > input {
            margin-top: 15px;
        }

        .editNewsBlock table tr > td {
            font-size: 12px;
            border: 0;
        }

        .editNewsBlock table tr {
            background-color: rgba(0, 0, 0, 0) !important;
        }

        .modal > .modal-body > div > div {
            box-shadow: none;
        }

        .modal > .modal-body .idea-rating-item-name {
            font-size: 1.2em;
            font-weight: bold;
            margin-bottom: 7px;
        }

        .modal > .modal-body > div > div:nth-child(n + 2) {
            padding: 15px 0;
        }

        .modal > .modal-body table > tbody > tr > td:nth-child(0) {
            text-align: left;
        }

        .modal > .modal-body table > tbody > tr > td:nth-child(n + 2) {
            width: 30px;
            padding: 2px 8px;
            text-align: center;
        }

        .modal div > .textbox > input {
            margin-top: 15px;
            margin-bottom: 0;
            width: 484px !important;
        }

        .modal .modal-body > div > div:nth-last-child(n + 3) {
            border-bottom: 2px solid #e5e4e3;
        }

        .modal .modal-body a {
            text-decoration: none;
            cursor: default;
        }

        .modal .modal-body a:hover {
            text-decoration: none;
            cursor: default;
        }

        .accordion {
            margin-bottom: 10px;
        }

        .idea-fill-progress-bar {
            margin-top: 20px;
        }

        .idea-fill-progress-bar > .progress-striped {
            padding: 0;
            margin: 0;
            height: 10px;
            border-radius: 5px;
        }

        .idea-fill-progress-bar > span {
            margin-bottom: 8px;
            color: #fff;
            display: block;
        }

        .modal-body > .modal-content table {
            width: 100%;
        }

        .modal-body > .modal-content table td {
            padding: 5px 0 5px 7px;
            text-align: center;
        }

        .modal-body > .modal-content table tr > td:nth-child(1) {
            text-align: left;
            min-width: 250px;
        }

        .modal-body > .modal-content table tr > td > b {
            display: block;
            padding: 5px 0;
            font-weight: bold;
            font-size: 1.2em;
        }

        .modal-body > .modal-content .experts-evaluation tr:nth-child(2) > td:nth-child(1),
        .modal-body > .modal-content .experts-evaluation tr:nth-child(3) > td:nth-child(1) {
            padding: 8px 0 8px 2px;
        }

        .modal-body > .modal-content .utility-evaluation tr:nth-child(1) > td:last-child {
            font-size: 0.9em;
            font-weight: bold;
        }

        .modal-body > .modal-content table tr:nth-child(1) td:nth-child(n + 2) {
            min-width: 45px;
            height: 45px;
            padding: 0;
        }

        .modal-body > .modal-content table tr:nth-child(n + 2) td:nth-child(n + 2) {
            padding: 0;
            min-width: 45px;
        }

        .modal-body > .modal-content table tr:nth-child(1) td:last-child {
            width: 50px;
        }

        .modal-body > .modal-content table tr:nth-child(2n) {
            background-color: #d3d3d3;
        }

        .modal-body > .modal-content table tr.matrix-middle-of-all-elements {
        }

        .modal-body > .modal-content table tr:nth-child(1) td {
            height: 25px;
        }

        .newsitem .n-b-newstype21 .realtext .news_addidionalfields > div > div > span {
            font-weight: bold;
            margin: 5px 0;
            display: block;
            font-size: 14px;
        }

        .newsitem .n-b-newstype21 .realtext .news_addidionalfields > div > div label.textbox {
            font-style: italic;
            font-size: 12px;
            padding: 5px 0 10px;
        }

        .n-b-newstype21 .n-b-hideonedit {
            width: 25%;
            text-align: center;
        }

        .newsitem .n-b-newstype21 .n-b-hideonedit > div.dropdown {
            margin-bottom: 15px;
        }

        .newsitem .n-b-newstype21 .n-b-hideonedit > .news_ideastatus {
            font-size: 12px;
        }

        .newsitem .n-b-newstype21 .n-b-hideonedit > div.btn-group2 > a,
        .newsitem .n-b-newstype21 .n-b-hideonedit > div > .btn-small {
            padding: 4px 11px;
            line-height: 16px;
        }

        .content .newsitem .n-b-newstype21 .n-b-hideonedit > .dropdown > a {
            padding: 4px 6px;
            line-height: 16px;
        }

        .newsitem .n-b-newstype21 .realtext .news_addidionalfields > div > div > div > label.checkbox {
            padding: 0;
        }

        .newsitem .n-b-newstype21 .realtext .news_addidionalfields table > tbody > tr {
            background-color: rgba(0, 0, 0, 0);
        }

        .newsitem .n-b-newstype21 .realtext .news_addidionalfields table > thead {
            display: none;
        }

        .newsitem
            .n-b-newstype21
            .realtext
            .news_addidionalfields
            table
            > tbody
            > tr
            > td:nth-child(n + 2)
            > label
            > input {
            display: none;
        }

        .newsitem
            .n-b-newstype21
            .realtext
            .news_addidionalfields
            table
            > tbody
            > tr
            > td:nth-child(n + 2)
            > label
            > input[type="radio"]
            + i {
            position: relative;
            width: 20px;
            height: 20px;
            display: block;
            background: #faebd7;
            border-radius: 50%;
            cursor: default;
        }

        .newsitem
            .n-b-newstype21
            .realtext
            .news_addidionalfields
            table
            > tbody
            > tr
            > td:nth-child(n + 2)
            > label
            > input[type="radio"]:checked
            + i {
            background-color: #20b2aa;
        }

        .newsitem
            .n-b-newstype21
            .realtext
            .news_addidionalfields
            table
            > tbody
            > tr
            > td:nth-last-child(n + 2)
            > label
            > i:after {
            content: "";
            position: absolute;
            width: 10px;
            border: 1px solid #faebd7;
            top: 9px;
            left: 20px;
        }

        .newsitem .n-b-newstype21 .realtext .news_addidionalfields table > tbody > tr > td {
            vertical-align: middle !important;
            border: none;
            font-size: 0.86em;
            padding-left: 0 !important;
        }

        .p-b-main-container
            .content[data-bind="visible:_viewMode()==1"]
            .table-ideas
            > tbody
            > tr
            > td:last-child
            > div {
            text-align: left !important;
        }

        .p-b-main-container
            .content[data-bind="visible:_viewMode()==1"]
            .table-ideas
            > tbody
            > tr
            > td:last-child
            > div
            > span {
            padding-left: 18px;
        }

        div[data-bind="foreach: permissions"] .row:first-child > div:first-child {
            text-align: center;
            margin-bottom: 10px;
        }

        div[data-bind="foreach: permissions"] .row:nth-child(2) > div > div {
            display: inline-block;
            vertical-align: middle;
        }

        div[data-bind="foreach: permissions"] .row:nth-child(2) {
            margin-bottom: 20px;
        }

        .form_prof_dannie p:last-child > .editable-container {
            float: left;
            margin: 0 5px;
        }

        .modal-body .select2-choices > .select2-search-field {
            width: 500px;
        }

        .w-b-ideastatusfilter .sorting-ideas.active,
        .w-b-ideastatusfilter .status-creativetasks.active {
            font-weight: bold;
        }

        .sorting-ideas .filter-carret,
        .sorting-creativetasks .filter-carret {
            border-top: 8px solid #c5c5c5;
            border-right: 6px solid transparent;
            border-left: 6px solid transparent;
            border-bottom: none;
        }

        .sorting-ideas,
        .sorting-creativetasks {
            margin: 15px 0;
            cursor: pointer;
        }

        .sorting-ideas:last-child,
        .sorting-creativetasks:last-child {
            margin-bottom: 0;
        }

        .sorting-ideas:hover,
        .sorting-creativetasks:hover {
            font-weight: bold;
        }

        .rotate180 {
            transform: rotate(180deg);
        }

        .input-filters {
            margin-bottom: 0 !important;
            width: 120px;
        }

        .filter-rating-span {
            float: none !important;
            display: inline-block;
            vertical-align: top;
        }

        .clearBoth {
            clear: both;
        }

        .select2-container.select2-container-required .select2-choices {
            border: 1px solid red !important;
        }

        .p-support.p-n-support .p-b-main-container .content .support-send-form {
            background: #fff;
            padding: 15px;
            border: 1px solid #d3d6db;
        }

        .p-support.p-n-support .p-b-main-container .content .support-send-form > h4 {
            margin-bottom: 15px;
            text-align: center;
        }

        .p-support.p-n-support .p-b-main-container .content .support-send-form > .row label {
            display: inline-block;
            margin-left: 15px;
        }

        .p-support.p-n-support .p-b-main-container .content .support-send-form > .row label:nth-child(even) {
            margin-left: 27px;
        }

        .p-support.p-n-support .p-b-main-container .content .support-send-form > .row label:last-child {
            margin: 0;
        }

        .w-b-courselist ul {
            list-style-type: none;
            font-size: 13px;
        }

        .w-b-courselist ul li {
            margin-top: 15px;
        }

        .w-b-courselist ul li span.pull-left {
            margin-right: 10px;
        }

        .home-buttons {
            display: inline-block;
            vertical-align: top;
            visibility: hidden;
        }

        .home-buttons .home {
            height: 12px;
            width: 12px;
            background-position: 2.5px -188px;
        }

        .home-buttons .home,
        .home-buttons .noticelink {
            display: inline-block;
            vertical-align: top;
        }

        .home-buttons .home:hover,
        .home-buttons .noticelink > a:hover {
            background-color: #c3cfe0 !important;
            text-decoration: none !important;
            cursor: pointer;
        }

        .home-buttons .noticelink.open > a:before {
            color: #007bc4;
        }

        .home-buttons .home:focus,
        .home-buttons .noticelink > a:focus {
            text-decoration: none !important;
        }

        .home-buttons .noticelink > a,
        .home-buttons .home {
            background-color: rgba(255, 255, 255, 0.7);
            padding: 5px !important;
            margin-top: 3px;
            color: #797979 !important;
            border: 1px solid #ccc;
        }

        .home-buttons .bot-home-links:nth-child(n + 2) {
            margin-left: 5px !important;
            padding: 0;
        }

        .noticelink > a .icon-notice {
            margin-top: 0;
        }

        .noticelink > a .badge {
            display: inline-block;
            border-radius: 9px !important;
            top: -5px;
            right: -7px;
            position: absolute;
            height: 14px;
            width: 15px;
            padding: 0 !important;
            text-align: center;
        }

        .noticelink .dropdown-menu {
            width: 365px;
            max-width: 500px;
            padding: 0;
        }

        .noticelink .dropdown-menu .notification {
            padding: 4px 10px;
            position: relative;
        }

        .noticelink .dropdown-menu .notification:hover {
            background-color: #c3cfe0;
            background-image: none;
            color: #000;
        }

        .noticelink .dropdown-menu .notification .notification-img {
            display: inline-block;
            width: 40px;
            height: 40px;
            margin-right: 3.5px;
            vertical-align: middle;
            border-radius: 50%;
        }

        .noticelink .dropdown-menu .notification .badge {
            display: inline-block;
            position: absolute;
            top: 14px;
            right: 10px;
        }

        .noticelink .dropdown-menu .notification .notification-data {
            display: inline-block;
            vertical-align: middle;
            max-width: 300px;
        }

        .noticelink .dropdown-menu .notification .notification-data .notification-data-text,
        .noticelink .dropdown-menu .notification .notification-data .notification-data-text p {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: normal;
            height: 30px;
            font-size: 12px;
            line-height: 16px;
        }

        .noticelink .dropdown-menu .notification .notification-data .notification-data-date {
            color: #9e9e9e;
            font-size: 10px;
            line-height: 12px;
            margin-top: 4px;
        }

        .chatnoticelink .dropdown-menu .notification .notification-img,
        .chatnoticelink .dropdown-menu .notification .notification-data {
            vertical-align: middle;
        }

        .chatnoticelink .dropdown-menu .notification .notification-data .notification-data-text {
            height: 16px;
            overflow: hidden;
            white-space: nowrap;
        }

        .notification-list {
            list-style-type: none;
        }

        .notification-list .notification-list-item > a {
            color: #000;
            display: block;
            padding: 10px;
        }

        .notification-list .notification-list-item .notification-img {
            width: 48px;
            height: 48px;
            margin-right: 7px;
        }

        .notification-list .notification-list-item .notification-data {
            display: inline-block;
            vertical-align: top;
            max-width: 91%;
        }

        .notification-list .notification-list-item .notification-data .notification-data-text,
        .notification-list .notification-list-item .notification-data .notification-data-text p {
            height: 34px;
            font-size: 14px;
            line-height: 18px;
            margin-top: -2px;
            overflow: hidden;
        }

        .notification-list .notification-list-item .notification-data .notification-data-date {
            color: #9e9e9e;
            font-size: 12px;
            line-height: 12px;
            margin-top: 5px;
        }

        .notification-list .notification-list-item:nth-child(odd) {
            background: #f5f5f5;
        }

        .notification-list .notification-list-item:nth-child(even) {
            background: #fff;
        }

        .notification-list .notification-list-item:hover {
            background-color: #c3cfe0;
        }

        .notification-list .notification-list-item:hover > a,
        .notification-list .notification-list-item:focus > a {
            color: inherit;
            text-decoration: none;
        }

        .notification-list-item__not-viewed,
        .notification__not-viewed {
            background: #eee !important;
        }

        .group-tree-list-container_header {
            background: #fff;
        }

        .group-tree-list-container_header span {
            display: inline-block;
            vertical-align: bottom;
            padding: 8px;
            font-weight: bold;
            font-size: 13px;
            line-height: 20px;
        }

        .group-tree-item .group-tree-accordion-buttons .subgroups {
            padding-right: 17px;
            font-size: 11px;
            position: relative;
        }

        .group-tree-item .group-tree-accordion-buttons .subgroups::after {
            content: "";
            position: absolute;
            top: 7px;
            right: 4px;
            border-width: 4px;
            border-style: solid;
            border-right: 8px solid;
            border-left: 0;
            border-color: transparent #fff transparent transparent;
            transition: all 0.5s ease-in-out;
        }

        .group-tree-item .group-tree-accordion-buttons .subgroups.open::after {
            transform: rotate(-90deg);
        }

        .rosbank-menu_tooltip {
            display: none;
            position: absolute;
            top: -27px;
            color: #fff;
            background: rgba(70, 70, 70, 0.85);
            padding: 0 7px;
            white-space: nowrap;
            border-radius: 6px;
            z-index: 1;
        }

        .rosbank-menu_tooltip::after {
            content: "";
            position: absolute;
            display: block;
            bottom: -6px;
            left: 20px;
            border: 3px solid rgba(70, 70, 70, 0.85);
            border-right-color: transparent;
            border-bottom-color: transparent;
        }

        .rosbank-menu_item {
            position: relative;
            overflow: visible !important;
        }

        .rosbank-menu_item > a {
            width: 158px;
            padding: 0 0 0 10px !important;
            margin-right: 0 !important;
            margin-left: -6px !important;
        }

        .rosbank-menu_item:hover .rosbank-menu_tooltip {
            display: block;
        }

        #pgzoomview .he-view .sider-top > a {
            vertical-align: middle;
        }

        #pgzoomview .he-view .sider-top > .fa-download.download:before {
            margin-top: 11px;
            display: block;
        }

        .nav > li > a:hover,
        .nav > li > a:focus {
            background-color: #c3cfe0 !important;
        }

        .search_top_block {
            margin-left: 0;
            display: inline-block;
            vertical-align: top;
        }

        .user-block-li {
            width: 280px;
            padding-top: 2px;
            white-space: nowrap;
        }

        .user-block {
            margin-left: 45px;
        }

        .user-block .logged-user-photo {
            vertical-align: middle;
            width: 41px;
            height: 41px;
            border: 1px solid #e3e4e6;
            margin-right: 7px;
        }

        .group-chatblock {
            position: absolute;
            bottom: 5px;
            right: 5px;
        }

        .btn-complete-edit-page {
            opacity: 0.7;
            position: absolute;
            top: 10px;
            right: 40px;
        }

        .r-p-newsviews {
            display: none;
        }

        .goal-approve-header {
            font-weight: bold;
        }

        .p-b-quests .goal_questbonus {
            float: left;
        }

        .modal .modal-tabselector + .userlist .modal-body {
            max-height: 300px;
        }

        .rating-levels {
            width: 100%;
            margin-bottom: 10px;
        }

        .rating-levels thead {
            font-weight: bold;
            text-align: center;
            font-size: 13px;
        }

        .rating-levels tbody tr {
            height: 46px;
        }

        .rating-levels td {
            padding: 3px;
        }

        .rating-levels tbody td:not(:last-child) div {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .rating-levels tbody td:first-child div {
            width: 40px;
            margin: 0 auto;
        }

        .rating-levels tbody td:not(:last-child):not(:first-child):nth-child(2) div {
            width: 200px;
            text-align: center;
        }

        .rating-levels tbody td:not(:last-child):not(:first-child):nth-child(3) div {
            width: 175px;
            text-align: center;
        }

        .rating-levels tbody td:last-child button {
            border: 0;
            background: transparent;
            margin-left: 0;
        }

        .modal-body.rating-level div.clearfix.nospace > div {
            padding: 10px;
            border-top: 1px solid #e5e4e3;
            border-bottom: 0;
        }

        .modal-body.rating-level div.clearfix.nospace > div input {
            margin-bottom: 0;
        }

        .modal-body.rating-level div.clearfix.nospace > div span {
            width: 150px;
            display: inline-block;
            vertical-align: middle;
        }

        .modal-body.rating-level div.clearfix.nospace > div span + a {
            display: inline-block;
            vertical-align: middle;
        }

        .modal-body.rating-level div.clearfix.nospace .uploadblock {
            margin-top: 0;
        }

        .modal-body.rating-level div.clearfix.nospace .error {
            text-align: center;
            color: red;
        }

        .p-n-leaderboard-list-header {
            margin-bottom: 15px;
        }

        .ui-ios-overlay .title {
            font-size: 14px;
        }

        .ui-ios-overlay img {
            margin-top: 0;
        }

        .p-b-managecompanyprofile .p-b-design-upload .uploadblock > div {
            min-width: 180px;
        }

        .p-b-managecompanyprofile > label > span {
            display: inline-block;
            min-width: 150px;
        }

        .n-b-tags {
            margin-top: 10px;
        }

        #groupLevels .accordion-inner > .btn {
            padding: 3px 10px !important;
        }

        .RatingLevel {
            margin: 10px 0 25px -12px;
        }

        .modal-body.rating-level .uploadblock .prnuploader {
            padding-top: 20px;
            margin-bottom: 8px;
        }

        .headquestion-postfooter {
            padding-top: 2px;
        }

        .headquestion-postfooter:after {
            content: "";
            clear: both;
            display: block;
        }

        .headquestion-dropdown {
            position: relative;
        }

        .headquestion-dropdown > a {
            padding: 0 10px !important;
        }

        .headquestion-dropdown > a .caret {
            margin-top: 4px;
        }

        .headquestion-dropdown .dropdown-menu > span {
            width: 480px;
            white-space: normal;
            display: block;
        }

        .headquestion-dropdown .dropdown-menu > span > a {
            white-space: normal;
            width: 100%;
            box-sizing: border-box;
            display: block;
            padding: 3px 10px;
            clear: both;
            font-weight: normal;
            line-height: 20px;
            display: block;
            color: #333;
        }

        .headquestion-dropdown .dropdown-menu > span > a:hover {
            color: #fff;
            text-decoration: none;
            background-color: #0081c2;
            background-image: -moz-linear-gradient(top, #08c, #0077b3);
            background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#08c), to(#0077b3));
            background-image: -webkit-linear-gradient(top, #08c, #0077b3);
            background-image: -o-linear-gradient(top, #08c, #0077b3);
            background-image: linear-gradient(to bottom, #08c, #0077b3);
            background-repeat: repeat-x;
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff0088cc',endColorstr='#ff0077b3',GradientType=0);
        }

        .g-b-noaccess {
            color: #ccc;
        }

        .shop_not_released {
            display: none;
        }

        .b_ratings-name {
            line-height: 20px;
            margin-bottom: 28px;
        }

        .likecount {
            vertical-align: middle;
            float: none;
            padding: 3px 4px 2px;
            line-height: 13px;
        }

        .checkbox-rosbank-login {
            padding: 0;
        }

        .checkbox-rosbank-login > *,
        .checkbox-rosbank-login > input[type="checkbox"] {
            float: none;
            vertical-align: middle;
            margin: 0;
        }

        .Quiz-img {
            max-width: 48px;
            float: left;
            margin-right: 15px;
        }

        .Quiz-name {
            font-size: 20px;
            line-height: 20px;
            margin-bottom: 10px;
        }

        .Quiz-control {
            display: flex;
            justify-content: space-between;
            border-top: 1px solid #f3f3f3;
            margin-top: 10px;
            padding-top: 10px;
            font-size: 12px;
        }

        .Quiz-go {
            display: block;
            width: 100%;
            box-sizing: border-box;
            font-size: 12px;
            margin-top: 15px;
        }

        .w-b-quiz > div {
            background: transparent;
            padding: 0;
        }

        .w-b-quiz > div > div {
            padding: 13px;
        }

        .w-b-quiz span.q-b-questionnumber {
            font-size: 12px;
            display: inline-block;
            margin-bottom: 5px;
        }

        .w-b-quiz .q-b-question {
            font-size: 18px;
            margin-bottom: 16px;
        }

        .w-b-quiz .q-b-question p {
            margin-bottom: 10px;
        }

        .w-b-quiz .q-b-answer {
            font-size: 14px;
        }

        .w-b-quiz span.q-b-questionnumber,
        .w-b-quiz .q-b-question,
        .w-b-quiz .q-b-answer {
            line-height: 20px;
        }

        .w-b-quiz .q-b-question img {
            margin: 0 auto;
            display: block;
        }

        .w-b-quiz .q-b-answer label {
            margin-bottom: 10px;
        }

        .w-b-quiz .q-b-button {
            font-size: 12px;
            line-height: 16px;
            padding: 7px 20px !important;
        }

        .s-b-network {
            display: none;
            font-size: x-small;
        }

        .table-employees {
            width: 620px;
        }

        .table-employees th.human,
        .table-employees td.human {
            width: 200px;
        }

        .table-employees th.shef,
        .table-employees td.shef {
            width: 45px;
            padding-left: 0;
            padding-right: 0;
        }

        .table-employees th.shef img {
            float: right;
        }

        .table-employees th.mail,
        .table-employees td.mail {
            width: 100px;
        }

        .table-employees th.phone,
        .table-employees td.phone {
            width: 80px;
        }

        .table-employees th.human img,
        .table-employees td.human img {
            width: 48px;
            height: 48px;
            float: left;
            margin-right: 5px;
            margin-bottom: 30px;
        }

        .table-employees td.human p {
            font-style: italic;
            font-size: 10pt;
        }

        .table-employees td.human h1 {
            font-size: 11pt;
        }

        .simple-player-container {
            display: block;
            -webkit-border-radius: 5px;
            -moz-border-radius: 5px;
            border-radius: 5px;
            background-color: transparent;
        }

        .simple-player-container > div > ul {
            margin: 0;
            padding-left: 0;
        }

        .simple-player-container > div > ul > li > a {
            margin: 0 !important;
            padding: 0;
        }

        .simpleplayer-play-control {
            display: block;
            width: 16px;
            height: 16px;
            top: 3px;
            position: relative;
        }

        .simpleplayer-stop-control {
            display: block;
            width: 16px;
            height: 16px;
            top: 3px;
            position: relative;
        }

        .colorpicker {
            width: 356px;
            height: 176px;
            overflow: hidden;
            position: absolute;
            font-family: Arial, Helvetica, sans-serif;
            display: none;
            z-index: 100007;
        }

        .colorpicker_color {
            width: 150px;
            height: 150px;
            left: 14px;
            top: 13px;
            position: absolute;
            background: red;
            overflow: hidden;
            cursor: crosshair;
        }

        .colorpicker_color div {
            position: absolute;
            top: 0;
            left: 0;
            width: 150px;
            height: 150px;
        }

        .colorpicker_color div div {
            position: absolute;
            top: 0;
            left: 0;
            width: 11px;
            height: 11px;
            overflow: hidden;
            margin: -5px 0 0 -5px;
        }

        .colorpicker_hue {
            position: absolute;
            top: 13px;
            left: 171px;
            width: 35px;
            height: 150px;
            cursor: n-resize;
        }

        .colorpicker_hue div {
            position: absolute;
            width: 35px;
            height: 9px;
            overflow: hidden;
            margin: -4px 0 0 0;
            left: 0;
        }

        .colorpicker_new_color {
            position: absolute;
            width: 60px;
            height: 30px;
            left: 213px;
            top: 13px;
            background: red;
        }

        .colorpicker_current_color {
            position: absolute;
            width: 60px;
            height: 30px;
            left: 283px;
            top: 13px;
            background: red;
        }

        .colorpicker input {
            background-color: transparent;
            border: 1px solid transparent;
            position: absolute;
            font-size: 10px;
            font-family: Arial, Helvetica, sans-serif;
            color: #898989;
            top: 0;
            width: auto;
            text-align: right;
            margin: 0;
            padding: 0;
        }

        .colorpicker_hex {
            position: absolute;
            width: 72px;
            height: 22px;
            left: 212px;
            top: 142px;
        }

        .colorpicker_hex input {
            right: 6px;
        }

        .colorpicker_field {
            height: 22px;
            width: 62px;
            background-position: top;
            position: absolute;
        }

        .colorpicker_field span {
            position: absolute;
            width: 12px;
            height: 22px;
            overflow: hidden;
            top: 0;
            right: 0;
            cursor: n-resize;
        }

        

        .colorpicker_focus {
            background-position: center;
        }

        .colorpicker_hex.colorpicker_focus {
            background-position: bottom;
        }

        .colorpicker_submit.colorpicker_focus {
            background-position: bottom;
        }

        .colorpicker_slider {
            background-position: bottom;
        }

        .editableform {
            margin-bottom: 0;
            z-index: 99999;
        }

        .editableform .control-group {
            margin-bottom: 0;
            white-space: nowrap;
            line-height: 20px;
        }

        .editable-buttons {
            display: inline-block;
            vertical-align: top;
            margin-left: 7px;
            zoom: 1;
            *display: inline;
        }

        .editable-buttons.editable-buttons-bottom {
            display: block;
            margin-top: 7px;
            margin-left: 0;
        }

        .editable-input {
            vertical-align: top;
            display: inline-block;
            width: auto;
            white-space: normal;
            zoom: 1;
            *display: inline;
        }

        .editable-buttons .editable-cancel {
            margin-left: 7px;
        }

        .editable-buttons button.ui-button-icon-only {
            height: 24px;
            width: 30px;
        }

        .editableform-loading {
            height: 25px;
            width: auto;
            min-width: 25px;
        }

        .editable-inline .editableform-loading {
            background-position: left 5px;
        }

        .editable-error-block {
            max-width: 300px;
            margin: 5px 0 0 0;
            width: auto;
            white-space: normal;
        }

        .editable-error-block.ui-state-error {
            padding: 3px;
        }

        .editable-error {
            color: red;
        }

        .editableform .editable-date {
            padding: 0;
            margin: 0;
            float: left;
        }

        .editable-inline .add-on .icon-th {
            margin-top: 3px;
            margin-left: 1px;
        }

        .editable-checklist label input[type="checkbox"],
        .editable-checklist label span {
            vertical-align: middle;
            margin: 0;
        }

        .editable-checklist label {
            white-space: nowrap;
        }

        .editable-wysihtml5 {
            width: 566px;
            height: 250px;
        }

        .editable-clear {
            clear: both;
            font-size: 0.9em;
            text-decoration: none;
            text-align: right;
        }

        .editable-clear-x {
            display: block;
            width: 13px;
            height: 13px;
            position: absolute;
            opacity: 0.6;
            z-index: 10000;
            top: 50%;
            right: 6px;
            margin-top: -6px;
        }

        .editable-clear-x:hover {
            opacity: 1;
        }

        .editable-container.editable-popup {
            max-width: none !important;
        }

        .editable-container.popover {
            width: auto;
            z-index: 99999;
        }

        .editable-container.editable-inline {
            display: inline-block;
            vertical-align: middle;
            width: auto;
            zoom: 1;
            *display: inline;
        }

        .editable-container.ui-widget {
            font-size: inherit;
        }

        .editable-click,
        a.editable-click,
        a.editable-click:hover {
            text-decoration: none;
            border-bottom: dashed 1px #08c;
        }

        .editable-click.editable-disabled,
        a.editable-click.editable-disabled,
        a.editable-click.editable-disabled:hover {
            color: #585858;
            cursor: default;
            border-bottom: none;
        }

        .editable-empty,
        .editable-empty:hover,
        .editable-empty:focus {
            font-style: italic;
            color: #d14;
            text-decoration: none;
        }

        .editable-unsaved {
            font-weight: bold;
        }

        .editable-unsaved:after {
        }

        .editable-bg-transition {
            -webkit-transition: background-color 1400ms ease-out;
            -moz-transition: background-color 1400ms ease-out;
            -o-transition: background-color 1400ms ease-out;
            -ms-transition: background-color 1400ms ease-out;
            transition: background-color 1400ms ease-out;
        }

        .form-horizontal .editable {
            padding-top: 5px;
            display: inline-block;
        }

        .datepicker {
            padding: 4px;
            -webkit-border-radius: 4px;
            -moz-border-radius: 4px;
            border-radius: 4px;
            direction: ltr;
        }

        .datepicker-inline {
            width: 220px;
        }

        .datepicker.datepicker-rtl {
            direction: rtl;
        }

        .datepicker.datepicker-rtl table tr td span {
            float: right;
        }

        .datepicker-dropdown {
            top: 0;
            left: 0;
        }

        .datepicker-dropdown:before {
            content: "";
            display: inline-block;
            border-left: 7px solid transparent;
            border-right: 7px solid transparent;
            border-bottom: 7px solid #ccc;
            border-bottom-color: rgba(0, 0, 0, 0.2);
            position: absolute;
            top: -7px;
            left: 6px;
        }

        .datepicker-dropdown:after {
            content: "";
            display: inline-block;
            border-left: 6px solid transparent;
            border-right: 6px solid transparent;
            border-bottom: 6px solid #fff;
            position: absolute;
            top: -6px;
            left: 7px;
        }

        .datepicker > div {
            display: none;
        }

        .datepicker.days div.datepicker-days {
            display: block;
        }

        .datepicker.months div.datepicker-months {
            display: block;
        }

        .datepicker.years div.datepicker-years {
            display: block;
        }

        .datepicker table {
            margin: 0;
        }

        .datepicker td,
        .datepicker th {
            text-align: center;
            width: 20px;
            height: 20px;
            -webkit-border-radius: 4px;
            -moz-border-radius: 4px;
            border-radius: 4px;
            border: none;
        }

        .table-striped .datepicker table tr td,
        .table-striped .datepicker table tr th {
            background-color: transparent;
        }

        .datepicker table tr td.day:hover {
            background: #eee;
            cursor: pointer;
        }

        .datepicker table tr td.old,
        .datepicker table tr td.new {
            color: #999;
        }

        .datepicker table tr td.disabled,
        .datepicker table tr td.disabled:hover {
            background: none;
            color: #999;
            cursor: default;
        }

        .datepicker table tr td.today,
        .datepicker table tr td.today:hover,
        .datepicker table tr td.today.disabled,
        .datepicker table tr td.today.disabled:hover {
            background-color: #fde19a;
            background-image: -moz-linear-gradient(top, #fdd49a, #fdf59a);
            background-image: -ms-linear-gradient(top, #fdd49a, #fdf59a);
            background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#fdd49a), to(#fdf59a));
            background-image: -webkit-linear-gradient(top, #fdd49a, #fdf59a);
            background-image: -o-linear-gradient(top, #fdd49a, #fdf59a);
            background-image: linear-gradient(top, #fdd49a, #fdf59a);
            background-repeat: repeat-x;
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fdd49a',endColorstr='#fdf59a',GradientType=0);
            border-color: #fdf59a #fdf59a #fbed50;
            border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
            filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
            color: #000;
        }

        .datepicker table tr td.today:hover,
        .datepicker table tr td.today:hover:hover,
        .datepicker table tr td.today.disabled:hover,
        .datepicker table tr td.today.disabled:hover:hover,
        .datepicker table tr td.today:active,
        .datepicker table tr td.today:hover:active,
        .datepicker table tr td.today.disabled:active,
        .datepicker table tr td.today.disabled:hover:active,
        .datepicker table tr td.today.active,
        .datepicker table tr td.today:hover.active,
        .datepicker table tr td.today.disabled.active,
        .datepicker table tr td.today.disabled:hover.active,
        .datepicker table tr td.today.disabled,
        .datepicker table tr td.today:hover.disabled,
        .datepicker table tr td.today.disabled.disabled,
        .datepicker table tr td.today.disabled:hover.disabled,
        .datepicker table tr td.today[disabled],
        .datepicker table tr td.today:hover[disabled],
        .datepicker table tr td.today.disabled[disabled],
        .datepicker table tr td.today.disabled:hover[disabled] {
            background-color: #fdf59a;
        }

        .datepicker table tr td.today:active,
        .datepicker table tr td.today:hover:active,
        .datepicker table tr td.today.disabled:active,
        .datepicker table tr td.today.disabled:hover:active,
        .datepicker table tr td.today.active,
        .datepicker table tr td.today:hover.active,
        .datepicker table tr td.today.disabled.active,
        .datepicker table tr td.today.disabled:hover.active {
            background-color: #fbf069 \9;
        }

        .datepicker table tr td.today:hover:hover {
            color: #000;
        }

        .datepicker table tr td.today.active:hover {
            color: #fff;
        }

        .datepicker table tr td.range,
        .datepicker table tr td.range:hover,
        .datepicker table tr td.range.disabled,
        .datepicker table tr td.range.disabled:hover {
            background: #eee;
            -webkit-border-radius: 0;
            -moz-border-radius: 0;
            border-radius: 0;
        }

        .datepicker table tr td.range.today,
        .datepicker table tr td.range.today:hover,
        .datepicker table tr td.range.today.disabled,
        .datepicker table tr td.range.today.disabled:hover {
            background-color: #f3d17a;
            background-image: -moz-linear-gradient(top, #f3c17a, #f3e97a);
            background-image: -ms-linear-gradient(top, #f3c17a, #f3e97a);
            background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#f3c17a), to(#f3e97a));
            background-image: -webkit-linear-gradient(top, #f3c17a, #f3e97a);
            background-image: -o-linear-gradient(top, #f3c17a, #f3e97a);
            background-image: linear-gradient(top, #f3c17a, #f3e97a);
            background-repeat: repeat-x;
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f3c17a',endColorstr='#f3e97a',GradientType=0);
            border-color: #f3e97a #f3e97a #edde34;
            border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
            filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
            -webkit-border-radius: 0;
            -moz-border-radius: 0;
            border-radius: 0;
        }

        .datepicker table tr td.range.today:hover,
        .datepicker table tr td.range.today:hover:hover,
        .datepicker table tr td.range.today.disabled:hover,
        .datepicker table tr td.range.today.disabled:hover:hover,
        .datepicker table tr td.range.today:active,
        .datepicker table tr td.range.today:hover:active,
        .datepicker table tr td.range.today.disabled:active,
        .datepicker table tr td.range.today.disabled:hover:active,
        .datepicker table tr td.range.today.active,
        .datepicker table tr td.range.today:hover.active,
        .datepicker table tr td.range.today.disabled.active,
        .datepicker table tr td.range.today.disabled:hover.active,
        .datepicker table tr td.range.today.disabled,
        .datepicker table tr td.range.today:hover.disabled,
        .datepicker table tr td.range.today.disabled.disabled,
        .datepicker table tr td.range.today.disabled:hover.disabled,
        .datepicker table tr td.range.today[disabled],
        .datepicker table tr td.range.today:hover[disabled],
        .datepicker table tr td.range.today.disabled[disabled],
        .datepicker table tr td.range.today.disabled:hover[disabled] {
            background-color: #f3e97a;
        }

        .datepicker table tr td.range.today:active,
        .datepicker table tr td.range.today:hover:active,
        .datepicker table tr td.range.today.disabled:active,
        .datepicker table tr td.range.today.disabled:hover:active,
        .datepicker table tr td.range.today.active,
        .datepicker table tr td.range.today:hover.active,
        .datepicker table tr td.range.today.disabled.active,
        .datepicker table tr td.range.today.disabled:hover.active {
            background-color: #efe24b \9;
        }

        .datepicker table tr td.selected,
        .datepicker table tr td.selected:hover,
        .datepicker table tr td.selected.disabled,
        .datepicker table tr td.selected.disabled:hover {
            background-color: #9e9e9e;
            background-image: -moz-linear-gradient(top, #b3b3b3, gray);
            background-image: -ms-linear-gradient(top, #b3b3b3, gray);
            background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#b3b3b3), to(gray));
            background-image: -webkit-linear-gradient(top, #b3b3b3, gray);
            background-image: -o-linear-gradient(top, #b3b3b3, gray);
            background-image: linear-gradient(top, #b3b3b3, gray);
            background-repeat: repeat-x;
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#b3b3b3',endColorstr='#808080',GradientType=0);
            border-color: gray gray #595959;
            border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
            filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
            color: #fff;
            text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
        }

        .datepicker table tr td.selected:hover,
        .datepicker table tr td.selected:hover:hover,
        .datepicker table tr td.selected.disabled:hover,
        .datepicker table tr td.selected.disabled:hover:hover,
        .datepicker table tr td.selected:active,
        .datepicker table tr td.selected:hover:active,
        .datepicker table tr td.selected.disabled:active,
        .datepicker table tr td.selected.disabled:hover:active,
        .datepicker table tr td.selected.active,
        .datepicker table tr td.selected:hover.active,
        .datepicker table tr td.selected.disabled.active,
        .datepicker table tr td.selected.disabled:hover.active,
        .datepicker table tr td.selected.disabled,
        .datepicker table tr td.selected:hover.disabled,
        .datepicker table tr td.selected.disabled.disabled,
        .datepicker table tr td.selected.disabled:hover.disabled,
        .datepicker table tr td.selected[disabled],
        .datepicker table tr td.selected:hover[disabled],
        .datepicker table tr td.selected.disabled[disabled],
        .datepicker table tr td.selected.disabled:hover[disabled] {
            background-color: gray;
        }

        .datepicker table tr td.selected:active,
        .datepicker table tr td.selected:hover:active,
        .datepicker table tr td.selected.disabled:active,
        .datepicker table tr td.selected.disabled:hover:active,
        .datepicker table tr td.selected.active,
        .datepicker table tr td.selected:hover.active,
        .datepicker table tr td.selected.disabled.active,
        .datepicker table tr td.selected.disabled:hover.active {
            background-color: #666 \9;
        }

        .datepicker table tr td.active,
        .datepicker table tr td.active:hover,
        .datepicker table tr td.active.disabled,
        .datepicker table tr td.active.disabled:hover {
            background-color: #006dcc;
            background-image: -moz-linear-gradient(top, #08c, #04c);
            background-image: -ms-linear-gradient(top, #08c, #04c);
            background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#08c), to(#04c));
            background-image: -webkit-linear-gradient(top, #08c, #04c);
            background-image: -o-linear-gradient(top, #08c, #04c);
            background-image: linear-gradient(top, #08c, #04c);
            background-repeat: repeat-x;
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#0088cc',endColorstr='#0044cc',GradientType=0);
            border-color: #04c #04c #002a80;
            border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
            filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
            color: #fff;
            text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
        }

        .datepicker table tr td.active:hover,
        .datepicker table tr td.active:hover:hover,
        .datepicker table tr td.active.disabled:hover,
        .datepicker table tr td.active.disabled:hover:hover,
        .datepicker table tr td.active:active,
        .datepicker table tr td.active:hover:active,
        .datepicker table tr td.active.disabled:active,
        .datepicker table tr td.active.disabled:hover:active,
        .datepicker table tr td.active.active,
        .datepicker table tr td.active:hover.active,
        .datepicker table tr td.active.disabled.active,
        .datepicker table tr td.active.disabled:hover.active,
        .datepicker table tr td.active.disabled,
        .datepicker table tr td.active:hover.disabled,
        .datepicker table tr td.active.disabled.disabled,
        .datepicker table tr td.active.disabled:hover.disabled,
        .datepicker table tr td.active[disabled],
        .datepicker table tr td.active:hover[disabled],
        .datepicker table tr td.active.disabled[disabled],
        .datepicker table tr td.active.disabled:hover[disabled] {
            background-color: #04c;
        }

        .datepicker table tr td.active:active,
        .datepicker table tr td.active:hover:active,
        .datepicker table tr td.active.disabled:active,
        .datepicker table tr td.active.disabled:hover:active,
        .datepicker table tr td.active.active,
        .datepicker table tr td.active:hover.active,
        .datepicker table tr td.active.disabled.active,
        .datepicker table tr td.active.disabled:hover.active {
            background-color: #039 \9;
        }

        .datepicker table tr td span {
            display: block;
            width: 23%;
            height: 54px;
            line-height: 54px;
            float: left;
            margin: 1%;
            cursor: pointer;
            -webkit-border-radius: 4px;
            -moz-border-radius: 4px;
            border-radius: 4px;
        }

        .datepicker table tr td span:hover {
            background: #eee;
        }

        .datepicker table tr td span.disabled,
        .datepicker table tr td span.disabled:hover {
            background: none;
            color: #999;
            cursor: default;
        }

        .datepicker table tr td span.active,
        .datepicker table tr td span.active:hover,
        .datepicker table tr td span.active.disabled,
        .datepicker table tr td span.active.disabled:hover {
            background-color: #006dcc;
            background-image: -moz-linear-gradient(top, #08c, #04c);
            background-image: -ms-linear-gradient(top, #08c, #04c);
            background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#08c), to(#04c));
            background-image: -webkit-linear-gradient(top, #08c, #04c);
            background-image: -o-linear-gradient(top, #08c, #04c);
            background-image: linear-gradient(top, #08c, #04c);
            background-repeat: repeat-x;
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#0088cc',endColorstr='#0044cc',GradientType=0);
            border-color: #04c #04c #002a80;
            border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
            filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
            color: #fff;
            text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
        }

        .datepicker table tr td span.active:hover,
        .datepicker table tr td span.active:hover:hover,
        .datepicker table tr td span.active.disabled:hover,
        .datepicker table tr td span.active.disabled:hover:hover,
        .datepicker table tr td span.active:active,
        .datepicker table tr td span.active:hover:active,
        .datepicker table tr td span.active.disabled:active,
        .datepicker table tr td span.active.disabled:hover:active,
        .datepicker table tr td span.active.active,
        .datepicker table tr td span.active:hover.active,
        .datepicker table tr td span.active.disabled.active,
        .datepicker table tr td span.active.disabled:hover.active,
        .datepicker table tr td span.active.disabled,
        .datepicker table tr td span.active:hover.disabled,
        .datepicker table tr td span.active.disabled.disabled,
        .datepicker table tr td span.active.disabled:hover.disabled,
        .datepicker table tr td span.active[disabled],
        .datepicker table tr td span.active:hover[disabled],
        .datepicker table tr td span.active.disabled[disabled],
        .datepicker table tr td span.active.disabled:hover[disabled] {
            background-color: #04c;
        }

        .datepicker table tr td span.active:active,
        .datepicker table tr td span.active:hover:active,
        .datepicker table tr td span.active.disabled:active,
        .datepicker table tr td span.active.disabled:hover:active,
        .datepicker table tr td span.active.active,
        .datepicker table tr td span.active:hover.active,
        .datepicker table tr td span.active.disabled.active,
        .datepicker table tr td span.active.disabled:hover.active {
            background-color: #039 \9;
        }

        .datepicker table tr td span.old,
        .datepicker table tr td span.new {
            color: #999;
        }

        .datepicker th.datepicker-switch {
            width: 145px;
        }

        .datepicker thead tr:first-child th,
        .datepicker tfoot tr th {
            cursor: pointer;
        }

        .datepicker thead tr:first-child th:hover,
        .datepicker tfoot tr th:hover {
            background: #eee;
        }

        .datepicker .cw {
            font-size: 10px;
            width: 12px;
            padding: 0 2px 0 5px;
            vertical-align: middle;
        }

        .datepicker thead tr:first-child th.cw {
            cursor: default;
            background-color: transparent;
        }

        .input-append.date .add-on i,
        .input-prepend.date .add-on i {
            display: block;
            cursor: pointer;
            width: 16px;
            height: 16px;
        }

        .input-daterange input {
            text-align: center;
        }

        .input-daterange input:first-child {
            -webkit-border-radius: 3px 0 0 3px;
            -moz-border-radius: 3px 0 0 3px;
            border-radius: 3px 0 0 3px;
        }

        .input-daterange input:last-child {
            -webkit-border-radius: 0 3px 3px 0;
            -moz-border-radius: 0 3px 3px 0;
            border-radius: 0 3px 3px 0;
        }

        .input-daterange .add-on {
            display: inline-block;
            width: auto;
            min-width: 16px;
            height: 18px;
            padding: 4px 5px;
            font-weight: normal;
            line-height: 18px;
            text-align: center;
            text-shadow: 0 1px 0 #fff;
            vertical-align: middle;
            background-color: #eee;
            border: 1px solid #ccc;
            margin-left: -5px;
            margin-right: -5px;
        }

        .he-wrap {
            position: relative;
            zoom: 1;
            position: relative;
        }

        .he-view,
        .he-zoom {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            overflow: hidden;
            visibility: hidden;
        }

        .he-zoom img {
            position: absolute;
            top: 0;
            left: 0;
        }

        .he-zoomstate {
            position: absolute;
            bottom: 0;
            right: 0;
            background: #c00;
            color: #fff;
            border-radius: 3px 0 0 0;
            font-size: 12px;
            line-height: 16px;
            padding: 0 4px;
            box-shadow: -1px -1px 1px rgba(204, 0, 0, 0.3);
        }

        .he-view-show {
            visibility: visible;
        }

        .he-sliders {
            overflow: hidden;
        }

        .he-sliders img {
            display: none;
        }

        .he-sliders img:first-child {
            display: inline-block;
            *display: inline;
            *zoom: 1;
        }

        .he-pre,
        .he-next {
            position: absolute;
            top: 50%;
            left: 0;
            width: 61px;
            height: 35px;
            margin-top: -18px;
            cursor: pointer;
        }

        .he-next {
            left: auto;
            right: 0;
            background-position: top right;
        }

        .a0,
        .a1,
        .a2,
        .a3,
        .a4,
        .a5,
        .a6,
        .a7 {
            -webkit-animation-fill-mode: both;
            -moz-animation-fill-mode: both;
            -ms-animation-fill-mode: both;
            -o-animation-fill-mode: both;
            animation-fill-mode: both;
            -webkit-animation-duration: 0.6s;
            -moz-animation-duration: 0.6s;
            -ms-animation-duration: 0.6s;
            -o-animation-duration: 0.6s;
            animation-duration: 0.6s;
        }

        .a1 {
            -webkit-animation-delay: 0.2s;
            -moz-animation-delay: 0.2s;
            -o-animation-delay: 0.2s;
            animation-delay: 0.2s;
        }

        .a2 {
            -webkit-animation-delay: 0.4s;
            -moz-animation-delay: 0.4s;
            -o-animation-delay: 0.4s;
            animation-delay: 0.4s;
        }

        .a3 {
            -webkit-animation-delay: 0.6s;
            -moz-animation-delay: 0.6s;
            -o-animation-delay: 0.6s;
            animation-delay: 0.6s;
        }

        .a4 {
            -webkit-animation-delay: 0.8s;
            -moz-animation-delay: 0.8s;
            -o-animation-delay: 0.8s;
            animation-delay: 0.8s;
        }

        .a5 {
            -webkit-animation-delay: 1s;
            -moz-animation-delay: 1s;
            -o-animation-delay: 1s;
            animation-delay: 1s;
        }

        .a6 {
            -webkit-animation-delay: 1.2s;
            -moz-animation-delay: 1.2s;
            -o-animation-delay: 1.2s;
            animation-delay: 1.2s;
        }

        .a7 {
            -webkit-animation-delay: 1.4s;
            -moz-animation-delay: 1.4s;
            -o-animation-delay: 1.4s;
            animation-delay: 1.4s;
        }

        @-webkit-keyframes flipInLeft {
            0% {
                -webkit-transform: perspective(400px) rotateY(90deg);
                opacity: 0.5;
            }

            60% {
                -webkit-transform: perspective(400px) rotateY(-10deg);
            }

            80% {
                -webkit-transform: perspective(400px) rotateY(5deg);
            }

            100% {
                -webkit-transform: perspective(400px) rotateY(0deg);
                opacity: 1;
            }
        }

        @-moz-keyframes flipInLeft {
            0% {
                -moz-transform: perspective(400px) rotateY(90deg);
                opacity: 0.5;
            }

            60% {
                -moz-transform: perspective(400px) rotateY(-10deg);
            }

            80% {
                -moz-transform: perspective(400px) rotateY(5deg);
            }

            100% {
                -moz-transform: perspective(400px) rotateY(0deg);
                opacity: 1;
            }
        }

        @-o-keyframes flipInLeft {
            0% {
                -o-transform: perspective(400px) rotateY(90deg);
                opacity: 0.5;
            }

            60% {
                -o-transform: perspective(400px) rotateY(-10deg);
            }

            80% {
                -o-transform: perspective(400px) rotateY(5deg);
            }

            100% {
                -o-transform: perspective(400px) rotateY(0deg);
                opacity: 1;
            }
        }

        @keyframes flipInLeft {
            0% {
                transform: perspective(400px) rotateY(90deg);
                opacity: 0.5;
            }

            60% {
                transform: perspective(400px) rotateY(-10deg);
            }

            80% {
                transform: perspective(400px) rotateY(5deg);
            }

            100% {
                transform: perspective(400px) rotateY(0deg);
                opacity: 1;
            }
        }

        .flipInLeft {
            -webkit-transform-style: preserve-3d;
            -webkit-animation-name: flipInLeft;
            -webkit-transform-origin: 0 50%;
            -moz-transform-style: preserve-3d;
            -moz-animation-name: flipInLeft;
            -moz-transform-origin: 0 50%;
            -o-transform-style: preserve-3d;
            -o-animation-name: flipInLeft;
            -o-transform-origin: 0 50%;
            transform-style: preserve-3d;
            animation-name: flipInLeft;
            transform-origin: 0 50%;
        }

        @-webkit-keyframes flipInRight {
            0% {
                -webkit-transform: perspective(400px) rotateY(-90deg);
                opacity: 0.5;
            }

            60% {
                -webkit-transform: perspective(400px) rotateY(10deg);
            }

            80% {
                -webkit-transform: perspective(400px) rotateY(-5deg);
            }

            100% {
                -webkit-transform: perspective(400px) rotateY(0deg);
                opacity: 1;
            }
        }

        @-moz-keyframes flipInRight {
            0% {
                -moz-transform: perspective(400px) rotateY(-90deg);
                opacity: 0.5;
            }

            60% {
                -moz-transform: perspective(400px) rotateY(10deg);
            }

            80% {
                -moz-transform: perspective(400px) rotateY(-5deg);
            }

            100% {
                -moz-transform: perspective(400px) rotateY(0deg);
                opacity: 1;
            }
        }

        @-o-keyframes flipInRight {
            0% {
                -o-transform: perspective(400px) rotateY(-90deg);
                opacity: 0.5;
            }

            60% {
                -o-transform: perspective(400px) rotateY(10deg);
            }

            80% {
                -o-transform: perspective(400px) rotateY(-5deg);
            }

            100% {
                -o-transform: perspective(400px) rotateY(0deg);
                opacity: 1;
            }
        }

        @keyframes flipInRight {
            0% {
                transform: perspective(400px) rotateY(-90deg);
                opacity: 0.5;
            }

            60% {
                transform: perspective(400px) rotateY(10deg);
            }

            80% {
                transform: perspective(400px) rotateY(-5deg);
            }

            100% {
                transform: perspective(400px) rotateY(0deg);
                opacity: 1;
            }
        }

        .flipInRight {
            -webkit-transform-style: preserve-3d;
            -webkit-animation-name: flipInRight;
            -webkit-transform-origin: 100% 50%;
            -moz-transform-style: preserve-3d;
            -moz-animation-name: flipInRight;
            -moz-transform-origin: 100% 50%;
            -o-transform-style: preserve-3d;
            -o-animation-name: flipInRight;
            -o-transform-origin: 100% 50%;
            transform-style: preserve-3d;
            animation-name: flipInRight;
            transform-origin: 100% 50%;
        }

        @-webkit-keyframes flipInDown {
            0% {
                -webkit-transform: perspective(400px) rotateX(-90deg);
                opacity: 0.5;
            }

            60% {
                -webkit-transform: perspective(400px) rotateX(10deg);
            }

            80% {
                -webkit-transform: perspective(400px) rotateX(-5deg);
            }

            100% {
                -webkit-transform: perspective(400px) rotateX(0deg);
                opacity: 1;
            }
        }

        @-moz-keyframes flipInDown {
            0% {
                -moz-transform: perspective(400px) rotateX(-90deg);
                opacity: 0.5;
            }

            60% {
                -moz-transform: perspective(400px) rotateX(10deg);
            }

            80% {
                -moz-transform: perspective(400px) rotateX(-5deg);
            }

            100% {
                -moz-transform: perspective(400px) rotateX(0deg);
                opacity: 1;
            }
        }

        @-o-keyframes flipInDown {
            0% {
                -o-transform: perspective(400px) rotateX(-90deg);
                opacity: 0.5;
            }

            60% {
                -o-transform: perspective(400px) rotateX(10deg);
            }

            80% {
                -o-transform: perspective(400px) rotateX(-5deg);
            }

            100% {
                -o-transform: perspective(400px) rotateX(0deg);
                opacity: 1;
            }
        }

        @keyframes flipInDown {
            0% {
                transform: perspective(400px) rotateX(-90deg);
                opacity: 0.5;
            }

            60% {
                transform: perspective(400px) rotateX(10deg);
            }

            80% {
                transform: perspective(400px) rotateX(-5deg);
            }

            100% {
                transform: perspective(400px) rotateX(0deg);
                opacity: 1;
            }
        }

        .flipInDown {
            -webkit-transform-style: preserve-3d;
            -webkit-animation-name: flipInDown;
            -webkit-transform-origin: 50% 0%;
            -moz-transform-style: preserve-3d;
            -moz-animation-name: flipInDown;
            -moz-transform-origin: 50% 0%;
            -o-transform-style: preserve-3d;
            -o-animation-name: flipInDown;
            -o-transform-origin: 50% 0%;
            transform-style: preserve-3d;
            animation-name: flipInDown;
            transform-origin: 50% 0%;
        }

        @-webkit-keyframes flipInUp {
            0% {
                -webkit-transform: perspective(300px) rotateX(90deg);
                opacity: 0.5;
            }

            60% {
                -webkit-transform: perspective(300px) rotateX(-10deg);
            }

            80% {
                -webkit-transform: perspective(300px) rotateX(5deg);
            }

            100% {
                -webkit-transform: perspective(300px) rotateX(0deg);
                opacity: 1;
            }
        }

        @-webkit-keyframes flipInUp {
            0% {
                -webkit-transform: perspective(300px) rotateX(90deg);
                opacity: 0.5;
            }

            60% {
                -webkit-transform: perspective(300px) rotateX(-10deg);
            }

            80% {
                -webkit-transform: perspective(300px) rotateX(5deg);
            }

            100% {
                -webkit-transform: perspective(300px) rotateX(0deg);
                opacity: 1;
            }
        }

        @-moz-keyframes flipInUp {
            0% {
                -moz-transform: perspective(300px) rotateX(90deg);
                opacity: 0.5;
            }

            60% {
                -moz-transform: perspective(300px) rotateX(-10deg);
            }

            80% {
                -moz-transform: perspective(300px) rotateX(5deg);
            }

            100% {
                -moz-transform: perspective(300px) rotateX(0deg);
                opacity: 1;
            }
        }

        @-o-keyframes flipInUp {
            0% {
                -o-transform: perspective(300px) rotateX(90deg);
                opacity: 0.5;
            }

            60% {
                -o-transform: perspective(300px) rotateX(-10deg);
            }

            80% {
                -o-transform: perspective(300px) rotateX(5deg);
            }

            100% {
                -o-transform: perspective(300px) rotateX(0deg);
                opacity: 1;
            }
        }

        @keyframes flipInUp {
            0% {
                transform: perspective(300px) rotateX(90deg);
                opacity: 0.5;
            }

            60% {
                transform: perspective(300px) rotateX(-10deg);
            }

            80% {
                transform: perspective(300px) rotateX(5deg);
            }

            100% {
                transform: perspective(300px) rotateX(0deg);
                opacity: 1;
            }
        }

        .flipInUp {
            -webkit-transform-style: preserve-3d;
            -webkit-animation-name: flipInUp;
            -webkit-transform-origin: 50% 100%;
            -moz-transform-style: preserve-3d;
            -moz-animation-name: flipInUp;
            -moz-transform-origin: 50% 100%;
            -o-transform-style: preserve-3d;
            -o-animation-name: flipInUp;
            -o-transform-origin: 50% 100%;
            transform-style: preserve-3d;
            animation-name: flipInUp;
            transform-origin: 50% 100%;
        }

        .flipInV,
        flipInH {
            -webkit-transform-style: preserve-3d;
            -webkit-animation-name: flipInDown;
            -webkit-transform-origin: 50% 50%;
            -moz-transform-style: preserve-3d;
            -moz-animation-name: flipInDown;
            -moz-transform-origin: 50% 50%;
            -o-transform-style: preserve-3d;
            -o-animation-name: flipInDown;
            -o-transform-origin: 50% 50%;
            transform-style: preserve-3d;
            animation-name: flipInDown;
            transform-origin: 50% 50%;
        }

        .flipInH {
            -webkit-animation-name: flipInLeft;
            -moz-animation-name: flipInLeft;
            -o-animation-name: flipInLeft;
            animation-name: flipInLeft;
        }

        @-webkit-keyframes zoomIn {
            0% {
                opacity: 0;
                -webkit-transform: scale(0.3);
            }

            50% {
                opacity: 1;
                -webkit-transform: scale(1.05);
            }

            70% {
                -webkit-transform: scale(0.9);
            }

            100% {
                -webkit-transform: scale(1);
            }
        }

        @-moz-keyframes zoomIn {
            0% {
                opacity: 0;
                -moz-transform: scale(0.3);
            }

            50% {
                opacity: 1;
                -moz-transform: scale(1.05);
            }

            70% {
                -moz-transform: scale(0.9);
            }

            100% {
                -moz-transform: scale(1);
            }
        }

        @-o-keyframes zoomIn {
            0% {
                opacity: 0;
                -o-transform: scale(0.3);
            }

            50% {
                opacity: 1;
                -o-transform: scale(1.05);
            }

            70% {
                -o-transform: scale(0.9);
            }

            100% {
                -o-transform: scale(1);
            }
        }

        @keyframes zoomIn {
            0% {
                opacity: 0;
                transform: scale(0.3);
            }

            50% {
                opacity: 1;
                transform: scale(1.05);
            }

            70% {
                transform: scale(0.9);
            }

            100% {
                transform: scale(1);
            }
        }

        .zoomIn {
            -webkit-animation-name: zoomIn;
            -moz-animation-name: zoomIn;
            -o-animation-name: zoomIn;
            animation-name: zoomIn;
        }

        @-webkit-keyframes zoomInLeft {
            0% {
                opacity: 0.3;
                -webkit-transform: scale(0.4) translateX(-1000px);
            }

            70% {
                opacity: 1;
                -webkit-transform: scale(1.05);
            }

            90% {
                -webkit-transform: scale(0.95) translateX(0);
            }

            100% {
                -webkit-transform: scale(1);
            }
        }

        @-moz-keyframes zoomInLeft {
            0% {
                opacity: 0.3;
                -moz-transform: scale(0.4) translateX(-1000px);
            }

            70% {
                opacity: 1;
                -moz-transform: scale(1.05);
            }

            90% {
                -moz-transform: scale(0.95) translateX(0);
            }

            100% {
                -moz-transform: scale(1);
            }
        }

        @-o-keyframes zoomInLeft {
            0% {
                opacity: 0.3;
                -o-transform: scale(0.4) translateX(-1000px);
            }

            70% {
                opacity: 1;
                -o-transform: scale(1.05);
            }

            90% {
                -o-transform: scale(0.95) translateX(0);
            }

            100% {
                -o-transform: scale(1);
            }
        }

        @keyframes zoomInLeft {
            0% {
                opacity: 0.3;
                transform: scale(0.4) translateX(-1000px);
            }

            70% {
                opacity: 1;
                transform: scale(1.05);
            }

            90% {
                transform: scale(0.95) translateX(0);
            }

            100% {
                transform: scale(1);
            }
        }

        .zoomInLeft {
            -webkit-animation-name: zoomInLeft;
            -moz-animation-name: zoomInLeft;
            -o-animation-name: zoomInLeft;
            animation-name: zoomInLeft;
        }

        @-webkit-keyframes zoomInRight {
            0% {
                opacity: 0.3;
                -webkit-transform: scale(0.4) translateX(1000px);
            }

            70% {
                opacity: 1;
                -webkit-transform: scale(1.05);
            }

            90% {
                -webkit-transform: scale(0.95) translateX(0);
            }

            100% {
                -webkit-transform: scale(1);
            }
        }

        @-moz-keyframes zoomInRight {
            0% {
                opacity: 0.3;
                -moz-transform: scale(0.4) translateX(1000px);
            }

            70% {
                opacity: 1;
                -moz-transform: scale(1.05);
            }

            90% {
                -moz-transform: scale(0.95) translateX(0);
            }

            100% {
                -moz-transform: scale(1);
            }
        }

        @-o-keyframes zoomInRight {
            0% {
                opacity: 0.3;
                -o-transform: scale(0.4) translateX(1000px);
            }

            70% {
                opacity: 1;
                -o-transform: scale(1.05);
            }

            90% {
                -o-transform: scale(0.95) translateX(0);
            }

            100% {
                -o-transform: scale(1);
            }
        }

        @keyframes zoomInRight {
            0% {
                opacity: 0.3;
                transform: scale(0.4) translateX(1000px);
            }

            70% {
                opacity: 1;
                transform: scale(1.05);
            }

            90% {
                transform: scale(0.95) translateX(0);
            }

            100% {
                transform: scale(1);
            }
        }

        .zoomInRight {
            -webkit-animation-name: zoomInRight;
            -moz-animation-name: zoomInRight;
            -o-animation-name: zoomInRight;
            animation-name: zoomInRight;
        }

        @-webkit-keyframes zoomInUp {
            0% {
                opacity: 0.3;
                -webkit-transform: scale(0.4) translateY(-1000px);
            }

            70% {
                opacity: 1;
                -webkit-transform: scale(1.05);
            }

            90% {
                -webkit-transform: scale(0.95) translateY(0);
            }

            100% {
                -webkit-transform: scale(1);
            }
        }

        @-moz-keyframes zoomInUp {
            0% {
                opacity: 0.3;
                -moz-transform: scale(0.4) translateY(-1000px);
            }

            70% {
                opacity: 1;
                -moz-transform: scale(1.05);
            }

            90% {
                -moz-transform: scale(0.95) translateY(0);
            }

            100% {
                -moz-transform: scale(1);
            }
        }

        @-o-keyframes zoomInUp {
            0% {
                opacity: 0.3;
                -o-transform: scale(0.4) translateY(-1000px);
            }

            70% {
                opacity: 1;
                -o-transform: scale(1.05);
            }

            90% {
                -o-transform: scale(0.95) translateY(0);
            }

            100% {
                -o-transform: scale(1);
            }
        }

        @keyframes zoomInUp {
            0% {
                opacity: 0.3;
                transform: scale(0.4) translateY(-1000px);
            }

            70% {
                opacity: 1;
                transform: scale(1.05);
            }

            90% {
                transform: scale(0.95) translateY(0);
            }

            100% {
                transform: scale(1);
            }
        }

        .zoomInUp {
            -webkit-animation-name: zoomInUp;
            -moz-animation-name: zoomInUp;
            -o-animation-name: zoomInUp;
            animation-name: zoomInUp;
        }

        @-webkit-keyframes zoomInDown {
            0% {
                opacity: 0.3;
                -webkit-transform: scale(0.4) translateY(1000px);
            }

            70% {
                opacity: 1;
                -webkit-transform: scale(1.05);
            }

            90% {
                -webkit-transform: scale(0.95) translateY(0);
            }

            100% {
                -webkit-transform: scale(1);
            }
        }

        @-moz-keyframes zoomInDown {
            0% {
                opacity: 0.3;
                -moz-transform: scale(0.4) translateY(1000px);
            }

            70% {
                opacity: 1;
                -moz-transform: scale(1.05);
            }

            90% {
                -moz-transform: scale(0.95) translateY(0);
            }

            100% {
                -moz-transform: scale(1);
            }
        }

        @-o-keyframes zoomInDown {
            0% {
                opacity: 0.3;
                -o-transform: scale(0.4) translateY(1000px);
            }

            70% {
                opacity: 1;
                -o-transform: scale(1.05);
            }

            90% {
                -o-transform: scale(0.95) translateY(0);
            }

            100% {
                -o-transform: scale(1);
            }
        }

        @keyframes zoomInDown {
            0% {
                opacity: 0.3;
                transform: scale(0.4) translateY(1000px);
            }

            70% {
                opacity: 1;
                transform: scale(1.05);
            }

            90% {
                transform: scale(0.95) translateY(0);
            }

            100% {
                transform: scale(1);
            }
        }

        .zoomInDown {
            -webkit-animation-name: zoomInDown;
            -moz-animation-name: zoomInDown;
            -o-animation-name: zoomInDown;
            animation-name: zoomInDown;
        }

        @-webkit-keyframes fadeIn {
            0% {
                opacity: 0;
            }

            100% {
                opacity: 1;
            }
        }

        @-moz-keyframes fadeIn {
            0% {
                opacity: 0;
            }

            100% {
                opacity: 1;
            }
        }

        @-o-keyframes fadeIn {
            0% {
                opacity: 0;
            }

            100% {
                opacity: 1;
            }
        }

        @keyframes fadeIn {
            0% {
                opacity: 0;
            }

            100% {
                opacity: 1;
            }
        }

        .fadeIn {
            -webkit-animation-name: fadeIn;
            -moz-animation-name: fadeIn;
            -o-animation-name: fadeIn;
            animation-name: fadeIn;
        }

        @-webkit-keyframes fadeInLeft {
            0% {
                opacity: 0;
                -webkit-transform: translateX(-100%);
            }

            100% {
                opacity: 1;
                -webkit-transform: translateX(0%);
            }
        }

        @-moz-keyframes fadeInLeft {
            0% {
                opacity: 0;
                -moz-transform: translateX(-100%);
            }

            100% {
                opacity: 1;
                -moz-transform: translateX(0%);
            }
        }

        @-o-keyframes fadeInLeft {
            0% {
                opacity: 0;
                -o-transform: translateX(-100%);
            }

            100% {
                opacity: 1;
                -o-transform: translateX(0%);
            }
        }

        @keyframes fadeInLeft {
            0% {
                opacity: 0;
                transform: translateX(-100%);
            }

            100% {
                opacity: 1;
                transform: translateX(0%);
            }
        }

        .fadeInLeft {
            -webkit-animation-name: fadeInLeft;
            -moz-animation-name: fadeInLeft;
            -o-animation-name: fadeInLeft;
            animation-name: fadeInLeft;
        }

        @-webkit-keyframes fadeInRight {
            0% {
                opacity: 0;
                -webkit-transform: translateX(100%);
            }

            100% {
                opacity: 1;
                -webkit-transform: translateX(0%);
            }
        }

        @-moz-keyframes fadeInRight {
            0% {
                opacity: 0;
                -moz-transform: translateX(100%);
            }

            100% {
                opacity: 1;
                -moz-transform: translateX(0%);
            }
        }

        @-o-keyframes fadeInRight {
            0% {
                opacity: 0;
                -o-transform: translateX(100%);
            }

            100% {
                opacity: 1;
                -o-transform: translateX(0%);
            }
        }

        @keyframes fadeInRight {
            0% {
                opacity: 0;
                transform: translateX(100%);
            }

            100% {
                opacity: 1;
                transform: translateX(0%);
            }
        }

        .fadeInRight {
            -webkit-animation-name: fadeInRight;
            -moz-animation-name: fadeInRight;
            -o-animation-name: fadeInRight;
            animation-name: fadeInRight;
        }

        @-webkit-keyframes fadeInUp {
            0% {
                opacity: 0;
                -webkit-transform: translateY(100%);
            }

            100% {
                opacity: 1;
                -webkit-transform: translateY(0%);
            }
        }

        @-moz-keyframes fadeInUp {
            0% {
                opacity: 0;
                -moz-transform: translateY(100%);
            }

            100% {
                opacity: 1;
                -moz-transform: translateY(0%);
            }
        }

        @-o-keyframes fadeInUp {
            0% {
                opacity: 0;
                -o-transform: translateY(100%);
            }

            100% {
                opacity: 1;
                -o-transform: translateY(0%);
            }
        }

        @keyframes fadeInUp {
            0% {
                opacity: 0;
                transform: translateY(100%);
            }

            100% {
                opacity: 1;
                transform: translateY(0%);
            }
        }

        .fadeInUp {
            -webkit-animation-name: fadeInUp;
            -moz-animation-name: fadeInUp;
            -o-animation-name: fadeInUp;
            animation-name: fadeInUp;
        }

        @-webkit-keyframes fadeInDown {
            0% {
                opacity: 0;
                -webkit-transform: translateY(-100%);
            }

            100% {
                opacity: 1;
                -webkit-transform: translateY(0%);
            }
        }

        @-moz-keyframes fadeInDown {
            0% {
                opacity: 0;
                -moz-transform: translateY(-100%);
            }

            100% {
                opacity: 1;
                -moz-transform: translateY(0%);
            }
        }

        @-o-keyframes fadeInDown {
            0% {
                opacity: 0;
                -o-transform: translateY(-100%);
            }

            100% {
                opacity: 1;
                -o-transform: translateY(0%);
            }
        }

        @keyframes fadeInDown {
            0% {
                opacity: 0;
                transform: translateY(-100%);
            }

            100% {
                opacity: 1;
                transform: translateY(0%);
            }
        }

        .fadeInDown {
            -webkit-animation-name: fadeInDown;
            -moz-animation-name: fadeInDown;
            -o-animation-name: fadeInDown;
            animation-name: fadeInDown;
        }

        @-webkit-keyframes rotateIn {
            0% {
                opacity: 0;
                -webkit-transform: rotate(360deg) scale(0.1);
            }

            100% {
                opacity: 1;
                -webkit-transform: rotate(0deg) scale(1);
            }
        }

        @-moz-keyframes rotateIn {
            0% {
                opacity: 0;
                -moz-transform: rotate(360deg) scale(0.1);
            }

            100% {
                opacity: 1;
                -moz-transform: rotate(0deg) scale(1);
            }
        }

        @-o-keyframes rotateIn {
            0% {
                opacity: 0;
                -o-transform: rotate(360deg) scale(0.1);
            }

            100% {
                opacity: 1;
                -o-transform: rotate(0deg) scale(1);
            }
        }

        @keyframes rotateIn {
            0% {
                opacity: 0;
                transform: rotate(360deg) scale(0.1);
            }

            100% {
                opacity: 1;
                transform: rotate(0deg) scale(1);
            }
        }

        .rotateIn {
            -webkit-animation-name: rotateIn;
            -moz-animation-name: rotateIn;
            -o-animation-name: rotateIn;
            animation-name: rotateIn;
        }

        @-webkit-keyframes rotateInLeft {
            0% {
                opacity: 0;
                -webkit-transform: translateX(-100%) rotate(-120deg);
            }

            100% {
                opacity: 1;
                -webkit-transform: translateX(0) rotate(0deg);
            }
        }

        @-moz-keyframes rotateInLeft {
            0% {
                opacity: 0;
                -moz-transform: translateX(-100%) rotate(-120deg);
            }

            100% {
                opacity: 1;
                -moz-transform: translateX(0) rotate(0deg);
            }
        }

        @-o-keyframes rotateInLeft {
            0% {
                opacity: 0;
                -o-transform: translateX(-100%) rotate(-120deg);
            }

            100% {
                opacity: 1;
                -o-transform: translateX(0) rotate(0deg);
            }
        }

        @keyframes rotateInLeft {
            0% {
                opacity: 0;
                transform: translateX(-100%) rotate(-120deg);
            }

            100% {
                opacity: 1;
                transform: translateX(0) rotate(0deg);
            }
        }

        .rotateInLeft {
            -webkit-animation-name: rotateInLeft;
            -moz-animation-name: rotateInLeft;
            -o-animation-name: rotateInLeft;
            animation-name: rotateInLeft;
        }

        @-webkit-keyframes rotateInRight {
            0% {
                opacity: 0;
                -webkit-transform: translateX(100%) rotate(120deg);
            }

            100% {
                opacity: 1;
                -webkit-transform: translateX(0) rotate(0deg);
            }
        }

        @-moz-keyframes rotateInRight {
            0% {
                opacity: 0;
                -moz-transform: translateX(100%) rotate(120deg);
            }

            100% {
                opacity: 1;
                -moz-transform: translateX(0) rotate(0deg);
            }
        }

        @-o-keyframes rotateInRight {
            0% {
                opacity: 0;
                -o-transform: translateX(100%) rotate(120deg);
            }

            100% {
                opacity: 1;
                -o-transform: translateX(0) rotate(0deg);
            }
        }

        @keyframes rotateInRight {
            0% {
                opacity: 0;
                transform: translateX(100%) rotate(120deg);
            }

            100% {
                opacity: 1;
                transform: translateX(0) rotate(0deg);
            }
        }

        .rotateInRight {
            -webkit-animation-name: rotateInRight;
            -moz-animation-name: rotateInRight;
            -o-animation-name: rotateInRight;
            animation-name: rotateInRight;
        }

        @-webkit-keyframes rotateInUp {
            0% {
                opacity: 0;
                -webkit-transform: translateY(100%) rotate(-120deg);
            }

            100% {
                opacity: 1;
                -webkit-transform: translateY(0) rotate(0deg);
            }
        }

        @-moz-keyframes rotateInUp {
            0% {
                opacity: 0;
                -moz-transform: translateY(100%) rotate(-120deg);
            }

            100% {
                opacity: 1;
                -moz-transform: translateY(0) rotate(0deg);
            }
        }

        @-o-keyframes rotateInUp {
            0% {
                opacity: 0;
                -o-transform: translateY(100%) rotate(-120deg);
            }

            100% {
                opacity: 1;
                -o-transform: translateY(0) rotate(0deg);
            }
        }

        @keyframes rotateInUp {
            0% {
                opacity: 0;
                transform: translateY(100%) rotate(-120deg);
            }

            100% {
                opacity: 1;
                transform: translateY(0) rotate(0deg);
            }
        }

        .rotateInUp {
            -webkit-animation-name: rotateInUp;
            -moz-animation-name: rotateInUp;
            -o-animation-name: rotateInUp;
            animation-name: rotateInUp;
        }

        @-webkit-keyframes rotateInDown {
            0% {
                opacity: 0;
                -webkit-transform: translateY(-100%) rotate(120deg);
            }

            100% {
                opacity: 1;
                -webkit-transform: translateY(0) rotate(0deg);
            }
        }

        @-moz-keyframes rotateInDown {
            0% {
                opacity: 0;
                -moz-transform: translateY(-100%) rotate(120deg);
            }

            100% {
                opacity: 1;
                -moz-transform: translateY(0) rotate(0deg);
            }
        }

        @-o-keyframes rotateInDown {
            0% {
                opacity: 0;
                -o-transform: translateY(-100%) rotate(120deg);
            }

            100% {
                opacity: 1;
                -o-transform: translateY(0) rotate(0deg);
            }
        }

        @keyframes rotateInDown {
            0% {
                opacity: 0;
                transform: translateY(-100%) rotate(120deg);
            }

            100% {
                opacity: 1;
                transform: translateY(0) rotate(0deg);
            }
        }

        .rotateInDown {
            -webkit-animation-name: rotateInDown;
            -moz-animation-name: rotateInDown;
            -o-animation-name: rotateInDown;
            animation-name: rotateInDown;
        }

        @-webkit-keyframes jellyInDown {
            0% {
                -webkit-transform: translateY(-1000px);
                opacity: 0;
            }

            40% {
                -webkit-transform: translateY(0);
                opacity: 1;
            }

            50% {
                -webkit-transform: scale(1.1, 0.9);
            }

            60% {
                -webkit-transform: scale(0.95, 1.05);
            }

            70% {
                -webkit-transform: scale(1.05, 0.95);
            }

            80% {
                -webkit-transform: scale(0.98, 1.02);
            }

            90% {
                -webkit-transform: scale(1.02, 0.98);
            }

            100% {
                -webkit-transform: scale(1);
            }
        }

        @-moz-keyframes jellyInDown {
            0% {
                -moz-transform: translateY(-1000px);
                opacity: 0;
            }

            40% {
                -moz-transform: translateY(0);
                opacity: 1;
            }

            50% {
                -moz-transform: scale(1.1, 0.9);
            }

            60% {
                -moz-transform: scale(0.95, 1.05);
            }

            70% {
                -moz-transform: scale(1.05, 0.95);
            }

            80% {
                -moz-transform: scale(0.98, 1.02);
            }

            90% {
                -moz-transform: scale(1.02, 0.98);
            }

            100% {
                -moz-transform: scale(1);
            }
        }

        @-o-keyframes jellyInDown {
            0% {
                -o-transform: translateY(-1000px);
                opacity: 0;
            }

            40% {
                -o-transform: translateY(0);
                opacity: 1;
            }

            50% {
                -o-transform: scale(1.1, 0.9);
            }

            60% {
                -o-transform: scale(0.95, 1.05);
            }

            70% {
                -o-transform: scale(1.05, 0.95);
            }

            80% {
                -o-transform: scale(0.98, 1.02);
            }

            90% {
                -o-transform: scale(1.02, 0.98);
            }

            100% {
                -o-transform: scale(1);
            }
        }

        @keyframes jellyInDown {
            0% {
                transform: translateY(-1000px);
                opacity: 0;
            }

            40% {
                transform: translateY(0);
                opacity: 1;
            }

            50% {
                transform: scale(1.1, 0.9);
            }

            60% {
                transform: scale(0.95, 1.05);
            }

            70% {
                transform: scale(1.05, 0.95);
            }

            80% {
                transform: scale(0.98, 1.02);
            }

            90% {
                transform: scale(1.02, 0.98);
            }

            100% {
                transform: scale(1);
            }
        }

        .jellyInDown {
            -webkit-animation-name: jellyInDown;
            -webkit-transform-origin: 50% 100%;
            -moz-animation-name: jellyInDown;
            -moz-transform-origin: 50% 100%;
            -o-animation-name: jellyInDown;
            -o-transform-origin: 50% 100%;
            animation-name: jellyInDown;
            transform-origin: 50% 100%;
        }

        @-webkit-keyframes jellyInUp {
            0% {
                -webkit-transform: translateY(1000px);
                opacity: 0;
            }

            40% {
                -webkit-transform: translateY(0);
                opacity: 1;
            }

            50% {
                -webkit-transform: scale(1.1, 0.9);
            }

            60% {
                -webkit-transform: scale(0.95, 1.05);
            }

            70% {
                -webkit-transform: scale(1.05, 0.95);
            }

            80% {
                -webkit-transform: scale(0.98, 1.02);
            }

            90% {
                -webkit-transform: scale(1.02, 0.98);
            }

            100% {
                -webkit-transform: scale(1);
            }
        }

        @-moz-keyframes jellyInUp {
            0% {
                -moz-transform: translateY(1000px);
                opacity: 0;
            }

            40% {
                -moz-transform: translateY(0);
                opacity: 1;
            }

            50% {
                -moz-transform: scale(1.1, 0.9);
            }

            60% {
                -moz-transform: scale(0.95, 1.05);
            }

            70% {
                -moz-transform: scale(1.05, 0.95);
            }

            80% {
                -moz-transform: scale(0.98, 1.02);
            }

            90% {
                -moz-transform: scale(1.02, 0.98);
            }

            100% {
                -moz-transform: scale(1);
            }
        }

        @-o-keyframes jellyInUp {
            0% {
                -o-transform: translateY(1000px);
                opacity: 0;
            }

            40% {
                -o-transform: translateY(0);
                opacity: 1;
            }

            50% {
                -o-transform: scale(1.1, 0.9);
            }

            60% {
                -o-transform: scale(0.95, 1.05);
            }

            70% {
                -o-transform: scale(1.05, 0.95);
            }

            80% {
                -o-transform: scale(0.98, 1.02);
            }

            90% {
                -o-transform: scale(1.02, 0.98);
            }

            100% {
                -o-transform: scale(1);
            }
        }

        @keyframes jellyInUp {
            0% {
                transform: translateY(1000px);
                opacity: 0;
            }

            40% {
                transform: translateY(0);
                opacity: 1;
            }

            50% {
                transform: scale(1.1, 0.9);
            }

            60% {
                transform: scale(0.95, 1.05);
            }

            70% {
                transform: scale(1.05, 0.95);
            }

            80% {
                transform: scale(0.98, 1.02);
            }

            90% {
                transform: scale(1.02, 0.98);
            }

            100% {
                transform: scale(1);
            }
        }

        .jellyInUp {
            -webkit-animation-name: jellyInUp;
            -webkit-transform-origin: 50% 0%;
            -moz-animation-name: jellyInUp;
            -moz-transform-origin: 50% 0%;
            -o-animation-name: jellyInUp;
            -o-transform-origin: 50% 0%;
            animation-name: jellyInUp;
            transform-origin: 50% 0%;
        }

        @-webkit-keyframes jellyInLeft {
            0% {
                -webkit-transform: translateX(-1000px);
                opacity: 0;
            }

            40% {
                -webkit-transform: translateX(0);
                opacity: 1;
            }

            50% {
                -webkit-transform: scale(0.9, 1.1);
            }

            60% {
                -webkit-transform: scale(1.05, 0.95);
            }

            70% {
                -webkit-transform: scale(0.95, 1.05);
            }

            80% {
                -webkit-transform: scale(1.02, 0.98);
            }

            90% {
                -webkit-transform: scale(0.98, 1.02);
            }

            100% {
                -webkit-transform: scale(1);
            }
        }

        @-moz-keyframes jellyInLeft {
            0% {
                -moz-transform: translateX(-1000px);
                opacity: 0;
            }

            40% {
                -moz-transform: translateX(0);
                opacity: 1;
            }

            50% {
                -moz-transform: scale(0.9, 1.1);
            }

            60% {
                -moz-transform: scale(1.05, 0.95);
            }

            70% {
                -moz-transform: scale(0.95, 1.05);
            }

            80% {
                -moz-transform: scale(1.02, 0.98);
            }

            90% {
                -moz-transform: scale(0.98, 1.02);
            }

            100% {
                -moz-transform: scale(1);
            }
        }

        @-o-keyframes jellyInLeft {
            0% {
                -o-transform: translateX(-1000px);
                opacity: 0;
            }

            40% {
                -o-transform: translateX(0);
                opacity: 1;
            }

            50% {
                -o-transform: scale(0.9, 1.1);
            }

            60% {
                -o-transform: scale(1.05, 0.95);
            }

            70% {
                -o-transform: scale(0.95, 1.05);
            }

            80% {
                -o-transform: scale(1.02, 0.98);
            }

            90% {
                -o-transform: scale(0.98, 1.02);
            }

            100% {
                -o-transform: scale(1);
            }
        }

        @keyframes jellyInLeft {
            0% {
                transform: translateX(-1000px);
                opacity: 0;
            }

            40% {
                transform: translateX(0);
                opacity: 1;
            }

            50% {
                transform: scale(0.9, 1.1);
            }

            60% {
                transform: scale(1.05, 0.95);
            }

            70% {
                transform: scale(0.95, 1.05);
            }

            80% {
                transform: scale(1.02, 0.98);
            }

            90% {
                transform: scale(0.98, 1.02);
            }

            100% {
                transform: scale(1);
            }
        }

        .jellyInLeft {
            -webkit-animation-name: jellyInLeft;
            -webkit-transform-origin: 100% 50%;
            -moz-animation-name: jellyInLeft;
            -moz-transform-origin: 100% 50%;
            -o-animation-name: jellyInLeft;
            -o-transform-origin: 100% 50%;
            animation-name: jellyInLeft;
            transform-origin: 100% 50%;
        }

        @-webkit-keyframes jellyInRight {
            0% {
                -webkit-transform: translateX(1000px);
                opacity: 0;
            }

            40% {
                -webkit-transform: translateX(0);
                opacity: 1;
            }

            50% {
                -webkit-transform: scale(0.9, 1.1);
            }

            60% {
                -webkit-transform: scale(1.05, 0.95);
            }

            70% {
                -webkit-transform: scale(0.95, 1.05);
            }

            80% {
                -webkit-transform: scale(1.02, 0.98);
            }

            90% {
                -webkit-transform: scale(0.98, 1.02);
            }

            100% {
                -webkit-transform: scale(1);
            }
        }

        @-moz-keyframes jellyInRight {
            0% {
                -moz-transform: translateX(1000px);
                opacity: 0;
            }

            40% {
                -moz-transform: translateX(0);
                opacity: 1;
            }

            50% {
                -moz-transform: scale(0.9, 1.1);
            }

            60% {
                -moz-transform: scale(1.05, 0.95);
            }

            70% {
                -moz-transform: scale(0.95, 1.05);
            }

            80% {
                -moz-transform: scale(1.02, 0.98);
            }

            90% {
                -moz-transform: scale(0.98, 1.02);
            }

            100% {
                -moz-transform: scale(1);
            }
        }

        @-o-keyframes jellyInRight {
            0% {
                -o-transform: translateX(1000px);
                opacity: 0;
            }

            40% {
                -o-transform: translateX(0);
                opacity: 1;
            }

            50% {
                -o-transform: scale(0.9, 1.1);
            }

            60% {
                -o-transform: scale(1.05, 0.95);
            }

            70% {
                -o-transform: scale(0.95, 1.05);
            }

            80% {
                -o-transform: scale(1.02, 0.98);
            }

            90% {
                -o-transform: scale(0.98, 1.02);
            }

            100% {
                -o-transform: scale(1);
            }
        }

        @keyframes jellyInRight {
            0% {
                transform: translateX(1000px);
                opacity: 0;
            }

            40% {
                transform: translateX(0);
                opacity: 1;
            }

            50% {
                transform: scale(0.9, 1.1);
            }

            60% {
                transform: scale(1.05, 0.95);
            }

            70% {
                transform: scale(0.95, 1.05);
            }

            80% {
                transform: scale(1.02, 0.98);
            }

            90% {
                transform: scale(0.98, 1.02);
            }

            100% {
                transform: scale(1);
            }
        }

        .jellyInRight {
            -webkit-animation-name: jellyInRight;
            -webkit-transform-origin: 0% 50%;
            -moz-animation-name: jellyInRight;
            -moz-transform-origin: 0% 50%;
            -o-animation-name: jellyInRight;
            -o-transform-origin: 0% 50%;
            animation-name: jellyInRight;
            transform-origin: 0% 50%;
        }

        @-webkit-keyframes bounceInLeft {
            0% {
                -webkit-transform: translateX(-1000px);
            }

            34% {
                -webkit-animation-timing-function: ease-out;
                -webkit-transform: translateX(0);
            }

            52% {
                -webkit-transform: translateX(-24px);
                -webkit-animation-timing-function: ease-in;
            }

            70% {
                -webkit-transform: translateX(0);
                -webkit-animation-timing-function: ease-out;
            }

            79% {
                -webkit-transform: translateX(-8px);
                -webkit-animation-timing-function: ease-in;
            }

            88% {
                -webkit-transform: translateX(0);
                -webkit-animation-timing-function: ease-out;
            }

            94% {
                -webkit-transform: translateX(-3px);
                -webkit-animation-timing-function: ease-in;
            }

            100% {
                -webkit-transform: translateX(0);
            }
        }

        @-moz-keyframes bounceInLeft {
            0% {
                -moz-transform: translateX(-1000px);
            }

            34% {
                -moz-animation-timing-function: ease-out;
                -moz-transform: translateX(0);
            }

            52% {
                -moz-transform: translateX(-24px);
                -moz-animation-timing-function: ease-in;
            }

            70% {
                -moz-transform: translateX(0);
                -moz-animation-timing-function: ease-out;
            }

            79% {
                -moz-transform: translateX(-8px);
                -moz-animation-timing-function: ease-in;
            }

            88% {
                -moz-transform: translateX(0);
                -moz-animation-timing-function: ease-out;
            }

            94% {
                -moz-transform: translateX(-3px);
                -moz-animation-timing-function: ease-in;
            }

            100% {
                -moz-transform: translateX(0);
            }
        }

        @-o-keyframes bounceInLeft {
            0% {
                -o-transform: translateX(-1000px);
            }

            34% {
                -o-animation-timing-function: ease-out;
                -o-transform: translateX(0);
            }

            52% {
                -o-transform: translateX(-24px);
                -o-animation-timing-function: ease-in;
            }

            70% {
                -o-transform: translateX(0);
                -o-animation-timing-function: ease-out;
            }

            79% {
                -o-transform: translateX(-8px);
                -o-animation-timing-function: ease-in;
            }

            88% {
                -o-transform: translateX(0);
                -o-animation-timing-function: ease-out;
            }

            94% {
                -o-transform: translateX(-3px);
                -o-animation-timing-function: ease-in;
            }

            100% {
                -o-transform: translateX(0);
            }
        }

        @keyframes bounceInLeft {
            0% {
                transform: translateX(-1000px);
            }

            34% {
                animation-timing-function: ease-out;
                transform: translateX(0);
            }

            52% {
                transform: translateX(-24px);
                animation-timing-function: ease-in;
            }

            70% {
                transform: translateX(0);
                animation-timing-function: ease-out;
            }

            79% {
                transform: translateX(-8px);
                animation-timing-function: ease-in;
            }

            88% {
                transform: translateX(0);
                animation-timing-function: ease-out;
            }

            94% {
                transform: translateX(-3px);
                animation-timing-function: ease-in;
            }

            100% {
                transform: translateX(0);
            }
        }

        .bounceInLeft {
            -webkit-animation-name: bounceInLeft;
            -moz-animation-name: bounceInLeft;
            -o-animation-name: bounceInLeft;
            animation-name: bounceInLeft;
        }

        @-webkit-keyframes bounceInRight {
            0% {
                -webkit-transform: translateX(1000px);
            }

            34% {
                -webkit-animation-timing-function: ease-out;
                -webkit-transform: translateX(0);
            }

            52% {
                -webkit-transform: translateX(24px);
                -webkit-animation-timing-function: ease-in;
            }

            70% {
                -webkit-transform: translateX(0);
                -webkit-animation-timing-function: ease-out;
            }

            79% {
                -webkit-transform: translateX(8px);
                -webkit-animation-timing-function: ease-in;
            }

            88% {
                -webkit-transform: translateX(0);
                -webkit-animation-timing-function: ease-out;
            }

            94% {
                -webkit-transform: translateX(3px);
                -webkit-animation-timing-function: ease-in;
            }

            100% {
                -webkit-transform: translateX(0);
            }
        }

        @-moz-keyframes bounceInRight {
            0% {
                -moz-transform: translateX(1000px);
            }

            34% {
                -moz-animation-timing-function: ease-out;
                -moz-transform: translateX(0);
            }

            52% {
                -moz-transform: translateX(24px);
                -moz-animation-timing-function: ease-in;
            }

            70% {
                -moz-transform: translateX(0);
                -moz-animation-timing-function: ease-out;
            }

            79% {
                -moz-transform: translateX(8px);
                -moz-animation-timing-function: ease-in;
            }

            88% {
                -moz-transform: translateX(0);
                -moz-animation-timing-function: ease-out;
            }

            94% {
                -moz-transform: translateX(3px);
                -moz-animation-timing-function: ease-in;
            }

            100% {
                -moz-transform: translateX(0);
            }
        }

        @-o-keyframes bounceInRight {
            0% {
                -o-transform: translateX(1000px);
            }

            34% {
                -o-animation-timing-function: ease-out;
                -o-transform: translateX(0);
            }

            52% {
                -o-transform: translateX(24px);
                -o-animation-timing-function: ease-in;
            }

            70% {
                -o-transform: translateX(0);
                -o-animation-timing-function: ease-out;
            }

            79% {
                -o-transform: translateX(8px);
                -o-animation-timing-function: ease-in;
            }

            88% {
                -o-transform: translateX(0);
                -o-animation-timing-function: ease-out;
            }

            94% {
                -o-transform: translateX(3px);
                -o-animation-timing-function: ease-in;
            }

            100% {
                -o-transform: translateX(0);
            }
        }

        @keyframes bounceInRight {
            0% {
                transform: translateX(1000px);
            }

            34% {
                animation-timing-function: ease-out;
                transform: translateX(0);
            }

            52% {
                transform: translateX(24px);
                animation-timing-function: ease-in;
            }

            70% {
                transform: translateX(0);
                animation-timing-function: ease-out;
            }

            79% {
                transform: translateX(8px);
                animation-timing-function: ease-in;
            }

            88% {
                transform: translateX(0);
                animation-timing-function: ease-out;
            }

            94% {
                transform: translateX(3px);
                animation-timing-function: ease-in;
            }

            100% {
                transform: translateX(0);
            }
        }

        .bounceInRight {
            -webkit-animation-name: bounceInRight;
            -moz-animation-name: bounceInRight;
            -o-animation-name: bounceInRight;
            animation-name: bounceInRight;
        }

        @-webkit-keyframes bounceInUp {
            0% {
                -webkit-transform: translateY(1000px);
            }

            34% {
                -webkit-animation-timing-function: ease-out;
                -webkit-transform: translateY(0);
            }

            52% {
                -webkit-transform: translateY(24px);
                -webkit-animation-timing-function: ease-in;
            }

            70% {
                -webkit-transform: translateY(0);
                -webkit-animation-timing-function: ease-out;
            }

            79% {
                -webkit-transform: translateY(8px);
                -webkit-animation-timing-function: ease-in;
            }

            88% {
                -webkit-transform: translateY(0);
                -webkit-animation-timing-function: ease-out;
            }

            94% {
                -webkit-transform: translateY(3px);
                -webkit-animation-timing-function: ease-in;
            }

            100% {
                -webkit-transform: translateY(0);
            }
        }

        @-moz-keyframes bounceInUp {
            0% {
                -moz-transform: translateY(1000px);
            }

            34% {
                -moz-animation-timing-function: ease-out;
                -moz-transform: translateY(0);
            }

            52% {
                -moz-transform: translateY(24px);
                -moz-animation-timing-function: ease-in;
            }

            70% {
                -moz-transform: translateY(0);
                -moz-animation-timing-function: ease-out;
            }

            79% {
                -moz-transform: translateY(8px);
                -moz-animation-timing-function: ease-in;
            }

            88% {
                -moz-transform: translateY(0);
                -moz-animation-timing-function: ease-out;
            }

            94% {
                -moz-transform: translateY(3px);
                -moz-animation-timing-function: ease-in;
            }

            100% {
                -moz-transform: translateY(0);
            }
        }

        @-o-keyframes bounceInUp {
            0% {
                -o-transform: translateY(1000px);
            }

            34% {
                -o-animation-timing-function: ease-out;
                -o-transform: translateY(0);
            }

            52% {
                -o-transform: translateY(24px);
                -o-animation-timing-function: ease-in;
            }

            70% {
                -o-transform: translateY(0);
                -o-animation-timing-function: ease-out;
            }

            79% {
                -o-transform: translateY(8px);
                -o-animation-timing-function: ease-in;
            }

            88% {
                -o-transform: translateY(0);
                -o-animation-timing-function: ease-out;
            }

            94% {
                -o-transform: translateY(3px);
                -o-animation-timing-function: ease-in;
            }

            100% {
                -o-transform: translateY(0);
            }
        }

        @keyframes bounceInUp {
            0% {
                transform: translateY(1000px);
            }

            34% {
                animation-timing-function: ease-out;
                transform: translateY(0);
            }

            52% {
                transform: translateY(24px);
                animation-timing-function: ease-in;
            }

            70% {
                transform: translateY(0);
                animation-timing-function: ease-out;
            }

            79% {
                transform: translateY(8px);
                animation-timing-function: ease-in;
            }

            88% {
                transform: translateY(0);
                animation-timing-function: ease-out;
            }

            94% {
                transform: translateY(3px);
                animation-timing-function: ease-in;
            }

            100% {
                transform: translateY(0);
            }
        }

        .bounceInUp {
            -webkit-animation-name: bounceInUp;
            -moz-animation-name: bounceInUp;
            -o-animation-name: bounceInUp;
            animation-name: bounceInUp;
        }

        @-webkit-keyframes bounceInDown {
            0% {
                -webkit-transform: translateY(-1000px);
            }

            34% {
                -webkit-animation-timing-function: ease-out;
                -webkit-transform: translateY(0);
            }

            52% {
                -webkit-transform: translateY(-24px);
                -webkit-animation-timing-function: ease-in;
            }

            70% {
                -webkit-transform: translateY(0);
                -webkit-animation-timing-function: ease-out;
            }

            79% {
                -webkit-transform: translateY(-8px);
                -webkit-animation-timing-function: ease-in;
            }

            88% {
                -webkit-transform: translateY(0);
                -webkit-animation-timing-function: ease-out;
            }

            94% {
                -webkit-transform: translateY(-3px);
                -webkit-animation-timing-function: ease-in;
            }

            100% {
                -webkit-transform: translateY(0);
            }
        }

        @-moz-keyframes bounceInDown {
            0% {
                -moz-transform: translateY(-1000px);
            }

            34% {
                -moz-animation-timing-function: ease-out;
                -moz-transform: translateY(0);
            }

            52% {
                -moz-transform: translateY(-24px);
                -moz-animation-timing-function: ease-in;
            }

            70% {
                -moz-transform: translateY(0);
                -moz-animation-timing-function: ease-out;
            }

            79% {
                -moz-transform: translateY(-8px);
                -moz-animation-timing-function: ease-in;
            }

            88% {
                -moz-transform: translateY(0);
                -moz-animation-timing-function: ease-out;
            }

            94% {
                -moz-transform: translateY(-3px);
                -moz-animation-timing-function: ease-in;
            }

            100% {
                -moz-transform: translateY(0);
            }
        }

        @-o-keyframes bounceInDown {
            0% {
                -o-transform: translateY(-1000px);
            }

            34% {
                -o-animation-timing-function: ease-out;
                -o-transform: translateY(0);
            }

            52% {
                -o-transform: translateY(-24px);
                -o-animation-timing-function: ease-in;
            }

            70% {
                -o-transform: translateY(0);
                -o-animation-timing-function: ease-out;
            }

            79% {
                -o-transform: translateY(-8px);
                -o-animation-timing-function: ease-in;
            }

            88% {
                -o-transform: translateY(0);
                -o-animation-timing-function: ease-out;
            }

            94% {
                -o-transform: translateY(-3px);
                -o-animation-timing-function: ease-in;
            }

            100% {
                -o-transform: translateY(0);
            }
        }

        @keyframes bounceInDown {
            0% {
                transform: translateY(-1000px);
            }

            34% {
                animation-timing-function: ease-out;
                transform: translateY(0);
            }

            52% {
                transform: translateY(-24px);
                animation-timing-function: ease-in;
            }

            70% {
                transform: translateY(0);
                animation-timing-function: ease-out;
            }

            79% {
                transform: translateY(-8px);
                animation-timing-function: ease-in;
            }

            88% {
                transform: translateY(0);
                animation-timing-function: ease-out;
            }

            94% {
                transform: translateY(-3px);
                animation-timing-function: ease-in;
            }

            100% {
                transform: translateY(0);
            }
        }

        .bounceInDown {
            -webkit-animation-name: bounceInDown;
            -moz-animation-name: bounceInDown;
            -o-animation-name: bounceInDown;
            animation-name: bounceInDown;
        }

        @-webkit-keyframes bounce {
            0% {
                -webkit-animation-timing-function: ease-out;
                -webkit-transform: translateY(0);
            }

            37% {
                -webkit-transform: translateY(-24px);
                -webkit-animation-timing-function: ease-in;
            }

            54% {
                -webkit-transform: translateY(0);
                -webkit-animation-timing-function: ease-out;
            }

            68% {
                -webkit-transform: translateY(-8px);
                -webkit-animation-timing-function: ease-in;
            }

            82% {
                -webkit-transform: translateY(0);
                -webkit-animation-timing-function: ease-out;
            }

            91% {
                -webkit-transform: translateY(-3px);
                -webkit-animation-timing-function: ease-in;
            }

            100% {
                -webkit-transform: translateY(0);
            }
        }

        @-moz-keyframes bounce {
            0% {
                -moz-animation-timing-function: ease-out;
                -moz-transform: translateY(0);
            }

            37% {
                -moz-transform: translateY(-24px);
                -moz-animation-timing-function: ease-in;
            }

            54% {
                -moz-transform: translateY(0);
                -moz-animation-timing-function: ease-out;
            }

            68% {
                -moz-transform: translateY(-8px);
                -moz-animation-timing-function: ease-in;
            }

            82% {
                -moz-transform: translateY(0);
                -moz-animation-timing-function: ease-out;
            }

            91% {
                -moz-transform: translateY(-3px);
                -moz-animation-timing-function: ease-in;
            }

            100% {
                -moz-transform: translateY(0);
            }
        }

        @-o-keyframes bounce {
            0% {
                -o-animation-timing-function: ease-out;
                -o-transform: translateY(0);
            }

            37% {
                -o-transform: translateY(-24px);
                -o-animation-timing-function: ease-in;
            }

            54% {
                -o-transform: translateY(0);
                -o-animation-timing-function: ease-out;
            }

            68% {
                -o-transform: translateY(-8px);
                -o-animation-timing-function: ease-in;
            }

            82% {
                -o-transform: translateY(0);
                -o-animation-timing-function: ease-out;
            }

            91% {
                -o-transform: translateY(-3px);
                -o-animation-timing-function: ease-in;
            }

            100% {
                -o-transform: translateY(0);
            }
        }

        @keyframes bounce {
            0% {
                animation-timing-function: ease-out;
                transform: translateY(0);
            }

            37% {
                transform: translateY(-24px);
                animation-timing-function: ease-in;
            }

            54% {
                transform: translateY(0);
                animation-timing-function: ease-out;
            }

            68% {
                transform: translateY(-8px);
                animation-timing-function: ease-in;
            }

            82% {
                transform: translateY(0);
                animation-timing-function: ease-out;
            }

            91% {
                transform: translateY(-3px);
                animation-timing-function: ease-in;
            }

            100% {
                transform: translateY(0);
            }
        }

        .bounce {
            -webkit-animation-name: bounce;
            -moz-animation-name: bounce;
            -o-animation-name: bounce;
            animation-name: bounce;
        }

        @-webkit-keyframes elasticInLeft {
            0% {
                opacity: 0;
                -webkit-transform: translateX(-1000px);
                -webkit-animation-timing-function: ease-in-out;
            }

            60% {
                opacity: 1;
                -webkit-transform: translateX(30px);
                -webkit-animation-timing-function: ease-in-out;
            }

            80% {
                -webkit-transform: translateX(-10px);
                -webkit-animation-timing-function: ease-in-out;
            }

            100% {
                -webkit-transform: translateX(0);
            }
        }

        @-moz-keyframes elasticInLeft {
            0% {
                opacity: 0;
                -moz-transform: translateX(-1000px);
                -moz-animation-timing-function: ease-in-out;
            }

            60% {
                opacity: 1;
                -moz-transform: translateX(30px);
                -moz-animation-timing-function: ease-in-out;
            }

            80% {
                -moz-transform: translateX(-10px);
                -moz-animation-timing-function: ease-in-out;
            }

            100% {
                -moz-transform: translateX(0);
            }
        }

        @-o-keyframes elasticInLeft {
            0% {
                opacity: 0;
                -o-transform: translateX(-1000px);
                -o-animation-timing-function: ease-in-out;
            }

            60% {
                opacity: 1;
                -o-transform: translateX(30px);
                -o-animation-timing-function: ease-in-out;
            }

            80% {
                -o-transform: translateX(-10px);
                -o-animation-timing-function: ease-in-out;
            }

            100% {
                -o-transform: translateX(0);
            }
        }

        @keyframes elasticInLeft {
            0% {
                opacity: 0;
                transform: translateX(-1000px);
                animation-timing-function: ease-in-out;
            }

            60% {
                opacity: 1;
                transform: translateX(30px);
                animation-timing-function: ease-in-out;
            }

            80% {
                transform: translateX(-10px);
                animation-timing-function: ease-in-out;
            }

            100% {
                transform: translateX(0);
            }
        }

        .elasticInLeft {
            -webkit-animation-name: elasticInLeft;
            -moz-animation-name: elasticInLeft;
            -o-animation-name: elasticInLeft;
            animation-name: elasticInLeft;
        }

        @-webkit-keyframes elasticInRight {
            0% {
                opacity: 0;
                -webkit-transform: translateX(1000px);
                -webkit-animation-timing-function: ease-in-out;
            }

            60% {
                opacity: 1;
                -webkit-transform: translateX(-30px);
                -webkit-animation-timing-function: ease-in-out;
            }

            80% {
                -webkit-transform: translateX(10px);
                -webkit-animation-timing-function: ease-in-out;
            }

            100% {
                -webkit-transform: translateX(0);
            }
        }

        @-moz-keyframes elasticInRight {
            0% {
                opacity: 0;
                -moz-transform: translateX(1000px);
                -moz-animation-timing-function: ease-in-out;
            }

            60% {
                opacity: 1;
                -moz-transform: translateX(-30px);
                -moz-animation-timing-function: ease-in-out;
            }

            80% {
                -moz-transform: translateX(10px);
                -moz-animation-timing-function: ease-in-out;
            }

            100% {
                -moz-transform: translateX(0);
            }
        }

        @-o-keyframes elasticInRight {
            0% {
                opacity: 0;
                -o-transform: translateX(1000px);
                -o-animation-timing-function: ease-in-out;
            }

            60% {
                opacity: 1;
                -o-transform: translateX(-30px);
                -o-animation-timing-function: ease-in-out;
            }

            80% {
                -o-transform: translateX(10px);
                -o-animation-timing-function: ease-in-out;
            }

            100% {
                -o-transform: translateX(0);
            }
        }

        @keyframes elasticInRight {
            0% {
                opacity: 0;
                transform: translateX(1000px);
                animation-timing-function: ease-in-out;
            }

            60% {
                opacity: 1;
                transform: translateX(-30px);
                animation-timing-function: ease-in-out;
            }

            80% {
                transform: translateX(10px);
                animation-timing-function: ease-in-out;
            }

            100% {
                transform: translateX(0);
            }
        }

        .elasticInRight {
            -webkit-animation-name: elasticInRight;
            -moz-animation-name: elasticInRight;
            -o-animation-name: elasticInRight;
            animation-name: elasticInRight;
        }

        @-webkit-keyframes elasticInUp {
            0% {
                opacity: 0;
                -webkit-transform: translateY(1000px);
                -webkit-animation-timing-function: ease-in-out;
            }

            60% {
                opacity: 1;
                -webkit-transform: translateY(-30px);
                -webkit-animation-timing-function: ease-in-out;
            }

            80% {
                -webkit-transform: translateY(10px);
                -webkit-animation-timing-function: ease-in-out;
            }

            100% {
                -webkit-transform: translateY(0);
            }
        }

        @-moz-keyframes elasticInUp {
            0% {
                opacity: 0;
                -moz-transform: translateY(1000px);
                -moz-animation-timing-function: ease-in-out;
            }

            60% {
                opacity: 1;
                -moz-transform: translateY(-30px);
                -moz-animation-timing-function: ease-in-out;
            }

            80% {
                -moz-transform: translateY(10px);
                -moz-animation-timing-function: ease-in-out;
            }

            100% {
                -moz-transform: translateY(0);
            }
        }

        @-o-keyframes elasticInUp {
            0% {
                opacity: 0;
                -o-transform: translateY(1000px);
                -o-animation-timing-function: ease-in-out;
            }

            60% {
                opacity: 1;
                -o-transform: translateY(-30px);
                -o-animation-timing-function: ease-in-out;
            }

            80% {
                -o-transform: translateY(10px);
                -o-animation-timing-function: ease-in-out;
            }

            100% {
                -o-transform: translateY(0);
            }
        }

        @keyframes elasticInUp {
            0% {
                opacity: 0;
                transform: translateY(1000px);
                animation-timing-function: ease-in-out;
            }

            60% {
                opacity: 1;
                transform: translateY(-30px);
                animation-timing-function: ease-in-out;
            }

            80% {
                transform: translateY(10px);
                animation-timing-function: ease-in-out;
            }

            100% {
                transform: translateY(0);
            }
        }

        .elasticInUp {
            -webkit-animation-name: elasticInUp;
            -moz-animation-name: elasticInUp;
            -o-animation-name: elasticInUp;
            animation-name: elasticInUp;
        }

        @-webkit-keyframes elasticInDown {
            0% {
                opacity: 0;
                -webkit-transform: translateY(-1000px);
                -webkit-animation-timing-function: ease-in-out;
            }

            60% {
                opacity: 1;
                -webkit-transform: translateY(30px);
                -webkit-animation-timing-function: ease-in-out;
            }

            80% {
                -webkit-transform: translateY(-10px);
                -webkit-animation-timing-function: ease-in-out;
            }

            100% {
                -webkit-transform: translateY(0);
            }
        }

        @-moz-keyframes elasticInDown {
            0% {
                opacity: 0;
                -moz-transform: translateY(-1000px);
                -moz-animation-timing-function: ease-in-out;
            }

            60% {
                opacity: 1;
                -moz-transform: translateY(30px);
                -moz-animation-timing-function: ease-in-out;
            }

            80% {
                -moz-transform: translateY(-10px);
                -moz-animation-timing-function: ease-in-out;
            }

            100% {
                -moz-transform: translateY(0);
            }
        }

        @-o-keyframes elasticInDown {
            0% {
                opacity: 0;
                -o-transform: translateY(-1000px);
                -o-animation-timing-function: ease-in-out;
            }

            60% {
                opacity: 1;
                -o-transform: translateY(30px);
                -o-animation-timing-function: ease-in-out;
            }

            80% {
                -o-transform: translateY(-10px);
                -o-animation-timing-function: ease-in-out;
            }

            100% {
                -o-transform: translateY(0);
            }
        }

        @keyframes elasticInDown {
            0% {
                opacity: 0;
                transform: translateY(-1000px);
                animation-timing-function: ease-in-out;
            }

            60% {
                opacity: 1;
                transform: translateY(30px);
                animation-timing-function: ease-in-out;
            }

            80% {
                transform: translateY(-10px);
                animation-timing-function: ease-in-out;
            }

            100% {
                transform: translateY(0);
            }
        }

        .elasticInDown {
            -webkit-animation-name: elasticInDown;
            -moz-animation-name: elasticInDown;
            -o-animation-name: elasticInDown;
            animation-name: elasticInDown;
        }

        @-webkit-keyframes shake {
            10% {
                -webkit-transform: translateX(7px);
            }

            30% {
                -webkit-transform: translateX(-7px);
            }

            50% {
                -webkit-transform: translateX(5px);
            }

            70% {
                -webkit-transform: translateX(-5px);
            }

            90% {
                -webkit-transform: translateX(3px);
            }

            100% {
                -webkit-transform: translateX(0);
            }
        }

        @-moz-keyframes shake {
            10% {
                -moz-transform: translateX(7px);
            }

            30% {
                -moz-transform: translateX(-7px);
            }

            50% {
                -moz-transform: translateX(5px);
            }

            70% {
                -moz-transform: translateX(-5px);
            }

            90% {
                -moz-transform: translateX(3px);
            }

            100% {
                -moz-transform: translateX(0);
            }
        }

        @-o-keyframes shake {
            10% {
                -o-transform: translateX(7px);
            }

            30% {
                -o-transform: translateX(-7px);
            }

            50% {
                -o-transform: translateX(5px);
            }

            70% {
                -o-transform: translateX(-5px);
            }

            90% {
                -o-transform: translateX(3px);
            }

            100% {
                -o-transform: translateX(0);
            }
        }

        @keyframes shake {
            10% {
                transform: translateX(7px);
            }

            30% {
                transform: translateX(-7px);
            }

            50% {
                transform: translateX(5px);
            }

            70% {
                transform: translateX(-5px);
            }

            90% {
                transform: translateX(3px);
            }

            100% {
                transform: translateX(0);
            }
        }

        .shake {
            -webkit-animation-name: shake;
            -moz-animation-name: shake;
            -o-animation-name: shake;
            animation-name: shake;
        }

        @-webkit-keyframes pendulum {
            10% {
                -webkit-transform: rotate(15deg);
            }

            30% {
                -webkit-transform: rotate(-10deg);
            }

            50% {
                -webkit-transform: rotate(5deg);
            }

            70% {
                -webkit-transform: rotate(-5deg);
            }

            90% {
                -webkit-transform: rotate(2deg);
            }

            100% {
                -webkit-transform: rotate(0deg);
            }
        }

        @-moz-keyframes pendulum {
            10% {
                -moz-transform: rotate(15deg);
            }

            30% {
                -moz-transform: rotate(-10deg);
            }

            50% {
                -moz-transform: rotate(5deg);
            }

            70% {
                -moz-transform: rotate(-5deg);
            }

            90% {
                -moz-transform: rotate(2deg);
            }

            100% {
                -moz-transform: rotate(0deg);
            }
        }

        @-o-keyframes pendulum {
            10% {
                -o-transform: rotate(15deg);
            }

            30% {
                -o-transform: rotate(-10deg);
            }

            50% {
                -o-transform: rotate(5deg);
            }

            70% {
                -o-transform: rotate(-5deg);
            }

            90% {
                -o-transform: rotate(2deg);
            }

            100% {
                -o-transform: rotate(0deg);
            }
        }

        @keyframes pendulum {
            10% {
                transform: rotate(15deg);
            }

            30% {
                transform: rotate(-10deg);
            }

            50% {
                transform: rotate(5deg);
            }

            70% {
                transform: rotate(-5deg);
            }

            90% {
                transform: rotate(2deg);
            }

            100% {
                transform: rotate(0deg);
            }
        }

        .pendulum {
            -webkit-animation-name: pendulum;
            -moz-animation-name: pendulum;
            -o-animation-name: pendulum;
            animation-name: pendulum;
        }

        .pf-container {
            position: relative;
            background-color: #000;
            margin: 0;
            padding: 0;
        }

        .pf-container .pf-video,
        .pf-container .pf-silverlight {
            width: 100%;
            height: 100%;
        }

        .pf-video,
        .pf-silverlight {
            display: block;
            position: relative;
            background-color: #000;
        }

        .pf-static {
            color: #999;
        }

        .pf-hyperlinks {
            background-color: transparent;
            font-size: 16px;
            font-weight: normal;
            font-family: Arial, Times New Roman;
        }

        .pf-controls {
            display: none;
            position: relative;
            left: 0;
            right: 0;
            height: 36px;
            width: 100%;
            background-color: #000;
            color: #fff;
            margin: 0;
            padding: 0;
            user-select: none;
            -webkit-user-select: none;
            -moz-user-select: none;
        }

        .pf-controls.pf-controls-overlay {
            position: absolute;
            bottom: 0;
            opacity: 0.8;
        }

        .pf-button {
            background-color: transparent;
            border: 0;
            width: 100%;
            height: 100%;
        }

        .pf-toggle-control {
            display: none;
        }

        .pf-play-control {
            position: absolute;
            left: 0;
            top: 0;
            width: 30px;
            height: 36px;
        }

        .pf-timeline-control {
            position: absolute;
            left: 34px;
            top: 12px;
            right: 257px;
        }

        .pf-time-elapsed-control {
            position: absolute;
            right: 185px;
            top: 6px;
            width: 52px;
            text-align: right;
        }

        .pf-time-divider-control {
            position: absolute;
            right: 185px;
            top: 6px;
            width: 12px;
            text-align: center;
        }

        .pf-time-remaining-control {
            position: absolute;
            right: 132px;
            top: 6px;
            width: 52px;
            text-align: left;
        }

        .pf-mute-control {
            position: absolute;
            right: 105px;
            top: 0;
            width: 30px;
            height: 36px;
        }

        .pf-volume-control {
            position: absolute;
            right: 40px;
            top: 12px;
            width: 64px;
        }

        .pf-full-browser-control {
            position: absolute;
            right: 6px;
            top: 0;
            width: 35px;
            height: 36px;
        }

        .pf-play-overlay-control {
            position: absolute;
            left: 50%;
            top: 50%;
            width: 80px;
            height: 80px;
            margin: -40px 0 0 -40px;
        }

        .pf-no-volume .pf-volume-control {
            display: none;
        }

        .pf-no-volume .pf-mute-control {
            display: none;
        }

        .pf-no-volume .pf-time-elapsed-control {
            right: 83px;
        }

        .pf-no-volume .pf-time-divider-control {
            right: 83px;
        }

        .pf-no-volume .pf-time-remaining-control {
            right: 30px;
        }

        .pf-no-volume .pf-timeline-control {
            right: 155px;
        }

        .pf-poster {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
        }

        .pf-play-overlay-control {
            display: none;
            background-color: rgba(0, 0, 0, 0.8);
            border-radius: 10px;
            -webkit-border-radius: 10px;
            -moz-border-radius: 10px;
        }

        .pf-play-overlay-control span {
            position: absolute;
            top: 20px;
            left: 30px;
            font-size: 0;
            line-height: 0;
            width: 0;
            height: 0;
            border-left: 30px solid #fff;
            border-right: 0;
            border-top: 20px solid transparent;
            border-bottom: 20px solid transparent;
        }

        .pf-play-overlay-control button {
            cursor: pointer !important;
        }

        .pf-play-control span {
            position: absolute;
            top: 12px;
            left: 10px;
            padding: 0;
            font-size: 0;
            line-height: 0;
        }

        .pf-play span {
            width: 0;
            height: 0;
            border-left: 11px solid #fff;
            border-right: 0;
            border-top: 6px solid transparent;
            border-bottom: 6px solid transparent;
        }

        .pf-pause span {
            top: 13px;
            width: 3px;
            height: 10px;
            border-left: 3px solid #fff;
            border-right: 3px solid #fff;
            border-top: 0;
            border-bottom: 0;
        }

        .pf-slider-container {
            position: absolute;
            width: 100%;
            padding: 0;
            height: 12px;
        }

        .pf-slider-range {
            position: absolute;
            width: 0;
            margin: 0;
            padding: 0;
            width: 100%;
            top: 3px;
            height: 6px;
            opacity: 0.6;
            background-color: #999;
        }

        .pf-slider-fill {
            position: absolute;
            width: 0;
            margin: 0;
            padding: 0;
            top: 3px;
            height: 6px;
            background: #76cde8;
        }

        .pf-slider-empty {
            position: absolute;
            right: 0;
            width: 100%;
            margin: 0;
            padding: 0;
            top: 3px;
            height: 6px;
        }

        .pf-slider-load {
            position: absolute;
            width: 0;
            margin: 0;
            padding: 0;
            top: 3px;
            height: 6px;
            opacity: 0.6;
            background-color: #aaa;
        }

        .pf-slider-head-container {
            position: absolute;
            margin: 0;
            padding: 0;
            left: 0;
            right: 12px;
        }

        .pf-slider-head {
            position: absolute;
            left: 0;
            width: 10px;
            height: 10px;
            margin: 0;
            padding: 0;
            border: 1px solid rgba(0, 0, 0, 0.8);
            background: #fff;
            border-radius: 5px;
            -webkit-border-radius: 5px;
            -moz-border-radius: 5px;
        }

        .pf-timeline-control .pf-button {
            position: absolute;
            top: 0;
        }

        .pf-volume-control .pf-button {
            position: absolute;
            top: 0;
            left: 0;
        }

        .pf-volume-control .pf-slider-fill {
            background: #ccc;
        }

        .pf-time-display {
            cursor: default !important;
            color: #fff;
            font-size: 13.25px;
            font-weight: normal;
            font-family: Arial, Times New Roman;
        }

        .pf-time-display div {
            line-height: 24px;
        }

        .pf-mute-icon {
            position: absolute;
            top: 12px;
            left: 8px;
        }

        .pf-mute-icon span {
            position: absolute;
            font-size: 0;
            line-height: 0;
        }

        .pf-speaker-base {
            top: 3px;
            width: 0;
            height: 6px;
            border-left: 1px solid #fff;
            border-right: 0;
            border-top: 0;
            border-bottom: 0;
        }

        .pf-speaker {
            left: 2px;
            width: 0;
            height: 6px;
            border-right: 4px solid #fff;
            border-top: 3px solid transparent;
            border-bottom: 3px solid transparent;
        }

        .pf-sound .pf-sound-waves {
            left: 5px;
            width: 0;
            height: 0;
        }

        .pf-sound-waves .pf-sound-wave {
            border-right: 2px solid #fff;
        }

        .pf-sound-wave-1 {
            left: 1px;
            top: 4.5px;
            width: 1px;
            height: 3px;
            border-radius: 0 3px 3px 0;
            -webkit-border-radius: 0 3px 3px 0;
            -moz-border-radius: 0 3px 3px 0;
        }

        .pf-sound-wave-2 {
            left: 2px;
            top: 2px;
            width: 3px;
            height: 8px;
            border-radius: 0 3px 3px 0;
            -webkit-border-radius: 0 3px 3px 0;
            -moz-border-radius: 0 3px 3px 0;
        }

        .pf-sound-wave-3 {
            left: 2px;
            width: 6px;
            height: 12px;
            border-radius: 0 5px 5px 0;
            -webkit-border-radius: 0 5px 5px 0;
            -moz-border-radius: 0 5px 5px 0;
        }

        .pf-mute .pf-sound-waves {
            display: none;
        }

        .pf-full-browser-control span {
            position: absolute;
            font-size: 0;
            line-height: 0;
        }

        .pf-full-browser-box {
            top: 13px;
            left: 11px;
            height: 6px;
            width: 8px;
            border-width: 3px 5px 1px 1px;
            border-style: solid;
            border-color: #fff;
        }

        .pf-full-browser .pf-full-browser-box {
            height: 9px;
            width: 13px;
            border-width: 1px 1px 1px 1px;
        }

        .pf-container.pf-full-browser {
            position: fixed;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            z-index: 9999;
            overflow: hidden;
        }

        .pf-caption-cues-container {
            position: absolute;
            left: 0;
            right: 0;
            bottom: 45px;
            color: #fff;
            font-size: 18px;
            font-family: Segoe UI, Arial, Helvetica, sans-serif;
            text-shadow: -1px -1px 2px #000, 1px -1px 2px #000, -1px 1px 2px #000, 1px 1px 2px #000;
            text-align: center;
            z-index: 0;
        }

        .pf-caption-cues-container .cue {
            display: table;
            margin: auto;
        }

        .pf-caption-cues-container .cue > * {
            margin: 0;
            padding: 0;
            border: 0;
        }

        .pf-skip-back-chapter-control {
            display: none;
            position: absolute;
            left: 34px;
            top: 0;
            width: 30px;
            height: 36px;
        }

        .pf-skip-back-chapter-control span {
            position: absolute;
            padding: 0;
            font-size: 0;
            line-height: 0;
        }

        .pf-skip-back-chapter-control .pf-skip-back-chapter-arrow {
            top: 13px;
            left: 9px;
            width: 0;
            height: 0;
            border-left: 0;
            border-right: 9px solid #fff;
            border-top: 5px solid transparent;
            border-bottom: 5px solid transparent;
        }

        .pf-skip-back-chapter-control .pf-skip-back-chapter-line {
            top: 13px;
            left: 20px;
            width: 2px;
            height: 10px;
            background-color: #fff;
        }

        .pf-chapters .pf-skip-back-chapter-control {
            display: block;
        }

        .pf-skip-forward-chapter-control {
            display: none;
            position: absolute;
            right: 258px;
            top: 0;
            width: 30px;
            height: 36px;
        }

        .pf-skip-forward-chapter-control span {
            position: absolute;
            padding: 0;
            font-size: 0;
            line-height: 0;
        }

        .pf-skip-forward-chapter-control .pf-skip-forward-chapter-line {
            top: 13px;
            left: 11px;
            width: 2px;
            height: 10px;
            background-color: #fff;
        }

        .pf-skip-forward-chapter-control .pf-skip-forward-chapter-arrow {
            top: 13px;
            left: 15px;
            width: 0;
            height: 0;
            border-left: 9px solid #fff;
            border-right: 0;
            border-top: 5px solid transparent;
            border-bottom: 5px solid transparent;
        }

        .pf-chapters .pf-skip-forward-chapter-control {
            display: block;
        }

        .pf-error-message-control {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0);
            z-index: 99999;
        }

        .pf-error-message-container {
            position: absolute;
            top: 50%;
            left: 20%;
            right: 20%;
            margin: -26px 0;
            padding: 5px 10px;
            background-color: rgba(0, 0, 0, 0.6);
            color: #fff;
            font-size: 16px;
            font-family: Segoe UI, Arial, Helvetica, sans-serif;
            text-align: center;
            border-radius: 10px;
            -webkit-border-radius: 5px;
            -moz-border-radius: 5px;
        }

        .pf-playlist {
            position: absolute;
            left: 0;
            right: 0;
            top: -80px;
            width: 100%;
            height: 80px;
            background-color: rgba(0, 0, 0, 0.8);
            color: #fff;
            margin: 0;
            padding: 0;
            user-select: none;
            -webkit-user-select: none;
            -moz-user-select: none;
            overflow: hidden;
        }

        .pf-playlist-toggle {
            position: absolute;
            display: none;
            left: 50%;
            top: 0;
            width: 80px;
            height: 20px;
            margin: 0 0 0 -40px;
            background-color: rgba(0, 0, 0, 0.8);
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;
            -webkit-border-bottom-left-radius: 10px;
            -webkit-border-bottom-right-radius: 10px;
            -moz-border-bottom-left-radius: 10px;
            -moz-border-bottom-right-radius: 10px;
            user-select: none;
            -webkit-user-select: none;
            -moz-user-select: none;
            cursor: pointer;
        }

        .pf-playlist-toggle-text {
            padding-top: 4px;
            text-align: center;
            color: #fff;
            font-size: 10px;
            font-weight: normal;
            font-family: Arial, Times New Roman;
        }

        .pf-playlist-items-container {
            position: absolute;
            left: 35px;
            right: 35px;
            height: 100%;
            margin: -2px 0 0 -2px;
            overflow: hidden;
        }

        .pf-playlist-items {
            position: absolute;
            top: 12.5%;
            height: 100%;
        }

        .pf-playlist-item {
            position: absolute;
            height: 75%;
            margin: 3px 0 0 3px;
            background-color: #fff;
        }

        .pf-playlist-item-selected {
            margin: 0;
            border: 2px solid #76cde8;
        }

        .pf-playlist-arrow-control {
            position: absolute;
            top: 50%;
            width: 15px;
            height: 32px;
            margin-top: -16px;
        }

        .pf-playlist-arrow-control-left {
            left: 10px;
        }

        .pf-playlist-arrow-control-right {
            right: 10px;
        }

        .pf-playlist-arrow {
            position: absolute;
            top: 0;
            left: 0;
            width: 0;
            height: 0;
            border-top: 16px solid transparent;
            border-bottom: 16px solid transparent;
        }

        .pf-playlist-arrow-control-left .pf-playlist-arrow {
            border-left: 0 solid transparent;
            border-right: 15px solid #fff;
        }

        .pf-playlist-arrow-control-right .pf-playlist-arrow {
            border-left: 15px solid #fff;
            border-right: 0 solid transparent;
        }

        .pf-throbber-control {
            position: absolute;
            left: 50%;
            top: 50%;
            width: 80px;
            height: 80px;
            margin: -40px 0 0 -40px;
            background-color: rgba(0, 0, 0, 0.8);
            border-radius: 10px;
            -webkit-border-radius: 10px;
            -moz-border-radius: 10px;
        }

        .pf-throbber-container {
            position: relative;
            left: 50%;
            top: 50%;
            width: 50px;
            height: 50px;
            margin: -25px 0 0 -25px;
        }

        .pf-throbber-node {
            position: absolute;
            width: 8px;
            height: 8px;
            border-radius: 4px;
            -webkit-border-radius: 4px;
            -moz-border-radius: 4px;
            background-color: #fff;
        }

        .pf-timeline-cues-container {
            position: absolute;
            left: 0;
            right: 12px;
            top: -6px;
            padding: 0;
        }

        .pf-timeline-cue {
            display: none;
            position: absolute;
            width: 0;
            height: 0;
            margin-left: 2.5px;
            border-left: 4px solid transparent;
            border-right: 4px solid transparent;
            border-top: 6px solid #76cde8;
            border-bottom: 0;
        }

        .pf-track-selector .pf-track-selector-control {
            display: block;
        }

        .pf-track-selector-control {
            position: absolute;
            top: 0;
            width: 35px;
            height: 36px;
            display: none;
            cursor: default;
        }

        .pf-track-selector-control span {
            position: absolute;
            color: #fff;
            font-size: 10px;
            font-weight: normal;
            font-family: Arial, Times New Roman;
            line-height: 12px;
        }

        .pf-track-selector-box {
            top: 11px;
            left: 11px;
            height: 12px;
            width: 16px;
            border-width: 1px 1px 1px 1px;
            border-style: solid;
            border-color: #fff;
        }

        .pf-popup-track-selector-control {
            position: absolute;
            width: 200px;
            right: 0;
            background-color: rgba(0, 0, 0, 0.8);
        }

        .pf-popup-track-selector-option button {
            margin: 2px;
            padding: 2px;
            height: 18px;
            color: #fff;
            font-size: 14px;
            font-weight: normal;
            font-family: Arial, Times New Roman;
            line-height: 14px;
            text-align: left;
        }

        .pf-popup-track-selector-option.selected {
            background-color: #76cde8;
        }

        .pf-popup-track-selector-option.selected button {
            color: #000;
        }

        .document-viewer-wrapper {
            display: inline-block;
            margin-right: 10px;
            float: right;
            position: relative;
            border-radius: 5px;
        }

        .document-viewer-outer {
            position: relative;
            background: #fff;
            border: 1px solid #dadada;
            box-shadow: 0 0 4px rgba(0, 0, 0, 0.07);
        }

        .document-viewer {
            padding: 10px 0;
            overflow: auto;
        }

        .document-viewer pre,
        .document-viewer pre.prettyprint {
            padding: none;
            border: none;
            font-size: 11px;
            line-height: 21px;
        }

        .document-viewer-empty-text {
            padding: 20px 0;
            text-align: center;
        }

        .dv-sheet {
            border: 1px solid #dadada;
            border-top: none;
            background: #fff;
            height: 2px;
        }

        .dv-sheet.sheet1 {
            margin: 0 2px;
        }

        .dv-sheet.sheet2 {
            margin: 0 6px;
        }

        .dv-error {
            padding: 10px;
            padding: 10px;
            text-align: center;
            margin: 20px auto;
            font-size: 14px;
            color: #fff;
            background: #d87777;
            background: -moz-linear-gradient(top, #d87777 1%, #d95757 100%);
            background: -webkit-gradient(
                linear,
                left top,
                left bottom,
                color-stop(1%, #d87777),
                color-stop(100%, #d95757)
            );
            background: -webkit-linear-gradient(top, #d87777 1%, #d95757 100%);
            background: -o-linear-gradient(top, #d87777 1%, #d95757 100%);
            background: -ms-linear-gradient(top, #d87777 1%, #d95757 100%);
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#d87777',endColorstr='#d95757',GradientType=0);
            background: linear-gradient(top, #d87777 1%, #d95757 100%);
            border: 1px solid #b72224;
        }

        .dv-error span {
            display: block;
            margin-top: 5px;
            color: #eee;
            font-size: 11px;
        }

        .dv-loading {
            height: 32px;
            width: 100%;
        }

        .dv-sheet.sheet2:before,
        .dv-sheet.sheet2:after {
            content: "";
            position: absolute;
            z-index: -2;
        }

        .dv-sheet.sheet2 {
            -moz-border-radius: 4px;
            border-radius: 4px;
        }

        .dv-sheet.sheet2:before,
        .dv-sheet.sheet2:after {
            bottom: 15px;
            left: 10px;
            width: 50%;
            height: 20%;
            max-width: 300px;
            -webkit-box-shadow: 0 15px 10px rgba(0, 0, 0, 0.2);
            -moz-box-shadow: 0 15px 10px rgba(0, 0, 0, 0.2);
            box-shadow: 0 15px 10px rgba(0, 0, 0, 0.2);
            -webkit-transform: rotate(-3deg);
            -moz-transform: rotate(-3deg);
            -ms-transform: rotate(-3deg);
            -o-transform: rotate(-3deg);
            transform: rotate(-3deg);
        }

        .dv-sheet.sheet2:after {
            right: 10px;
            left: auto;
            -webkit-transform: rotate(3deg);
            -moz-transform: rotate(3deg);
            -ms-transform: rotate(3deg);
            -o-transform: rotate(3deg);
            transform: rotate(3deg);
        }

        .scrollable {
            clear: both;
        }

        .scrollable .viewport {
            overflow: hidden;
            position: relative;
        }

        .scrollable .scroll-content {
            list-style: none;
            position: absolute;
            left: 0;
            top: 0;
        }

        .scrollable .thumb .end,
        .scrollable .thumb {
            background-color: rgba(0, 0, 0, 0.6);
        }

        .scrollable .scrollbar {
            float: right;
            width: 6px;
            position: absolute;
            right: 2px;
            top: 0;
            opacity: 0;
            -webkit-transition: all 0.2s linear;
            -moz-transition: all 0.2s linear;
            -ms-transition: all 0.2s linear;
            -o-transition: all 0.2s linear;
            transition: all 0.2s linear;
        }

        .scrollable:hover .scrollbar {
            opacity: 1;
        }

        .scrollable .track {
            background-color: rgba(0, 0, 0, 0.2);
            height: 100%;
            width: 6px;
            position: relative;
            padding: 0 1px;
            border-radius: 3px;
            -moz-border-radius: 3px;
            -webkit-border-radius: 3px;
            -o-border-radius: 3px;
        }

        .scrollable .thumb {
            height: 20px;
            width: 6px;
            cursor: pointer;
            overflow: hidden;
            position: absolute;
            top: 0;
            border-radius: 3px;
            -moz-border-radius: 3px;
            -webkit-border-radius: 3px;
            -o-border-radius: 3px;
        }

        .scrollable .thumb .end {
            overflow: hidden;
            height: 5px;
            width: 6px;
            border-radius: 3px;
            -moz-border-radius: 3px;
            -webkit-border-radius: 3px;
            -o-border-radius: 3px;
        }

        .scrollable .disable {
            display: none;
        }

        .ttw-video-player {
            margin: 0 auto;
            padding: 10px 0;
            position: relative;
        }

        .ttw-video-player .player {
            width: 554px;
            height: 33px;
        }

        .ttw-video-player .jPlayer-container {
            background: #000;
            width: 554px;
            height: 312px;
        }

        .ttw-video-player .jPlayer-container img {
            display: none !important;
        }

        .ttw-video-player .player-controls {
            background: transparent;
            height: 36px;
            width: 100%;
            position: relative;
            z-index: 1;
            position: relative;
            margin-top: 10px;
        }

        .ttw-video-player .button {
            width: 25px;
            height: 25px;
            cursor: pointer;
        }

        .ttw-video-player .play,
        .ttw-video-player .pause {
            position: absolute;
            left: 0;
            top: 0;
        }



        .ttw-video-player .progress-wrapper {
            top: 0;
            margin: 0 106px 0 25px;
            width: auto;
            padding: 0 10px;
            position: relative;
            top: 9px;
        }

        .ttw-video-player .progress-bg,
        .ttw-video-player .progress,
        .ttw-video-player .elapsed,
        .ttw-video-player .volume,
        .ttw-video-player .volume-value {
            height: 5px;
        }

        .ttw-video-player .volume-value,
        .ttw-video-player .elapsed {
            background: #35c0fa;
            box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.7);
        }

        .ttw-video-player .progress-bg,
        .ttw-video-player .volume {
            box-shadow: inset 0 2px 2px rgba(0, 0, 0, 0.1);
            border: 1px solid #555;
            background: #888;
            margin: 9px 0;
        }

        .ttw-video-player .progress-bg {
            width: 100%;
        }

        .ttw-video-player .progress {
            cursor: pointer;
            width: 10%;
        }

        .ttw-video-player .volume-wrapper {
            position: absolute;
            right: 0;
            top: 0;
            width: 61px;
            padding: 0 10px 0 35px;
        }

        .ttw-video-player .volume {
            cursor: pointer;
        }

        .ttw-video-player .unused-controls {
            display: none !important;
        }

        .ttw-video-player .player:after,
        .ttw-video-player .progress-bg:after {
            clear: both;
            content: " ";
            display: block;
            font-size: 0;
            line-height: 0;
            visibility: hidden;
            width: 0;
            height: 0;
        }

        .dv-image {
            width: inherit;
            padding: 10px 0;
        }

        .pdf .document-viewer {
            padding: 40px 10px 10px 10px;
        }

        .pdf-menu {
            background: #f9f9f9;
            height: 30px;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
            box-shadow: 0 2px 2px rgba(0, 0, 0, 0.02);
            overflow: hidden;
        }

        .pdf-menu > div {
            float: left;
            font-size: 12px;
            text-shadow: 0 1px 0 #fff;
            color: #666;
            line-height: 30px;
            padding: 0 10px;
            border-right: 1px solid rgba(0, 0, 0, 0.1);
            border-left: 1px solid rgba(255, 255, 255, 0.8);
            cursor: pointer;
        }

        .pdf-menu > div:hover {
            background-color: rgba(255, 255, 255, 0.4);
        }

        .pdf-menu > div:first-child {
            border-left: none;
        }

        .pdf-menu > div:last-child {
            float: right;
            border: none;
        }

        .pdf-menu > div:last-child input,
        .pdf-menu > div:last-child input:focus {
            width: 40px;
            border: 1px solid #cecece;
            outline: none;
            box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.08);
            margin-bottom: 2px;
        }

        .clearfix:before,
        .clearfix:after {
            content: "";
            display: table;
        }

        .clearfix:after {
            clear: both;
        }

        .clearfix {
            *zoom: 1;
        }

        .clear {
            clear: both;
            float: left;
        }

        .viewport {
            -webkit-user-select: none;
            -moz-user-select: none;
            user-select: none;
        }

        [magnet="true"]:not(.element) {
            cursor: crosshair;
        }

        [magnet="true"]:not(.element):hover {
            opacity: 0.7;
        }

        .element {
            cursor: move;
        }

        .element * {
            vector-effect: non-scaling-stroke;
            -moz-user-select: none;
            user-drag: none;
        }

        .connection-wrap {
            fill: none;
            stroke: black;
            stroke-width: 15;
            stroke-linecap: round;
            stroke-linejoin: round;
            opacity: 0;
            cursor: move;
        }

        .connection-wrap:hover {
            opacity: 0.4;
            stroke-opacity: 0.4;
        }

        .connection {
            fill: none;
            stroke-linejoin: round;
        }

        .marker-source,
        .marker-target {
            vector-effect: non-scaling-stroke;
        }

        .marker-vertices {
            opacity: 0;
            cursor: move;
        }

        .marker-arrowheads {
            opacity: 0;
            cursor: move;
            cursor: -webkit-grab;
            cursor: -moz-grab;
        }

        .link-tools {
            opacity: 0;
            cursor: pointer;
        }

        .link-tools .tool-options {
            display: none;
        }

        .link-tools .tool-remove circle {
            fill: red;
        }

        .link-tools .tool-remove path {
            fill: white;
        }

        .link:hover .marker-vertices,
        .link:hover .marker-arrowheads,
        .link:hover .link-tools {
            opacity: 1;
        }

        .marker-vertex {
            fill: #1abc9c;
        }

        .marker-vertex:hover {
            fill: #34495e;
            stroke: none;
        }

        .marker-arrowhead {
            fill: #1abc9c;
        }

        .marker-arrowhead:hover {
            fill: #f39c12;
            stroke: none;
        }

        .marker-vertex-remove {
            cursor: pointer;
            opacity: 0.1;
            fill: white;
        }

        .marker-vertex-group:hover .marker-vertex-remove {
            opacity: 1;
        }

        .marker-vertex-remove-area {
            opacity: 0.1;
            cursor: pointer;
        }

        .marker-vertex-group:hover .marker-vertex-remove-area {
            opacity: 1;
        }

        text.highlighted {
            fill: red;
        }

        .highlighted {
            outline: 2px solid red;
            opacity: 0.7 \9;
        }

        @-moz-document url-prefix() {
            .highlighted {
                opacity: 0.7;
            }
        }

        doesnotexist:-o-prefocus,
        .highlighted {
            opacity: 0.7;
        }

        .TextBlock .fobj body {
            background-color: transparent;
            margin: 0;
        }

        .TextBlock .fobj div {
            text-align: center;
            vertical-align: middle;
            display: table-cell;
            padding: 0 5px 0 5px;
        }

        .bwizard {
            position: relative;
        }

        .bwizard .well {
            margin: 3px 0;
        }

        .bwizard-steps {
            margin: 0;
            padding: 0;
            display: inline-block;
            background: #fff;
        }

        .bwizard-steps li {
            display: inline-block;
            position: relative;
            margin-right: 5px;
            line-height: 18px;
            list-style: none;
            padding: 12px 17px 10px 30px;
            background: #efefef;
            zoom: 1;
            *display: inline;
            *padding-left: 17px;
        }

        .bwizard-steps li:first-child {
            -moz-border-radius: 4px 0 0 4px;
            -webkit-border-radius: 4px 0 0 4px;
            border-radius: 4px 0 0 4px;
            padding-left: 12px;
        }

        .bwizard-steps li:first-child:before {
            border: none;
        }

        .bwizard-steps li:last-child {
            -moz-border-radius: 0 4px 4px 0;
            -webkit-border-radius: 0 4px 4px 0;
            border-radius: 0 4px 4px 0;
            margin-right: 0;
        }

        .bwizard-steps li:last-child:after {
            border: none;
        }

        .bwizard-steps li:before {
            position: absolute;
            left: 0;
            top: 0;
            height: 0;
            width: 0;
            border-bottom: 20px inset transparent;
            border-left: 20px solid #fff;
            border-top: 20px inset transparent;
            content: "";
        }

        .bwizard-steps li:after {
            position: absolute;
            right: -20px;
            top: 0;
            height: 0;
            width: 0;
            border-bottom: 20px inset transparent;
            border-left: 20px solid #efefef;
            border-top: 20px inset transparent;
            content: "";
            z-index: 2;
        }

        .bwizard-steps .label {
            margin: 0 5px 0 0;
            padding: 1px 5px 2px;
            position: relative;
            top: -1px;
        }

        .bwizard-steps .active {
            color: #fff;
            background: #007acc;
        }

        .bwizard-steps .active:after {
            border-left-color: #007acc;
        }

        .bwizard-steps a {
            color: #333;
        }

        .bwizard-steps .active a {
            color: #fff;
            cursor: default;
        }

        .bwizard-steps a:hover {
            text-decoration: none;
        }

        .bwizard-steps.clickable li:not(.active) {
            cursor: pointer;
        }

        .bwizard-steps.clickable li:hover:not(.active) {
            background: #ccc;
        }

        .bwizard-steps.clickable li:hover:not(.active) a {
            color: #08c;
        }

        .bwizard-steps.clickable li:hover:not(.active):after {
            border-left-color: #ccc;
        }

        @media (max-width: 480px) {
            .bwizard-steps li,
            .bwizard-steps li.active,
            .bwizard-steps li:first-child,
            .bwizard-steps li:last-child {
                background-color: transparent;
                padding: 0;
                margin-right: 0;
            }

            .bwizard-steps li:after,
            .bwizard-steps li:before {
                border: none;
            }
        }

        .bwizard .scroll-arrow {
            border: 1px solid #ddd;
            color: #428bca;
            cursor: pointer;
            float: left;
            height: 29px;
            margin-bottom: -1px;
            padding-left: 2px;
            padding-top: 10px;
            width: 20px;
        }

        .bwizard .scroll-arrow:hover {
            background-color: #eee;
        }

        .bwizard .tabs-fixed-container {
            float: left;
            height: 42px;
            overflow: hidden;
            width: 100%;
        }

        .bwizard .tabs-fixed-container li {
            white-space: nowrap;
        }

        .bwizard .tabs-movable-container {
            position: relative;
        }

        .jcrop-holder {
            direction: ltr;
            text-align: left;
        }

        .jcrop-vline,
        .jcrop-hline {
            font-size: 0;
            position: absolute;
        }

        .jcrop-vline {
            height: 100%;
            width: 1px !important;
        }

        .jcrop-vline.right {
            right: 0;
        }

        .jcrop-hline {
            height: 1px !important;
            width: 100%;
        }

        .jcrop-hline.bottom {
            bottom: 0;
        }

        .jcrop-tracker {
            height: 100%;
            width: 100%;
            -webkit-tap-highlight-color: transparent;
            -webkit-touch-callout: none;
            -webkit-user-select: none;
        }

        .jcrop-handle {
            background-color: #333;
            border: 1px #eee solid;
            width: 7px;
            height: 7px;
            font-size: 1px;
        }

        .jcrop-handle.ord-n {
            left: 50%;
            margin-left: -4px;
            margin-top: -4px;
            top: 0;
        }

        .jcrop-handle.ord-s {
            bottom: 0;
            left: 50%;
            margin-bottom: -4px;
            margin-left: -4px;
        }

        .jcrop-handle.ord-e {
            margin-right: -4px;
            margin-top: -4px;
            right: 0;
            top: 50%;
        }

        .jcrop-handle.ord-w {
            left: 0;
            margin-left: -4px;
            margin-top: -4px;
            top: 50%;
        }

        .jcrop-handle.ord-nw {
            left: 0;
            margin-left: -4px;
            margin-top: -4px;
            top: 0;
        }

        .jcrop-handle.ord-ne {
            margin-right: -4px;
            margin-top: -4px;
            right: 0;
            top: 0;
        }

        .jcrop-handle.ord-se {
            bottom: 0;
            margin-bottom: -4px;
            margin-right: -4px;
            right: 0;
        }

        .jcrop-handle.ord-sw {
            bottom: 0;
            left: 0;
            margin-bottom: -4px;
            margin-left: -4px;
        }

        .jcrop-dragbar.ord-n,
        .jcrop-dragbar.ord-s {
            height: 7px;
            width: 100%;
        }

        .jcrop-dragbar.ord-e,
        .jcrop-dragbar.ord-w {
            height: 100%;
            width: 7px;
        }

        .jcrop-dragbar.ord-n {
            margin-top: -4px;
        }

        .jcrop-dragbar.ord-s {
            bottom: 0;
            margin-bottom: -4px;
        }

        .jcrop-dragbar.ord-e {
            margin-right: -4px;
            right: 0;
        }

        .jcrop-dragbar.ord-w {
            margin-left: -4px;
        }

        .jcrop-light .jcrop-vline,
        .jcrop-light .jcrop-hline {
            background: #fff;
            filter: alpha(opacity=70) !important;
            opacity: 0.7 !important;
        }

        .jcrop-light .jcrop-handle {
            -moz-border-radius: 3px;
            -webkit-border-radius: 3px;
            background-color: #000;
            border-color: #fff;
            border-radius: 3px;
        }

        .jcrop-dark .jcrop-vline,
        .jcrop-dark .jcrop-hline {
            background: #000;
            filter: alpha(opacity=70) !important;
            opacity: 0.7 !important;
        }

        .jcrop-dark .jcrop-handle {
            -moz-border-radius: 3px;
            -webkit-border-radius: 3px;
            background-color: #fff;
            border-color: #000;
            border-radius: 3px;
        }

        .solid-line .jcrop-vline,
        .solid-line .jcrop-hline {
            background: #fff;
        }

        .jcrop-holder img,
        img.jcrop-preview {
            max-width: none;
        }

        .clearfix:before,
        .clearfix:after {
            content: "";
            display: block;
            height: 0;
            overflow: hidden;
        }

        .clearfix:after {
            clear: both;
        }

        .clearfix {
            zoom: 1;
        }

        #plusgallery {
            zoom: 1;
            color: #222;
            position: relative;
            z-index: 1;
            text-align: left;
        }

        #plusgallery.loaded {
            background: none;
        }

        #plusgallery ul {
            list-style-type: none !important;
            margin: 0 !important;
            padding: 0 !important;
        }

        #plusgallery * {
            box-sizing: border-box;
            -moz-box-sizing: border-box;
        }

        #plusgallery a,
        #pgzoomview a {
            display: block;
            -webkit-transition: all 0.3s ease-out 0.1s;
            -moz-transition: all 0.3s ease-out 0.1s;
            -ms-transition: all 0.3s ease-out 0.1s;
            -o-transition: all 0.3s ease-out 0.1s;
            transition: all 0.3s ease-out 0.1s;
            -webkit-backface-visibility: hidden;
            text-decoration: none;
        }

        #plusgallery a {
            background-color: #fff;
            display: block;
            color: #222;
            -webkit-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05), 1px 1px 5px rgba(0, 0, 0, 0.3);
            -moz-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05), 1px 1px 5px rgba(0, 0, 0, 0.3);
            box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05), 1px 1px 5px rgba(0, 0, 0, 0.3);
        }

        #plusgallery a:hover,
        #pgzoomview a:hover {
            background-color: #38beea;
        }

        #plusgallery a img {
            display: block;
            max-width: 100%;
        }

        .spacer {
            position: relative;
            z-index: 1;
        }

        #pgalbums {
            clear: both;
        }

        .pgalbumthumb {
            float: left;
            width: 17.91666666666%;
            max-width: 172px;
            margin: 1.04166666666%;
        }

        #plusgallery .pgalbumlink {
            position: relative;
            overflow: hidden;
            display: block;
            width: 100%;
            padding: 3%;
        }

        .pgalbumimg {
            display: block;
            width: 100%;
            border: none;
            position: relative;
            z-index: 1;
            background-position: 50% 50%;
            background-size: cover;
            background-repeat: no-repeat;
        }

        .pgalbumthumb span {
            position: absolute;
            display: block;
        }

        .pgalbumtitle {
            bottom: 3%;
            left: 3%;
            background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.65) 100%);
            background: -webkit-gradient(
                linear,
                left top,
                left bottom,
                color-stop(0%, rgba(0, 0, 0, 0)),
                color-stop(100%, rgba(0, 0, 0, 0.65))
            );
            background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.65) 100%);
            background: -o-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.65) 100%);
            background: -ms-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.65) 100%);
            background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.65) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000',endColorstr='#a6000000',GradientType=0);
            color: #fff;
            text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.4);
            font-size: 1.12em;
            z-index: 7;
            padding: 2em 6% 6% 6%;
            width: 94%;
        }

        .pgalbumthumb .pgloading {
            width: 95.4545454545455%;
            height: 95.4545454545455%;
            top: 2.3809523809524%;
            left: 2.3809523809524%;
            z-index: 6;
        }

        .oldie .pgalbumthumb .pgloading {
            top: 3%;
            left: 3%;
        }

        .pgplus {
            background: #fff;
            line-height: 1em;
            padding: 0.3em;
            font-size: 1.8em;
            color: #fff;
            position: absolute;
            top: -1.5em;
            width: 1.5em;
            right: -1.5em;
            z-index: 7;
            text-align: center;
            font-weight: 100;
            -webkit-transition: all 0.3s ease-out 0.1s;
            -moz-transition: all 0.3s ease-out 0.1s;
            -ms-transition: all 0.3s ease-out 0.1s;
            -o-transition: all 0.3s ease-out 0.1s;
            transition: all 0.3s ease-out 0.1s;
        }

        .pgalbumlink:hover .pgplus {
            background: #38beea;
            top: 0;
            right: 0;
        }

        #pgthumbview {
            clear: both;
            display: none;
        }

        #plusgallery #pgthumbcrumbs {
            margin: 10px 10px 0 10px !important;
            color: #222;
            border-bottom: 1px solid #222;
        }

        #pgthumbcrumbs li {
            padding: 9px 0.7em 0 0.7em;
            float: left;
            line-height: 1em;
            height: 30px;
        }

        #pgthumbcrumbs li#pgthumbhome {
            cursor: pointer;
            -webkit-transition: all 0.3s ease-out;
            -moz-transition: all 0.3s ease-out;
            -ms-transition: all 0.3s ease-out;
            -o-transition: all 0.3s ease-out;
            transition: all 0.3s ease-out;
            font-size: 1.5em;
            line-height: 0.6em;
            padding-left: 0.4em;
            padding-right: 0.4em;
            border-right: 1px solid #222;
        }

        #pgthumbcrumbs li#pgthumbhome:hover {
            background-color: #38beea;
            -webkit-box-shadow: inset 1px 1px 5px rgba(0, 0, 0, 0.5);
            -moz-box-shadow: inset 1px 1px 5px rgba(0, 0, 0, 0.5);
            box-shadow: inset 1px 1px 5px rgba(0, 0, 0, 0.5);
            color: #fff;
        }

        .pgthumb {
            float: left;
            width: 14.5833333333333%;
            height: 14.5833333333333%;
            max-width: 140px;
            margin: 10px 1.0417%;
            display: block;
            position: relative;
        }

        .oldie .pgthumb {
            width: 140px;
            height: 140px;
            margin: 10px;
        }

        .pgthumb a {
            width: 100%;
            height: 100%;
            padding: 5px !important;
            margin: 0;
        }

        .oldie .pgthumb a {
            width: 130px;
            height: 130px;
            padding: 5px;
        }

        .pgthumb img {
            width: 100%;
            border: none;
            max-width: 130px;
        }

        #pgzoomview {
            position: absolute;
            display: none;
            padding: 0;
            margin: 0;
            list-style-type: none;
            width: 100%;
            height: 100%;
            z-index: -1;
            background: transparent;
            -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr=#CC000000,endColorstr=#CC000000)";
            background: rgba(0, 0, 0, 0.8);
            text-align: center;
            z-index: 1000;
            top: 0;
            left: 0;
        }


        #pgzoomview.fixed {
            position: fixed;
        }

        #pgzoomscroll {
            position: absolute;
            overflow-x: scroll;
            overflow-y: none;
            -webkit-overflow-scrolling: touch;
            width: 100%;
            height: 100%;
            z-index: 1050;
        }

        #pgzoom {
            list-style-type: none;
            margin: 0;
            padding: 0;
            height: 100%;
            -webkit-transform: translateZ(0);
        }

        .pgzoomslide {
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            float: left;
            overflow: hidden;
            text-align: center;
            position: relative;
            -webkit-transform: translate3d(0, 0, 0);
        }

        .pgzoomslide.loading {
            background-position: 50% 50%;
            background-repeat: no-repeat;
            position: relative;
        }

        .pgzoomspacer {
            display: inline-block;
            width: 1px;
            height: 100%;
            vertical-align: middle;
        }

        .pgzoomcenter {
            vertical-align: middle;
            display: inline-block;
            text-align: center;
        }

        .pgzoomimg {
            max-width: 80%;
            max-height: 75%;
            -webkit-box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.9);
            -moz-box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.9);
            box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.9);
            vertical-align: middle;
            opacity: 0;
            -webkit-transition: opacity 0.4s ease-out;
            -moz-transition: opacity 0.4s ease-out;
            -ms-transition: opacity 0.4s ease-out;
            -o-transition: opacity 0.4s ease-out;
            transition: opacity 0.4s ease-out;
            vertical-align: middle;
            margin-bottom: 2em;
        }

        .pgzoomimg.active {
            opacity: 1;
        }

        .oldie .pgzoomimg {
            max-width: none;
            max-height: none;
        }

        .pgzoomcaption {
            margin: 1.5em 0;
            color: #fff;
            font-size: 0.9em;
            opacity: 1;
            position: absolute;
            left: 0;
            padding-left: 20%;
            padding-right: 20%;
            box-sizing: border-box;
            -moz-box-sizing: border-box;
            width: 100%;
            bottom: 0;
            text-align: center;
            -webkit-transition: opacity 0.5s ease-out;
            -moz-transition: opacity 0.5s ease-out;
            -ms-transition: opacity 0.5s ease-out;
            -o-transition: opacity 0.5s ease-out;
            transition: opacity 0.5s ease-out;
        }

        .pgzoomcaptionloading {
            display: none;
            min-height: 32px;
            margin: 1.5em 0;
            color: #fff;
            z-index: 1200;
            font-size: 0.9em;
            opacity: 1;
            position: absolute;
            left: 0;
            padding-left: 20%;
            padding-right: 20%;
            box-sizing: border-box;
            -moz-box-sizing: border-box;
            width: 100%;
            bottom: 200px;
            text-align: center;
            -webkit-transition: opacity 0.5s ease-out;
            -moz-transition: opacity 0.5s ease-out;
            -ms-transition: opacity 0.5s ease-out;
            -o-transition: opacity 0.5s ease-out;
            transition: opacity 0.5s ease-out;
        }

        .pgzoomarrow {
            position: fixed;
            width: 52px;
            height: 100px;
            top: 50%;
            margin-top: -50px;
            overflow: hidden;
            text-indent: -5000px;
            z-index: 1051;
        }

        .pgzoomarrow:hover {
            -webkit-box-shadow: inset 1px 1px 6px rgba(0, 0, 0, 0.3);
            -moz-box-shadow: inset 1px 1px 6px rgba(0, 0, 0, 0.3);
            box-shadow: inset 1px 1px 6px rgba(0, 0, 0, 0.3);
        }

        #pgprevious {
            left: 0;
            -webkit-border-top-right-radius: 3px;
            -webkit-border-bottom-right-radius: 3px;
            -moz-border-radius-topright: 3px;
            -moz-border-radius-bottomright: 3px;
            border-top-right-radius: 3px;
            border-bottom-right-radius: 3px;
            background-position: 0 50%;
        }

        #pgnext {
            right: 0;
            -webkit-border-top-left-radius: 3px;
            -webkit-border-bottom-left-radius: 3px;
            -moz-border-radius-topleft: 3px;
            -moz-border-radius-bottomleft: 3px;
            border-top-left-radius: 3px;
            border-bottom-left-radius: 3px;
            background-position: 100% 50%;
        }

        #pgzoomclose {
            position: absolute;
            right: 0;
            top: 0;
            -webkit-border-bottom-left-radius: 3px;
            -moz-border-radius-bottomleft: 3px;
            border-bottom-left-radius: 3px;
            background-position: 100% 50%;
            width: 52px;
            height: 52px;
            background-repeat: no-repeat;
            background-position: 50% 50%;
            overflow: hidden;
            text-indent: -5000px;
            z-index: 1051;
        }

        #pgzoomclose:hover {
            -webkit-box-shadow: inset 1px 1px 6px rgba(0, 0, 0, 0.3);
            -moz-box-shadow: inset 1px 1px 6px rgba(0, 0, 0, 0.3);
            box-shadow: inset 1px 1px 6px rgba(0, 0, 0, 0.3);
        }

        #pgcredit {
            text-align: center;
            clear: both;
            padding-top: 10px;
            padding-bottom: 10px;
        }

        #plusgallery #pgcredit a {
            display: inline-block;
            color: #222;
            letter-spacing: 1px;
            background-color: transparent;
            -webkit-box-shadow: none;
            -moz-box-shadow: none;
            box-shadow: none;
            padding: 0.3em 0.5em 0.3em 0.4em;
            font-size: 10px;
            text-transform: uppercase;
        }

        #pgcredit a:hover {
            background-color: #38beea !important;
            -webkit-box-shadow: inset 1px 1px 3px rgba(0, 0, 0, 0.5) !important;
            -moz-box-shadow: inset 1px 1px 3px rgba(0, 0, 0, 0.5) !important;
            box-shadow: inset 1px 1px 3px rgba(0, 0, 0, 0.5) !important;
        }

        #plusgallery #pgcredit a span {
            color: #666;
            -webkit-transition: color 0.3s ease-out;
            -moz-transition: color 0.3s ease-out;
            -ms-transition: color 0.3s ease-out;
            -o-transition: color 0.3s ease-out;
            transition: color 0.3s ease-out;
        }

        #plusgallery #pgcredit a:hover span {
            color: #fff;
        }

        @media only screen and (max-width: 768px) {
            .pgalbumthumb,
            .pgthumb {
                margin: 1.302083333333%;
            }

            .pgalbumthumb {
                width: 22.395833333333%;
            }

            .pgthumb {
                width: 17.395833333333%;
            }
        }

        @media only screen and (max-width: 480px) {
            .pgalbumthumb,
            .pgthumb {
                margin: 2.083333333333%;
            }

            .pgalbumthumb {
                width: 29.16666666666%;
            }

            .pgthumb {
                width: 20.833333333333%;
            }

            .pgzoomarrow {
                width: 36px;
                height: 60px;
            }

            #pgprevious {
                background-position: -6px 50%;
            }

            #pgnext {
                background-position: -66px 50%;
            }

            #pgzoomclose {
                width: 36px;
                height: 36px;
            }
        }

        @media only screen and (max-width: 320px) {
            .pgalbumthumb,
            .pgthumb {
                margin: 3.125%;
            }

            .pgalbumthumb {
                width: 43.75%;
            }

            .pgthumb {
                width: 27.083333333333%;
            }
        }

        

        .redactor_box ::selection {
            background: #ffff9e;
        }

        .redactor_box ::-moz-selection {
            background: #ffff9e;
        }

        .redactor_box img::selection {
            background: transparent;
        }

        .redactor_box img::-moz-selection {
            background: transparent;
        }

        .redactor_box {
            position: relative;
            overflow: visible;
            background: #fff;
        }

        .redactor_box iframe {
            display: block;
            margin: 0;
            padding: 0;
            border: 1px solid #eee;
        }

        .redactor_box textarea {
            position: relative;
            display: block;
            overflow: auto;
            margin: 0;
            padding: 0;
            width: 100%;
            outline: none;
            border: none;
            background-color: #222;
            box-shadow: none;
            color: #ccc;
            font-size: 13px;
            font-family: Menlo, Monaco, monospace, sans-serif;
            resize: none;
        }

        .redactor_box textarea:focus {
            outline: none;
        }

        .redactor_box,
        .redactor_box textarea {
            z-index: auto !important;
        }

        .redactor_box_fullscreen {
            z-index: 99 !important;
        }

        #redactor_modal_overlay,
        #redactor_modal,
        .redactor_dropdown {
            z-index: 1060 !important;
        }

        body .redactor_air {
            position: absolute;
            z-index: 502;
        }

        body .redactor_box_fullscreen {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
        }

        .redactor-link-tooltip {
            position: absolute;
            z-index: 49999;
            padding: 10px;
            line-height: 1;
            display: inline-block;
            background-color: #000;
            color: #555 !important;
        }

        .redactor-link-tooltip,
        .redactor-link-tooltip a {
            font-size: 12px;
            font-family: Arial, Helvetica, Verdana, Tahoma, sans-serif;
        }

        .redactor-link-tooltip a {
            color: #ccc;
            margin: 0 5px;
            text-decoration: none;
        }

        .redactor-link-tooltip a:hover {
            color: #fff;
        }

        #redactor-image-box img {
            width: 100%;
        }

        .redactor_editor {
            position: relative;
            overflow: auto;
            margin: 0 !important;
            padding: 10px 20px;
            padding-bottom: 5px;
            outline: none;
            background: none;
            background: #fff;
            box-shadow: none !important;
            white-space: normal;
            border: 1px solid #eee;
        }

        .redactor_editor:focus {
            outline: none;
        }

        .redactor_editor div,
        .redactor_editor p,
        .redactor_editor ul,
        .redactor_editor ol,
        .redactor_editor table,
        .redactor_editor dl,
        .redactor_editor blockquote,
        .redactor_editor pre,
        .redactor_editor h1,
        .redactor_editor h2,
        .redactor_editor h3,
        .redactor_editor h4,
        .redactor_editor h5,
        .redactor_editor h6 {
            font-family: Arial, Helvetica, Verdana, Tahoma, sans-serif;
        }

        .redactor_editor code,
        .redactor_editor pre {
            font-family: Menlo, Monaco, monospace, sans-serif;
        }

        .redactor_editor div,
        .redactor_editor p,
        .redactor_editor ul,
        .redactor_editor ol,
        .redactor_editor table,
        .redactor_editor dl,
        .redactor_editor blockquote,
        .redactor_editor pre {
            font-size: 14px;
            line-height: 1.5em;
        }

        .redactor_editor a {
            color: #15c !important;
            text-decoration: underline !important;
        }

        .redactor_editor .redactor_placeholder {
            color: #999 !important;
            display: block !important;
        }

        .redactor_editor object,
        .redactor_editor embed,
        .redactor_editor video,
        .redactor_editor img {
            max-width: 100%;
            width: auto;
        }

        .redactor_editor video,
        .redactor_editor img {
            height: auto;
        }

        .redactor_editor div,
        .redactor_editor p,
        .redactor_editor ul,
        .redactor_editor ol,
        .redactor_editor table,
        .redactor_editor dl,
        .redactor_editor blockquote,
        .redactor_editor pre {
            margin: 0;
            margin-bottom: 10px !important;
            border: none;
            background: none !important;
            box-shadow: none !important;
        }

        .redactor_editor iframe,
        .redactor_editor object,
        .redactor_editor hr {
            margin-bottom: 15px !important;
        }

        .redactor_editor blockquote {
            margin-left: 1.5em !important;
            padding-left: 0 !important;
            color: #777;
            font-style: italic !important;
        }

        .redactor_editor ul,
        .redactor_editor ol {
            padding-left: 2em !important;
        }

        .redactor_editor ul ul,
        .redactor_editor ol ol,
        .redactor_editor ul ol,
        .redactor_editor ol ul {
            margin: 2px !important;
            padding: 0 !important;
            padding-left: 2em !important;
            border: none;
        }

        .redactor_editor dl dt {
            font-weight: bold;
        }

        .redactor_editor dd {
            margin-left: 1em;
        }

        .redactor_editor table {
            border-collapse: collapse;
            font-size: 1em !important;
        }

        .redactor_editor table td {
            padding: 5px !important;
            border: 1px solid #ddd;
            vertical-align: top;
        }

        .redactor_editor table thead td {
            border-bottom: 2px solid #000 !important;
            font-weight: bold !important;
        }

        .redactor_editor code {
            background-color: #d8d7d7 !important;
        }

        .redactor_editor pre {
            overflow: auto;
            padding: 1em !important;
            border: 1px solid #ddd !important;
            border-radius: 3px !important;
            background: #f8f8f8 !important;
            white-space: pre;
            font-size: 90% !important;
        }

        .redactor_editor hr {
            display: block;
            height: 1px;
            border: 0;
            border-top: 1px solid #ccc;
        }

        .redactor_editor h1,
        .redactor_editor h2,
        .redactor_editor h3,
        .redactor_editor h4,
        .redactor_editor h5,
        .redactor_editor h6 {
            margin-top: 0 !important;
            padding: 0 !important;
            background: none;
            color: #000;
            font-weight: bold;
        }

        .redactor_editor h1 {
            font-size: 36px !important;
            line-height: 1.111em !important;
            margin-bottom: 0.15em !important;
        }

        .redactor_editor h2 {
            font-size: 30px !important;
            line-height: 1.111em !important;
            margin-bottom: 0.25em !important;
        }

        .redactor_editor h3 {
            font-size: 24px !important;
            line-height: 1.333em !important;
            margin-bottom: 0.2em !important;
        }

        .redactor_editor h4 {
            font-size: 18px !important;
            line-height: 1.5em !important;
            margin-bottom: 0.2em !important;
        }

        .redactor_editor h5 {
            font-size: 1em !important;
            line-height: 1.6em !important;
            margin-bottom: 0.25em !important;
        }

        .redactor_editor h6 {
            font-size: 0.8em !important;
            line-height: 1.6em !important;
            text-transform: uppercase;
            margin-bottom: 0.3em !important;
        }

        .redactor_toolbar {
            position: relative;
            top: 0;
            left: 0;
            margin: 0 !important;
            padding: 0 !important;
            list-style: none !important;
            font-size: 14px !important;
            font-family: Arial, Helvetica, Verdana, Tahoma, sans-serif;
            line-height: 1 !important;
            background: #fff;
            border: none;
            box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
            z-index: 3;
        }

        .redactor_toolbar:after {
            content: "";
            display: table;
            clear: both;
        }

        .redactor_toolbar.redactor-toolbar-overflow {
            overflow-y: auto;
            height: 29px;
            white-space: nowrap;
        }

        .redactor_toolbar.redactor-toolbar-external {
            z-index: 999;
            box-shadow: none;
            border: 1px solid rgba(0, 0, 0, 0.1);
        }

        body .redactor_air .redactor_toolbar {
            padding-right: 2px !important;
        }

        .redactor_toolbar li {
            vertical-align: top;
            display: inline-block;
            margin: 0 !important;
            padding: 0 !important;
            outline: none;
            list-style: none !important;
            -webkit-box-sizing: content-box;
            -moz-box-sizing: content-box;
            box-sizing: content-box;
        }

        .redactor_toolbar li a {
            display: block;
            color: #333;
            text-align: center;
            padding: 9px 10px;
            outline: none;
            border: none;
            text-decoration: none;
            cursor: pointer;
            zoom: 1;
            -webkit-box-sizing: content-box;
            -moz-box-sizing: content-box;
            box-sizing: content-box;
        }

        .redactor_toolbar li a:hover {
            outline: none;
            background-color: #1f78d8;
            color: #fff;
        }

        .redactor_toolbar li a:hover i:before {
            color: #fff;
        }

        .redactor_toolbar li a:active,
        .redactor_toolbar li a.redactor_act {
            outline: none;
            background-color: #ccc;
            color: #444;
        }

        .redactor_toolbar li a.redactor-btn-image {
            width: 14px;
            height: 14px;
            background-position: center center;
            background-repeat: no-repeat;
        }

        .redactor_button_disabled {
            filter: alpha(opacity=30);
            -moz-opacity: 0.3;
            opacity: 0.3;
        }

        .redactor_button_disabled:hover {
            outline: none;
            background-color: transparent !important;
            cursor: default;
        }

        .redactor_toolbar li a.fa-redactor-btn {
            display: inline-block;
            padding: 9px 10px 8px 10px;
            line-height: 1;
        }

        .redactor_toolbar.redactor-toolbar-typewriter {
            box-shadow: none;
            background: rgba(240, 240, 240, 0.9);
        }

        .redactor_toolbar.redactor-toolbar-typewriter li a:hover {
            outline: none;
            background-color: #1f78d8;
            color: #fff;
        }

        .redactor_toolbar.redactor-toolbar-typewriter li a:active,
        .redactor_toolbar.redactor-toolbar-typewriter li a.redactor_act {
            outline: none;
            background-color: #ccc;
            color: #444;
        }

        .re-icon {
            font-family: "RedactorFont";
            speak: none;
            font-style: normal;
            font-weight: normal;
            font-variant: normal;
            text-transform: none;
            line-height: 1;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
        }

        .re-icon i:before {
            position: relative;
            font-size: 14px;
        }

        .re-video:before {
            content: "";
        }

        .re-unorderedlist:before {
            content: "";
        }

        .re-undo:before {
            content: "";
        }

        .re-underline:before {
            content: "";
        }

        .re-textdirection:before {
            content: "";
        }

        .re-fontcolor:before {
            content: "";
        }

        .re-table:before {
            content: "";
        }

        .re-redo:before {
            content: "";
        }

        .re-quote:before {
            content: "";
        }

        .re-outdent:before {
            content: "";
        }

        .re-orderedlist:before {
            content: "";
        }

        .re-link:before {
            content: "";
        }

        .re-horizontalrule:before {
            content: "";
        }

        .re-italic:before {
            content: "";
        }

        .re-indent:before {
            content: "";
        }

        .re-image:before {
            content: "";
        }

        .re-fullscreen:before {
            content: "";
        }

        .re-normalscreen:before {
            content: "";
        }

        .re-formatting:before {
            content: "";
        }

        .re-fontsize:before {
            content: "";
        }

        .re-fontfamily:before {
            content: "";
        }

        .re-deleted:before {
            content: "";
        }

        .re-html:before {
            content: "";
        }

        .re-clips:before {
            content: "";
        }

        .re-bold:before {
            content: "";
        }

        .re-backcolor:before {
            content: "";
        }

        .re-file:before {
            content: "";
        }

        .re-alignright:before {
            content: "";
        }

        .re-alignment:before,
        .re-alignleft:before {
            content: "";
        }

        .re-alignjustify:before {
            content: "";
        }

        .re-aligncenter:before {
            content: "";
        }

        .re-gallery:before {
            content: "";
        }

        .redactor_format_blockquote {
            padding-left: 10px;
            color: #666 !important;
            font-style: italic;
        }

        .redactor_format_pre {
            font-family: monospace, sans-serif;
        }

        .redactor_format_h1,
        .redactor_format_h2,
        .redactor_format_h3,
        .redactor_format_h4,
        .redactor_format_h5 {
            font-weight: bold;
        }

        .redactor_format_h1 {
            font-size: 30px;
            line-height: 36px;
        }

        .redactor_format_h2 {
            font-size: 24px;
            line-height: 36px;
        }

        .redactor_format_h3 {
            font-size: 20px;
            line-height: 30px;
        }

        .redactor_format_h4 {
            font-size: 16px;
            line-height: 26px;
        }

        .redactor_format_h5 {
            font-size: 14px;
            line-height: 23px;
        }

        .redactor-toolbar-typewriter .redactor_dropdown .redactor_format_h1,
        .redactor-toolbar-typewriter .redactor_dropdown .redactor_format_h2,
        .redactor-toolbar-typewriter .redactor_dropdown .redactor_format_h3,
        .redactor-toolbar-typewriter .redactor_dropdown .redactor_format_h4,
        .redactor-toolbar-typewriter .redactor_dropdown .redactor_format_h5 {
            font-size: 1em;
            line-height: 1.6em;
            text-transform: uppercase;
        }

        .redactor-toolbar-typewriter .redactor_dropdown .redactor_format_h2 {
            font-size: 0.85em;
        }

        .redactor_editor.redactor-editor-typewriter {
            background: #f5f5f5 !important;
            padding: 25px 50px !important;
        }

        .redactor_editor.redactor-editor-typewriter div,
        .redactor_editor.redactor-editor-typewriter p,
        .redactor_editor.redactor-editor-typewriter ul,
        .redactor_editor.redactor-editor-typewriter ol,
        .redactor_editor.redactor-editor-typewriter table,
        .redactor_editor.redactor-editor-typewriter dl,
        .redactor_editor.redactor-editor-typewriter blockquote,
        .redactor_editor.redactor-editor-typewriter pre,
        .redactor_editor.redactor-editor-typewriter h1,
        .redactor_editor.redactor-editor-typewriter h2,
        .redactor_editor.redactor-editor-typewriter h3,
        .redactor_editor.redactor-editor-typewriter h4,
        .redactor_editor.redactor-editor-typewriter h5,
        .redactor_editor.redactor-editor-typewriter h6 {
            font-family: "Courier New", "Lucida Console", Consolas, Monaco, monospace, sans-serif;
            font-size: 18px !important;
            line-height: 1.5em !important;
            margin-bottom: 1.5em !important;
        }

        .redactor_editor.redactor-editor-typewriter h2 {
            font-size: 14px !important;
        }

        .redactor_editor.redactor-editor-typewriter h1,
        .redactor_editor.redactor-editor-typewriter h2,
        .redactor_editor.redactor-editor-typewriter h3,
        .redactor_editor.redactor-editor-typewriter h4,
        .redactor_editor.redactor-editor-typewriter h5,
        .redactor_editor.redactor-editor-typewriter h6 {
            text-transform: uppercase;
        }

        .redactor_editor.redactor-editor-typewriter a {
            color: #000 !important;
            text-decoration: underline !important;
        }

        .redactor_editor.redactor_editor_wym {
            padding: 10px 7px 0 7px !important;
            background: #f6f6f6 !important;
        }

        .redactor_editor.redactor_editor_wym div,
        .redactor_editor.redactor_editor_wym p,
        .redactor_editor.redactor_editor_wym ul,
        .redactor_editor.redactor_editor_wym ol,
        .redactor_editor.redactor_editor_wym table,
        .redactor_editor.redactor_editor_wym dl,
        .redactor_editor.redactor_editor_wym pre,
        .redactor_editor.redactor_editor_wym h1,
        .redactor_editor.redactor_editor_wym h2,
        .redactor_editor.redactor_editor_wym h3,
        .redactor_editor.redactor_editor_wym h4,
        .redactor_editor.redactor_editor_wym h5,
        .redactor_editor.redactor_editor_wym h6,
        .redactor_editor.redactor_editor_wym blockquote {
            margin-top: 0;
            margin-bottom: 5px !important;
            padding: 10px !important;
            border: 1px solid #e4e4e4 !important;
            background-color: #fff !important;
            z-index: 0;
        }

        .redactor_editor.redactor_editor_wym blockquote:before {
            content: "";
        }

        .redactor_editor.redactor_editor_wym img {
            position: relative;
            z-index: 1;
        }

        .redactor_editor.redactor_editor_wym div {
            border: 1px dotted #aaa !important;
        }

        .redactor_editor.redactor_editor_wym pre {
            border: 2px dashed #e4e4e4 !important;
            background-color: #f8f8f8 !important;
        }

        .redactor_editor.redactor_editor_wym ul,
        .redactor_editor.redactor_editor_wym ol {
            padding-left: 2em !important;
        }

        .redactor_editor.redactor_editor_wym ul li ul,
        .redactor_editor.redactor_editor_wym ol li ul,
        .redactor_editor.redactor_editor_wym ul li ol,
        .redactor_editor.redactor_editor_wym ol li ol {
            border: none !important;
        }

        .redactor_dropdown {
            position: absolute;
            top: 28px;
            left: 0;
            padding: 10px;
            width: 200px;
            background-color: #fff;
            box-shadow: 0 1px 5px #bbb;
            font-size: 13px;
            font-family: Helvetica, Arial, Verdana, Tahoma, sans-serif;
            line-height: 21px;
        }

        .redactor-toolbar-typewriter .redactor_dropdown {
            font-family: "Courier New", "Lucida Console", Consolas, Monaco, monospace, sans-serif;
            background-color: #f5f5f5;
        }

        .redactor_separator_drop {
            padding: 0 !important;
            border-top: 1px solid #ddd;
            font-size: 0;
            line-height: 0;
        }

        .redactor_dropdown a {
            display: block;
            padding: 3px 5px;
            color: #000;
            text-decoration: none;
        }

        .redactor_dropdown a:hover {
            background-color: #dde4ef;
            color: #444 !important;
            text-decoration: none;
        }

        #redactor_modal_overlay {
            position: fixed;
            top: 0;
            left: 0;
            margin: auto;
            width: 100%;
            height: 100%;
            background-color: #000 !important;
            filter: alpha(opacity=30);
            -moz-opacity: 0.3;
            opacity: 0.3;
        }

        #redactor_modal {
            position: fixed;
            top: 50%;
            left: 50%;
            padding: 0;
            background: #fff;
            color: #000;
            font-size: 12px !important;
            font-family: Arial, Helvetica, Verdana, Tahoma, sans-serif;
            box-shadow: 0 1px 10px rgba(0, 0, 0, 0.5);
        }

        #redactor_modal header {
            padding: 20px 30px 5px 30px;
            font-size: 16px;
        }

        #redactor_modal section {
            padding: 20px 30px;
        }

        #redactor_modal label {
            display: block !important;
            float: none !important;
            margin: 10px 0 3px 0 !important;
            padding: 0 !important;
            font-size: 12px !important;
        }

        #redactor_modal footer:after {
            content: "";
            display: table;
            clear: both;
        }

        #redactor_modal footer div {
            float: left;
        }

        #redactor_modal input[type="radio"],
        #redactor_modal input[type="checkbox"] {
            position: relative;
            top: -1px;
        }

        #redactor_modal input[type="text"],
        #redactor_modal input[type="password"],
        #redactor_modal input[type="email"],
        #redactor_modal textarea {
            position: relative;
            z-index: 2;
            margin: 0;
            padding: 1px 2px;
            height: 23px;
            border: 1px solid #ccc;
            border-radius: 1px;
            background-color: #fff;
            box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2) inset;
            color: #333;
            font-size: 13px;
            font-family: Arial, Helvetica, Verdana, Tahoma, sans-serif;
            line-height: 1;
            -moz-transition: border 0.3s ease-in;
            transition: border 0.3s ease-in;
        }

        #redactor_modal textarea {
            display: block;
            margin-top: 4px;
            line-height: 1.4em;
        }

        #redactor_modal input:focus,
        #redactor_modal textarea:focus {
            outline: none;
            border-color: #5ca9e4;
            box-shadow: 0 0 0 2px rgba(70, 161, 231, 0.3), 0 1px 2px rgba(0, 0, 0, 0.2) inset;
        }

        #redactor_modal_close {
            position: absolute;
            top: 5px;
            right: 3px;
            width: 20px;
            height: 20px;
            color: #999;
            font-size: 26px;
            cursor: pointer;
        }

        #redactor_modal_close:hover {
            color: #000;
        }

        .redactor_input {
            width: 99%;
            font-size: 14px;
        }

        .redactor_modal_box {
            overflow: auto;
            margin-bottom: 10px;
            height: 350px;
        }

        #redactor_image_box {
            overflow: auto;
            margin-bottom: 10px;
            height: 270px;
        }

        #redactor_image_box_select {
            display: block;
            margin-bottom: 15px !important;
            width: 200px;
        }

        #redactor_image_box img {
            margin-right: 10px;
            margin-bottom: 10px;
            max-width: 100px;
            cursor: pointer;
        }

        #redactor_tabs {
            margin-bottom: 18px;
        }

        #redactor_tabs a {
            display: inline-block;
            margin-right: 2px;
            padding: 4px 14px;
            border: 1px solid #d2d2d2;
            border-radius: 3px;
            background: #fff;
            color: #000;
            text-decoration: none;
            line-height: 1;
        }

        #redactor_tabs a:hover,
        #redactor_tabs a.redactor_tabs_act {
            border-color: #eee;
            color: #999 !important;
            text-decoration: none !important;
        }

        .redactor_modal_btn_hidden {
            display: none;
        }

        #redactor_modal footer button {
            position: relative;
            width: 100%;
            padding: 10px 16px;
            margin: 0;
            outline: none;
            border: none;
            background-color: #ddd;
            color: #000;
            text-align: center;
            text-decoration: none;
            font-weight: normal;
            font-size: 12px;
            font-family: Arial, Helvetica, Verdana, Tahoma, sans-serif;
            line-height: 1;
            cursor: pointer;
        }

        #redactor_modal footer button:hover {
            color: #777;
            background: none;
            background: #bbb;
            text-decoration: none;
        }

        #redactor_modal footer button.redactor_modal_delete_btn {
            background: none;
            color: #fff;
            background-color: #b52525;
        }

        #redactor_modal footer button.redactor_modal_delete_btn:hover {
            color: rgba(255, 255, 255, 0.6);
            background-color: #881b1b;
        }

        #redactor_modal footer button.redactor_modal_action_btn {
            background: none;
            color: #fff;
            background-color: #2461b5;
        }

        #redactor_modal footer button.redactor_modal_action_btn:hover {
            color: rgba(255, 255, 255, 0.6);
            background-color: #1a4580;
        }

        .redactor_droparea {
            position: relative;
            margin: auto;
            margin-bottom: 5px;
            width: 100%;
        }

        .redactor_droparea .redactor_dropareabox {
            position: relative;
            z-index: 1;
            padding: 60px 0;
            width: 99%;
            border: 1px dashed #ddd;
            background: #fff;
            text-align: center;
        }

        .redactor_droparea .redactor_dropareabox,
        .redactor_dropalternative {
            color: #555;
            font-size: 12px;
        }

        .redactor_dropalternative {
            margin: 4px 0 2px 0;
        }

        .redactor_dropareabox.hover {
            border-color: #aaa;
            background: #efe3b8;
        }

        .redactor_dropareabox.error {
            border-color: #dcc3c3;
            background: #f7e5e5;
        }

        .redactor_dropareabox.drop {
            border-color: #e0e5d6;
            background: #f4f4ee;
        }

        #redactor-progress {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            z-index: 1000000;
            height: 10px;
        }

        #redactor-progress span {
            display: block;
            width: 100%;
            height: 100%;
            background-color: #3d58a8;
            background-image: -webkit-linear-gradient(
                45deg,
                rgba(255, 255, 255, 0.2) 25%,
                transparent 25%,
                transparent 50%,
                rgba(255, 255, 255, 0.2) 50%,
                rgba(255, 255, 255, 0.2) 75%,
                transparent 75%,
                transparent
            );
            background-image: -o-linear-gradient(
                45deg,
                rgba(255, 255, 255, 0.2) 25%,
                transparent 25%,
                transparent 50%,
                rgba(255, 255, 255, 0.2) 50%,
                rgba(255, 255, 255, 0.2) 75%,
                transparent 75%,
                transparent
            );
            background-image: linear-gradient(
                45deg,
                rgba(255, 255, 255, 0.2) 25%,
                transparent 25%,
                transparent 50%,
                rgba(255, 255, 255, 0.2) 50%,
                rgba(255, 255, 255, 0.2) 75%,
                transparent 75%,
                transparent
            );
            -webkit-animation: progress-bar-stripes 2s linear infinite;
            -o-animation: progress-bar-stripes 2s linear infinite;
            animation: progress-bar-stripes 2s linear infinite;
            background-size: 40px 40px;
        }

        @-webkit-keyframes progress-bar-stripes {
            from {
                background-position: 40px 0;
            }

            to {
                background-position: 0 0;
            }
        }

        @-o-keyframes progress-bar-stripes {
            from {
                background-position: 40px 0;
            }

            to {
                background-position: 0 0;
            }
        }

        @keyframes progress-bar-stripes {
            from {
                background-position: 40px 0;
            }

            to {
                background-position: 0 0;
            }
        }


        .ui-ios-overlay {
            z-index: 99999;
            position: fixed;
            top: 50%;
            left: 50%;
            width: 200px;
            height: 200px;
            margin-left: -100px;
            margin-top: -100px;
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#cc000000,endColorstr=#cc000000);
            background: rgba(0, 0, 0, 0.8);
            -webkit-border-radius: 20px;
            -moz-border-radius: 20px;
            border-radius: 20px;
        }

        .ui-ios-overlay .title {
            color: #fff;
            font-weight: bold;
            text-align: center;
            display: block;
            font-size: 26px;
            position: absolute;
            bottom: 30px;
            left: 0;
            width: 100%;
        }

        .ui-ios-overlay img {
            display: block;
            margin: 20% auto 0 auto;
        }

        .ui-ios-overlay .spinner {
            left: 50% !important;
            top: 40% !important;
        }

        .ios-overlay-show {
            -webkit-animation-name: ios-overlay-show;
            -webkit-animation-duration: 750ms;
            -moz-animation-name: ios-overlay-show;
            -moz-animation-duration: 750ms;
            -ms-animation-name: ios-overlay-show;
            -ms-animation-duration: 750ms;
            -o-animation-name: ios-overlay-show;
            -o-animation-duration: 750ms;
            animation-name: ios-overlay-show;
            animation-duration: 750ms;
        }

        @-webkit-keyframes ios-overlay-show {
            0% {
                opacity: 0;
            }

            100% {
                opacity: 1;
            }
        }

        @-moz-keyframes ios-overlay-show {
            0% {
                opacity: 0;
            }

            100% {
                opacity: 1;
            }
        }

        @-ms-keyframes ios-overlay-show {
            0% {
                opacity: 0;
            }

            100% {
                opacity: 1;
            }
        }

        @-o-keyframes ios-overlay-show {
            0% {
                opacity: 0;
            }

            100% {
                opacity: 1;
            }
        }

        @keyframes ios-overlay-show {
            0% {
                opacity: 0;
            }

            100% {
                opacity: 1;
            }
        }

        .ios-overlay-hide {
            -webkit-animation-name: ios-overlay-hide;
            -webkit-animation-duration: 750ms;
            -webkit-animation-fill-mode: forwards;
            -moz-animation-name: ios-overlay-hide;
            -moz-animation-duration: 750ms;
            -moz-animation-fill-mode: forwards;
            -ms-animation-name: ios-overlay-hide;
            -ms-animation-duration: 750ms;
            -ms-animation-fill-mode: forwards;
            -o-animation-name: ios-overlay-hide;
            -o-animation-duration: 750ms;
            -o-animation-fill-mode: forwards;
            animation-name: ios-overlay-hide;
            animation-duration: 750ms;
            animation-fill-mode: forwards;
        }

        @-webkit-keyframes ios-overlay-hide {
            0% {
                opacity: 1;
            }

            100% {
                opacity: 0;
            }
        }

        @-moz-keyframes ios-overlay-hide {
            0% {
                opacity: 1;
            }

            100% {
                opacity: 0;
            }
        }

        @-ms-keyframes ios-overlay-hide {
            0% {
                opacity: 1;
            }

            100% {
                opacity: 0;
            }
        }

        @-o-keyframes ios-overlay-hide {
            0% {
                opacity: 1;
            }

            100% {
                opacity: 0;
            }
        }

        @keyframes ios-overlay-hide {
            0% {
                opacity: 1;
            }

            100% {
                opacity: 0;
            }
        }

        .em {
            width: 20px;
            height: 20px;
            display: -moz-inline-stack;
            display: inline-block;
            *display: inline;
            zoom: 1;
        }

        
        .fc {
            direction: ltr;
            text-align: left;
        }

        .fc-rtl {
            text-align: right;
        }

        body .fc {
            font-size: 1em;
        }

        .fc-unthemed th,
        .fc-unthemed td,
        .fc-unthemed hr,
        .fc-unthemed thead,
        .fc-unthemed tbody,
        .fc-unthemed .fc-row,
        .fc-unthemed .fc-popover {
            border-color: #ddd;
        }

        .fc-unthemed .fc-popover {
            background-color: #fff;
        }

        .fc-unthemed hr,
        .fc-unthemed .fc-popover .fc-header {
            background: #eee;
        }

        .fc-unthemed .fc-popover .fc-header .fc-close {
            color: #666;
        }

        .fc-unthemed .fc-today {
            background: #fcf8e3;
        }

        .fc-highlight {
            background: #bce8f1;
            opacity: 0.3;
            filter: alpha(opacity=30);
        }

        .fc-bgevent {
            background: #8fdf82;
            opacity: 0.3;
            filter: alpha(opacity=30);
        }

        .fc-nonbusiness {
            background: #ccc;
        }

        .fc-icon {
            display: inline-block;
            font-size: 2em;
            line-height: 0.5em;
            height: 0.5em;
            font-family: "Courier New", Courier, monospace;
        }

        .fc-icon-left-single-arrow:after {
            content: "‹";
            font-weight: bold;
        }

        .fc-icon-right-single-arrow:after {
            content: "›";
            font-weight: bold;
        }

        .fc-icon-left-double-arrow:after {
            content: "«";
        }

        .fc-icon-right-double-arrow:after {
            content: "»";
        }

        .fc-icon-x:after {
            content: "×";
        }

        .fc button {
            -moz-box-sizing: border-box;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            margin: 0;
            height: 2.1em;
            padding: 0 0.6em;
            font-size: 1em;
            white-space: nowrap;
            cursor: pointer;
        }

        .fc button::-moz-focus-inner {
            margin: 0;
            padding: 0;
        }

        .fc-state-default {
            border: 1px solid;
        }

        .fc-state-default.fc-corner-left {
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;
        }

        .fc-state-default.fc-corner-right {
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;
        }

        .fc button .fc-icon {
            position: relative;
            top: 0.05em;
            margin: 0 0.1em;
        }

        .fc-state-default {
            background-color: #f5f5f5;
            background-image: -moz-linear-gradient(top, #fff, #e6e6e6);
            background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#fff), to(#e6e6e6));
            background-image: -webkit-linear-gradient(top, #fff, #e6e6e6);
            background-image: -o-linear-gradient(top, #fff, #e6e6e6);
            background-image: linear-gradient(to bottom, #fff, #e6e6e6);
            background-repeat: repeat-x;
            border-color: #e6e6e6 #e6e6e6 #bfbfbf;
            border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
            color: #333;
            text-shadow: 0 1px 1px rgba(255, 255, 255, 0.75);
            box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05);
        }

        .fc-state-hover,
        .fc-state-down,
        .fc-state-active,
        .fc-state-disabled {
            color: #333;
            background-color: #e6e6e6;
        }

        .fc-state-hover {
            color: #333;
            text-decoration: none;
            background-position: 0 -15px;
            -webkit-transition: background-position 0.1s linear;
            -moz-transition: background-position 0.1s linear;
            -o-transition: background-position 0.1s linear;
            transition: background-position 0.1s linear;
        }

        .fc-state-down,
        .fc-state-active {
            background-color: #ccc;
            background-image: none;
            box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.15), 0 1px 2px rgba(0, 0, 0, 0.05);
        }

        .fc-state-disabled {
            cursor: default;
            background-image: none;
            opacity: 0.65;
            filter: alpha(opacity=65);
            box-shadow: none;
        }

        .fc-button-group {
            display: inline-block;
        }

        .fc .fc-button-group > * {
            float: left;
            margin: 0 0 0 -1px;
        }

        .fc .fc-button-group > :first-child {
            margin-left: 0;
        }

        .fc-popover {
            position: absolute;
            box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
        }

        .fc-popover .fc-header {
            padding: 2px 4px;
        }

        .fc-popover .fc-header .fc-title {
            margin: 0 2px;
        }

        .fc-popover .fc-header .fc-close {
            cursor: pointer;
        }

        .fc-ltr .fc-popover .fc-header .fc-title,
        .fc-rtl .fc-popover .fc-header .fc-close {
            float: left;
        }

        .fc-rtl .fc-popover .fc-header .fc-title,
        .fc-ltr .fc-popover .fc-header .fc-close {
            float: right;
        }

        .fc-unthemed .fc-popover {
            border-width: 1px;
            border-style: solid;
        }

        .fc-unthemed .fc-popover .fc-header .fc-close {
            font-size: 25px;
            margin-top: 4px;
        }

        .fc-popover > .ui-widget-header + .ui-widget-content {
            border-top: 0;
        }

        .fc hr {
            height: 0;
            margin: 0;
            padding: 0 0 2px;
            border-style: solid;
            border-width: 1px 0;
        }

        .fc-clear {
            clear: both;
        }

        .fc-bg,
        .fc-bgevent-skeleton,
        .fc-highlight-skeleton,
        .fc-helper-skeleton {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
        }

        .fc-bg {
            bottom: 0;
        }

        .fc-bg table {
            height: 100%;
        }

        .fc table {
            width: 100%;
            table-layout: fixed;
            border-collapse: collapse;
            border-spacing: 0;
            font-size: 1em;
        }

        .fc th {
            text-align: center;
        }

        .fc th,
        .fc td {
            border-style: solid;
            border-width: 1px;
            padding: 0;
            vertical-align: top;
        }

        .fc td.fc-today {
            border-style: double;
        }

        .fc .fc-row {
            border-style: solid;
            border-width: 0;
        }

        .fc-row table {
            border-left: 0 hidden transparent;
            border-right: 0 hidden transparent;
            border-bottom: 0 hidden transparent;
        }

        .fc-row:first-child table {
            border-top: 0 hidden transparent;
        }

        .fc-row {
            position: relative;
        }

        .fc-row .fc-bg {
            z-index: 1;
        }

        .fc-row .fc-bgevent-skeleton,
        .fc-row .fc-highlight-skeleton {
            bottom: 0;
        }

        .fc-row .fc-bgevent-skeleton table,
        .fc-row .fc-highlight-skeleton table {
            height: 100%;
        }

        .fc-row .fc-highlight-skeleton td,
        .fc-row .fc-bgevent-skeleton td {
            border-color: transparent;
        }

        .fc-row .fc-bgevent-skeleton {
            z-index: 2;
        }

        .fc-row .fc-highlight-skeleton {
            z-index: 3;
        }

        .fc-row .fc-content-skeleton {
            position: relative;
            z-index: 4;
            padding-bottom: 2px;
        }

        .fc-row .fc-helper-skeleton {
            z-index: 5;
        }

        .fc-row .fc-content-skeleton td,
        .fc-row .fc-helper-skeleton td {
            background: none;
            border-color: transparent;
            border-bottom: 0;
        }

        .fc-row .fc-content-skeleton tbody td,
        .fc-row .fc-helper-skeleton tbody td {
            border-top: 0;
        }

        .fc-scroller {
            overflow-y: scroll;
            overflow-x: hidden;
        }

        .fc-scroller > * {
            position: relative;
            width: 100%;
            overflow: hidden;
        }

        .fc-event {
            position: relative;
            display: block;
            font-size: 0.85em;
            line-height: 1.3;
            border-radius: 3px;
            border: 1px solid #3a87ad;
            background-color: #3a87ad;
            font-weight: normal;
        }

        .fc-event,
        .fc-event:hover,
        .ui-widget .fc-event {
            color: #fff;
            text-decoration: none;
        }

        .fc-event[href],
        .fc-event.fc-draggable {
            cursor: pointer;
        }

        .fc-not-allowed,
        .fc-not-allowed .fc-event {
            cursor: not-allowed;
        }

        .fc-day-grid-event {
            margin: 1px 2px 0;
            padding: 0 1px;
        }

        .fc-ltr .fc-day-grid-event.fc-not-start,
        .fc-rtl .fc-day-grid-event.fc-not-end {
            margin-left: 0;
            border-left-width: 0;
            padding-left: 1px;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }

        .fc-ltr .fc-day-grid-event.fc-not-end,
        .fc-rtl .fc-day-grid-event.fc-not-start {
            margin-right: 0;
            border-right-width: 0;
            padding-right: 1px;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }

        .fc-day-grid-event > .fc-content {
            white-space: nowrap;
            overflow: hidden;
        }

        .fc-day-grid-event .fc-time {
            font-weight: bold;
        }

        .fc-day-grid-event .fc-resizer {
            position: absolute;
            top: 0;
            bottom: 0;
            width: 7px;
        }

        .fc-ltr .fc-day-grid-event .fc-resizer {
            right: -3px;
            cursor: e-resize;
        }

        .fc-rtl .fc-day-grid-event .fc-resizer {
            left: -3px;
            cursor: w-resize;
        }

        a.fc-more {
            margin: 1px 3px;
            font-size: 0.85em;
            cursor: pointer;
            text-decoration: none;
        }

        a.fc-more:hover {
            text-decoration: underline;
        }

        .fc-limited {
            display: none;
        }

        .fc-day-grid .fc-row {
            z-index: 1;
        }

        .fc-more-popover {
            z-index: 2;
            width: 220px;
        }

        .fc-more-popover .fc-event-container {
            padding: 10px;
        }

        .fc-toolbar {
            text-align: center;
            margin-bottom: 1em;
        }

        .fc-toolbar .fc-left {
            float: left;
        }

        .fc-toolbar .fc-right {
            float: right;
        }

        .fc-toolbar .fc-center {
            display: inline-block;
        }

        .fc .fc-toolbar > * > * {
            float: left;
            margin-left: 0.75em;
        }

        .fc .fc-toolbar > * > :first-child {
            margin-left: 0;
        }

        .fc-toolbar h2 {
            margin: 0;
        }

        .fc-toolbar button {
            position: relative;
        }

        .fc-toolbar .fc-state-hover,
        .fc-toolbar .ui-state-hover {
            z-index: 2;
        }

        .fc-toolbar .fc-state-down {
            z-index: 3;
        }

        .fc-toolbar .fc-state-active,
        .fc-toolbar .ui-state-active {
            z-index: 4;
        }

        .fc-toolbar button:focus {
            z-index: 5;
        }

        .fc-view-container *,
        .fc-view-container *:before,
        .fc-view-container *:after {
            -webkit-box-sizing: content-box;
            -moz-box-sizing: content-box;
            box-sizing: content-box;
        }

        .fc-view,
        .fc-view > table {
            position: relative;
            z-index: 1;
        }

        .fc-basicWeek-view .fc-content-skeleton,
        .fc-basicDay-view .fc-content-skeleton {
            padding-top: 1px;
            padding-bottom: 1em;
        }

        .fc-basic-view tbody .fc-row {
            min-height: 4em;
        }

        .fc-row.fc-rigid {
            overflow: hidden;
        }

        .fc-row.fc-rigid .fc-content-skeleton {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
        }

        .fc-basic-view .fc-week-number,
        .fc-basic-view .fc-day-number {
            padding: 0 2px;
        }

        .fc-basic-view td.fc-week-number span,
        .fc-basic-view td.fc-day-number {
            padding-top: 2px;
            padding-bottom: 2px;
        }

        .fc-basic-view .fc-week-number {
            text-align: center;
        }

        .fc-basic-view .fc-week-number span {
            display: inline-block;
            min-width: 1.25em;
        }

        .fc-ltr .fc-basic-view .fc-day-number {
            text-align: right;
        }

        .fc-rtl .fc-basic-view .fc-day-number {
            text-align: left;
        }

        .fc-day-number.fc-other-month {
            opacity: 0.3;
            filter: alpha(opacity=30);
        }

        .fc-agenda-view .fc-day-grid {
            position: relative;
            z-index: 2;
        }

        .fc-agenda-view .fc-day-grid .fc-row {
            min-height: 3em;
        }

        .fc-agenda-view .fc-day-grid .fc-row .fc-content-skeleton {
            padding-top: 1px;
            padding-bottom: 1em;
        }

        .fc .fc-axis {
            vertical-align: middle;
            padding: 0 4px;
            white-space: nowrap;
        }

        .fc-ltr .fc-axis {
            text-align: right;
        }

        .fc-rtl .fc-axis {
            text-align: left;
        }

        .ui-widget td.fc-axis {
            font-weight: normal;
        }

        .fc-time-grid-container,
        .fc-time-grid {
            position: relative;
            z-index: 1;
        }

        .fc-time-grid {
            min-height: 100%;
        }

        .fc-time-grid table {
            border: 0 hidden transparent;
        }

        .fc-time-grid > .fc-bg {
            z-index: 1;
        }

        .fc-time-grid .fc-slats,
        .fc-time-grid > hr {
            position: relative;
            z-index: 2;
        }

        .fc-time-grid .fc-bgevent-skeleton,
        .fc-time-grid .fc-content-skeleton {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
        }

        .fc-time-grid .fc-bgevent-skeleton {
            z-index: 3;
        }

        .fc-time-grid .fc-highlight-skeleton {
            z-index: 4;
        }

        .fc-time-grid .fc-content-skeleton {
            z-index: 5;
        }

        .fc-time-grid .fc-helper-skeleton {
            z-index: 6;
        }

        .fc-slats td {
            height: 1.5em;
            border-bottom: 0;
        }

        .fc-slats .fc-minor td {
            border-top-style: dotted;
        }

        .fc-slats .ui-widget-content {
            background: none;
        }

        .fc-time-grid .fc-highlight-container {
            position: relative;
        }

        .fc-time-grid .fc-highlight {
            position: absolute;
            left: 0;
            right: 0;
        }

        .fc-time-grid .fc-event-container,
        .fc-time-grid .fc-bgevent-container {
            position: relative;
        }

        .fc-ltr .fc-time-grid .fc-event-container {
            margin: 0 2.5% 0 2px;
        }

        .fc-rtl .fc-time-grid .fc-event-container {
            margin: 0 2px 0 2.5%;
        }

        .fc-time-grid .fc-event,
        .fc-time-grid .fc-bgevent {
            position: absolute;
            z-index: 1;
        }

        .fc-time-grid .fc-bgevent {
            left: 0;
            right: 0;
        }

        .fc-time-grid-event.fc-not-start {
            border-top-width: 0;
            padding-top: 1px;
            border-top-left-radius: 0;
            border-top-right-radius: 0;
        }

        .fc-time-grid-event.fc-not-end {
            border-bottom-width: 0;
            padding-bottom: 1px;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        }

        .fc-time-grid-event {
            overflow: hidden;
        }

        .fc-time-grid-event > .fc-content {
            position: relative;
            z-index: 2;
        }

        .fc-time-grid-event .fc-time,
        .fc-time-grid-event .fc-title {
            padding: 0 1px;
        }

        .fc-time-grid-event .fc-time {
            font-size: 0.85em;
            white-space: nowrap;
        }

        .fc-time-grid-event .fc-bg {
            z-index: 1;
            background: #fff;
            opacity: 0.25;
            filter: alpha(opacity=25);
        }

        .fc-time-grid-event.fc-short .fc-content {
            white-space: nowrap;
        }

        .fc-time-grid-event.fc-short .fc-time,
        .fc-time-grid-event.fc-short .fc-title {
            display: inline-block;
            vertical-align: top;
        }

        .fc-time-grid-event.fc-short .fc-time span {
            display: none;
        }

        .fc-time-grid-event.fc-short .fc-time:before {
            content: attr(data-start);
        }

        .fc-time-grid-event.fc-short .fc-time:after {
            content: " - ";
        }

        .fc-time-grid-event.fc-short .fc-title {
            font-size: 0.85em;
            padding: 0;
        }

        .fc-time-grid-event .fc-resizer {
            position: absolute;
            z-index: 3;
            left: 0;
            right: 0;
            bottom: 0;
            height: 8px;
            overflow: hidden;
            line-height: 8px;
            font-size: 11px;
            font-family: monospace;
            text-align: center;
            cursor: s-resize;
        }

        .fc-time-grid-event .fc-resizer:after {
            content: "=";
        }

        #orgChartSvg {
            left: 475px !important;
            top: 120px !important;
        }

        table.dataTable {
            width: 100%;
            margin: 0 auto;
            clear: both;
            border-collapse: separate;
            border-spacing: 0;
        }

        table.dataTable thead th,
        table.dataTable tfoot th {
            font-weight: bold;
        }

        table.dataTable thead th,
        table.dataTable thead td {
            padding: 10px 18px;
            border-bottom: 1px solid #111;
        }

        table.dataTable thead th:active,
        table.dataTable thead td:active {
            outline: none;
        }

        table.dataTable tfoot th,
        table.dataTable tfoot td {
            padding: 10px 18px 6px 18px;
            border-top: 1px solid #111;
        }

        table.dataTable thead .sorting,
        table.dataTable thead .sorting_asc,
        table.dataTable thead .sorting_desc {
            cursor: pointer;
            *cursor: hand;
        }

        table.dataTable thead .sorting,
        table.dataTable thead .sorting_asc,
        table.dataTable thead .sorting_desc,
        table.dataTable thead .sorting_asc_disabled,
        table.dataTable thead .sorting_desc_disabled {
            background-repeat: no-repeat;
            background-position: center right;
        }

        

        table.dataTable tbody tr {
            background-color: #fff;
        }

        table.dataTable tbody tr.selected {
            background-color: #b0bed9;
        }

        table.dataTable tbody th,
        table.dataTable tbody td {
            padding: 8px 10px;
        }

        table.dataTable.row-border tbody th,
        table.dataTable.row-border tbody td,
        table.dataTable.display tbody th,
        table.dataTable.display tbody td {
            border-top: 1px solid #ddd;
        }

        table.dataTable.row-border tbody tr:first-child th,
        table.dataTable.row-border tbody tr:first-child td,
        table.dataTable.display tbody tr:first-child th,
        table.dataTable.display tbody tr:first-child td {
            border-top: none;
        }

        table.dataTable.cell-border tbody th,
        table.dataTable.cell-border tbody td {
            border-top: 1px solid #ddd;
            border-right: 1px solid #ddd;
        }

        table.dataTable.cell-border tbody tr th:first-child,
        table.dataTable.cell-border tbody tr td:first-child {
            border-left: 1px solid #ddd;
        }

        table.dataTable.cell-border tbody tr:first-child th,
        table.dataTable.cell-border tbody tr:first-child td {
            border-top: none;
        }

        table.dataTable.stripe tbody tr.odd,
        table.dataTable.display tbody tr.odd {
            background-color: #f9f9f9;
        }

        table.dataTable.stripe tbody tr.odd.selected,
        table.dataTable.display tbody tr.odd.selected {
            background-color: #acbad4;
        }

        table.dataTable.hover tbody tr:hover,
        table.dataTable.display tbody tr:hover {
            background-color: #f6f6f6;
        }

        table.dataTable.hover tbody tr:hover.selected,
        table.dataTable.display tbody tr:hover.selected {
            background-color: #aab7d1;
        }

        table.dataTable.order-column tbody tr > .sorting_1,
        table.dataTable.order-column tbody tr > .sorting_2,
        table.dataTable.order-column tbody tr > .sorting_3,
        table.dataTable.display tbody tr > .sorting_1,
        table.dataTable.display tbody tr > .sorting_2,
        table.dataTable.display tbody tr > .sorting_3 {
            background-color: #fafafa;
        }

        table.dataTable.order-column tbody tr.selected > .sorting_1,
        table.dataTable.order-column tbody tr.selected > .sorting_2,
        table.dataTable.order-column tbody tr.selected > .sorting_3,
        table.dataTable.display tbody tr.selected > .sorting_1,
        table.dataTable.display tbody tr.selected > .sorting_2,
        table.dataTable.display tbody tr.selected > .sorting_3 {
            background-color: #acbad5;
        }

        table.dataTable.display tbody tr.odd > .sorting_1,
        table.dataTable.order-column.stripe tbody tr.odd > .sorting_1 {
            background-color: #f1f1f1;
        }

        table.dataTable.display tbody tr.odd > .sorting_2,
        table.dataTable.order-column.stripe tbody tr.odd > .sorting_2 {
            background-color: #f3f3f3;
        }

        table.dataTable.display tbody tr.odd > .sorting_3,
        table.dataTable.order-column.stripe tbody tr.odd > .sorting_3 {
            background-color: #f5f5f5;
        }

        table.dataTable.display tbody tr.odd.selected > .sorting_1,
        table.dataTable.order-column.stripe tbody tr.odd.selected > .sorting_1 {
            background-color: #a6b4cd;
        }

        table.dataTable.display tbody tr.odd.selected > .sorting_2,
        table.dataTable.order-column.stripe tbody tr.odd.selected > .sorting_2 {
            background-color: #a8b5cf;
        }

        table.dataTable.display tbody tr.odd.selected > .sorting_3,
        table.dataTable.order-column.stripe tbody tr.odd.selected > .sorting_3 {
            background-color: #a9b7d1;
        }

        table.dataTable.display tbody tr.even > .sorting_1,
        table.dataTable.order-column.stripe tbody tr.even > .sorting_1 {
            background-color: #fafafa;
        }

        table.dataTable.display tbody tr.even > .sorting_2,
        table.dataTable.order-column.stripe tbody tr.even > .sorting_2 {
            background-color: #fcfcfc;
        }

        table.dataTable.display tbody tr.even > .sorting_3,
        table.dataTable.order-column.stripe tbody tr.even > .sorting_3 {
            background-color: #fefefe;
        }

        table.dataTable.display tbody tr.even.selected > .sorting_1,
        table.dataTable.order-column.stripe tbody tr.even.selected > .sorting_1 {
            background-color: #acbad5;
        }

        table.dataTable.display tbody tr.even.selected > .sorting_2,
        table.dataTable.order-column.stripe tbody tr.even.selected > .sorting_2 {
            background-color: #aebcd6;
        }

        table.dataTable.display tbody tr.even.selected > .sorting_3,
        table.dataTable.order-column.stripe tbody tr.even.selected > .sorting_3 {
            background-color: #afbdd8;
        }

        table.dataTable.display tbody tr:hover > .sorting_1,
        table.dataTable.order-column.hover tbody tr:hover > .sorting_1 {
            background-color: #eaeaea;
        }

        table.dataTable.display tbody tr:hover > .sorting_2,
        table.dataTable.order-column.hover tbody tr:hover > .sorting_2 {
            background-color: #ececec;
        }

        table.dataTable.display tbody tr:hover > .sorting_3,
        table.dataTable.order-column.hover tbody tr:hover > .sorting_3 {
            background-color: #efefef;
        }

        table.dataTable.display tbody tr:hover.selected > .sorting_1,
        table.dataTable.order-column.hover tbody tr:hover.selected > .sorting_1 {
            background-color: #a2aec7;
        }

        table.dataTable.display tbody tr:hover.selected > .sorting_2,
        table.dataTable.order-column.hover tbody tr:hover.selected > .sorting_2 {
            background-color: #a3b0c9;
        }

        table.dataTable.display tbody tr:hover.selected > .sorting_3,
        table.dataTable.order-column.hover tbody tr:hover.selected > .sorting_3 {
            background-color: #a5b2cb;
        }

        table.dataTable.no-footer {
            border-bottom: 1px solid #111;
        }

        table.dataTable.nowrap th,
        table.dataTable.nowrap td {
            white-space: nowrap;
        }

        table.dataTable.compact thead th,
        table.dataTable.compact thead td {
            padding: 4px 17px 4px 4px;
        }

        table.dataTable.compact tfoot th,
        table.dataTable.compact tfoot td {
            padding: 4px;
        }

        table.dataTable.compact tbody th,
        table.dataTable.compact tbody td {
            padding: 4px;
        }

        table.dataTable th.dt-left,
        table.dataTable td.dt-left {
            text-align: left;
        }

        table.dataTable th.dt-center,
        table.dataTable td.dt-center,
        table.dataTable td.dataTables_empty {
            text-align: center;
        }

        table.dataTable th.dt-right,
        table.dataTable td.dt-right {
            text-align: right;
        }

        table.dataTable th.dt-justify,
        table.dataTable td.dt-justify {
            text-align: justify;
        }

        table.dataTable th.dt-nowrap,
        table.dataTable td.dt-nowrap {
            white-space: nowrap;
        }

        table.dataTable thead th.dt-head-left,
        table.dataTable thead td.dt-head-left,
        table.dataTable tfoot th.dt-head-left,
        table.dataTable tfoot td.dt-head-left {
            text-align: left;
        }

        table.dataTable thead th.dt-head-center,
        table.dataTable thead td.dt-head-center,
        table.dataTable tfoot th.dt-head-center,
        table.dataTable tfoot td.dt-head-center {
            text-align: center;
        }

        table.dataTable thead th.dt-head-right,
        table.dataTable thead td.dt-head-right,
        table.dataTable tfoot th.dt-head-right,
        table.dataTable tfoot td.dt-head-right {
            text-align: right;
        }

        table.dataTable thead th.dt-head-justify,
        table.dataTable thead td.dt-head-justify,
        table.dataTable tfoot th.dt-head-justify,
        table.dataTable tfoot td.dt-head-justify {
            text-align: justify;
        }

        table.dataTable thead th.dt-head-nowrap,
        table.dataTable thead td.dt-head-nowrap,
        table.dataTable tfoot th.dt-head-nowrap,
        table.dataTable tfoot td.dt-head-nowrap {
            white-space: nowrap;
        }

        table.dataTable tbody th.dt-body-left,
        table.dataTable tbody td.dt-body-left {
            text-align: left;
        }

        table.dataTable tbody th.dt-body-center,
        table.dataTable tbody td.dt-body-center {
            text-align: center;
        }

        table.dataTable tbody th.dt-body-right,
        table.dataTable tbody td.dt-body-right {
            text-align: right;
        }

        table.dataTable tbody th.dt-body-justify,
        table.dataTable tbody td.dt-body-justify {
            text-align: justify;
        }

        table.dataTable tbody th.dt-body-nowrap,
        table.dataTable tbody td.dt-body-nowrap {
            white-space: nowrap;
        }

        table.dataTable,
        table.dataTable th,
        table.dataTable td {
            -webkit-box-sizing: content-box;
            -moz-box-sizing: content-box;
            box-sizing: content-box;
        }

        .dataTables_wrapper {
            position: relative;
            clear: both;
            *zoom: 1;
            zoom: 1;
        }

        .dataTables_wrapper .dataTables_length {
            float: left;
        }

        .dataTables_wrapper .dataTables_filter {
            float: right;
            text-align: right;
        }

        .dataTables_wrapper .dataTables_filter input {
            margin-left: 0.5em;
        }

        .dataTables_wrapper .dataTables_info {
            clear: both;
            float: left;
            padding-top: 0.755em;
        }

        .dataTables_wrapper .dataTables_paginate {
            float: right;
            text-align: right;
            padding-top: 0.25em;
        }

        .dataTables_wrapper .dataTables_paginate .paginate_button {
            box-sizing: border-box;
            display: inline-block;
            min-width: 1.5em;
            padding: 0.5em 1em;
            margin-left: 2px;
            text-align: center;
            text-decoration: none !important;
            cursor: pointer;
            *cursor: hand;
            color: #333 !important;
            border: 1px solid transparent;
            border-radius: 2px;
        }

        .dataTables_wrapper .dataTables_paginate .paginate_button.current,
        .dataTables_wrapper .dataTables_paginate .paginate_button.current:hover {
            color: #333 !important;
            border: 1px solid #979797;
            background-color: #fff;
            background: -webkit-gradient(
                linear,
                left top,
                left bottom,
                color-stop(0%, #fff),
                color-stop(100%, #dcdcdc)
            );
            background: -webkit-linear-gradient(top, #fff 0%, #dcdcdc 100%);
            background: -moz-linear-gradient(top, #fff 0%, #dcdcdc 100%);
            background: -ms-linear-gradient(top, #fff 0%, #dcdcdc 100%);
            background: -o-linear-gradient(top, #fff 0%, #dcdcdc 100%);
            background: linear-gradient(to bottom, #fff 0%, #dcdcdc 100%);
        }

        .dataTables_wrapper .dataTables_paginate .paginate_button.disabled,
        .dataTables_wrapper .dataTables_paginate .paginate_button.disabled:hover,
        .dataTables_wrapper .dataTables_paginate .paginate_button.disabled:active {
            cursor: default;
            color: #666 !important;
            border: 1px solid transparent;
            background: transparent;
            box-shadow: none;
        }

        .dataTables_wrapper .dataTables_paginate .paginate_button:hover {
            color: #fff !important;
            border: 1px solid #111;
            background-color: #585858;
            background: -webkit-gradient(
                linear,
                left top,
                left bottom,
                color-stop(0%, #585858),
                color-stop(100%, #111)
            );
            background: -webkit-linear-gradient(top, #585858 0%, #111 100%);
            background: -moz-linear-gradient(top, #585858 0%, #111 100%);
            background: -ms-linear-gradient(top, #585858 0%, #111 100%);
            background: -o-linear-gradient(top, #585858 0%, #111 100%);
            background: linear-gradient(to bottom, #585858 0%, #111 100%);
        }

        .dataTables_wrapper .dataTables_paginate .paginate_button:active {
            outline: none;
            background-color: #2b2b2b;
            background: -webkit-gradient(
                linear,
                left top,
                left bottom,
                color-stop(0%, #2b2b2b),
                color-stop(100%, #0c0c0c)
            );
            background: -webkit-linear-gradient(top, #2b2b2b 0%, #0c0c0c 100%);
            background: -moz-linear-gradient(top, #2b2b2b 0%, #0c0c0c 100%);
            background: -ms-linear-gradient(top, #2b2b2b 0%, #0c0c0c 100%);
            background: -o-linear-gradient(top, #2b2b2b 0%, #0c0c0c 100%);
            background: linear-gradient(to bottom, #2b2b2b 0%, #0c0c0c 100%);
            box-shadow: inset 0 0 3px #111;
        }

        .dataTables_wrapper .dataTables_paginate .ellipsis {
            padding: 0 1em;
        }

        .dataTables_wrapper .dataTables_processing {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 100%;
            height: 40px;
            margin-left: -50%;
            margin-top: -25px;
            padding-top: 20px;
            text-align: center;
            font-size: 1.2em;
            background-color: #fff;
            background: -webkit-gradient(
                linear,
                left top,
                right top,
                color-stop(0%, rgba(255, 255, 255, 0)),
                color-stop(25%, rgba(255, 255, 255, 0.9)),
                color-stop(75%, rgba(255, 255, 255, 0.9)),
                color-stop(100%, rgba(255, 255, 255, 0))
            );
            background: -webkit-linear-gradient(
                left,
                rgba(255, 255, 255, 0) 0%,
                rgba(255, 255, 255, 0.9) 25%,
                rgba(255, 255, 255, 0.9) 75%,
                rgba(255, 255, 255, 0) 100%
            );
            background: -moz-linear-gradient(
                left,
                rgba(255, 255, 255, 0) 0%,
                rgba(255, 255, 255, 0.9) 25%,
                rgba(255, 255, 255, 0.9) 75%,
                rgba(255, 255, 255, 0) 100%
            );
            background: -ms-linear-gradient(
                left,
                rgba(255, 255, 255, 0) 0%,
                rgba(255, 255, 255, 0.9) 25%,
                rgba(255, 255, 255, 0.9) 75%,
                rgba(255, 255, 255, 0) 100%
            );
            background: -o-linear-gradient(
                left,
                rgba(255, 255, 255, 0) 0%,
                rgba(255, 255, 255, 0.9) 25%,
                rgba(255, 255, 255, 0.9) 75%,
                rgba(255, 255, 255, 0) 100%
            );
            background: linear-gradient(
                to right,
                rgba(255, 255, 255, 0) 0%,
                rgba(255, 255, 255, 0.9) 25%,
                rgba(255, 255, 255, 0.9) 75%,
                rgba(255, 255, 255, 0) 100%
            );
        }

        .dataTables_wrapper .dataTables_length,
        .dataTables_wrapper .dataTables_filter,
        .dataTables_wrapper .dataTables_info,
        .dataTables_wrapper .dataTables_processing,
        .dataTables_wrapper .dataTables_paginate {
            color: #333;
        }

        .dataTables_wrapper .dataTables_scroll {
            clear: both;
        }

        .dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody {
            *margin-top: -1px;
            -webkit-overflow-scrolling: touch;
        }

        .dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody th,
        .dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody td {
            vertical-align: middle;
        }

        .dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody th > div.dataTables_sizing,
        .dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody td > div.dataTables_sizing {
            height: 0;
            overflow: hidden;
            margin: 0 !important;
            padding: 0 !important;
        }

        .dataTables_wrapper.no-footer .dataTables_scrollBody {
            border-bottom: 1px solid #111;
        }

        .dataTables_wrapper.no-footer div.dataTables_scrollHead table,
        .dataTables_wrapper.no-footer div.dataTables_scrollBody table {
            border-bottom: none;
        }

        .dataTables_wrapper:after {
            visibility: hidden;
            display: block;
            content: "";
            clear: both;
            height: 0;
        }

        @media screen and (max-width: 767px) {
            .dataTables_wrapper .dataTables_info,
            .dataTables_wrapper .dataTables_paginate {
                float: none;
                text-align: center;
            }

            .dataTables_wrapper .dataTables_paginate {
                margin-top: 0.5em;
            }
        }

        @media screen and (max-width: 640px) {
            .dataTables_wrapper .dataTables_length,
            .dataTables_wrapper .dataTables_filter {
                float: none;
                text-align: center;
            }

            .dataTables_wrapper .dataTables_filter {
                margin-top: 0.5em;
            }
        }

        div.dataTables_wrapper div.dataTables_filter {
            text-align: right;
        }

        div.dataTables_wrapper div.dataTables_filter input {
            margin-left: 0.5em;
        }

        div.dataTables_wrapper div.dataTables_info {
            padding-top: 10px;
            white-space: nowrap;
        }

        div.dataTables_wrapper div.dataTables_processing {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 200px;
            margin-left: -100px;
            text-align: center;
        }

        div.dataTables_wrapper div.dataTables_paginate {
            text-align: right;
        }

        div.dataTables_wrapper div.mdl-grid.dt-table {
            padding-top: 0;
            padding-bottom: 0;
        }

        div.dataTables_wrapper div.mdl-grid.dt-table > div.mdl-cell {
            margin-top: 0;
            margin-bottom: 0;
        }

        table.dataTable thead > tr > th.sorting_asc,
        table.dataTable thead > tr > th.sorting_desc,
        table.dataTable thead > tr > th.sorting,
        table.dataTable thead > tr > td.sorting_asc,
        table.dataTable thead > tr > td.sorting_desc,
        table.dataTable thead > tr > td.sorting {
            padding-right: 30px;
        }

        table.dataTable thead > tr > th:active,
        table.dataTable thead > tr > td:active {
            outline: none;
        }

        table.dataTable thead .sorting,
        table.dataTable thead .sorting_asc,
        table.dataTable thead .sorting_desc,
        table.dataTable thead .sorting_asc_disabled,
        table.dataTable thead .sorting_desc_disabled {
            cursor: pointer;
            position: relative;
        }

        table.dataTable thead .sorting:before,
        table.dataTable thead .sorting:after,
        table.dataTable thead .sorting_asc:before,
        table.dataTable thead .sorting_asc:after,
        table.dataTable thead .sorting_desc:before,
        table.dataTable thead .sorting_desc:after,
        table.dataTable thead .sorting_asc_disabled:before,
        table.dataTable thead .sorting_asc_disabled:after,
        table.dataTable thead .sorting_desc_disabled:before,
        table.dataTable thead .sorting_desc_disabled:after {
            position: absolute;
            bottom: 11px;
            display: block;
            opacity: 0.3;
            font-size: 1.3em;
        }

        table.dataTable thead .sorting:before,
        table.dataTable thead .sorting_asc:before,
        table.dataTable thead .sorting_desc:before,
        table.dataTable thead .sorting_asc_disabled:before,
        table.dataTable thead .sorting_desc_disabled:before {
            right: 1em;
            content: "↑";
        }

        table.dataTable thead .sorting:after,
        table.dataTable thead .sorting_asc:after,
        table.dataTable thead .sorting_desc:after,
        table.dataTable thead .sorting_asc_disabled:after,
        table.dataTable thead .sorting_desc_disabled:after {
            right: 0.5em;
            content: "↓";
        }

        table.dataTable thead .sorting_asc:before,
        table.dataTable thead .sorting_desc:after {
            opacity: 1;
        }

        table.dataTable thead .sorting_asc_disabled:before,
        table.dataTable thead .sorting_desc_disabled:after {
            opacity: 0;
        }

        .cd-horizontal-timeline {
            opacity: 0;
            margin: 2em auto;
            -webkit-transition: opacity 0.2s;
            -moz-transition: opacity 0.2s;
            transition: opacity 0.2s;
        }

        .cd-horizontal-timeline::before {
            content: "mobile";
            display: none;
        }

        .cd-horizontal-timeline.loaded {
            opacity: 1;
        }

        .cd-horizontal-timeline .timeline {
            position: relative;
            height: 100px;
            width: 90%;
            max-width: 800px;
            margin: 0 auto;
        }

        .cd-horizontal-timeline .events-wrapper {
            position: relative;
            height: 100%;
            margin: 0 40px;
            overflow: hidden;
        }

        .cd-horizontal-timeline .events-wrapper::after,
        .cd-horizontal-timeline .events-wrapper::before {
            content: "";
            position: absolute;
            z-index: 2;
            top: 0;
            height: 100%;
            width: 20px;
        }

        .cd-horizontal-timeline .events-wrapper::before {
            left: 0;
            background-image: -webkit-linear-gradient(left, #f8f8f8, rgba(248, 248, 248, 0));
            background-image: linear-gradient(to right, #f8f8f8, rgba(248, 248, 248, 0));
        }

        .cd-horizontal-timeline .events-wrapper::after {
            right: 0;
            background-image: -webkit-linear-gradient(right, #f8f8f8, rgba(248, 248, 248, 0));
            background-image: linear-gradient(to left, #f8f8f8, rgba(248, 248, 248, 0));
        }

        .cd-horizontal-timeline .events {
            position: absolute;
            z-index: 1;
            left: 0;
            top: 49px;
            height: 2px;
            background: #dfdfdf;
            -webkit-transition: -webkit-transform 0.4s;
            -moz-transition: -moz-transform 0.4s;
            transition: transform 0.4s;
        }

        .cd-horizontal-timeline .filling-line {
            position: absolute;
            z-index: 1;
            left: 0;
            top: 0;
            height: 100%;
            width: 100%;
            background-color: #7b9d6f;
            -webkit-transform: scaleX(0);
            -moz-transform: scaleX(0);
            -ms-transform: scaleX(0);
            -o-transform: scaleX(0);
            transform: scaleX(0);
            -webkit-transform-origin: left center;
            -moz-transform-origin: left center;
            -ms-transform-origin: left center;
            -o-transform-origin: left center;
            transform-origin: left center;
            -webkit-transition: -webkit-transform 0.3s;
            -moz-transition: -moz-transform 0.3s;
            transition: transform 0.3s;
        }

        .cd-horizontal-timeline .events a {
            position: absolute;
            bottom: 0;
            z-index: 2;
            text-align: center;
            font-size: 1.3rem;
            padding-bottom: 15px;
            color: #383838;
            -webkit-transform: translateZ(0);
            -moz-transform: translateZ(0);
            -ms-transform: translateZ(0);
            -o-transform: translateZ(0);
            transform: translateZ(0);
        }

        .cd-horizontal-timeline .events a::after {
            content: "";
            position: absolute;
            left: 50%;
            right: auto;
            -webkit-transform: translateX(-50%);
            -moz-transform: translateX(-50%);
            -ms-transform: translateX(-50%);
            -o-transform: translateX(-50%);
            transform: translateX(-50%);
            bottom: -5px;
            height: 12px;
            width: 12px;
            border-radius: 50%;
            border: 2px solid #dfdfdf;
            background-color: #f8f8f8;
            -webkit-transition: background-color 0.3s, border-color 0.3s;
            -moz-transition: background-color 0.3s, border-color 0.3s;
            transition: background-color 0.3s, border-color 0.3s;
        }

        .no-touch .cd-horizontal-timeline .events a:hover::after {
            background-color: #7b9d6f;
            border-color: #7b9d6f;
        }

        .cd-horizontal-timeline .events a.selected {
            pointer-events: none;
        }

        .cd-horizontal-timeline .events a.selected::after {
            background-color: #7b9d6f;
            border-color: #7b9d6f;
        }

        .cd-horizontal-timeline .events a.older-event::after {
            border-color: #7b9d6f;
        }

        @media only screen and (min-width: 1100px) {
            .cd-horizontal-timeline {
                margin: 6em auto;
            }

            .cd-horizontal-timeline::before {
                content: "desktop";
            }
        }

        .cd-timeline-navigation a {
            position: absolute;
            z-index: 1;
            top: 50%;
            bottom: auto;
            -webkit-transform: translateY(-50%);
            -moz-transform: translateY(-50%);
            -ms-transform: translateY(-50%);
            -o-transform: translateY(-50%);
            transform: translateY(-50%);
            height: 34px;
            width: 34px;
            border-radius: 50%;
            border: 2px solid #dfdfdf;
            overflow: hidden;
            color: transparent;
            text-indent: 100%;
            white-space: nowrap;
            -webkit-transition: border-color 0.3s;
            -moz-transition: border-color 0.3s;
            transition: border-color 0.3s;
        }

        .cd-timeline-navigation a::after {
            content: "";
            position: absolute;
            height: 16px;
            width: 16px;
            left: 50%;
            top: 50%;
            bottom: auto;
            right: auto;
            -webkit-transform: translateX(-50%) translateY(-50%);
            -moz-transform: translateX(-50%) translateY(-50%);
            -ms-transform: translateX(-50%) translateY(-50%);
            -o-transform: translateX(-50%) translateY(-50%);
            transform: translateX(-50%) translateY(-50%);
        }

        .cd-timeline-navigation a.prev {
            left: 0;
            -webkit-transform: translateY(-50%) rotate(180deg);
            -moz-transform: translateY(-50%) rotate(180deg);
            -ms-transform: translateY(-50%) rotate(180deg);
            -o-transform: translateY(-50%) rotate(180deg);
            transform: translateY(-50%) rotate(180deg);
        }

        .cd-timeline-navigation a.next {
            right: 0;
        }

        .no-touch .cd-timeline-navigation a:hover {
            border-color: #7b9d6f;
        }

        .cd-timeline-navigation a.inactive {
            cursor: not-allowed;
        }

        .cd-timeline-navigation a.inactive::after {
            background-position: 0 -16px;
        }

        .no-touch .cd-timeline-navigation a.inactive:hover {
            border-color: #dfdfdf;
        }

        .cd-horizontal-timeline .events-content {
            position: relative;
            width: 100%;
            margin: 2em 0;
            overflow: hidden;
            -webkit-transition: height 0.4s;
            -moz-transition: height 0.4s;
            transition: height 0.4s;
        }

        .cd-horizontal-timeline .events-content li {
            position: absolute;
            z-index: 1;
            width: 100%;
            left: 0;
            top: 0;
            -webkit-transform: translateX(-100%);
            -moz-transform: translateX(-100%);
            -ms-transform: translateX(-100%);
            -o-transform: translateX(-100%);
            transform: translateX(-100%);
            padding: 0 5%;
            opacity: 0;
            -webkit-animation-duration: 0.4s;
            -moz-animation-duration: 0.4s;
            animation-duration: 0.4s;
            -webkit-animation-timing-function: ease-in-out;
            -moz-animation-timing-function: ease-in-out;
            animation-timing-function: ease-in-out;
        }

        .cd-horizontal-timeline .events-content li.selected {
            position: relative;
            z-index: 2;
            opacity: 1;
            -webkit-transform: translateX(0);
            -moz-transform: translateX(0);
            -ms-transform: translateX(0);
            -o-transform: translateX(0);
            transform: translateX(0);
        }

        .cd-horizontal-timeline .events-content li.enter-right,
        .cd-horizontal-timeline .events-content li.leave-right {
            -webkit-animation-name: cd-enter-right;
            -moz-animation-name: cd-enter-right;
            animation-name: cd-enter-right;
        }

        .cd-horizontal-timeline .events-content li.enter-left,
        .cd-horizontal-timeline .events-content li.leave-left {
            -webkit-animation-name: cd-enter-left;
            -moz-animation-name: cd-enter-left;
            animation-name: cd-enter-left;
        }

        .cd-horizontal-timeline .events-content li.leave-right,
        .cd-horizontal-timeline .events-content li.leave-left {
            -webkit-animation-direction: reverse;
            -moz-animation-direction: reverse;
            animation-direction: reverse;
        }

        .cd-horizontal-timeline .events-content li > * {
            max-width: 800px;
            margin: 0 auto;
        }

        .cd-horizontal-timeline .events-content h2 {
            font-weight: bold;
            font-size: 2.6rem;
            font-family: "Playfair Display", serif;
            font-weight: 700;
            line-height: 1.2;
        }

        .cd-horizontal-timeline .events-content em {
            display: block;
            font-style: italic;
            margin: 10px auto;
        }

        .cd-horizontal-timeline .events-content em::before {
            content: "- ";
        }

        .cd-horizontal-timeline .events-content p {
            font-size: 1.4rem;
            color: #959595;
        }

        .cd-horizontal-timeline .events-content em,
        .cd-horizontal-timeline .events-content p {
            line-height: 1.6;
        }

        @media only screen and (min-width: 768px) {
            .cd-horizontal-timeline .events-content h2 {
                font-size: 7rem;
            }

            .cd-horizontal-timeline .events-content em {
                font-size: 2rem;
            }

            .cd-horizontal-timeline .events-content p {
                font-size: 1.8rem;
            }
        }

        @-webkit-keyframes cd-enter-right {
            0% {
                opacity: 0;
                -webkit-transform: translateX(100%);
            }

            100% {
                opacity: 1;
                -webkit-transform: translateX(0%);
            }
        }

        @-moz-keyframes cd-enter-right {
            0% {
                opacity: 0;
                -moz-transform: translateX(100%);
            }

            100% {
                opacity: 1;
                -moz-transform: translateX(0%);
            }
        }

        @keyframes cd-enter-right {
            0% {
                opacity: 0;
                -webkit-transform: translateX(100%);
                -moz-transform: translateX(100%);
                -ms-transform: translateX(100%);
                -o-transform: translateX(100%);
                transform: translateX(100%);
            }

            100% {
                opacity: 1;
                -webkit-transform: translateX(0%);
                -moz-transform: translateX(0%);
                -ms-transform: translateX(0%);
                -o-transform: translateX(0%);
                transform: translateX(0%);
            }
        }

        @-webkit-keyframes cd-enter-left {
            0% {
                opacity: 0;
                -webkit-transform: translateX(-100%);
            }

            100% {
                opacity: 1;
                -webkit-transform: translateX(0%);
            }
        }

        @-moz-keyframes cd-enter-left {
            0% {
                opacity: 0;
                -moz-transform: translateX(-100%);
            }

            100% {
                opacity: 1;
                -moz-transform: translateX(0%);
            }
        }

        @keyframes cd-enter-left {
            0% {
                opacity: 0;
                -webkit-transform: translateX(-100%);
                -moz-transform: translateX(-100%);
                -ms-transform: translateX(-100%);
                -o-transform: translateX(-100%);
                transform: translateX(-100%);
            }

            100% {
                opacity: 1;
                -webkit-transform: translateX(0%);
                -moz-transform: translateX(0%);
                -ms-transform: translateX(0%);
                -o-transform: translateX(0%);
                transform: translateX(0%);
            }
        }

        .nav-tabs,
        .nav-pills {
            position: relative;
        }
    }
}
