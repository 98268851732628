.RatingMini {
  min-height: 100px;

  // &-Header {
  //   display: flex;
  //   align-items: center;
  //   padding: 24px;
  //   max-width: 900px;
  //   margin: auto;
  //   // height: 102px;

  //   &_isSmall {
  //     display: block;
  //     text-align: center;
  //     height: auto;
  //   }
  // }
  &-Header-Title {
    margin: 24px 24px 8px 24px;
    display: flex;
    align-items: center;

    span {
      // margin-left: 16px;
      font-size: 16px;
      font-weight: 700;
      line-height: 116%;
      color: #000;
    }
  }

  &-Avatar {
    position: relative;
    min-width: 40px !important;
    max-width: 40px !important;

    .Avatar-Img {
      background-color: transparent;
    }
  }

  &-Header_isSmall &-Avatar {
    // display: none;
  }

  &-Name {
    flex: 1;
    font-size: 24px;
    line-height: 1.5;
    margin-left: 20px;
  }

  &-Header_isSmall &-Name {
    margin-left: 0px;
    // font-size: 16px;
  }

  &-All {
    // width: 100%;
    margin: 8px 24px 24px 24px;
    text-align: left;
    box-sizing: border-box;
  }

  &-Items {

    &_type_rounded {
      display: flex;
      flex-wrap: wrap;
      text-align: center;
      justify-content: center;
    }
  }

  &-Item {

    height: 63px;

    &_rounded {
      font-weight: 700;
      font-size: 16px;
      display: flex;
      align-items: center;
      flex-basis: 25%;
      max-width: 100px;
      justify-content: center;
      margin: 10px;

      .Button {
        margin-right: 10px;
      }
    }
  }
}

.WidgetMui {
  .RatingMini-Header-Title {
    margin: 0 0 8px 0;
    // display: flex;
    // align-items: center;

    // span {
    //   // margin-left: 16px;
    //   font-size: 16px;
    //   font-weight: 700;
    //   line-height: 116%;
    //   color: #000;
    // }
  }

  .RatingMini-All {
    margin: 0;
  }

  .RatingMini-Items_type_stroke {
    margin-bottom: 16px;

  }
}