.ShopWidget {


  &.Widget {
    background-color: transparent;
    border: none;
  }
  &.WidgetMui {
    background-color: transparent;
    box-shadow: none;
    padding:0;
    border: none;
  }

  a {
    text-decoration: none !important;
  }

  &-Item {
    background-color: var(--background-color);
    // border: 1px solid var(--border-color);
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12);
    /*border: 1px solid #d3d9e0;*/
    border-radius: 8px;
    padding: 24px;
    margin-bottom: 24px;
  }

  &-Icon,
  &-IconColl {
    border-radius: 50%;
    // border: 3px solid $BorderColor;
    font-size: 50px;
    width: 40px;
    height: 40px;
    margin-right: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #FFB400;

    &:before {
      width: auto !important;
    }
  }

  &-Footer {
    // padding: 16px;
    text-align: left;
    line-height: normal;

    &_missing {
      color: var(--danger-color);
    }
  }

  &-Img {
    width: 100%;
    display: block;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border: none;
    padding: 0;
  }

  .css-1ps4owl-MuiTypography-root-MuiLink-root {
    text-decoration: none;
  }

  &-Header {
    margin: 0 0 8px 0;
    padding: 6.5px 0;
    color: #000;
    border: none;
    text-decoration: none !important;

    &-Title {
        margin-bottom: 8px;
        font-weight: 700;
        font-size: 16px;
        line-height: 116%;
        letter-spacing: 0.15px;
        padding: 6.5px 0;
        margin-bottom: 8px;
        color: rgba(0, 0, 0, 0.87) !important;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: 245px
      }

      &-Text {
        font-size: 14px;
        line-height: 20.02px;
        width: 100%;
        margin-bottom: 12px
      }
    
  }

  &-Info {
    padding: 16px;
  }



  &-Price {
    text-align: left;
    font-size: 16px;
    display: flex;
    align-items: center;
    // border-top: 1px solid var(--border-color);
    // border-bottom: 1px solid var(--border-color);
    padding: 8px 0;
    color: var(--highlight-color);

    &-PriceText {
      display: flex;
      flex-direction: column;
      color: #000;
      font-size: 14px;
      line-height: 157%;
    }

    .MuiIcon-root {
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;

      img {
        width: 24px;
        height: 24px;
      }
    }
  }

  &-ToShop {
    width: 100%;
  }

  &-Description {
    overflow-wrap: break-word;
  }

  &-Order.Button_main:disabled {
    pointer-events: none;
  }
}

@media all and (-ms-high-contrast:none) {
  .ShopWidget {
    &-Order.Button_main:disabled {
      pointer-events: auto;
    }
  }
}