.VirtualUser {
  padding: 24px;
  &_png {
    padding: 0;
    background-color: unset;
    border: none;
  }
  position: relative;
  &-Image {
    width: 100%;
  }
  &-StartText {
    cursor: pointer;
    position: absolute;
    top: 50%; 
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 3px 6px;
    background-color: #fff;
    border-radius: 6px;
    user-select: none;
  }

  &-Tooltip {
    position: absolute;
    top: 50%;
    right: 180px;
    cursor: pointer;
    user-select: none;
    z-index: 1;
    &-Inner {
      position: relative;
      user-select: none;
    }

    &-Image {
      width: 300px;
      user-select: none;
    }

    &-Text {
      position: absolute;
      top: calc(17px + 50%); 
      left: 50%;
      transform: translate(-50%, -50%);
      width: 250px;
      text-align: center;
      user-select: none;
    }
  }
}