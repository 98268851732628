.NewsTimelineInfo {
    // background-color: #FFF3BD;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 24px;
    border-bottom: 1px solid #d3d9e0;
    margin-bottom: 24px;
    &-Value {
      display: flex;
      align-items: center;
      padding: 8px 24px 8px 6px;
      margin: 0 6px;
      border-radius: 50px;
      &.Earned {
          background-color: #DEE89A;
      }
      &.Given {
          background-color: #D4F0FA;
      }
      &.Deleted {
        background-color: #ccc;
      }
      img {
        height: 32px;
        margin-right: 6px;
      }
      span {
        font-size: 24px;
        line-height: 1;
      }

    }
    & > span {
      font-weight: bold;
      font-size: 16px;
    }
}