.PriorityNav {
  display: flex;
  white-space: nowrap;
  align-items: center;

  &-Dropdown {
    margin-left: auto;

    & > *.Button {
      padding: 2px 8px;
    }

    & > *.SplitButton-Toggle {
      padding-right: 20px;
    }

    @media (max-width: 500px) {
      width: 115px;
    }
  }

  &-ddText {
    display: block;
    cursor: auto;
    user-select: none;
    padding: 2px 8px;
  }

  &-Visible {
    overflow: hidden;
    width: 100%;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  /* IE10+ CSS styles go here */
  .PriorityNav {
    display: block;
    position: relative;

    &-Visible {
      overflow: initial;
    }

    .Dropdown {
      position: absolute;
      // right: 24px;
      // height: 28px;
      top: 12px;
      display: block;

      .Dropdown-Button {
        display: inline-flex;
      }
    }
  }
}
