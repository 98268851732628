.NewsTimeline {
    &-More {
        text-align: center;

        &:empty {
            display: none;
        }
    }

    &-ErrorMsg {
        margin-bottom: 12px;
    }

    &-Item {
        margin-bottom: 24px;
    }
}