.KanbanCard {
  background-color: #fff;
  border-radius: 3px;
  margin-bottom: 12px;
  cursor: grab;
  position: relative;

  & > div {
    padding: 3px 6px;
  }

  &-Header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &-Left {
      display: flex;
      align-items: center;
      & > svg {
        width: 20px;
        height: 20px;
        margin-right: 6px;
      }
      & > span {
        font-weight: 500;
        max-width: 170px;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    

    textarea {
      width: 0;
      height: 0;
      z-index: -1;
      position: absolute;
      // visibility: hidden;
    }

    &-Right {
      display: flex;
      align-items: center;

      & > a {
        display: flex;
        margin-right: 6px;
      }

      & > svg {
      cursor: pointer;
      transform: rotate(-40deg);
      font-size: 20px;
    }
    }
  }


  &-Additional {
    border-top: 1px solid #d3d9e0;
    .FieldRender {
      .FieldViewItem {
        & > div {
          display: flex;
          max-width: 300px;
          // overflow: hidden;
          text-overflow: ellipsis;
          & > div:first-child {
            font-weight: normal;
            margin-right: 3px;
            &:after {
              content: ':'
            }
          }

          & > div + div {
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }
  }


  &-Users {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid #d3d9e0;
  }



  &-Responsible {
    line-height: 1;
    padding: 3px 0;
    & > li:not(:first-child) {
      margin-left: -12px;
    }
  }

  &-Author {
    line-height: 1;

    a {
      padding: 3px;
    }
  }
}