    .HtmlWidget {
        padding: 15px;
        .prn-editor-container {
            padding: 0;
        }
        figure {
            margin: 0;
            padding: 0;
        }
        img {
            max-width: 100%;
            height: auto;
            // display: block;
        }
        .img-center {
            // max-width: 100%;
            // height: auto;
            // display: block;
            margin: auto;
        }
        .img-left {
            float: left;
            // max-width: 100%;
            // height: auto;
            // display: block;
        }
        .img-right {
            float: right;
            // max-width: 100%;
            // height: auto;
            // display: block;
        }
    }