.SkillsItem {
    border-bottom: 1px solid #d3d9e0;
    height: 54px;

    &-TagLink {
        color: #0066cc;
        overflow: hidden;
        text-overflow: ellipsis;
        display: inline-block;
        max-width: 100%;
    }

    &-Controls {
        width: 100%;
        display: flex;
        justify-content: flex-end;
    }

    &-Index {
        width: 24px;
        padding-left: 24px;
        @media (max-width: 500px) {
            display: none;
        }
    }

    &-ConfirmByBtn {
        display: none!important;
        @media (max-width: 500px) {
            display: block!important;
        }
    }

    &-Title {
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 250px;
        @media (max-width: 720px) {
            max-width: 120px;
        }
        @media (max-width: 500px) {
            padding-left: 8px;
        }
    }

    &-Users {
        padding-left: 21px;
        @media (max-width: 500px) {
            display: none;
        }
    }

    &-AlertCell {
        padding-left: 24px;
        padding-right: 24px;
        @media (max-width: 500px) {
            padding-left: 8px;
            padding-right: 8px;
        }
    }

    &-ConfirmIcon {
        @media (min-width: 500px) {
            display: none;
        }
        @media (max-width: 500px) {
            display: inline-block;
        }
    }
    &-ConfirmText {
        @media (min-width: 500px) {
            display: inline-block;
        }
        @media (max-width: 500px) {
            display: none;
        }
    }

    &-Action {
        width: 160px;
        padding-left: 24px;
        &_small {
            padding-left: 0px
        }
        @media (max-width: 500px) {
            width: 80px;
            padding-left: 8px;
        }
        padding-right: 24px;
        @media (max-width: 500px) {
            padding-right: 8px;
        }
    }

    &-NotConfirmed {
        margin: 15px 0;
    }

    &-ConfirmBtn {
        padding-left: 16px;
        padding-right: 16px;
        padding-top: 0;
        padding-bottom: 0;
    }

    &-ActionMenu {
        .Button {
            padding-top: 0;
            padding-bottom: 0;
        }
    }

    &-Alert {
        margin: 8px 0;

        .MuiAlert-message {
            width: 100%;
        }
    }

    &-AlertText {
        flex: 1;
        word-break: break-all;
        word-break: break-word;
    }

    &-ButtonBox {
        width: 160px;
        @media (max-width: 500px) {
            margin-top: 12px;
        }
    }

    &-AlertContent {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        align-items: flex-end;
        &_small {
            flex-direction: column;
        }
        @media (max-width: 500px) {
            flex-direction: column;
        }
    }
}
