.Kanban {
  position: relative;

  &-Loader {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
  }

  .react-trello-board {
    background-color: rgba(255,255,255, .5);
    border-radius: 8px;
    border: 1px solid #d3d9e0;
  }
}