.PagesMenuMuiBar {
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12);
    overflow: hidden;
    display: flex;
    flex-direction: column;
    min-width: unset;
    transition: min-width 0.25s ease-in-out;

    &_opened {
        //     .PagesMenuMuiBar-ContextActionButton{
        //     border-bottom: 1px solid #ccc;

        // }
        min-width: 328px;

        // .MuiSvgIcon-root {
        //     margin-right: 14px;
        // }

        .PagesMenuMuiBar-MenuItem-Content-LikeButton {

            .MuiSvgIcon-root {
                margin-right: 0px
            }


        }

    }

    // &-Actions_Hedaer {
    //     display: flex;
    //     justify-content: flex-end;
    //     position: absolute;
    //     right: 8px;
    //     top: 8px;
    //     z-index: 10;
    //     opacity: 0;
    // }

    &-Sortable {
        overflow-x: auto;
        overflow-y: scroll;
        flex: 1;
        margin-right: -17px;
    }

    &-MenuItem {
        &-Content {
            margin: 0 16px;
            height: 100%;

            &-Title {
                font-weight: 600;
                font-size: 14px;
                line-height: 24px;
                letter-spacing: 0.17px;
                color: rgba(0, 0, 0, 0.87);
            }

            &-AvatarBox {
                display: flex;
                margin-bottom: 8px;

                .Avatar-Img,
                .Avatar-Letter {
                    height: 40px;
                }
            }

            // &-ItemBox {
            //     display: flex;
            //     margin-bottom: 8px;
            //     padding: 8px;
            //     position: relative;

            //     .CommonmarkRender {
            //         font-weight: 400;
            //         font-size: 12px;
            //         line-height: 20px;
            //         display: flex;
            //         align-items: center;
            //         letter-spacing: 0.4px;
            //         color: rgba(0, 0, 0, 0.87);

            //     }

            //     .MuiSvgIcon-root {
            //         margin-right: 0px
            //     }

            //     .Avatar-Img,
            //     .Avatar-Letter {
            //         height: 40px;
            //     }
            // }

            // &-ItemBox:hover {
            //     background: #F5F5F5;
            //     border-radius: 4px;
            //     padding: 8px;

            //     .PagesMenuMuiBar-Actions_Hedaer {
            //         opacity: 1;
            //     }
            // }

            &-LikeButton {
                button:hover {
                    background-color: inherit;
                }

                span {
                    margin-left: 8px;
                }
            }

            &-Item {
                margin-bottom: 16px;

                &-DateBox {
                    display: flex;
                    align-items: center;
                    margin-bottom: 8px;

                    span {
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 143%;
                        letter-spacing: 0.15px;
                        color: rgba(0, 0, 0, 0.87);
                        margin-left: 16px;
                    }

                    .PagesMenuMuiBar-MenuItem-Content-AvatarBox {
                        margin-bottom: 0;
                    }
                }

                &-Name {
                    margin-left: 16px;
                    display: flex;
                    flex-direction: column;

                    span {
                        font-size: 14px;
                        line-height: 143%;
                        letter-spacing: 0.15px;
                        color: rgba(0, 0, 0, 0.6);
                    }
                }
            }
        }
    }
}