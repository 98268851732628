@import '../../../../../utils/global.scss';

.IdeaFieldString{
    &-String{
        position: relative;
    }
        
    &-HelpTooltip{
        position: absolute;
        top: 5px;
        right: 24px;
        z-index: 0;        
        cursor: default;
            .Icon{
                color: #999999;
            }
    }
}