.ProgressStudentsFilter {
    &-Name {
        padding: 12px;
        padding-left: 24px;
        margin: 0px;
    }

    &-SliderBox {
        padding-left: 24px;
        padding-right: 24px;
    }
}
