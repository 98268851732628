@import 'utils/src/global.scss';

.Groups {
    // display: flex;
    // justify-content: space-between;
    // padding: $defaultPadding;
    // border-bottom: 1px solid $BorderColor;
    // &-Title {
    //   display: flex;
    //   align-items: center;
    //   span {
    //     font-size: 20px;
    //     margin-left: 12px;
    //   }
    // }
    &-Settings {
      display: flex;
      flex-direction: column;
      align-items: center;
      &-Spinner {
        position: static;
        padding: 12px 24px;
        i {
          top: unset;
        }
      }
      &-Empty {
        padding: 12px 24px;
      }
    }
  }