.QuickLinksSettings {
    padding: 16px;

    &-ItemSortable {
        display: flex;
        align-items: center;
        border: 1px solid #d3d9e0;
        border-radius: 8px;
        position: relative;

        &:nth-child(n + 2) {
            margin-top: 8px;
        }

        &>*:nth-child(n + 3) {
            margin-top: 8px;
        }

    }

    &-Item {
        padding: 16px;

        .ImageLoader {

            padding: 0;
        }

        .ItemRemove {
            position: absolute;
            top: 0;
            right: 0;
            z-index: 1;
        }
    }

    &-Add {
        width: 100%;
    }
}