.InfoBlock-Badges {

    padding: 24px;

    .InfoBlock-Img {
        display: flex;
    }

    .InfoBlock-Layout {
        &-Left {
            width: auto;
        }
    }

    // &-Data {
    //     display: flex;
    //     flex-direction: column;
    //     justify-content: center;
    //     height: 100%;
    //     margin-left: 30px;
    // }

    // &-DataName {}

    // &-DataDescription {
    //     margin-top: 10px;
    // }

    // &-Img {
    //     .Avatar-Img {
    //         background-color: transparent;
    //     }
    // }
}