@import '../../List.scss';

.List_type_users {

  .ListLite {
    background-color: var(--background-color);
    border: 1px solid var(--border-color);   
  } 

  .List {

    &-Item {
      &-DataPart {
        .UserName{
          font-weight: bold;
          font-size: 16px;
        }
        flex: 1;
      }

      &_nochecked {
        
        .List-Item-DataPart {
        }
      }
    }

    &-Button {

      &_more {
        margin-top: 10px;
        display: block;
        width: 100%;
        border-radius: 8px;
      }
    }
  }
}