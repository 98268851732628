@import '../../../../utils/global.scss';

.PageMenuComments {

    &-Cross {
        font-size: 20px;
        line-height: 40px;
    }

    &-CommentsPageMenuLayout {
        max-width: 328px;
        // background-color: #ffffff;
        height: 100%;
        // position: fixed;
        // z-index: 1;
        // border: 1px solid var(--border-color);
        background-color: #fff;
        // border-radius: 8px;
        // bottom: 35px;
        // width: 58%;

        &-Data {

            height: 100%;
            // border: 1px solid var(--border-color);
            background-color: #fff;
            // border-radius: 8px;
            position: relative;
            overflow: hidden;
        }

        .Comments,
        &-Comments {
            .CommentsLayout {
                .CommentsLayout-Comments {
                    background-color: #FFF;
                    border-top: none;
                    border-bottom-right-radius: 0px;
                    border-bottom-left-radius: 0px;

                    .Reply {
                        position: relative;
                        border-bottom: none;
                        padding-top: 8px;
                        display: flex;
                    }
                }

                .CommentsLayout-Form {
                    border-top: none;

                    .EditActions .Icon {
                        font-size: 20px;
                    }

                    .EditActions-EditActions .Button {
                        width: 34px;
                        height: 50px;
                        text-align: center;
                    }

                    .PrnEditor-StyleButton {
                        background: transparent;
                        color: #888;
                        /* font-size: 18px; */
                        border: 0;
                        /* padding-top: 5px; */
                        padding: 0px;
                        line-height: normal;

                    }
                }
            }

            position: relative;
            // top: 60px;
            // padding-bottom: 8px;

            // border-top: 1px solid #D3D9E0;
            // @include BorderRadiusBottom();

            &:empty {
                display: none;
            }
        }
    }

    &-More {

        background-color: #fff;

        // border-top: 1px solid #D3D9E0;
        &:empty {
            display: none;
        }
    }



    &-Form {
        border-bottom-right-radius: 8px;
        border-bottom-left-radius: 8px;
        background-color: #fff;
        border-top: 1px solid #D3D9E0;

        &:empty {
            display: none;
        }
    }

    .Reply-Section {
        padding: 8px;
        width: 244px;
        // height: 120px;
        background: #F5F5F5;
        border-radius: 4px;
    }
}