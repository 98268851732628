.MuiMenu-list {
  .MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected:hover {
    background-color: rgba(0, 0, 0, 0.2);
  }
}

// label.MuiFormLabel-root, label.MuiInputLabel-root {
//   font-size: 14px;
//   font-family: 'Open Sans';
// }

// input.MuiInputBase-input, textarea.MuiInputBase-input {
//   font-size: 14px;
//   font-family: 'Open Sans';
// }