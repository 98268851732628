.IdeaHistory {
    &-history {
        padding: 7px 24px;
        border-bottom: 1px solid #ccc;
        display: flex;
        align-items: center;
    }

    &-post {
        width: 70%;
    }

    &-Content {
        background-color: #fff;
        font-size: 16px;
        font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    }

    &-history_text {
        padding: 0 20px;

        .Markdown {
            p {
                margin: 0;
            }
        }
    }

    &-UserName {
        padding: 0 20px;
        font-weight: 600;
        color: #0066cc;
    }

    &-Avatar {
        height: 56px;
    }

    &-Title {
        margin: 0;
    }

    &-NotHistory {
        margin-left: 24px;
        font-size: 18px;
    }
}