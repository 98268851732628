.LMSResults {
  &-Header {
    background-color: var(--background-color);
    // padding: 24px;
  }

  &-Title {
    margin: 0;
  }

  &-Header {
    margin-bottom: 24px;
  }

  &-Section {
    display: flex;
  }

  &-Content {
    flex: 28;
  }

  // &-Aside {
  //   flex: 10;
  //   background-color: #ffffff;
  //   border-radius: 8px;
  //   margin-right: 24px;
  //   border: 1px solid #d3d9e0;
  // }
}
