@import '../../../utils/global.scss';

.SecretSantaDialog {

    &-Body{
        background-color: #fff;         
        &-Items{
            margin:10px 24px;
        }
        .MuiFormControl-root {
            margin: 7px 0;      
        }
        &-Letter{
            margin-top: 12px;
            border-radius: 2px;
            .DraftEditor-editorContainer {
                min-height: 145px;
                background: #fff;
                border-radius: 2px;
            }
        }
    }
    // &-Unparticipate{
    //     background: red;
    //     color: #fff;
    // }
    &-Footer{
        display: flex;
        justify-content: center;
        .SecretSantaDialog-Unparticipate{
            background: red;
        color: #fff;
        }
        .SecretSantaDialog-Unparticipate:hover{
            background: red;
            color: #fff;
        }
        .SecretSantaDialog-Save{
            margin: 0 5px;
        }
    }
    svg.MuiSvgIcon-root {
        color: #4e4d4dde;
    }
    
}