.BottomLine {
  border-bottom: 1px solid #D3D9E0;
}

.TopLine {
  border-top: 1px solid #D3D9E0;
}

.Filter-Title {
  padding: 0;
  padding-top:24px;
  padding-left: 24px;
  margin: 0;
  padding-bottom: 24px;
}

.Filter-Subtitle {
  padding: 0;
  padding-left: 24px;
}

.Filter-Toggle {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.Filter-Bottom {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Filter-Button {
  margin-bottom: 24px;
}

.Filter-Dropdown {
  margin-bottom: 24px;
  margin-left: 24px;
}

.Dropdown-Options_align_left {
  margin-left: 24px;
}

.Filter-Radius {
  .Selectize-Button:last-of-type {
    border-bottom-right-radius: 8px; 
    border-bottom-left-radius: 8px;
  }
}

.Filter-SortBy {
  width: 0;
	height: 0;
	border-left: 10px solid transparent;
	border-right: 10px solid transparent;
	border-bottom: 15px solid #ccc;
}

.Selectize-Button {
  align-items: center;
}

.Filter-Select {
  max-width: 150px;
  margin-left: 24px;
  margin-bottom: 24px;
}