.SessionResult {
  background-color: #ffffff;
  border-radius: 8px;

  &-Summary {
    display: flex;
    padding: 24px;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  &-SummaryContent {
    display: flex;
  }

  &-SummaryTitle {
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    padding-top: 9px;
    padding-bottom: 9px;
  }

  &-SummaryAnswers {
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    padding-top: 9px;
    padding-bottom: 9px;
  }

  &-SummaryColumn {
    display: flex;
  }

  &-Header {
    padding: 24px 24px 8px 24px;
    display: flex;
    justify-content: space-between;
  }

  &-HeaderColumn {
    // flex: 1 1;
  }

  &-Link {}

  &-Badge {
    border-top: 1px solid #D3D9E0;
    padding: 12px 24px;
  }

  &-Chart {
    width: 140px;
    height: 140px;
    // margin-right: 24px;
    margin-left: 24px;
  }

  &-Title {
    font-weight: bold;
    font-size: 24px;
    line-height: 33px;
  }

  &-Attempts {
    font-size: 20px;
    line-height: 27px;
  }

  &-Time {
    font-weight: bold;
    font-size: 24px;
    line-height: 33px;
  }

  &-TimeText {
    font-size: 20px;
    line-height: 27px;
  }

  &-Item {
    // flex: 1;
  }

  &-Actions {
    border-top: 1px solid #D3D9E0;
    display: flex;
    justify-content: space-between;
    padding: 24px;
  }

  &-Btn {
    min-width: 132px;
    border-radius: 8px;
    padding-left: 12px;
    padding-right: 12px;
    font-size: 14px;
    line-height: 19px;

    &_type_again {
      color: #0066cc;
    }
  }

  &-Percent {
    font-size: 36px;
    color: #979797;
  }

  &-Value {
    line-height: normal;
    font-size: 20px;
    line-height: 24px;

    &_type_success {
      color: #89ca5e;
      // font-size: 64px;
    }

    &_type_wrong {
      color: #f7434c;
      // font-size: 64px;
    }
  }

  &-QuestionList {
    margin: 0;
    padding: 0;
    border-top: 1px solid #d3d9e0;
    list-style: none;
  }

  &-QuestionContent {
    line-height: normal;
    padding-left: 24px;
    flex: 1;
  }

  &-AnswerTime {}

  &-QuestionItem {
    border-bottom: 1px solid #d3d9e0;
    padding: 15px 24px;
    display: flex;
  }

  &-QuestionIcon {
    height: 26px;
    padding: 0 13px;
    margin-top: 6px;
    background: url("data:image/svg+xml,%3Csvg width='26' height='26' viewBox='0 0 26 26' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M13 26C20.1797 26 26 20.1797 26 13C26 5.8203 20.1797 0 13 0C5.8203 0 0 5.8203 0 13C0 20.1797 5.8203 26 13 26Z' fill='%23F7434C'/%3E%3Cpath d='M8.05021 17.9498L17.9497 8.05029' stroke='white' stroke-width='1.2'/%3E%3Cpath d='M8.05021 8.05033L17.9497 17.9498' stroke='white' stroke-width='1.2'/%3E%3C/svg%3E%0A");

    &_right {
      background: url("data:image/svg+xml,%3Csvg width='26' height='26' viewBox='0 0 26 26' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M13 26C20.1797 26 26 20.1797 26 13C26 5.8203 20.1797 0 13 0C5.8203 0 0 5.8203 0 13C0 20.1797 5.8203 26 13 26Z' fill='%2389CA5E'/%3E%3Cpath d='M6 14L10 18L19 9' stroke='white' stroke-width='1.2'/%3E%3C/svg%3E%0A");
    }
  }

  &-QuestionText {
    font-size: 16px;
    color: #000;
  }

  &-Answers {
    font-size: 14px;
    color: #979797;
  }
}