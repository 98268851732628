.TagsCloud {
  padding: 24px;

  &-Header {
    &-Title {
      margin: 6.5px 0;
      font-weight: 700;
      font-size: 16px;
      line-height: 116%;
      letter-spacing: 0.15px;

    }


  }

  .tag-cloud-tag {
    cursor: pointer;
  }

  text {
    font-family: inherit;
  }
}