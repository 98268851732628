.BadgeItem {
    width: 175px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 12px 0;
    position: relative;
    
    //
    &-Link {
        display: block;
    }

    &-ImgLink {
        display: block;
    }

    &-Img {
        border: 1px solid #fff;
        display: block;
        height: 92px;
        .Avatar-Img {
            background-color: transparent;
        }
    }

    &-Name {
        text-align: center;
    }

    &-Count {
        position: absolute;
        right: 0;
        bottom: 0;
        border: 1px solid #fff;
    }

    &-ImgBox {
        position: relative;
        text-align: center;
    }

    &-RemoveBadge {
        position: absolute;
        right: 0;
        top: 0;
        padding: 0 2px;
        border: 1px solid #fff;
    }
}
