.ListSkiped {
  background-color: #ffffff;
  border-radius: 8px;

  &-Header {
    margin: 0;
    padding: 24px;
  }

  &-List {
  }

  &-Actions {
    display: flex;
    justify-content: center;
    border-top: 1px solid #d3d9e0;
  }

  &-Control {
    min-width: 132px;
    border-radius: 8px;
    padding-left: 12px;
    padding-right: 12px;
    margin: 24px;
    font-size: 14px;
    line-height: 19px;
  }

  &-Timer {
    padding: 24px;
  }
}
