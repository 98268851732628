.outer {

  position: relative;
  margin: 50px;
}
.MindMap{
  &-Map {
    height: 500px;
    width: 100%;
    overflow: auto;  
  }
  &-Actions{
    display: flex;
    justify-content: flex-end;
      &-SaveMindMap{
        margin: 10px 24px!important;
        
        }
  }

}


 