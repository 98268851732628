.KPITable {
    width: 98%;
    border-collapse: collapse;
    margin-left: 1%;
    margin-right: 1%;
    margin-top: 11px;

    th {
        text-align: left;
    }

    &-ColCompleted {
        width: 30px;
    }
    &-ColName {
        width: 280px;
    }
    &-ColGoal {
        // width: 35%;
    }
    &-ColDueDate {
        width: 160px;
    }
    &-ColActions {
        width: 95px;
    }
}
