.User {
  .Widget_type_info {
    border-bottom: 0;
    border-radius: 8px 8px 0 0;
  }

  .Widget_type_info + .Widget_type_tabs {
    margin-top: 0px;
  }
}
