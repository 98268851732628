.InfoBlock-Tag {

    &-Inner {
        padding: 12px;
    }

    &-Info {
        text-align: center;
    }

    &-Title {
        margin: 0;
    }

    &-TagName {}
}

.Tag {
    .InfoBlock {
        border-top-right-radius: 0;
        border-top-left-radius: 0;
        border-top: none;
    }

    .Widget_type_info+.Widget_type_tabs {
        margin-top: -8px;
    }
}