.AlbumView {
  background-color: #fff;
  border-radius: 8px;
  &-SelectCount {
    width: 250px;
  }
  &-MoreBox {
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 24px;
    position: relative;
    &:empty {
      display: none;
    }
  }
  &-CreateBox {

  }
  &-SelectsBox {
    display: flex;
    align-items: center;
    .CheckboxInput {
      padding: 0;
    }
  }
  &-Actions {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    background-color: var(--background-highlight-color);
    border-bottom: 1px solid var(--border-color);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 24px;
  }

  &-List {
    display: flex;
    flex-wrap: wrap;
    padding: 24px;
    justify-content: center;
  }
}
