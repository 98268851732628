.SecretSantaPart {
  padding: 15px 2%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  background-color: #fff;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  .ListItem-Skeleton {
    background-color: #fff;
    border: none;
    flex-basis: 45%;
    width: 50%;
    padding: 15px 0;
  }
  .Loading{
    line-height: 166%;
    letter-spacing: 0.4px;
    font-size: 12px!important;
  }
  &-stab {
    background-color: var(--background-color);
    width: 100%;
    height: 352px;
    border-radius: 8px;
    text-align: center;
    padding-top: 40px;
  }
  &-AdditionalInfo {
    &-DestributionOver {
      text-align: left;
      font-style: italic;
      font-size: 16px;
      &-Loading {
        white-space: nowrap;
      }
    }
  }
  &-Search {
  }
  &-Header {
    &-TitlePresent {
      span {
        font-size: 15px;
        font-weight: 700;
      }
    }
    &-Content {
      margin: 24px;
      button {
        margin-top: 24px;
      }
    }
    &-Title {
      display: flex;
      align-items: center;
      &-Info {
        margin-left: 24px;
        &-Name {
          font-weight: 600;
          font-size: 18px;
        }
        &-Description {
          display: flex;
          &-Span {
            color: #999;
          }
          &-participantsCount {
            font-weight: 600;
            padding: 0 5px;
          }
        }
      }
    }
  }
  .Skeleton-ListItem {
    background-color: #fff;
    border: none;
    flex-basis: 45%;
    width: 50%;
    padding: 15px 0;
  }

  &-ListItem {
    border: none;
    flex-basis: 45%;
    width: 37%;
    padding: 15px 0;
    margin: 5px;
    &-Image {
      display: flex;
      padding: 0 24px;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      display: flex;
      align-items: start;
      margin-right: 24px;
      position: relative;
      .Avatar {
        margin-right: 20px;
      }
    }
    &-Content {
      width: 85%;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      > div {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
      &-UserName {
        width: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        text-align: left;
        font-weight: 600;
        font-size: 16px;
      }
      // &-DescriptionGift{
      //     &-Delivery{
      //         display:flex;
      //         margin: 5px 0;
      //         .CommonmarkRender.CommonmarkRender_type_full{
      //           width: 60%;
      //         }
      //         span{
      //           font-weight: bold;
      //           margin-right: 5px;
      //           white-space: nowrap;
      //           width: 140px;
      //         }
      //         p{
      //           margin: 0;
      //           padding: 0;
      //           white-space: break-spaces;
      //           width: 60%;
      //         }
      //     }
      // }
    }
  }
  &-ListItem:hover {
    background-color: #fff;
  }
  &-UserAdditionalInfo {
    &-Avatar {
      align-items: center;
      display: flex;
      span {
        margin-left: 5px;
      }
    }
    &-Description {
      .CommonmarkRender-Paragraph {
        margin: 12px 0;
        line-height: 1.3em;
      }
    }
    &-Info {
      display: flex;
      span {
        color: #999;
        margin-right: 5px;
      }
      p {
        padding: 0;
        margin: 0;
      }
    }
  }

  .stab {
    background-color: var(--background-color);
    width: 100%;
    height: 352px;
    border-radius: 8px;
    text-align: center;
    padding-top: 40px;
  }
  &-ButtonBox {
    display: flex;
    // .SecretSantaPart-Unparticipate {
    //   margin-left: 24px;
    // }
   .SecretSantaPart-SaveButton {
      &-Submit,
      &-Unparticipate {
        text-transform: uppercase;
      }
      &-Unparticipate {
        margin-left: 16px;
      }
    }
  }
 
}