.Suggesters {

  &_open {}

  &-Dropdown {
    position: absolute;
    background: #fff;
    border: 1px solid var(--border-color);
    width: 100%;
    border-bottom-right-radius: 16px;
    border-bottom-left-radius: 16px;
    overflow: hidden;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  }

  &-SearchBox {
    border: 1px solid var(--border-color);
    width: 100%;
    height: 24px;
    display: flex;
    border-radius: 16px;
    transition: all .15s linear;
  }

  &-SearchBox_open {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    border-bottom: none;
  }

  &-Results {
    max-height: 60vh;
    overflow: auto;
  }

  &-GlobalSearch {
    height: 20px;
    display: flex;
    align-items: center;
    margin-left: 12px;
  }

  &-Portal {
    position: relative;
    width: 100%;
  }

  &-Input {
    width: 100%;
    border: none;
    background: transparent;
    padding: 0 12px;
  }

  &-Button {
    line-height: normal;
  }

  &-Actions {
    line-height: normal;
    display: flex;
    position: relative;
    padding: 0 12px;
    align-items: center;
  }

   &-Noop {
     padding: 0 12px;
     position: relative;
     text-align: center;
   }

  .Spinner {
    left: -5px;
  }
}