.NewsSender {
    
    & > * {
        margin-top: 16px !important;
    }

    &-Error {
        display: flex;
        align-items: flex-start;
        padding: 6px 16px;
        background: linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #F44336;
        border-radius: 4px;
        display: flex;
        gap: 14px;
        align-items: center;
        // align-items: flex-start;

        .Icon {
            color: #F44336;
            height: 24px;
        }

        h4,
        p {
            margin: 0;
        }
    }
}