.NewsSlider {
    border-radius: 8px;
    border: 1px solid #d3d9e0;
    background-color: #fff;
}

.NewsSliderNew {
    background-color: #fff;
    padding: 16px;
    border-radius: 4px;

    .NewsTimelineHeader.NewsSliderNew-Header {
        flex-wrap: wrap;
        padding: 8px;
        margin-top: 0;

        .NewsTimelineHeader-Title {
            padding: 0;
        }
    }
    .MiniTimelineList-List {
        padding-bottom: 0;
    }
    &-BackgroundImg {
        background: #f5f5f5;
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 8px;
    }
}

.NewsSliderNoData {
    border-radius: 8px;
    border: 1px solid #d3d9e0;
    background-color: #fff;
    padding: 24px;

    &-Header {
        margin: 0 0 8px 0;
        padding: 6.5px 0;
        color: #000;
        border: none;
        text-decoration: none !important;

        &-Title {
            margin-bottom: 8px;
            font-weight: 700;
            font-size: 16px;
            line-height: 116%;
            letter-spacing: 0.15px;
            padding: 6.5px 0;
            margin-bottom: 8px;
            color: rgba(0, 0, 0, 0.87) !important;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }

        &-Text {
            font-size: 14px;
            line-height: 20.02px;
            width: 100%;
            margin-bottom: 12px;
        }
    }
}
