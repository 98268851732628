.TasksInfo {
  display: flex;
  align-items: center;

  &-Icon {
    // width: 150px;
    font-size: 75px;
    flex-basis: 120px;
  }

  &-Data {
    flex: 1;
  }
}