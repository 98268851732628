.QRLogin {
  background-color: #fff;
  border-bottom-left-radius: 4px ;
  border-bottom-right-radius: 4px ;
  &-Title {
    font-size: 32px;
    line-height: 40px;
  }
  &-Content {
    display: flex;
    flex-direction: column;
    background: url("../../../../assets/svg/LogInBack.svg");
    background-repeat: no-repeat;
    background-size: 434px;
    background-position-x: 38px;
    background-position-y: 260px;
  }
  &-Submit {
    text-align: center;
    margin: 0 auto 15px;
    width: 65%;
    font-size: 20px;
    line-height: 40px;
    background: transparent;
  }
  &-QrCode {
    display: flex;
    flex-direction: column;
    width: 300px;
    margin: 30px auto 0;
    text-align: center;
    img {
      width: 250px;
      height: 250px;
      margin: 10px auto;
    }
  }
  &-Code {
    margin: 0;
  }
  &-How-To {
    width: 65%;
    margin: 10px auto;
    text-align: center;
  }
  &-Warning {
    color: #d2555a;
    width: 65%;
    font-weight: bold;
    margin: 0 auto 30px;
    text-align: center;
  }
}
