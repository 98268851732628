.rbc-event {
    padding: 0;
    background-color: transparent;
    // border-radius: none;

    // color: none;
}

.rbc-event-content {
    height: 30px;
    margin-bottom: 2px;
    border-radius: 4px;
}

.rbc-event:focus {
    outline: none;
}

.rbc-event {
    background-color: transparent;
    border: none;
}

.rbc-day-slot .rbc-event {
    border: none;
}

.rbc-event-label {
    display: none;
}

.CalendarPEvent {
    display: block;
    text-decoration: none;
    border: none;
    box-sizing: border-box;
    box-shadow: none;
    margin: 0;
    padding: 0 2px;
    border-radius: 5px;
    color: #fff;
    cursor: pointer;
    width: 100%;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    border: 3px solid;
    height: 100%;
    &:visited {
        color: #fff;
    }
    &:focus {
        color: #fff;
    }
}

/////////////////////////////////// допихало для проверяйтен
.rbc-event {
    // box-shadow: 0 0 2px 2px #fff;
    &:hover {
        z-index: 20;
    }
}
.rbc-row-content {
    z-index: 0;
}
