.PageHistoryItemBox {

    // max-width: 328px;

    &-Actions_Hedaer {
        display: flex;
        justify-content: flex-end;
        position: absolute;
        right: 8px;
        top: 8px;
        z-index: 10;
        opacity: 0;
    }

    &-MenuItem {
        &-Content {
            margin: 0 16px;
            height: 100%;

            &-ItemBox {
                display: flex;
                margin-bottom: 8px;
                padding: 8px;
                position: relative;

                .CommonmarkRender {
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 20px;
                    // display: flex;
                    // align-items: center;
                    letter-spacing: 0.4px;
                    color: rgba(0, 0, 0, 0.87);

                }

                .MuiSvgIcon-root {
                    margin-right: 0px
                }

                .Avatar-Img,
                .Avatar-Letter {
                    height: 40px;
                }
            }

            &-ItemBox:hover {
                background: #F5F5F5;
                border-radius: 4px;
                padding: 8px;

                .PageHistoryItemBox-Actions_Hedaer {
                    opacity: 1;
                }
            }

            &-Item {
                margin-bottom: 8px;
                &-Name {
                    margin-left: 16px;
                    display: flex;
                    flex-direction: column;

                    span {
                        font-size: 12px;
                        line-height: 166%;
                        /* identical to box height, or 20px */

                        letter-spacing: 0.4px;

                        /* Text/Disabled */

                        color: rgba(0, 0, 0, 0.38);
                    }
                }
            }
        }
    }
}