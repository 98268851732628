.ThanksSelector-Desc {
  padding-left: 6px;
}
.ThanksSelector-InputBox {
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0 !important;
    -moz-appearance:textfield !important;
    margin: 0; 
  }
  input[type="number"] {
    
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; 
    -moz-appearance: textfield; /* Firefox */
  }
  input {
    
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; 
    -moz-appearance: textfield; /* Firefox */
  }
}
.ThanksSelector-ArrowBox {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 30px;
  .Button.ThanksSelector-ArrowBtn {
    display: flex;
    padding: 0;
    align-items: center;
    flex: 1;
  }
  .Icon {
    &:before {
      line-height: 0.5em;
      height: 4px;
      display: block;
    }
  }
}
