.SUPPORT {
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 999;
    background-color: #fff;
    border: 1px solid #ccc;
    resize: both;
    padding: 12px;
    max-height: 400px;
}