.EditorCreativetasks {
    background: #fff;

    &-Title {
        border-top: 1px solid #D3D9E0;
    }

    &-Datepicker {
        // border-top: 1px solid #D3D9E0;
        border-left: 1px solid #D3D9E0;
        flex: 1;


        .InputLayout-IconZone {
            .Icon {
                color: var(--text-muted-color);
            }
        }
    }

    .ThanksSelector {
        // border-top: 1px solid #D3D9E0;
        flex: 1;

        .InputLayout-IconZone {
            .Icon {
                color: var(--text-muted-color);
            }
        }
    }

    .PryanikyEditor {
        border-top: 1px solid #D3D9E0;
        border-bottom: 1px solid #D3D9E0;
    }

    &-HContainer {
        border-top: 1px solid #D3D9E0;
        display: flex;
    }
}