.NotifiSettingsNeworks {
  &-ExpansionPanel {
  }

  .NotifiSettingsNeworks-PanelDetails  {
    padding: 0;
  }

  &-ItemTitle {
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &-Item {
    &-Summary {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      max-width: 100%;
      padding: 0 12px;
      box-sizing: border-box;

      @media (max-width: 500px) {
      }

      div:first-child {
        font-weight: bold;
        font-size: 16px;
      }
      &-Actions {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-right: 12px;
        align-items: flex-end;
        &>* {
          margin-top: 12px;
        }
        @media (max-width: 500px) {
          align-items: flex-start;
        }
      }
    }
  }
}
