.LMSTOTracksStatistic {
    display: flex;
    margin-top: 24px;
    align-items: flex-start;

    &-Aside {
        background-color: #fff;
        flex: 1 4;
        border-radius: 8px;
        overflow: hidden;
        border: 1px solid #d3d9e0;

        max-height: calc(100vh - 56px);
        overflow: auto;
        position: sticky;
        top: 56px;

        margin-right: 24px;

        &::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
            border-radius: 8px;
            margin: 8px 0;
        }

        &::-webkit-scrollbar {
            width: 5px;
        }

        &::-webkit-scrollbar-thumb {
            background-color: rgba($color: #000000, $alpha: 0.2);
            opacity: 0.3;
            border-radius: 8px;

            &:hover {
                background-color: rgba($color: #000000, $alpha: 0.4);
            }
        }
    }

    &-SearchBox {
        background: #f7f7f7;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        padding: 24px;
        // margin-bottom: 24px;
        border-bottom: 1px solid #d3d9e0;
        display: flex;
        align-items: center;
    }

    &-ActionsBox {
        display: flex;
        align-items: center;
        margin-right: 12px;
    }
    &-ActionMenu {
        .Button {
            padding: 0 4px;
        }
    }
    &-Search {
        margin-right: 24px;
    }
    &-CreateBtn {
        margin-left: auto;
        // margin-left: 24px;
        padding: 0px 12px;
        line-height: 24px;
    }

    &-List {
        padding-bottom: 8px;
    }

    &-Section {
        flex: 3 4;
        background-color: #fff;
        border: 1px solid #d3d9e0;
        border-radius: 8px;
    }
}
