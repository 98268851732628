.Lesson {
    &-Lesson {
        overflow: auto;
        height: 86%;
    }
}

.fullscreen {

    .Lesson {
        height: 100%;

        &-Lesson {
            overflow: auto;
            height: 86%;
        }
    }
}