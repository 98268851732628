.ItemLayout {
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--border-color);
  padding: 12px 24px;

  &:hover {
    background-color: #0066cc;
    color: #fff;

    .ItemLayout-ActionMenu {
      visibility: visible;
    }
  }

  &-ActionMenu {
    visibility: hidden;
    background-color: #ebeff2;

    .Button {
      color: #000;
    }

    &.SplitButton .Button {
      padding: 0px;
    }

    &.SplitButton .Dropdown .Button {
      padding: 0px 5px;
    }
  }

  &-Action {
    width: 65px;
  }

  &-Icon {
    width: 32px;
  }

  &-Name,
  &-Date,
  &-Data {
    flex: 1;
    padding-left: 24px;
    max-width: 317px;
    overflow: hidden;
    word-wrap: break-word;
  }

  &-Checkbox {
    padding: 0;
  }

  &-Img {
    width: 32px;
    height: 32px;
    display: block;
  }

  // &-ActionBtn {
  //     margin: 0 6px;
  // }

  &-Select {
    width: 25px;
  }

  &_move {
    background-color: #00f;
  }

  &_open {
    .ItemLayout-ActionMenu {
      visibility: visible;
    }
  }

  &_pointer {
    cursor: pointer;
  }
}