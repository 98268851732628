.NewsEdit-Body_type_polls {}

.NewsEdit-Body_type_polls-Poll-Answer-Input {
    display: flex;
    border-top: 1px solid #D3D9E0;
    /*.Input-Icon {
        position: static;
        top: auto;
        left: left;
        transform:none;
    }*/
}

.Button_addNewAnswer {
    padding: 11px 24px 10px 24px;
    .Icon_plus:before {
        margin-left: 0;
        margin-right: 7px;
    }
}