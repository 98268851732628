.Events {
    &-Header {
        display: flex;
    }

    &-ContentBox {
        background-color: #fff;
        margin: 0 4px 0 4px;
        padding: 20px 5%;
    }

    &-ContentTitle {
        margin: 0;
    }

    &-Layout {
        margin-top: 16px;
        background-color: #d4e5e0;
        border-bottom: none;
        padding-bottom: 4px;
        align-items: center;
        border-top-left-radius: 10px;
        margin-left: 12px;
        margin-right: 12px;

        @media (min-width: 576px) {
            margin-left: 24px;
            margin-right: 24px;
        }
    }
}