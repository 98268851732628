.Reply {
    // margin-top: 24px;
    position: relative;
    border-bottom: 1px solid #D3D9E0;
    // padding-bottom: 12px;
    padding-top: 24px;
    display: flex;

    //////////////////////
    &-Aside {
        margin-left: 24px;
    }

    &-Section {
        margin-left: 12px;
        margin-right: 24px;
        flex: 1;
        overflow: hidden;
    }

    &-Header {
        display: flex;
        justify-content: space-between;

        .UserName,
        .GrupLink {
            color: #0066cc;
            font-weight: bold;
        }
    }

    &-Title {}

    &-Service {}

    &-Content {}

    &-Footer {}

    //////////////////////


    &:last-of-type {
        border-bottom: none;
    }

    // .Reply-Content {
    //     padding-left: 80px;
    //     margin-top: -14px;
    //     padding-right: 24px;

    //     p:first-of-type {
    //         margin-top: 0;
    //     }

    //     p:last-of-type {
    //         margin-bottom: 0;
    //     }
    // }

    .Reply-Preview {
        margin-top: 15px;
        margin-left: 24px;
        margin-right: 24px;
    }

    .Actions {
        padding-top: 8px;
        padding-left: 0;
    }

    &:hover {
        .Reply-ServiceActions {
            display: block;
        }
    }

    .paddingHorizontalZero {
        padding-left: 0;
        padding-right: 0;
    }

    .content-padding {
        padding-left: 80px;
        margin-right: 24px
    }
}