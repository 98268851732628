.Matrix {
  padding: 0 24px 10px ;
  width: 100%;
  text-align: center;

  
  &-HelpTooltip{
      top: -174px;
      right: -5px;
      position: relative;
  }
  &-ErrorIcon:before {
    // right: 15px;
    // position: absolute;
    color: red;
  }

  td:first-child {
    text-align: left;
  }

  tr:nth-child(n + 2) td {
    border-top: 1px solid lightgray;
  }

  tr td:nth-child(n + 2) {
    width: 19%;;
  }
  &-Radio {
    width: 12px;
    height: 12px;
  }

}