.StudentsSessionsList {
    &-SessionsTable {
        width: 100%;
        position: relative;
        border-collapse: collapse;
    }

    &-SessionsHead {
        font-weight: bold;

        th {
            background: white;
            position: sticky;
            top: 56px;
            box-shadow: 0 2px 2px -1px #d3d9e0;
        }
    }

    &-SessionItem {}

    &-SessionSummary {
        display: flex;
        margin-bottom: 12px;
    }

    &-SessionProgress,
    &-SessionProgressCol {}

    &-SessionProgressCol {
        padding-top: 12px;
        padding-bottom: 12px;
    }

    &-SessionSpacer {
        border-top: 1px solid #d3d9e0;
        padding: 12px;
    }

    &-SessionStatus {
        &_status {
            &_isAbsolutely {
                color: #f00;
            }

            &_isCompleted {
                color: #0f0;
            }

            &_isMandatory {
                color: #00f;
            }

            &_isProcess {
            }
        }
    }
}