.LMSTOStatFullHeader {
   padding: 16px 32px;
   background-color: #fff;
   border-radius: 8px;
   border: 1px solid #d3d9e0;
   
   &-Content {
      margin-top: 16px;
      display: flex;
      align-items: center;
   }

   &-Title {
      font-weight: bold;
      margin: 8px 0;
   }

   &-Info {
      margin-left: 24px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex: 1;
   }
}