.QuizPassing {
  &-Question {
    display: flex;
    flex-direction: column;
    span {
      margin-right: 12px;
    }
    .Markdown {
      p {
        margin: 0;
      }
    }
    &-Multi {
      color: #999;
    }
  }


  &-Buttons {
    display: flex;
    justify-content: space-between;
    padding: 24px 0;
    button {
      width: 166px;
    }
  }
}