.QuizWidget {
  &-Header {
    padding: 0 0 8px 0;
    display: flex;
    justify-content: space-between;
    &-Title {
      font-size: 16px;
      font-weight: bold;
    }
    .MuiSvgIcon-colorPrimary{
      color:#0066CC;
    }
  &-Total{
    &-Length{
      color: rgba(0, 0, 0, 0.6);
    }
  }
  }
  &-Quiz {
    // border-bottom: 1px solid #D3D9E0;
    &-NoData {
      margin: 0 24px 24px 24px;
      display: block;
    }
  }
  &-Navigate {
    font-size: 14px;
    display: flex;
    &-Item {
      cursor: pointer;
      color: #d3d9e0;
      height: 23px;
    }
  }
}

.QuizWidgetNoData{
  border-radius: 8px;
  border: 1px solid #d3d9e0;
  background-color: #fff;
  padding: 24px;

  &-Header {
    margin: 0 0 8px 0;
    padding: 6.5px 0;
    color: #000;
    border: none;
    text-decoration: none !important;

    &-Title {
      margin-bottom: 8px;
      font-weight: 700;
      font-size: 16px;
      line-height: 116%;
      letter-spacing: 0.15px;
      padding: 6.5px 0;
      margin-bottom: 8px;
      color: rgba(0, 0, 0, 0.87) !important;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      width: 245px
    }

    &-Text {
      font-size: 14px;
      line-height: 20.02px;
      width: 100%;
      margin-bottom: 12px
    }

  }
}