.ImageLoaderPreview {
  width: 104px;
  height: 104px;
  position: relative;
  box-sizing: border-box;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;

  &_circle {
    border-radius: 50%;
  }

  &_empty {
    background-color: #d1d1d1;
    border: 8px solid #e7e7e7;
  }

  &-Icon {
    font-size: 24px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}