@import '../../utils/global.scss';

.ImgLoader {
  position: relative;
  min-height: 150px;

  &-Progress {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0%;
    height: 3px;
    background-color: #6e839c;

    transition: width $TransitionDuration $TransitionTimingFunction;
    
    &.success {
      background-color: #89ca5e;
    }
  }
}