@import '../../utils/global.scss';
.Layout {
  font-family: $FontFamilyRegular;
  transition: all $TransitionDuration $TransitionTimingFunction;

  &_auth {
    padding-top: $HeaderHeight;
  }

  @media screen and (min-width: 768px) {
    padding-left: $LeftMenuFoldedWidth;
    // &_showMenu .Layout-Inner {
    //   transform: translateX($LeftMenuWidth - $LeftMenuFoldedWidth);
    // }
  }

  &-Inner {
    box-sizing: border-box;
    max-width: 1170px;
    margin: 0 auto;
    transition: all 0.3s ease-in-out;
  }

  &-Inner.FullWidth,
  &-Inner.FullWidthForce {
    max-width: unset;
  }
  
  &_wv {
    padding: 0;
  }
}

