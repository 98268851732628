.SurveyAnswerItem {
    margin-bottom: 6px;

    &-Slider {
        width: 100%;
        max-width: 600px;
    }

    .MuiTypography-root.MuiFormControlLabel-label.MuiTypography-body1 {
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        color: rgba(0, 0, 0, 0.87);
        padding-top: 10px;
    }


    .MuiButtonBase-root.MuiIconButton-root {
        color: rgba(0, 0, 0, .54);
    }

    .MuiTypography-root.MuiFormControlLabel-label.Mui-disabled.MuiTypography-body1 {
        color: rgba(0, 0, 0, 0.38);
    }

    .MuiCheckbox-colorPrimary.Mui-disabled {
        color: rgba(0, 0, 0, 0.26);
    }

    // .MuiSvgIcon-root {
    //     fill: currentColor;
    //     width: 20px;
    //     height: 20px;
    // }

    .MuiInputAdornment-positionEnd {
        .MuiSvgIcon-root {
            width: 26px;
            height: 27px;
        }

        .MuiSvgIcon-root[data-testid="ClockIcon"] {
            width: 24px;
            height: 24px;
        }
    }

    .InputMUI,
    .TextField {
        margin-top: 11px;

        input.MuiInputBase-input,
        textarea.MuiInputBase-input {
            font-size: 14px;
            font-family: 'Open Sans';
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: 0.15px;
        }
    }

    &-Description {
        .emojione-24-people {
            top: 0!important
        }
    }
}