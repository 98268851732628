.CourseInfo {
  border: 1px solid #d3d9e0;
  border-radius: 8px;
  background-color: #ffffff;

  &-Noop {
    background-color: #ffffff;
    flex: 1;
  }

  &-Content {
    display: flex;
    // border-radius: 8px;
    padding-right: 24px;
    padding-left: 43px;
    // padding-top: 18px;
    // border-top-right-radius: 8px;
    // border-top-left-radius: 8px;
    // border: 1px solid #d3d9e0;
    // background-color: #ffffff;
  }

  &-Actions {
    .Icon_eye:before {
      width: 1.6em;
    }

    // width: 60px;
    // display: flex;
    // justify-content: space-between;
    // align-items: center;
  }

  &-TopActions {
    display: flex;
    margin: 8px 24px;
    justify-content: space-between;
  }

  &-BackLink {}

  &-Nav {
    // background-color: #ffffff;
    display: flex;
    justify-content: center;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
    overflow: hidden;
  }

  &-ImageBox {
    margin-right: 24px;
    width: 178px;

    &-BackgroundImg {
      .ImageLoader-Block_preview {
        position: relative;
        flex: 0 0 104px;
        border: 1px solid #ccc;
        padding: 10px;
        border-radius: 4px;
      }

      &-downloadBTN {
        border-radius: 4px;
        position: absolute;
        background-color: #fff;
        top: 60px;
        left: 49px;
      }
    }

    &-DescBox {
      flex: 1;
    }

    &-Progress {
      margin-top: 36px;
    }

    &-Authors {
      margin-top: 12px;
      display: flex;
      flex-wrap: wrap;
      margin-left: -12px;
      margin-right: -12px;
    }

    &-Author {
      margin: 12px;
    }

    &-AuthorsList {
      width: 100%;
    }

    &-Title {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
    }

    &-Like {
      justify-content: center;
      margin-top: 24px;
    }

    &-Name {
      font-size: 24px;
      font-weight: bold;
    }
  }
}