@import '../../../utils/global.scss';

.Modal-Footer {
  text-align: center;
  padding: 10px 0;

  &_border {
    border-top: 1px solid $BorderColor;
  }

  &-Button {
    padding: 10px 25px;
  }
}


@media all and (-ms-high-contrast: none),
(-ms-high-contrast: active) {

  /* IE10+ CSS styles go here */
  .Modal-Footer {
    height: 60px;
  }
}