.SkipedQuestion {
    // cursor: pointer;
    padding: 24px;
    border-top: 1px solid #D3D9E0;

    // &:hover {
    //     background-color: #0066cc;
    //     color: #fff;
    // }

    &-Start {
        display: block;
        margin: 12px auto;

    }

    &-Question {

    }

    &-Header {
        margin: 0;
    }

    &-Required {
        color: #0f0;
        &_require {
            color: #f00;
        }
    }
}