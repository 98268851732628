.Reactions {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 20px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    width: 300px;
    padding: 5px 10px;
    border-radius: 50px;
    border: 1px solid #d3d9e0;
    background-color: #fff;
}