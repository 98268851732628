.HeaderWikiWithFilters {
    background: #FFFFFF;
    padding: 16px;
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12);
    border-radius: 4px;

    margin-bottom: 24px;
    
    &-Header {

        &-CreateButton {
            .MuiButtonBase-root.MuiButton-root {
                padding: 0.4em 1.14em;
            }

            .MuiButton-label {
                text-transform: uppercase;
                white-space: nowrap;
            }
        }
    }

}