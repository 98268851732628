  .PreListFilters {
      &-BulitsForSearch {
          // display: flex;
          width: 100%;
          // align-items: center;

          &-WikiTypes {
              display: flex;
              align-items: center;
              font-weight: 400;
              font-size: 16px;
              line-height: 150%;
              letter-spacing: 0.15px;
              padding-left: 8px;
            
              &-Lable {
                  // font-weight: 400;
                  // font-size: 16px;
                  // line-height: 150%;
                  // letter-spacing: 0.15px;
                  margin-right: 10px;
              }
          }

          &-SortFilters {
              display: flex;
              flex-wrap: wrap;


              &-Item {
                  margin-right: 14px;
                  font-weight: 500;
                  font-size: 16px;
                  line-height: 150%;
                  letter-spacing: 0.15px;
                  display: flex;
                  white-space: nowrap;
                  align-items: center;
                  cursor: pointer;
              }
              &-Item._active {
                //   margin-right: 14px;
                //   font-weight: 500;
                //   font-size: 16px;
                //   line-height: 150%;
                //   letter-spacing: 0.15px;
                //   display: flex;
                //   white-space: nowrap;
                //   align-items: center;
                //   cursor: pointer;
                //   text-decoration: underline;
                background-color:  rgba(0, 102, 204, 0.08);
                
              }
            
          }
      }
  }