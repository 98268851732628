.ThanksSelector {
    &-InputBox {
        display: flex;
        cursor: text;
        width: 100%;
        line-height: normal;
        // margin-top: 5px;
        line-height: normal;
        align-items: center;
    }

    .InputLayout-EditZone {
        padding-top: 5px;
    }

    .Button {
        padding: 0 4px 0px 1px;
    }

    &-Reward {
        font-weight: bold;
    }

    &-Input {
        border: none;
        padding: 0;
        color: #89ca5e;
        font-weight: bold;
        font-size: 24px;
        margin: 0;
        // padding-top: 3px;
        padding-top: 2px;
        padding-left: 3px;
        width: 70px;
    }
}