.Courses {
  &-Aside {
    // background-color: #ffffff;
    flex: 1 4;
    margin-left: 24px;
    // border-radius: 8px;
  }

  .LMSTOComments {
    margin-top: 0;
  }

  &-Header {
    // -Header {
    border: 1px solid #d3d9e0;
    border-radius: 8px;
    background-color: white;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08);
    padding: 16px 32px;
    margin: 16px 2% 0;
    margin: 0;
    margin-bottom: 12px;
  }

  &-Noop {
    background-color: #ffffff;
    flex: 1;
  }

  &-HeaderSection {
    display: flex;
    align-items: center;
  }

  &-HeaderNav {
    display: flex;
    justify-content: center;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
    overflow: hidden;
    flex: 1;
  }

  &-HeaderAvatar {}

  &-HeaderInfo {
    margin-left: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 1;
    position: relative;
    &-VideoHelp{
      position:absolute;
      top: -17px;
      right: -23px;
      font-size: 22px;
    }
  }

  &-HeaderTitle {}

  &-HeaderActions {}

  .Courses-Header {
    margin: 0;
    margin-bottom: 24px;
  }

  &-SearchBox {
    background: #f7f7f7;
    border-radius: 8px;
    flex: 3 4;
    padding: 24px;
    margin-bottom: 24px;
    border: 1px solid #d3d9e0;
  }

  &-SearchInput {
    max-width: none;
  }

  &-Trigger {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  &-Content {
    flex: 3 4;
  }

  &-Section {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
  }

  &-Statuses {
    flex: 1;
    margin-bottom: 24px;
  }

  &-Items {
    flex: 3 4;

    .Masonry-Column {
      &:not(:last-child) {
        margin-right: 24px;
      }
    }
  }
}

.Courses-Title {
  font-weight: bold;
  margin: 8px 0;
}

.Courses-Description {
  color: var(--text-muted-color);
  margin: 0;
}