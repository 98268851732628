@import '../../../../utils/global.scss';

.EditorBadge {
    &-InputMention {
        border-top: 1px solid #D3D9E0;
    }

    .PryanikyEditor {
        border-top: 1px solid #D3D9E0;
        border-bottom: 1px solid #D3D9E0;
        border-radius: 0;
    }

    div.DraftEditor-editorContainer {
        min-height: auto;
    }

    .BadgeSelector {
        border-bottom: 1px solid #D3D9E0;
    }
}

.Button_badgeSelect {
    display: block;
    width: 100%;
    padding: 10px;
    text-align: left;
}

.Badge {
    position: relative;

    &-Logo,
    &-Text {
        display: inline-block;
        vertical-align: middle;
    }

    &-Logo {
        // position: absolute;
        // top: 0;
        // left: 0;

        &_icon {
            text-align: center;
            width: 80px;
            height: 80px;
            line-height: 80px;
            border-radius: 50%;
            border: 4px solid #e7e7e7;
            background-color: #d1d1d1;
            color: #666;
            font-size: 24px;
        }
    }

    &-Text {
        width: calc(100% - 108px);
        margin-left: 20px;

        &-Item {

            &:nth-child(1) {
                font-weight: bold;
            }
        }
    }
}