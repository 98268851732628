.TaskDocumentReadBlock {

  &-Header {
    padding: 11px 24px;
    position: relative;
  }

  &-Remove {
    position: absolute;
    right: 24px;
    top: 6px;
    padding: 5px;
  }

  &-Fileload {
    width: 100%;
  }
}