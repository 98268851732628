.CountSlider_type_modal {
        z-index: 1;
}

.CountSlider_type_modal {
    .CountSlider-Slider {
        border: none;
        border-top: 1px solid #d3d9e0;
        border-bottom: 1px solid #d3d9e0;
        position: static;
        display: flex;
        align-items: center;
        height: 40px;

        .CountSlider-Slider-Counts {
            position: static;
            margin-top: 0;
            line-height: normal;
        }

        .CountSlider-Slider-Bar {
            flex: 1 1;
            margin: 0;
        }
    }
}

.ModalCountSlider {
    box-shadow: 0px 0px 10px -5px rgba(0, 0, 0, 0.3);
}

.ModalCountSlider-Footer {
    background-color: #fff;
    display: flex;
    justify-content: center;
}
.ModalCountSlider-SaveBtn {
    background-color: #0066cc;
    border-radius: 5px;
    color: #fff;
}
.ModalCountSlider-SaveBtn,
.ModalCountSlider-CancelBtn {
    padding: 10px 24px;
    margin: 15px 5px;
}