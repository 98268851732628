.Filters{
    border-radius: 4px;
    background: #FFFFFF;
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12);
    // gap: "16px",
    &-Title{
        margin: 0 0 8px 0;
    }
    &_type_timeline {
        .Filters-Subtitle{
            font-size: 16px;
            margin: 0;
        }
    }
    &-RadioGroup{
        margin: 0 24px 26px!important;
    }
    &-Categiries{
        margin: 0px 24px 24px 32px!important;
    }
    &-showMore{        
        padding: 0;
        .MuiButton-label{
            font-size: 14px;
        }
        .MuiButton-textPrimary:hover {
            background-color: transparent;
        }
        .MuiButton-textSizeSmall {
            padding: 0;
        }
    }
    &-ActiveChip{
        margin: 0px 4px 4px 0;
        .MuiChip-label{
            font-size: 13px;
        }
    }
    &-ActiveTypes{
        margin: 0 0 14px;
    }
    &-ActiveAll{
        font-size: 16px;
        &-Name{
            text-transform: lowercase;
        }
    }
    // .MuiAutocomplete-root.MuiAutocomplete-hasClearIcon.MuiAutocomplete-hasPopupIcon{
    //     margin: 0 24px 24px 24px!important;
    //     max-width: 85%;
    //     label.MuiFormLabel-root, 
    //     label.MuiInputLabel-root {
    //         font-size: 18px;
    //         font-family: 'Open Sans';
    //         /* margin-bottom: 5px; */
    //     }
    //     .MuiFormLabel-root.Mui-focused {
    //         color: rgba(0, 0, 0, 0.54);
    //     }
    //     .MuiInput-underline:after {
    //         left: 0;
    //         right: 0;
    //         bottom: 0;
    //         content: "";
    //         position: absolute;
    //         transform: scaleX(0);
    //         transition: transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
    //         border-bottom: 2px solid #000;
    //         pointer-events: none;
    //     }
    // }
    &-UseFilterBtn{
        .MuiButton-label{
            text-transform: uppercase;
        }
    }
 &-Types{
    margin: 14px 24px 24px 24px!important;
    max-width: 85%;
    // label.MuiFormLabel-root, 
    // label.MuiInputLabel-root {
    //     font-size: 18px;
    //     font-family: 'Open Sans';
    //     /* margin-bottom: 5px; */
    // }
    .MuiFormLabel-root.Mui-focused {
        color: rgba(0, 0, 0, 0.54);
    }
    .MuiInput-underline:after {
        left: 0;
        right: 0;
        bottom: 0;
        content: "";
        position: absolute;
        transform: scaleX(0);
        transition: transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
        border-bottom: 2px solid #000;
        pointer-events: none;
    }
 }
}