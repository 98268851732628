@import '../../../../utils/global.scss';


.List-Item_type_common {
  padding: 12px 24px;
  display: flex;
  position: relative;
  align-items: center;
  .MuiFormControlLabel-root {
    margin-right: 0px;
  }
  .MuiButtonBase-root.MuiIconButton-root{
    color: rgba(0, 0, 0, 0.54); 
  }
  .Button:not(a) {
    opacity: 0;
  }

  

  &:hover,
  &:hover .Group-Label,
  &:hover .List-Item-DataPart a.Button {
    background-color: var(--main-color);
    color: var(--invariant-text-color);
  }

  &:hover .Button {
    opacity: 1;
  }

  /*&:first-of-type {
    @include BorderRadiusTop;
  }*/
  &:last-of-type {
    @include BorderRadiusBottom;
  }

  .List-Item {
    &-RefDiv {
      display: none;
    }
    &-Avatar {
      margin-left: 12px;
    }
    &-DataPart {
      flex: 1;
      min-width: 100px;
      // width: calc((100% - 115px) / 2);
      &:nth-child(n + 2) {
        margin-left: 15px;
      }

      // &_single {
      //   width: calc(100% - 100px);
      // }

      > div {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        > a {
          display: inline;
        }
      }
    }

    &-Actions {
      position: relative;
      // z-index: 1;
      opacity: 0;

      > .Button {
        // padding: 0;
        line-height: 1;
      }
    }
  }

  &.nochecked {
    &.hide_second_data_part {
  
      .List-Item-DataPart:not(.List-Item-DataPart_alone) {
        
        &:nth-of-type(n + 3) {
  
          display: none;
        }
      }
    }
  }

  &:not(.nochecked) {
    &.hide_second_data_part {
  
      .List-Item-DataPart:not(.List-Item-DataPart_alone) {
        
        &:nth-of-type(n + 4) {
  
          display: none;
        }
      }
    }
  }


  &:hover {
    .List-Item-Actions {
      opacity: 1;
    }
  }
}

.List-Item_type_common.List-Item_tree {

  .Checkbox {
    position: absolute;
    left: 24px;
  }

  .List-Item {

    &-Avatar {
      margin-left: 24px;
    }
  }
}