.LMSScormResult {

    &-Table {}

    &-Key {}

    &-Value {}

    &-Content {
        width: 300px;
        overflow: hidden;
    }

    &-NotData,
    &-Summary {
        padding: 24px;
    }

    &-Title {
        margin: 24px;
    }

    &-Questions {}
}