
.UserInviteDialog{
  background-color: #fff;
  &-HelpTitle{
    font-size: 14px;
    font-weight: 400;
    margin: 0;
  }
  &-Input{
    border: 1px solid #ccc;    
    margin: 10px 24px;
    padding: 0px;
  
  }
  &-Suggest{
    margin: 10px 24px;
  }
  &-notImportant{
    color:#0066cc;
    margin:24px;
    
  }
  &-AvatarLoader{
    margin: 10px 24px;
  }
  &-Radio{
    display: block!important;
    // flex: 1 1 100%!important;
    margin: 24px!important;
  }
  &-Sex{
    width: 100%;
  }

  .CollapseBox-Content {
        border-bottom: 1px solid #d3d9e0;
        .LineInput{
            margin: 0 9px;
            padding: 5px 0;
        }
    }

    &-CollapseBox-Toggle {
    display: flex;
    cursor: pointer;
    background: #f7f7f7;
    padding: 9px 24px;
    border-bottom: 1px solid #d3d9e0;
    }

    &-CollapseBox-Toggle-Text {
    margin: 0;
    flex: 1 1;
    }


}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    /* IE10+ CSS styles go here */
  .UserInviteDialog{
    &-CollapseBox{
      width: 695px;
    }
  }
}