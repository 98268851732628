.SurveyPassing {
  &-Answers {
    .AnswerItem {
      cursor: pointer;
      display: block;
      align-items: center;
      margin: 4px 0;
    }

  }

  &-Question {
    display: flex;
    flex-direction: column;
    margin-top: 24px;

    span {
      margin-right: 12px;
    }

    .Markdown {
      p {
        margin: 0;
      }
    }

    &-Multi {
      color: #999;
    }

    &-Title {
      font-weight: 500;
      font-size: 20px;
      line-height: 160%;
      letter-spacing: 0.15px;
      color: rgba(0, 0, 0, 0.87);
      display:flex;
      align-items: center;
    }

    &-Text {
      font-weight: 400;
      font-size: 16px;
      line-height: 150%;
      letter-spacing: 0.15px;
      color: rgba(0, 0, 0, 0.87);

      .CommonmarkRenderImgBlock-Expand {
        display: none;

        .Icon_expand-arrows-alt {
          display: none;
        }
      }
    }
  }


  &-Buttons {
    display: flex;
    justify-content: space-between;
    padding: 16px 0 24px 0;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.4px;

    .MuiButton-contained.Mui-disabled {
      text-transform: uppercase;
    }

    .MuiButton-outlined {
      .MuiButton-label {
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 0.4px;
      }
    }


    button {

      text-transform: uppercase;
    }
  }

  &-Warning {
    margin-top: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 16px;
    // width: 838px;
    // height: 68px;
    border: 1px solid #2196F3;
    border-radius: 4px;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;

    span {
      // font-family: 'Roboto';
      // font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 143%;
      /* or 20px */
      padding: 8px 0px;
      letter-spacing: 0.15px;
      color: #305e83;
      height: 40px;
      display: flex;
      align-items: center;
      margin-left: 14px;
    }
  }
}

@media (max-width: 576px) {
  .SurveyPassing {
    &-Warning {


      span {
        font-size: 0.9em;

      }
    }
  }

}