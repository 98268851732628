.LMSTOStatUserCoursesListItem {
    td {
        padding: 12px;
    }
    &-Col1 {
        padding: 24px;
        // line-height: 0;
    }

    &-Col2 {
        text-align: left;
    }

    &-UserItem {
        display: flex;
        align-items: center;
    }

    &-UserMention {
        font-weight: bold;
    }

    &-UserData {
        margin-left: 16px;
    }
}