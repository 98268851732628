@import 'utils/src/global.scss';

.CustomNodePositions{
  &-VacancyButton{
      border-radius: 50%;
      width: 36px;
      height: 36px;
      // height: 42px;
      border: 1px solid #ccc;
      margin-left: 7px;
      display: flex;
      align-items: center;
      justify-content: center;
      img{
        width:36px;
      }
      // &:before {
      //     content: "";
      //     float: left;
      //     padding-top: 100%;
      //     display: block;
      // }
  }
  &-ActionsMenu{
    list-style: none;
    margin:0; 
    padding: 0;
    &-Item{
      border-top: 1px solid #afafaf;
      padding: 0px 36px;
      white-space: nowrap;
      &-ActionBTN{
      }
    }
    &-Item:last-child{
       border-bottom : 1px solid #afafaf;
    }
    &-Item:hover{
      background: #0066cc;
      padding: 0px 36px;
      button{
        color:#fff;   
      }
    }
  }
    &-Vacancy{
        border-radius: 50%;
        // border: 1px solid #000;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        &-preBox{
          display:flex;
        }
        &-ImgBox {
            position: relative;
            text-align: center;
            display: flex;
        }
        &-ImgBoxEdit {
            position: relative;
            text-align: center;
            display: flex;
        }
        &-RemoveBadge {
            position: absolute;
            z-index: 10;
            top: -5px;
            right: -5px;
            padding: 0 2px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 15px;
            height: 18px;
            border: 1px solid #fff;
        }
        &-TypeBadge {
            position: absolute;
            z-index: 0;
            right: -5px;
            bottom: -5px;
            padding: 0 2px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 15px;
            height: 18px;
            
            &-TypeIMG{
              width: 20px;
              height: 20px;
            }
            &-NoTypeIMG{
              display: none;
            }
            &-onlyVacancy{
              display: none;
            }
        }
        &-EditBadge {
            position: absolute;
            z-index: 10;
            right: -3px;
            bottom: -1px;
            padding: 0 2px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 15px;
            height: 18px;
            border: 1px solid #fff;
        }
    }
    &-VacancyOpen{
      border-radius: 50%;
      -webkit-box-shadow: -1px 0px 14px -1px rgba(247, 37, 5, 0.45);
      -moz-box-shadow: -1px 0px 14px -1px rgba(247, 37, 5, 0.45);
      box-shadow: -1px 0px 14px -1px rgba(247, 37, 5, 0.45);
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 0;
    }
    .MuiTooltip-tooltip{
      color:#000;
      background-color: #fff;
    }
}
.PositionTooltip {
    display: flex;
    align-items: flex-start;
    min-width: 250px;
    max-width: 350px;
    margin:10px;

    &-Data {
        padding-left: 12px;
        text-align: left;
    }

    &-Name {
        font-weight: bold;
        &-Vacancy{
          font-weight: 400;
        }
    }
    &-Img{
        height: 40px;        
    }
    &-Description{
      display: flex;
      align-items: center;
      span{
        margin-left: 10px;
      }
    }
   
    
}



.PositionTooltipUser {
  position: relative;
  display: inline-block;

  &-Link {
    font-weight: bold;
    color: var(--main-color);
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  &-Popup {
    width: 500px;
    display: flex;
    align-items: flex-start;
    color: var(--text-color);
    line-height: 14px;
    margin: 10px;
  }

  &-Contact,
  &-Name,
  &-Network,
  &-Position,
  &-Division {
    margin-bottom: 8px;
    margin-top: 0;
  }

  &-Contact {
    text-align: left;
    .PositionTooltipUser-CopyBTN{
      opacity: 0;
    }
  }

  &-Contact:hover{
    .PositionTooltipUser-CopyBTN{
      opacity: 1;
    }
  }

  &-Description{
    display: flex;
    align-items: center;
    padding-bottom: 5px;
    span{
      margin-left: 10px;
    }
  }
  &-ContactName {
    width: 180px;
    display: inline-block;
    font-weight: bold;
    margin-right: 4px;
    &::first-letter {
      text-transform: uppercase;
    }
    &-HideInput{
      opacity: 0;
      position: absolute;
      z-index: -10;
    }
  }

  &-Avatar {
    margin-right: 15px;
    text-decoration: none;

    &:hover+.PositionTooltipUser-Info>.PositionTooltipUser-Name {
      text-decoration: underline!important;
    }
  }
  &-Popup{
    &-VacancyOpen{
       border: 3px solid #ea0f0f;
    }
  }
  &-Info {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &-NetworkIcon {
    margin-right: 6px;
  }

  &-Name {
    display: block;
    font-size: 16px!important;
    text-decoration: none;
    font-weight: bold!important;
    color: var(--text-color)!important;
  }

}