@import './utils/global';

iframe {
    border: none;
    box-shadow: none;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Open Sans', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // @include HiddenScrollbars;
  font-size: 12px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.system {
  &Border {
    border: 1px solid;
  }

  &BorderColorGray {
    border-color: #d3d9e0;
  }
}

.FontFamily {
  &_regular {
    font-family: 'Open Sans regular';
  }

  &_bold {
    font-weight: bold;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: inset 0 0 0px 9999px white;
}

h1 {
  font-weight: normal;
  font-size: 32px;
  line-height: 1.2;
}

h2 {
  font-weight: normal;
  font-size: 24px;
  line-height: 1.2;
}

h3 {
  font-weight: normal;
  font-size: 20px;
  line-height: 1.3;
}

h4 {
  font-size: 14px;
  line-height: 1.3;
}

h5 {
  font-size: 12px;
  line-height: 1.8;
}

input,
textarea,
button {
  font-size: inherit;
  line-height: inherit;
}

a {
  color: var(--main-color);
}

// a:visited {
//   color: var(--visited-link-color);
// }

ul,
ol {
  line-height: 2.2;
}

@media (min-width: 576px) {
  body {
    font-family: 'Open Sans', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
      'Helvetica Neue', sans-serif;
    font-size: 14px;
    line-height: 1.8;
  }

  h1 {
    font-weight: normal;
    font-size: 40px;
  }

  h2 {
    font-weight: normal;
    font-size: 32px;
  }

  h3 {
    font-weight: normal;
    font-size: 24px;
  }

  h4 {
    font-size: 16px;
  }

  h5 {
    font-size: 14px;
  }
}

.clear {
  clear: both;
}

input,
textarea {
  outline: none;
}

.rounded {
  @include BorderRadius;
}

.rounded-top {
  border-radius: 8px 8px 0 0;
  // overflow: hidden;
}

.rounded-bottom {
  border-radius: 0 0 8px 8px;
  // overflow: hidden;
}

.squared {
  border-radius: 0;
}

.no-spacing {
  margin: 0;
  padding: 0;
}

$directions: (
  l: 'left',
  r: 'right',
  t: 'top',
  b: 'bottom',
);

@mixin margin-list {
  @each $code, $dir in $directions {
    @for $i from 0 through 5 {
      .m#{$code}-#{$i} {
        margin-#{$dir}: #{$i * 8}px;
      }
    }
  }
  @for $i from 0 through 5 {
    .mx-#{$i} {
      margin-left: #{$i * 8}px;
      margin-right: #{$i * 8}px;
    }
    .my-#{$i} {
      margin-top: #{$i * 8}px;
      margin-bottom: #{$i * 8}px;
    }
  }
}

@include margin-list;

$spacers: (
  none: 0,
  xs: 4,
  sm: 8, 
  md: 12,
  lg: 16,
  xl: 24,
  xxl: 32
);

@mixin spacers {
  @each $size, $pixels in $spacers {
    .spacing-#{$size} {
      margin: #{$pixels}px;
    }
  }
}

@include spacers;

.no-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
}

.unstyled {
  color: inherit;
  text-decoration: inherit;
  font: inherit;
}
