.draftJsEmojiPlugin__emojiSelectButton__3sPol,
.draftJsEmojiPlugin__emojiSelectButtonPressed__2Tezu {
    margin: 0;
    padding: 0;
    width: 2.5em;
    height: 1.5em;
    box-sizing: border-box;
    line-height: 1.2em;
    font-size: 1.5em;
    color: #888;
    background: transparent;
    border: none;
    border-radius: none;
    cursor: pointer;
}

.draftJsEmojiPlugin__emojiSelectButton__3sPol:hover,
.draftJsEmojiPlugin__emojiSelectButtonPressed__2Tezu:hover {
    background: transparent;
}

.PrnEdit-blockquote {
    border-left: 4px solid #ccc;
    margin-bottom: 5px;
    margin-top: 5px;
    padding-left: 16px;
}

.ToolbarIcon {
    width: 100%;
    height: auto;
    display: block;
}

.alignmentTool {
    display: inline-flex;
}

/*    left: 50%;

    display: flex;
    transform: translate(-50%) scale(0);

    position: absolute;

    border: 1px solid #ddd;

    background: #fff;

    border-radius: 2px;

    box-shadow: 0px 1px 3px 0px rgba(220, 220, 220, 1);

    z-index: 2;

    box-sizing: border-box;
} */

.inlineToolbar {
    position: absolute;
}

.PryanikyEditor {
    width: 100%;

    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
}

.PryanikyEditor a.Button.Button_theme_unstyled.Button_type_rlink,
.PryanikyEditor a.Button.Button_theme_unstyled.Button_type_link {
    display: inline;
}

.PryanikyEditor figure {
    margin: 0;
}

.PryanikyEditor figure img {
    margin-left: 6px;
    margin-right: 6px;
}


.PryanikyEditor .public-DraftStyleDefault-unorderedListItem.public-DraftStyleDefault-depth1, 
.PryanikyEditor .public-DraftStyleDefault-unorderedListItem.public-DraftStyleDefault-depth1, 
.PryanikyEditor .public-DraftStyleDefault-unorderedListItem.public-DraftStyleDefault-depth1 {
    list-style-type: circle;
}
.PryanikyEditor .public-DraftStyleDefault-depth1 {
    margin-left: 40px;
}
.PryanikyEditor .public-DraftStyleDefault-depth2 {
    margin-left: 80px;
}
.PryanikyEditor .public-DraftStyleDefault-depth3 {
    margin-left: 120px;
}
.public-DraftStyleDefault-depth0.public-DraftStyleDefault-reset {
    counter-reset: ol0;
}

.public-DraftStyleDefault-depth1.public-DraftStyleDefault-reset {
    counter-reset: ol1;
}

.public-DraftStyleDefault-orderedListItem {
    list-style-type: none;
}
.public-DraftStyleDefault-orderedListItem {
    list-style-type: none;
    position: relative;
}
.public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-listLTR:before {
    left: -36px;
    position: absolute;
    text-align: right;
    width: 30px;
}

.public-DraftStyleDefault-orderedListItem:before {
    content: counter(ol0) ". ";
    counter-increment: ol0;
}
.public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-depth1:before {
    content: counter(ol1,decimal) ". ";
    counter-increment: ol1;
}
.public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-listLTR:before {
    left: -36px;
    position: absolute;
    text-align: right;
    width: 30px;
}

.prn-editor-container {
    background-color: #fff;
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 6px;
    padding-bottom: 6px;
}

.prn-editor-container::after {
    clear: both;
    display: table;
    content: " "
}

.DraftEditor-root {
    /* min-height: 100px; */
    /* padding-top: 26px; */
    /* padding-bottom: 12px; */
}

.DraftEditor-editorContainer {
    min-height: 72px;
    /* width: 100%; */
}

.public-DraftEditorPlaceholder-root

/*.public-DraftEditor-content*/
    {
    /* margin: 0 -15px -15px; */
    /* padding: 15px; */
    color: hsl(0, 0%, 50%);
    position: absolute;
}

.public-DraftEditorPlaceholder-root::first-letter {
    text-transform: capitalize;
}

.PryanilyEditor-hidePlaceholder .public-DraftEditorPlaceholder-inner {
    display: none;
}

.public-DraftEditorPlaceholder-hasFocus

/*.public-DraftEditorPlaceholder-inner*/
    {
    display: none;
}

.public-DraftEditorPlaceholder-inner:before {
    content: "\ee27";
    font-family: 'pryaniky-icons';
    font-style: normal;
    font-weight: normal;
    display: inline-block;
    text-decoration: inherit;
    width: 1em;
    margin-right: 0.2em;
    text-align: center;
    /* opacity: .8; */
    font-feature-settings: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1em;
    margin-left: 0.2em;
    /* font-size: 120%; */
    /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
    /* margin-left: 5px; */
    font-size: 16px;
    text-align: center;
    margin-right: 21px;
}

.PryanikyEditor_noIcon .public-DraftEditorPlaceholder-inner:before {
    display: none;
}

.textCenter {
    color: #f00;
    text-align: center;
    display: block;
}

.alignment--center {
    text-align: center;
}

.alignment--left {
    text-align: left;
}

.alignment--right {
    text-align: right;
}

.alignment--justify {
    text-align: justify;
}