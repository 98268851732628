.Replyes-ReplyForm {
    border-top: 1px solid #D3D9E0;

    .Replyes-ReplyForm-Actions {
        background-color: #F7F7F7;
        padding: 8px 13px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    .Replyes-ReplyForm-Visible {
        padding: 8px 13px;
        display: flex;
        align-items: center;
    }

    .Replyes-ReplyForm-EditActions {}

    .Replyes-ReplyForm-SubmitActions {}

    .Replyes-ReplyForm-EditButton {
        margin-right: 22px;
    }

    .Replyes-ReplyForm-SendButton {
        padding: 10px 20px;
    }

    .TextareaMentions-Wrapper__highlighter,
    .TextareaMentions-Wrapper__input {
        min-height: auto;
        padding: 5px;
        margin: 0 5px;
        line-height: 24px;
    }

    .TextareaMentions {
        flex: 1 1;
    }

    .Replyes-ReplyForm-Part {
        flex: 1 1 100%;
        padding: 5px 0;
    }

    .Replyes-ReplyForm-Thanks {
        position: relative;

        .css-1pcexqc-container {
            margin: 0 10px;
        }

        .css-bg1rzq-control,
        .css-1szy77t-control,
        .css-yk16xz-control,
        .css-1szy77t-control:focus,
        .css-1szy77t-control:hover,
        .css-yk16xz-control:focus,
        .css-yk16xz-control:hover {
            border: none;
            outline: 0;
            box-shadow: none;
        }
    }

    .Replyes-ReplyForm-CloseThank {
        margin: 0 10px 0 auto;
        display: block;
        font-size: 24px;
    }

    

    .Replyes-ReplyForm-UserInput {}
}