.Timeline {
  &-Form {
    margin-bottom: 24px;
  }
  &-CollapseBox-Toggle {
    display: flex;
    height: 59px;
    align-items: center;
    cursor: pointer;
    background: #f7f7f7;
    padding: 9px 24px;
    border-radius: 8px;
    border: 1px solid #d3d9e0;
  }

  .CollapseBox-Content {
    border-bottom: 1px solid #d3d9e0;

    .CreateNews {
      border-top-right-radius: 0;
      border-top-left-radius: 0;
    }
  }

  .Timeline-CollapseBox-Toggle-open {
    display: flex;
    height: 59px;
    align-items: center;
    cursor: pointer;
    background: #f7f7f7;
    padding: 9px 24px;
    border-radius: 8px 8px 0 0;
    border: 1px solid #d3d9e0;
    border-bottom: none;
  }

  &-CollapseBox-Toggle-Text {
    margin: 0;
    flex: 1 1;
  }

  &-Placeholder {
    margin-top: 24px;
    background-color: var(--background-color);
    border: 1px solid var(--border-color);
    border-radius: 8px;
    padding-left: 25%;
    padding-right: 25%;

    .List-Placeholder-Image {
      width: 100%;
      height: auto;
      display: block;
    }
  }

  &-Button_more {
    display: block;
    width: 100%;
    margin-top: 12px;

    &:disabled {
      background-color: #fff;
    }
  }

  >.NewsView {
    margin-top: 24px;
  }

  >.NewsPostBlock_hide+.NewsView {
    margin-top: 0px;
  }

  &_postOff {

    >.NewsView:nth-child(1),
    >.PostSkeleton:nth-child(1) {
      margin-top: 0px;
    }
  }
}

.Widgets-Column_fullWidth .Timeline {
  margin: 0 auto;
  max-width: 808px;
}