.EditorAchievements {
    .AchievementsSelector {
        border-top: 1px solid #D3D9E0;
    }
    .PryanikyEditor {
        border-top: 1px solid #D3D9E0;
        border-bottom: 1px solid #D3D9E0;
    }

    &-Disabled {
        border-top: 1px solid #D3D9E0;
        font-size: 20px;
        text-align: center;
    }
}