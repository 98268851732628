.Modal-Body_type_imageCrop {

  .Modal-Body {
    &-Cropper {
      text-align: center;
      margin: 10px 0;

      &-Input {
        display: block;
      }

      &-Preview {
        margin-top: 10px;

        > * {
          vertical-align: middle;
        }
        
        > *:nth-child(n + 2) {
          margin-left: 20px;
        }
      }
    }
    
    .ReactCrop {
      
    }
  }
}