.Bar {
  background: linear-gradient(#fff, transparent);
  box-sizing: border-box;
  padding: 10px;
  margin-top: auto;
  font-size: 10px;
  transition: all .3s linear;
  display: flex;
  width: 71px;
  position: relative;

  &.Bar_open {
    .Bar-BidDataLayout {
      padding: 6px;
    }
  }

  &-BidDataLayout {

    overflow: hidden;

    font-size: 14px;

  }

  &-BigData {
    background-color: rgba(255, 255, 255, 0.8);
    transition: all .3s linear;
    top: 0px;
    // left: 71px;
    position: absolute;
    white-space: pre-line;
    width: 400px;
    height: 100%;
    z-index: 100;
    overflow: hidden;
  }

  &-User {
    width: 71px;
    overflow: hidden;
  }

  &-Wrapper {

    height: 100%;
    box-sizing: border-box;
    display: flex;
    margin-right: 1px;
  }

  &_first {
    background: linear-gradient(#009d3f, transparent);
  }

  &_me {
    background: linear-gradient(#fbb800, transparent);
  }

  &-Name,
  &-Value {
    text-align: center;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &-Value {
    white-space: nowrap;
    font-size: 12px;
    font-weight: bold;
  }

  &-Avatar {
    position: relative;
  }

  &-Position {
    display: block;
    position: absolute;
    width: 20px;
    height: 20px;
    background-color: var(--danger-color);
    color: var(--invariant-text-color);
    font-size: 12px;
    z-index: 10;
    border-radius: 50%;
    text-align: center;
    line-height: 20px;
  }
}