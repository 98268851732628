.VoteItem {
  background: #FFFFFF;
  padding: 16px;
  border-radius: 8px;
  border: 1px solid #D3D9E0;
  margin-bottom: 24px;
  margin-top: 48px;

  &-Header {
    position: relative;
    text-align: center;
  }

  &-Avatar {
    margin-top: -64px;

    .Avatar-Img {
      border: 1px solid #D3D9E0;
    }
  }

  &-ContextMenu {
    position: absolute;
    right: 0px;
    top: 0px;
    opacity: 0;
  }

  &:hover {
    .VoteItem-ContextMenu {
      opacity: 1;
    }
  }

  &-UserName {
    display: block;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
  }

  &-Body {
    margin: 0;

    .FieldViewTextarea {


      &-Data {  
        overflow: hidden;
        // white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }

  &-Actions {
    display: flex;
    justify-content: space-between;
    margin-top: 16px;

    .ShowFull {
      position: relative;
    }

    .ActionsLayout {
      
      &-Master {
        justify-content: flex-end;
        flex: 1;
        padding: 0;

        .ActionButton {
          margin: 0;
        }
      }

      &-Slave {
        display: none;
      }
    }
  }

  hr {  
    border: none; /* Убираем границу для браузера Firefox */
    color: #D3D9E0; /* Цвет линии для остальных браузеров */
    background-color: #D3D9E0; /* Цвет линии для браузера Firefox и Opera */
    height: 2px; /* Толщина линии */
    margin: 16px -16px;
  }
}