.ReplySkeleton {
    // background-color: var(--background-color);
    // border: 1px solid var(--border-color);
    // border-radius: 8px;
    padding: 24px 24px;
    // margin: 12px 0;
    border-bottom: 1px solid #D3D9E0;

    &-Header {
        display: flex;
        align-items: center;
    }

    &-Divider {
        border: none;
        border-top: 1px solid var(--border-color);
        margin: 0 -24px;
        padding-bottom: 24px;
    }

    .Skeleton_shape_circle {
        width: 40px;
        height: 40px;
    }

    .Skeleton_shape_line {
        height: 12px;
    }
}