.LMSTOStatFullTile {
   width: 168px;
   height: 168px;
   background-color: #fff;
   border-radius: 8px;
   border: 1px solid #d3d9e0;
   cursor: pointer;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   position: relative;

   &_disabled {
      cursor: default;
   }

   &-Icon {
      position: absolute;
      top: 4px;
      right: 4px;
   }

   &-Disabled {
      position: absolute;
      top: 0;
      right: 0;
      width: 48px;
      height: 48px;
      color: #fff;
      overflow: hidden;
      border-top-right-radius: 8px;
      &::before {
         content: "";
         background-color: #515151;
         display: block;
         width: 72px;
         height: 33px;
         position: absolute;
         top: -5px;
         right: -25px;
         transform: rotate(45deg);
      }
   }

   &-Header {
      margin-top: 12px;
      color: #0066cc;
      font-size: 14px;
      line-height: 16px;
   }

   &-ImgBox {
      width: 56px;
      height: 56px;
   }

   &-Img {
      width: 100%;
      height: auto;
      display: block;
   }
}
