.AnswerLayout {
    position: relative;
    min-height: 60px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #D3D9E0;
    
    >* {
        align-self: center;
    }
    cursor: pointer;
}

.AnswerProgress {
    position: absolute;
    height: 100%;
    background-color: #e4eaf1;
    left: 0;
    top: 0;
    transition: width ease-in-out .3s;
}

.AnswerContent {
    display: flex;
    position: relative;
    top: 0;
    flex: 1 1;
    padding: 5px 0;
    >* {
        align-self: center;
    }
}
