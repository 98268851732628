.LineInput {
    border: none;
    outline: none;
    width: 100%;
    background-color: transparent;
    font-size: 14px;
    /* line-height: 28px; */
    /* margin-top: 5px; */
    /* padding: 5px 0; */
    /* padding: 11px 0 10px 0; */
}

.LineInput::-webkit-inner-spin-button,
.LineInput::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
}