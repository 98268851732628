.UsersWidget_type_list {

  .UsersWidget-Block {

    &-Name {
      font-weight: 700;
      font-size: 16px;
      line-height: 116%;
      letter-spacing: 0.15px;
      color: rgba(0, 0, 0, 0.87);
      align-items: center;
      margin: 6.5px 0;
    }

    &-Description {
      font-weight: 400;
      font-size: 12px;
      line-height: 166%;
      letter-spacing: 0.4px;
      color: rgba(0, 0, 0, 0.87);
      margin-top: 8px;
    }

    &-User {
      display: flex;
      font-weight: 400;
      font-size: 14px;
      line-height: 143%;
      letter-spacing: 0.15px;
      align-items: center;

      &:nth-child(n + 2) {
        margin-top: 8px;
      }

      .Avatar {
        margin-right: 16px;
      }
    }
  }
}

.UsersWidgetCollegues-Item:hover {

  .UsersWidgetCollegues-Item-Info-EditIcon {
    opacity: 1;
  }
}

.UsersWidgetCollegues {
  display: flex;
  flex-direction: column;
  gap: 24px;
  grid-gap: 24px;

  &-Block {
    display: flex;
    flex-direction: column;
    gap: 16px;
    grid-gap: 16px;


    &-Name {
      font-weight: 700;
      font-size: 14px;
      line-height: 116%;
      letter-spacing: 0.15px;
      color: rgba(0, 0, 0, 0.87);
      align-items: center;
      margin: 6.5px 0;
    }

    &-Description {
      font-weight: 400;
      font-size: 12px;
      line-height: 166%;
      letter-spacing: 0.4px;
      color: rgba(0, 0, 0, 0.87);
    }
  }

  &-Item {
    display: flex;
    align-items: flex-start;
    gap: 16px;
    grid-gap: 16px;
    width: 100%;


    &-Info {
      min-width: 0;
      width: 100%;

      &-EditIcon {
        opacity: 0;
      }

      a {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: rgba(0, 0, 0, 0.87);
        font-feature-settings: 'clig' off, 'liga' off;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        letter-spacing: 0.15px;
        width: 100%;
      }

    }
  }
}