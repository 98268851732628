@import '../../utils/global.scss';

.TextareaMentions {
  box-sizing: border-box;
  position: relative;
  background-color: #fff;
  margin: 1px 0;
  padding: 12px 24px 12px 64px;

  &:focus-within {
    outline: 1px solid $SystemBlue;
  }

  &-Icon {
    margin-right: 24px;
    position: absolute;
    top: 12px;
    left: 24px;
    color: var(--text-muted-color);
  }

  .Mention-Suggester {
    position: relative;
    padding: 11px 10px 10px 63px;

    &_noImg {
      padding-left: 10px;
    }

    &:hover {
      background-color: $SystemBlue;
      color: #fff;
    }

    .Avatar {
      position: absolute;
      top: 4px;
      left: 6px;
    }
  }

  &-Inner {
    min-height: 120px;

    &__input,
    &__highlighter {
      width: 100%;
      padding: 0;
      border: none;
    }

    &__highlighter {
      strong {
        background-color: $SystemBlue;
        // box-shadow: 2px 0px 0px 0px $SystemBlue, -2px 0px 0px 0px $SystemBlue;
      }
    }
  }

}