.TestInfoList {
    list-style: none;
    margin: 0;

  &-PropertiesIcon {
    width: 24px;
    height: 24px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;

    &_wait {
      background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='iso-8859-1'%3F%3E%3C!-- Generator: Adobe Illustrator 19.1.0, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Capa_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 41.301 41.301' style='enable-background:new 0 0 41.301 41.301;' xml:space='preserve'%3E%3Cpath style='fill:%231E201D;' d='M20.642,0c5.698,0,10.857,2.317,14.602,6.047c3.73,3.746,6.047,8.905,6.047,14.603 c0,5.698-2.317,10.857-6.047,14.603c-3.746,3.73-8.904,6.047-14.602,6.047S9.786,38.983,6.056,35.253 C2.31,31.507,0.008,26.349,0.008,20.65c0-5.698,2.301-10.857,6.047-14.603C9.786,2.317,14.944,0,20.642,0L20.642,0z M31.166,19.523 c0.619,0,1.111,0.508,1.111,1.127c0,0.619-0.492,1.127-1.111,1.127H20.674h-0.032c-0.413,0-0.778-0.238-0.968-0.571l-0.016-0.016 l0,0l-0.016-0.032l0,0v-0.016l0,0l-0.016-0.032l0,0l-0.016-0.032l0,0v-0.016l0,0l-0.016-0.032l0,0l-0.016-0.016l0,0v-0.032l0,0 l-0.016-0.032l0,0v-0.016l0,0l-0.016-0.032l0,0v-0.032l0,0v-0.016v-0.016l-0.016-0.016l0,0v-0.032l0,0v-0.032l0,0V20.73l0,0v-0.016 l0,0v-0.032l0,0V20.65l0,0V7.206c0-0.619,0.492-1.111,1.111-1.111c0.619,0,1.127,0.492,1.127,1.111v12.317H31.166z M33.657,7.635 c-3.333-3.333-7.936-5.381-13.015-5.381S10.96,4.301,7.627,7.635C4.31,10.968,2.246,15.571,2.246,20.65 c0,5.079,2.063,9.682,5.381,13.016c3.333,3.333,7.936,5.381,13.015,5.381s9.682-2.048,13.015-5.381 c3.333-3.333,5.397-7.936,5.397-13.016C39.054,15.571,36.991,10.968,33.657,7.635L33.657,7.635z'/%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3C/svg%3E%0A");
    }

    &_failure {
      background-image: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.999919 10.8995L10.8994 1' stroke='%23F1543F' stroke-width='1.2'/%3E%3Cpath d='M0.99992 0.999918L10.8994 10.8994' stroke='%23F1543F' stroke-width='1.2'/%3E%3C/svg%3E%0A");
    }

    &_success {
      background-image: url("data:image/svg+xml,%3Csvg width='24' height='18' viewBox='0 0 24 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M23.9021 2.82319L9.0214 17.7039C8.89033 17.8349 8.6781 17.8349 8.54733 17.7039L0.0980745 9.25431C-0.0326915 9.12385 -0.0326915 8.91162 0.0980745 8.78055L2.15113 6.72749C2.2822 6.59673 2.49443 6.59673 2.6252 6.72749L8.78467 12.8867L21.3753 0.296073C21.5066 0.165307 21.7183 0.165307 21.8493 0.296073L23.9021 2.34913C24.0332 2.47989 24.0332 2.69182 23.9021 2.82319Z' fill='%2389CA5E'/%3E%3C/svg%3E%0A");
    }

    &_info {
      background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M7.02271 4.5C7.02271 4.22386 6.79885 4 6.52271 4C6.24656 4 6.02271 4.22386 6.02271 4.5V19.5C6.02271 19.7761 6.24656 20 6.52271 20C6.79885 20 7.02271 19.7761 7.02271 19.5V14.8297L17.4514 10.4596C17.6371 10.3818 17.7585 10.2044 17.7613 10.0069C17.764 9.80939 17.6476 9.62877 17.4642 9.546L17.1712 9.4135C16.9817 9.32766 16.7063 9.20277 16.363 9.04665C15.6764 8.73439 14.7183 8.29721 13.6325 7.7976C11.5588 6.84343 9.02289 5.66319 7.02271 4.69062V4.5ZM7.02271 5.80659V13.7417L15.9947 9.98203L15.9317 9.95337C15.244 9.64063 14.2845 9.20281 13.197 8.70242C11.276 7.81853 8.95265 6.73799 7.02271 5.80659Z' fill='%2394A4B5'/%3E%3C/svg%3E%0A");
    }
  }

  &-PropertiesContent {
    display: inline-block;
    font-size: 16px;
    line-height: 22px;
    vertical-align: top;
    padding-left: 24px;
  }

  &-PropertiesTitle {
    font-weight: bold;
  }

  &-PropertiesData {
    font-weight: normal;
  }

  &-PropertiesItem {
    display: flex;
    padding: 8px;
  }
}
