.Ideas {
    &-Layout {
        background-color: #fbffe0;
        margin: 59px 0 0px;
        padding-bottom: 42px;
        border-top: 1px solid #6e839c;

        // position: relative;
        .Matrix {
            padding: 0;
        }

        .StageBar {
            margin-top: -30px;

        }
    }
}