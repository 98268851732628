.CurrencyTooltip {
  &-List {
  }
  &-Item {
    margin: 12px 0;
  }
  &-Layout {
    padding: 0 12px;
  }
}
