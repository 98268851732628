.BadgesList {

    &-Loading {
        padding: 24px;
    }

    &-Header {
        padding: 24px 24px 16px 24px;
        background-color: #ffffff;

        .SearchInput {
            max-width: unset;
        }
    }

    &-List {
        display: flex;
        flex-direction: column;
        gap: 12px;
    }

    &-Category {
        font-weight: 700;
        margin-top: 24px;
        padding: 8px 16px;
        background-color: #ffffff;
        border: 1px solid #d3d9e0;
        border-radius: 8px;
    }

    &-Badges {
        display: flex;
        gap: 16px;
        flex-wrap: wrap;
        width: calc(100% + 16px);
    }

    &-Filters {
        display: flex;
        margin-top: 16px;
        align-items: center;
        flex-wrap: wrap;
        gap: 0 16px;

        h4 {
            margin: 0;
        }
    }

    .BadgesList-Radio {
        margin: 0;
    }
}