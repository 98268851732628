.LikeAction {
    display: flex;

    .BtnLikeNum {
        margin-left: 8px;
    }

    .Icon {
        margin-right: 8px;
    }

    .Button {
        color: #999;

    }
}