.SliderEventDetailItem {
    font-weight: normal;
    &-Title {
        color: rgba(0, 0, 0, 0.6);
        margin-right: 4px;
    }

    &-Value {
        margin-right: 4px;
    }
}

.MuiButtonBase-root.SliderEventHeader {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 12px 0px 8px;
    height: 150px;
    background: #f44336;
    border-radius: 4px;
    margin: 0 24px;
}

.SliderEventHeader {
    &-Box {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 8px 16px;
        background: #f44336;
        border-radius: 4px;
    }

    &-Date {
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 133.4%;
    }

    &-Day {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 266%;
        text-align: center;
        letter-spacing: 1px;
        text-transform: uppercase;
    }
}

.SliderEvent {
    &-Header {
    }

    &-OpenBtn {
        display: flex;
        height: 36px;
        max-width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &-OpenBtnText {
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &-Detail {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 8px;
        border-radius: 4px;
        background: #f5f5f5;
    }

    &-DetailItem {
        margin-right: 16px;
        display: flex;
        font-size: 12px;
        line-height: 166%;
    }

    &-Times {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    &-Location {
    }

    &-Content {
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;
        padding: 24px;
        padding-top: 8px;
        padding-bottom: 16px;
    }

    &-Text {
        font-size: 12px;
        line-height: 166%;
    }

    &-Footer {
        margin-top: 8px;
    }

    &-Title {
        margin-top: 8px;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 157%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        cursor: pointer;
    }

    &-Preview {
        margin-top: 8px;
        display: block;
        max-width: 100%;
        height: auto;
        width: 100%;
        object-fit: cover;
        max-height: 120px;
    }

    &-Times {
        display: flex;
    }
}

.SliderEventNew {
    &-Title,
    &-DateNew {
        width: 100%;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    &-DetailItemNew {
        word-wrap: break-word;
    }
    &-Footer {
    }
}
