.TOStudentResultsTask {
    &-Title {
        padding-left: 24px;
        padding-right: 24px;
    }

    &-Select {
        margin: 0 24px;
    }
}
