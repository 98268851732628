@import 'utils/src/global.scss';

.NotificationsSettingsNetworkGroup{
  &-Title{
    font-size: 24px;
  }
  &-GroupName {
    padding-left: 6px;
    font-weight: bold;
  }
  &-saveButton{
    color: #fff;
    background: #0066CC;
    margin-left: 91%;
    margin-top: 20px;
  }
  
  &-NetworkItemWrapper {
    
    background-color: #fff;
    display: flex;
    flex-direction: column;
    border: 1px solid $BorderColor;
    border-radius: 12px;
    margin-top: 20px;
  }
  // &-NetworkItem {
  //   display: flex;
  //   justify-content: space-between;
  //   padding: $defaultPadding;
  //   border-bottom: 1px solid $BorderColor;
  //   &-Title {
  //     display: flex;
  //     align-items: center;
  //     span {
  //       font-size: 26px;
  //     }
  //   }
  //   &-Settings {
  //     display: flex;
  //     flex-direction: column;
  //     align-items: center;
  //   }
  // }

  &-ShowGroups {
    margin-left: $defaultMargin;
  }
}