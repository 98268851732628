.LMSTOStatUserCoursesList {
    background-color: #fff;
    border-radius: 8px;
    // display: flex;
    // flex-direction: column;
    // align-items: center;

    &-Header {
        position: relative;
        background-color: #F7F7F7;
        padding: 24px;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        display: flex;
        justify-content: space-between;
        flex: 1;
        width: 100%;
        box-sizing: border-box;
    }

    &-Progress {
        width: 100%;
    }

    &-SearchBox {
        width: 528px;
    }

    &-Col1 {
        width: 230px;
    }
    &-Col2 {
        width: 330px;
    }
    &-Col3 {}
    &-Col4 {
        // width: 80px;
    }
    &-Col5 {
        // width: 80px;
    }
    &-Col6 {
        width: 100px;
    }
    &-Col7 {
        width: 100px;
    }
    &-Col8 {}

    &-Table {
        width: 100%;
        border-collapse: collapse;
        text-align: center;
        margin-top: 4px;
        position: relative;

        &_isLoading {
            margin-top: 0px;
        }

        thead {
            text-align: left;
            th {
                position: sticky;
                top: 56px;
                background-color: #fff;
                white-space: nowrap;
                padding-left: 8px;
                padding-right: 8px;
                border-bottom: 1px solid #D3D9E0;

            }
        }


        th {
            padding: 12px 12px;
        }

        td,
        th {
            border-bottom: 1px solid #D3D9E0;
        }
    }
}