.Modal-Body_type_linkToNews {
  display: flex;
}
.Modal-Body_type_linkToNews-Item-Input {
    flex: 1 1;
    font-family: 'Open Sans regular';
    text-align: center;
    border: 0;
    outline: none;
    padding: 14px 0;
    display: block;
}

.type_linkToNews-Item-Button {
    display: block;
}