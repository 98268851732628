.UnitEdit{

    .MuiDialog-root.Pane .MuiDialog-paper > div {
        flex: inherit;
        display: flex;
        flex-direction: column;
        margin-left: 1000px;
        width: calc(100% - 1000px);
    }
   &-NameSaggest{
       margin-bottom: 5px!important;
   }
    &-Positions{
        display:flex;
        flex-direction: column;
        margin: 10px 0 0 0px;
        text-align: left;
        .CustomNodePositions{
            margin-right:10px;
        }
        &-Users{
            display:flex;
        }
        &-Title{
            margin-bottom: 10px;
            font-weight: 600;
        }
        &-active{
            background: #fff7002e;
            padding: 10px;
        }
        
    }
    &-AddPosition{
        display: flex;
        flex-direction: column;
        text-align: left;
            &-VacancyCheckbox{
                margin-left: 24px;
            }
            &-Actions{
                margin: 5px 24px;
                display:flex;
                justify-content: space-between;
            }
    }
    &-VacancyButton{
        border-radius: 50%;
        width: 42px;
        // height: 42px;
        // border: 1px solid #000;
        display: flex;
        align-items: center;
        justify-content: center;

        &:before {
            content: "";
            float: left;
            padding-top: 100%;
            display: block;
        }
    }
    &-Item{
        display: flex;
    }
    &-Content{
        background: #fff;
        border: 1px solid #ccc;
        border-radius:  8px;
        
    }
    &-Body{
        height: 722px;
    }
    &-Title{
        align-items: center;
        display: flex;
        justify-content: space-between;
        margin: 5px 24px;
        h4{
            font-size: 14px;
        }
    }
    &-Actions{
        margin: 5px 24px;
        &-CancelBTN{
            color: #0066CC;
        }
    }

   
}