.Wiki {
  &-EditBtn {
    position: absolute;
    top: 8px;
    left: 8px;
    opacity: 0;
    z-index: 100;
    transition: all 0.15s ease-in-out;
    &:hover {
      opacity: 1!important;
    }
  }

  &:hover {
    .Wiki-EditBtn {
      opacity: 0.5;
    }
  }

  .ce-block__content {
    position: relative;
    max-width: 100%;
    margin: 0 auto;
  }

  &-List_ol {
    padding-left: 15px;
  }

  &-List_ul {
    padding-left: 20px;
  }

  &-List_checklist {
    list-style: none;
    padding-left: 0;
  }

  &-Header_2 {
    font-size: 32px;
    line-height: 40px;
  }

  &-Img {
    display: block;
    max-width: 100%;
    height: auto;
  }

  &-Comments {
    margin-left: -24px;
    margin-right: -24px;
    margin-bottom: -24px;
  }

  &-Blockquote {
    position: relative;
    margin-left: 38px;

    &:before {
      content: '';
      background-repeat: no-repeat;
      width: 18px;
      display: block;
      position: absolute;
      height: 14px;
      left: -38px;
      top: 5px;
      background-image: url("data:image/svg+xml,%3Csvg width='17' height='14' viewBox='0 0 17 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.75781 0L7.65625 1.96875C5.96094 4.28385 5.08594 6.78125 5.03125 9.46094V13.1797H0V10.0078C0 8.14844 0.4375 6.30729 1.3125 4.48438C2.20573 2.66146 3.35417 1.16667 4.75781 0ZM13.3711 0L16.2695 1.96875C14.5742 4.28385 13.6992 6.78125 13.6445 9.46094V13.1797H8.61328V10.0078C8.61328 8.14844 9.05078 6.30729 9.92578 4.48438C10.819 2.66146 11.9674 1.16667 13.3711 0Z' fill='%2394A4B5'/%3E%3C/svg%3E%0A");
    }

  }
}