@import '../../../utils/global.scss';

.NewsDialogView {
    background-color: #fff;
    border: 1px solid #d3d9e0;
    position: relative;
    padding-top: 24px;


    &_actionHeader {
        padding-top: 0px;
    }

    &-isNeedApprove {}

    &-UserImg {
        display: block;
    }

    &-Attachments {
        &:empty {
            display: none;
        }
    }

    .NewsView-Preview {
        margin-top: 15px;

        &:empty {
            display: none;
        }
    }

    .horizontalPadding {
        padding-left: 12px;
        padding-right: 12px;

        @media (min-width: 576px) {
            padding-left: 24px;
            padding-right: 24px;
        }
    }

    .horizontalMargin {
        margin-left: 12px;
        margin-right: 12px;

        @media (min-width: 576px) {
            margin-left: 24px;
            margin-right: 24px;
        }
    }


    &:hover {
        .ServiceActions-Toggle {
            opacity: 1;
        }
    }
}
