.List-Item {
  display: flex;
  align-items: top;
  padding: 8px 12px;

  &:not(:first-child) {
    border-top: 1px solid var(--border-color);
  }

  &-Info {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  &-Col {
    margin-left: 24px;
    &_span_1 {
      width: 100%;
    }
    &_span_2 {
      width: 50%;
    }
    &_span_3 {
      width: 33%;
    }
  }

  @media (min-width: 576px) {
    align-items: flex-start;
    padding: 12px 24px;
    &-Info {
      flex-direction: row;
    }
    &-Col {
      margin-left: 12px;
    }
  }
}