.Modal-Body {
  padding: 0 15px;
  max-height: calc(100vh - 200px);
  overflow-x: hidden;
  overflow-y: auto;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  /* IE10+ CSS styles go here */
  .Modal-Body {
    max-height: 650px;
  }
}
