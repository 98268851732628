.NewsReplyLayout {
    .ContextMenu-Toggle {
        opacity: 0;
    }

    &:hover {
        .ContextMenu-Toggle {
            opacity: 1;
        }
    }

}