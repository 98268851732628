.WithQRDialog {
  background: #fff;
  border-bottom-left-radius: 4px ;
  border-bottom-right-radius: 4px ;
  &-Content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: url("../../../assets/svg/QRDialogBack.svg");
    background-repeat: no-repeat;
    background-size: 100%;
    background-position-x: -13px;
    background-position-y: 378px;
  }

  &-QRContent {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }

  &-QrCodeImg {
    width: 250px;
  }

  &-QrCode {
    display: flex;
    flex-direction: column;
    &-StoreBtn {
      text-align: center;
      &-IMG {
        width: 60%;
      }
    }
  }
  &-Submit {
    text-align: center;
    margin: 0 auto 15px;
    width: 65%;
    font-size: 18px;
    line-height: 40px;
    background: transparent;
  }
  &-Title {
    font-size: 32px;
    line-height: 40px;
  }
  &-ActionBTN {
    text-align: center;
    margin: 5px auto 24px;
    width: 80%;
    font-size: 18px;
    line-height: 25px;
    background: transparent;
    // color: #0066CC;
  }

  &-SendOnPhone {
    width: 80%;
    margin: 24px auto;
    text-align: center;
  }
  &-Input {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    padding: 0;
    margin: 5px auto;
    width: 170px;
    border: 1px solid #ccc;
    border-radius: 8px;
    .Input-Input_noIcon {
      border-radius: 8px;
    }
  }
  &-Error {
    color: red;
  }
}
