@import "../../../utils/global.scss";


.orgchart ul li .oc-node {
    box-sizing: border-box;
    display: inline-block;
    position: relative;
    width: 325px;
    /* height: 150px; */
    padding: 0px;
    border-radius: 8px;
    border: 1px dashed transparent;
    margin-bottom: 20px;
}
 .orgchart ul li .oc-node:hover {
    background-color: inherit;
    box-shadow: -1px 10px 18px -1px rgba(34, 60, 80, 0.2);
}
.CustomNode{
    position: relative;
    -webkit-box-shadow: -1px 10px 18px -1px rgba(34, 60, 80, 0.2);
    -moz-box-shadow: -1px 10px 18px -1px rgba(34, 60, 80, 0.2);
    box-shadow: -1px 10px 18px -1px rgba(34, 60, 80, 0.2);
    border-radius: 8px;
    user-select: none;
    border: 1px solid #d3d9e0;
    box-sizing: border-box;

    
    &-PopApp{
        position: absolute;
        background: #fff;
        border-radius: 8px;
        border: 1px solid #ccc;
        top: 40px;
        z-index: 50;
        &-List{
            display: flex;
            flex-direction:column;
        }
    }
    &.allowDrop {
        border: 5px red solid;
    }

    &-List{
        list-style: none;
        padding: 0;
        margin: 5px 0;
    }
    &-ActionsItem {
    padding: 5px 10px;
    width: 100%; 
        &:hover {
            background-color: $SystemBlue;
            color: #fff;
        }
    }

    &-Dragger {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        z-index: 1;
    }

    &_dragging {
        opacity: .5;
        outline: 5px dashed red;
    }

    &-Header{
        box-sizing: border-box;
        padding: 10px 5px;
        margin: 0;
        display: flex;
        justify-content: space-between;
        /* width: 130px;
        height: 20px; */
        text-align: center;
        align-items: center;
        font-size: 0.75rem;
        font-weight: bold;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        background-color: #194776;
        color: #fff;
        border-radius: 4px 4px 0 0;
        .HeaderLayout-SettingsButton{
            width: 24px;
            height: 24px;
            padding: 8px 11px 11px 8px;
            display: flex;
            font-size: 14px;
            align-items: center;
            justify-content: center;
            margin-left: 0px;                
        }
        &-Title{
            line-height: 1.5em;
            white-space: break-spaces;
            text-align: left;
            &-Actions{
                button.MuiButtonBase-root.MuiButton-root.MuiButton-contained.MuiButton-containedPrimary{
                    color: #000;
                    background: #fff;
                }
                ul.MuiList-root.MuiList-padding{
                    flex-direction: column;
                }
            }         
            
        }
    }
    &-HeaderWithoutUsers{
        box-sizing: border-box;
        padding: 10px 5px;
        margin: 0;
        display: flex;
        justify-content: space-between;
        /* width: 130px;
        height: 20px; */
        text-align: center;
        align-items: center;
        font-size: 0.75rem;
        font-weight: bold;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        background-color: #194776;
        color: #fff;
        border-radius: 4px;
        .HeaderLayout-SettingsButton{
            width: 24px;
            height: 24px;
            padding: 8px 11px 11px 8px;
            display: flex;
            font-size: 14px;
            align-items: center;
            justify-content: center;
            margin-left: 0px;                
        }
        &-Title{
            line-height: 1.5em;
            white-space: break-spaces;
            text-align: left;
           
        }
    }
    &-Content{
        // border: 2px solid #d77a70; 
        border-radius: 0 0 8px 8px;
        background: #fff;
        padding: 5px;
        display: none;

        &_show {
            display: block;
        }
    }
    &-Heads, &-SubUsers{
        display: none;
        margin: 10px 5px;
        // align-items: center;

        &_show {
            display: flex;
            .Sortable_horizontal{
                justify-content: space-evenly;
            }
        }

        .UserList-Item::before{
            border:none;
        }
        .Span{
            min-width: 128px;
            text-align: left;
        }
    }
     &-VacancyButton{
        border-radius: 50%;
        width: 42px;
        // height: 42px;
        // border: 1px solid #000;
        display: flex;
        align-items: center;
        justify-content: center;

        &:before {
            content: "";
            float: left;
            padding-top: 100%;
            display: block;
        }
    }

    &.showPositions &-Content {
        display: block;
    }

    &.showPositions &-Heads,
    &.showPositions &-SubUsers {
        display: flex;
    }
    

}