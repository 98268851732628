.Course {

  &-Header {
    // display: flex;
  }

  &-Threads {
    // padding: 24px;
    border-bottom: 1px solid #d3d9e0;
    padding-bottom: 12px;
  }

  &-Content {
    background-color: #ffffff;
    border-radius: 8px;
    border: 1px solid #d3d9e0;
  }

  &-Header {
    margin-bottom: 24px;
  }

  &-ThreadsTitle {
    margin: 0;
    padding: 24px 24px 6px 24px;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
  }

  &-Search {
    border-bottom: 1px solid #d3d9e0;
    //   border-top-right-radius: 8px;
    //   border-top-left-radius: 8px;
    border-top: 1px solid #d3d9e0;
    padding: 24px;
    background: #f7f7f7;
  }

  &-SearchInput {
    max-width: none;
  }

  &-SessionsHeader {
    display: flex;
  }

  &-SessionsTabs {
    padding-top: 22px;
    padding-bottom: 0px;

    .LMSCourseTabs-TabBtn {
      font-size: 16px;
    }
  }

  &-UnitsCount {
    margin: 0;
    padding: 24px;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    border-bottom: 1px solid #d3d9e0;
  }

  &-CommentsHeader {
    padding: 24px;
    font-size: 16px;
    line-height: 24px;
  }

  &-AnswersList {
    display: flex;
    margin-top: 24px;

    &-Aside {
      background-color: #fff;
      flex: 1 4;
      border-radius: 8px;
      overflow: hidden;
      border: 1px solid #d3d9e0;
    }

    &-Section {
      flex: 3 4;
      margin-left: 24px;
    }

  }

  // &-Tabs {
  //   padding-top: 24px;
  //   padding-bottom: 24px;
  //   // border-bottom: 1px solid #d3d9e0;
  // }

  // &-TabBtn {
  //   font-size: 20px;
  //   margin-left: 24px;
  //   margin-right: 24px;
  //   border-bottom: 1px solid #979797;
  //   padding-bottom: 1px;

  //   &_selected {
  //     padding-bottom: 0;
  //     border-bottom: 2px solid #0066CC;
  //   }
  // }
}