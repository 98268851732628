.UsersWidget {
  &-Header {
    &-Title {
      font-family: "Open Sans", Arial, Helvetica, sans-serif;
      text-transform: none;
      font-weight: 700;
      font-size: 16px;
      line-height: 116%;
      letter-spacing: 0.15px;
      margin: 6.5px 0;
    }

    &-noData {
      width: 100%;
      max-width: 300px;
    }

    &-Text {
      font-size: 14px;
      line-height: 20.02px;
      width: 100%;
      margin-bottom: 12px
    }
  }

  &-Name {
    font-weight: 700;
    font-size: 16px;
    line-height: 116%;
    margin: 0 0 8px 0;
  }

  .ListRound {
    .ListRound-Item {
      margin: 0 6px 0 0 !important;
      // margin-right:10px;
    }

    .MuiButtonBase-root.MuiIconButton-root.ListRound-More {
      width: 40px;
      height: 40px;
      // border: 1px solid var(--border-color);
      // text-align: center;
      // border-radius: 50%;
      // box-sizing: border-box;
    }
  }
}