@import '../../../utils/global.scss';

.ImgLoader-Preview {
  width: 150px;
  height: 150px;
  border: 1px solid $BorderColor;
  position: relative;
  float: left;
  margin-right: 15px;
  box-sizing: border-box;
  overflow: hidden;

  &-Img {
    width: inherit;
    height: inherit;
  }

  &-Icon {
    color: $BorderColor;
    position: absolute;
    font-size: 40px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -56%);
  }
}