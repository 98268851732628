.InfoBlock-User {
    &-CurransyHelp {
        cursor: default;
    }

    .InfoBlock-Img {
        position: relative;
    }
    
    .InfoBlock-Layout {
        justify-content: center;
        align-items: flex-start;
        flex-wrap: wrap;
        @media (max-width: 500px) {
            align-items: center;
        }
    }

    .InfoBlock-Layout-Right {
        flex: unset;
        max-width: calc(100% - 204px);
    }

    .InfoBlock-DataName {
        i {
            margin-left: 12px;
        }
    }

    .InfoBlock-DataNameBox {
        display: flex;
        align-items: center;

        @media (max-width: 500px) {
            margin-top: 12px;
            flex-direction: column;
            text-align: center;
            justify-content: center;
        }
    }

    .InfoBlock-QR {
    }

    &-TagLink {
        color: var(--main-color);
    }

    &-Data-Status {
        @media (max-width: 500px) {
            text-align: center;
        }
    }

    &-Seporator {
        margin-right: 6px;
    }

    &-Fields {
        border-collapse: collapse;
        &-Item {
            vertical-align: top;
        }
    }
    &-Col1 {
        width: 200px;
    }

    .InfoBlock-User-SocialActions {
        display: flex;
        flex-direction: column;
        gap: 8px;
    }

    .InfoBlock-User-SocialActions,
    .Profile-Currency {
        text-align: center;
        margin-top: 8px;

        &Divider {
            height: 22px;
            display: inline-block;
            vertical-align: middle;
            border-left: 1px solid var(--border-color);
            margin-left: 1rem;
            margin-right: 0.5rem;
        }

        &Icon {
            font-size: 18px;
            color: var(--text-muted-color);
        }
    }

    .Profile-Currency {

        .CurrencyTooltip {
            display: flex;
            align-items: center;
            gap: 8px;

            .InfoBlock-User-CurransyHelp {
                text-align: start;
            }
        }
    }
    
    .InfoBlock {
        &-Textarea {
            white-space: nowrap;
            max-width: 300px;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    &-Settings {
        display: flex;
        gap: 8px;

        &-Splitter {
            border-left: 1px solid;
        }
    }
}
