.Reply-Edit {
    &-TextareaMentions {
        background-color: #F7F7F7;
        margin: 0;

        .TextareaMentions-Wrapper__highlighter,
        .TextareaMentions-Wrapper__input {
            padding: 0 0 0 56px;
            min-height: 50px;
        }

        .TextareaMentions-Icon {
            top: 0;
        }
    }

    &-Action {
        display: flex;
        align-items: center;
        padding: 20px 0;
        border-top: 1px solid #D3D9E0;
        border-bottom: 1px solid #D3D9E0;
    }

    &-LeftSection {
        flex: 1 1;
        text-align: left;
    }

    &-RightSection {
        flex: 1 1;
        text-align: right;
    }

    &-SaveBtn,
    &-CancleBtn {
        padding: 10px 20px;
    }

    &-CancleBtn {
        margin-left: 24px;
    }
}