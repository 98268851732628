.CoursesItem {
    &-ImadgeBox {
        margin-bottom: 12px;

        &-Title {
            font-weight: 400;
            font-size: 14px;
            line-height: 143%;
            letter-spacing: 0.15px;
            margin: 0;
            padding: 0;
        }
    }

    &-UnitCount {
        font-weight: 500;
        font-size: 14px;
        line-height: 157%;
        margin-bottom: 8px;

        &-Complited {}

        &-Total {
            font-size: 14px;
            line-height: 157%;
            letter-spacing: 0.1px;
            color: rgba(0, 0, 0, 0.6);
        }
    }

    &-Progress {
        margin-bottom: 16px;

        .CourseProgress-Value {
            display: none;
        }

        .CourseProgress-Item {
            height: 12px;
        }
    }
}