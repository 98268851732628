.WorkflowsChangeStatus {

  &-Body {
    &_loading {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .FieldItem {
      margin-bottom: 12px;
    }
  }

  &-Actions {
    button {
      margin: 0 12px;
    }
  }
}