.NewsView-StageBar {
    border: 2px solid #e5cd54;
    // width: max-content; //fit-content;
    padding: 5.5px;
    border-radius: 5px;
    background-color: #ffffff;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    position: relative;
    @media (max-width: 576px) {
        margin: 0 12px;
      }
}

.StageBar-Data {
    // display: inline-block;
    padding: 0 18.2px;
}

.StageBar-Primary {
    font-weight: bold;
    display: flex;
}

.StageBar-Second {
    text-align: center;
    color: #999999;
}

.StageBar-Content {
    max-width: 100%;
}