.QuizSkeleton {
  background-color: var(--background-color);
  border: 1px solid var(--border-color);
  border-radius: 8px;
  padding-bottom: 20px;
  //  padding: 24px 24px;
  // margin: 12px 0;
  margin-top: 24px;
  &-Header {
    display: flex;
    align-items: center;
  }
  &-Body {
    margin: 0 24px;
  }

 
}