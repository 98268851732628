.UsersWidget_type_subscribers {
    
}

.Widget_subtype_subscribers {
    border: none;
    box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
    border-radius: 4px;

    .UsersWidget-Name {
        font-weight: 700;
    }
}

.Widget_type_users {
    padding: 16px;
}