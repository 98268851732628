.NameForm {
  display: flex;
  margin-left: -12px;

  &-Btn {
    margin: 0 6px;

    &_apply {
      color: #fff;
    }

    &_cancle {
      color: #000;
    }
  }

  .InputLayout-WorkZone {
    padding: 0 12px;
  }

  .InputLayout {
    background: #fff;
  }
}

@media all and (-ms-high-contrast: none),
(-ms-high-contrast: active) {

  /* IE10+ CSS styles go here */
  .NameForm {
    min-width: 300px;

    &-Input {
      min-width: 200px;
    }
  }
}