.MiniTimelineList {
    padding-bottom: 8px;
    &-Error {
        border: none;
        border-radius: 0;
    }

    &-List {
       padding-bottom: 8px;
    }
}