.TOAnswerList {
    // display: flex;
    // margin-top: 24px;

    &-Aside {
        background-color: #fff;
        flex: 1 4;
        border-radius: 8px;
        overflow: hidden;
        border: 1px solid #d3d9e0;
    }

    &-Section {
        flex: 3 4;
        margin-left: 24px;
    }

    &-Answer {
        margin-bottom: 24px;
    }

    &-Trigger {
        display: block;
        margin-left: auto;
        margin-right: auto;
    }

    &-Question {
        border: 1px solid #d3d9e0;
        margin-bottom: 24px;
    }
}
