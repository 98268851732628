.FeedbackInfo {
  display: flex;
  align-items: center;
  padding: 24px;
  width: 100%;
  margin-left: -24px;
  background-color: #edf7e7;
  margin-top: 10px;
  span {
    margin-left: 16px;
    font-weight: bold;
    font-size: 16px;
  }
}